/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IdDocumentType,
    IdDocumentTypeFromJSON,
    IdDocumentTypeFromJSONTyped,
    IdDocumentTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface IdDocument
 */
export interface IdDocument {
    /**
     * 
     * @type {Date}
     * @memberof IdDocument
     */
    expiryDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof IdDocument
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof IdDocument
     */
    issueDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof IdDocument
     */
    issuePlace?: string;
    /**
     * 
     * @type {string}
     * @memberof IdDocument
     */
    number?: string;
    /**
     * 
     * @type {IdDocumentType}
     * @memberof IdDocument
     */
    type?: IdDocumentType;
}

export function IdDocumentFromJSON(json: any): IdDocument {
    return IdDocumentFromJSONTyped(json, false);
}

export function IdDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdDocument {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'expiryDate': !exists(json, 'expiryDate') ? undefined : (new Date(json['expiryDate'])),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'issueDate': !exists(json, 'issueDate') ? undefined : (new Date(json['issueDate'])),
        'issuePlace': !exists(json, 'issuePlace') ? undefined : json['issuePlace'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'type': !exists(json, 'type') ? undefined : IdDocumentTypeFromJSON(json['type']),
    };
}

export function IdDocumentToJSON(value?: IdDocument | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'expiryDate': value.expiryDate === undefined ? undefined : (value.expiryDate.toISOString().substr(0,10)),
        'id': value.id,
        'issueDate': value.issueDate === undefined ? undefined : (value.issueDate.toISOString().substr(0,10)),
        'issuePlace': value.issuePlace,
        'number': value.number,
        'type': IdDocumentTypeToJSON(value.type),
    };
}


