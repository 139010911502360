/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PreScreeningQuizKey {
    GENERATE_REVENUE = 'GENERATE_REVENUE',
    LAUNCHED_PRODUCT = 'LAUNCHED_PRODUCT',
    MONTHLY_RUNWAY = 'MONTHLY_RUNWAY',
    MONEY_FUNDRAISED = 'MONEY_FUNDRAISED',
    SUMMARY = 'SUMMARY',
    TRACTION = 'TRACTION',
    MONEY_RAISED = 'MONEY_RAISED',
    MONEY_WANTS_RAISE = 'MONEY_WANTS_RAISE',
    CAMPAIGN_TYPE = 'CAMPAIGN_TYPE',
    NUMBER_OF_EMPLOYEES = 'NUMBER_OF_EMPLOYEES'
}

export function PreScreeningQuizKeyFromJSON(json: any): PreScreeningQuizKey {
    return PreScreeningQuizKeyFromJSONTyped(json, false);
}

export function PreScreeningQuizKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreScreeningQuizKey {
    return json as PreScreeningQuizKey;
}

export function PreScreeningQuizKeyToJSON(value?: PreScreeningQuizKey | null): any {
    return value as any;
}

