<template>
  <div class="container ms-0 mb-0">
    <Attachments
      :attachments="attachments"
      :load-attachment="loadAttachment"
    ></Attachments>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { businessApi } from "@/resources";
import { Legal, AttachmentListItem } from "@/api";
import Attachments from "@/views/components/Attachments.vue";

export default defineComponent({
  name: "BusinessAttachments",
  components: { Attachments },
  data() {
    return {
      businessId: inject("businessId") as number,
      legal: inject("legal") as Legal,
      attachments: [] as AttachmentListItem[],
    };
  },
  watch: {
    async legal() {
      await this.fetchAttachments();
    },
  },
  async mounted() {
    await this.fetchAttachments();
  },
  methods: {
    async fetchAttachments() {
      this.attachments = await businessApi.attachments({
        id: this.businessId,
      });
    },
    async loadAttachment(attachmentId: number): Promise<Blob> {
      return await businessApi.attachment({
        id: this.businessId,
        attachmentId: attachmentId,
      });
    },
  },
});
</script>
