/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserActor,
    UserActorFromJSON,
    UserActorFromJSONTyped,
    UserActorToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserHistory
 */
export interface UserHistory {
    /**
     * 
     * @type {UserActor}
     * @memberof UserHistory
     */
    actor: UserActor;
    /**
     * 
     * @type {number}
     * @memberof UserHistory
     */
    actorId?: number;
    /**
     * 
     * @type {string}
     * @memberof UserHistory
     */
    actorName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserHistory
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserHistory
     */
    givenName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserHistory
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserHistory
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof UserHistory
     */
    role?: string;
    /**
     * 
     * @type {Date}
     * @memberof UserHistory
     */
    updatedAt: Date;
}

export function UserHistoryFromJSON(json: any): UserHistory {
    return UserHistoryFromJSONTyped(json, false);
}

export function UserHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserHistory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actor': UserActorFromJSON(json['actor']),
        'actorId': !exists(json, 'actorId') ? undefined : json['actorId'],
        'actorName': !exists(json, 'actorName') ? undefined : json['actorName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'givenName': !exists(json, 'givenName') ? undefined : json['givenName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'role': !exists(json, 'role') ? undefined : json['role'],
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function UserHistoryToJSON(value?: UserHistory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actor': UserActorToJSON(value.actor),
        'actorId': value.actorId,
        'actorName': value.actorName,
        'email': value.email,
        'givenName': value.givenName,
        'lastName': value.lastName,
        'phone': value.phone,
        'role': value.role,
        'updatedAt': (value.updatedAt.toISOString()),
    };
}


