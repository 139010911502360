<template>
  <Headline
    >{{ $t("sidebar.transactions") }}
    <template v-slot:actions>
      <el-button
        v-if="!filterVisible"
        class="filter"
        @click="filterVisible = true"
        >{{ $t("filter.show") }}
      </el-button>
      <el-button v-else class="filter" @click="filterVisible = false">
        {{ $t("filter.hide") }}
      </el-button>
      <el-dropdown
        v-if="hasAuthority(authorities.TRANSACTION_WRITE) || qaEnabled"
        trigger="click"
        style="margin-left: 15px"
      >
        <el-button type="default">
          {{ $t("actions") }}<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <template #dropdown>
          <el-dropdown-item v-if="hasAuthority(authorities.TRANSACTION_WRITE)">
            <CompleteTransactionDialog
              :header="$t('transactions.completeManual.header')"
              :text="$t('transactions.completeManual.text')"
              :info-message="$t('transactions.completeManual.info')"
              :on-submit="completeTransactionManually"
              @update="fetchData"
            />
          </el-dropdown-item>
          <el-dropdown-item v-if="qaEnabled">
            <CompleteTransactionDialog
              :header="$t('transactions.completeQA.header')"
              :text="$t('transactions.completeQA.text')"
              :info-message="$t('transactions.completeQA.info')"
              :on-submit="completeTransactionQA"
              @update="fetchData"
            />
          </el-dropdown-item>
        </template>
      </el-dropdown>
    </template>
  </Headline>
  <TransactionFilter
    v-if="filterVisible"
    v-model:transaction-filter="filter"
    @submit="fetchData"
  />
  <el-table
    :data="transactionPage.content"
    @sort-change="onSortChange($event, pageRequest)"
  >
    <el-table-column :label="$t('id')" prop="id" sortable="custom" width="85">
    </el-table-column>
    <el-table-column
      :label="$t('transactions.externalId')"
      prop="externalId"
      width="150"
    >
      <template #default="scope">
        {{ scope.row.externalId ?? "-" }}
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('transactions.campaignId')"
      prop="campaignId"
      width="110"
    >
      <template #default="scope">
        <router-link
          v-if="hasAuthority(authorities.CAMPAIGN_READ)"
          :to="{ name: 'campaign', params: { id: scope.row.campaignId } }"
          >{{ scope.row.campaignId }}
        </router-link>
        <span v-else>{{ scope.row.campaignId }}</span>
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('transactions.investmentId')"
      prop="investmentId"
      width="110"
    >
      <template #default="scope">
        <template v-if="scope.row.investmentId">
          <router-link
            v-if="
              hasAuthority(authorities.INVESTMENT_READ) &&
              scope.row.investmentId
            "
            :to="{ name: 'investment', params: { id: scope.row.investmentId } }"
            >{{ scope.row.investmentId }}
          </router-link>
          <span v-else>{{ scope.row.investmentId }}</span>
        </template>
        <span v-else>-</span>
      </template>
    </el-table-column>
    <el-table-column :label="$t('transactions.startDate')">
      <template #default="scope">
        {{ $dateTimeSeconds(scope.row.startDate) }}
      </template>
    </el-table-column>
    <el-table-column :label="$t('transactions.effectiveDate')">
      <template #default="scope">
        {{ $dateTimeSeconds(scope.row.effectiveDate) }}
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('transactions.transactionType')"
      prop="transactionType"
    >
      <template #default="scope">
        <span class="text-break">
          {{ $enums.transaction.type(scope.row.transactionType) }}
        </span>
      </template>
    </el-table-column>
    <el-table-column :label="$t('transactions.amount')">
      <template #default="scope">
        {{ $curr(scope.row.amount) }}
      </template>
    </el-table-column>
    <el-table-column :label="$t('transactions.omrAmount')">
      <template #default="scope">
        {{ $omrCurrValue(scope.row.omrAmount) }}
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('transactions.transactionStatus')"
      prop="transactionStatus"
      min-width="220"
      align="center"
    >
      <template #default="scope">
        <Status
          size="small"
          :color="$enums.transaction.statusColor(scope.row.transactionStatus)"
        >
          {{ $enums.transaction.status(scope.row.transactionStatus) }}
        </Status>
      </template>
    </el-table-column>
  </el-table>

  <TableFooter
    @size-change="onSizeChange($event, pageRequest)"
    @current-change="onCurrentChange($event, pageRequest)"
    :page="transactionPage"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { qaTransactionApi, transactionApi } from "@/resources";
import Headline from "@/components/Headline.vue";
import {
  PageTransactionListItem,
  TransactionType,
  TransactionStatus,
  BackOfficeCompleteTransactionRequest,
} from "@/api";
import {
  authorityMixin,
  fixLocalDate,
  PageRequest,
  pageSortMixin,
} from "@/util";
import TableFooter from "@/components/TableFooter.vue";
import TransactionFilter from "@/views/transaction/TransactionFilter.vue";
import Status from "@/components/Status.vue";
import CompleteTransactionDialog from "@/views/transaction/dialogs/CompleteTransactionDialog.vue";
import { mapState } from "vuex";

export interface Filter {
  campaignId: number | undefined;
  investmentId: number | undefined;
  transactionTypes: TransactionType[];
  transactionStatuses: TransactionStatus[];
  startDateFrom: Date | undefined;
  startDateTo: Date | undefined;
  effectiveDateFrom: Date | undefined;
  effectiveDateTo: Date | undefined;
  amountFrom: number | undefined;
  amountTo: number | undefined;
}

export default defineComponent({
  name: "TransactionList",
  components: {
    CompleteTransactionDialog,
    TransactionFilter,
    Status,
    Headline,
    TableFooter,
  },
  mixins: [pageSortMixin, authorityMixin],
  data: () => ({
    transactionPage: {} as PageTransactionListItem,
    pageRequest: {} as PageRequest,
    filterVisible: false,
    filter: {} as Filter,
  }),
  mounted() {
    this.fetchData();
  },
  computed: {
    ...mapState("feature", ["qaEnabled"]),
  },
  methods: {
    async fetchData() {
      this.filter.startDateFrom = this.filter.startDateFrom
        ? fixLocalDate(this.filter.startDateFrom)
        : undefined;
      this.filter.startDateTo = this.filter.startDateTo
        ? fixLocalDate(this.filter.startDateTo)
        : undefined;
      this.filter.effectiveDateFrom = this.filter.effectiveDateFrom
        ? fixLocalDate(this.filter.effectiveDateFrom)
        : undefined;
      this.filter.effectiveDateTo = this.filter.effectiveDateTo
        ? fixLocalDate(this.filter.effectiveDateTo)
        : undefined;

      this.transactionPage = await transactionApi.transactions({
        ...this.pageRequest,
        ...this.filter,
      });
    },
    async completeTransactionManually(
      form: BackOfficeCompleteTransactionRequest
    ) {
      return await transactionApi.completeTransactionManually({
        backOfficeCompleteTransactionRequest: form,
      });
    },
    async completeTransactionQA(form: BackOfficeCompleteTransactionRequest) {
      return await qaTransactionApi.completeTransaction({
        backOfficeCompleteTransactionRequest: form,
      });
    },
  },
});
</script>
