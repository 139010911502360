
import { defineComponent, PropType } from "vue";
import ButtonOkCancelDialog from "@/views/components/ButtonOkCancelDialog.vue";
import { CampaignContentData } from "@/api";

export default defineComponent({
  name: "ContentAttachment",
  components: { ButtonOkCancelDialog },
  emits: ["update"],
  props: {
    attachment: {
      type: Object as PropType<CampaignContentData>,
      required: true,
    },
    isImage: {
      type: Boolean,
      required: true,
    },
    deleteEnabled: {
      type: Boolean,
      default: false,
    },
    deleteMethod: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      loading: true,
      sourceUrl: "" as string,
    };
  },
  async mounted() {
    if (this.isImage) {
      await this.updateSourceUrl();
    }
    this.loading = false;
  },
  methods: {
    async download() {
      const link = document.createElement("a");
      link.href = this.attachment.attachmentUrl as string;
      link.download = this.attachment.filename as string;
      link.click();
    },
    async updateSourceUrl() {
      this.sourceUrl = this.attachment.attachmentUrl as string;
    },
    async deleteAttachment() {
      if (this.deleteMethod) {
        await this.deleteMethod();
      }
    },
  },
});
