
import { defineComponent } from "vue";
import SidebarLink from "@/components/SidebarLink.vue";
import { authorityMixin } from "@/util";
import TheUserMenu from "@/components/TheUserMenu.vue";
import { RouteRecordNormalized } from "vue-router";

export default defineComponent({
  name: "TheSidebar",
  components: { SidebarLink, TheUserMenu },
  mixins: [authorityMixin],
  data() {
    return {
      showUsers: true,
      showBatch: true,
      usersActiveRoutes: [
        "users",
        "user",
        "userDetails",
        "newUser",
        "portalUsers",
        "portalUser",
        "portalUserDetails",
      ],
      transactionActiveRoutes: ["transaction", "transactions", "batches"],
    };
  },
  watch: {
    $route(to: RouteRecordNormalized) {
      if (to && to.name) {
        if (!this.usersActiveRoutes.includes(to.name as string)) {
          this.showUsers = false;
        }
        if (!this.transactionActiveRoutes.includes(to.name as string)) {
          this.showBatch = false;
        }
      }
    },
  },
});
