/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CampaignChecklist
 */
export interface CampaignChecklist {
    /**
     * 
     * @type {boolean}
     * @memberof CampaignChecklist
     */
    audited?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignChecklist
     */
    campaignImageAccepted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignChecklist
     */
    campaignSignedOff?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignChecklist
     */
    campaignVideoAccepted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignChecklist
     */
    documentAccepted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignChecklist
     */
    reviewCompleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignChecklist
     */
    sectionContentComplete?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignChecklist
     */
    sectionImagesAccepted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignChecklist
     */
    valued?: boolean;
}

export function CampaignChecklistFromJSON(json: any): CampaignChecklist {
    return CampaignChecklistFromJSONTyped(json, false);
}

export function CampaignChecklistFromJSONTyped(json: any, ignoreDiscriminator: boolean): CampaignChecklist {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'audited': !exists(json, 'audited') ? undefined : json['audited'],
        'campaignImageAccepted': !exists(json, 'campaignImageAccepted') ? undefined : json['campaignImageAccepted'],
        'campaignSignedOff': !exists(json, 'campaignSignedOff') ? undefined : json['campaignSignedOff'],
        'campaignVideoAccepted': !exists(json, 'campaignVideoAccepted') ? undefined : json['campaignVideoAccepted'],
        'documentAccepted': !exists(json, 'documentAccepted') ? undefined : json['documentAccepted'],
        'reviewCompleted': !exists(json, 'reviewCompleted') ? undefined : json['reviewCompleted'],
        'sectionContentComplete': !exists(json, 'sectionContentComplete') ? undefined : json['sectionContentComplete'],
        'sectionImagesAccepted': !exists(json, 'sectionImagesAccepted') ? undefined : json['sectionImagesAccepted'],
        'valued': !exists(json, 'valued') ? undefined : json['valued'],
    };
}

export function CampaignChecklistToJSON(value?: CampaignChecklist | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'audited': value.audited,
        'campaignImageAccepted': value.campaignImageAccepted,
        'campaignSignedOff': value.campaignSignedOff,
        'campaignVideoAccepted': value.campaignVideoAccepted,
        'documentAccepted': value.documentAccepted,
        'reviewCompleted': value.reviewCompleted,
        'sectionContentComplete': value.sectionContentComplete,
        'sectionImagesAccepted': value.sectionImagesAccepted,
        'valued': value.valued,
    };
}


