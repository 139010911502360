<template>
  <div class="container-filters mt-4 mb-3">
    <el-form
      @validate="onValidate"
      :model="filter"
      :rules="rules"
      ref="filter-form"
      @change="$emit('update:investmentFilter', filter)"
    >
      <div class="row">
        <div class="col-4">
          <el-form-item
            :label="$t('investment.filter.investor')"
            prop="investor"
          >
            <el-input v-model="filter.investor"></el-input>
          </el-form-item>
          <el-form-item :label="$t('investment.filter.status')">
            <el-select
              v-model="filter.statuses"
              class="w-100"
              multiple
              collapse-tags
            >
              <el-option
                v-for="(status, key) in statuses"
                :key="key"
                :label="$enums.investment.status(status)"
                :value="status"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="col-4">
          <label class="el-form-item__label">{{
            $t("investment.filter.committedAmount")
          }}</label>
          <div class="d-flex gap-2">
            <el-form-item prop="committedAmountFrom">
              <el-input
                v-model.number="filter.committedAmountFrom"
                :placeholder="$t('filter.from')"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="committedAmountTo">
              <el-input
                v-model.number="filter.committedAmountTo"
                :placeholder="$t('filter.to')"
              >
              </el-input>
            </el-form-item>
          </div>
          <label class="el-form-item__label">{{
            $t("investment.filter.receivedAmount")
          }}</label>
          <div class="d-flex gap-2">
            <el-form-item prop="receivedAmountFrom">
              <el-input
                v-model.number="filter.receivedAmountFrom"
                :placeholder="$t('filter.from')"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="receivedAmountTo">
              <el-input
                v-model.number="filter.receivedAmountTo"
                :placeholder="$t('filter.to')"
              >
              </el-input>
            </el-form-item>
          </div>
        </div>
        <div class="col-4">
          <el-form-item :label="$t('investment.filter.createDate')">
            <div class="d-flex gap-2">
              <el-date-picker
                type="date"
                v-model="filter.createdDateFrom"
                format="DD/MM/YYYY"
                :placeholder="$t('filter.from')"
              ></el-date-picker>
              <el-date-picker
                type="date"
                v-model="filter.createdDateTo"
                format="DD/MM/YYYY"
                :placeholder="$t('filter.to')"
              ></el-date-picker>
            </div>
          </el-form-item>
          <el-form-item
            :label="$t('investment.filter.campaignId')"
            prop="campaignId"
          >
            <el-input v-model="filter.campaignId"></el-input>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
  <div class="mb-5">
    <el-button type="primary" @click="submit" :disabled="!isFormValid">
      {{ $t("apply") }}
    </el-button>
    <el-button type="default" @click="resetFilter">
      {{ $t("filter.reset") }}
    </el-button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ElementForm, InputLength } from "@/util/validation";
import { Filter } from "@/views/investment/InvestmentList.vue";
import { InvestmentStatus } from "@/api";

export default defineComponent({
  emits: ["update:investmentFilter", "submit"],
  props: ["investmentFilter"],
  data() {
    return {
      isFormValid: true,
      filter: {} as Filter,
      statuses: [] as InvestmentStatus[],
      rules: {
        investor: [new InputLength(100)],
        campaignId: [new InputLength(10)],
      },
    };
  },
  async mounted() {
    this.filter = this.investmentFilter;
    this.statuses = Object.values(InvestmentStatus);
  },
  methods: {
    submit() {
      const form = this.$refs["filter-form"] as ElementForm;
      form.validate(async (valid) => {
        if (valid) {
          this.$emit("submit");
        }
      });
    },
    onValidate(prop: string, isVal: boolean) {
      this.isFormValid = isVal;
    },
    resetFilter() {
      this.filter = {} as Filter;
      this.$emit("update:investmentFilter", this.filter);
      this.$emit("submit");
    },
  },
});
</script>
