
import { defineComponent, inject } from "vue";
import Notes from "@/views/components/Notes.vue";
import { NoteRequest, PageNoteItem, PreScreeningNoteType } from "@/api";
import { authorityMixin, PageRequest } from "@/util";
import { preScreeningApi } from "@/resources";
import { mapState } from "vuex";

export default defineComponent({
  name: "PreScreeningFollowUpNotes",
  components: { Notes },
  mixins: [authorityMixin],
  data() {
    return {
      preScreeningId: inject("preScreeningId") as number,
    };
  },
  computed: {
    ...mapState("account", ["profile"]),
  },
  methods: {
    loadNotes(pageRequest: PageRequest): Promise<PageNoteItem> {
      return preScreeningApi.preScreeningNotes({
        id: this.preScreeningId,
        type: PreScreeningNoteType.FOLLOW_UP,
        ...pageRequest,
      });
    },
    createNote(noteRequest: NoteRequest): Promise<void> {
      return preScreeningApi.createPreScreeningNote({
        id: this.preScreeningId,
        type: PreScreeningNoteType.FOLLOW_UP,
        noteRequest: noteRequest,
      });
    },
    deleteNote(noteId: number): Promise<void> {
      return preScreeningApi.deletePreScreeningNote({
        id: noteId,
      });
    },
    updateNote(noteId: number, noteRequest: NoteRequest) {
      return preScreeningApi.updatePreScreeningNote({
        id: noteId,
        noteRequest: noteRequest,
      });
    },
  },
});
