/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CommonFeeSettingsItem,
    CommonFeeSettingsItemFromJSON,
    CommonFeeSettingsItemFromJSONTyped,
    CommonFeeSettingsItemToJSON,
    InvestorFeeSettingsItem,
    InvestorFeeSettingsItemFromJSON,
    InvestorFeeSettingsItemFromJSONTyped,
    InvestorFeeSettingsItemToJSON,
    InvestorIndividualLimitSettingsItem,
    InvestorIndividualLimitSettingsItemFromJSON,
    InvestorIndividualLimitSettingsItemFromJSONTyped,
    InvestorIndividualLimitSettingsItemToJSON,
    InvestorLegalLimitSettingsItem,
    InvestorLegalLimitSettingsItemFromJSON,
    InvestorLegalLimitSettingsItemFromJSONTyped,
    InvestorLegalLimitSettingsItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface SettingsItem
 */
export interface SettingsItem {
    /**
     * 
     * @type {CommonFeeSettingsItem}
     * @memberof SettingsItem
     */
    commonFee: CommonFeeSettingsItem;
    /**
     * 
     * @type {InvestorFeeSettingsItem}
     * @memberof SettingsItem
     */
    investorFee: InvestorFeeSettingsItem;
    /**
     * 
     * @type {InvestorIndividualLimitSettingsItem}
     * @memberof SettingsItem
     */
    investorIndividualLimit: InvestorIndividualLimitSettingsItem;
    /**
     * 
     * @type {InvestorLegalLimitSettingsItem}
     * @memberof SettingsItem
     */
    investorLegalLimit: InvestorLegalLimitSettingsItem;
}

export function SettingsItemFromJSON(json: any): SettingsItem {
    return SettingsItemFromJSONTyped(json, false);
}

export function SettingsItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingsItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'commonFee': CommonFeeSettingsItemFromJSON(json['commonFee']),
        'investorFee': InvestorFeeSettingsItemFromJSON(json['investorFee']),
        'investorIndividualLimit': InvestorIndividualLimitSettingsItemFromJSON(json['investorIndividualLimit']),
        'investorLegalLimit': InvestorLegalLimitSettingsItemFromJSON(json['investorLegalLimit']),
    };
}

export function SettingsItemToJSON(value?: SettingsItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'commonFee': CommonFeeSettingsItemToJSON(value.commonFee),
        'investorFee': InvestorFeeSettingsItemToJSON(value.investorFee),
        'investorIndividualLimit': InvestorIndividualLimitSettingsItemToJSON(value.investorIndividualLimit),
        'investorLegalLimit': InvestorLegalLimitSettingsItemToJSON(value.investorLegalLimit),
    };
}


