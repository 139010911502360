
import { computed, defineComponent } from "vue";
import TabLink from "@/components/TabLink.vue";
import { BusinessItem, LegalCommerceItem } from "@/api";
import { businessApi } from "@/resources";
import Headline from "@/components/Headline.vue";
import BackLink from "@/components/BackLink.vue";
import { authorityMixin } from "@/util";
import { watchBusinessUpdate } from "@/util/ws";

export default defineComponent({
  name: "BusinessView",
  mixins: [authorityMixin],
  components: { Headline, TabLink, BackLink },
  props: ["id"],
  provide() {
    return {
      businessId: computed(() => this.id),
      legal: computed(() => (this.business.legal ? this.business.legal : {})),
      address: computed(() =>
        this.business.address ? this.business.address : {}
      ),
      finance: computed(() =>
        this.business.finance ? this.business.finance : {}
      ),
      commerce: computed(() => (this.commerce.commerce ? this.commerce : {})),
    };
  },
  data() {
    return {
      business: {} as BusinessItem,
      commerce: {} as LegalCommerceItem,
      businessUpdatedSubscription: watchBusinessUpdate(this.id, () => {
        this.$notify.info(this.$t("business.updated"));
        this.fetchBusinessData();
      }),
    };
  },
  async beforeRouteLeave() {
    this.businessUpdatedSubscription.unsubscribe();
  },
  async mounted() {
    await this.fetchBusinessData();
  },
  methods: {
    async fetchBusinessData() {
      await Promise.all([this.fetchBusiness(), this.fetchCommerce()]);
    },
    async fetchBusiness() {
      this.business = await businessApi.business({ id: this.id });
    },
    async fetchCommerce() {
      this.commerce = await businessApi.commerce({ id: this.id });
    },
  },
});
