
import { defineComponent, inject } from "vue";
import {
  ActorType,
  AttachmentListItem,
  CampaignAttachmentType,
  CampaignItem,
} from "@/api";
import { Authority, authorityMixin } from "@/util";
import { campaignApi } from "@/resources";
import Attachments from "@/views/components/Attachments.vue";
import { isConstraintViolation, isProblem } from "@/resources/problem";
import { mapState } from "vuex";
import { AccountState } from "@/store/account";

export default defineComponent({
  name: "CampaignDocuments",
  mixins: [authorityMixin],
  components: { Attachments },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      campaign: inject("campaign") as CampaignItem,
      documents: [
        {
          type: CampaignAttachmentType.SPV,
          attachments: [] as AttachmentListItem[],
        },
        {
          type: CampaignAttachmentType.AGREEMENT,
          attachments: [] as AttachmentListItem[],
        },
      ],
    };
  },
  async mounted() {
    for (let document of this.documents) {
      await this.fetchAttachments(document.type);
    }
  },
  computed: {
    ...mapState("account", {
      userId(state: AccountState) {
        return state.profile?.id;
      },
    }),
  },
  methods: {
    async fetchAttachments(type: CampaignAttachmentType) {
      const document = this.documents.find((document) => document.type == type);
      if (document) {
        document.attachments = await campaignApi.campaignAttachments({
          id: Number(this.id),
          type: type,
        });
      }
    },
    async loadAttachment(attachmentId: number): Promise<Blob> {
      return await campaignApi.campaignAttachment({
        id: Number(this.id),
        attachmentId: attachmentId,
      });
    },
    async uploadAttachment(file: File, type: CampaignAttachmentType) {
      return await campaignApi.uploadCampaignAttachment({
        id: Number(this.id),
        file: file,
        type: type,
      });
    },
    deleteEnabled(attachment: AttachmentListItem): boolean {
      return (
        this.userId == attachment.actorId &&
        ActorType.BO_USER === attachment.actorType &&
        this.uploadEnabled() &&
        !this.$enums.campaign.isTerminal(this.campaign.status)
      );
    },
    uploadEnabled(): boolean {
      return this.hasAuthority(Authority.CAMPAIGN_WRITE);
    },
    async deleteMethod(attachmentId: number, type: CampaignAttachmentType) {
      try {
        await campaignApi.deleteCampaignAttachment({
          id: Number(this.id),
          attachmentId: attachmentId,
        });
        const document = this.documents.find(
          (document) => document.type == type
        );
        if (document) {
          document.attachments = document.attachments.filter(
            (attachment) => attachment.id != attachmentId
          );
        }
      } catch (e) {
        if (isConstraintViolation(e)) {
          e.violations.forEach((violation) =>
            this.$notify.error(this.$t(violation.message))
          );
        } else if (isProblem(e)) {
          this.$notify.error(this.$t(e.detail));
        }
      }
    },
  },
});
