
import { defineComponent, PropType } from "vue";
import { PageLegalPersonItem } from "@/api";
import { authorityMixin, PageRequest, pageSortMixin } from "@/util";
import TableFooter from "@/components/TableFooter.vue";

export default defineComponent({
  name: "LegalPersons",
  mixins: [pageSortMixin, authorityMixin],
  components: { TableFooter },
  props: {
    page: {
      type: Object as PropType<PageLegalPersonItem>,
      required: true,
    },
    pageRequest: {
      type: Object as PropType<PageRequest>,
      required: true,
    },
    fetchPersonsData: {
      type: Function,
      required: true,
    },
  },
  mounted() {
    this.fetchPersonsData();
  },
  methods: {
    fetchData() {
      this.fetchPersonsData();
    },
  },
});
