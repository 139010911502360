<template>
  <div class="container-auth text-center">
    <h2 class="text-center">{{ $t("auth.registrationInvalidLink") }}</h2>
    <div class="d-flex justify-content-center mt-4">
      <el-button type="primary" @click="$router.push('login')">{{
        $t("back")
      }}</el-button>
    </div>
  </div>
</template>
