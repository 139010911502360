/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Country,
    CountryFromJSON,
    CountryFromJSONTyped,
    CountryToJSON,
    StartPage,
    StartPageFromJSON,
    StartPageFromJSONTyped,
    StartPageToJSON,
} from './';

/**
 * 
 * @export
 * @interface Role
 */
export interface Role {
    /**
     * 
     * @type {Array<string>}
     * @memberof Role
     */
    authorities: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    name: string;
    /**
     * 
     * @type {Country}
     * @memberof Role
     */
    residenceCountry: Country;
    /**
     * 
     * @type {StartPage}
     * @memberof Role
     */
    startPage: StartPage;
}

export function RoleFromJSON(json: any): Role {
    return RoleFromJSONTyped(json, false);
}

export function RoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Role {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'authorities': json['authorities'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'name': json['name'],
        'residenceCountry': CountryFromJSON(json['residenceCountry']),
        'startPage': StartPageFromJSON(json['startPage']),
    };
}

export function RoleToJSON(value?: Role | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'authorities': value.authorities,
        'description': value.description,
        'name': value.name,
        'residenceCountry': CountryToJSON(value.residenceCountry),
        'startPage': StartPageToJSON(value.startPage),
    };
}


