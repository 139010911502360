<template>
  <div class="card">
    <div class="card-main">
      <div class="d-flex justify-content-between align-items-start">
        <BackLink />
      </div>
      <Headline v-if="accreditation.id" :class="'align-items-start'">
        <div class="h1-sm" style="display: inline">
          {{ accreditation.investor.name + " " + accreditation.id }}
        </div>
        <template v-slot:details>
          <Status
            :color="$enums.accreditation.statusColor(accreditation.status)"
            size="medium"
          >
            {{ $enums.accreditation.status(accreditation.status) }}
          </Status>
        </template>
        <template
          v-slot:actions
          v-if="
            accreditation.id &&
            hasAuthority(authorities.ACCREDITATION_OPERATION_WRITE)
          "
        >
          <el-dropdown trigger="click">
            <el-button type="default">
              {{ $t("actions")
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <template #dropdown>
              <el-dropdown-item
                v-for="status in accreditationStatuses"
                v-bind:key="status"
              >
                <template
                  v-if="
                    accreditation.id &&
                    accreditationState.nextStatuses &&
                    hasAuthority(authorities.ACCREDITATION_OPERATION_WRITE)
                  "
                >
                  <OkCancelDialog
                    :dialogDisabled="
                      !accreditationState.nextStatuses.includes(status)
                    "
                    :header="$t('accreditation.' + status + '.header')"
                    :text="$t('accreditation.' + status + '.text')"
                    :info-message="$t('accreditation.' + status + '.info')"
                    :onOk="() => changeAccreditationStatus(status)"
                    @update="fetchAccreditationData"
                  />
                </template>
              </el-dropdown-item>
            </template>
          </el-dropdown>
        </template>
      </Headline>
    </div>
    <div class="card-foot">
      <ul class="nav nav-tabs mb-3">
        <TabLink to="accreditationGeneralInfo"
          >{{ $t("accreditation.tabs.general") }}
        </TabLink>
        <li
          v-if="
            hasAuthority(authorities.INVESTOR_READ) &&
            accreditation.investor?.id
          "
          class="nav-item"
        >
          <router-link
            v-slot="{ navigate }"
            custom
            :to="{
              name: 'accreditationInvestor',
              params: { investorId: accreditation.investor.id },
            }"
          >
            <button
              class="nav-link"
              :class="investorOpened && 'active'"
              @click="navigate"
            >
              {{ $t("accreditation.tabs.investor") }}
            </button>
          </router-link>
        </li>
        <TabLink to="accreditationAttachments"
          >{{ $t("accreditation.tabs.attachments") }}
        </TabLink>
      </ul>
    </div>
    <CollapseTransition
      v-if="
        hasAuthority(authorities.INVESTOR_READ) && accreditation.investor?.id
      "
    >
      <div class="card-foot sub_tab_foot" v-show="investorOpened">
        <ul
          class="nav nav-tabs mb-3"
          v-if="accreditation.investorType === 'INDIVIDUAL'"
        >
          <TabLink
            to="accreditationInvestorGeneralInfo"
            :props="{ investorId: accreditation.investor.id }"
          >
            {{ $t("investor.tabs.generalInfo") }}
          </TabLink>
          <TabLink
            to="accreditationInvestorContactDetails"
            :props="{ investorId: accreditation.investor.id }"
          >
            {{ $t("investor.tabs.contactDetails") }}
          </TabLink>
          <TabLink
            to="accreditationInvestorOccupationIncome"
            :props="{ investorId: accreditation.investor.id }"
          >
            {{ $t("investor.tabs.occupationIncome") }}
          </TabLink>
          <TabLink
            to="accreditationInvestorBankDetails"
            :props="{ investorId: accreditation.investor.id }"
          >
            {{ $t("investor.tabs.bankDetails") }}
          </TabLink>
          <TabLink
            to="accreditationInvestorAttachments"
            :props="{ investorId: accreditation.investor.id }"
          >
            {{ $t("investor.tabs.attachments") }}
          </TabLink>
        </ul>

        <ul class="nav nav-tabs mb-3" v-else>
          <TabLink
            to="accreditationInvestorGeneralInfo"
            :props="{ investorId: accreditation.investor.id }"
          >
            {{ $t("investor.tabs.generalInfo") }}
          </TabLink>
          <TabLink
            to="accreditationInvestorContactDetails"
            :props="{ investorId: accreditation.investor.id }"
          >
            {{ $t("investor.tabs.contactDetails") }}
          </TabLink>
          <TabLink
            to="accreditationInvestorFinancialInfo"
            :props="{ investorId: accreditation.investor.id }"
          >
            {{ $t("investor.tabs.financialInfo") }}
          </TabLink>
          <TabLink
            to="accreditationInvestorBankDetails"
            :props="{ investorId: accreditation.investor.id }"
          >
            {{ $t("investor.tabs.bankDetails") }}
          </TabLink>
          <TabLink
            to="accreditationInvestorOwnershipInfo"
            :props="{ investorId: accreditation.investor.id }"
          >
            {{ $t("investor.tabs.ownership") }}
          </TabLink>
          <TabLink
            to="accreditationInvestorAttachments"
            :props="{ investorId: accreditation.investor.id }"
          >
            {{ $t("investor.tabs.attachments") }}
          </TabLink>
        </ul>
      </div>
    </CollapseTransition>
  </div>
  <div class="tab-content">
    <div class="tab-pane active">
      <router-view @update="fetchAccreditationData"></router-view>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import TabLink from "@/components/TabLink.vue";
import {
  AccreditationItem,
  AccreditationItemHistory,
  AccreditationStatus,
  BackOfficeFlowEntityStateAccreditationStatus,
} from "@/api";
import { accreditationApi, accreditationOperationApi } from "@/resources";
import Headline from "@/components/Headline.vue";
import BackLink from "@/components/BackLink.vue";
import { authorityMixin } from "@/util";
import Status from "@/components/Status.vue";
import OkCancelDialog from "@/views/components/OkCancelDialog.vue";
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";

export default defineComponent({
  name: "AccreditationView",
  mixins: [authorityMixin],
  components: {
    Headline,
    TabLink,
    BackLink,
    Status,
    OkCancelDialog,
    CollapseTransition,
  },
  props: ["id"],
  provide() {
    return {
      accreditationId: computed(() => this.id),
      accreditation: computed(() =>
        this.accreditation.id ? this.accreditation : {}
      ),
      accreditationHistory: computed(() =>
        this.accreditationHistory.status ? this.accreditationHistory : {}
      ),
    };
  },
  data() {
    return {
      accreditation: {} as AccreditationItem,
      accreditationHistory: {} as AccreditationItemHistory,
      accreditationState: {} as BackOfficeFlowEntityStateAccreditationStatus,
      accreditationStatuses: Object.values(AccreditationStatus).filter(
        (status) =>
          status !== AccreditationStatus.DRAFT &&
          status !== AccreditationStatus.RECEIVED
      ),
    };
  },
  async mounted() {
    await this.fetchAccreditationData();
  },
  methods: {
    async fetchAccreditationData() {
      await Promise.all([
        this.fetchAccreditation(),
        this.fetchAccreditationState(),
        this.fetchAccreditationHistory(),
      ]);
    },
    async fetchAccreditation() {
      this.accreditation = await accreditationApi.accreditation({
        id: this.id,
      });
    },
    async fetchAccreditationState() {
      this.accreditationState = await accreditationApi.getAccreditationState({
        id: this.id,
      });
    },
    async fetchAccreditationHistory() {
      this.accreditationHistory = await accreditationApi.accreditationHistory({
        id: this.id,
      });
    },
    async changeAccreditationStatus(status: AccreditationStatus) {
      await accreditationOperationApi.changeAccreditationStatus({
        id: this.id,
        backOfficeFlowStatusOperationRequestAccreditationStatus: {
          status: status,
        },
      });
    },
  },
  computed: {
    investorOpened(): boolean {
      return this.$route.name
        ? this.$route.name.toString().startsWith("accreditationInvestor")
        : false;
    },
  },
});
</script>
<style lang="scss" scoped>
@import "src/styles/tabs";
</style>
