
import { defineComponent, inject } from "vue";
import { Currency, Investor } from "@/api";

export default defineComponent({
  name: "InvestorOccupationIncome",
  data() {
    return {
      investor: inject("investor") as Investor,
    };
  },
  computed: {
    currency(): Currency {
      return this.investor.individual?.income?.currency ?? Currency.OMR;
    },
  },
});
