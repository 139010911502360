<template>
  <el-dropdown-item :disabled="dialogDisabled" @click="dialogVisible = true">
    {{ $t("campaign.approve.header") }}
  </el-dropdown-item>

  <teleport to="body">
    <el-dialog
      :title="$t('campaign.approve.text')"
      v-model="dialogVisible"
      :before-close="cancel"
    >
      <el-form
        :model="form"
        ref="form"
        :rules="rules"
        @validate="onValidate"
        v-loading="loading"
      >
        <el-form-item prop="amount" :label="$t('campaign.approvedAmount')">
          <el-input v-model="form.amount">
            <template #prepend>
              <font-awesome-icon :icon="['fas', 'dollar-sign']" />
            </template>
          </el-input>
        </el-form-item>
        <el-form-item
          prop="daysDuration"
          :label="$t('campaign.approvedDuration')"
        >
          <el-input v-model.number="form.daysDuration"></el-input>
        </el-form-item>
        <el-form-item
          prop="minimumFundingPercent"
          :label="$t('campaign.minimumFundingPercent')"
        >
          <el-input v-model="form.minimumFundingPercent">
            <template #prepend>
              <font-awesome-icon :icon="['fas', 'percent']" />
            </template>
          </el-input>
        </el-form-item>
        <el-form-item
          prop="minimumInvestment"
          :label="$t('campaign.minimumInvestment')"
        >
          <el-input v-model="form.minimumInvestment">
            <template #prepend>
              <font-awesome-icon :icon="['fas', 'dollar-sign']" />
            </template>
          </el-input>
        </el-form-item>
        <el-form-item
          prop="overRaiseAmount"
          :label="$t('campaign.overRaiseAmount')"
        >
          <el-input v-model="form.overRaiseAmount">
            <template #prepend>
              <font-awesome-icon :icon="['fas', 'dollar-sign']" />
            </template>
          </el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button :disabled="loading" @click="cancel">{{
            $t("no")
          }}</el-button>
          <el-button
            :disabled="!isFormValid || loading"
            type="primary"
            @click="submit"
            >{{ $t("yes") }}</el-button
          >
        </span>
      </template>
    </el-dialog>
  </teleport>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { isConstraintViolation, isProblem } from "@/resources/problem";
import { ApproveCampaignOperationRequest, CampaignItem } from "@/api";
import {
  ElementForm,
  InputRequired,
  integerFormat,
  numberFormat,
  NumberMin,
  NumberRange,
} from "@/util/validation";
import { campaignOperationApi } from "@/resources";

export default defineComponent({
  name: "ApproveCampaignDialog",
  emits: ["update"],
  props: {
    campaign: {
      type: Object as PropType<CampaignItem>,
      required: true,
    },
    dialogDisabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dialogVisible: false,
      isFormValid: true,
      form: {
        overRaiseAmount: 0.0,
        minimumFundingPercent: 80.0,
      } as ApproveCampaignOperationRequest,
      loading: false,
      rules: {
        amount: [
          new InputRequired("validation.inputRequired"),
          numberFormat,
          new NumberMin(1.0),
        ],
        daysDuration: [
          new InputRequired("validation.inputRequired"),
          integerFormat,
          new NumberMin(1),
        ],
        minimumFundingPercent: [
          new InputRequired("validation.inputRequired"),
          numberFormat,
          new NumberRange(1.0, 100),
        ],
        overRaiseAmount: [
          new InputRequired("validation.inputRequired"),
          numberFormat,
          new NumberMin(0),
        ],
        minimumInvestment: [
          new InputRequired("validation.inputRequired"),
          numberFormat,
          {
            validator: (
              rule: Record<string, unknown>,
              value: string,
              callback: (error?: Error) => unknown
            ) => {
              const constraints = (this
                .form as unknown) as ApproveCampaignOperationRequest;
              const min = Number(1.0);
              if (
                constraints.amount &&
                (constraints.amount <= Number.parseFloat(value) ||
                  Number.parseFloat(value) < min)
              ) {
                callback(
                  new Error(
                    this.$t("validation.integerRange", {
                      min: min,
                      max: constraints.amount,
                    })
                  )
                );
              } else {
                callback();
              }
            },
          },
        ],
      },
    };
  },
  methods: {
    cancel() {
      this.dialogVisible = false;
      this.form = {
        overRaiseAmount: 0.0,
        minimumFundingPercent: 80.0,
      } as ApproveCampaignOperationRequest;
    },
    onValidate(prop: string, isVal: boolean) {
      this.isFormValid = isVal;
    },
    async submit() {
      if (this.loading) {
        return;
      }
      const form = this.$refs["form"] as ElementForm;
      if (await form.validate()) {
        try {
          this.loading = true;
          await campaignOperationApi.approveCampaign({
            id: this.campaign.id,
            approveCampaignOperationRequest: this.form,
          });
          this.$notify.info(this.$t("campaign.approve.info"));
        } catch (e) {
          if (isConstraintViolation(e)) {
            e.violations.forEach((violation) =>
              this.$notify.error(this.$t(violation.message))
            );
          } else if (isProblem(e)) {
            this.$notify.error(this.$t(e.detail));
          }
        } finally {
          this.loading = false;
        }
        this.$emit("update");
        this.cancel();
      }
    },
  },
});
</script>
