
import { defineComponent } from "vue";
import { userApi } from "@/resources";
import { isProblem } from "@/resources/problem";

export default defineComponent({
  name: "DeactivateUserDialog",
  emits: ["update"],
  props: ["id", "givenName", "lastName"],
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    async deactivateUser() {
      try {
        await userApi.deactivateUser({
          id: this.id,
        });
        this.$notify.info(
          this.$t("user.deactivate", {
            givenName: this.givenName,
            lastName: this.lastName,
          })
        );
        this.$emit("update");
        this.dialogVisible = false;
      } catch (e) {
        if (isProblem(e)) {
          this.$notify.error(e.detail ?? e.title);
        }
      }
    },
  },
});
