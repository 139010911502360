/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IndividualPersonItem
 */
export interface IndividualPersonItem {
    /**
     * 
     * @type {Date}
     * @memberof IndividualPersonItem
     */
    birthDate: Date;
    /**
     * 
     * @type {string}
     * @memberof IndividualPersonItem
     */
    birthPlace?: string;
    /**
     * 
     * @type {string}
     * @memberof IndividualPersonItem
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof IndividualPersonItem
     */
    givenName: string;
    /**
     * 
     * @type {string}
     * @memberof IndividualPersonItem
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof IndividualPersonItem
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof IndividualPersonItem
     */
    residenceCountry: string;
}

export function IndividualPersonItemFromJSON(json: any): IndividualPersonItem {
    return IndividualPersonItemFromJSONTyped(json, false);
}

export function IndividualPersonItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): IndividualPersonItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'birthDate': (new Date(json['birthDate'])),
        'birthPlace': !exists(json, 'birthPlace') ? undefined : json['birthPlace'],
        'email': json['email'],
        'givenName': json['givenName'],
        'lastName': json['lastName'],
        'phone': json['phone'],
        'residenceCountry': json['residenceCountry'],
    };
}

export function IndividualPersonItemToJSON(value?: IndividualPersonItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'birthDate': (value.birthDate.toISOString().substr(0,10)),
        'birthPlace': value.birthPlace,
        'email': value.email,
        'givenName': value.givenName,
        'lastName': value.lastName,
        'phone': value.phone,
        'residenceCountry': value.residenceCountry,
    };
}


