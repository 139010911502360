
import { defineComponent, PropType } from "vue";
import { AttachmentListItem } from "@/api";
import ButtonOkCancelDialog from "@/views/components/ButtonOkCancelDialog.vue";

export default defineComponent({
  name: "Attachment",
  components: { ButtonOkCancelDialog },
  emits: ["update"],
  props: {
    attachment: {
      type: Object as PropType<AttachmentListItem>,
      required: true,
    },
    isImage: {
      type: Boolean,
      required: true,
    },
    source: {
      type: Function,
      required: true,
    },
    deleteEnabled: {
      type: Boolean,
      default: false,
    },
    deleteMethod: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      sourceUrl: "" as string,
      loading: true,
    };
  },
  watch: {
    isImage(newValue, oldValue) {
      if (newValue && !oldValue) {
        this.updateSourceUrl();
      }
    },
  },
  async mounted() {
    if (this.isImage) {
      await this.updateSourceUrl();
    }
    this.loading = false;
  },
  methods: {
    async download() {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(await this.source(this.attachment.id));
      link.download = this.attachment.name;
      link.click();
    },
    async updateSourceUrl() {
      this.sourceUrl = URL.createObjectURL(
        await this.source(this.attachment.id)
      );
    },
    async deleteAttachment() {
      if (this.deleteMethod) {
        await this.deleteMethod(this.attachment.id);
      }
    },
  },
});
