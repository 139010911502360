/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PermanentAddress
 */
export interface PermanentAddress {
    /**
     * 
     * @type {string}
     * @memberof PermanentAddress
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof PermanentAddress
     */
    line1?: string;
    /**
     * 
     * @type {string}
     * @memberof PermanentAddress
     */
    line2?: string;
    /**
     * 
     * @type {string}
     * @memberof PermanentAddress
     */
    postalCode?: string;
}

export function PermanentAddressFromJSON(json: any): PermanentAddress {
    return PermanentAddressFromJSONTyped(json, false);
}

export function PermanentAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): PermanentAddress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'country': !exists(json, 'country') ? undefined : json['country'],
        'line1': !exists(json, 'line1') ? undefined : json['line1'],
        'line2': !exists(json, 'line2') ? undefined : json['line2'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
    };
}

export function PermanentAddressToJSON(value?: PermanentAddress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'country': value.country,
        'line1': value.line1,
        'line2': value.line2,
        'postalCode': value.postalCode,
    };
}


