
import { authorityMixin } from "@/util";
import HistoryPopover from "@/components/HistoryPopover.vue";
import { defineComponent } from "vue";
import {
  ExchangeRateSettingsData,
  ExchangeRateSettingsHistoryItem,
  ExchangeRateSettingsItem,
} from "@/api";
import { HistoryFormat } from "@/util/enum/HistoryFormat";
import { settingsApi } from "@/resources";
import {
  DecimalFormat,
  ElementForm,
  InputRequired,
  NumberRange,
} from "@/util/validation";
import { isConstraintViolation, isProblem } from "@/resources/problem";

export default defineComponent({
  name: "SettingsExchangeRate",
  mixins: [authorityMixin],
  components: { HistoryPopover },
  data() {
    return {
      exchangeRate: {} as ExchangeRateSettingsItem,
      exchangeRateHistory: {} as ExchangeRateSettingsHistoryItem,
      historyFormat: HistoryFormat,
      form: {} as ExchangeRateSettingsData,
      rules: {
        usdToOmr: [
          new InputRequired("validation.inputRequired"),
          new DecimalFormat(5, 6),
          new NumberRange(0.000001, 1000.0),
        ],
        usdToAed: [
          new InputRequired("validation.inputRequired"),
          new DecimalFormat(5, 6),
          new NumberRange(0.000001, 1000.0),
        ],
        usdToQar: [
          new InputRequired("validation.inputRequired"),
          new DecimalFormat(5, 6),
          new NumberRange(0.000001, 1000.0),
        ],
        usdToSar: [
          new InputRequired("validation.inputRequired"),
          new DecimalFormat(5, 6),
          new NumberRange(0.000001, 1000.0),
        ],
        usdToBhd: [
          new InputRequired("validation.inputRequired"),
          new DecimalFormat(5, 6),
          new NumberRange(0.000001, 1000.0),
        ],
        usdToKwd: [
          new InputRequired("validation.inputRequired"),
          new DecimalFormat(5, 6),
          new NumberRange(0.000001, 1000.0),
        ],
      },
      isFormValid: true,
      editable: false,
    };
  },
  watch: {
    exchangeRate() {
      Object.assign(this.form, this.exchangeRate);
    },
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchExchangeRate() {
      this.exchangeRate = await settingsApi.exchangeRates();
    },
    async fetchExchangeRateHistory() {
      this.exchangeRateHistory = await settingsApi.exchangeRatesHistory();
    },
    async fetchData() {
      await Promise.all([
        this.fetchExchangeRate(),
        this.fetchExchangeRateHistory(),
      ]);
    },
    cancel() {
      Object.assign(this.form, this.exchangeRate);
      this.editable = false;
    },
    onValidate(prop: string, isVal: boolean) {
      this.isFormValid = isVal;
    },
    edit() {
      this.editable = true;
      Object.assign(this.form, this.exchangeRate);
    },
    async submit() {
      const form = this.$refs["form"] as ElementForm;
      if (await form.validate()) {
        try {
          await settingsApi.updateExchangeRates({
            exchangeRateSettingsData: this.form,
          });
          this.editable = false;
          await this.fetchData();
        } catch (error) {
          if (isConstraintViolation(error)) {
            error.violations.forEach((violation) =>
              this.$notify.error(this.$t(violation.message))
            );
          } else if (isProblem(error)) {
            this.$notify.error(this.$t(error.detail));
          }
        }
      }
    },
  },
});
