import { i18n } from "@/util";
import { PreScreeningQuizKey, PreScreeningStatus } from "@/api";

export class PreScreening {
  static status(status: string): string {
    return i18n.global.t("preScreening.status." + status);
  }

  static duplicateStatus(status: string): string {
    return i18n.global.t("preScreening.duplicateStatus." + status);
  }

  static statusColor(status: PreScreeningStatus): string {
    switch (status) {
      case PreScreeningStatus.RECEIVED:
        return "#e78b2d";
      case PreScreeningStatus.REVIEWED:
        return "#0f7dbb";
      case PreScreeningStatus.APPROVED:
        return "#28e848";
      case PreScreeningStatus.DECLINED:
        return "#000000";
      case PreScreeningStatus.FOLLOW_UP:
        return "#7601f3";
      case PreScreeningStatus.PENDING:
        return "#ca0fd9";
      case PreScreeningStatus.CANCELLED:
        return "#8c2c2c";
      default:
        return "#7f7f7f";
    }
  }

  static quizKey(key: PreScreeningQuizKey): string {
    return i18n.global.t("preScreening.quiz.key." + key.toUpperCase());
  }

  static quizOrder(quizKey: PreScreeningQuizKey): number {
    switch (quizKey) {
      case PreScreeningQuizKey.GENERATE_REVENUE:
        return 1;
      case PreScreeningQuizKey.LAUNCHED_PRODUCT:
        return 3;
      case PreScreeningQuizKey.MONTHLY_RUNWAY:
        return 2;
      case PreScreeningQuizKey.MONEY_FUNDRAISED:
        return 10;
      case PreScreeningQuizKey.SUMMARY:
        return 5;
      case PreScreeningQuizKey.TRACTION:
        return 7;
      case PreScreeningQuizKey.MONEY_RAISED:
        return 4;
      case PreScreeningQuizKey.MONEY_WANTS_RAISE:
        return 6;
      case PreScreeningQuizKey.CAMPAIGN_TYPE:
        return 8;
      case PreScreeningQuizKey.NUMBER_OF_EMPLOYEES:
        return 9;
      default:
        return 10;
    }
  }
}
