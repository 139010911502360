<template>
  <div class="container ms-0 mb-0" v-if="verification">
    <el-form ref="comment" :model="commentForm">
      <div class="row mb-2">
        <div class="col">
          <el-form-item prop="comment">
            <el-input
              :disabled="!editable"
              :maxlength="1000"
              :placeholder="$t('campaign.fundraiserVerification.placeholder')"
              show-word-limit
              type="textarea"
              :autosize="{ minRows: 5 }"
              resize="vertical"
              v-model="commentForm.comment"
            />
          </el-form-item>
        </div>
        <div class="col">
          <el-button
            class="post-button"
            size="small"
            type="default"
            @click="edit"
            v-show="!editable"
            >{{ $t("edit") }}</el-button
          >
          <el-button
            size="small"
            style="margin-left: 0"
            @click="cancel"
            v-show="editable"
          >
            {{ $t("cancel") }}
          </el-button>
          <el-button
            size="small"
            type="primary"
            v-show="editable"
            @click="saveComment"
          >
            {{ $t("save") }}
          </el-button>
        </div>
      </div>
    </el-form>

    <div class="row mb-3">
      <div class="col fw-bolder mb-3">
        {{ $t("campaign.fundraiserVerification.complianceChecks") }}
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.fundraiserVerification.informationMatch") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.informationMatch"
            @decided="updateValue('informationMatch', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{
            $t("campaign.fundraiserVerification.acceptableRegistrationCountry")
          }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.acceptableRegistrationCountry"
            @decided="updateValue('acceptableRegistrationCountry', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.fundraiserVerification.validCertificate") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.validCertificate"
            @decided="updateValue('validCertificate', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.fundraiserVerification.acceptableCompanyType") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.acceptableCompanyType"
            @decided="updateValue('acceptableCompanyType', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.fundraiserVerification.validCertificateInCountry") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.validCertificateInCountry"
            @decided="updateValue('validCertificateInCountry', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.fundraiserVerification.activitiesMatches") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.activitiesMatches"
            @decided="updateValue('activitiesMatches', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.fundraiserVerification.clearShareholding") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.clearShareholding"
            @decided="updateValue('clearShareholding', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.fundraiserVerification.shareholdersCompletedKyc") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.shareholdersCompletedKyc"
            @decided="updateValue('shareholdersCompletedKyc', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.fundraiserVerification.hasOtherActivities") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.hasOtherActivities"
            @decided="updateValue('hasOtherActivities', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.fundraiserVerification.acceptableTargetAmount") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.acceptableTargetAmount"
            @decided="updateValue('acceptableTargetAmount', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.fundraiserVerification.riskAssessmentCompleted") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.riskAssessmentCompleted"
            @decided="updateValue('riskAssessmentCompleted', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.fundraiserVerification.riskRatingAcceptable") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.riskRatingAcceptable"
            @decided="updateValue('riskRatingAcceptable', $event)"
          />
        </div>
      </div>
    </div>
    <!--    -->
    <div class="row mb-3">
      <div class="col fw-bolder mb-3">
        {{ $t("campaign.fundraiserVerification.dataRoomChecks") }}
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{
            $t("campaign.fundraiserVerification.checkedRegistrationDocument")
          }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.checkedRegistrationDocument"
            @decided="updateValue('checkedRegistrationDocument', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.fundraiserVerification.checkedAssociationDocs") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.checkedAssociationDocs"
            @decided="updateValue('checkedAssociationDocs', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{
            $t("campaign.fundraiserVerification.checkedShareholderAgreement")
          }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.checkedShareholderAgreement"
            @decided="updateValue('checkedShareholderAgreement', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.fundraiserVerification.checkedAuditedFinancials") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.checkedAuditedFinancials"
            @decided="updateValue('checkedAuditedFinancials', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.fundraiserVerification.checkedValuationDocument") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.checkedValuationDocument"
            @decided="updateValue('checkedValuationDocument', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.fundraiserVerification.checkedCancelledCheque") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.checkedCancelledCheque"
            @decided="updateValue('checkedCancelledCheque', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.fundraiserVerification.checkedFounderProfiles") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.checkedFounderProfiles"
            @decided="updateValue('checkedFounderProfiles', $event)"
          />
        </div>
      </div>
    </div>
    <!--    -->
    <div class="row mb-3">
      <div class="col fw-bolder mb-3">
        {{ $t("campaign.fundraiserVerification.fitChecks") }}
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.fundraiserVerification.hasWebsite") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.hasWebsite"
            @decided="updateValue('hasWebsite', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.fundraiserVerification.socialMediaPresence") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.socialMediaPresence"
            @decided="updateValue('socialMediaPresence', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.fundraiserVerification.traction") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.traction"
            @decided="updateValue('traction', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.fundraiserVerification.valuableOffer") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.valuableOffer"
            @decided="updateValue('valuableOffer', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.fundraiserVerification.interestInMarket") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.interestInMarket"
            @decided="updateValue('interestInMarket', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.fundraiserVerification.fundraisingExperience") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.fundraisingExperience"
            @decided="updateValue('fundraisingExperience', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.fundraiserVerification.marketingExperience") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.marketingExperience"
            @decided="updateValue('marketingExperience', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.fundraiserVerification.willingInvestTimeEffort") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.willingInvestTimeEffort"
            @decided="updateValue('willingInvestTimeEffort', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.fundraiserVerification.understandCostsAndLimits") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.understandCostsAndLimits"
            @decided="updateValue('understandCostsAndLimits', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.fundraiserVerification.interestSectorForInvestor") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.interestSectorForInvestor"
            @decided="updateValue('interestSectorForInvestor', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.fundraiserVerification.clearStrategy") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.clearStrategy"
            @decided="updateValue('clearStrategy', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.fundraiserVerification.acceptableRepute") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.acceptableRepute"
            @decided="updateValue('acceptableRepute', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.fundraiserVerification.acceptableFinancials") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.acceptableFinancials"
            @decided="updateValue('acceptableFinancials', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{
            $t("campaign.fundraiserVerification.acceptableValuationAndTerms")
          }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.acceptableValuationAndTerms"
            @decided="updateValue('acceptableValuationAndTerms', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.fundraiserVerification.securedLeadInvestor") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.securedLeadInvestor"
            @decided="updateValue('securedLeadInvestor', $event)"
          />
        </div>
      </div>
    </div>
    <!--    -->
    <div class="row mb-3">
      <div class="col fw-bolder mb-3">
        {{ $t("campaign.fundraiserVerification.readinessChecks") }}
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.fundraiserVerification.nda") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.nda"
            @decided="updateValue('nda', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.fundraiserVerification.kybCompleted") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.kybCompleted"
            @decided="updateValue('kybCompleted', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{
            $t("campaign.fundraiserVerification.orientationSessionCompleted")
          }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.orientationSessionCompleted"
            @decided="updateValue('orientationSessionCompleted', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.fundraiserVerification.pricingAgreed") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.pricingAgreed"
            @decided="updateValue('pricingAgreed', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.fundraiserVerification.fundraisingBudgetAgreed") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.fundraisingBudgetAgreed"
            @decided="updateValue('fundraisingBudgetAgreed', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.fundraiserVerification.potentialRisks") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.potentialRisks"
            @decided="updateValue('potentialRisks', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.fundraiserVerification.fundraisingPlanCompleted") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.fundraisingPlanCompleted"
            @decided="updateValue('fundraisingPlanCompleted', $event)"
          />
        </div>
      </div>
    </div>
    <!--    -->
    <div class="row mb-3">
      <div class="col fw-bolder mb-3">
        {{ $t("campaign.fundraiserVerification.completeness") }}
      </div>
      <div class="row ms-0 mb-lg-0">
        <div class="col-6 fw-bolder">
          {{ $t("campaign.fundraiserVerification.verificationCompleted") }}
        </div>
        <div class="col wrap-text">
          <Decision
            :checked="verification.verificationCompleted"
            :disabled="
              !Campaign.FUNDRAISER_VERIFICATION_STATUSES.includes(
                campaign.status
              )
            "
            @decided="updateValue('verificationCompleted', $event)"
          />
        </div>
        <div class="col wrap-text">
          <el-date-picker
            v-model="verification.verificationDate"
            type="date"
            format="DD/MM/YYYY"
            size="small"
            disabled
          />
        </div>
      </div>
    </div>
    <el-button
      @click="completeFundraiserVerification"
      :disabled="!verification.verificationCompleted"
      type="primary"
      v-if="
        hasAuthority(authorities.CAMPAIGN_OPERATION_WRITE) &&
        state.nextStatuses &&
        campaign.status !== campaignStatuses.CANCELLATION_REQUEST &&
        state.nextStatuses.includes(campaignStatuses.CAMPAIGN_VERIFICATION)
      "
    >
      {{ $t("campaign.fundraiserVerification.complete") }}
    </el-button>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import {
  BackOfficeFlowEntityStateCampaignStatus,
  CampaignFundraiserVerification,
  CampaignItem,
  CampaignStatus,
} from "@/api";
import { campaignApi, campaignOperationApi } from "@/resources";
import Decision from "@/components/Decision.vue";
import { isConstraintViolation, isProblem } from "@/resources/problem";
import { InputLength } from "@/util/validation";
import { authorityMixin } from "@/util";
import { Campaign } from "@/util/enum/campaign";

interface CommentForm {
  comment: string | undefined;
}

export default defineComponent({
  name: "CampaignFundraiserVerification",
  computed: {
    Campaign() {
      return Campaign;
    },
  },
  mixins: [authorityMixin],
  components: { Decision },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      rules: {
        comment: [new InputLength(1000)],
      },
      verification: {} as CampaignFundraiserVerification,
      campaignStatuses: CampaignStatus,
      state: inject("state") as BackOfficeFlowEntityStateCampaignStatus,
      campaign: inject("campaign") as CampaignItem,
      editable: false,
      commentForm: {} as CommentForm,
    };
  },
  async mounted() {
    await this.fetchVerification();
  },
  methods: {
    async fetchVerification() {
      this.verification = await campaignApi.getCampaignFundraiserVerification({
        id: Number(this.id),
      });
      this.commentForm = { comment: this.verification.comment };
    },
    async updateValue(field: string, value: boolean | undefined) {
      try {
        if (
          value != undefined ||
          (value == undefined && !this.verification.verificationCompleted)
        ) {
          this.verification = await campaignApi.updateCampaignFundraiserVerification(
            {
              id: Number(this.id),
              campaignFundraiserVerification: {
                ...this.verification,
                [field]: value,
              },
            }
          );
        }
      } catch (error) {
        if (isConstraintViolation(error)) {
          error.violations.forEach((violation) =>
            this.$notify.error(this.$t(violation.message))
          );
        } else if (isProblem(error)) {
          this.$notify.error(this.$t(error.detail));
        }
      }
    },
    async saveComment() {
      try {
        this.verification = await campaignApi.updateCampaignFundraiserVerification(
          {
            id: Number(this.id),
            campaignFundraiserVerification: {
              ...this.verification,
              comment: this.commentForm.comment,
            },
          }
        );
        this.editable = false;
      } catch (e) {
        if (isConstraintViolation(e)) {
          e.violations.forEach((violation) =>
            this.$notify.error(this.$t(violation.message))
          );
        } else if (isProblem(e)) {
          this.$notify.error(this.$t(e.detail));
        }
      }
    },
    async completeFundraiserVerification() {
      await campaignOperationApi.campaignVerification({ id: Number(this.id) });
    },
    edit() {
      this.editable = true;
    },
    cancel() {
      this.commentForm.comment = this.verification.comment;
      this.editable = false;
    },
  },
});
</script>
