<template>
  <div class="d-flex flex-wrap align-items-center mb-4 gap-4">
    <h1 class="h2">
      <slot></slot>
    </h1>
    <div class="details">
      <slot name="details"></slot>
    </div>
    <div class="ms-auto">
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Headline",
});
</script>

<style scoped lang="scss">
@import "src/styles/variables";

h1 {
  font-family: $font-medium;
  font-size: 54px;
  letter-spacing: -1px;
  word-break: break-all;
}

.align-items-center.align-items-start {
  align-items: flex-start !important;
  .details {
    margin-top: 16px;
  }
  .ms-auto {
    margin-top: 11px;
  }
}

@media (max-width: $width-md) {
  h1 {
    font-size: 50px;
  }
}
</style>
