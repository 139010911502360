
import { defineComponent, PropType } from "vue";
import { ComponentSize } from "@/util/enum/ComponentSize";

export default defineComponent({
  name: "Status",
  props: {
    color: {
      type: String,
      required: true,
    },
    size: {
      type: String as PropType<ComponentSize>,
      required: false,
    },
  },
  computed: {
    isMedium(): boolean {
      return this.size == ComponentSize.MEDIUM;
    },
  },
});
