/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FlowActor,
    FlowActorFromJSON,
    FlowActorFromJSONTyped,
    FlowActorToJSON,
    IdNameEntry,
    IdNameEntryFromJSON,
    IdNameEntryFromJSONTyped,
    IdNameEntryToJSON,
} from './';

/**
 * 
 * @export
 * @interface FieldHistoryView
 */
export interface FieldHistoryView {
    /**
     * 
     * @type {Date}
     * @memberof FieldHistoryView
     */
    changedAt?: Date;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof FieldHistoryView
     */
    changedBy?: IdNameEntry;
    /**
     * 
     * @type {FlowActor}
     * @memberof FieldHistoryView
     */
    changedByActor?: FlowActor;
    /**
     * 
     * @type {object}
     * @memberof FieldHistoryView
     */
    newValue?: object;
    /**
     * 
     * @type {object}
     * @memberof FieldHistoryView
     */
    oldValue?: object;
}

export function FieldHistoryViewFromJSON(json: any): FieldHistoryView {
    return FieldHistoryViewFromJSONTyped(json, false);
}

export function FieldHistoryViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): FieldHistoryView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'changedAt': !exists(json, 'changed_at') ? undefined : (new Date(json['changed_at'])),
        'changedBy': !exists(json, 'changed_by') ? undefined : IdNameEntryFromJSON(json['changed_by']),
        'changedByActor': !exists(json, 'changed_by_actor') ? undefined : FlowActorFromJSON(json['changed_by_actor']),
        'newValue': !exists(json, 'new_value') ? undefined : json['new_value'],
        'oldValue': !exists(json, 'old_value') ? undefined : json['old_value'],
    };
}

export function FieldHistoryViewToJSON(value?: FieldHistoryView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'changed_at': value.changedAt === undefined ? undefined : (value.changedAt.toISOString()),
        'changed_by': IdNameEntryToJSON(value.changedBy),
        'changed_by_actor': FlowActorToJSON(value.changedByActor),
        'new_value': value.newValue,
        'old_value': value.oldValue,
    };
}


