/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CurrentAddress
 */
export interface CurrentAddress {
    /**
     * 
     * @type {string}
     * @memberof CurrentAddress
     */
    apartment?: string;
    /**
     * 
     * @type {string}
     * @memberof CurrentAddress
     */
    building?: string;
    /**
     * 
     * @type {string}
     * @memberof CurrentAddress
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof CurrentAddress
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof CurrentAddress
     */
    poBox?: string;
    /**
     * 
     * @type {string}
     * @memberof CurrentAddress
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CurrentAddress
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof CurrentAddress
     */
    street?: string;
}

export function CurrentAddressFromJSON(json: any): CurrentAddress {
    return CurrentAddressFromJSONTyped(json, false);
}

export function CurrentAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrentAddress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'apartment': !exists(json, 'apartment') ? undefined : json['apartment'],
        'building': !exists(json, 'building') ? undefined : json['building'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'poBox': !exists(json, 'poBox') ? undefined : json['poBox'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'street': !exists(json, 'street') ? undefined : json['street'],
    };
}

export function CurrentAddressToJSON(value?: CurrentAddress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'apartment': value.apartment,
        'building': value.building,
        'city': value.city,
        'country': value.country,
        'poBox': value.poBox,
        'postalCode': value.postalCode,
        'state': value.state,
        'street': value.street,
    };
}


