<template>
  <div class="container ms-0 mb-0" v-if="investorId">
    <div class="row ms-0 mb-3">
      <h4>{{ $t("investor.bankAccounts") }}</h4>
      <BankAccounts
        :page="bankAccountsPage"
        :pageRequest="bankAccountsPageRequest"
        :fetchBankAccountsData="fetchBankAccountsData"
      ></BankAccounts>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { Investor, PageBankAccountListItem } from "@/api";
import BankAccounts from "@/views/components/BankAccounts.vue";
import { investorApi } from "@/resources";
import { PageRequest } from "@/util";

export default defineComponent({
  name: "InvestorBankDetails",
  components: { BankAccounts },
  data() {
    return {
      investorId: inject("investorId") as number,
      investor: inject("investor") as Investor,
      bankAccountsPage: {} as PageBankAccountListItem,
      bankAccountsPageRequest: {} as PageRequest,
    };
  },
  methods: {
    async fetchBankAccountsData() {
      this.bankAccountsPage = await investorApi.investorBankAccounts({
        id: this.investorId,
        ...this.bankAccountsPageRequest,
      });
    },
  },
});
</script>
