/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PasswordChangeForm
 */
export interface PasswordChangeForm {
    /**
     * 
     * @type {string}
     * @memberof PasswordChangeForm
     */
    confirmNewPassword: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordChangeForm
     */
    newPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordChangeForm
     */
    oldPassword: string;
}

export function PasswordChangeFormFromJSON(json: any): PasswordChangeForm {
    return PasswordChangeFormFromJSONTyped(json, false);
}

export function PasswordChangeFormFromJSONTyped(json: any, ignoreDiscriminator: boolean): PasswordChangeForm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'confirmNewPassword': json['confirmNewPassword'],
        'newPassword': !exists(json, 'newPassword') ? undefined : json['newPassword'],
        'oldPassword': json['oldPassword'],
    };
}

export function PasswordChangeFormToJSON(value?: PasswordChangeForm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'confirmNewPassword': value.confirmNewPassword,
        'newPassword': value.newPassword,
        'oldPassword': value.oldPassword,
    };
}


