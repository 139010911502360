
import { defineComponent, inject } from "vue";
import {
  ActorType,
  AttachmentListItem,
  AttachmentType,
  Investment as InvestmentItem,
} from "@/api";
import { Authority, authorityMixin } from "@/util";
import Attachments from "@/views/components/Attachments.vue";
import { investmentApi } from "@/resources";
import { account, AccountState } from "@/store/account";
import { mapState } from "vuex";
import { isConstraintViolation, isProblem } from "@/resources/problem";
import { Investment } from "@/util/enum/investment";

export default defineComponent({
  name: "InvestmentAttachments",
  mixins: [authorityMixin, account],
  components: { Attachments },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      attachments: [] as AttachmentListItem[],
      investmentItem: inject("investment") as InvestmentItem,
    };
  },
  async mounted() {
    await this.fetchAttachments();
  },
  computed: {
    ...mapState("account", {
      userId(state: AccountState) {
        return state.profile?.id;
      },
    }),
  },
  methods: {
    async fetchAttachments() {
      this.attachments = await investmentApi.investmentAttachments({
        id: Number(this.id),
        type: AttachmentType.ATTACHMENT,
      });
    },
    async loadAttachment(attachmentId: number): Promise<Blob> {
      return await investmentApi.investmentAttachment({
        id: Number(this.id),
        attachmentId: attachmentId,
      });
    },
    async uploadAttachment(file: File) {
      return await investmentApi.uploadInvestmentAttachment({
        id: Number(this.id),
        file: file,
        type: AttachmentType.ATTACHMENT,
      });
    },
    deleteEnabled(attachment: AttachmentListItem): boolean {
      return (
        this.userId == attachment.actorId &&
        ActorType.BO_USER === attachment.actorType &&
        this.hasAuthority(Authority.INVESTMENT_WRITE) &&
        this.investmentItem.status &&
        !Investment.TERMINAL_STATUSES.includes(this.investmentItem.status)
      );
    },
    async deleteMethod(id: number) {
      try {
        await investmentApi.deleteInvestmentAttachment({
          id: Number(this.id),
          attachmentId: id,
        });
        this.attachments = this.attachments.filter(
          (attachment) => attachment.id != id
        );
      } catch (e) {
        if (isConstraintViolation(e)) {
          e.violations.forEach((violation) =>
            this.$notify.error(this.$t(violation.message))
          );
        } else if (isProblem(e)) {
          this.$notify.error(this.$t(e.detail));
        }
      }
    },
  },
});
