<template>
  <div class="container ms-0 mb-5" v-if="campaign.id">
    <h3>{{ $t("campaign.generalInformation") }}</h3>
    <div class="row mb-3">
      <div class="col color-light-text">
        {{ $t("campaign.requestedAmount") }}
      </div>
      <div class="col">
        {{ $curr(campaign.requestedData.amount) }}
      </div>
      <div class="col color-light-text">
        {{ $t("createdAt") }}
      </div>
      <div class="col">
        {{ $date(campaign.createdAt) }}
      </div>
      <div class="col color-light-text">
        {{ $t("campaign.minimumFundingPercent") }}
      </div>
      <div class="col">
        {{ campaign.approvedData.minimumFundingPercent ?? "-" }}
      </div>
    </div>
    <div class="row mb-3">
      <div class="col color-light-text">
        {{ $t("campaign.requestedDuration") }}
      </div>
      <div class="col">
        {{ campaign.requestedData.daysDuration }}
      </div>
      <div class="col color-light-text">
        {{ $t("campaign.campaignStartDate") }}
      </div>
      <div class="col">
        {{ $date(campaign.campaignStartedAt) }}
      </div>
      <div class="col color-light-text">
        {{ $t("campaign.terminatedReason.title") }}
      </div>
      <div class="col">
        {{ $enums.campaign.terminatedReason(campaign.terminatedReason) }}
      </div>
    </div>
    <div class="row mb-3">
      <div class="col color-light-text">
        {{ $t("campaign.approvedAmount") }}
      </div>
      <div class="col">
        {{ $curr(campaign.approvedData.amount) }}
      </div>
      <div class="col color-light-text">
        {{ $t("campaign.campaignEndDate") }}
      </div>
      <div class="col">
        {{ $date(campaign.campaignFinishedAt) }}
      </div>
      <div class="col color-light-text">
        {{ $t("campaign.type.title") }}
      </div>
      <div class="col">
        {{ $enums.campaign.type(campaign.requestedData.type) }}
      </div>
    </div>
    <div class="row mb-3">
      <div class="col color-light-text">
        {{ $t("campaign.approvedDuration") }}
      </div>
      <div class="col">
        {{ campaign.approvedData.daysDuration ?? "-" }}
      </div>
      <div class="col color-light-text">
        {{ $t("campaign.disbursementDate") }}
      </div>
      <div class="col">
        {{ $date(campaign.disbursementFinishedAt) }}
      </div>
      <div class="col color-light-text">
        {{ $t("campaign.overRaiseAmount") }}
      </div>
      <div class="col">
        {{ $curr(campaign.approvedData.overRaiseAmount) }}
      </div>
    </div>
    <div class="row mb-3">
      <div class="col color-light-text">
        {{ $t("campaign.minimumInvestment") }}
      </div>
      <div class="col">
        {{ $curr(campaign.approvedData.minimumInvestment) }}
      </div>
      <div class="col color-light-text">
        {{ $t("campaign.terminatedAt") }}
      </div>
      <div class="col">
        {{ $date(campaign.terminatedAt) }}
      </div>
      <div class="col color-light-text"></div>
      <div class="col"></div>
    </div>
    <br />
    <h3>{{ $t("campaign.investmentsInformation") }}</h3>
    <InvestmentSummary
      :fetch-investment-summary="fetchInvestmentSummary"
      :page-request="investmentSummaryPageRequest"
      :page="investmentSummaryPage"
    ></InvestmentSummary>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { CampaignItem, PageInvestmentSummaryItem } from "@/api";
import { authorityMixin, PageRequest } from "@/util";
import InvestmentSummary from "@/views/components/InvestmentSummary.vue";
import { campaignApi } from "@/resources";

export default defineComponent({
  name: "CampaignGeneralInfo",
  mixins: [authorityMixin],
  components: { InvestmentSummary },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      campaign: inject("campaign") as CampaignItem,
      investmentSummaryPage: {} as PageInvestmentSummaryItem,
      investmentSummaryPageRequest: {} as PageRequest,
    };
  },
  methods: {
    async fetchInvestmentSummary() {
      this.investmentSummaryPage = await campaignApi.campaignInvestments({
        id: Number(this.id),
        ...this.investmentSummaryPageRequest,
      });
    },
  },
});
</script>
