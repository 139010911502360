<template>
  <Headline
    >{{ $t("sidebar.deals") }}
    <template v-slot:actions>
      <el-button
        v-if="!filterVisible"
        class="filter"
        @click="filterVisible = true"
        >{{ $t("filter.show") }}
      </el-button>
      <el-button v-else class="filter" @click="filterVisible = false">
        {{ $t("filter.hide") }}
      </el-button>
    </template>
  </Headline>
  <DealFilter
    v-if="filterVisible"
    v-model:deal-filter="filter"
    @submit="fetchData"
  />
  <el-table
    :data="dealPage.content"
    @sort-change="onSortChange($event, pageRequest)"
    :row-style="{ cursor: 'pointer' }"
    @cell-click="onRowClick"
  >
    <el-table-column :label="$t('id')" prop="id" sortable="custom" width="85">
    </el-table-column>
    <el-table-column :label="$t('deals.amount')">
      <template #default="scope">
        {{ $curr(scope.row.amount) }}
      </template>
    </el-table-column>
    <el-table-column :label="$t('deals.lockInPeriod')" prop="lockInPeriod">
    </el-table-column>
    <el-table-column :label="$t('deals.startAt')">
      <template #default="scope">
        {{ $date(scope.row.startAt) }}
      </template>
    </el-table-column>
    <el-table-column :label="$t('deals.endAt')">
      <template #default="scope">
        {{ $date(scope.row.endAt) }}
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('deals.businessName')"
      :show-overflow-tooltip="true"
    >
      <template #default="scope">
        <router-link
          v-if="hasAuthority(authorities.BUSINESS_READ)"
          :to="{ name: 'business', params: { id: scope.row.business.id } }"
          >{{ scope.row.business.name }}
        </router-link>
        <span class="text-break" v-else>{{ scope.row.business.name }}</span>
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('deals.campaignName')"
      :show-overflow-tooltip="true"
    >
      <template #default="scope">
        <router-link
          v-if="hasAuthority(authorities.CAMPAIGN_READ)"
          :to="{ name: 'campaign', params: { id: scope.row.campaign.id } }"
          >{{ scope.row.campaign.name }}
        </router-link>
        <span class="text-break" v-else>{{ scope.row.campaign.name }}</span>
      </template>
    </el-table-column>
    <el-table-column :label="$t('deals.campaignType')" prop="campaignType">
      <template #default="scope">
        <span class="text-break">
          {{ $enums.campaign.type(scope.row.campaignType) }}
        </span>
      </template>
    </el-table-column>
  </el-table>

  <TableFooter
    @size-change="onSizeChange($event, pageRequest)"
    @current-change="onCurrentChange($event, pageRequest)"
    :page="dealPage"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { dealApi } from "@/resources";
import Headline from "@/components/Headline.vue";
import { PageInvestorListItem, Deal, CampaignType } from "@/api";
import {
  authorityMixin,
  fixLocalDate,
  PageRequest,
  pageSortMixin,
} from "@/util";
import TableFooter from "@/components/TableFooter.vue";
import DealFilter from "@/views/deal/DealFilter.vue";

export interface Filter {
  businessName: string | undefined;
  campaignTypes: CampaignType[];
  startFrom: Date | undefined;
  startTo: Date | undefined;
  endFrom: Date | undefined;
  endTo: Date | undefined;
  amountFrom: number | undefined;
  amountTo: number | undefined;
}

export default defineComponent({
  name: "DealList",
  components: { DealFilter, Headline, TableFooter },
  mixins: [pageSortMixin, authorityMixin],
  data: () => ({
    dealPage: {} as PageInvestorListItem,
    pageRequest: {} as PageRequest,
    filterVisible: false,
    filter: {} as Filter,
  }),
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.filter.startFrom = this.filter.startFrom
        ? fixLocalDate(this.filter.startFrom)
        : undefined;
      this.filter.startTo = this.filter.startTo
        ? fixLocalDate(this.filter.startTo)
        : undefined;
      this.filter.endFrom = this.filter.endFrom
        ? fixLocalDate(this.filter.endFrom)
        : undefined;
      this.filter.endTo = this.filter.endTo
        ? fixLocalDate(this.filter.endTo)
        : undefined;

      this.dealPage = await dealApi.deals({
        ...this.pageRequest,
        ...this.filter,
      });
    },
    onRowClick(row: Deal) {
      this.$router.push({
        name: "deal",
        params: { id: row.id },
      });
    },
  },
});
</script>
