/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FieldHistoryView,
    FieldHistoryViewFromJSON,
    FieldHistoryViewFromJSONTyped,
    FieldHistoryViewToJSON,
} from './';

/**
 * 
 * @export
 * @interface InvestorFeeSettingsHistoryItem
 */
export interface InvestorFeeSettingsHistoryItem {
    /**
     * 
     * @type {Array<FieldHistoryView>}
     * @memberof InvestorFeeSettingsHistoryItem
     */
    accreditationAngel: Array<FieldHistoryView>;
    /**
     * 
     * @type {Array<FieldHistoryView>}
     * @memberof InvestorFeeSettingsHistoryItem
     */
    accreditationSophisticated: Array<FieldHistoryView>;
    /**
     * 
     * @type {Array<FieldHistoryView>}
     * @memberof InvestorFeeSettingsHistoryItem
     */
    administration: Array<FieldHistoryView>;
    /**
     * 
     * @type {Array<FieldHistoryView>}
     * @memberof InvestorFeeSettingsHistoryItem
     */
    kyb: Array<FieldHistoryView>;
    /**
     * 
     * @type {Array<FieldHistoryView>}
     * @memberof InvestorFeeSettingsHistoryItem
     */
    kyc: Array<FieldHistoryView>;
    /**
     * 
     * @type {Array<FieldHistoryView>}
     * @memberof InvestorFeeSettingsHistoryItem
     */
    reKyb: Array<FieldHistoryView>;
    /**
     * 
     * @type {Array<FieldHistoryView>}
     * @memberof InvestorFeeSettingsHistoryItem
     */
    reKyc: Array<FieldHistoryView>;
}

export function InvestorFeeSettingsHistoryItemFromJSON(json: any): InvestorFeeSettingsHistoryItem {
    return InvestorFeeSettingsHistoryItemFromJSONTyped(json, false);
}

export function InvestorFeeSettingsHistoryItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestorFeeSettingsHistoryItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accreditationAngel': ((json['accreditationAngel'] as Array<any>).map(FieldHistoryViewFromJSON)),
        'accreditationSophisticated': ((json['accreditationSophisticated'] as Array<any>).map(FieldHistoryViewFromJSON)),
        'administration': ((json['administration'] as Array<any>).map(FieldHistoryViewFromJSON)),
        'kyb': ((json['kyb'] as Array<any>).map(FieldHistoryViewFromJSON)),
        'kyc': ((json['kyc'] as Array<any>).map(FieldHistoryViewFromJSON)),
        'reKyb': ((json['reKyb'] as Array<any>).map(FieldHistoryViewFromJSON)),
        'reKyc': ((json['reKyc'] as Array<any>).map(FieldHistoryViewFromJSON)),
    };
}

export function InvestorFeeSettingsHistoryItemToJSON(value?: InvestorFeeSettingsHistoryItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accreditationAngel': ((value.accreditationAngel as Array<any>).map(FieldHistoryViewToJSON)),
        'accreditationSophisticated': ((value.accreditationSophisticated as Array<any>).map(FieldHistoryViewToJSON)),
        'administration': ((value.administration as Array<any>).map(FieldHistoryViewToJSON)),
        'kyb': ((value.kyb as Array<any>).map(FieldHistoryViewToJSON)),
        'kyc': ((value.kyc as Array<any>).map(FieldHistoryViewToJSON)),
        'reKyb': ((value.reKyb as Array<any>).map(FieldHistoryViewToJSON)),
        'reKyc': ((value.reKyc as Array<any>).map(FieldHistoryViewToJSON)),
    };
}


