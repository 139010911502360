
import { defineComponent } from "vue";
import { InvestorChecklist, InvestorType } from "@/api";
import { investmentApi } from "@/resources";
import Decision from "@/components/Decision.vue";
import { isConstraintViolation, isProblem } from "@/resources/problem";
import { fixLocalDate } from "@/util";

export default defineComponent({
  name: "InvestmentInvestorVerification",
  components: { Decision },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      checklist: {} as InvestorChecklist,
    };
  },
  async mounted() {
    await this.fetchChecklist();
  },
  methods: {
    async fetchChecklist() {
      this.checklist = await investmentApi.getInvestorChecklist({
        id: Number(this.id),
      });
    },
    async updateChecklistValue(field: string, value: boolean | undefined) {
      try {
        const individual =
          this.checklist.type === InvestorType.INDIVIDUAL
            ? { ...this.checklist.individual, [field]: value }
            : this.checklist.individual;
        const legal =
          this.checklist.type === InvestorType.LEGAL
            ? { ...this.checklist.legal, [field]: value }
            : this.checklist.legal;
        this.checklist = await investmentApi.updateInvestorChecklist({
          id: Number(this.id),
          investorChecklist: {
            type: this.checklist.type,
            individual: individual,
            legal: legal,
          },
        });
      } catch (error) {
        if (isConstraintViolation(error)) {
          error.violations.forEach((violation) =>
            this.$notify.error(this.$t(violation.message))
          );
        } else if (isProblem(error)) {
          this.$notify.error(this.$t(error.detail));
        }
      }
    },
    async updateCheckResult(value: Date) {
      try {
        const individual =
          this.checklist.type === InvestorType.INDIVIDUAL
            ? {
                ...this.checklist.individual,
                kycResultDate: value ? fixLocalDate(value) : undefined,
              }
            : this.checklist.individual;
        const legal =
          this.checklist.type === InvestorType.LEGAL
            ? {
                ...this.checklist.legal,
                kycResultDate: value ? fixLocalDate(value) : undefined,
              }
            : this.checklist.legal;
        this.checklist = await investmentApi.updateInvestorChecklist({
          id: Number(this.id),
          investorChecklist: {
            type: this.checklist.type,
            individual: individual,
            legal: legal,
          },
        });
      } catch (error) {
        if (isConstraintViolation(error)) {
          error.violations.forEach((violation) =>
            this.$notify.error(this.$t(violation.message))
          );
        } else if (isProblem(error)) {
          this.$notify.error(this.$t(error.detail));
        }
      }
    },
  },
});
