
import { defineComponent } from "vue";
import { CampaignType } from "@/api";
import { ElementForm, InputLength, numberFormat } from "@/util/validation";
import { Filter } from "@/views/deal/DealList.vue";

export default defineComponent({
  emits: ["update:dealFilter", "submit"],
  props: ["dealFilter"],
  data() {
    return {
      campaignType: [] as CampaignType[],
      isFormValid: true,
      filter: {} as Filter,
      rules: {
        businessName: [new InputLength(70)],
        amountFrom: [numberFormat],
        amountTo: [numberFormat],
      },
    };
  },
  async mounted() {
    this.filter = this.dealFilter;
    this.campaignType = Object.values(CampaignType);
  },
  methods: {
    submit() {
      const form = this.$refs["filter-form"] as ElementForm;
      form.validate(async (valid) => {
        if (valid) {
          this.$emit("submit");
        }
      });
    },
    onValidate(prop: string, isVal: boolean) {
      this.isFormValid = isVal;
    },
    resetFilter() {
      this.filter = {} as Filter;
      this.$emit("update:dealFilter", this.filter);
      this.$emit("submit");
    },
  },
});
