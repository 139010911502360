/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RoleActor,
    RoleActorFromJSON,
    RoleActorFromJSONTyped,
    RoleActorToJSON,
} from './';

/**
 * 
 * @export
 * @interface RoleHistory
 */
export interface RoleHistory {
    /**
     * 
     * @type {RoleActor}
     * @memberof RoleHistory
     */
    actor: RoleActor;
    /**
     * 
     * @type {number}
     * @memberof RoleHistory
     */
    actorId?: number;
    /**
     * 
     * @type {string}
     * @memberof RoleHistory
     */
    actorName?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleHistory
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleHistory
     */
    groupName?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleHistory
     */
    startPage?: string;
    /**
     * 
     * @type {Date}
     * @memberof RoleHistory
     */
    updatedAt: Date;
}

export function RoleHistoryFromJSON(json: any): RoleHistory {
    return RoleHistoryFromJSONTyped(json, false);
}

export function RoleHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleHistory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actor': RoleActorFromJSON(json['actor']),
        'actorId': !exists(json, 'actorId') ? undefined : json['actorId'],
        'actorName': !exists(json, 'actorName') ? undefined : json['actorName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'groupName': !exists(json, 'groupName') ? undefined : json['groupName'],
        'startPage': !exists(json, 'startPage') ? undefined : json['startPage'],
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function RoleHistoryToJSON(value?: RoleHistory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actor': RoleActorToJSON(value.actor),
        'actorId': value.actorId,
        'actorName': value.actorName,
        'description': value.description,
        'groupName': value.groupName,
        'startPage': value.startPage,
        'updatedAt': (value.updatedAt.toISOString()),
    };
}


