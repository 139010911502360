/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum StartPage {
    CAMPAIGNS = 'CAMPAIGNS',
    ROLES = 'ROLES',
    USERS = 'USERS',
    INVESTORS = 'INVESTORS',
    BUSINESSES = 'BUSINESSES',
    DEALS = 'DEALS',
    INVESTMENTS = 'INVESTMENTS',
    TRANSACTIONS = 'TRANSACTIONS',
    PRE_SCREENINGS = 'PRE_SCREENINGS',
    ACCREDITATIONS = 'ACCREDITATIONS',
    SETTINGS = 'SETTINGS'
}

export function StartPageFromJSON(json: any): StartPage {
    return StartPageFromJSONTyped(json, false);
}

export function StartPageFromJSONTyped(json: any, ignoreDiscriminator: boolean): StartPage {
    return json as StartPage;
}

export function StartPageToJSON(value?: StartPage | null): any {
    return value as any;
}

