/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FlowActor,
    FlowActorFromJSON,
    FlowActorFromJSONTyped,
    FlowActorToJSON,
    IdNameEntry,
    IdNameEntryFromJSON,
    IdNameEntryFromJSONTyped,
    IdNameEntryToJSON,
} from './';

/**
 * 
 * @export
 * @interface NoteItem
 */
export interface NoteItem {
    /**
     * 
     * @type {FlowActor}
     * @memberof NoteItem
     */
    actor: FlowActor;
    /**
     * 
     * @type {Date}
     * @memberof NoteItem
     */
    createdAt: Date;
    /**
     * 
     * @type {number}
     * @memberof NoteItem
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof NoteItem
     */
    modifiedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof NoteItem
     */
    note: string;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof NoteItem
     */
    user: IdNameEntry;
}

export function NoteItemFromJSON(json: any): NoteItem {
    return NoteItemFromJSONTyped(json, false);
}

export function NoteItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): NoteItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actor': FlowActorFromJSON(json['actor']),
        'createdAt': (new Date(json['createdAt'])),
        'id': json['id'],
        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (new Date(json['modifiedAt'])),
        'note': json['note'],
        'user': IdNameEntryFromJSON(json['user']),
    };
}

export function NoteItemToJSON(value?: NoteItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actor': FlowActorToJSON(value.actor),
        'createdAt': (value.createdAt.toISOString()),
        'id': value.id,
        'modifiedAt': value.modifiedAt === undefined ? undefined : (value.modifiedAt.toISOString()),
        'note': value.note,
        'user': IdNameEntryToJSON(value.user),
    };
}


