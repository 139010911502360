
import { defineComponent, inject } from "vue";
import { Investor } from "@/api";
import InvestorIndividualGeneralInfo from "@/views/investor/components/InvestorIndividualGeneralInfo.vue";
import LegalGeneralInfo from "@/views/components/LegalGeneralInfo.vue";

export default defineComponent({
  name: "InvestorGeneralInfo",
  emits: ["update"],
  components: { InvestorIndividualGeneralInfo, LegalGeneralInfo },
  props: {
    id: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      investor: inject("investor") as Investor,
    };
  },
});
