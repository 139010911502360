<template>
  <el-checkbox-group v-model="selected" :disabled="disabled">
    <el-checkbox label="true" @click.prevent="onClickYes">
      {{ $t("decision.yes") }}
    </el-checkbox>
    <el-checkbox label="false" @click.prevent="onClickNo">
      {{ $t("decision.no") }}
    </el-checkbox>
  </el-checkbox-group>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Decision",
  props: {
    checked: {
      type: Boolean,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selected(): string[] {
      return this.checked === false ? ["false"] : this.checked ? ["true"] : [];
    },
  },
  methods: {
    onClickYes() {
      if (!this.disabled) {
        this.$emit("decided", this.checked ? undefined : true);
      }
    },
    onClickNo() {
      if (!this.disabled) {
        this.$emit("decided", this.checked === false ? undefined : false);
      }
    },
  },
});
</script>
