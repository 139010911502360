import { createI18n } from "vue-i18n";

const messages = {
  en: {
    header: {
      myProfile: "My profile",
      changePassword: "Change password",
      logout: "Log out",
    },

    footer: {
      buildTime: "Build Time",
      buildVersion: "Build Version",
    },

    sidebar: {
      businesses: "Fundraisers",
      campaigns: "Campaigns",
      partners: "Partners",
      users: "Users",
      backOfficeUsers: "Back Office",
      portalUsers: "Portal",
      roles: "Roles",
      investors: "Investors",
      deals: "Deals",
      investments: "Investments",
      transactions: "Transactions",
      batches: "Batches",
      preScreening: "Prescreenings",
      settings: "Settings",
      accreditations: "Accreditations",
    },

    auth: {
      welcome: "Welcome",
      email: "Email",
      passwordTitle: "Password",
      login: "Login",
      confirmPassword: "Confirm password",
      setPassword: "Set password",
      passwordMismatch:
        "The new password and confirmation password do not match",
      forceLogout: "You has just been deactivated",
      createPassword: "Create password",
      createNewPassword: "Create a new password",
      passwordDontMatch: "The entered passwords don't match",
      passwordRuleTitle:
        "Please ensure the password you entered is between 8 and 32 characters long and contains:",
      passwordRule1: "At least one letter",
      passwordRule2: "At least one Upper case letter",
      passwordRule3: "At least one digit",
      passwordRule4:
        "At least one special symbol from these: !\"#$%&'()*+,-./:;<=>?[\\]^_`{",
      passwordRule5: "Spaces are prohibited",

      registrationLinkExpired:
        "The link expired. Please contact system administrator to resend a link",
      registrationInvalidLink: "The link expired",

      password: {
        change: "Change Password",
        changed: "Your password has been successfully changed",
        createNew:
          "Your current password has expired. Please create a new password",
        enterNew: "New password",
        expired: "Password expired",
        incorrectCurrentPassword: "Current password is incorrect",
        incorrectPassword:
          "Please ensure the password you have entered meets the password policy",
        newPasswordIsSameAsPreviousPasswords:
          "The new password must be different from the previous 10 passwords in the password history",
        passwordMismatch: "Passwords don’t match",
        currentPassword: "Current password",
        confirmNew: "Confirm new password",
        forgot: "Forgot password?",
        recoverPassword:
          "To recover your password, please enter your email and date of birth",
        recoveryLinkHasBeenSent:
          "The link has been sent to this email to recover your password.",
        recoverySendMeLink: "Send me a link",
        invalidEmailOrBirthDate:
          "A user with these parameters doesn't exist in the system or doesn't have a dedicated permission",
      },
    },

    id: "ID",
    externalId: "External ID",
    status: "Status",
    yes: "Yes",
    no: "No",
    cancel: "Cancel",
    ok: "Ok",
    date: "Date",
    reason: "Reason",
    select: "Select",
    save: "Save",
    submit: "Submit",
    apply: "Apply",
    edit: "Edit",
    delete: "Delete",
    create: "Create",
    download: "Download",
    confirmedBy: "Confirmed by",
    type: "Type",
    amount: "Amount",
    amountOtherCurrency: "Amount, other currency",
    totalAmount: "Total amount",
    uw: "UW",
    active: "Active",
    actions: "Actions",
    annual: "annual",
    phoneExists: "The phone is already registered in the system",
    name: "Name",
    createdAt: "Created at",
    createdBy: "Created By",
    noData: "No data",
    back: "Back",
    value: "Value",
    changedAt: "Changed At",
    changedBy: "Changed By",
    comment: "Comment",
    commentRecommendation: "Comment/Recommendation",
    load: "Load More",
    loading: "Loading...",

    pageable: {
      total: "Total",
      goTo: "Go to",
      perPage: "page",
    },
    popup: {
      info: "Info",
      error: "Error",
    },

    attachment: {
      tip: "%{formats} files with a size less than %{size}",
      uploaded: "The file %{file} has been uploaded successfully",
      validations: {
        inappropriateFileType:
          "Wrong file format %{file}. Please select file in the following formats: listed %{formats}",
        maxFileSize: "Wrong file size. Please select file <= %{size}",
      },
      limit: {
        exceeds: "You are not allowed to upload more files",
      },
      delete: {
        title: "Are you sure you want to delete the file?",
        message: "File was deleted",
      },
      error: {
        notFound: "Attachment not found",
        onlyCreatorCanEditDocument:
          "Attachment document can be modified only by creator.",
        statusTerminal:
          "This action is not possible. You cannot delete the file for the closed entity",
      },
    },

    bank: {
      account: {
        status: {
          ACTIVE: "Active",
        },
      },
    },

    campaign: {
      status: {
        DRAFT: "Draft",
        WAITING_FOR_REGISTRATION_FEE: "Waiting for Registration Fee",
        ACTIVE: "Active",
        CANCELLATION_REQUEST: "Cancellation Request",
        CANCELED: "Cancelled",
        BUSINESS_VERIFICATION: "Business Verification",
        CAMPAIGN_VERIFICATION: "Campaign Verification",
        CAMPAIGN_COLLATERAL_REVIEW: "Campaign Collateral Review",
        WAITING_FOR_DATA: "Waiting for Data",
        DECLINED: "Declined",
        ABANDONED: "Abandoned",
        APPROVED: "Approved",
        SIGNING_CAMPAIGN_AGREEMENT: "Signing Campaign Agreement",
        FUNDRAISING_RESERVE: "Fundraising Reserve",
        FUNDRAISING_LIVE: "Fundraising Live",
        CAMPAIGN_FAIL: "Campaign Fail",
        CAMPAIGN_RECONCILIATION: "Campaign Reconciliation",
        TEMPORARILY_SUSPENDED: "Temporarily Suspended",
        UNSUCCESSFUL: "Unsuccessful",
        SUCCESSFUL: "Successful",
        SPV_SET_UP: "SPV Set Up",
        READY_FOR_DISBURSEMENT: "Ready for Disbursement",
        DISBURSEMENT_IN_PROGRESS: "Disbursement in Progress",
        DISBURSEMENT_ERROR: "Disbursement Error",
        DISBURSEMENT_SUCCESS: "Disbursement Success",
      },
      type: {
        title: "Campaign Type",
        CROWDFUNDING: "Equity Crowdfunding",
      },
      updated: "Campaign was updated.",
      businessUpdated: "Campaign fundraiser was updated.",
      business: "Fundraiser",
      approvedDuration: "Offered/\u200BAgreed Campaign Duration, days",
      approvedAmount: "Offered/\u200BAgreed Amount, USD",
      requestedDuration: "Requested Campaign Duration, days",
      requestedAmount: "Requested Amount, USD",
      minimumInvestment: "Min Ticket Size, USD",
      minimumFundingPercent: "Minimum Required Funding, %",
      overRaiseAmount: "Over-Raise Amount, USD",
      campaignStartDate: "Campaign Start Date",
      campaignEndDate: "Campaign End Date",
      disbursementDate: "Disbursement Date",
      terminatedAt: "Closed Date",
      terminatedReason: {
        title: "Review Reconciliation",
        BAD_KYC: "Bad KYC results",
        LOW_SCORE: "Low score",
        SUSPICION_OF_FRAUD: "Suspicion of Fraud",
        OTHER: "Other",
      },
      number: "Campaign",
      generalInformation: "General Information",
      investmentsInformation: "Investments",
      cancel: {
        header: "Cancel",
        text: "Cancel Campaign",
        info: "The campaign has been cancelled.",
      },
      suspend: {
        header: "Suspend",
        text: "Suspend Campaign",
        info: "The campaign has been suspended.",
      },
      revertSuspend: {
        header: "Return to %{status}",
        text: "Are you sure you want to return the campaign to the %{status}?",
        info: "The campaign has been returned to %{status}.",
      },
      revertCancel: {
        header: "Abandon Cancellation Request",
        text:
          "Are you sure you want to abandon Cancellation Request and return the campaign to the %{status}?",
        info: "The campaign has been returned to %{status}.",
      },
      decline: {
        header: "Decline",
        text: "Decline Campaign",
        info: "The campaign has been declined.",
      },
      approve: {
        header: "Approve",
        text: "Approve Campaign",
        info: "The campaign has been approved.",
      },
      verifyBusiness: {
        header: "Start Business Verification",
        text: "Are you sure you want to Start Business Verification?",
        info: "The campaign status has been changed.",
      },
      waitingForData: {
        header: "Request Data",
        text: "Request Additional Data",
        info: "The data has been requested.",
      },
      registrationFee: {
        header: "Request Registration Fee",
        text: "Are you sure you want to initiate the Registration Fee request?",
        info: "The request has been initiated.",
      },
      manualDisbursement: {
        header: "Initiate Disbursement",
        text: "Are you sure you want to initiate disbursement?",
        info: "Disbursement has been initiated.",
      },
      disbursement: {
        header: "Finish SPV Set Up Process",
        text: "Are you sure you want to finish SPV Set Up process?",
        info: "The campaign status has been changed.",
      },
      spv: {
        header: "Start SPV Set Up Process",
        text: "Are you sure you want to initiate SPV Set Up process?",
        info: "The campaign status has been changed.",
      },
      signingAgreement: {
        header: "Sign Agreement",
        text:
          "Are you sure you want to start Signing Campaign Agreement process?",
        info: "The campaign status has been changed.",
      },
      fundraising: {
        header: "Start Fundraising",
        text: "Are you sure you want to start Fundraising process?",
        info: "The campaign status has been changed.",
      },
      extendDuration: {
        header: "Extend Campaign Duration",
        text: "Extend Campaign Duration",
      },
      tabs: {
        general: "General Info",
        details: "Details",
        business: {
          title: "Fundraiser",
          legal: "Legal Entity Details",
          contact: "Contact Details",
          finance: "Finance Info",
          commerce: "Commercial Info",
          bank: "Bank Details",
          ownership: "Ownership, Directors, Authorized Signatory (s)",
          attachments: "Attachments",
        },
        preScreening: {
          title: "Prescreening",
          details: "Details",
          general: "General Info",
          attachments: "Attachments",
          reviewNotes: "Review Notes",
          followUpNotes: "Follow-up Notes",
        },
        fee: "Fees",
        summary: "Summary",
        attachments: "Attachments",
        documents: "Documents",
        reviewNotes: "Review Notes",
        fundraiserVerification: "Fundraiser Verification",
        campaignVerification: "Campaign Verification",
        collateralReview: "Collateral Review",
        content: {
          title: "Content",
          filesTitle: "Files",
          labels: {
            HIGHLIGHTS: "Highlights",
            PROBLEM: "The Problem",
            SOLUTION: "Solution",
            PRODUCT: "Product/Service",
            TRACTION: "Traction",
            CUSTOMERS: "Customers",
            BUSINESS: "Business Model",
            MARKET: "Market",
            COMPETITION: "Competition",
            STRATEGY: "Strategy",
            FUNDING: "Funding",
            TEAM: "The Team",
            RISKS: "Risks",
            TERMS: "Terms",
            LOGO: "Logo",
            BANNER: "Banner",
            FAQ: "FAQ",
            TERMS_AND_CONDITION: "Terms & Condition",
            PITCH_DECK: "Pitch Deck",
            VIDEO_LINK: "Video Link",
          },
        },
      },
      flow: {
        status: "This action is not possible. Please check a campaign status.",
      },
      error: {
        details:
          "The action is not possible. Please complete the campaign's details.",
        extendDateMustBeInFuture: "Campaign End Date must be in the future.",
        deletedEntity:
          "No action is possible on deleted note. Refresh notes list.",
        spvFee: "This action is not possible. Please check the SPV Fee amount.",
        spvDocument:
          "This action is not possible. Please upload the SPV document.",
        agreementDocument:
          "This action is not possible. Please upload the signed campaign agreement.",
        minimumInvestmentGreaterAmount:
          "This action is not possible. The campaign Min Ticket Size can't be greater or equals than amount.",
        checklist:
          "This action is not possible. The campaign cannot be approved without completing Campaign Collateral Review.",
        fundraiserVerification:
          "Action is not possible. Complete Fundraiser Verification checklist.",
        campaignVerificationChecklist:
          "Action is not possible. Complete Campaign Verification checklist.",
      },
      fee: {
        registration: "Registration Fee, USD",
        spv: "SPV Fee, USD",
        fundraisingCommission: "Fundraising Commission, %",
      },
      details: {
        name: "Campaign Name",
        description: "Campaign Description",
        technologies: "Technology",
        highlights: "Highlights",
        businessModels: "Business Model",
        impacts: "Impact",
        technology: {
          AI_ML: "AI & ML",
          APPS_ECOMMERCE: "Apps & E-Commerce",
          AR_VR: "AR & VR",
          BIG_DATA: "Big Data",
          BIOTECHNOLOGY: "Biotechnology",
          BLOCKCHAIN_WEB3: "Blockchain & Web 3",
          DEVELOPMENT_TOOLS: "Development Tools",
          ELECTRIC_VEHICLES: "Electric Vehicles (EVs)",
          GAMES: "Games",
          HARDWARE: "Hardware",
          IOT: "IoT",
          ROBOTICS: "Robotics",
          SMART_DEVICES: "Smart Devices",
          WEARABLES: "Wearables",
        },
        highlight: {
          RAISED_LESS_250K: "Below $250K raised",
          RAISED_MORE_250K: "$250K+ raised",
          RAISED_MORE_1M: "$1M+ raised",
          RAISED_MORE_5M: "$5M+ raised",
          RAISED_MORE_10M: "$10M+ raised",
          RAISED_MORE_25M: "$25M+ raised",
          EMPLOYEES_BETWEEN_0_10: "0-10 employees",
          EMPLOYEES_BETWEEN_11_25: "11-25 employees",
          EMPLOYEES_BETWEEN_26_50: "26-50 employees",
          EMPLOYEES_BETWEEN_51_200: "51-200 employees",
          EMPLOYEES_MORE_200: "200+ employees",
          BOOTSTRAPPED: "Bootstrapped",
          NOTABLE_ANGEL_BACKED: "Notable Angel backed",
          POWER_FOUNDERS: "Power Founders",
          PROFITABLE: "Profitable",
          VENTURE_BACKED: "Venture-backed",
          SME_DEVELOPMENT_FUND_BACKED: "SME Development Fund backed",
          OMAN_TECHNOLOGY_FUND_BACKED: "Oman Technology Fund backed",
        },
        businessModel: {
          B2B: "B2B",
          B2C: "B2C",
          D2C: "D2C",
          MARKETPLACE: "Marketplace",
          SAAS: "SaaS",
          SUBSCRIPTION: "Subscription",
        },
        impact: {
          LOCAL_FOUNDERS: "All Local Founders",
          WOMEN_FOUNDERS: "All Women Founders",
          DIVERSE_FOUNDERS: "Diverse Founders",
          MINORITY_FOUNDER: "Minority Founder",
          SOCIAL_IMPACT: "Social Impact",
          SUSTAINABILITY: "Sustainability",
        },
        securityType: "Security Type",
        valuation: {
          title: "Valuation Cap, USD",
        },
        previouslyFundedAmount: "Previously Funded Amount, USD",
        priceForShare: "Price for Share, USD",
        fundraiserWebsite: "Fundraiser Website",
        fundraiserFoundingYear: "Fundraiser Founding Year",
      },
      summary: {
        title: "Summary",
        tentativeAmount: "Tentative Commitments Amount, USD",
        committedAmount: "Total Committed Amount, USD",
        committedPercent: "Campaign Commitment, %",
        receivedAmount: "Total Received Amount, USD",
      },
      attachment: {
        type: {
          SPV: "SPV",
          AGREEMENT: "Agreements",
        },
      },
      collateralReview: {
        valued: "Valuation agreed?",
        audited: "Audited Financials accepted?",
        documentAccepted:
          "Is the pitch document finalised and of acceptable quality?",
        campaignVideoAccepted:
          "Is the campaign video available and of good quality?",
        campaignImageAccepted:
          "Is campaign banner image available and of good quality?",
        sectionImagesAccepted:
          "Images of acceptable quality available for all relevant pitch sections?",
        sectionContentComplete: "Are all pitch section contents complete?",
        campaignSignedOff:
          "Full campaign page previewed with Fundraiser and signed-off?",
        reviewCompleted: "Campaign Collaterals Review complete?",
      },
      fundraiserVerification: {
        informationMatch:
          "1. Does Company information match Registration Documents?",
        acceptableRegistrationCountry:
          "2. Acceptable (Non-Sanctioned) country of Registration?",
        validCertificate:
          "3. Valid (Unexpired) Company Registration Certificate?",
        acceptableCompanyType: "4. Company registration type acceptable?",
        validCertificateInCountry:
          "5. KYC - Is CR valid in the country registration database?",
        activitiesMatches: "6. CR activities matches activity of the company?",
        clearShareholding: "7. Clear shareholding?",
        shareholdersCompletedKyc:
          "8. Beneficial Shareholders owning 10% or more Identified and KYC completed?",
        hasOtherActivities:
          "9. Does company have other potentially unrelated activities under the same CR?",
        acceptableTargetAmount:
          "10. Target Amount requested acceptable for company age? ",
        riskAssessmentCompleted: "11. Risk Assessment Completed?",
        riskRatingAcceptable:
          "12. Does the company have an acceptable risk rating?",
        checkedRegistrationDocument:
          "13. Company Registration Document - Checked and OK",
        checkedAssociationDocs:
          "14. Memorandum or Articles of association of the legal entity - Checked and OK",
        checkedShareholderAgreement:
          "15. Shareholder Agreement - Checked and OK",
        checkedAuditedFinancials:
          "16. Audited Financials from Accredited Audit Firm or Equivalent - Checked and OK",
        checkedValuationDocument: "17. Valuation Document - Checked and OK",
        checkedCancelledCheque: "18. Cancelled Cheque - Checked and OK",
        checkedFounderProfiles: "19. Founder Profiles - Checked and OK",
        hasWebsite: "20. Company has website?",
        socialMediaPresence: "21. Company has social media presence?",
        traction: "22. Is there sufficient traction?",
        valuableOffer: "23. Is offer valuable enough for potential investors?",
        interestInMarket: "24. Is there sufficient interest in target market?",
        fundraisingExperience: "25. Previous experience of fundraising?",
        marketingExperience:
          "26. Previous marketing and social media experience?",
        willingInvestTimeEffort:
          "27. Willing to invest significant time and effort?",
        understandCostsAndLimits:
          "28. Understand financial costs & limits of crowdfunding? ",
        interestSectorForInvestor:
          "29. Is the Industry/ Sector of interest for Wadiaa investors?",
        clearStrategy: "30. Clear Strategy and Business Model?",
        acceptableRepute:
          "31. Founders and Management of acceptable Repute/ Track Record?",
        acceptableFinancials: "32. Acceptable Financials and Runway?",
        acceptableValuationAndTerms:
          "33. Acceptable valuation and investment terms?",
        securedLeadInvestor: "34. Identified and secured lead investor?",
        nda: "35. NDA Signed?",
        kybCompleted: "36. Completed KYB session?",
        orientationSessionCompleted:
          "37. Completed Wadiaa orientation session?",
        pricingAgreed: "38. Pricing & Commercials agreed?",
        fundraisingBudgetAgreed:
          "39. Agreed fundraising marketing budget and plan?",
        potentialRisks:
          "40. Any potential risks that need to be reviewed in detail?",
        fundraisingPlanCompleted: "41. Fundraising plan completed?",
        verificationCompleted: "42. Fundraiser Verification completed?",
        complianceChecks: "Compliance Checks",
        dataRoomChecks: "Document - Data Room Checks",
        fitChecks: "Fundraising-Fit Checks",
        readinessChecks: "Readiness Checks",
        completeness: "Completeness",
        complete: "Complete",
        post: "Post",
        placeholder: "Type comment...",
      },
      campaignVerification: {
        placeholder: "Type comment...",
        planAccepted: "1. Is the campaign plan finalised/accepted? ",
        amountsAgreed: "2. All Campaign Amounts agreed?",
        overRaiseAgreed: "3. Over-raise agreed?",
        durationAgreed: "4. Campaign duration agreed?",
        extensionAgreed: "5. Campaign extension agreed?",
        rollingCloseAgreed: "6. Rolling close agreed?",
        rewardAgreed: "7. Rewards and incentives agreed? (if applicable)",
        rewardSigned:
          "8. Rewards and incentives agreement signed? (if applicable)",
        campaignFeeReceived: "9. Campaign Fee received?",
        termsSigned:
          "10. Investment Instrument and Terms finalised, documented and signed-off?",
        investmentNoteUploaded:
          "11. Campaign investment note template finalised and uploaded?",
        verificationCompleted: "12. Campaign Verification completed?",
        complete: "Complete",
        post: "Post",
      },
    },

    user: {
      givenName: "Given Name",
      lastName: "Last Name",
      birthDate: "Date of Birth",
      residenceCountry: "Country of Residence",
      role: "Role",
      phone: "Mobile Phone",
      email: "Email",
      password: "Password",
      partner: "Partner",
      status: "Status",
      active: "Active",
      inactive: "Inactive",
      new: "New user",
      create: "Create",
      userCreated: "The new user has been created",
      userUpdated: "The user has been updated",
      exists: "A user with the this email or phone already exists",
      disabled: "User inactive",
      user: "User",
      doActivate: "Activate",
      doDeactivate: "Deactivate",
      doSuspend: "Suspend",
      askActivate: "Are you sure you want to activate the user?",
      askPortalUserActivate:
        "Are you sure you want to activate the portal's user?",
      askDeactivate: "Are you sure you want to deactivate the user?",
      askPortalUserSuspend: "Are you sure you want to suspend the user?",
      resendLink: "Resend link",
      resendLinkSent: "Registration link was successfully resend",
      deactivate: "{givenName} {lastName} has been deactivated.",
      suspend: "{givenName} {lastName} has been suspended.",
      activate: "{givenName} {lastName} has been activated.",
      userStatus: {
        active: "Active",
        inactive: "Inactive",
        locked: "Locked",
        suspended: "Suspended",
      },

      tabs: {
        details: "Details",
      },
      error: {
        unauthorized: "Incorrect email or password",
        locked: "Account locked out - Contact your System Administrator",
        disabled: "Account is disabled - Contact your System Administrator",
        incorrectPassword:
          "Please ensure the password you have entered meets the password policy",
      },
    },

    globals: {
      country: {
        BAHRAIN: "Bahrain",
        KUWAIT: "Kuwait",
        OMAN: "Oman",
        QATAR: "Qatar",
        SAUDI_ARABIA: "Saudi Arabia",
        UNITED_ARAB_EMIRATES: "United Arab Emirates",
        OTHER: "Other",
      },
      gender: {
        MALE: "Male",
        FEMALE: "Female",
      },
      currency: {
        USD: "USD",
        OMR: "OMR",
        AED: "AED",
        QAR: "QAR",
        SAR: "SAR",
        BHD: "BHD",
        KWD: "KWD",
      },
    },

    role: {
      roleName: "Name",
      roleDescription: "Description",
      new: "New role",
      create: "Create",
      startPage: "Start page",
      residenceCountry: "Country",
      permissions: "Permissions",
      permissionsSelectAll: "Select all permissions",
      campaigns: "Campaigns",
      campaignOperations: "Change status",
      manualDisbursement: "Initiate Disbursement",
      collateralReview: "Collateral Review",
      fundraiserVerification: "Fundraiser Verification",
      campaignVerification: "Campaign Verification",
      campaignStatuses: "Campaign statuses",
      selectAllCampaignStatuses: "Select all campaign statuses",
      investors: "Investors",
      investments: "Investments",
      investmentChangeStatus: "Change status",
      investmentManualRefund: "Initiate Refund",
      investmentInvestorVerification: "Investor Verification",
      businesses: "Fundraisers",
      deals: "Deals",
      transactions: "Transactions",
      completeTransaction: "Complete Transaction",
      batch: "Batches",
      preScreening: "Prescreenings",
      preScreeningEdit: "Edit",
      preScreeningOperations: "Change Status",
      settings: "Settings",
      settingsAccountDetails: "Account Details",
      accreditation: "Accreditation",
      accreditationOperations: "Change Status",

      CAMPAIGN_DRAFT_VIEW: "Draft",
      CAMPAIGN_WAITING_FOR_REGISTRATION_FEE_VIEW:
        "Waiting for Registration Fee",
      CAMPAIGN_ACTIVE_VIEW: "Active",
      CAMPAIGN_CANCELLATION_REQUEST_VIEW: "Cancellation Request",
      CAMPAIGN_CANCELED_VIEW: "Canceled",
      CAMPAIGN_BUSINESS_VERIFICATION_VIEW: "Business Verification",
      CAMPAIGN_CAMPAIGN_VERIFICATION_VIEW: "Campaign Verification",
      CAMPAIGN_CAMPAIGN_COLLATERAL_REVIEW_VIEW: "Campaign Collateral Review",
      CAMPAIGN_WAITING_FOR_DATA_VIEW: "Waiting for Data",
      CAMPAIGN_DECLINED_VIEW: "Declined",
      CAMPAIGN_ABANDONED_VIEW: "Abandoned",
      CAMPAIGN_APPROVED_VIEW: "Approved",
      CAMPAIGN_SIGNING_CAMPAIGN_AGREEMENT_VIEW: "Signing Campaign Agreement",
      CAMPAIGN_FUNDRAISING_RESERVE_VIEW: "Fundraising Reserve",
      CAMPAIGN_FUNDRAISING_LIVE_VIEW: "Fundraising Live",
      CAMPAIGN_FAIL_VIEW: "Campaign Fail",
      CAMPAIGN_RECONCILIATION_VIEW: "Campaign Reconciliation",
      CAMPAIGN_TEMPORARILY_SUSPENDED_VIEW: "Temporarily Suspended",
      CAMPAIGN_UNSUCCESSFUL_VIEW: "Unsuccessful",
      CAMPAIGN_SUCCESSFUL_VIEW: "Successful",
      CAMPAIGN_READY_FOR_DISBURSEMENT_VIEW: "Ready for Disbursement",
      CAMPAIGN_DISBURSEMENT_IN_PROGRESS_VIEW: "Disbursement in Progress",
      CAMPAIGN_DISBURSEMENT_ERROR_VIEW: "Disbursement Error",
      CAMPAIGN_DISBURSEMENT_SUCCESS_VIEW: "Disbursement Success",
      CAMPAIGN_SPV_SET_UP_VIEW: "SPV Set Up",

      edit: "Edit",
      editCreate: "Edit, create new",
      roles: "Roles",
      users: "Users",
      notification: "Notifications",
      startPageNoData: "Please select permissions first",
      roleCreated: "A new role has been created",
      roleUpdated: "The role has been updated",
      roleExists: "A role with this name already exists",
    },

    report: {
      reports: "Reports",
    },

    validation: {
      inputRequired: "Please the input field",
      selectRequired: "Please select a value",
      dateRequired: "Please, pick a date",
      permissionRequired: "Please, select at least one permission",
      reasonRequired: "Please, enter a reason for the request",
      currentPasswordRequired: "Enter your current password",
      newPasswordRequired: "Enter your new password",
      confirmationPasswordRequired: "Confirm your new password",

      integerRange: "Value must be more than %{min} and less than %{max}",
      integerMin: "Value must be more than %{min}",

      numberFormat: "Inappropriate number format",
      decimalFormat: "Inappropriate decimal format",
      integerFormat: "Inappropriate integer number format",
      alphabeticalFormat: "Inappropriate alphabetical format",
      alphanumericFormat: "Inappropriate alphanumeric format",
      emailFormat: "Inappropriate email format",
      phoneFormat: "Inappropriate phone format",
      phoneFormatRequired: "Fill the field in the correct format",

      inputLength: "Text size should not exceed %{max} symbols",
    },

    filter: {
      show: "Show filters",
      hide: "Hide filters",
      reset: "Reset filters",
      residenceCountry: "Country of Residence",
      status: "Status",
      disbursementStatus: "Disbursement Status",
      disbursementDate: "Disbursement Date",
      createdBy: "Created By",
      role: "Role",
      lastName: "Last name",
      fullname: "Full Name",
      email: "Email",
      phone: "Cell Phone",
      idNumber: "ID number",
      createDate: "Created at",
      id: "ID",
      amount: "Amount",
      totalAmount: "Total amount",
      confirmedBy: "Confirmed by",
      paymentType: "Payment type",
      startDate: "Start Date",
      endDate: "End date",
      title: "Title",
      from: "From",
      to: "To",
    },

    profile: {
      myProfile: "My profile",
      updated: "Profile updated",
    },

    deals: {
      tabs: {
        details: "Details",
        attachments: "Attachments",
      },
      number: "Deal",
      amount: "Amount, USD",
      lockInPeriod: "Lock-in Period, months",
      startAt: "Start Date",
      endAt: "End Date",
      businessName: "Fundraiser",
      campaignName: "Campaign Name",
      campaign: "Campaign ID",
      campaignType: "Type",
      generalInformation: "General Information",
      investorName: "Investor Name",
      investments: "Investments",
    },

    transactions: {
      tabs: {
        details: "Details",
      },
      type: {
        registration_fee: "Registration Fee",
        investment: "Investment",
        disbursement: "Disbursement",
        refund: "Refund",
        platform_fee: "Platform Fee",
      },
      status: {
        initiated: "Initiated",
        in_progress: "In Progress",
        returned: "Returned",
        submitted: "Submitted",
        failed: "Failed",
      },
      externalId: "External ID",
      campaignId: "Campaign ID",
      investmentId: "Investment ID",
      startDate: "Start Date & Time",
      startDateFilter: "Start Date",
      effectiveDate: "Effective Date & Time",
      effectiveDateFilter: "Effective Date",
      transactionType: "Type",
      amount: "Amount, USD",
      omrAmount: "Amount, OMR",
      transactionStatus: "Status",
      id: "Transaction ID",
      completeManual: {
        header: "Complete Transaction",
        text: "Complete Transaction",
        info: "The transaction state has been updated.",
      },
      completeQA: {
        header: "Complete Transaction (QA Only)",
        text: "Complete Transaction (QA Only)",
        info: "The transaction state has been updated.",
      },
      error: {
        notFound:
          "This action is not possible. The transaction with the entered id doesn't exist. Please check the entered transaction id.",
        status:
          "This action is not possible. Please check the transaction status.",
        type:
          "This action is not possible. The entered transaction id should relates to the disbursement, refund or investment transaction. Please check the transaction type for the entered id",
      },
    },

    legal: {
      general: {
        name: "Full Legal Company Name",
        registrationCountry: "Registration Country",
        investmentCountry: "Investment Country",
        establishedAt: "Date of Establishment",
        registrationNumber: "Commercial Registration Number",
        expiryDate: "Commercial Registration Expiry Date",
        taxIdNumber: "TAX ID Number",
        listedExchange: "Listed Exchange",
        bearerSharesIssuer: "Issuer of Bearer Shares",
        businessNature:
          "Nature of Business (a summary of the company's activities)",
        ownershipChangedLast5Years: "Change of Ownership in the last 5 years",
        statusChangedLast5Years:
          "Change of Legal Entity status in the last 5 years",
        relatedFirmsInside: "Related firms/Subsidiaries (in Home Country)",
        relatedFirmsOutside:
          "Related firms/Subsidiaries (outside Home Country)",
        numberOfEmployees: "Number of Employees",
      },
      type: {
        title: "Legal Entity Type",
        SOLE_TRADER: "Sole Trader / Proprietorship",
        GENERAL_PARTNERSHIP: "General Partnership",
        HOLDING_COMPANY: "Holding Company",
        BRANCH_OF_FOREIGN_COMPANY: "Branch of Foreign Company",
        LIMITED_PARTNERSHIP: "Limited Partnership",
        GOVERNMENT_OR_STATE: "Government of Oman & State Owned Bodies",
        SAOC: "SAOC Company",
        LLC: "Limited Liability Company (LLC)",
        CPV: "CPV",
      },
      businessSector: {
        title: "Sector",
        AGRICULTURE_FISHERY: "Agriculture & Fisheries",
        ARTS_ENTERTAINMENT: "Arts & Entertainment",
        CONSTRUCTION_INFRASTRUCTURE: "Construction & Infrastructure",
        GOODS_RETAIL: "Consumer Goods & Retail",
        EDTECH_EDUCATION: "EdTech & Education ",
        ENERGY: "Energy",
        FASHION_DESIGN: "Fashion & Design",
        FINTECH_FINANCE: "FinTech & Finance",
        FOODTECH_FOOD_BEVERAGE: "FoodTech and F&B",
        HEALTH_WELLNESS: "Health & Wellness",
        HEALTHCARE: "Healthcare",
        IT: "Information Technology",
        MANUFACTURING: "Manufacturing",
        SERVICE: "Services",
        SPORT: "Sports",
        TRANSPORT: "Transportation",
        REAL_ESTATE: "Real Estate",
        SCIENCE_RESEARCH_DEVELOPMENT: "Science and R&D",
        TRAVEL_TOURISM: "Travel & Tourism",
        OTHER: "Others",
      },
      contact: {
        phone: "Contact Number",
        email: "Email",
      },
      finance: {
        turnover: {
          title: "Turnover",
          local: "Local Sales Turnover, %{currency}",
          current: "Current",
          projected: "Projected",
        },
        totalAnnualRevenue: "Total Annual Revenue, %{currency}",
        totalAssets: "Total Assets, %{currency}",
        initialFundsSource:
          "Principal source of funds used to initiate the company",
        groupName: "Name of the Group (Parent Company)",
        groupAddress: "Address",
      },
      commerce: {
        type: {
          PROVIDED_PRODUCT: "Key products/services provided by the company:",
          COUNTRY_DEALT_PRODUCT:
            "The country of origin of the products/services dealt by the company:",
          COUNTRY_IMPORTED_PRODUCT:
            "The countries from where the company source (import) the key products/services:",
          CUSTOMER_TYPE: "Types of Customers the company deals with:",
          COUNTRY_EXPORTED_PRODUCT:
            "The countries where the company directly sell (export) the key products/services:",
          TOP_COMPANY_BUYERS: "Names of the company Top 5 Buyers:",
          TOP_COMPANY_SUPPLIERS: "Names of the company Top 5 Suppliers:",
        },
      },
      bankAccounts: "Account(s)",
      ownership: {
        owners: {
          title: "Ownership",
          name: "Full Title/\u200BName",
          address: "Full Residential and P O Box Address",
          residenceCountry: "Country of Residence",
          registrationNumber: "Company Registration No",
          idCardNumber: "Civil/\u200BResident Card Number",
          passportNumber: "Passport Number",
          birthDate: "Date of Birth",
          incorporation: "Nationality/\u200BIncorporation",
          percent: "Shareholding/\u200BOwnership Percentage (%)",
        },
        members: {
          title:
            "Directors, Authorized Signatory (s), Other Influential Parties",
          name: "Name",
          position: "Position",
          address: "Full Residential and P O Box Address",
          residenceCountry: "Country of Residence",
          idCardNumber: "Civil/\u200BResident Card Number",
          passportNumber: "Passport Number",
          birthDate: "Date of Birth",
          nationality: "Nationality",
        },
        persons: {
          title:
            "Politically Exposed Persons (PEP) Declaration of Owners/\u200BDirectors/\u200BSignatories",
          name: "Name",
          companyPosition: "Position in the Company",
          governmentPosition: "Position in the Government",
          governmentDepartment: "Government Department",
          closeAssociate: "Close Associate",
        },
      },
    },
    business: {
      name: "Name",
      phone: "Telephone Number",
      tabs: {
        legal: "Legal Entity Details",
        contact: "Contact Details",
        finance: "Finance Info",
        commerce: "Commercial Info",
        bank: "Bank Details",
        ownership: "Ownership, Directors, Authorized Signatory (s)",
        attachments: "Attachments",
      },
      email: "Email",
      updated: "Fundraiser was updated.",
    },
    investor: {
      type: {
        title: "Type",
        INDIVIDUAL: "Individual",
        LEGAL: "Legal Entity",
      },
      category: {
        title: "Category",
        RETAIL: "Retail",
        ANGEL: "Angel",
        SOPHISTICATED: "Sophisticated",
        OTHER_INSTITUTIONAL: "Other Institutional Investor",
      },
      subCategory: {
        GOVERNMENT: {
          SOPHISTICATED: {
            LEGAL: "Units of the administrative status of the Government",
          },
        },
        CMA_LICENSED_ENTITIES: {
          SOPHISTICATED: {
            LEGAL:
              "Entities licensed by the CMA including capital market institutions, companies operating in the field of securities, insurance, and Takaful companies",
          },
        },
        CBO_LICENSED_ENTITIES: {
          SOPHISTICATED: {
            LEGAL:
              "Financial institutions licensed by the Central Bank of Oman",
          },
        },
        PENSION_FUNDS: {
          SOPHISTICATED: {
            LEGAL: "Pension funds",
          },
        },
        LEVEL_1: {
          SOPHISTICATED: {
            LEGAL:
              "A company or investment fund with total assets exceeding USD 2,600,000 (OMR 1,000,000) or its equivalent in foreign currencies",
            INDIVIDUAL:
              "Your total net personal assets or total net joint assets with his or her spouse exceed USD 1,300,000 (OMR 500,000) or its equivalent in foreign currencies, excluding the value of the individual’s primary residence",
          },
          ANGEL: {
            INDIVIDUAL:
              "Your total net personal assets exceed USD 780,000 (OMR 300,000) or its equivalent in foreign currencies",
          },
        },
        LEVEL_2: {
          SOPHISTICATED: {
            INDIVIDUAL:
              "Your gross total annual income is not less than USD 78,000 (OMR 30,000) or its equivalent in foreign currencies in the last twelve (12) months",
          },
          ANGEL: {
            INDIVIDUAL:
              "Your gross total annual income is not less than USD 52,000 (OMR 20,000) or its equivalent in foreign currencies in the last twelve (12) months",
          },
        },
        LEVEL_3: {
          SOPHISTICATED: {
            INDIVIDUAL:
              "You, jointly with your spouse, have a gross total annual income exceeding USD 130,000 (OMR 50,000) or its equivalent in foreign currencies in the last twelve (12) months",
          },
          ANGEL: {
            INDIVIDUAL:
              "You, jointly with your spouse, have a gross total annual income exceeding USD 78,000 (OMR 30,000) or its equivalent in foreign currencies in the last twelve (12) months",
          },
        },
      },
      givenName: "Given Name",
      lastName: "Last Name",
      investorType: "Investor Type",
      investorCategory: "Investor Category",
      residenceCountry: "Country of Residence",
      taxIdNumber: "TAX ID Number",
      nationality: "Nationality",
      listedExchange: "Listed Exchange",
      bearerSharesIssuer: "Issuer of Bearer Shares",
      birthDate: "Date of Birth",
      birthPlace: "Place of Birth",
      cardIdNumber: "Civil/Resident Card Number",
      passportNumber: "Passport Number",
      issuePlace: "Place of Issue",
      issueDate: "Issue Date",
      expiryDate: "Expiry Date",
      gender: "Gender",
      education: {
        title: "Educational Level",
        UNIVERSITY: "University",
        SECONDARY: "Secondary",
        MIDDLE_SCHOOL: "Middle School",
        ELEMENTARY: "Elementary",
        OTHER: "Other",
      },
      name: "Name",
      country: "Registration Country/Country of Residence",
      phone: "Telephone Number",
      mobileNumber: "Mobile Number",
      email: "Email",
      filter: {
        name: "Name",
        type: "Type",
        category: "Category",
        country: "Registration Country/Country of Residence",
        email: "Email",
      },
      tabs: {
        generalInfo: "General Info",
        contactDetails: "Contact Details",
        financialInfo: "Financial Info",
        occupationIncome: "Occupation/Income",
        bankDetails: "Bank Details",
        ownership: "Ownership, Directors, Authorized Signatory (s)",
        attachments: "Attachments",
      },
      legalName: "Full Legal Company Name",
      employmentType: {
        title: "Type of Employment",
        SALARIED: "Salaried",
        SELF_EMPLOYED: "Self-employed",
        BOTH: "Both",
      },
      companyName: "Company Name",
      companyAddress: "Company Address",
      companyContactNumber: "Company Contact Number",
      occupation: "Occupation",
      wealthSource: "Source(s) of Wealth",
      totalNetPersonalAssets: "Total Net Personal Assets Value, %{currency}",
      totalNetJointAssets:
        "Total Net Joint (with Spouse) Assets Value, %{currency}",
      totalAnnualInvestments:
        "Total Annual Investments Value (for last 12 months), %{currency}",
      totalGrossPersonalAnnualIncome:
        "Gross Total Annual Income (for last 12 months), %{currency}",
      totalGrossJointAnnualIncome:
        "Gross Total Joint (with Spouse) Annual Income (for last 12 months), %{currency}",
      bankAccounts: "Account(s)",
    },

    contact: {
      title: "Alternate Contact Person",
      name: "Name",
      position: "Position",
      contactNumber: "Contact Number",
      email: "Email",
    },

    address: {
      poBox: "P.O. Box",
      postalCode: "Postal/\u200BZip Code",
      line1: "Address Line 1",
      line2: "Address Line 2",
      city: "City",
      country: "Country",
      currentAddress: "Current Address",
      permanentAddress: "Permanent Address",
      apartment: "Apartment/\u200BUnit Number",
      building: "House/\u200BBuilding Number & Building, Community Name",
      street: "Way Number/\u200BStreet Name/\u200BLocality name",
      state: "State/\u200BGovernorate",
    },

    bankAccount: {
      number: "Account Number",
      openedAt: "Date Account Opened",
      status: "Account Status",
      bankName: "Bank Name",
      bankAddress: "Bank Address",
      bankBic: "Bank BIC",
      master: "Master Account",
      dividend: "Dividend Account",
      currency: "Currency",
    },

    investment: {
      columns: {
        campaignId: "Campaign ID",
        investor: "Investor",
        requestedAmount: "Requested Investment Amount, USD",
        committedAmount: "Committed Amount, USD",
        receivedAmount: "Received Amount, USD",
        investorCommitment: "Investor Commitment, %",
        createdAt: "Created at",
        status: "Status",
      },
      status: {
        DRAFT: "Draft",
        WAITLISTED: "Waitlisted",
        SUBMITTED: "Submitted",
        UNDER_PROCESS: "Under Process",
        WAITING_FOR_DATA: "Waiting for Data",
        ABANDONED: "Abandoned",
        APPROVED: "Approved",
        SIGNING_TERM_SHEET: "Signing Term Sheet",
        AWAITING_PLATFORM_FEE: "Awaiting Platform Fee",
        RESERVED: "Reserved",
        COMPLETED: "Completed",
        CANCELLATION_REQUEST: "Cancellation Request",
        CANCELLED: "Cancelled",
        DECLINED: "Declined",
        REFUND_PENDING: "Refund Pending",
        REFUND_IN_PROGRESS: "Refund in Progress",
        REFUND_ERROR: "Refund Error",
        REFUND_COMPLETED: "Refund Completed",
        CLOSED: "Closed",
        INVESTED: "Invested",
      },
      filter: {
        investor: "Investor",
        status: "Status",
        committedAmount: "Committed Amount, USD",
        receivedAmount: "Received Amount, USD",
        createDate: "Created at",
        campaignId: "Campaign ID",
      },
      viewTitle: "Investment",
      tabs: {
        details: "Details",
        investor: "Investor",
        attachments: "Attachments",
        documents: "Documents",
        review: "Reviews",
        investorVerification: "Investor Verification",
      },
      generalInformation: "General Information",
      requestedAmount: "Requested Investment Amount, USD",
      committedAmount: "Committed Amount, USD",
      receivedAmount: "Received Amount, USD",
      investorCommitment: "Investor Commitment, %",
      fee: "Fees",
      platformFee: "Platform Fee, USD",
      createdAt: "Created at",
      closedAt: "Closed at",
      campaign: "Campaign",
      updated: "Investment was updated.",
      declineReason: {
        title: "Decline reason",
        BAD_KYC: "Bad KYC results",
        LOW_SCORE: "Low score",
        SUSPICION_OF_FRAUD: "Suspicion of Fraud",
        OTHER: "Other",
      },
      submit: {
        header: "Submit",
        text: "Are you sure you want to submit investment?",
        info: "Investment has been submitted.",
      },
      awaitingPlatformFee: {
        header: "Request Platform Fee",
        text: "Are you sure you want to initiate the Platform Fee request?",
        info: "The request has been initiated",
      },
      signingTermSheet: {
        header: "Sign Term Sheet",
        text: "Proceed with Term Sheet Signing process?",
        info: "The investment status has been changed",
      },
      confirmTermSigning: {
        header: "Request Investment",
        text: "Are you sure you want to initiate the Investment request?",
        info: "The request has been initiated",
      },
      process: {
        header: "Start Verification",
        text: "Are you sure you want to start investment verification?",
        info: "Investment is under process.",
      },
      approve: {
        header: "Approve",
        text: "Are you sure you want to approve this investment?",
        info: "Investment has been approved.",
      },
      decline: {
        title: "Decline Investment",
        header: "Decline",
        text: "Decline investment",
        info: "Investment has been declined.",
      },
      requestDataUpdate: {
        header: "Request Data",
        text: "Request Additional Data",
        info: "The data has been requested.",
      },
      refund: {
        header: "Initiate Refund",
        text: "Are you sure you want to initiate refund?",
        info: "Refund has been initiated.",
      },
      error: {
        not: { found: "Investment not found" },
        newStatus:
          "This action is not possible. Please check the investment status.",
        amountOverLimit:
          "This action is not possible. The Requested Investment Amount adding leads to an increase in the amount requested by the business",
        previousCreatedWaitlistedInvestments:
          "This action is not possible. Please process previously received waitlisted investments first",
        investorIsNotVerified:
          "This action is not possible. The investment cannot be approved without completing Investor Verification",
        investmentTransactionExist:
          'This action is not possible. You already have a transaction with the type "Investment".',
        signedTermNotExits:
          "This action is not possible. Please upload the signed term sheet.",
      },
      investorVerification: {
        individual: {
          documentVerified: "Document Verification against Name and ID number?",
          photoVerified: "Check for valid Photo ID (Passport/ Civil Card)?",
          faceVerified: "Investor Face Verification against ID?",
          dofChecked: "Date of Birth Check?",
          occupationChecked: "Occupation Check?",
          nationalityChecked: "Nationality Check?",
          addressChecked: "Address Verification?",
          locationChecked: "Location Check?",
          amlChecked: "AML Check Completed?",
          accreditationChecked:
            "Accreditation check completed? (if applicable)",
          kycResultDate: "KYB/KYC AML Check Results",
          verificationCompleted: "Investor Verification completed?",
        },
        legal: {
          informationMatch:
            "Does Company information match Registration Documents?",
          acceptableRegistrationCountry:
            "Acceptable (Non-Sanctioned) country of Registration?",
          validCertificate:
            "Valid (Unexpired) Company Registration Certificate?",
          collectedKybDocuments: "KYB Check - Collected all KYB documents?",
          shareholdersIdentified:
            "Has Beneficial Shareholders been Identified?",
          shareholdersClearedKyc:
            "Have Major Shareholders (Owning 10% or more) cleared KYC/ AML checks?",
          accreditationCompleted:
            "Accreditation check completed? (if applicable)",
          checkedAuthorisationLetter: "Authorisation Letter - checked?",
          kycResultDate: "KYB/KYC AML Check Results",
          verificationCompleted: "Investor Verification completed?",
        },
      },
      transactionProcessing: {
        MANUAL: "Manual",
        AUTOMATED: "Automated",
      },
    },

    preScreening: {
      status: {
        DRAFT: "Draft",
        RECEIVED: "Received",
        REVIEWED: "Reviewed",
        FOLLOW_UP: "Follow-Up",
        PENDING: "Pending Input",
        APPROVED: "Approved",
        DECLINED: "Declined",
        CANCELLED: "Cancelled",
      },
      filter: {
        duplicateStatus: "Duplicate Status",
      },
      duplicateStatus: {
        OLD: "Old possible duplicate",
        NEW: "New possible duplicate",
        NONE: "None",
      },
      companyName: "Company Name",
      companyRegistrationCountry: "Registration Country",
      companyRegistrationNumber: "Registration Number",
      contactName: "Contact Name",
      contactEmail: "Contact Email",
      contactPosition: "Contact Designation",
      contactNumber: "Contact Number",
      createdAt: "Prescreening Created At",
      tabs: {
        details: "Details",
        general: "General Info",
        attachments: "Attachments",
        reviewNotes: "Review Notes",
        followUpNotes: "Follow-up Notes",
      },
      quiz: {
        key: {
          GENERATE_REVENUE: "Is the Company generating revenue?",
          LAUNCHED_PRODUCT: "Has the Company launched a product in the market?",
          MONTHLY_RUNWAY: "What is the Company's runway (in months), USD?",
          MONEY_FUNDRAISED:
            "Has the company raised funds via Crowdfunding in the past?",
          SUMMARY: "Summary",
          TRACTION: "Traction",
          MONEY_RAISED: "How much money has the company raised till date, USD?",
          MONEY_WANTS_RAISE: "How much would you like to raise on Wadiaa, USD?",
          CAMPAIGN_TYPE: "How would you like to fundraise?",
          NUMBER_OF_EMPLOYEES: "Number of Employees",
        },
      },
      APPROVED: {
        header: "Approve",
        text: "Are you sure you want to approve this prescreening?",
        info: "The prescreening has been approved.",
      },
      REVIEWED: {
        header: "Start Review",
        text: "Are you sure you want to start review process?",
        info: "The prescreening status has been changed.",
      },
      DECLINED: {
        header: "Decline",
        text: "Are you sure you want to decline this prescreening?",
        info: "The prescreening has been declined.",
      },
      CANCELLED: {
        header: "Cancel",
        text: "Are you sure you want to cancel this prescreening?",
        info: "The prescreening has been cancelled.",
      },
      PENDING: {
        header: "Pending Input",
        text:
          "Are you sure you want to put this prescreening into a pending input state?",
        info: "The prescreening status has been changed.",
      },
      FOLLOW_UP: {
        header: "Follow-Up",
        text:
          "Are you sure you want to put this prescreening into a follow-up state?",
        info: "The prescreening status has been changed.",
      },
      flow: {
        status:
          "This action is not possible. Please check a prescreening status",
      },
      post: "Post",
      editNote: "Edit note",
      noteDelete: {
        label: "Delete",
        title: "Are you sure you want to delete the note?",
        message: "Note was deleted",
      },
      error: {
        noteNotFound: "Pre-screening note not found",
        onlyCreatorCanEditNote:
          "Pre-screening note can be modified only by creator.",
        deletedEntity:
          "No action is possible on deleted note. Refresh notes list.",
        onlyCreatorCanEditDocument:
          "Pre-screening document can be modified only by creator.",
      },
    },

    accreditation: {
      approvedAt: "Approved At",
      investor: "Investor",
      previousCategory: "Initial Category",
      category: "Requested Category",
      subCategory: "Subcategory",
      validTill: "Validity (including)",
      status: {
        DRAFT: "Draft",
        RECEIVED: "Received",
        REVIEWED: "Reviewed",
        FOLLOW_UP: "Follow-Up",
        PENDING: "Pending Input",
        APPROVED: "Approved",
        DECLINED: "Declined",
        CANCELLED: "Cancelled",
      },
      tabs: {
        general: "General Info",
        attachments: "Attachments",
        investor: "Investor",
      },
      REVIEWED: {
        header: "Start Review",
        text: "Are you sure you want to start review process?",
        info: "The accreditation status has been changed.",
      },
      APPROVED: {
        header: "Approve",
        text: "Are you sure you want to approve this accreditation?",
        info: "The accreditation has been approved.",
      },
      DECLINED: {
        header: "Decline",
        text: "Are you sure you want to decline this accreditation?",
        info: "The accreditation has been declined.",
      },
      CANCELLED: {
        header: "Cancel",
        text: "Are you sure you want to cancel this accreditation?",
        info: "The accreditation has been cancelled.",
      },
      PENDING: {
        header: "Pending Input",
        text:
          "Are you sure you want to put this accreditation into a pending input state?",
        info: "The accreditation status has been changed.",
      },
      FOLLOW_UP: {
        header: "Follow-Up",
        text:
          "Are you sure you want to put this accreditation into a follow-up state?",
        info: "The accreditation status has been changed.",
      },
      flow: {
        status:
          "This action is not possible. Please check the accreditation status",
      },
    },

    investmentSummary: {
      name: "Investor Name",
      requestedAmount: "Requested Investment Amount, USD",
      committedAmount: "Committed Amount, USD",
      receivedAmount: "Received Amount, USD",
    },

    note: {
      title: "Your Note",
      placeholder: "Type Note...",
      createdBy: "Created by",
      createdAtNotModified: "at %{createdAt}",
      createdModified: "at %{createdAt} modified at %{modifiedAt}",
      post: "Post",
      edit: "Edit note",
      delete: {
        label: "Delete",
        title: "Are you sure you want to delete the note?",
        message: "Note was deleted",
      },
    },
    review: {
      createdBy: "Created by",
      createdAtNotModified: "at %{createdAt}",
      createdModified: "at %{createdAt} modified at %{modifiedAt}",
      delete: {
        label: "Delete",
        title: "Are you sure you want to delete the review?",
        message: "Review was deleted",
      },
    },

    batch: {
      file: "Batch Document",
      error: "Error",
      status: {
        initiated: "Initiated",
        in_progress: "In Progress",
        completed: "Completed",
        failed: "Failed",
      },
      b2b: {
        header: "Generate Batch",
        text: "Are you sure you want to generate the B2B file?",
      },
    },

    settings: {
      title: "Settings",
      tabs: {
        fee: {
          title: "Fees",
          common: "General",
          investor: "Investor",
        },
        investorLimit: {
          title: "Investor Limits",
          individual: "Individual",
          legal: "Legal Entity",
        },
        accountDetails: {
          title: "Account Details",
          bankAccount: "Wadiaa Account Details",
        },
        exchangeRate: {
          title: "Exchange Rate",
        },
      },
      fee: {
        commonPayment: "Payment Gateway Fee, USD",
        investorAccreditationAngel: "Accreditation Fee - Angel Investor, USD",
        investorAccreditationSophisticated:
          "Accreditation Fee - Sophisticated Investor, USD",
        investorAdministration: "Investment Administration Fee, USD",
        investorKyc: "KYC Fee, USD",
        investorReKyc: "Re-KYC Fee, USD",
        investorKyb: "KYB Fee, USD",
        investorReKyb: "Re-KYB Fee, USD",
      },
      investorLimit: {
        retail: "Retail, USD",
        angel: "Angel, USD",
        otherInstitutional: "Other Institutional Investor, USD",
      },
      exchangeRate: {
        usdToOmr: "USD to OMR",
        usdToAed: "USD to AED",
        usdToQar: "USD to QAR",
        usdToSar: "USD to SAR",
        usdToBhd: "USD to BHD",
        usdToKwd: "USD to KWD",
      },
    },
    error: {
      locking:
        "Your request cannot be processed at this time. Please try again later.",
    },

    decision: {
      yes: "Yes",
      no: "No",
    },
  },
};

export const i18n = createI18n({
  messages: messages,
  globalInjection: true,
  locale: "en",
  fallbackLocale: "key",
});
