/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AttachmentListItem,
    AttachmentListItemFromJSON,
    AttachmentListItemToJSON,
    AttachmentType,
    AttachmentTypeFromJSON,
    AttachmentTypeToJSON,
    BackOfficeInvestmentState,
    BackOfficeInvestmentStateFromJSON,
    BackOfficeInvestmentStateToJSON,
    BaseInvestmentStatusChangeRequest,
    BaseInvestmentStatusChangeRequestFromJSON,
    BaseInvestmentStatusChangeRequestToJSON,
    DeclineInvestmentRequest,
    DeclineInvestmentRequestFromJSON,
    DeclineInvestmentRequestToJSON,
    Investment,
    InvestmentFromJSON,
    InvestmentToJSON,
    InvestorChecklist,
    InvestorChecklistFromJSON,
    InvestorChecklistToJSON,
    PageInvestmentListItem,
    PageInvestmentListItemFromJSON,
    PageInvestmentListItemToJSON,
} from '../models';

export interface ApproveInvestmentRequest {
    id: number;
}

export interface ConfirmTermSigningRequest {
    id: number;
    body: string;
}

export interface DeclineRequest {
    id: number;
    declineInvestmentRequest: DeclineInvestmentRequest;
}

export interface DeleteInvestmentAttachmentRequest {
    id: number;
    attachmentId: number;
}

export interface DeleteReviewRequest {
    id: number;
}

export interface GetInvestmentStateRequest {
    id: number;
}

export interface GetInvestorChecklistRequest {
    id: number;
}

export interface InvestmentRequest {
    id: number;
}

export interface InvestmentAttachmentRequest {
    id: number;
    attachmentId: number;
}

export interface InvestmentAttachmentsRequest {
    id: number;
    type: AttachmentType;
}

export interface InvestmentsRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
    investor?: string;
    statuses?: Array<string>;
    createdDateFrom?: Date;
    createdDateTo?: Date;
    committedAmountFrom?: number;
    committedAmountTo?: number;
    receivedAmountFrom?: number;
    receivedAmountTo?: number;
    campaignId?: string;
}

export interface ProcessRequest {
    id: number;
}

export interface RefundRequest {
    id: number;
}

export interface RequestDataUpdateRequest {
    id: number;
    baseInvestmentStatusChangeRequest: BaseInvestmentStatusChangeRequest;
}

export interface SignTermSheetRequest {
    id: number;
}

export interface SubmitRequest {
    id: number;
}

export interface UpdateInvestorChecklistRequest {
    id: number;
    investorChecklist: InvestorChecklist;
}

export interface UploadInvestmentAttachmentRequest {
    id: number;
    type: AttachmentType;
    file: Blob;
}

export interface WaitingForPlatformFeeRequest {
    id: number;
}

/**
 * 
 */
export class BackofficeInvestmentControllerApi extends runtime.BaseAPI {

    /**
     */
    async approveInvestmentRaw(requestParameters: ApproveInvestmentRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling approveInvestment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/investments/{id}/approve`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async approveInvestment(requestParameters: ApproveInvestmentRequest): Promise<void> {
        await this.approveInvestmentRaw(requestParameters);
    }

    /**
     */
    async confirmTermSigningRaw(requestParameters: ConfirmTermSigningRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling confirmTermSigning.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling confirmTermSigning.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/investments/{id}/confirmTermSigning`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async confirmTermSigning(requestParameters: ConfirmTermSigningRequest): Promise<void> {
        await this.confirmTermSigningRaw(requestParameters);
    }

    /**
     */
    async declineRaw(requestParameters: DeclineRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling decline.');
        }

        if (requestParameters.declineInvestmentRequest === null || requestParameters.declineInvestmentRequest === undefined) {
            throw new runtime.RequiredError('declineInvestmentRequest','Required parameter requestParameters.declineInvestmentRequest was null or undefined when calling decline.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/investments/{id}/decline`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeclineInvestmentRequestToJSON(requestParameters.declineInvestmentRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async decline(requestParameters: DeclineRequest): Promise<void> {
        await this.declineRaw(requestParameters);
    }

    /**
     */
    async deleteInvestmentAttachmentRaw(requestParameters: DeleteInvestmentAttachmentRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteInvestmentAttachment.');
        }

        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId','Required parameter requestParameters.attachmentId was null or undefined when calling deleteInvestmentAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/investments/{id}/attachments/{attachmentId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteInvestmentAttachment(requestParameters: DeleteInvestmentAttachmentRequest): Promise<void> {
        await this.deleteInvestmentAttachmentRaw(requestParameters);
    }

    /**
     */
    async deleteReviewRaw(requestParameters: DeleteReviewRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteReview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/investments/{id}/review`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteReview(requestParameters: DeleteReviewRequest): Promise<void> {
        await this.deleteReviewRaw(requestParameters);
    }

    /**
     */
    async getInvestmentStateRaw(requestParameters: GetInvestmentStateRequest): Promise<runtime.ApiResponse<BackOfficeInvestmentState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getInvestmentState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/investments/{id}/state`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BackOfficeInvestmentStateFromJSON(jsonValue));
    }

    /**
     */
    async getInvestmentState(requestParameters: GetInvestmentStateRequest): Promise<BackOfficeInvestmentState> {
        const response = await this.getInvestmentStateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getInvestorChecklistRaw(requestParameters: GetInvestorChecklistRequest): Promise<runtime.ApiResponse<InvestorChecklist>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getInvestorChecklist.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/investments/{id}/checklist`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InvestorChecklistFromJSON(jsonValue));
    }

    /**
     */
    async getInvestorChecklist(requestParameters: GetInvestorChecklistRequest): Promise<InvestorChecklist> {
        const response = await this.getInvestorChecklistRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async investmentRaw(requestParameters: InvestmentRequest): Promise<runtime.ApiResponse<Investment>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling investment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/investments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InvestmentFromJSON(jsonValue));
    }

    /**
     */
    async investment(requestParameters: InvestmentRequest): Promise<Investment> {
        const response = await this.investmentRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async investmentAttachmentRaw(requestParameters: InvestmentAttachmentRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling investmentAttachment.');
        }

        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId','Required parameter requestParameters.attachmentId was null or undefined when calling investmentAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/investments/{id}/attachments/{attachmentId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async investmentAttachment(requestParameters: InvestmentAttachmentRequest): Promise<Blob> {
        const response = await this.investmentAttachmentRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async investmentAttachmentsRaw(requestParameters: InvestmentAttachmentsRequest): Promise<runtime.ApiResponse<Array<AttachmentListItem>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling investmentAttachments.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling investmentAttachments.');
        }

        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/investments/{id}/attachments`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AttachmentListItemFromJSON));
    }

    /**
     */
    async investmentAttachments(requestParameters: InvestmentAttachmentsRequest): Promise<Array<AttachmentListItem>> {
        const response = await this.investmentAttachmentsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async investmentsRaw(requestParameters: InvestmentsRequest): Promise<runtime.ApiResponse<PageInvestmentListItem>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.investor !== undefined) {
            queryParameters['investor'] = requestParameters.investor;
        }

        if (requestParameters.statuses) {
            queryParameters['statuses'] = requestParameters.statuses;
        }

        if (requestParameters.createdDateFrom !== undefined) {
            queryParameters['createdDateFrom'] = (requestParameters.createdDateFrom as any).toISOString().substr(0,10);
        }

        if (requestParameters.createdDateTo !== undefined) {
            queryParameters['createdDateTo'] = (requestParameters.createdDateTo as any).toISOString().substr(0,10);
        }

        if (requestParameters.committedAmountFrom !== undefined) {
            queryParameters['committedAmountFrom'] = requestParameters.committedAmountFrom;
        }

        if (requestParameters.committedAmountTo !== undefined) {
            queryParameters['committedAmountTo'] = requestParameters.committedAmountTo;
        }

        if (requestParameters.receivedAmountFrom !== undefined) {
            queryParameters['receivedAmountFrom'] = requestParameters.receivedAmountFrom;
        }

        if (requestParameters.receivedAmountTo !== undefined) {
            queryParameters['receivedAmountTo'] = requestParameters.receivedAmountTo;
        }

        if (requestParameters.campaignId !== undefined) {
            queryParameters['campaignId'] = requestParameters.campaignId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/investments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageInvestmentListItemFromJSON(jsonValue));
    }

    /**
     */
    async investments(requestParameters: InvestmentsRequest): Promise<PageInvestmentListItem> {
        const response = await this.investmentsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async processRaw(requestParameters: ProcessRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling process.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/investments/{id}/process`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async process(requestParameters: ProcessRequest): Promise<void> {
        await this.processRaw(requestParameters);
    }

    /**
     */
    async refundRaw(requestParameters: RefundRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling refund.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/investments/{id}/refund`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async refund(requestParameters: RefundRequest): Promise<void> {
        await this.refundRaw(requestParameters);
    }

    /**
     */
    async requestDataUpdateRaw(requestParameters: RequestDataUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling requestDataUpdate.');
        }

        if (requestParameters.baseInvestmentStatusChangeRequest === null || requestParameters.baseInvestmentStatusChangeRequest === undefined) {
            throw new runtime.RequiredError('baseInvestmentStatusChangeRequest','Required parameter requestParameters.baseInvestmentStatusChangeRequest was null or undefined when calling requestDataUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/investments/{id}/request/update`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BaseInvestmentStatusChangeRequestToJSON(requestParameters.baseInvestmentStatusChangeRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async requestDataUpdate(requestParameters: RequestDataUpdateRequest): Promise<void> {
        await this.requestDataUpdateRaw(requestParameters);
    }

    /**
     */
    async signTermSheetRaw(requestParameters: SignTermSheetRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling signTermSheet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/investments/{id}/signTermSheet`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async signTermSheet(requestParameters: SignTermSheetRequest): Promise<void> {
        await this.signTermSheetRaw(requestParameters);
    }

    /**
     */
    async submitRaw(requestParameters: SubmitRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling submit.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/investments/{id}/submit`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async submit(requestParameters: SubmitRequest): Promise<void> {
        await this.submitRaw(requestParameters);
    }

    /**
     */
    async updateInvestorChecklistRaw(requestParameters: UpdateInvestorChecklistRequest): Promise<runtime.ApiResponse<InvestorChecklist>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateInvestorChecklist.');
        }

        if (requestParameters.investorChecklist === null || requestParameters.investorChecklist === undefined) {
            throw new runtime.RequiredError('investorChecklist','Required parameter requestParameters.investorChecklist was null or undefined when calling updateInvestorChecklist.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/investments/{id}/checklist`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: InvestorChecklistToJSON(requestParameters.investorChecklist),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InvestorChecklistFromJSON(jsonValue));
    }

    /**
     */
    async updateInvestorChecklist(requestParameters: UpdateInvestorChecklistRequest): Promise<InvestorChecklist> {
        const response = await this.updateInvestorChecklistRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async uploadInvestmentAttachmentRaw(requestParameters: UploadInvestmentAttachmentRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling uploadInvestmentAttachment.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling uploadInvestmentAttachment.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadInvestmentAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/investments/{id}/attachments/{type}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async uploadInvestmentAttachment(requestParameters: UploadInvestmentAttachmentRequest): Promise<void> {
        await this.uploadInvestmentAttachmentRaw(requestParameters);
    }

    /**
     */
    async waitingForPlatformFeeRaw(requestParameters: WaitingForPlatformFeeRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling waitingForPlatformFee.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/investments/{id}/waiting-for-platform-fee`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async waitingForPlatformFee(requestParameters: WaitingForPlatformFeeRequest): Promise<void> {
        await this.waitingForPlatformFeeRaw(requestParameters);
    }

}
