import { TransactionStatus } from "@/api";
import { i18n } from "@/util";

export class Transaction {
  static status(status: string): string {
    return i18n.global.t("transactions.status." + status.toLowerCase());
  }

  static type(type: string): string {
    return i18n.global.t("transactions.type." + type.toLowerCase());
  }

  static statusColor(status: TransactionStatus): string {
    switch (status) {
      case TransactionStatus.FAILED:
        return "#d20f0f";
      case TransactionStatus.IN_PROGRESS:
        return "#e1a514";
      case TransactionStatus.SUBMITTED:
        return "#1ddc37";
      case TransactionStatus.INITIATED:
        return "#0056f3";
      case TransactionStatus.RETURNED:
        return "#0e0d0d";
      default:
        return "#7f7f7f";
    }
  }
}
