
import { defineComponent } from "vue";
import { businessApi } from "@/resources";
import { PageRequest, pageSortMixin } from "@/util";
import TableFooter from "@/components/TableFooter.vue";
import Headline from "@/components/Headline.vue";
import BusinessFilter from "@/views/business/BusinessFilter.vue";
import { BusinessListItem, PageBusinessListItem } from "@/api";

export interface Filter {
  name: string;
  registrationNumber: string;
  email: string;
}

export default defineComponent({
  name: "BusinessList",
  mixins: [pageSortMixin],
  components: { TableFooter, Headline, BusinessFilter },
  data: () => ({
    businessPage: {} as PageBusinessListItem,
    pageRequest: {} as PageRequest,
    filterVisible: false,
    filter: {} as Filter,
  }),
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.businessPage = await businessApi.businesses({
        ...this.pageRequest,
        ...this.filter,
      });
    },
    onCellClick(row: BusinessListItem) {
      this.$router.push({
        name: "business",
        params: { id: row.id },
      });
    },
  },
});
