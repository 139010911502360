/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IdNameEntry,
    IdNameEntryFromJSON,
    IdNameEntryFromJSONTyped,
    IdNameEntryToJSON,
    InvestmentStatus,
    InvestmentStatusFromJSON,
    InvestmentStatusFromJSONTyped,
    InvestmentStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface InvestmentListItem
 */
export interface InvestmentListItem {
    /**
     * 
     * @type {number}
     * @memberof InvestmentListItem
     */
    campaignId?: number;
    /**
     * 
     * @type {number}
     * @memberof InvestmentListItem
     */
    committedAmount?: number;
    /**
     * 
     * @type {Date}
     * @memberof InvestmentListItem
     */
    createdAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof InvestmentListItem
     */
    id: number;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof InvestmentListItem
     */
    investor: IdNameEntry;
    /**
     * 
     * @type {number}
     * @memberof InvestmentListItem
     */
    readonly investorCommitment?: number;
    /**
     * 
     * @type {number}
     * @memberof InvestmentListItem
     */
    receivedAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof InvestmentListItem
     */
    requestedAmount?: number;
    /**
     * 
     * @type {InvestmentStatus}
     * @memberof InvestmentListItem
     */
    status?: InvestmentStatus;
}

export function InvestmentListItemFromJSON(json: any): InvestmentListItem {
    return InvestmentListItemFromJSONTyped(json, false);
}

export function InvestmentListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestmentListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'campaignId': !exists(json, 'campaignId') ? undefined : json['campaignId'],
        'committedAmount': !exists(json, 'committedAmount') ? undefined : json['committedAmount'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'id': json['id'],
        'investor': IdNameEntryFromJSON(json['investor']),
        'investorCommitment': !exists(json, 'investorCommitment') ? undefined : json['investorCommitment'],
        'receivedAmount': !exists(json, 'receivedAmount') ? undefined : json['receivedAmount'],
        'requestedAmount': !exists(json, 'requestedAmount') ? undefined : json['requestedAmount'],
        'status': !exists(json, 'status') ? undefined : InvestmentStatusFromJSON(json['status']),
    };
}

export function InvestmentListItemToJSON(value?: InvestmentListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'campaignId': value.campaignId,
        'committedAmount': value.committedAmount,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString().substr(0,10)),
        'id': value.id,
        'investor': IdNameEntryToJSON(value.investor),
        'receivedAmount': value.receivedAmount,
        'requestedAmount': value.requestedAmount,
        'status': InvestmentStatusToJSON(value.status),
    };
}


