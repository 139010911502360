<template>
  <div class="card">
    <div class="card-main">
      <BackLink />
      <Headline v-if="settings.commonFee" :class="'align-items-start'">
        {{ $t("settings.title") }}
      </Headline>
    </div>
    <div class="card-foot">
      <ul class="nav nav-tabs mb-3">
        <TabLink to="settingsFee">
          {{ $t("settings.tabs.fee.title") }}
        </TabLink>
        <TabLink to="settingsInvestorLimit">
          {{ $t("settings.tabs.investorLimit.title") }}
        </TabLink>
        <TabLink
          to="settingsAccountDetails"
          v-if="hasAuthority(authorities.SETTINGS_ACCOUNT_DETAILS)"
        >
          {{ $t("settings.tabs.accountDetails.title") }}
        </TabLink>
        <TabLink to="exchangeRate">
          {{ $t("settings.tabs.exchangeRate.title") }}
        </TabLink>
      </ul>
    </div>
    <div class="card-foot sub_tab_foot" v-show="feeOpened">
      <ul class="nav nav-tabs mb-3">
        <TabLink to="settingsFeeCommon">
          {{ $t("settings.tabs.fee.common") }}
        </TabLink>
        <TabLink to="settingsFeeInvestor">
          {{ $t("settings.tabs.fee.investor") }}
        </TabLink>
      </ul>
    </div>
    <div class="card-foot sub_tab_foot" v-show="investorLimitOpened">
      <ul class="nav nav-tabs mb-3">
        <TabLink to="settingsInvestorLimitIndividual">
          {{ $t("settings.tabs.investorLimit.individual") }}
        </TabLink>
        <TabLink to="settingsInvestorLimitLegal">
          {{ $t("settings.tabs.investorLimit.legal") }}
        </TabLink>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="tab-content">
      <div class="tab-pane active">
        <router-view @update="fetchData"></router-view>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import TabLink from "@/components/TabLink.vue";
import Headline from "@/components/Headline.vue";
import { settingsApi } from "@/resources";
import BackLink from "@/components/BackLink.vue";
import { SettingsHistoryItem, SettingsItem } from "@/api";
import { mapState } from "vuex";
import { authorityMixin } from "@/util";

export default defineComponent({
  name: "SettingsView",
  mixins: [authorityMixin],
  components: {
    Headline,
    TabLink,
    BackLink,
  },
  provide() {
    return {
      settings: computed(() => this.settings),
      settingsHistory: computed(() => this.settingsHistory),
    };
  },
  data() {
    return {
      settings: {} as SettingsItem,
      settingsHistory: {} as SettingsHistoryItem,
    };
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchSettings() {
      this.settings = await settingsApi.settings();
    },
    async fetchSettingsHistory() {
      this.settingsHistory = await settingsApi.settingsHistory();
    },
    async fetchData() {
      await Promise.all([this.fetchSettings(), this.fetchSettingsHistory()]);
    },
  },
  computed: {
    feeOpened(): boolean {
      return this.$route.name
        ? this.$route.name.toString().startsWith("settingsFee")
        : false;
    },
    investorLimitOpened(): boolean {
      return this.$route.name
        ? this.$route.name.toString().startsWith("settingsInvestorLimit")
        : false;
    },
    ...mapState("feature", ["qaEnabled"]),
  },
});
</script>
<style lang="scss" scoped>
@import "src/styles/tabs";
</style>
