
import { defineComponent, PropType } from "vue";
import { CurrentAddress } from "@/api";

export default defineComponent({
  name: "CurrentAddress",
  props: {
    currentAddress: {
      type: Object as PropType<CurrentAddress>,
      required: true,
    },
  },
});
