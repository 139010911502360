
import { defineComponent, inject } from "vue";
import { LegalFinanceItem } from "@/api";
import LegalFinance from "@/views/components/LegalFinance.vue";

export default defineComponent({
  name: "BusinessFinanceInfo",
  components: { LegalFinance },
  data() {
    return {
      finance: inject("finance") as LegalFinanceItem,
    };
  },
});
