<template>
  <div class="container-filters mt-4 mb-3">
    <el-form
      @validate="onValidate"
      :model="filter"
      :rules="rules"
      ref="filter-form"
      @change="$emit('update:campaignFilter', filter)"
    >
      <div class="row">
        <div class="col-4">
          <el-form-item :label="$t('deals.businessName')" prop="businessName">
            <el-input v-model="filter.businessName"></el-input>
          </el-form-item>
          <el-form-item :label="$t('deals.campaignType')">
            <el-select
              v-model="filter.campaignTypes"
              class="w-100"
              multiple
              collapse-tags
            >
              <el-option
                v-for="(campaignType, key) in campaignType"
                :key="key"
                :label="$enums.campaign.type(campaignType)"
                :value="campaignType"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="col-4">
          <el-form-item :label="$t('deals.startAt')">
            <div class="d-flex gap-2">
              <el-date-picker
                type="date"
                v-model="filter.startFrom"
                format="DD/MM/YYYY"
                :placeholder="$t('filter.from')"
              ></el-date-picker>
              <el-date-picker
                type="date"
                v-model="filter.startTo"
                format="DD/MM/YYYY"
                :placeholder="$t('filter.to')"
              ></el-date-picker>
            </div>
          </el-form-item>
          <el-form-item :label="$t('deals.endAt')">
            <div class="d-flex gap-2">
              <el-date-picker
                type="date"
                v-model="filter.endFrom"
                format="DD/MM/YYYY"
                :placeholder="$t('filter.from')"
              ></el-date-picker>
              <el-date-picker
                type="date"
                v-model="filter.endTo"
                format="DD/MM/YYYY"
                :placeholder="$t('filter.to')"
              ></el-date-picker>
            </div>
          </el-form-item>
        </div>
        <div class="col-4">
          <label class="el-form-item__label">{{ $t("deals.amount") }}</label>
          <div class="d-flex gap-2">
            <el-form-item prop="amountFrom">
              <el-input
                v-model.number="filter.amountFrom"
                :placeholder="$t('filter.from')"
              >
                <template #prepend>
                  <font-awesome-icon :icon="['fas', 'dollar-sign']" />
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="amountTo">
              <el-input
                v-model.number="filter.amountTo"
                :placeholder="$t('filter.to')"
              >
                <template #prepend>
                  <font-awesome-icon :icon="['fas', 'dollar-sign']" />
                </template>
              </el-input>
            </el-form-item>
          </div>
        </div>
      </div>
    </el-form>
  </div>
  <div class="mb-5">
    <el-button type="primary" @click="submit" :disabled="!isFormValid">
      {{ $t("apply") }}
    </el-button>
    <el-button type="default" @click="resetFilter">
      {{ $t("filter.reset") }}
    </el-button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { CampaignType } from "@/api";
import { ElementForm, InputLength, numberFormat } from "@/util/validation";
import { Filter } from "@/views/deal/DealList.vue";

export default defineComponent({
  emits: ["update:dealFilter", "submit"],
  props: ["dealFilter"],
  data() {
    return {
      campaignType: [] as CampaignType[],
      isFormValid: true,
      filter: {} as Filter,
      rules: {
        businessName: [new InputLength(70)],
        amountFrom: [numberFormat],
        amountTo: [numberFormat],
      },
    };
  },
  async mounted() {
    this.filter = this.dealFilter;
    this.campaignType = Object.values(CampaignType);
  },
  methods: {
    submit() {
      const form = this.$refs["filter-form"] as ElementForm;
      form.validate(async (valid) => {
        if (valid) {
          this.$emit("submit");
        }
      });
    },
    onValidate(prop: string, isVal: boolean) {
      this.isFormValid = isVal;
    },
    resetFilter() {
      this.filter = {} as Filter;
      this.$emit("update:dealFilter", this.filter);
      this.$emit("submit");
    },
  },
});
</script>
