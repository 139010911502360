
import { defineComponent, inject } from "vue";
import { AttachmentListItem } from "@/api";
import Attachments from "@/views/components/Attachments.vue";
import { investorApi } from "@/resources";

export default defineComponent({
  name: "InvestorAttachments",
  components: { Attachments },
  data() {
    return {
      investorId: inject("investorId") as number,
      attachments: [] as AttachmentListItem[],
    };
  },
  async mounted() {
    await this.fetchAttachments();
  },
  methods: {
    async fetchAttachments() {
      this.attachments = await investorApi.investorAttachments({
        id: this.investorId,
      });
    },
    async loadAttachment(attachmentId: number): Promise<Blob> {
      return await investorApi.investorAttachment({
        id: this.investorId,
        attachmentId: attachmentId,
      });
    },
  },
});
