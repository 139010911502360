
import { defineComponent } from "vue";
import { InvestorCategory, InvestorType, Country } from "@/api";
import { InputLength } from "@/util/validation";
import { Filter } from "@/views/investor/InvestorList.vue";

export default defineComponent({
  emits: ["update:investorFilter", "submit"],
  props: ["investorFilter"],
  data() {
    return {
      countries: [] as Country[],
      types: [] as InvestorType[],
      categories: [] as InvestorCategory[],
      isFormValid: true,
      filter: {} as Filter,
      rules: {
        name: [new InputLength(100)],
        email: [new InputLength(255)],
      },
    };
  },
  async mounted() {
    this.filter = this.investorFilter;
    this.types = Object.values(InvestorType);
    this.categories = Object.values(InvestorCategory);
    this.countries = Object.values(Country);
  },
  methods: {
    onValidate(prop: string, isVal: boolean) {
      this.isFormValid = isVal;
    },
    resetFilter() {
      this.filter = {} as Filter;
      this.$emit("update:investorFilter", this.filter);
      this.$emit("submit");
    },
  },
});
