
import { defineComponent, inject } from "vue";
import { Investment } from "@/api";
import { authorityMixin } from "@/util";

export default defineComponent({
  name: "InvestmentDetails",
  mixins: [authorityMixin],
  data() {
    return {
      investment: inject("investment") as Investment,
    };
  },
});
