/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PagePortalUser,
    PagePortalUserFromJSON,
    PagePortalUserToJSON,
    PortalUser,
    PortalUserFromJSON,
    PortalUserToJSON,
} from '../models';

export interface ActivatePortalUserRequest {
    id: number;
}

export interface DeactivatePortalUserRequest {
    id: number;
}

export interface PortalUserRequest {
    id: number;
}

export interface PortalUsersRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
    fullname?: string;
    email?: string;
    statuses?: Array<string>;
}

/**
 * 
 */
export class BackOfficePortalUserControllerApi extends runtime.BaseAPI {

    /**
     */
    async activatePortalUserRaw(requestParameters: ActivatePortalUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling activatePortalUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/portal/{id}/activate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async activatePortalUser(requestParameters: ActivatePortalUserRequest): Promise<void> {
        await this.activatePortalUserRaw(requestParameters);
    }

    /**
     */
    async deactivatePortalUserRaw(requestParameters: DeactivatePortalUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deactivatePortalUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/portal/{id}/deactivate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deactivatePortalUser(requestParameters: DeactivatePortalUserRequest): Promise<void> {
        await this.deactivatePortalUserRaw(requestParameters);
    }

    /**
     */
    async portalUserRaw(requestParameters: PortalUserRequest): Promise<runtime.ApiResponse<PortalUser>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling portalUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/portal/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PortalUserFromJSON(jsonValue));
    }

    /**
     */
    async portalUser(requestParameters: PortalUserRequest): Promise<PortalUser> {
        const response = await this.portalUserRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async portalUsersRaw(requestParameters: PortalUsersRequest): Promise<runtime.ApiResponse<PagePortalUser>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.fullname !== undefined) {
            queryParameters['fullname'] = requestParameters.fullname;
        }

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        if (requestParameters.statuses) {
            queryParameters['statuses'] = requestParameters.statuses;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/portal`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PagePortalUserFromJSON(jsonValue));
    }

    /**
     */
    async portalUsers(requestParameters: PortalUsersRequest): Promise<PagePortalUser> {
        const response = await this.portalUsersRaw(requestParameters);
        return await response.value();
    }

}
