/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum BatchStatus {
    INITIATED = 'INITIATED',
    IN_PROGRESS = 'IN_PROGRESS',
    FAILED = 'FAILED',
    COMPLETED = 'COMPLETED'
}

export function BatchStatusFromJSON(json: any): BatchStatus {
    return BatchStatusFromJSONTyped(json, false);
}

export function BatchStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): BatchStatus {
    return json as BatchStatus;
}

export function BatchStatusToJSON(value?: BatchStatus | null): any {
    return value as any;
}

