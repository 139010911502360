<template>
  <div class="container ms-0 mb-3" v-if="form.registrationFee !== undefined">
    <div class="col-md-5">
      <div class="row">
        <el-form
          :model="form"
          :rules="rules"
          status-icon
          ref="form"
          @validate="onValidate"
        >
          <el-form-item
            :label="$t('campaign.fee.registration')"
            prop="registrationFee"
          >
            <el-input
              :disabled="!editable || !settings.registrationFeeEditAvailable"
              v-model="form.registrationFee"
            ></el-input>
            <HistoryPopover
              :history="settingsHistory.registrationFee"
              :field-name="$t('campaign.fee.registration')"
              :value-type="historyFormat.CURRENCY"
            />
          </el-form-item>
          <el-form-item :label="$t('campaign.fee.spv')" prop="spvFee">
            <el-input
              :disabled="!editable || !settings.spvFeeEditAvailable"
              v-model="form.spvFee"
            ></el-input>
            <HistoryPopover
              :history="settingsHistory.spvFee"
              :field-name="$t('campaign.fee.spv')"
              :value-type="historyFormat.CURRENCY"
            />
          </el-form-item>
          <el-form-item
            :label="$t('campaign.fee.fundraisingCommission')"
            prop="fundraisingCommissionFee"
          >
            <el-input
              :disabled="
                !editable || !settings.fundraisingCommissionFeeEditAvailable
              "
              v-model="form.fundraisingCommissionFee"
            ></el-input>
            <HistoryPopover
              :history="settingsHistory.fundraisingCommissionFee"
              :field-name="$t('campaign.fee.fundraisingCommission')"
              :value-type="historyFormat.CURRENCY"
            />
          </el-form-item>
        </el-form>
      </div>
      <br />
      <el-button
        @click="edit"
        v-show="!editable"
        v-if="hasAuthority(authorities.CAMPAIGN_WRITE)"
        :disabled="
          !settings.registrationFeeEditAvailable &&
          !settings.fundraisingCommissionFeeEditAvailable &&
          !settings.spvFeeEditAvailable
        "
      >
        {{ $t("edit") }}
      </el-button>
      <el-button style="margin-left: 0" @click="cancel" v-show="editable">
        {{ $t("cancel") }}
      </el-button>
      <el-button
        type="primary"
        v-show="editable"
        :disabled="!isFormValid"
        @click="submit"
      >
        {{ $t("save") }}
      </el-button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { authorityMixin } from "@/util";
import {
  CampaignSettingsData,
  CampaignSettingsHistoryItem,
  CampaignSettingsItem,
} from "@/api";
import {
  ElementForm,
  InputRequired,
  numberFormat,
  NumberRange,
} from "@/util/validation";
import { campaignApi } from "@/resources";
import { isConstraintViolation, isProblem } from "@/resources/problem";
import { HistoryFormat } from "@/util/enum/HistoryFormat";
import HistoryPopover from "@/components/HistoryPopover.vue";

export default defineComponent({
  name: "CampaignFee",
  components: { HistoryPopover },
  mixins: [authorityMixin],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      settings: {} as CampaignSettingsItem,
      settingsHistory: {} as CampaignSettingsHistoryItem,
      historyFormat: HistoryFormat,
      form: {} as CampaignSettingsData,
      rules: {
        fundraisingCommissionFee: [
          new InputRequired("validation.inputRequired"),
          numberFormat,
          new NumberRange(0.0, 10.0),
        ],
        spvFee: [
          new InputRequired("validation.inputRequired"),
          numberFormat,
          new NumberRange(0.0, 5000.0),
        ],
        registrationFee: [
          new InputRequired("validation.inputRequired"),
          numberFormat,
          new NumberRange(0.0, 5000.0),
        ],
      },
      isFormValid: true,
      editable: false,
    };
  },
  watch: {
    settings() {
      Object.assign(this.form, this.settings);
    },
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      return Promise.all([this.fetchSettings(), this.fetchSettingsHistory()]);
    },
    async fetchSettings() {
      this.settings = await campaignApi.campaignSettings({
        id: Number(this.id),
      });
    },
    async fetchSettingsHistory() {
      this.settingsHistory = await campaignApi.campaignSettingsHistory({
        id: Number(this.id),
      });
    },
    cancel() {
      Object.assign(this.form, this.settings);
      this.editable = false;
    },
    onValidate(prop: string, isVal: boolean) {
      this.isFormValid = isVal;
    },
    edit() {
      this.editable = true;
      Object.assign(this.form, this.settings);
    },
    async submit() {
      const form = this.$refs["form"] as ElementForm;
      if (await form.validate()) {
        try {
          await campaignApi.updateCampaignSettings({
            id: Number(this.id),
            campaignSettingsData: this.form,
          });
          Object.assign(this.settings, this.form);
          this.editable = false;
          await this.fetchData();
          this.$emit("update");
        } catch (error) {
          if (isConstraintViolation(error)) {
            error.violations.forEach((violation) =>
              this.$notify.error(this.$t(violation.message))
            );
          } else if (isProblem(error)) {
            this.$notify.error(this.$t(error.detail));
          }
        }
      }
    },
  },
});
</script>
