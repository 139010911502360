import { createStore } from "vuex";
import { account, AccountState } from "@/store/account";
import { Features } from "@/api";
import { feature } from "@/store/feature";

export interface State {
  account: AccountState;
  feature: Features;
}

const store = createStore<State>({
  modules: {
    account,
    feature,
  },
});

export default store;
