/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CampaignType,
    CampaignTypeFromJSON,
    CampaignTypeFromJSONTyped,
    CampaignTypeToJSON,
    IdNameEntry,
    IdNameEntryFromJSON,
    IdNameEntryFromJSONTyped,
    IdNameEntryToJSON,
} from './';

/**
 * 
 * @export
 * @interface Deal
 */
export interface Deal {
    /**
     * 
     * @type {number}
     * @memberof Deal
     */
    amount: number;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof Deal
     */
    business: IdNameEntry;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof Deal
     */
    campaign: IdNameEntry;
    /**
     * 
     * @type {CampaignType}
     * @memberof Deal
     */
    campaignType: CampaignType;
    /**
     * 
     * @type {Date}
     * @memberof Deal
     */
    endAt: Date;
    /**
     * 
     * @type {number}
     * @memberof Deal
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Deal
     */
    lockInPeriod: number;
    /**
     * 
     * @type {Date}
     * @memberof Deal
     */
    startAt: Date;
}

export function DealFromJSON(json: any): Deal {
    return DealFromJSONTyped(json, false);
}

export function DealFromJSONTyped(json: any, ignoreDiscriminator: boolean): Deal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'business': IdNameEntryFromJSON(json['business']),
        'campaign': IdNameEntryFromJSON(json['campaign']),
        'campaignType': CampaignTypeFromJSON(json['campaignType']),
        'endAt': (new Date(json['endAt'])),
        'id': json['id'],
        'lockInPeriod': json['lockInPeriod'],
        'startAt': (new Date(json['startAt'])),
    };
}

export function DealToJSON(value?: Deal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'business': IdNameEntryToJSON(value.business),
        'campaign': IdNameEntryToJSON(value.campaign),
        'campaignType': CampaignTypeToJSON(value.campaignType),
        'endAt': (value.endAt.toISOString().substr(0,10)),
        'id': value.id,
        'lockInPeriod': value.lockInPeriod,
        'startAt': (value.startAt.toISOString().substr(0,10)),
    };
}


