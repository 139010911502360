<template>
  <Headline>{{ $t("user.new") }}</Headline>
  <div class="container-md ms-0 mb-5">
    <el-form :model="user" :rules="rules" status-icon ref="userForm">
      <div class="row">
        <div class="col-md-6">
          <el-form-item :label="$t('user.givenName')" prop="givenName">
            <el-input v-model="user.givenName"></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.lastName')" prop="lastName">
            <el-input v-model="user.lastName"></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.phone')" prop="phone">
            <MaskedPhoneNumber
              v-model="user.phone"
              :isoCountry="'OM'"
            ></MaskedPhoneNumber>
          </el-form-item>
          <el-form-item :label="$t('user.email')" prop="email">
            <el-input v-model="user.email"></el-input>
          </el-form-item>
        </div>
        <div class="col-md-6">
          <el-form-item :label="$t('user.birthDate')" prop="birthDate">
            <el-date-picker
              type="date"
              v-model="user.birthDate"
              class="w-100"
              format="DD/MM/YYYY"
            ></el-date-picker>
          </el-form-item>
          <el-form-item
            :label="$t('filter.residenceCountry')"
            prop="residenceCountry"
          >
            <el-select v-model="user.residenceCountry" class="w-100">
              <el-option
                v-for="(country, key) in residenceCountryOptions"
                :key="key"
                :label="$enums.globals.country(country)"
                :value="country"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('user.role')" prop="role">
            <el-select v-model="user.role" clearable class="w-100">
              <el-option
                v-for="(role, id) in roles"
                :key="id"
                :value="role"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>

  <el-button @click="$router.push({ name: 'users' })">
    {{ $t("cancel") }}
  </el-button>
  <el-button type="primary" @click="submit">
    {{ $t("save") }}
  </el-button>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { CreateUserForm, Country } from "@/api";
import {
  ElementForm,
  emailFormat,
  InputLength,
  InputRequired,
  nameFormat,
} from "@/util/validation";
import { roleApi, userApi } from "@/resources";
import Headline from "@/components/Headline.vue";
import MaskedPhoneNumber from "@/components/MaskedPhoneNumber/MaskedPhoneNumber.vue";
import { fixLocalDate } from "@/util";

export default defineComponent({
  name: "NewUser",
  components: { MaskedPhoneNumber, Headline },
  data() {
    return {
      residenceCountryOptions: Country,
      user: {} as CreateUserForm,
      roles: [] as string[],
      rules: {
        givenName: [new InputRequired(), new InputLength(100), nameFormat],
        lastName: [new InputRequired(), new InputLength(100), nameFormat],
        birthDate: [new InputRequired()],
        residenceCountry: [new InputRequired()],
        phone: [new InputRequired("validation.phoneFormatRequired")],
        email: [new InputRequired(), new InputLength(100), emailFormat],
        password: [new InputRequired()],
        role: [new InputRequired("validation.selectRequired")],
      },
    };
  },
  async mounted() {
    this.roles = await roleApi.roleOptions();
  },
  methods: {
    submit() {
      const form = this.$refs["userForm"] as ElementForm;
      form.validate((valid) => {
        if (valid) {
          this.createUser();
        }
        return false;
      });
    },
    async createUser() {
      try {
        this.user.birthDate = fixLocalDate(this.user.birthDate);
        const id = await userApi.createUser({
          createUserForm: this.user,
        });
        this.$notify.info(this.$t("user.userCreated"));
        await this.$router.push({ name: "user", params: { id: id } });
      } catch (error) {
        this.$notify.error(this.$t(error.detail));
      }
    },
  },
});
</script>
