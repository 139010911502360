<template>
  <div class="row mb-3">
    <div class="col color-light-text">
      {{ $t("legal.general.name") }}
    </div>
    <div class="col wrap-text">
      {{ legal.name }}
    </div>
    <div class="col color-light-text">
      {{ $t("legal.type.title") }}
    </div>
    <div class="col">
      {{ $enums.legal.type(legal.type, legal.typeOther) }}
    </div>
  </div>
  <div class="row mb-3">
    <div class="col color-light-text">
      {{ $t("legal.general.registrationCountry") }}
    </div>
    <div class="col">
      {{ $enums.globals.countryIso(legal.registrationCountry) }}
    </div>
    <div class="col color-light-text">
      {{ $t("legal.businessSector.title") }}
    </div>
    <div class="col">
      {{ $enums.legal.businessSector(legal.businessSector) }}
    </div>
  </div>
  <div class="row mb-3">
    <div class="col color-light-text">
      {{ $t("legal.general.establishedAt") }}
    </div>
    <div class="col">
      {{ $date(legal.establishedAt) }}
    </div>
    <div class="col color-light-text">
      {{ $t("legal.general.businessNature") }}
    </div>
    <div class="col">
      {{ legal.businessNature }}
    </div>
  </div>
  <div class="row mb-3">
    <div class="col color-light-text">
      {{ $t("legal.general.registrationNumber") }}
    </div>
    <div class="col wrap-text">
      {{ legal.registrationNumber }}
    </div>
    <div class="col color-light-text">
      {{ $t("legal.general.ownershipChangedLast5Years") }}
    </div>
    <div class="col">
      {{ $logic(legal.ownershipChangedLast5Years) }}
    </div>
  </div>
  <div class="row mb-3">
    <div class="col color-light-text">
      {{ $t("legal.general.expiryDate") }}
    </div>
    <div class="col">
      {{ $date(legal.expiryDate) }}
    </div>
    <div class="col color-light-text">
      {{ $t("legal.general.statusChangedLast5Years") }}
    </div>
    <div class="col">
      {{ $logic(legal.statusChangedLast5Years) }}
    </div>
  </div>
  <div class="row mb-3">
    <div class="col color-light-text">
      {{ $t("legal.general.taxIdNumber") }}
    </div>
    <div class="col wrap-text">
      {{ legal.taxIdNumber ?? "-" }}
    </div>
    <div class="col color-light-text">
      {{ $t("legal.general.numberOfEmployees") }}
    </div>
    <div class="col">
      {{ legal.numberOfEmployees }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Legal } from "@/api";

export default defineComponent({
  name: "LegalGeneralInfo",
  props: {
    legal: {
      type: Object as PropType<Legal>,
      required: true,
    },
  },
});
</script>

<style scoped></style>
