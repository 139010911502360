/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Currency,
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './';

/**
 * 
 * @export
 * @interface LegalFinanceItem
 */
export interface LegalFinanceItem {
    /**
     * 
     * @type {Currency}
     * @memberof LegalFinanceItem
     */
    currency?: Currency;
    /**
     * 
     * @type {string}
     * @memberof LegalFinanceItem
     */
    groupAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalFinanceItem
     */
    groupName?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalFinanceItem
     */
    initialFundsSource?: string;
    /**
     * 
     * @type {number}
     * @memberof LegalFinanceItem
     */
    localTurnoverCurrent?: number;
    /**
     * 
     * @type {number}
     * @memberof LegalFinanceItem
     */
    localTurnoverProjected?: number;
    /**
     * 
     * @type {number}
     * @memberof LegalFinanceItem
     */
    totalAnnualRevenue?: number;
    /**
     * 
     * @type {number}
     * @memberof LegalFinanceItem
     */
    totalAssets?: number;
}

export function LegalFinanceItemFromJSON(json: any): LegalFinanceItem {
    return LegalFinanceItemFromJSONTyped(json, false);
}

export function LegalFinanceItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): LegalFinanceItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currency': !exists(json, 'currency') ? undefined : CurrencyFromJSON(json['currency']),
        'groupAddress': !exists(json, 'groupAddress') ? undefined : json['groupAddress'],
        'groupName': !exists(json, 'groupName') ? undefined : json['groupName'],
        'initialFundsSource': !exists(json, 'initialFundsSource') ? undefined : json['initialFundsSource'],
        'localTurnoverCurrent': !exists(json, 'localTurnoverCurrent') ? undefined : json['localTurnoverCurrent'],
        'localTurnoverProjected': !exists(json, 'localTurnoverProjected') ? undefined : json['localTurnoverProjected'],
        'totalAnnualRevenue': !exists(json, 'totalAnnualRevenue') ? undefined : json['totalAnnualRevenue'],
        'totalAssets': !exists(json, 'totalAssets') ? undefined : json['totalAssets'],
    };
}

export function LegalFinanceItemToJSON(value?: LegalFinanceItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currency': CurrencyToJSON(value.currency),
        'groupAddress': value.groupAddress,
        'groupName': value.groupName,
        'initialFundsSource': value.initialFundsSource,
        'localTurnoverCurrent': value.localTurnoverCurrent,
        'localTurnoverProjected': value.localTurnoverProjected,
        'totalAnnualRevenue': value.totalAnnualRevenue,
        'totalAssets': value.totalAssets,
    };
}


