/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CampaignType,
    CampaignTypeFromJSON,
    CampaignTypeFromJSONTyped,
    CampaignTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface RequestedCampaignData
 */
export interface RequestedCampaignData {
    /**
     * 
     * @type {number}
     * @memberof RequestedCampaignData
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof RequestedCampaignData
     */
    daysDuration: number;
    /**
     * 
     * @type {CampaignType}
     * @memberof RequestedCampaignData
     */
    type: CampaignType;
}

export function RequestedCampaignDataFromJSON(json: any): RequestedCampaignData {
    return RequestedCampaignDataFromJSONTyped(json, false);
}

export function RequestedCampaignDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestedCampaignData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'daysDuration': json['daysDuration'],
        'type': CampaignTypeFromJSON(json['type']),
    };
}

export function RequestedCampaignDataToJSON(value?: RequestedCampaignData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'daysDuration': value.daysDuration,
        'type': CampaignTypeToJSON(value.type),
    };
}


