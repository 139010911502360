/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CampaignSummaryItem
 */
export interface CampaignSummaryItem {
    /**
     * 
     * @type {number}
     * @memberof CampaignSummaryItem
     */
    committedAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignSummaryItem
     */
    committedPercent?: number;
    /**
     * 
     * @type {Date}
     * @memberof CampaignSummaryItem
     */
    date?: Date;
    /**
     * 
     * @type {number}
     * @memberof CampaignSummaryItem
     */
    receivedAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignSummaryItem
     */
    tentativeAmount?: number;
}

export function CampaignSummaryItemFromJSON(json: any): CampaignSummaryItem {
    return CampaignSummaryItemFromJSONTyped(json, false);
}

export function CampaignSummaryItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): CampaignSummaryItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'committedAmount': !exists(json, 'committedAmount') ? undefined : json['committedAmount'],
        'committedPercent': !exists(json, 'committedPercent') ? undefined : json['committedPercent'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'receivedAmount': !exists(json, 'receivedAmount') ? undefined : json['receivedAmount'],
        'tentativeAmount': !exists(json, 'tentativeAmount') ? undefined : json['tentativeAmount'],
    };
}

export function CampaignSummaryItemToJSON(value?: CampaignSummaryItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'committedAmount': value.committedAmount,
        'committedPercent': value.committedPercent,
        'date': value.date === undefined ? undefined : (value.date.toISOString().substr(0,10)),
        'receivedAmount': value.receivedAmount,
        'tentativeAmount': value.tentativeAmount,
    };
}


