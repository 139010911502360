/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum InvestmentStatus {
    DRAFT = 'DRAFT',
    WAITLISTED = 'WAITLISTED',
    SUBMITTED = 'SUBMITTED',
    UNDER_PROCESS = 'UNDER_PROCESS',
    WAITING_FOR_DATA = 'WAITING_FOR_DATA',
    ABANDONED = 'ABANDONED',
    APPROVED = 'APPROVED',
    SIGNING_TERM_SHEET = 'SIGNING_TERM_SHEET',
    AWAITING_PLATFORM_FEE = 'AWAITING_PLATFORM_FEE',
    RESERVED = 'RESERVED',
    COMPLETED = 'COMPLETED',
    CANCELLATION_REQUEST = 'CANCELLATION_REQUEST',
    CANCELLED = 'CANCELLED',
    DECLINED = 'DECLINED',
    REFUND_PENDING = 'REFUND_PENDING',
    REFUND_IN_PROGRESS = 'REFUND_IN_PROGRESS',
    REFUND_ERROR = 'REFUND_ERROR',
    REFUND_COMPLETED = 'REFUND_COMPLETED',
    CLOSED = 'CLOSED',
    INVESTED = 'INVESTED'
}

export function InvestmentStatusFromJSON(json: any): InvestmentStatus {
    return InvestmentStatusFromJSONTyped(json, false);
}

export function InvestmentStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestmentStatus {
    return json as InvestmentStatus;
}

export function InvestmentStatusToJSON(value?: InvestmentStatus | null): any {
    return value as any;
}

