/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InvestorCategory,
    InvestorCategoryFromJSON,
    InvestorCategoryFromJSONTyped,
    InvestorCategoryToJSON,
    InvestorType,
    InvestorTypeFromJSON,
    InvestorTypeFromJSONTyped,
    InvestorTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface InvestorListItem
 */
export interface InvestorListItem {
    /**
     * 
     * @type {InvestorCategory}
     * @memberof InvestorListItem
     */
    category?: InvestorCategory;
    /**
     * 
     * @type {string}
     * @memberof InvestorListItem
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestorListItem
     */
    email?: string;
    /**
     * 
     * @type {number}
     * @memberof InvestorListItem
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InvestorListItem
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestorListItem
     */
    phone?: string;
    /**
     * 
     * @type {InvestorType}
     * @memberof InvestorListItem
     */
    type?: InvestorType;
}

export function InvestorListItemFromJSON(json: any): InvestorListItem {
    return InvestorListItemFromJSONTyped(json, false);
}

export function InvestorListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestorListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'category': !exists(json, 'category') ? undefined : InvestorCategoryFromJSON(json['category']),
        'country': !exists(json, 'country') ? undefined : json['country'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'type': !exists(json, 'type') ? undefined : InvestorTypeFromJSON(json['type']),
    };
}

export function InvestorListItemToJSON(value?: InvestorListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'category': InvestorCategoryToJSON(value.category),
        'country': value.country,
        'email': value.email,
        'id': value.id,
        'name': value.name,
        'phone': value.phone,
        'type': InvestorTypeToJSON(value.type),
    };
}


