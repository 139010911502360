
import { computed, defineComponent } from "vue";
import TabLink from "@/components/TabLink.vue";
import {
  AccreditationItem,
  AccreditationItemHistory,
  AccreditationStatus,
  BackOfficeFlowEntityStateAccreditationStatus,
} from "@/api";
import { accreditationApi, accreditationOperationApi } from "@/resources";
import Headline from "@/components/Headline.vue";
import BackLink from "@/components/BackLink.vue";
import { authorityMixin } from "@/util";
import Status from "@/components/Status.vue";
import OkCancelDialog from "@/views/components/OkCancelDialog.vue";
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";

export default defineComponent({
  name: "AccreditationView",
  mixins: [authorityMixin],
  components: {
    Headline,
    TabLink,
    BackLink,
    Status,
    OkCancelDialog,
    CollapseTransition,
  },
  props: ["id"],
  provide() {
    return {
      accreditationId: computed(() => this.id),
      accreditation: computed(() =>
        this.accreditation.id ? this.accreditation : {}
      ),
      accreditationHistory: computed(() =>
        this.accreditationHistory.status ? this.accreditationHistory : {}
      ),
    };
  },
  data() {
    return {
      accreditation: {} as AccreditationItem,
      accreditationHistory: {} as AccreditationItemHistory,
      accreditationState: {} as BackOfficeFlowEntityStateAccreditationStatus,
      accreditationStatuses: Object.values(AccreditationStatus).filter(
        (status) =>
          status !== AccreditationStatus.DRAFT &&
          status !== AccreditationStatus.RECEIVED
      ),
    };
  },
  async mounted() {
    await this.fetchAccreditationData();
  },
  methods: {
    async fetchAccreditationData() {
      await Promise.all([
        this.fetchAccreditation(),
        this.fetchAccreditationState(),
        this.fetchAccreditationHistory(),
      ]);
    },
    async fetchAccreditation() {
      this.accreditation = await accreditationApi.accreditation({
        id: this.id,
      });
    },
    async fetchAccreditationState() {
      this.accreditationState = await accreditationApi.getAccreditationState({
        id: this.id,
      });
    },
    async fetchAccreditationHistory() {
      this.accreditationHistory = await accreditationApi.accreditationHistory({
        id: this.id,
      });
    },
    async changeAccreditationStatus(status: AccreditationStatus) {
      await accreditationOperationApi.changeAccreditationStatus({
        id: this.id,
        backOfficeFlowStatusOperationRequestAccreditationStatus: {
          status: status,
        },
      });
    },
  },
  computed: {
    investorOpened(): boolean {
      return this.$route.name
        ? this.$route.name.toString().startsWith("accreditationInvestor")
        : false;
    },
  },
});
