
import { defineComponent } from "vue";
import { ElementForm, InputLength } from "@/util/validation";
import { Filter } from "@/views/business/BusinessList.vue";

export default defineComponent({
  emits: ["update:businessFilter", "submit"],
  props: ["businessFilter"],
  data() {
    return {
      filter: {} as Filter,
      isFormValid: true,
      rules: {
        name: [new InputLength(70)],
        registrationNumber: [new InputLength(50)],
        email: [new InputLength(255)],
      },
    };
  },
  async mounted() {
    this.filter = this.businessFilter;
  },
  methods: {
    submit() {
      const form = this.$refs["filter-form"] as ElementForm;
      form.validate(async (valid) => {
        if (valid) {
          this.$emit("submit");
        }
      });
    },
    onValidate(prop: string, isVal: boolean) {
      this.isFormValid = isVal;
    },
    resetFilter() {
      this.filter = {} as Filter;
      this.$emit("update:businessFilter", this.filter);
      this.$emit("submit");
    },
  },
});
