<template>
  <Headline
    >{{ $t("sidebar.accreditations") }}
    <template v-slot:actions>
      <el-button
        v-if="!filterVisible"
        class="filter"
        @click="filterVisible = true"
        >{{ $t("filter.show") }}
      </el-button>
      <el-button
        v-if="filterVisible"
        class="filter"
        @click="filterVisible = false"
        >{{ $t("filter.hide") }}
      </el-button>
    </template>
  </Headline>
  <AccreditationFilter
    v-model:accreditation-filter="filter"
    v-if="filterVisible"
    @submit="fetchData"
  ></AccreditationFilter>
  <el-table
    :data="accreditationPage.content"
    @sort-change="onSortChange($event, pageRequest)"
    @cell-click="onCellClick"
    :row-style="{ cursor: 'pointer' }"
  >
    <el-table-column :label="$t('id')" prop="id" sortable="custom" width="60">
    </el-table-column>
    <el-table-column
      :label="$t('accreditation.investor')"
      :show-overflow-tooltip="true"
    >
      <template #default="scope">
        <router-link
          v-if="hasAuthority(authorities.INVESTOR_READ)"
          :to="{ name: 'investor', params: { id: scope.row.investor.id } }"
          >{{ scope.row.investor.name }}
        </router-link>
        <span v-else>{{ scope.row.investor.name }}</span>
      </template>
    </el-table-column>
    <el-table-column :label="$t('accreditation.previousCategory')">
      <template #default="scope">
        {{ $enums.investor.category(scope.row.previousCategory) }}
      </template>
    </el-table-column>
    <el-table-column :label="$t('accreditation.category')">
      <template #default="scope">
        {{ $enums.investor.category(scope.row.category) }}
      </template>
    </el-table-column>

    <el-table-column :label="$t('createdAt')" :show-overflow-tooltip="true">
      <template #default="scope">{{ $date(scope.row.createdAt) }}</template>
    </el-table-column>
    <el-table-column :label="$t('accreditation.approvedAt')">
      <template #default="scope">{{ $date(scope.row.approvedAt) }}</template>
    </el-table-column>
    <el-table-column :label="$t('accreditation.validTill')">
      <template #default="scope">{{ $date(scope.row.validTill) }}</template>
    </el-table-column>
    <el-table-column
      :label="$t('status')"
      min-width="220"
      align="center"
      class-name="status"
    >
      <template #default="scope">
        <Status
          size="small"
          :color="$enums.accreditation.statusColor(scope.row.status)"
        >
          {{ $enums.accreditation.status(scope.row.status) }}
        </Status>
      </template>
    </el-table-column>
  </el-table>

  <TableFooter
    @size-change="onSizeChange($event, pageRequest)"
    @current-change="onCurrentChange($event, pageRequest)"
    :page="accreditationPage"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  authorityMixin,
  fixLocalDates,
  PageRequest,
  pageSortMixin,
} from "@/util";
import TableFooter from "@/components/TableFooter.vue";
import Headline from "@/components/Headline.vue";
import {
  AccreditationListItem,
  AccreditationStatus,
  PageAccreditationListItem,
} from "@/api";
import Status from "@/components/Status.vue";
import AccreditationFilter from "@/views/accreditation/AccreditationFilter.vue";
import { accreditationApi } from "@/resources";

export interface Filter extends Record<string, unknown> {
  investorName: string | undefined;
  status: AccreditationStatus | undefined;
  createdAtFrom: Date | undefined;
  createdAtTo: Date | undefined;
  approvedAtFrom: Date | undefined;
  approvedAtTo: Date | undefined;
  validTillFrom: Date | undefined;
  validTillTo: Date | undefined;
}

export default defineComponent({
  name: "AccreditationList",
  mixins: [pageSortMixin, authorityMixin],
  components: { TableFooter, Headline, AccreditationFilter, Status },
  data: () => ({
    accreditationPage: {} as PageAccreditationListItem,
    pageRequest: {} as PageRequest,
    filterVisible: false,
    filter: {} as Filter,
  }),
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      fixLocalDates(this.filter);
      this.accreditationPage = await accreditationApi.accreditations({
        ...this.pageRequest,
        ...this.filter,
      });
    },
    onCellClick(row: AccreditationListItem) {
      this.$router.push({
        name: "accreditation",
        params: { id: row.id },
      });
    },
  },
});
</script>
