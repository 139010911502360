/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PreScreeningStatus,
    PreScreeningStatusFromJSON,
    PreScreeningStatusFromJSONTyped,
    PreScreeningStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface BackOfficeFlowEntityStatePreScreeningStatus
 */
export interface BackOfficeFlowEntityStatePreScreeningStatus {
    /**
     * 
     * @type {Array<PreScreeningStatus>}
     * @memberof BackOfficeFlowEntityStatePreScreeningStatus
     */
    nextStatuses: Array<PreScreeningStatus>;
    /**
     * 
     * @type {PreScreeningStatus}
     * @memberof BackOfficeFlowEntityStatePreScreeningStatus
     */
    previousStatus?: PreScreeningStatus;
}

export function BackOfficeFlowEntityStatePreScreeningStatusFromJSON(json: any): BackOfficeFlowEntityStatePreScreeningStatus {
    return BackOfficeFlowEntityStatePreScreeningStatusFromJSONTyped(json, false);
}

export function BackOfficeFlowEntityStatePreScreeningStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): BackOfficeFlowEntityStatePreScreeningStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nextStatuses': ((json['nextStatuses'] as Array<any>).map(PreScreeningStatusFromJSON)),
        'previousStatus': !exists(json, 'previousStatus') ? undefined : PreScreeningStatusFromJSON(json['previousStatus']),
    };
}

export function BackOfficeFlowEntityStatePreScreeningStatusToJSON(value?: BackOfficeFlowEntityStatePreScreeningStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nextStatuses': ((value.nextStatuses as Array<any>).map(PreScreeningStatusToJSON)),
        'previousStatus': PreScreeningStatusToJSON(value.previousStatus),
    };
}


