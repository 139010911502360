
import { defineComponent, inject } from "vue";
import {
  BackOfficeFlowEntityStateCampaignStatus,
  CampaignFundraiserVerification,
  CampaignItem,
  CampaignStatus,
} from "@/api";
import { campaignApi, campaignOperationApi } from "@/resources";
import Decision from "@/components/Decision.vue";
import { isConstraintViolation, isProblem } from "@/resources/problem";
import { InputLength } from "@/util/validation";
import { authorityMixin } from "@/util";
import { Campaign } from "@/util/enum/campaign";

interface CommentForm {
  comment: string | undefined;
}

export default defineComponent({
  name: "CampaignFundraiserVerification",
  computed: {
    Campaign() {
      return Campaign;
    },
  },
  mixins: [authorityMixin],
  components: { Decision },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      rules: {
        comment: [new InputLength(1000)],
      },
      verification: {} as CampaignFundraiserVerification,
      campaignStatuses: CampaignStatus,
      state: inject("state") as BackOfficeFlowEntityStateCampaignStatus,
      campaign: inject("campaign") as CampaignItem,
      editable: false,
      commentForm: {} as CommentForm,
    };
  },
  async mounted() {
    await this.fetchVerification();
  },
  methods: {
    async fetchVerification() {
      this.verification = await campaignApi.getCampaignFundraiserVerification({
        id: Number(this.id),
      });
      this.commentForm = { comment: this.verification.comment };
    },
    async updateValue(field: string, value: boolean | undefined) {
      try {
        if (
          value != undefined ||
          (value == undefined && !this.verification.verificationCompleted)
        ) {
          this.verification = await campaignApi.updateCampaignFundraiserVerification(
            {
              id: Number(this.id),
              campaignFundraiserVerification: {
                ...this.verification,
                [field]: value,
              },
            }
          );
        }
      } catch (error) {
        if (isConstraintViolation(error)) {
          error.violations.forEach((violation) =>
            this.$notify.error(this.$t(violation.message))
          );
        } else if (isProblem(error)) {
          this.$notify.error(this.$t(error.detail));
        }
      }
    },
    async saveComment() {
      try {
        this.verification = await campaignApi.updateCampaignFundraiserVerification(
          {
            id: Number(this.id),
            campaignFundraiserVerification: {
              ...this.verification,
              comment: this.commentForm.comment,
            },
          }
        );
        this.editable = false;
      } catch (e) {
        if (isConstraintViolation(e)) {
          e.violations.forEach((violation) =>
            this.$notify.error(this.$t(violation.message))
          );
        } else if (isProblem(e)) {
          this.$notify.error(this.$t(e.detail));
        }
      }
    },
    async completeFundraiserVerification() {
      await campaignOperationApi.campaignVerification({ id: Number(this.id) });
    },
    edit() {
      this.editable = true;
    },
    cancel() {
      this.commentForm.comment = this.verification.comment;
      this.editable = false;
    },
  },
});
