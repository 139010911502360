import { Country, Currency, Gender } from "@/api";
import { i18n } from "@/util";
import { getName } from "i18n-iso-countries";

export class Globals {
  static country(country: Country): string {
    return country ? i18n.global.t("globals.country." + country) : "-";
  }

  static countryIso(countryCode: string): string {
    return countryCode ? getName(countryCode, "en") : "-";
  }

  static currency(currency: Currency): string {
    return currency ? i18n.global.t("globals.currency." + currency) : "-";
  }
  static gender(gender: Gender): string {
    return gender ? i18n.global.t("globals.gender." + gender) : "-";
  }
}
