<template>
  <nav>
    <ul class="nav flex-column">
      <img src="@/assets/WGDBWideLogo.png" alt="Logo" class="logo" />
      <li class="nav-item"></li>
      <SidebarLink
        v-if="hasAuthority(authorities.BUSINESS_READ)"
        to="businesses"
        :active="[
          'businesses',
          'business',
          'businessGeneralInfo',
          'businessContactInfo',
          'businessFinanceInfo',
          'businessCommerceInfo',
          'businessOwnershipInfo',
          'businessBankInfo',
          'businessAttachments',
        ]"
      >
        {{ $t("sidebar.businesses") }}
      </SidebarLink>

      <SidebarLink
        v-if="hasAuthority(authorities.CAMPAIGN_READ)"
        to="campaigns"
        :active="[
          'campaign',
          'campaigns',
          'campaignDetails',
          'campaignGeneralInfo',
          'campaignFee',
          'campaignSummary',
          'campaignAttachments',
          'campaignBusiness',
          'campaignBusinessGeneralInfo',
          'campaignBusinessContactInfo',
          'campaignBusinessFinanceInfo',
          'campaignBusinessCommerceInfo',
          'campaignBusinessOwnershipInfo',
          'campaignBusinessBankInfo',
          'campaignBusinessAttachments',
          'campaignPreScreeningDetails',
          'campaignPreScreeningGeneralInfo',
          'campaignPreScreeningAttachments',
          'campaignPreScreeningReviewNotes',
          'campaignPreScreeningFollowUpNotes',
          'campaignReviewNotes',
          'campaignFundraiserVerification',
          'campaignCampaignVerification',
          'campaignCollateralReview',
          'campaignContent',
          'campaignContentFiles',
          'campaignDocuments',
        ]"
      >
        {{ $t("sidebar.campaigns") }}
      </SidebarLink>
      <SidebarLink
        v-if="hasAuthority(authorities.USER_READ)"
        :active="usersActiveRoutes"
        @click="showUsers ? (showUsers = false) : (showUsers = true)"
      >
        {{ $t("sidebar.users") }}
        <i class="el-icon-arrow-down el-icon--right"></i>
      </SidebarLink>
      <SidebarLink
        v-if="hasAuthority(authorities.USER_READ)"
        v-show="showUsers"
        class="nested"
        to="users"
        :active="['users', 'user', 'userDetails', 'newUser']"
      >
        {{ $t("sidebar.backOfficeUsers") }}
      </SidebarLink>
      <SidebarLink
        v-if="hasAuthority(authorities.USER_READ)"
        v-show="showUsers"
        class="nested"
        to="portalUsers"
        :active="['portalUsers', 'portalUser', 'portalUserDetails']"
      >
        {{ $t("sidebar.portalUsers") }}
      </SidebarLink>
      <SidebarLink
        v-if="hasAuthority(authorities.ROLE_READ)"
        to="roles"
        :active="['roles', 'newRole', 'role', 'roleDetails']"
      >
        {{ $t("sidebar.roles") }}
      </SidebarLink>
      <SidebarLink
        v-if="hasAuthority(authorities.INVESTOR_READ)"
        to="investors"
        :active="[
          'investor',
          'investors',
          'investorGeneralInfo',
          'investorContactDetails',
          'investorOccupationIncome',
          'investorBankDetails',
          'investorAttachments',
          'investorFinancialInfo',
          'investorOwnershipInfo',
        ]"
      >
        {{ $t("sidebar.investors") }}
      </SidebarLink>
      <SidebarLink
        v-if="hasAuthority(authorities.DEAL_READ)"
        to="deals"
        :active="['deal', 'deals']"
      >
        {{ $t("sidebar.deals") }}
      </SidebarLink>
      <SidebarLink
        v-if="hasAuthority(authorities.TRANSACTION_READ)"
        to="transactions"
        :active="transactionActiveRoutes"
        @click="showBatch = !showBatch"
      >
        {{ $t("sidebar.transactions") }}
        <i
          v-if="hasAuthority(authorities.TRANSACTION_BATCH)"
          class="el-icon-arrow-down el-icon--right"
        ></i>
      </SidebarLink>
      <SidebarLink
        v-if="hasAuthority(authorities.TRANSACTION_BATCH)"
        v-show="showBatch"
        class="nested"
        to="batches"
        :active="['batches']"
      >
        {{ $t("sidebar.batches") }}
      </SidebarLink>
      <SidebarLink
        v-if="hasAuthority(authorities.INVESTMENT_READ)"
        to="investments"
        :active="[
          'investments',
          'investment',
          'investmentDetails',
          'investmentCampaignReview',
          'investmentAttachments',
          'investmentDocuments',
          'investmentInvestor',
          'investmentInvestorGeneralInfo',
          'investmentInvestorContactDetails',
          'investmentInvestorFinancialInfo',
          'investmentInvestorOccupationIncome',
          'investmentInvestorBankDetails',
          'investmentInvestorOwnershipInfo',
          'investmentInvestorAttachments',
          'investorVerification',
        ]"
      >
        {{ $t("sidebar.investments") }}
      </SidebarLink>
      <SidebarLink
        v-if="hasAuthority(authorities.PRE_SCREENING_READ)"
        to="preScreenings"
        :active="[
          'preScreenings',
          'preScreening',
          'preScreeningDetails',
          'preScreeningGeneralInfo',
          'preScreeningAttachments',
          'preScreeningFollowUpNotes',
          'preScreeningReviewNotes',
        ]"
      >
        {{ $t("sidebar.preScreening") }}
      </SidebarLink>
      <SidebarLink
        v-if="hasAuthority(authorities.SETTINGS_READ)"
        to="settings"
        :active="[
          'settings',
          'settingsFeeInvestor',
          'settingsFeeCommon',
          'settingsFee',
          'settingsInvestorLimit',
          'settingsInvestorLimitLegal',
          'settingsInvestorLimitIndividual',
          'settingsAccountDetails',
        ]"
      >
        {{ $t("sidebar.settings") }}
      </SidebarLink>
      <SidebarLink
        v-if="hasAuthority(authorities.ACCREDITATION_READ)"
        to="accreditations"
        :active="[
          'accreditations',
          'accreditation',
          'accreditationGeneralInfo',
          'accreditationAttachments',
          'accreditationInvestor',
          'accreditationInvestorGeneralInfo',
          'accreditationInvestorContactDetails',
          'accreditationInvestorFinancialInfo',
          'accreditationInvestorOccupationIncome',
          'accreditationInvestorBankDetails',
          'accreditationInvestorOwnershipInfo',
          'accreditationInvestorAttachments',
        ]"
      >
        {{ $t("sidebar.accreditations") }}
      </SidebarLink>
    </ul>
  </nav>
  <TheUserMenu />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SidebarLink from "@/components/SidebarLink.vue";
import { authorityMixin } from "@/util";
import TheUserMenu from "@/components/TheUserMenu.vue";
import { RouteRecordNormalized } from "vue-router";

export default defineComponent({
  name: "TheSidebar",
  components: { SidebarLink, TheUserMenu },
  mixins: [authorityMixin],
  data() {
    return {
      showUsers: true,
      showBatch: true,
      usersActiveRoutes: [
        "users",
        "user",
        "userDetails",
        "newUser",
        "portalUsers",
        "portalUser",
        "portalUserDetails",
      ],
      transactionActiveRoutes: ["transaction", "transactions", "batches"],
    };
  },
  watch: {
    $route(to: RouteRecordNormalized) {
      if (to && to.name) {
        if (!this.usersActiveRoutes.includes(to.name as string)) {
          this.showUsers = false;
        }
        if (!this.transactionActiveRoutes.includes(to.name as string)) {
          this.showBatch = false;
        }
      }
    },
  },
});
</script>

<style scoped lang="scss">
@import "src/styles/variables";

nav {
  font-size: 18px;

  .nav-item:first-child {
    display: flex;
    align-items: center;
    margin: 25px 0 35px 15px;
    font-family: $font-medium;
    color: $color-dark-text;
    line-height: 1.2;

    span {
      margin-left: 15px;
    }
  }
}

.nested {
  margin-left: 20px;
}

.logo {
  display: flex;
  align-items: center;
  width: 150px;
  margin: 30px 0 45px 25px;
}

@media (max-width: $width-md) {
  nav {
    font-size: 16px;
  }
}
</style>
