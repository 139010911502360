<template>
  <div class="container ms-0 mb-0" v-if="legal.name">
    <div class="row">
      <div class="col mb-3">
        <CurrentAddress v-model:current-address="address.currentAddress">
          <div class="col mb-3">
            <LegalContact v-model:legal="legal" />
          </div>
        </CurrentAddress>
      </div>
      <div class="col mb-3">
        <PermanentAddress
          v-model:permanent-address="address.permanentAddress"
        />
      </div>
      <div class="col mb-3">
        <ContactPerson v-model:contact="legal.contact" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { Address, Legal } from "@/api";
import LegalContact from "@/views/components/LegalContact.vue";
import ContactPerson from "@/views/components/ContactPerson.vue";
import PermanentAddress from "@/views/components/PermanentAddress.vue";
import CurrentAddress from "@/views/components/CurrentAddress.vue";

export default defineComponent({
  name: "BusinessContactInfo",
  components: {
    CurrentAddress,
    ContactPerson,
    LegalContact,
    PermanentAddress,
  },
  data() {
    return {
      legal: inject("legal") as Legal,
      address: inject("address") as Address,
    };
  },
});
</script>
