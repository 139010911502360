/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InvestorIndividualLimitSettingsItem
 */
export interface InvestorIndividualLimitSettingsItem {
    /**
     * 
     * @type {number}
     * @memberof InvestorIndividualLimitSettingsItem
     */
    angel: number;
    /**
     * 
     * @type {number}
     * @memberof InvestorIndividualLimitSettingsItem
     */
    retail: number;
}

export function InvestorIndividualLimitSettingsItemFromJSON(json: any): InvestorIndividualLimitSettingsItem {
    return InvestorIndividualLimitSettingsItemFromJSONTyped(json, false);
}

export function InvestorIndividualLimitSettingsItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestorIndividualLimitSettingsItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'angel': json['angel'],
        'retail': json['retail'],
    };
}

export function InvestorIndividualLimitSettingsItemToJSON(value?: InvestorIndividualLimitSettingsItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'angel': value.angel,
        'retail': value.retail,
    };
}


