/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InvestmentDeclineReason,
    InvestmentDeclineReasonFromJSON,
    InvestmentDeclineReasonFromJSONTyped,
    InvestmentDeclineReasonToJSON,
} from './';

/**
 * 
 * @export
 * @interface DeclineInvestmentRequest
 */
export interface DeclineInvestmentRequest {
    /**
     * 
     * @type {InvestmentDeclineReason}
     * @memberof DeclineInvestmentRequest
     */
    declineReason: InvestmentDeclineReason;
    /**
     * 
     * @type {string}
     * @memberof DeclineInvestmentRequest
     */
    reason?: string;
}

export function DeclineInvestmentRequestFromJSON(json: any): DeclineInvestmentRequest {
    return DeclineInvestmentRequestFromJSONTyped(json, false);
}

export function DeclineInvestmentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeclineInvestmentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'declineReason': InvestmentDeclineReasonFromJSON(json['declineReason']),
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
    };
}

export function DeclineInvestmentRequestToJSON(value?: DeclineInvestmentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'declineReason': InvestmentDeclineReasonToJSON(value.declineReason),
        'reason': value.reason,
    };
}


