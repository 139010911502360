import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "bootstrap/dist/css/bootstrap.css";
import ElementPlus from "element-plus";
import "element-plus/lib/theme-chalk/index.css";
import "./styles/fonts/fonts.scss";
import "./styles/index.scss";
import countries from "i18n-iso-countries/langs/en.json";
import { registerLocale } from "i18n-iso-countries";
import {
  currency,
  currencyValue,
  omrCurrencyValue,
  date,
  dateTime,
  enums,
  i18n,
  notifications,
  phone,
  homeWorkPhone,
  time,
  logic,
  dateTimeSeconds,
} from "@/util";
import Maska from "maska";

library.add(fas);

const app = createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(ElementPlus)
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(Maska);
app.config.globalProperties.$enums = enums;
app.config.globalProperties.$date = date;
app.config.globalProperties.$dateTime = dateTime;
app.config.globalProperties.$dateTimeSeconds = dateTimeSeconds;
app.config.globalProperties.$time = time;
app.config.globalProperties.$curr = currency;
app.config.globalProperties.$currValue = currencyValue;
app.config.globalProperties.$omrCurrValue = omrCurrencyValue;
app.config.globalProperties.$phone = phone;
app.config.globalProperties.$homeWorkPhone = homeWorkPhone;
app.config.globalProperties.$notify = notifications;
app.config.globalProperties.$logic = logic;

store.dispatch("feature/loadFeatures");
registerLocale(countries);

app.mount("#app");
