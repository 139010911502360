/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum UserActor {
    USER = 'USER',
    SYSTEM = 'SYSTEM'
}

export function UserActorFromJSON(json: any): UserActor {
    return UserActorFromJSONTyped(json, false);
}

export function UserActorFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserActor {
    return json as UserActor;
}

export function UserActorToJSON(value?: UserActor | null): any {
    return value as any;
}

