
import { defineComponent } from "vue";
import { dealApi } from "@/resources";
import Headline from "@/components/Headline.vue";
import { PageInvestorListItem, Deal, CampaignType } from "@/api";
import {
  authorityMixin,
  fixLocalDate,
  PageRequest,
  pageSortMixin,
} from "@/util";
import TableFooter from "@/components/TableFooter.vue";
import DealFilter from "@/views/deal/DealFilter.vue";

export interface Filter {
  businessName: string | undefined;
  campaignTypes: CampaignType[];
  startFrom: Date | undefined;
  startTo: Date | undefined;
  endFrom: Date | undefined;
  endTo: Date | undefined;
  amountFrom: number | undefined;
  amountTo: number | undefined;
}

export default defineComponent({
  name: "DealList",
  components: { DealFilter, Headline, TableFooter },
  mixins: [pageSortMixin, authorityMixin],
  data: () => ({
    dealPage: {} as PageInvestorListItem,
    pageRequest: {} as PageRequest,
    filterVisible: false,
    filter: {} as Filter,
  }),
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.filter.startFrom = this.filter.startFrom
        ? fixLocalDate(this.filter.startFrom)
        : undefined;
      this.filter.startTo = this.filter.startTo
        ? fixLocalDate(this.filter.startTo)
        : undefined;
      this.filter.endFrom = this.filter.endFrom
        ? fixLocalDate(this.filter.endFrom)
        : undefined;
      this.filter.endTo = this.filter.endTo
        ? fixLocalDate(this.filter.endTo)
        : undefined;

      this.dealPage = await dealApi.deals({
        ...this.pageRequest,
        ...this.filter,
      });
    },
    onRowClick(row: Deal) {
      this.$router.push({
        name: "deal",
        params: { id: row.id },
      });
    },
  },
});
