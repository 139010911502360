import { i18n } from "@/util";
import { InvestmentStatus } from "@/api";

export class Investment {
  static TERMINAL_STATUSES: Array<InvestmentStatus> = [
    InvestmentStatus.CANCELLED,
    InvestmentStatus.DECLINED,
    InvestmentStatus.ABANDONED,
    InvestmentStatus.CLOSED,
    InvestmentStatus.INVESTED,
    InvestmentStatus.REFUND_COMPLETED,
  ] as InvestmentStatus[];

  static status(status: InvestmentStatus): string {
    return status ? i18n.global.t("investment.status." + status) : "-";
  }

  static statusColors: Map<InvestmentStatus, string> = new Map([
    [InvestmentStatus.DRAFT, "#39cb11"],
    [InvestmentStatus.WAITLISTED, "#e78b2d"],
    [InvestmentStatus.SUBMITTED, "#a26500"],
    [InvestmentStatus.UNDER_PROCESS, "#ca0fd9"],
    [InvestmentStatus.WAITING_FOR_DATA, "#7601f3"],
    [InvestmentStatus.ABANDONED, "#d70e15"],
    [InvestmentStatus.APPROVED, "#0cadc9"],
    [InvestmentStatus.SIGNING_TERM_SHEET, "#0f7dbb"],
    [InvestmentStatus.AWAITING_PLATFORM_FEE, "#0a44d3"],
    [InvestmentStatus.RESERVED, "#124805"],
    [InvestmentStatus.COMPLETED, "#dcb50e"],
    [InvestmentStatus.INVESTED, "#dcb50e"],
    [InvestmentStatus.CANCELLATION_REQUEST, "#0943d5"],
    [InvestmentStatus.CANCELLED, "#000000"],
    [InvestmentStatus.DECLINED, "#000000"],
    [InvestmentStatus.REFUND_PENDING, "#9d563d"],
    [InvestmentStatus.REFUND_IN_PROGRESS, "#579f5a"],
    [InvestmentStatus.REFUND_ERROR, "#a1060a"],
    [InvestmentStatus.REFUND_COMPLETED, "#28e848"],
    [InvestmentStatus.CLOSED, "#7f7f7f"],
  ]);

  static statusColor(status: InvestmentStatus): string {
    return this.statusColors.get(status) ?? "#7f7f7f";
  }

  static declineReason(reason: string): string {
    return reason ? i18n.global.t("investment.declineReason." + reason) : "-";
  }

  static isTerminal(status: InvestmentStatus | undefined): boolean {
    return status ? Investment.TERMINAL_STATUSES.includes(status) : false;
  }
}
