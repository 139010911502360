<template>
  <el-popover
    v-if="history && history.length > 0"
    placement="right"
    :width="500"
    trigger="click"
    :title="'Change history: ' + fieldName"
    :show-arrow="true"
  >
    <template #reference>
      <span class="history-reference">
        <font-awesome-icon icon="history"></font-awesome-icon>
      </span>
    </template>
    <el-table :data="history">
      <el-table-column min-width="100" label="Changed at">
        <template #default="scope">
          {{ $dateTimeSeconds(scope.row.changedAt) }}
        </template>
      </el-table-column>
      <el-table-column label="Changed by">
        <template #default="scope">
          <div v-if="scope.row.changedByActor === flowActor.BO_USER">
            <router-link
              v-if="hasAuthority(authorities.USER_READ, authorities.USER_WRITE)"
              :to="{ name: 'user', params: { id: scope.row.changedBy.id } }"
            >
              {{ scope.row.changedBy.name }}
            </router-link>
            <span v-else>{{ scope.row.changedBy.name }}</span>
          </div>
          <div v-else-if="scope.row.changedByActor === flowActor.PORTAL_USER">
            <router-link
              v-if="hasAuthority(authorities.USER_READ, authorities.USER_WRITE)"
              :to="{
                name: 'portalUser',
                params: { id: scope.row.changedBy.id },
              }"
            >
              {{ scope.row.changedBy.name }}
            </router-link>
            <span v-else>{{ scope.row.changedBy.name }}</span>
          </div>
          <template v-else>
            {{ "System" }}
          </template>
        </template>
      </el-table-column>
      <el-table-column label="Previous value">
        <template #default="scope">
          {{ formatValue(scope.row.oldValue) }}
        </template>
      </el-table-column>
      <el-table-column label="Value">
        <template #default="scope">
          {{ formatValue(scope.row.newValue) }}
        </template>
      </el-table-column>
    </el-table>
  </el-popover>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { FieldHistoryView, FlowActor } from "@/api";
import { authorityMixin } from "@/util";
import { HistoryFormat } from "@/util/enum/HistoryFormat";

export default defineComponent({
  name: "HistoryPopover",
  mixins: [authorityMixin],
  props: {
    history: {
      type: Object as PropType<FieldHistoryView[]>,
      required: false,
    },
    fieldName: {
      type: String,
      required: true,
    },
    valueType: {
      type: String,
      required: false,
      default: HistoryFormat.STRING,
    },
    customFormat: {
      type: Function,
      required: false,
      default: (value: unknown) => String(value),
    },
  },
  data() {
    return {
      flowActor: FlowActor,
    };
  },
  methods: {
    formatValue(val: never): string {
      switch (this.valueType) {
        case HistoryFormat.CUSTOM:
          return this.customFormat(val);
        case HistoryFormat.CURRENCY:
          return this.$curr(Number(val));
        case HistoryFormat.DATE:
          return this.$date(new Date(val));
        case HistoryFormat.DATETIME:
          return this.$dateTime(new Date(val));
        case HistoryFormat.LOGIC:
          return this.$logic(val);
        case HistoryFormat.STRING:
        default:
          return String(val);
      }
    },
  },
});
</script>
<style lang="scss">
@import "src/styles/variables";

.history-reference {
  color: $color-link;
  position: absolute;
  margin: auto auto auto 7px;
}
</style>
