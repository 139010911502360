<template>
  <Headline
    >{{ $t("sidebar.roles") }}
    <template v-slot:actions>
      <el-button
        v-if="hasAuthority(authorities.ROLE_WRITE)"
        type="primary"
        class="mx-1"
        @click="$router.push({ name: 'newRole' })"
        >{{ $t("role.create") }}
      </el-button>
    </template>
  </Headline>

  <el-table
    :data="rolePage.content"
    @sort-change="onSortChange($event, pageRequest)"
    @row-click="onRowClick"
    :row-style="{ cursor: 'pointer' }"
  >
    <el-table-column :label="$t('id')" prop="id" sortable="custom" width="85">
    </el-table-column>
    <el-table-column
      :label="$t('role.roleName')"
      :show-overflow-tooltip="true"
      prop="name"
      sortable="custom"
    >
    </el-table-column>
    <el-table-column
      :label="$t('role.roleDescription')"
      prop="description"
      sortable="custom"
      :show-overflow-tooltip="true"
    >
      <template #default="scope">
        {{ scope.row.description ?? "-" }}
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('role.startPage')"
      prop="startPage"
      sortable="custom"
    >
      <template #default="scope">{{
        $enums.startPage.displayValue(scope.row.startPage)
      }}</template>
    </el-table-column>
    <el-table-column
      :label="$t('role.residenceCountry')"
      prop="residenceCountry"
      sortable="custom"
      :show-overflow-tooltip="true"
    >
      <template #default="scope">
        <span class="text-break">{{
          $enums.globals.country(scope.row.residenceCountry)
        }}</span>
      </template>
    </el-table-column>
  </el-table>

  <TableFooter
    @size-change="onSizeChange($event, pageRequest)"
    @current-change="onCurrentChange($event, pageRequest)"
    :page="rolePage"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { roleApi } from "@/resources";
import Headline from "@/components/Headline.vue";
import { PageRoleListItem, RoleListItem } from "@/api";
import { authorityMixin, PageRequest, pageSortMixin } from "@/util";
import TableFooter from "@/components/TableFooter.vue";

export default defineComponent({
  name: "RoleList",
  components: { Headline, TableFooter },
  mixins: [pageSortMixin, authorityMixin],
  data: () => ({
    rolePage: {} as PageRoleListItem,
    pageRequest: {} as PageRequest,
  }),
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.rolePage = await roleApi.roles(this.pageRequest);
    },
    onRowClick(row: RoleListItem) {
      this.$router.push({ name: "role", params: { id: row.id } });
    },
  },
});
</script>
