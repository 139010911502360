<template>
  <el-table
    :data="page.content"
    @sort-change="onSortChange($event, pageRequest)"
  >
    <el-table-column :label="$t('id')" prop="id" sortable="custom" width="60">
    </el-table-column>
    <el-table-column
      :label="$t('legal.ownership.persons.name')"
      :show-overflow-tooltip="true"
      prop="name"
    ></el-table-column>
    <el-table-column
      :label="$t('legal.ownership.persons.companyPosition')"
      :show-overflow-tooltip="true"
      prop="position"
    >
    </el-table-column>
    <el-table-column
      :label="$t('legal.ownership.persons.governmentPosition')"
      :show-overflow-tooltip="true"
    >
      <template #default="scope">
        {{ scope.row.governmentPosition ?? "-" }}
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('legal.ownership.persons.governmentDepartment')"
      :show-overflow-tooltip="true"
    >
      <template #default="scope">
        {{ scope.row.governmentDepartment ?? "-" }}
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('legal.ownership.persons.closeAssociate')"
      :show-overflow-tooltip="true"
    >
      <template #default="scope">
        {{ scope.row.closeAssociate ?? "-" }}
      </template>
    </el-table-column>
  </el-table>
  <TableFooter
    @size-change="onSizeChange($event, pageRequest)"
    @current-change="onCurrentChange($event, pageRequest)"
    :page="page"
    :page-sizes="[5, 10, 30, 40]"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { PageLegalPersonItem } from "@/api";
import { authorityMixin, PageRequest, pageSortMixin } from "@/util";
import TableFooter from "@/components/TableFooter.vue";

export default defineComponent({
  name: "LegalPersons",
  mixins: [pageSortMixin, authorityMixin],
  components: { TableFooter },
  props: {
    page: {
      type: Object as PropType<PageLegalPersonItem>,
      required: true,
    },
    pageRequest: {
      type: Object as PropType<PageRequest>,
      required: true,
    },
    fetchPersonsData: {
      type: Function,
      required: true,
    },
  },
  mounted() {
    this.fetchPersonsData();
  },
  methods: {
    fetchData() {
      this.fetchPersonsData();
    },
  },
});
</script>
