/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CampaignVerificationChecklist
 */
export interface CampaignVerificationChecklist {
    /**
     * 
     * @type {boolean}
     * @memberof CampaignVerificationChecklist
     */
    amountsAgreed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignVerificationChecklist
     */
    campaignFeeReceived?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof CampaignVerificationChecklist
     */
    campaignFeeReceivedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof CampaignVerificationChecklist
     */
    comment?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignVerificationChecklist
     */
    durationAgreed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignVerificationChecklist
     */
    extensionAgreed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignVerificationChecklist
     */
    investmentNoteUploaded?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignVerificationChecklist
     */
    overRaiseAgreed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignVerificationChecklist
     */
    planAccepted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignVerificationChecklist
     */
    rewardAgreed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignVerificationChecklist
     */
    rewardSigned?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof CampaignVerificationChecklist
     */
    rewardSignedDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignVerificationChecklist
     */
    rollingCloseAgreed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignVerificationChecklist
     */
    termsSigned?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignVerificationChecklist
     */
    verificationCompleted?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof CampaignVerificationChecklist
     */
    verificationDate?: Date;
}

export function CampaignVerificationChecklistFromJSON(json: any): CampaignVerificationChecklist {
    return CampaignVerificationChecklistFromJSONTyped(json, false);
}

export function CampaignVerificationChecklistFromJSONTyped(json: any, ignoreDiscriminator: boolean): CampaignVerificationChecklist {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amountsAgreed': !exists(json, 'amountsAgreed') ? undefined : json['amountsAgreed'],
        'campaignFeeReceived': !exists(json, 'campaignFeeReceived') ? undefined : json['campaignFeeReceived'],
        'campaignFeeReceivedDate': !exists(json, 'campaignFeeReceivedDate') ? undefined : (new Date(json['campaignFeeReceivedDate'])),
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'durationAgreed': !exists(json, 'durationAgreed') ? undefined : json['durationAgreed'],
        'extensionAgreed': !exists(json, 'extensionAgreed') ? undefined : json['extensionAgreed'],
        'investmentNoteUploaded': !exists(json, 'investmentNoteUploaded') ? undefined : json['investmentNoteUploaded'],
        'overRaiseAgreed': !exists(json, 'overRaiseAgreed') ? undefined : json['overRaiseAgreed'],
        'planAccepted': !exists(json, 'planAccepted') ? undefined : json['planAccepted'],
        'rewardAgreed': !exists(json, 'rewardAgreed') ? undefined : json['rewardAgreed'],
        'rewardSigned': !exists(json, 'rewardSigned') ? undefined : json['rewardSigned'],
        'rewardSignedDate': !exists(json, 'rewardSignedDate') ? undefined : (new Date(json['rewardSignedDate'])),
        'rollingCloseAgreed': !exists(json, 'rollingCloseAgreed') ? undefined : json['rollingCloseAgreed'],
        'termsSigned': !exists(json, 'termsSigned') ? undefined : json['termsSigned'],
        'verificationCompleted': !exists(json, 'verificationCompleted') ? undefined : json['verificationCompleted'],
        'verificationDate': !exists(json, 'verificationDate') ? undefined : (new Date(json['verificationDate'])),
    };
}

export function CampaignVerificationChecklistToJSON(value?: CampaignVerificationChecklist | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amountsAgreed': value.amountsAgreed,
        'campaignFeeReceived': value.campaignFeeReceived,
        'campaignFeeReceivedDate': value.campaignFeeReceivedDate === undefined ? undefined : (value.campaignFeeReceivedDate.toISOString().substr(0,10)),
        'comment': value.comment,
        'durationAgreed': value.durationAgreed,
        'extensionAgreed': value.extensionAgreed,
        'investmentNoteUploaded': value.investmentNoteUploaded,
        'overRaiseAgreed': value.overRaiseAgreed,
        'planAccepted': value.planAccepted,
        'rewardAgreed': value.rewardAgreed,
        'rewardSigned': value.rewardSigned,
        'rewardSignedDate': value.rewardSignedDate === undefined ? undefined : (value.rewardSignedDate.toISOString().substr(0,10)),
        'rollingCloseAgreed': value.rollingCloseAgreed,
        'termsSigned': value.termsSigned,
        'verificationCompleted': value.verificationCompleted,
        'verificationDate': value.verificationDate === undefined ? undefined : (value.verificationDate.toISOString().substr(0,10)),
    };
}


