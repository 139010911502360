
import { defineComponent } from "vue";
import { roleApi } from "@/resources";
import Headline from "@/components/Headline.vue";
import { PageRoleListItem, RoleListItem } from "@/api";
import { authorityMixin, PageRequest, pageSortMixin } from "@/util";
import TableFooter from "@/components/TableFooter.vue";

export default defineComponent({
  name: "RoleList",
  components: { Headline, TableFooter },
  mixins: [pageSortMixin, authorityMixin],
  data: () => ({
    rolePage: {} as PageRoleListItem,
    pageRequest: {} as PageRequest,
  }),
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.rolePage = await roleApi.roles(this.pageRequest);
    },
    onRowClick(row: RoleListItem) {
      this.$router.push({ name: "role", params: { id: row.id } });
    },
  },
});
