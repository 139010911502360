/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum UserStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    LOCKED = 'LOCKED',
    SUSPENDED = 'SUSPENDED'
}

export function UserStatusFromJSON(json: any): UserStatus {
    return UserStatusFromJSONTyped(json, false);
}

export function UserStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserStatus {
    return json as UserStatus;
}

export function UserStatusToJSON(value?: UserStatus | null): any {
    return value as any;
}

