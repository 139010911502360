<template>
  <div class="container-filters mt-4 mb-3">
    <el-form
      @validate="onValidate"
      :model="filter"
      :rules="rules"
      ref="filter-form"
      @change="$emit('update:transactionFilter', filter)"
    >
      <div class="row">
        <div class="col-4">
          <el-form-item
            :label="$t('transactions.campaignId')"
            prop="campaignId"
          >
            <el-input v-model="filter.campaignId"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('transactions.investmentId')"
            prop="investmentId"
          >
            <el-input v-model="filter.investmentId"></el-input>
          </el-form-item>
          <el-form-item :label="$t('transactions.transactionType')">
            <el-select
              v-model="filter.transactionTypes"
              class="w-100"
              multiple
              collapse-tags
            >
              <el-option
                v-for="(transactionType, key) in transactionType"
                :key="key"
                :label="$enums.transaction.type(transactionType)"
                :value="transactionType"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="col-4">
          <el-form-item :label="$t('transactions.startDateFilter')">
            <div class="d-flex gap-2">
              <el-date-picker
                type="date"
                v-model="filter.startDateFrom"
                format="DD/MM/YYYY"
                :placeholder="$t('filter.from')"
              ></el-date-picker>
              <el-date-picker
                type="date"
                v-model="filter.startDateTo"
                format="DD/MM/YYYY"
                :placeholder="$t('filter.to')"
              ></el-date-picker>
            </div>
          </el-form-item>
          <el-form-item :label="$t('transactions.effectiveDateFilter')">
            <div class="d-flex gap-2">
              <el-date-picker
                type="date"
                v-model="filter.effectiveDateFrom"
                format="DD/MM/YYYY"
                :placeholder="$t('filter.from')"
              ></el-date-picker>
              <el-date-picker
                type="date"
                v-model="filter.effectiveDateTo"
                format="DD/MM/YYYY"
                :placeholder="$t('filter.to')"
              ></el-date-picker>
            </div>
          </el-form-item>
        </div>
        <div class="col-4">
          <label class="el-form-item__label">{{
            $t("transactions.amount")
          }}</label>
          <div class="d-flex gap-2">
            <el-form-item prop="amountFrom">
              <el-input
                v-model.number="filter.amountFrom"
                :placeholder="$t('filter.from')"
              >
                <template #prepend>
                  <font-awesome-icon :icon="['fas', 'dollar-sign']" />
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="amountTo">
              <el-input
                v-model.number="filter.amountTo"
                :placeholder="$t('filter.to')"
              >
                <template #prepend>
                  <font-awesome-icon :icon="['fas', 'dollar-sign']" />
                </template>
              </el-input>
            </el-form-item>
          </div>
          <el-form-item :label="$t('transactions.transactionStatus')">
            <el-select
              v-model="filter.transactionStatuses"
              class="w-100"
              multiple
              collapse-tags
            >
              <el-option
                v-for="(transactionStatus, key) in transactionStatus"
                :key="key"
                :label="$enums.transaction.status(transactionStatus)"
                :value="transactionStatus"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
  <div class="mb-5">
    <el-button type="primary" @click="submit" :disabled="!isFormValid">
      {{ $t("apply") }}
    </el-button>
    <el-button type="default" @click="resetFilter">
      {{ $t("filter.reset") }}
    </el-button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { TransactionStatus, TransactionType } from "@/api";
import { ElementForm, InputLength, numberFormat } from "@/util/validation";
import { Filter } from "@/views/deal/DealList.vue";

export default defineComponent({
  emits: ["update:transactionFilter", "submit"],
  props: ["transactionFilter"],
  data() {
    return {
      transactionType: [] as TransactionType[],
      transactionStatus: [] as TransactionStatus[],
      isFormValid: true,
      filter: {} as Filter,
      rules: {
        campaignId: [numberFormat, new InputLength(100)],
        investmentId: [numberFormat, new InputLength(100)],
        amountFrom: [numberFormat],
        amountTo: [numberFormat],
      },
    };
  },
  async mounted() {
    this.filter = this.transactionFilter;
    this.transactionType = Object.values(TransactionType);
    this.transactionStatus = Object.values(TransactionStatus);
  },
  methods: {
    submit() {
      const form = this.$refs["filter-form"] as ElementForm;
      form.validate(async (valid) => {
        if (valid) {
          this.$emit("submit");
        }
      });
    },
    onValidate(prop: string, isVal: boolean) {
      this.isFormValid = isVal;
    },
    resetFilter() {
      this.filter = {} as Filter;
      this.$emit("update:transactionFilter", this.filter);
      this.$emit("submit");
    },
  },
});
</script>
