<template>
  <div class="mt-3 d-block">
    {{ $t("auth.passwordRuleTitle") }}
    <ul>
      <li>{{ $t("auth.passwordRule1") }}</li>
      <li>{{ $t("auth.passwordRule2") }}</li>
      <li>{{ $t("auth.passwordRule3") }}</li>
      <li>{{ $t("auth.passwordRule4") }}"}~|@</li>
      <li>{{ $t("auth.passwordRule5") }}</li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PasswordPolicy",
});
</script>
