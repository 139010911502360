/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CampaignSettingsItem
 */
export interface CampaignSettingsItem {
    /**
     * 
     * @type {number}
     * @memberof CampaignSettingsItem
     */
    fundraisingCommissionFee: number;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignSettingsItem
     */
    fundraisingCommissionFeeEditAvailable: boolean;
    /**
     * 
     * @type {number}
     * @memberof CampaignSettingsItem
     */
    registrationFee: number;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignSettingsItem
     */
    registrationFeeEditAvailable: boolean;
    /**
     * 
     * @type {number}
     * @memberof CampaignSettingsItem
     */
    spvFee: number;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignSettingsItem
     */
    spvFeeEditAvailable: boolean;
}

export function CampaignSettingsItemFromJSON(json: any): CampaignSettingsItem {
    return CampaignSettingsItemFromJSONTyped(json, false);
}

export function CampaignSettingsItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): CampaignSettingsItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fundraisingCommissionFee': json['fundraisingCommissionFee'],
        'fundraisingCommissionFeeEditAvailable': json['fundraisingCommissionFeeEditAvailable'],
        'registrationFee': json['registrationFee'],
        'registrationFeeEditAvailable': json['registrationFeeEditAvailable'],
        'spvFee': json['spvFee'],
        'spvFeeEditAvailable': json['spvFeeEditAvailable'],
    };
}

export function CampaignSettingsItemToJSON(value?: CampaignSettingsItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fundraisingCommissionFee': value.fundraisingCommissionFee,
        'fundraisingCommissionFeeEditAvailable': value.fundraisingCommissionFeeEditAvailable,
        'registrationFee': value.registrationFee,
        'registrationFeeEditAvailable': value.registrationFeeEditAvailable,
        'spvFee': value.spvFee,
        'spvFeeEditAvailable': value.spvFeeEditAvailable,
    };
}


