
import { defineComponent, inject } from "vue";
import { Legal } from "@/api";
import LegalGeneralInfo from "@/views/components/LegalGeneralInfo.vue";

export default defineComponent({
  name: "BusinessGeneralInfo",
  components: { LegalGeneralInfo },
  data() {
    return {
      legal: inject("legal") as Legal,
    };
  },
});
