/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Country,
    CountryFromJSON,
    CountryFromJSONTyped,
    CountryToJSON,
    UserStatus,
    UserStatusFromJSON,
    UserStatusFromJSONTyped,
    UserStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserListItem
 */
export interface UserListItem {
    /**
     * 
     * @type {string}
     * @memberof UserListItem
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserListItem
     */
    givenName: string;
    /**
     * 
     * @type {number}
     * @memberof UserListItem
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UserListItem
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UserListItem
     */
    phone: string;
    /**
     * 
     * @type {Country}
     * @memberof UserListItem
     */
    residenceCountry: Country;
    /**
     * 
     * @type {string}
     * @memberof UserListItem
     */
    role: string;
    /**
     * 
     * @type {number}
     * @memberof UserListItem
     */
    roleId: number;
    /**
     * 
     * @type {UserStatus}
     * @memberof UserListItem
     */
    status: UserStatus;
}

export function UserListItemFromJSON(json: any): UserListItem {
    return UserListItemFromJSONTyped(json, false);
}

export function UserListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'givenName': json['givenName'],
        'id': json['id'],
        'lastName': json['lastName'],
        'phone': json['phone'],
        'residenceCountry': CountryFromJSON(json['residenceCountry']),
        'role': json['role'],
        'roleId': json['roleId'],
        'status': UserStatusFromJSON(json['status']),
    };
}

export function UserListItemToJSON(value?: UserListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'givenName': value.givenName,
        'id': value.id,
        'lastName': value.lastName,
        'phone': value.phone,
        'residenceCountry': CountryToJSON(value.residenceCountry),
        'role': value.role,
        'roleId': value.roleId,
        'status': UserStatusToJSON(value.status),
    };
}


