
import { defineComponent, inject } from "vue";
import {
  ElementForm,
  InputLength,
  InputRequired,
  nameFormat,
} from "@/util/validation";
import { Country, UpdateUserForm, User, UserHistory } from "@/api";
import { roleApi, userApi } from "@/resources";
import { authorityMixin, fixLocalDate } from "@/util";
import { isConstraintViolation, isProblem } from "@/resources/problem";
import MaskedPhoneNumber from "@/components/MaskedPhoneNumber/MaskedPhoneNumber.vue";

export default defineComponent({
  name: "UserDetails",
  components: { MaskedPhoneNumber },
  mixins: [authorityMixin],
  emits: ["update"],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      residenceCountryOptions: Country,
      user: inject("user") as User,
      roles: [] as string[],
      userForm: {} as UpdateUserForm,
      editable: false,
      rules: {
        givenName: [new InputRequired(), new InputLength(100), nameFormat],
        lastName: [new InputRequired(), new InputLength(100), nameFormat],
        birthDate: [new InputRequired()],
        residenceCountry: [new InputRequired()],
        phone: [new InputRequired("validation.phoneFormatRequired")],
        role: [new InputRequired("validation.selectRequired")],
      },
      history: inject("history") as UserHistory[],
    };
  },
  async mounted() {
    this.roles = await roleApi.roleOptions();
  },
  methods: {
    edit() {
      Object.assign(this.userForm, { ...this.user, ...this.user.person });
      this.editable = true;
    },
    submit() {
      const form = this.$refs["userForm"] as ElementForm;
      form.validate((valid) => {
        if (valid) {
          this.updateUser();
        }
        return false;
      });
    },
    async updateUser() {
      try {
        this.userForm.birthDate = fixLocalDate(this.userForm.birthDate);
        await userApi.updateUser({
          id: Number(this.id),
          updateUserForm: this.userForm,
        });
        this.$notify.info(this.$t("user.userUpdated"));
        Object.assign(this.user, {
          ...this.userForm,
          person: { ...this.userForm },
        });
        this.editable = false;
        this.$emit("update");
      } catch (error) {
        if (isConstraintViolation(error)) {
          error.violations.forEach((violation) =>
            this.$notify.error(this.$t(violation.message))
          );
        }
        if (isProblem(error)) {
          this.$notify.error(this.$t(error.detail));
        }
      }
    },
    async resendLink() {
      try {
        await userApi.resendRegistrationLink({
          id: Number(this.id),
        });
        this.$notify.info(this.$t("user.resendLinkSent"));
      } catch (error) {
        if (isConstraintViolation(error)) {
          error.violations.forEach((violation) =>
            this.$notify.error(this.$t(violation.message))
          );
        }
        if (isProblem(error)) {
          this.$notify.error(this.$t(error.detail));
        }
      }
    },
  },
});
