/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccreditationStatus,
    AccreditationStatusFromJSON,
    AccreditationStatusFromJSONTyped,
    AccreditationStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface BackOfficeFlowEntityStateAccreditationStatus
 */
export interface BackOfficeFlowEntityStateAccreditationStatus {
    /**
     * 
     * @type {Array<AccreditationStatus>}
     * @memberof BackOfficeFlowEntityStateAccreditationStatus
     */
    nextStatuses: Array<AccreditationStatus>;
    /**
     * 
     * @type {AccreditationStatus}
     * @memberof BackOfficeFlowEntityStateAccreditationStatus
     */
    previousStatus?: AccreditationStatus;
}

export function BackOfficeFlowEntityStateAccreditationStatusFromJSON(json: any): BackOfficeFlowEntityStateAccreditationStatus {
    return BackOfficeFlowEntityStateAccreditationStatusFromJSONTyped(json, false);
}

export function BackOfficeFlowEntityStateAccreditationStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): BackOfficeFlowEntityStateAccreditationStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nextStatuses': ((json['nextStatuses'] as Array<any>).map(AccreditationStatusFromJSON)),
        'previousStatus': !exists(json, 'previousStatus') ? undefined : AccreditationStatusFromJSON(json['previousStatus']),
    };
}

export function BackOfficeFlowEntityStateAccreditationStatusToJSON(value?: BackOfficeFlowEntityStateAccreditationStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nextStatuses': ((value.nextStatuses as Array<any>).map(AccreditationStatusToJSON)),
        'previousStatus': AccreditationStatusToJSON(value.previousStatus),
    };
}


