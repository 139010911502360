/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IdNameEntry,
    IdNameEntryFromJSON,
    IdNameEntryFromJSONTyped,
    IdNameEntryToJSON,
    InvestmentStatus,
    InvestmentStatusFromJSON,
    InvestmentStatusFromJSONTyped,
    InvestmentStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface InvestmentSummaryItem
 */
export interface InvestmentSummaryItem {
    /**
     * 
     * @type {number}
     * @memberof InvestmentSummaryItem
     */
    committedAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof InvestmentSummaryItem
     */
    id?: number;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof InvestmentSummaryItem
     */
    investor?: IdNameEntry;
    /**
     * 
     * @type {number}
     * @memberof InvestmentSummaryItem
     */
    receivedAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof InvestmentSummaryItem
     */
    requestedAmount?: number;
    /**
     * 
     * @type {InvestmentStatus}
     * @memberof InvestmentSummaryItem
     */
    status?: InvestmentStatus;
}

export function InvestmentSummaryItemFromJSON(json: any): InvestmentSummaryItem {
    return InvestmentSummaryItemFromJSONTyped(json, false);
}

export function InvestmentSummaryItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestmentSummaryItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'committedAmount': !exists(json, 'committedAmount') ? undefined : json['committedAmount'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'investor': !exists(json, 'investor') ? undefined : IdNameEntryFromJSON(json['investor']),
        'receivedAmount': !exists(json, 'receivedAmount') ? undefined : json['receivedAmount'],
        'requestedAmount': !exists(json, 'requestedAmount') ? undefined : json['requestedAmount'],
        'status': !exists(json, 'status') ? undefined : InvestmentStatusFromJSON(json['status']),
    };
}

export function InvestmentSummaryItemToJSON(value?: InvestmentSummaryItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'committedAmount': value.committedAmount,
        'id': value.id,
        'investor': IdNameEntryToJSON(value.investor),
        'receivedAmount': value.receivedAmount,
        'requestedAmount': value.requestedAmount,
        'status': InvestmentStatusToJSON(value.status),
    };
}


