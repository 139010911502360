/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CampaignContentTypeData,
    CampaignContentTypeDataFromJSON,
    CampaignContentTypeDataFromJSONTyped,
    CampaignContentTypeDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface CampaignContentData
 */
export interface CampaignContentData {
    /**
     * 
     * @type {string}
     * @memberof CampaignContentData
     */
    attachmentUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignContentData
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignContentData
     */
    filename?: string;
    /**
     * 
     * @type {number}
     * @memberof CampaignContentData
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CampaignContentData
     */
    mimetype?: string;
    /**
     * 
     * @type {CampaignContentTypeData}
     * @memberof CampaignContentData
     */
    type: CampaignContentTypeData;
}

export function CampaignContentDataFromJSON(json: any): CampaignContentData {
    return CampaignContentDataFromJSONTyped(json, false);
}

export function CampaignContentDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CampaignContentData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attachmentUrl': !exists(json, 'attachmentUrl') ? undefined : json['attachmentUrl'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'filename': !exists(json, 'filename') ? undefined : json['filename'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'mimetype': !exists(json, 'mimetype') ? undefined : json['mimetype'],
        'type': CampaignContentTypeDataFromJSON(json['type']),
    };
}

export function CampaignContentDataToJSON(value?: CampaignContentData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attachmentUrl': value.attachmentUrl,
        'description': value.description,
        'filename': value.filename,
        'id': value.id,
        'mimetype': value.mimetype,
        'type': CampaignContentTypeDataToJSON(value.type),
    };
}


