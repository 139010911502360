/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TransactionStatus,
    TransactionStatusFromJSON,
    TransactionStatusFromJSONTyped,
    TransactionStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface BackOfficeCompleteTransactionRequest
 */
export interface BackOfficeCompleteTransactionRequest {
    /**
     * 
     * @type {string}
     * @memberof BackOfficeCompleteTransactionRequest
     */
    externalId?: string;
    /**
     * 
     * @type {number}
     * @memberof BackOfficeCompleteTransactionRequest
     */
    id: number;
    /**
     * 
     * @type {TransactionStatus}
     * @memberof BackOfficeCompleteTransactionRequest
     */
    status: TransactionStatus;
}

export function BackOfficeCompleteTransactionRequestFromJSON(json: any): BackOfficeCompleteTransactionRequest {
    return BackOfficeCompleteTransactionRequestFromJSONTyped(json, false);
}

export function BackOfficeCompleteTransactionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BackOfficeCompleteTransactionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'id': json['id'],
        'status': TransactionStatusFromJSON(json['status']),
    };
}

export function BackOfficeCompleteTransactionRequestToJSON(value?: BackOfficeCompleteTransactionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'externalId': value.externalId,
        'id': value.id,
        'status': TransactionStatusToJSON(value.status),
    };
}


