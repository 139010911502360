<template>
  <div class="row mb-3" v-for="row in computeCommerce" v-bind:key="row">
    <div class="col">
      <span class="color-light-text">{{
        $enums.legal.commerce(row.key1)
      }}</span>
      <br />
      <div class="ms-1" v-if="row.values1.length === 0">-</div>
      <ol class="commerce-ol" v-if="row.values1.length > 0">
        <li v-for="value in row.values1" v-bind:key="value">
          {{ $enums.legal.commerceValue(row.key1, value) }}
        </li>
      </ol>
    </div>
    <div class="col">
      <span class="color-light-text">{{
        $enums.legal.commerce(row.key2)
      }}</span>
      <br />
      <div
        class="ms-1"
        v-if="row.values2 !== undefined && row.values2.length === 0"
      >
        -
      </div>
      <ol
        class="commerce-ol"
        v-if="row.values2 !== undefined && row.values2.length > 0"
      >
        <li v-for="value in row.values2" v-bind:key="value">
          {{ $enums.legal.commerceValue(row.key2, value) }}
        </li>
      </ol>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { LegalCommerceItem } from "@/api";

export interface Row {
  key1: string;
  values1: string[];
  key2: string | undefined;
  values2: string[] | undefined;
}

// eslint-disable-next-line @typescript-eslint/no-namespace, @typescript-eslint/no-unused-vars
namespace enums {
  export const enum LegalCommerceKeyOrder {
    TOP_COMPANY_BUYERS = 0,
    PROVIDED_PRODUCT = 1,
    TOP_COMPANY_SUPPLIERS = 2,
  }
}

export default defineComponent({
  name: "LegalCommerce",
  props: {
    commerce: {
      type: Object as PropType<LegalCommerceItem>,
      required: true,
    },
  },
  computed: {
    computeCommerce(): Row[] {
      const rows = [] as Row[];
      if (this.commerce.commerce) {
        let keys = Object.keys(this.commerce.commerce).sort(
          (k1: string, k2: string) =>
            // Enums will be deleted at runtime, so we need to define and export it.
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (enums as any).LegalCommerceKeyOrder[k1] -
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (enums as any).LegalCommerceKeyOrder[k2]
        );
        for (let i = 0; i < keys.length; i = i + 2) {
          const row = {} as Row;
          row.key1 = keys[i];
          row.values1 = this.commerce.commerce[row.key1];
          if (i + 1 < keys.length) {
            row.key2 = keys[i + 1];
            row.values2 = this.commerce.commerce[row.key2];
          }
          rows.push(row);
        }
      }
      return rows;
    },
  },
});
</script>
<style scoped lang="scss">
.commerce-ol {
  margin: 0 !important;
  margin-left: 1.25rem !important;
  padding: 0 !important;
}
</style>
