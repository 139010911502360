
import { defineComponent } from "vue";
import { campaignApi } from "@/resources";
import Status from "@/components/Status.vue";
import {
  CampaignListItem,
  CampaignStatus,
  CampaignType,
  PageCampaignListItem,
} from "@/api";
import TableFooter from "@/components/TableFooter.vue";
import {
  authorityMixin,
  fixLocalDate,
  PageRequest,
  pageSortMixin,
} from "@/util";
import Headline from "@/components/Headline.vue";
import CampaignFilter from "@/views/campaign/CampaignFilter.vue";

export interface Filter {
  business: string | undefined;
  createdDateFrom: Date | undefined;
  createdDateTo: Date | undefined;
  campaignStartedFrom: Date | undefined;
  campaignStartedTo: Date | undefined;
  campaignFinishedFrom: Date | undefined;
  campaignFinishedTo: Date | undefined;
  approvedAmountFrom: number | undefined;
  approvedAmountTo: number | undefined;
  commitmentPercentFrom: number | undefined;
  commitmentPercentTo: number | undefined;
  minimumInvestmentFrom: number | undefined;
  minimumInvestmentTo: number | undefined;
  statuses: CampaignStatus[];
  types: CampaignType[];
}

export default defineComponent({
  name: "CampaignList",
  mixins: [pageSortMixin, authorityMixin],
  components: {
    CampaignFilter,
    Headline,
    Status,
    TableFooter,
  },
  data: () => ({
    campaignPage: {} as PageCampaignListItem,
    pageRequest: {} as PageRequest,
    filterVisible: false,
    filter: {} as Filter,
  }),
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.filter.campaignStartedFrom = this.filter.campaignStartedFrom
        ? fixLocalDate(this.filter.campaignStartedFrom)
        : undefined;
      this.filter.campaignStartedTo = this.filter.campaignStartedTo
        ? fixLocalDate(this.filter.campaignStartedTo)
        : undefined;
      this.filter.createdDateFrom = this.filter.createdDateFrom
        ? fixLocalDate(this.filter.createdDateFrom)
        : undefined;
      this.filter.createdDateTo = this.filter.createdDateTo
        ? fixLocalDate(this.filter.createdDateTo)
        : undefined;
      this.filter.campaignFinishedFrom = this.filter.campaignFinishedFrom
        ? fixLocalDate(this.filter.campaignFinishedFrom)
        : undefined;
      this.filter.campaignFinishedTo = this.filter.campaignFinishedTo
        ? fixLocalDate(this.filter.campaignFinishedTo)
        : undefined;

      this.campaignPage = await campaignApi.campaigns({
        ...this.pageRequest,
        ...this.filter,
      });
    },
    onCellClick(row: CampaignListItem) {
      this.$router.push({
        name: "campaign",
        params: { id: row.id },
      });
    },
  },
});
