
import { defineComponent, PropType } from "vue";
import { authorityMixin } from "@/util";
import { NoteItem, NoteRequest } from "@/api";
import { ElementForm, InputRequired } from "@/util/validation";
import { isConstraintViolation, isProblem } from "@/resources/problem";
import NoteInfo from "@/views/components/NoteInfo.vue";
import ButtonOkCancelDialog from "@/views/components/ButtonOkCancelDialog.vue";

export default defineComponent({
  name: "Note",
  components: { ButtonOkCancelDialog, NoteInfo },
  mixins: [authorityMixin],
  props: {
    data: {
      type: Object as PropType<NoteItem>,
      required: true,
    },
    hideMethod: {
      type: Function,
      required: true,
    },
    deleteNoteMethod: {
      type: Function,
      required: true,
    },
    updateNoteMethod: {
      type: Function,
      required: true,
    },
    editDisabled: {
      type: Function,
      required: false,
      default: () => true,
    },
    deleteDisabled: {
      type: Function,
      required: false,
      default: () => true,
    },
    translationPrefix: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    editable: false,
    editForm: {} as NoteRequest,
    isFormValid: true,
    rules: {
      note: [new InputRequired("validation.inputRequired")],
    },
    note: {} as NoteItem,
  }),
  mounted() {
    this.note = this.data;
  },
  methods: {
    edit() {
      Object.assign(this.editForm, { id: this.note.id, note: this.note.note });
      this.editable = true;
    },
    onValidate(prop: string, isVal: boolean) {
      this.isFormValid = isVal;
    },
    async updateNote() {
      const form = this.$refs["editForm"] as ElementForm;
      if (await form.validate()) {
        try {
          this.note = await this.updateNoteMethod(this.note.id, this.editForm);
          this.$emit("update");
        } catch (e) {
          if (isConstraintViolation(e)) {
            e.violations.forEach((violation) =>
              this.$notify.error(this.$t(violation.message))
            );
          } else if (isProblem(e)) {
            this.$notify.error(this.$t(e.detail));
          }
        }
        this.editForm = {} as NoteRequest;
        this.editable = false;
      }
    },
    cancel() {
      Object.assign(this.editForm, { id: this.note.id, note: this.note.note });
      this.editable = false;
    },
    async deleteNote() {
      try {
        await this.deleteNoteMethod(this.note.id);
        await this.hideMethod(this.note.id);
      } catch (e) {
        if (isConstraintViolation(e)) {
          e.violations.forEach((violation) =>
            this.$notify.error(this.$t(violation.message))
          );
        } else if (isProblem(e)) {
          this.$notify.error(this.$t(e.detail));
        }
      }
    },
  },
});
