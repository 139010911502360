
import { defineComponent, inject } from "vue";
import { campaignContentApi } from "@/resources";
import { authorityMixin } from "@/util";
import ContentItem from "@/views/campaign/tabs/content/CampaignContentItem.vue";
import { FileFormat } from "@/views/components/Attachments.vue";
import {
  CampaignContentData,
  CampaignContentType,
  CampaignItem,
  CampaignStatus,
} from "@/api";

export default defineComponent({
  name: "CampaignContentFiles",
  components: { ContentItem },
  mixins: [authorityMixin],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      campaignEditStatuses: [
        CampaignStatus.DRAFT,
        CampaignStatus.WAITING_FOR_REGISTRATION_FEE,
        CampaignStatus.ACTIVE,
        CampaignStatus.BUSINESS_VERIFICATION,
        CampaignStatus.CAMPAIGN_VERIFICATION,
        CampaignStatus.CAMPAIGN_COLLATERAL_REVIEW,
        CampaignStatus.APPROVED,
        CampaignStatus.SIGNING_CAMPAIGN_AGREEMENT,
        CampaignStatus.FUNDRAISING_RESERVE,
        CampaignStatus.FUNDRAISING_LIVE,
        CampaignStatus.CAMPAIGN_FAIL,
        CampaignStatus.TEMPORARILY_SUSPENDED,
        CampaignStatus.CAMPAIGN_RECONCILIATION,
      ],
      campaign: inject("campaign") as CampaignItem,
      content: [] as CampaignContentData[],
      types: CampaignContentType,
      imageFormats: [
        { mimetype: "image/png", viewName: "PNG" } as FileFormat,
        { mimetype: "image/jpeg", viewName: "JPEG" } as FileFormat,
      ] as FileFormat[],
      pdfFormats: [
        { mimetype: "application/pdf", viewName: "PDF" } as FileFormat,
      ] as FileFormat[],
    };
  },
  async mounted() {
    await this.getContent();
  },
  methods: {
    async getContent() {
      this.content = await campaignContentApi.getContent({
        id: Number(this.id),
      });
    },
    getItem(type: CampaignContentType): CampaignContentData {
      return this.content.filter((item) => item.type.type === type)[0];
    },
    isCampaignEditable(): boolean {
      return this.campaignEditStatuses.includes(this.campaign.status);
    },
  },
});
