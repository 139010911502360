<template>
  <div class="container ms-0 mb-3">
    <div class="row mb-4">
      <div class="col">
        <el-form
          :model="form"
          :rules="rules"
          status-icon
          ref="form"
          @validate="onValidate"
        >
          <div class="row">
            <div class="col-lg-4">
              <el-form-item
                :label="$t('settings.exchangeRate.usdToOmr')"
                prop="usdToOmr"
              >
                <el-input
                  :disabled="!editable"
                  v-model="form.usdToOmr"
                ></el-input>
                <HistoryPopover
                  :history="exchangeRateHistory.usdToOmr"
                  :field-name="$t('settings.exchangeRate.usdToOmr')"
                  :value-type="historyFormat.STRING"
                />
              </el-form-item>
            </div>
            <div class="col-lg-4">
              <el-form-item
                :label="$t('settings.exchangeRate.usdToAed')"
                prop="usdToAed"
              >
                <el-input
                  :disabled="!editable"
                  v-model="form.usdToAed"
                ></el-input>
                <HistoryPopover
                  :history="exchangeRateHistory.usdToAed"
                  :field-name="$t('settings.exchangeRate.usdToAed')"
                  :value-type="historyFormat.STRING"
                />
              </el-form-item>
            </div>
            <div class="col-lg-4">
              <el-form-item
                :label="$t('settings.exchangeRate.usdToQar')"
                prop="usdToQar"
              >
                <el-input
                  :disabled="!editable"
                  v-model="form.usdToQar"
                ></el-input>
                <HistoryPopover
                  :history="exchangeRateHistory.usdToQar"
                  :field-name="$t('settings.exchangeRate.usdToQar')"
                  :value-type="historyFormat.STRING"
                />
              </el-form-item>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              <el-form-item
                :label="$t('settings.exchangeRate.usdToSar')"
                prop="usdToSar"
              >
                <el-input
                  :disabled="!editable"
                  v-model="form.usdToSar"
                ></el-input>
                <HistoryPopover
                  :history="exchangeRateHistory.usdToSar"
                  :field-name="$t('settings.exchangeRate.usdToSar')"
                  :value-type="historyFormat.STRING"
                />
              </el-form-item>
            </div>
            <div class="col-lg-4">
              <el-form-item
                :label="$t('settings.exchangeRate.usdToBhd')"
                prop="usdToBhd"
              >
                <el-input
                  :disabled="!editable"
                  v-model="form.usdToBhd"
                ></el-input>
                <HistoryPopover
                  :history="exchangeRateHistory.usdToBhd"
                  :field-name="$t('settings.exchangeRate.usdToBhd')"
                  :value-type="historyFormat.STRING"
                />
              </el-form-item>
            </div>
            <div class="col-lg-4">
              <el-form-item
                :label="$t('settings.exchangeRate.usdToKwd')"
                prop="usdToKwd"
              >
                <el-input
                  :disabled="!editable"
                  v-model="form.usdToKwd"
                ></el-input>
                <HistoryPopover
                  :history="exchangeRateHistory.usdToKwd"
                  :field-name="$t('settings.exchangeRate.usdToKwd')"
                  :value-type="historyFormat.STRING"
                />
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <el-button
          @click="edit"
          v-show="!editable"
          v-if="hasAuthority(authorities.SETTINGS_EXCHANGE_RATES)"
        >
          {{ $t("edit") }}
        </el-button>
        <el-button style="margin-left: 0" @click="cancel" v-show="editable">
          {{ $t("cancel") }}
        </el-button>
        <el-button
          type="primary"
          v-show="editable"
          :disabled="!isFormValid"
          @click="submit"
        >
          {{ $t("save") }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { authorityMixin } from "@/util";
import HistoryPopover from "@/components/HistoryPopover.vue";
import { defineComponent } from "vue";
import {
  ExchangeRateSettingsData,
  ExchangeRateSettingsHistoryItem,
  ExchangeRateSettingsItem,
} from "@/api";
import { HistoryFormat } from "@/util/enum/HistoryFormat";
import { settingsApi } from "@/resources";
import {
  DecimalFormat,
  ElementForm,
  InputRequired,
  NumberRange,
} from "@/util/validation";
import { isConstraintViolation, isProblem } from "@/resources/problem";

export default defineComponent({
  name: "SettingsExchangeRate",
  mixins: [authorityMixin],
  components: { HistoryPopover },
  data() {
    return {
      exchangeRate: {} as ExchangeRateSettingsItem,
      exchangeRateHistory: {} as ExchangeRateSettingsHistoryItem,
      historyFormat: HistoryFormat,
      form: {} as ExchangeRateSettingsData,
      rules: {
        usdToOmr: [
          new InputRequired("validation.inputRequired"),
          new DecimalFormat(5, 6),
          new NumberRange(0.000001, 1000.0),
        ],
        usdToAed: [
          new InputRequired("validation.inputRequired"),
          new DecimalFormat(5, 6),
          new NumberRange(0.000001, 1000.0),
        ],
        usdToQar: [
          new InputRequired("validation.inputRequired"),
          new DecimalFormat(5, 6),
          new NumberRange(0.000001, 1000.0),
        ],
        usdToSar: [
          new InputRequired("validation.inputRequired"),
          new DecimalFormat(5, 6),
          new NumberRange(0.000001, 1000.0),
        ],
        usdToBhd: [
          new InputRequired("validation.inputRequired"),
          new DecimalFormat(5, 6),
          new NumberRange(0.000001, 1000.0),
        ],
        usdToKwd: [
          new InputRequired("validation.inputRequired"),
          new DecimalFormat(5, 6),
          new NumberRange(0.000001, 1000.0),
        ],
      },
      isFormValid: true,
      editable: false,
    };
  },
  watch: {
    exchangeRate() {
      Object.assign(this.form, this.exchangeRate);
    },
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchExchangeRate() {
      this.exchangeRate = await settingsApi.exchangeRates();
    },
    async fetchExchangeRateHistory() {
      this.exchangeRateHistory = await settingsApi.exchangeRatesHistory();
    },
    async fetchData() {
      await Promise.all([
        this.fetchExchangeRate(),
        this.fetchExchangeRateHistory(),
      ]);
    },
    cancel() {
      Object.assign(this.form, this.exchangeRate);
      this.editable = false;
    },
    onValidate(prop: string, isVal: boolean) {
      this.isFormValid = isVal;
    },
    edit() {
      this.editable = true;
      Object.assign(this.form, this.exchangeRate);
    },
    async submit() {
      const form = this.$refs["form"] as ElementForm;
      if (await form.validate()) {
        try {
          await settingsApi.updateExchangeRates({
            exchangeRateSettingsData: this.form,
          });
          this.editable = false;
          await this.fetchData();
        } catch (error) {
          if (isConstraintViolation(error)) {
            error.violations.forEach((violation) =>
              this.$notify.error(this.$t(violation.message))
            );
          } else if (isProblem(error)) {
            this.$notify.error(this.$t(error.detail));
          }
        }
      }
    },
  },
});
</script>
