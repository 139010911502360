/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EmploymentType {
    SALARIED = 'SALARIED',
    SELF_EMPLOYED = 'SELF_EMPLOYED',
    BOTH = 'BOTH'
}

export function EmploymentTypeFromJSON(json: any): EmploymentType {
    return EmploymentTypeFromJSONTyped(json, false);
}

export function EmploymentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmploymentType {
    return json as EmploymentType;
}

export function EmploymentTypeToJSON(value?: EmploymentType | null): any {
    return value as any;
}

