/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccreditationStatus,
    AccreditationStatusFromJSON,
    AccreditationStatusFromJSONTyped,
    AccreditationStatusToJSON,
    IdNameEntry,
    IdNameEntryFromJSON,
    IdNameEntryFromJSONTyped,
    IdNameEntryToJSON,
    InvestorCategory,
    InvestorCategoryFromJSON,
    InvestorCategoryFromJSONTyped,
    InvestorCategoryToJSON,
} from './';

/**
 * 
 * @export
 * @interface AccreditationListItem
 */
export interface AccreditationListItem {
    /**
     * 
     * @type {Date}
     * @memberof AccreditationListItem
     */
    approvedAt?: Date;
    /**
     * 
     * @type {InvestorCategory}
     * @memberof AccreditationListItem
     */
    category: InvestorCategory;
    /**
     * 
     * @type {Date}
     * @memberof AccreditationListItem
     */
    createdAt: Date;
    /**
     * 
     * @type {number}
     * @memberof AccreditationListItem
     */
    id: number;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof AccreditationListItem
     */
    investor: IdNameEntry;
    /**
     * 
     * @type {InvestorCategory}
     * @memberof AccreditationListItem
     */
    previousCategory: InvestorCategory;
    /**
     * 
     * @type {AccreditationStatus}
     * @memberof AccreditationListItem
     */
    status: AccreditationStatus;
    /**
     * 
     * @type {Date}
     * @memberof AccreditationListItem
     */
    validTill?: Date;
}

export function AccreditationListItemFromJSON(json: any): AccreditationListItem {
    return AccreditationListItemFromJSONTyped(json, false);
}

export function AccreditationListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccreditationListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'approvedAt': !exists(json, 'approvedAt') ? undefined : (new Date(json['approvedAt'])),
        'category': InvestorCategoryFromJSON(json['category']),
        'createdAt': (new Date(json['createdAt'])),
        'id': json['id'],
        'investor': IdNameEntryFromJSON(json['investor']),
        'previousCategory': InvestorCategoryFromJSON(json['previousCategory']),
        'status': AccreditationStatusFromJSON(json['status']),
        'validTill': !exists(json, 'validTill') ? undefined : (new Date(json['validTill'])),
    };
}

export function AccreditationListItemToJSON(value?: AccreditationListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'approvedAt': value.approvedAt === undefined ? undefined : (value.approvedAt.toISOString().substr(0,10)),
        'category': InvestorCategoryToJSON(value.category),
        'createdAt': (value.createdAt.toISOString().substr(0,10)),
        'id': value.id,
        'investor': IdNameEntryToJSON(value.investor),
        'previousCategory': InvestorCategoryToJSON(value.previousCategory),
        'status': AccreditationStatusToJSON(value.status),
        'validTill': value.validTill === undefined ? undefined : (value.validTill.toISOString().substr(0,10)),
    };
}


