/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Education,
    EducationFromJSON,
    EducationFromJSONTyped,
    EducationToJSON,
    Gender,
    GenderFromJSON,
    GenderFromJSONTyped,
    GenderToJSON,
    IdDocument,
    IdDocumentFromJSON,
    IdDocumentFromJSONTyped,
    IdDocumentToJSON,
    Income,
    IncomeFromJSON,
    IncomeFromJSONTyped,
    IncomeToJSON,
    IndividualPersonItem,
    IndividualPersonItemFromJSON,
    IndividualPersonItemFromJSONTyped,
    IndividualPersonItemToJSON,
    Occupation,
    OccupationFromJSON,
    OccupationFromJSONTyped,
    OccupationToJSON,
    PrimaryContact,
    PrimaryContactFromJSON,
    PrimaryContactFromJSONTyped,
    PrimaryContactToJSON,
} from './';

/**
 * 
 * @export
 * @interface Individual
 */
export interface Individual {
    /**
     * 
     * @type {PrimaryContact}
     * @memberof Individual
     */
    contact?: PrimaryContact;
    /**
     * 
     * @type {Education}
     * @memberof Individual
     */
    education?: Education;
    /**
     * 
     * @type {Gender}
     * @memberof Individual
     */
    gender?: Gender;
    /**
     * 
     * @type {number}
     * @memberof Individual
     */
    id?: number;
    /**
     * 
     * @type {IdDocument}
     * @memberof Individual
     */
    idDocument?: IdDocument;
    /**
     * 
     * @type {Income}
     * @memberof Individual
     */
    income?: Income;
    /**
     * 
     * @type {string}
     * @memberof Individual
     */
    nationality?: string;
    /**
     * 
     * @type {Occupation}
     * @memberof Individual
     */
    occupation?: Occupation;
    /**
     * 
     * @type {IndividualPersonItem}
     * @memberof Individual
     */
    person?: IndividualPersonItem;
    /**
     * 
     * @type {string}
     * @memberof Individual
     */
    taxIdNumber?: string;
}

export function IndividualFromJSON(json: any): Individual {
    return IndividualFromJSONTyped(json, false);
}

export function IndividualFromJSONTyped(json: any, ignoreDiscriminator: boolean): Individual {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contact': !exists(json, 'contact') ? undefined : PrimaryContactFromJSON(json['contact']),
        'education': !exists(json, 'education') ? undefined : EducationFromJSON(json['education']),
        'gender': !exists(json, 'gender') ? undefined : GenderFromJSON(json['gender']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'idDocument': !exists(json, 'idDocument') ? undefined : IdDocumentFromJSON(json['idDocument']),
        'income': !exists(json, 'income') ? undefined : IncomeFromJSON(json['income']),
        'nationality': !exists(json, 'nationality') ? undefined : json['nationality'],
        'occupation': !exists(json, 'occupation') ? undefined : OccupationFromJSON(json['occupation']),
        'person': !exists(json, 'person') ? undefined : IndividualPersonItemFromJSON(json['person']),
        'taxIdNumber': !exists(json, 'taxIdNumber') ? undefined : json['taxIdNumber'],
    };
}

export function IndividualToJSON(value?: Individual | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contact': PrimaryContactToJSON(value.contact),
        'education': EducationToJSON(value.education),
        'gender': GenderToJSON(value.gender),
        'id': value.id,
        'idDocument': IdDocumentToJSON(value.idDocument),
        'income': IncomeToJSON(value.income),
        'nationality': value.nationality,
        'occupation': OccupationToJSON(value.occupation),
        'person': IndividualPersonItemToJSON(value.person),
        'taxIdNumber': value.taxIdNumber,
    };
}


