
import { defineComponent, PropType } from "vue";
import { IdDocumentType, PageLegalOwnerItem } from "@/api";
import { authorityMixin, PageRequest, pageSortMixin } from "@/util";
import TableFooter from "@/components/TableFooter.vue";

export default defineComponent({
  name: "LegalOwners",
  mixins: [pageSortMixin, authorityMixin],
  components: { TableFooter },
  props: {
    page: {
      type: Object as PropType<PageLegalOwnerItem>,
      required: true,
    },
    pageRequest: {
      type: Object as PropType<PageRequest>,
      required: true,
    },
    fetchOwnersData: {
      type: Function,
      required: true,
    },
  },
  mounted() {
    this.fetchOwnersData();
  },
  data() {
    return {
      idType: IdDocumentType,
    };
  },
  methods: {
    fetchData() {
      this.fetchOwnersData();
    },
  },
});
