import { CampaignAttachmentType, CampaignStatus } from "@/api";
import { i18n } from "@/util";

export class Campaign {
  static TERMINAL_STATUSES: Array<CampaignStatus> = [
    CampaignStatus.CANCELED,
    CampaignStatus.DECLINED,
    CampaignStatus.ABANDONED,
    CampaignStatus.UNSUCCESSFUL,
    CampaignStatus.DISBURSEMENT_SUCCESS,
  ] as CampaignStatus[];

  static FUNDRAISER_VERIFICATION_STATUSES: Array<CampaignStatus> = [
    CampaignStatus.DRAFT,
    CampaignStatus.WAITING_FOR_REGISTRATION_FEE,
    CampaignStatus.ACTIVE,
    CampaignStatus.BUSINESS_VERIFICATION,
  ] as CampaignStatus[];

  static CAMPAIGN_VERIFICATION_STATUSES: Array<CampaignStatus> = [
    CampaignStatus.DRAFT,
    CampaignStatus.WAITING_FOR_REGISTRATION_FEE,
    CampaignStatus.ACTIVE,
    CampaignStatus.BUSINESS_VERIFICATION,
    CampaignStatus.CAMPAIGN_VERIFICATION,
  ] as CampaignStatus[];

  static COLLATERAL_REVIEW_STATUSES: Array<CampaignStatus> = [
    CampaignStatus.DRAFT,
    CampaignStatus.WAITING_FOR_REGISTRATION_FEE,
    CampaignStatus.ACTIVE,
    CampaignStatus.BUSINESS_VERIFICATION,
    CampaignStatus.CAMPAIGN_VERIFICATION,
    CampaignStatus.CAMPAIGN_COLLATERAL_REVIEW,
  ] as CampaignStatus[];

  static status(status: string): string {
    return i18n.global.t("campaign.status." + status);
  }

  static terminatedReason(reason: string): string {
    return reason ? i18n.global.t("campaign.terminatedReason." + reason) : "-";
  }

  static type(type: string): string {
    return type ? i18n.global.t("campaign.type." + type) : "-";
  }

  static attachmentType(type: CampaignAttachmentType): string {
    return type ? i18n.global.t("campaign.attachment.type." + type) : "-";
  }

  static isTerminal(status: CampaignStatus): boolean {
    return status ? Campaign.TERMINAL_STATUSES.includes(status) : false;
  }

  static statusColor(status: CampaignStatus): string {
    switch (status) {
      case CampaignStatus.WAITING_FOR_REGISTRATION_FEE:
        return "#e78b2d";
      case CampaignStatus.ACTIVE:
        return "#a26500";
      case CampaignStatus.BUSINESS_VERIFICATION:
        return "#ca0fd9";
      case CampaignStatus.WAITING_FOR_DATA:
        return "#7601f3";
      case CampaignStatus.CAMPAIGN_VERIFICATION:
        return "#8c2c2c";
      case CampaignStatus.ABANDONED:
        return "#d70e15";
      case CampaignStatus.CAMPAIGN_COLLATERAL_REVIEW:
        return "#7055c9";
      case CampaignStatus.APPROVED:
        return "#0cadc9";
      case CampaignStatus.SIGNING_CAMPAIGN_AGREEMENT:
        return "#0f7dbb";
      case CampaignStatus.FUNDRAISING_RESERVE:
        return "#0a44d3";
      case CampaignStatus.FUNDRAISING_LIVE:
        return "#40800c";
      case CampaignStatus.CAMPAIGN_FAIL:
        return "#3d74ec";
      case CampaignStatus.TEMPORARILY_SUSPENDED:
        return "#7b9be0";
      case CampaignStatus.UNSUCCESSFUL:
        return "#124805";
      case CampaignStatus.CAMPAIGN_RECONCILIATION:
        return "#a6670b";
      case CampaignStatus.SUCCESSFUL:
        return "#dcb50e";
      case CampaignStatus.SPV_SET_UP:
        return "#0943d5";
      case CampaignStatus.READY_FOR_DISBURSEMENT:
        return "#9d563d";
      case CampaignStatus.DISBURSEMENT_IN_PROGRESS:
        return "#579f5a";
      case CampaignStatus.DISBURSEMENT_ERROR:
        return "#a1060a";
      case CampaignStatus.DISBURSEMENT_SUCCESS:
        return "#28e848";
      case CampaignStatus.CANCELLATION_REQUEST:
      case CampaignStatus.CANCELED:
      case CampaignStatus.DECLINED:
        return "#000000";
      case CampaignStatus.DRAFT:
      default:
        return "#7f7f7f";
    }
  }
}
