<template>
  <div class="container ms-0 mb-5" v-if="deal.id">
    <h3>{{ $t("deals.generalInformation") }}</h3>
    <div class="row mb-3">
      <div class="col color-light-text">
        {{ $t("deals.amount") }}
      </div>
      <div class="col">
        {{ $curr(deal.amount) }}
      </div>
      <div class="col color-light-text">
        {{ $t("deals.startAt") }}
      </div>
      <div class="col">
        {{ $date(deal.startAt) }}
      </div>
    </div>
    <div class="row mb-3">
      <div class="col color-light-text">
        {{ $t("deals.lockInPeriod") }}
      </div>
      <div class="col">
        {{ deal.lockInPeriod }}
      </div>
      <div class="col color-light-text">
        {{ $t("deals.endAt") }}
      </div>
      <div class="col">
        {{ $date(deal.endAt) }}
      </div>
    </div>
    <div class="row mb-3">
      <div class="col color-light-text">
        {{ $t("deals.businessName") }}
      </div>
      <div class="col">
        <router-link
          v-if="hasAuthority(authorities.BUSINESS_READ)"
          :to="{ name: 'business', params: { id: deal.business.id } }"
          >{{ deal.business.name }}
        </router-link>
        <span class="text-break" v-else>{{ deal.business.name }}</span>
      </div>
      <div class="col color-light-text"></div>
      <div class="col"></div>
    </div>
    <div class="row mb-3">
      <div class="col color-light-text">
        {{ $t("deals.campaign") }}
      </div>
      <div class="col">
        <router-link
          v-if="hasAuthority(authorities.CAMPAIGN_READ)"
          :to="{ name: 'campaign', params: { id: deal.campaign.id } }"
          >{{ deal.campaign.name }}
        </router-link>
        <span class="text-break" v-else>{{ deal.campaign.name }}</span>
      </div>
      <div class="col color-light-text"></div>
      <div class="col"></div>
    </div>
    <div class="row mb-3">
      <div class="col color-light-text">
        {{ $t("deals.campaignType") }}
      </div>
      <div class="col">
        {{ $enums.campaign.type(deal.campaignType) }}
      </div>
      <div class="col color-light-text"></div>
      <div class="col"></div>
    </div>
    <br />
    <h3>{{ $t("deals.investments") }}</h3>
    <InvestmentSummary
      :fetch-investment-summary="fetchInvestmentSummary"
      :page-request="investmentSummaryPageRequest"
      :page="investmentSummaryPage"
    ></InvestmentSummary>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { Deal, PageInvestmentSummaryItem } from "@/api";
import { authorityMixin, PageRequest } from "@/util";
import { dealApi } from "@/resources";
import InvestmentSummary from "@/views/components/InvestmentSummary.vue";

export default defineComponent({
  name: "DealDetails",
  emits: ["update"],
  mixins: [authorityMixin],
  components: { InvestmentSummary },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      deal: inject("deal") as Deal,
      investmentSummaryPage: {} as PageInvestmentSummaryItem,
      investmentSummaryPageRequest: {} as PageRequest,
    };
  },
  methods: {
    async fetchDeal() {
      let deal = await dealApi.deal({
        id: Number(this.id),
      });
      Object.assign(this.application, deal);
    },
    async fetchInvestmentSummary() {
      this.investmentSummaryPage = await dealApi.dealInvestments({
        id: Number(this.id),
        ...this.investmentSummaryPageRequest,
      });
    },
  },
});
</script>
