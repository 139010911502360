/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PasswordRecoveryForm
 */
export interface PasswordRecoveryForm {
    /**
     * 
     * @type {string}
     * @memberof PasswordRecoveryForm
     */
    password: string;
}

export function PasswordRecoveryFormFromJSON(json: any): PasswordRecoveryForm {
    return PasswordRecoveryFormFromJSONTyped(json, false);
}

export function PasswordRecoveryFormFromJSONTyped(json: any, ignoreDiscriminator: boolean): PasswordRecoveryForm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'password': json['password'],
    };
}

export function PasswordRecoveryFormToJSON(value?: PasswordRecoveryForm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'password': value.password,
    };
}


