<template>
  <div class="card">
    <div class="card-main">
      <div class="d-flex justify-content-between align-items-start">
        <BackLink />
      </div>
      <Headline v-if="preScreening.id" :class="'align-items-start'">
        <div class="h1-sm" style="display: inline">
          {{ preScreening.companyName + " " + preScreening.id }}
        </div>
        <template v-slot:details>
          <Status
            :color="$enums.preScreening.statusColor(preScreening.status)"
            size="medium"
          >
            {{ $enums.preScreening.status(preScreening.status) }}
          </Status>
          <span class="duplicate_status">{{
            preScreening.duplicateStatus === "NONE"
              ? ""
              : $enums.preScreening.duplicateStatus(
                  preScreening.duplicateStatus
                )
          }}</span>
        </template>
        <template
          v-slot:actions
          v-if="
            preScreening.id &&
            hasAuthority(authorities.PRE_SCREENING_OPERATION_WRITE)
          "
        >
          <el-dropdown trigger="click">
            <el-button type="default">
              {{ $t("actions")
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <template #dropdown>
              <el-dropdown-item
                v-for="status in preScreeningStatuses"
                v-bind:key="status"
              >
                <template
                  v-if="
                    preScreening.id &&
                    preScreeningState.nextStatuses &&
                    hasAuthority(authorities.PRE_SCREENING_OPERATION_WRITE)
                  "
                >
                  <OkCancelDialog
                    :dialogDisabled="
                      !preScreeningState.nextStatuses.includes(status)
                    "
                    :header="$t('preScreening.' + status + '.header')"
                    :text="$t('preScreening.' + status + '.text')"
                    :info-message="$t('preScreening.' + status + '.info')"
                    :onOk="() => changePreScreeningStatus(status)"
                    @update="fetchPreScreeningData"
                  />
                </template>
              </el-dropdown-item>
            </template>
          </el-dropdown>
        </template>
      </Headline>
    </div>
    <div class="card-foot">
      <ul class="nav nav-tabs mb-3">
        <TabLink to="preScreeningGeneralInfo"
          >{{ $t("preScreening.tabs.general") }}
        </TabLink>
        <TabLink to="preScreeningDetails"
          >{{ $t("preScreening.tabs.details") }}
        </TabLink>
        <TabLink to="preScreeningAttachments"
          >{{ $t("preScreening.tabs.attachments") }}
        </TabLink>
        <TabLink to="preScreeningReviewNotes"
          >{{ $t("preScreening.tabs.reviewNotes") }}
        </TabLink>
        <TabLink to="preScreeningFollowUpNotes"
          >{{ $t("preScreening.tabs.followUpNotes") }}
        </TabLink>
      </ul>
    </div>
  </div>
  <div class="tab-content">
    <div class="tab-pane active">
      <router-view @update="fetchPreScreeningData"></router-view>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import TabLink from "@/components/TabLink.vue";
import {
  BackOfficeFlowEntityStatePreScreeningStatus,
  PreScreeningItem,
  PreScreeningQuizItem,
  PreScreeningStatus,
} from "@/api";
import { preScreeningApi, preScreeningOperationApi } from "@/resources";
import Headline from "@/components/Headline.vue";
import BackLink from "@/components/BackLink.vue";
import { authorityMixin } from "@/util";
import Status from "@/components/Status.vue";
import OkCancelDialog from "@/views/components/OkCancelDialog.vue";

export default defineComponent({
  name: "PreScreeningView",
  mixins: [authorityMixin],
  components: { Headline, TabLink, BackLink, Status, OkCancelDialog },
  props: ["id"],
  provide() {
    return {
      preScreeningId: computed(() => this.id),
      preScreening: computed(() =>
        this.preScreening.id ? this.preScreening : {}
      ),
      preScreeningQuiz: computed(() =>
        this.preScreeningQuiz.quiz ? this.preScreeningQuiz : {}
      ),
    };
  },
  data() {
    return {
      preScreening: {} as PreScreeningItem,
      preScreeningState: {} as BackOfficeFlowEntityStatePreScreeningStatus,
      preScreeningQuiz: {} as PreScreeningQuizItem,
      preScreeningStatuses: Object.values(PreScreeningStatus).filter(
        (status) =>
          status !== PreScreeningStatus.DRAFT &&
          status !== PreScreeningStatus.RECEIVED
      ),
    };
  },
  async mounted() {
    await this.fetchPreScreeningData();
  },
  methods: {
    async fetchPreScreeningData() {
      await Promise.all([
        this.fetchPreScreening(),
        this.fetchPreScreeningState(),
        this.fetchPreScreeningQuiz(),
      ]);
    },
    async fetchPreScreening() {
      this.preScreening = await preScreeningApi.preScreening({ id: this.id });
    },
    async fetchPreScreeningState() {
      this.preScreeningState = await preScreeningApi.getPreScreeningState({
        id: this.id,
      });
    },
    async fetchPreScreeningQuiz() {
      this.preScreeningQuiz = await preScreeningApi.preScreeningQuiz({
        id: this.id,
      });
    },
    async changePreScreeningStatus(status: PreScreeningStatus) {
      await preScreeningOperationApi.changePreScreeningStatus({
        id: this.id,
        backOfficeFlowStatusOperationRequestPreScreeningStatus: {
          status: status,
        },
      });
    },
  },
});
</script>
<style lang="scss" scoped>
@import "src/styles/variables.scss";
.duplicate_status {
  padding-left: 20px;
  color: $color-danger;
}
</style>
