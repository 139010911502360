/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InvestorLegalChecklist
 */
export interface InvestorLegalChecklist {
    /**
     * 
     * @type {boolean}
     * @memberof InvestorLegalChecklist
     */
    acceptableRegistrationCountry?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvestorLegalChecklist
     */
    accreditationCompleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvestorLegalChecklist
     */
    checkedAuthorisationLetter?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvestorLegalChecklist
     */
    collectedKybDocuments?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvestorLegalChecklist
     */
    informationMatch?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof InvestorLegalChecklist
     */
    kycResultDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof InvestorLegalChecklist
     */
    shareholdersClearedKyc?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvestorLegalChecklist
     */
    shareholdersIdentified?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvestorLegalChecklist
     */
    validCertificate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvestorLegalChecklist
     */
    verificationCompleted?: boolean;
}

export function InvestorLegalChecklistFromJSON(json: any): InvestorLegalChecklist {
    return InvestorLegalChecklistFromJSONTyped(json, false);
}

export function InvestorLegalChecklistFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestorLegalChecklist {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'acceptableRegistrationCountry': !exists(json, 'acceptableRegistrationCountry') ? undefined : json['acceptableRegistrationCountry'],
        'accreditationCompleted': !exists(json, 'accreditationCompleted') ? undefined : json['accreditationCompleted'],
        'checkedAuthorisationLetter': !exists(json, 'checkedAuthorisationLetter') ? undefined : json['checkedAuthorisationLetter'],
        'collectedKybDocuments': !exists(json, 'collectedKybDocuments') ? undefined : json['collectedKybDocuments'],
        'informationMatch': !exists(json, 'informationMatch') ? undefined : json['informationMatch'],
        'kycResultDate': !exists(json, 'kycResultDate') ? undefined : (new Date(json['kycResultDate'])),
        'shareholdersClearedKyc': !exists(json, 'shareholdersClearedKyc') ? undefined : json['shareholdersClearedKyc'],
        'shareholdersIdentified': !exists(json, 'shareholdersIdentified') ? undefined : json['shareholdersIdentified'],
        'validCertificate': !exists(json, 'validCertificate') ? undefined : json['validCertificate'],
        'verificationCompleted': !exists(json, 'verificationCompleted') ? undefined : json['verificationCompleted'],
    };
}

export function InvestorLegalChecklistToJSON(value?: InvestorLegalChecklist | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'acceptableRegistrationCountry': value.acceptableRegistrationCountry,
        'accreditationCompleted': value.accreditationCompleted,
        'checkedAuthorisationLetter': value.checkedAuthorisationLetter,
        'collectedKybDocuments': value.collectedKybDocuments,
        'informationMatch': value.informationMatch,
        'kycResultDate': value.kycResultDate === undefined ? undefined : (value.kycResultDate.toISOString().substr(0,10)),
        'shareholdersClearedKyc': value.shareholdersClearedKyc,
        'shareholdersIdentified': value.shareholdersIdentified,
        'validCertificate': value.validCertificate,
        'verificationCompleted': value.verificationCompleted,
    };
}


