/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AttachmentListItem,
    AttachmentListItemFromJSON,
    AttachmentListItemToJSON,
    Investor,
    InvestorFromJSON,
    InvestorToJSON,
    LegalFinanceItem,
    LegalFinanceItemFromJSON,
    LegalFinanceItemToJSON,
    PageBankAccountListItem,
    PageBankAccountListItemFromJSON,
    PageBankAccountListItemToJSON,
    PageInvestorListItem,
    PageInvestorListItemFromJSON,
    PageInvestorListItemToJSON,
    PageLegalMemberItem,
    PageLegalMemberItemFromJSON,
    PageLegalMemberItemToJSON,
    PageLegalOwnerItem,
    PageLegalOwnerItemFromJSON,
    PageLegalOwnerItemToJSON,
    PageLegalPersonItem,
    PageLegalPersonItemFromJSON,
    PageLegalPersonItemToJSON,
} from '../models';

export interface InvestorRequest {
    id: number;
}

export interface InvestorAttachmentRequest {
    id: number;
    attachmentId: number;
}

export interface InvestorAttachmentsRequest {
    id: number;
}

export interface InvestorBankAccountsRequest {
    id: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface InvestorFinanceRequest {
    id: number;
}

export interface InvestorMembersRequest {
    id: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface InvestorOwnersRequest {
    id: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface InvestorPersonsRequest {
    id: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface InvestorsRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
    name?: string;
    email?: string;
    categories?: Array<string>;
    types?: Array<string>;
    countries?: Array<string>;
}

/**
 * 
 */
export class InvestorControllerApi extends runtime.BaseAPI {

    /**
     */
    async investorRaw(requestParameters: InvestorRequest): Promise<runtime.ApiResponse<Investor>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling investor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/investors/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InvestorFromJSON(jsonValue));
    }

    /**
     */
    async investor(requestParameters: InvestorRequest): Promise<Investor> {
        const response = await this.investorRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async investorAttachmentRaw(requestParameters: InvestorAttachmentRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling investorAttachment.');
        }

        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId','Required parameter requestParameters.attachmentId was null or undefined when calling investorAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/investors/{id}/attachments/{attachmentId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async investorAttachment(requestParameters: InvestorAttachmentRequest): Promise<Blob> {
        const response = await this.investorAttachmentRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async investorAttachmentsRaw(requestParameters: InvestorAttachmentsRequest): Promise<runtime.ApiResponse<Array<AttachmentListItem>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling investorAttachments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/investors/{id}/attachments`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AttachmentListItemFromJSON));
    }

    /**
     */
    async investorAttachments(requestParameters: InvestorAttachmentsRequest): Promise<Array<AttachmentListItem>> {
        const response = await this.investorAttachmentsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async investorBankAccountsRaw(requestParameters: InvestorBankAccountsRequest): Promise<runtime.ApiResponse<PageBankAccountListItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling investorBankAccounts.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/investors/{id}/bankAccounts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageBankAccountListItemFromJSON(jsonValue));
    }

    /**
     */
    async investorBankAccounts(requestParameters: InvestorBankAccountsRequest): Promise<PageBankAccountListItem> {
        const response = await this.investorBankAccountsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async investorFinanceRaw(requestParameters: InvestorFinanceRequest): Promise<runtime.ApiResponse<LegalFinanceItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling investorFinance.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/investors/{id}/finance`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LegalFinanceItemFromJSON(jsonValue));
    }

    /**
     */
    async investorFinance(requestParameters: InvestorFinanceRequest): Promise<LegalFinanceItem> {
        const response = await this.investorFinanceRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async investorMembersRaw(requestParameters: InvestorMembersRequest): Promise<runtime.ApiResponse<PageLegalMemberItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling investorMembers.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/investors/{id}/members`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageLegalMemberItemFromJSON(jsonValue));
    }

    /**
     */
    async investorMembers(requestParameters: InvestorMembersRequest): Promise<PageLegalMemberItem> {
        const response = await this.investorMembersRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async investorOwnersRaw(requestParameters: InvestorOwnersRequest): Promise<runtime.ApiResponse<PageLegalOwnerItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling investorOwners.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/investors/{id}/owners`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageLegalOwnerItemFromJSON(jsonValue));
    }

    /**
     */
    async investorOwners(requestParameters: InvestorOwnersRequest): Promise<PageLegalOwnerItem> {
        const response = await this.investorOwnersRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async investorPersonsRaw(requestParameters: InvestorPersonsRequest): Promise<runtime.ApiResponse<PageLegalPersonItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling investorPersons.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/investors/{id}/persons`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageLegalPersonItemFromJSON(jsonValue));
    }

    /**
     */
    async investorPersons(requestParameters: InvestorPersonsRequest): Promise<PageLegalPersonItem> {
        const response = await this.investorPersonsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async investorsRaw(requestParameters: InvestorsRequest): Promise<runtime.ApiResponse<PageInvestorListItem>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        if (requestParameters.categories) {
            queryParameters['categories'] = requestParameters.categories;
        }

        if (requestParameters.types) {
            queryParameters['types'] = requestParameters.types;
        }

        if (requestParameters.countries) {
            queryParameters['countries'] = requestParameters.countries;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/investors`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageInvestorListItemFromJSON(jsonValue));
    }

    /**
     */
    async investors(requestParameters: InvestorsRequest): Promise<PageInvestorListItem> {
        const response = await this.investorsRaw(requestParameters);
        return await response.value();
    }

}
