/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FieldHistoryView,
    FieldHistoryViewFromJSON,
    FieldHistoryViewFromJSONTyped,
    FieldHistoryViewToJSON,
} from './';

/**
 * 
 * @export
 * @interface CampaignSettingsHistoryItem
 */
export interface CampaignSettingsHistoryItem {
    /**
     * 
     * @type {Array<FieldHistoryView>}
     * @memberof CampaignSettingsHistoryItem
     */
    fundraisingCommissionFee: Array<FieldHistoryView>;
    /**
     * 
     * @type {Array<FieldHistoryView>}
     * @memberof CampaignSettingsHistoryItem
     */
    registrationFee: Array<FieldHistoryView>;
    /**
     * 
     * @type {Array<FieldHistoryView>}
     * @memberof CampaignSettingsHistoryItem
     */
    spvFee: Array<FieldHistoryView>;
}

export function CampaignSettingsHistoryItemFromJSON(json: any): CampaignSettingsHistoryItem {
    return CampaignSettingsHistoryItemFromJSONTyped(json, false);
}

export function CampaignSettingsHistoryItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): CampaignSettingsHistoryItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fundraisingCommissionFee': ((json['fundraisingCommissionFee'] as Array<any>).map(FieldHistoryViewFromJSON)),
        'registrationFee': ((json['registrationFee'] as Array<any>).map(FieldHistoryViewFromJSON)),
        'spvFee': ((json['spvFee'] as Array<any>).map(FieldHistoryViewFromJSON)),
    };
}

export function CampaignSettingsHistoryItemToJSON(value?: CampaignSettingsHistoryItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fundraisingCommissionFee': ((value.fundraisingCommissionFee as Array<any>).map(FieldHistoryViewToJSON)),
        'registrationFee': ((value.registrationFee as Array<any>).map(FieldHistoryViewToJSON)),
        'spvFee': ((value.spvFee as Array<any>).map(FieldHistoryViewToJSON)),
    };
}


