/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PreScreeningDuplicateStatus {
    NONE = 'NONE',
    NEW = 'NEW',
    OLD = 'OLD'
}

export function PreScreeningDuplicateStatusFromJSON(json: any): PreScreeningDuplicateStatus {
    return PreScreeningDuplicateStatusFromJSONTyped(json, false);
}

export function PreScreeningDuplicateStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreScreeningDuplicateStatus {
    return json as PreScreeningDuplicateStatus;
}

export function PreScreeningDuplicateStatusToJSON(value?: PreScreeningDuplicateStatus | null): any {
    return value as any;
}

