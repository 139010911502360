<template>
  <div class="row">
    <div class="tab-content">
      <div class="tab-pane active">
        <router-view @update="() => this.$emit('update')"></router-view>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, inject } from "vue";
import { authorityMixin } from "@/util";
import { SettingsHistoryItem, SettingsItem } from "@/api";

export default defineComponent({
  name: "SettingsInvestorLimit",
  mixins: [authorityMixin],
  provide() {
    return {
      investorIndividualLimit: computed(
        () => this.settings.investorIndividualLimit
      ),
      investorIndividualLimitHistory: computed(
        () => this.settingsHistory?.investorIndividualLimit ?? {}
      ),
      investorLegalLimit: computed(() => this.settings.investorLegalLimit),
      investorLegalLimitHistory: computed(
        () => this.settingsHistory?.investorLegalLimit ?? {}
      ),
    };
  },
  data() {
    return {
      settings: inject("settings") as SettingsItem,
      settingsHistory: inject("settingsHistory") as SettingsHistoryItem,
    };
  },
});
</script>
