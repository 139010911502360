import { RxStomp } from "@stomp/rx-stomp";
import { EntityUpdated } from "@/util/ws/model/EntityUpdated";
import { Subscription } from "rxjs";
import { UserLogout } from "@/util/ws/model";

const rxStomp = new RxStomp();
rxStomp.configure({
  brokerURL: `${process.env.VUE_APP_WS_PROTOCOL}//${
    process.env.VUE_APP_BACKOFFICE_WS_PATH
  }${location.port ? ":8080" : ""}/ws`,
  reconnectDelay: 5000,
  heartbeatIncoming: 4000,
  heartbeatOutgoing: 4000,
});

rxStomp.activate();

export function watchCampaignUpdate(
  id: string,
  callback: (message: EntityUpdated) => void
): Subscription {
  return rxStomp
    .watch(`/exchange/campaigns/${id}.updated`)
    .subscribe((message) => {
      const parsed: EntityUpdated = JSON.parse(message.body);
      callback(parsed);
    });
}

export function watchBusinessUpdate(
  id: number,
  callback: (message: EntityUpdated) => void
): Subscription {
  return rxStomp
    .watch(`/exchange/businesses/${id}.updated`)
    .subscribe((message) => {
      const parsed: EntityUpdated = JSON.parse(message.body);
      callback(parsed);
    });
}

export function watchInvestmentUpdate(
  id: string,
  callback: (message: EntityUpdated) => void
): Subscription {
  return rxStomp
    .watch(`/exchange/investments/${id}.updated`)
    .subscribe((message) => {
      const parsed: EntityUpdated = JSON.parse(message.body);
      callback(parsed);
    });
}

export function logoutUser(
  id: number,
  callback: (message: UserLogout) => void
): Subscription {
  return rxStomp.watch(`/exchange/users/${id}.logout`).subscribe((message) => {
    const parsed: UserLogout = JSON.parse(message.body);
    callback(parsed);
  });
}
