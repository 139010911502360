
import { defineComponent, inject } from "vue";
import { LegalCommerceItem } from "@/api";
import LegalCommerce from "@/views/components/LegalCommerce.vue";

export default defineComponent({
  name: "BusinessCommerceInfo",
  components: { LegalCommerce },
  data() {
    return {
      commerce: inject("commerce") as LegalCommerceItem,
    };
  },
});
