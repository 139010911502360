import { Authority } from "@/util/authority";

export const AllAllowedPermissions = [
  Authority.CAMPAIGN_READ,
  Authority.CAMPAIGN_WRITE,
  Authority.CAMPAIGN_COLLATERAL_REVIEW,
  Authority.CAMPAIGN_FUNDRAISER_VERIFICATION,
  Authority.CAMPAIGN_CAMPAIGN_VERIFICATION,
  Authority.CAMPAIGN_OPERATION_WRITE,
  //campaign statuses
  Authority.CAMPAIGN_DRAFT_VIEW,
  Authority.CAMPAIGN_WAITING_FOR_REGISTRATION_FEE_VIEW,
  Authority.CAMPAIGN_ACTIVE_VIEW,
  Authority.CAMPAIGN_CANCELLATION_REQUEST_VIEW,
  Authority.CAMPAIGN_CANCELED_VIEW,
  Authority.CAMPAIGN_BUSINESS_VERIFICATION_VIEW,
  Authority.CAMPAIGN_CAMPAIGN_VERIFICATION_VIEW,
  Authority.CAMPAIGN_CAMPAIGN_COLLATERAL_REVIEW_VIEW,
  Authority.CAMPAIGN_WAITING_FOR_DATA_VIEW,
  Authority.CAMPAIGN_DECLINED_VIEW,
  Authority.CAMPAIGN_ABANDONED_VIEW,
  Authority.CAMPAIGN_APPROVED_VIEW,
  Authority.CAMPAIGN_SIGNING_CAMPAIGN_AGREEMENT_VIEW,
  Authority.CAMPAIGN_FUNDRAISING_RESERVE_VIEW,
  Authority.CAMPAIGN_FUNDRAISING_LIVE_VIEW,
  Authority.CAMPAIGN_FAIL_VIEW,
  Authority.CAMPAIGN_RECONCILIATION_VIEW,
  Authority.CAMPAIGN_TEMPORARILY_SUSPENDED_VIEW,
  Authority.CAMPAIGN_UNSUCCESSFUL_VIEW,
  Authority.CAMPAIGN_SUCCESSFUL_VIEW,
  Authority.CAMPAIGN_READY_FOR_DISBURSEMENT_VIEW,
  Authority.CAMPAIGN_DISBURSEMENT_IN_PROGRESS_VIEW,
  Authority.CAMPAIGN_DISBURSEMENT_ERROR_VIEW,
  Authority.CAMPAIGN_DISBURSEMENT_SUCCESS_VIEW,
  Authority.CAMPAIGN_SPV_SET_UP_VIEW,
  Authority.CAMPAIGN_OPERATION_MANUAL_DISBURSEMENT,

  Authority.ROLE_READ,
  Authority.ROLE_WRITE,

  Authority.USER_READ,
  Authority.USER_WRITE,

  Authority.INVESTOR_READ,
  Authority.DEAL_READ,
  Authority.TRANSACTION_READ,
  Authority.TRANSACTION_BATCH,
  Authority.TRANSACTION_WRITE,
  Authority.BUSINESS_READ,
  Authority.INVESTMENT_READ,
  Authority.INVESTMENT_WRITE,
  Authority.INVESTMENT_STATUS_CHANGE,
  Authority.INVESTMENT_MANUAL_REFUND,
  Authority.INVESTMENT_INVESTOR_VERIFICATION,
  Authority.PRE_SCREENING_READ,
  Authority.PRE_SCREENING_WRITE,
  Authority.PRE_SCREENING_OPERATION_WRITE,
  Authority.SETTINGS_READ,
  Authority.SETTINGS_WRITE,
  Authority.SETTINGS_ACCOUNT_DETAILS,
  Authority.SETTINGS_EXCHANGE_RATES,
  Authority.ACCREDITATION_READ,
  Authority.ACCREDITATION_OPERATION_WRITE,
];

export const AllCampaignStatusPermissions = [
  Authority.CAMPAIGN_DRAFT_VIEW,
  Authority.CAMPAIGN_WAITING_FOR_REGISTRATION_FEE_VIEW,
  Authority.CAMPAIGN_ACTIVE_VIEW,
  Authority.CAMPAIGN_CANCELLATION_REQUEST_VIEW,
  Authority.CAMPAIGN_CANCELED_VIEW,
  Authority.CAMPAIGN_BUSINESS_VERIFICATION_VIEW,
  Authority.CAMPAIGN_CAMPAIGN_VERIFICATION_VIEW,
  Authority.CAMPAIGN_CAMPAIGN_COLLATERAL_REVIEW_VIEW,
  Authority.CAMPAIGN_WAITING_FOR_DATA_VIEW,
  Authority.CAMPAIGN_DECLINED_VIEW,
  Authority.CAMPAIGN_ABANDONED_VIEW,
  Authority.CAMPAIGN_APPROVED_VIEW,
  Authority.CAMPAIGN_SIGNING_CAMPAIGN_AGREEMENT_VIEW,
  Authority.CAMPAIGN_FUNDRAISING_RESERVE_VIEW,
  Authority.CAMPAIGN_FUNDRAISING_LIVE_VIEW,
  Authority.CAMPAIGN_FAIL_VIEW,
  Authority.CAMPAIGN_RECONCILIATION_VIEW,
  Authority.CAMPAIGN_TEMPORARILY_SUSPENDED_VIEW,
  Authority.CAMPAIGN_UNSUCCESSFUL_VIEW,
  Authority.CAMPAIGN_SUCCESSFUL_VIEW,
  Authority.CAMPAIGN_READY_FOR_DISBURSEMENT_VIEW,
  Authority.CAMPAIGN_DISBURSEMENT_IN_PROGRESS_VIEW,
  Authority.CAMPAIGN_DISBURSEMENT_ERROR_VIEW,
  Authority.CAMPAIGN_DISBURSEMENT_SUCCESS_VIEW,
  Authority.CAMPAIGN_SPV_SET_UP_VIEW,
];

export interface ReadWriteGroup {
  read: Authority;
  write: Authority;
}

export const ReadWritePermissions = [
  {
    read: Authority.USER_READ,
    write: Authority.USER_WRITE,
  } as ReadWriteGroup,
  {
    read: Authority.ROLE_READ,
    write: Authority.ROLE_WRITE,
  } as ReadWriteGroup,
  {
    read: Authority.CAMPAIGN_READ,
    write: Authority.CAMPAIGN_OPERATION_WRITE,
  } as ReadWriteGroup,
  {
    read: Authority.CAMPAIGN_READ,
    write: Authority.CAMPAIGN_WRITE,
  } as ReadWriteGroup,
  {
    read: Authority.CAMPAIGN_READ,
    write: Authority.CAMPAIGN_OPERATION_MANUAL_DISBURSEMENT,
  } as ReadWriteGroup,
  {
    read: Authority.CAMPAIGN_READ,
    write: Authority.CAMPAIGN_COLLATERAL_REVIEW,
  } as ReadWriteGroup,
  {
    read: Authority.CAMPAIGN_READ,
    write: Authority.CAMPAIGN_FUNDRAISER_VERIFICATION,
  } as ReadWriteGroup,
  {
    read: Authority.CAMPAIGN_READ,
    write: Authority.CAMPAIGN_CAMPAIGN_VERIFICATION,
  } as ReadWriteGroup,
  {
    read: Authority.PRE_SCREENING_READ,
    write: Authority.PRE_SCREENING_WRITE,
  } as ReadWriteGroup,
  {
    read: Authority.PRE_SCREENING_READ,
    write: Authority.PRE_SCREENING_OPERATION_WRITE,
  } as ReadWriteGroup,
  {
    read: Authority.INVESTMENT_READ,
    write: Authority.INVESTMENT_STATUS_CHANGE,
  } as ReadWriteGroup,
  {
    read: Authority.INVESTMENT_READ,
    write: Authority.INVESTMENT_MANUAL_REFUND,
  } as ReadWriteGroup,
  {
    read: Authority.INVESTMENT_READ,
    write: Authority.INVESTMENT_WRITE,
  } as ReadWriteGroup,
  {
    read: Authority.INVESTMENT_READ,
    write: Authority.INVESTMENT_INVESTOR_VERIFICATION,
  } as ReadWriteGroup,
  {
    read: Authority.SETTINGS_READ,
    write: Authority.SETTINGS_WRITE,
  } as ReadWriteGroup,
  {
    read: Authority.SETTINGS_READ,
    write: Authority.SETTINGS_ACCOUNT_DETAILS,
  } as ReadWriteGroup,
  {
    read: Authority.SETTINGS_READ,
    write: Authority.SETTINGS_EXCHANGE_RATES,
  } as ReadWriteGroup,
  {
    read: Authority.ACCREDITATION_READ,
    write: Authority.ACCREDITATION_OPERATION_WRITE,
  } as ReadWriteGroup,
  {
    read: Authority.TRANSACTION_READ,
    write: Authority.TRANSACTION_BATCH,
  } as ReadWriteGroup,
  {
    read: Authority.TRANSACTION_READ,
    write: Authority.TRANSACTION_WRITE,
  } as ReadWriteGroup,
];

export function uncheckEditPermission(
  authorities: string[],
  readAuthority: string,
  writeAuthority: string
): string[] {
  if (!authorities.includes(readAuthority)) {
    return authorities.filter(function (item) {
      return writeAuthority != item;
    });
  }
  return authorities;
}
