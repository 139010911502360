/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Currency,
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './';

/**
 * 
 * @export
 * @interface Income
 */
export interface Income {
    /**
     * 
     * @type {Currency}
     * @memberof Income
     */
    currency: Currency;
    /**
     * 
     * @type {number}
     * @memberof Income
     */
    totalAnnualInvestments: number;
    /**
     * 
     * @type {number}
     * @memberof Income
     */
    totalGrossJointAnnualIncome: number;
    /**
     * 
     * @type {number}
     * @memberof Income
     */
    totalGrossPersonalAnnualIncome: number;
    /**
     * 
     * @type {number}
     * @memberof Income
     */
    totalNetJointAssets: number;
    /**
     * 
     * @type {number}
     * @memberof Income
     */
    totalNetPersonalAssets: number;
    /**
     * 
     * @type {string}
     * @memberof Income
     */
    wealthSource: string;
}

export function IncomeFromJSON(json: any): Income {
    return IncomeFromJSONTyped(json, false);
}

export function IncomeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Income {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currency': CurrencyFromJSON(json['currency']),
        'totalAnnualInvestments': json['totalAnnualInvestments'],
        'totalGrossJointAnnualIncome': json['totalGrossJointAnnualIncome'],
        'totalGrossPersonalAnnualIncome': json['totalGrossPersonalAnnualIncome'],
        'totalNetJointAssets': json['totalNetJointAssets'],
        'totalNetPersonalAssets': json['totalNetPersonalAssets'],
        'wealthSource': json['wealthSource'],
    };
}

export function IncomeToJSON(value?: Income | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currency': CurrencyToJSON(value.currency),
        'totalAnnualInvestments': value.totalAnnualInvestments,
        'totalGrossJointAnnualIncome': value.totalGrossJointAnnualIncome,
        'totalGrossPersonalAnnualIncome': value.totalGrossPersonalAnnualIncome,
        'totalNetJointAssets': value.totalNetJointAssets,
        'totalNetPersonalAssets': value.totalNetPersonalAssets,
        'wealthSource': value.wealthSource,
    };
}


