
import { defineComponent, inject } from "vue";
import { Investor, PageBankAccountListItem } from "@/api";
import BankAccounts from "@/views/components/BankAccounts.vue";
import { investorApi } from "@/resources";
import { PageRequest } from "@/util";

export default defineComponent({
  name: "InvestorBankDetails",
  components: { BankAccounts },
  data() {
    return {
      investorId: inject("investorId") as number,
      investor: inject("investor") as Investor,
      bankAccountsPage: {} as PageBankAccountListItem,
      bankAccountsPageRequest: {} as PageRequest,
    };
  },
  methods: {
    async fetchBankAccountsData() {
      this.bankAccountsPage = await investorApi.investorBankAccounts({
        id: this.investorId,
        ...this.bankAccountsPageRequest,
      });
    },
  },
});
