/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InvestorIndividualChecklist
 */
export interface InvestorIndividualChecklist {
    /**
     * 
     * @type {boolean}
     * @memberof InvestorIndividualChecklist
     */
    accreditationChecked?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvestorIndividualChecklist
     */
    addressChecked?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvestorIndividualChecklist
     */
    amlChecked?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvestorIndividualChecklist
     */
    documentVerified?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvestorIndividualChecklist
     */
    dofChecked?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvestorIndividualChecklist
     */
    faceVerified?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof InvestorIndividualChecklist
     */
    kycResultDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof InvestorIndividualChecklist
     */
    locationChecked?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvestorIndividualChecklist
     */
    nationalityChecked?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvestorIndividualChecklist
     */
    occupationChecked?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvestorIndividualChecklist
     */
    photoVerified?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvestorIndividualChecklist
     */
    verificationCompleted?: boolean;
}

export function InvestorIndividualChecklistFromJSON(json: any): InvestorIndividualChecklist {
    return InvestorIndividualChecklistFromJSONTyped(json, false);
}

export function InvestorIndividualChecklistFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestorIndividualChecklist {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accreditationChecked': !exists(json, 'accreditationChecked') ? undefined : json['accreditationChecked'],
        'addressChecked': !exists(json, 'addressChecked') ? undefined : json['addressChecked'],
        'amlChecked': !exists(json, 'amlChecked') ? undefined : json['amlChecked'],
        'documentVerified': !exists(json, 'documentVerified') ? undefined : json['documentVerified'],
        'dofChecked': !exists(json, 'dofChecked') ? undefined : json['dofChecked'],
        'faceVerified': !exists(json, 'faceVerified') ? undefined : json['faceVerified'],
        'kycResultDate': !exists(json, 'kycResultDate') ? undefined : (new Date(json['kycResultDate'])),
        'locationChecked': !exists(json, 'locationChecked') ? undefined : json['locationChecked'],
        'nationalityChecked': !exists(json, 'nationalityChecked') ? undefined : json['nationalityChecked'],
        'occupationChecked': !exists(json, 'occupationChecked') ? undefined : json['occupationChecked'],
        'photoVerified': !exists(json, 'photoVerified') ? undefined : json['photoVerified'],
        'verificationCompleted': !exists(json, 'verificationCompleted') ? undefined : json['verificationCompleted'],
    };
}

export function InvestorIndividualChecklistToJSON(value?: InvestorIndividualChecklist | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accreditationChecked': value.accreditationChecked,
        'addressChecked': value.addressChecked,
        'amlChecked': value.amlChecked,
        'documentVerified': value.documentVerified,
        'dofChecked': value.dofChecked,
        'faceVerified': value.faceVerified,
        'kycResultDate': value.kycResultDate === undefined ? undefined : (value.kycResultDate.toISOString().substr(0,10)),
        'locationChecked': value.locationChecked,
        'nationalityChecked': value.nationalityChecked,
        'occupationChecked': value.occupationChecked,
        'photoVerified': value.photoVerified,
        'verificationCompleted': value.verificationCompleted,
    };
}


