<template>
  <div v-if="this.content.length > 0" class="container ms-0 mb-0">
    <template v-for="(item, key) in this.content" :key="key">
      <ContentItem
        :campaign-id="this.id"
        :item="item"
        :campaign-is-editable="isCampaignEditable()"
        @update="getContent"
      >
      </ContentItem>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { campaignContentApi } from "@/resources";
import { authorityMixin } from "@/util";
import ContentItem from "@/views/campaign/tabs/content/CampaignContentItem.vue";
import { CampaignContentData, CampaignItem, CampaignStatus } from "@/api";

export default defineComponent({
  name: "CampaignContent",
  components: { ContentItem },
  mixins: [authorityMixin],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      content: [] as CampaignContentData[],
      campaign: inject("campaign") as CampaignItem,
      campaignEditStatuses: [
        CampaignStatus.DRAFT,
        CampaignStatus.WAITING_FOR_REGISTRATION_FEE,
        CampaignStatus.ACTIVE,
        CampaignStatus.BUSINESS_VERIFICATION,
        CampaignStatus.CAMPAIGN_VERIFICATION,
        CampaignStatus.CAMPAIGN_COLLATERAL_REVIEW,
        CampaignStatus.APPROVED,
        CampaignStatus.SIGNING_CAMPAIGN_AGREEMENT,
        CampaignStatus.FUNDRAISING_RESERVE,
        CampaignStatus.FUNDRAISING_LIVE,
        CampaignStatus.CAMPAIGN_FAIL,
        CampaignStatus.TEMPORARILY_SUSPENDED,
        CampaignStatus.CAMPAIGN_RECONCILIATION,
      ],
    };
  },
  async mounted() {
    await this.getContent();
  },
  methods: {
    async getContent() {
      this.content = await campaignContentApi.getContent({
        id: Number(this.id),
      });
      this.content = this.content.filter(
        (it) => it.type.hasAttach && it.type.hasDescription
      );
    },
    isCampaignEditable(): boolean {
      return this.campaignEditStatuses.includes(this.campaign.status);
    },
  },
});
</script>
