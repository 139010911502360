<template>
  <Headline
    >{{ $t("sidebar.users") }}
    <template v-slot:actions>
      <el-button
        v-if="!filterVisible"
        class="filter"
        @click="filterVisible = true"
        >{{ $t("filter.show") }}
      </el-button>
      <el-button
        v-if="filterVisible"
        class="filter"
        @click="filterVisible = false"
        >{{ $t("filter.hide") }}
      </el-button>
    </template>
  </Headline>
  <PortalUserFilter
    v-if="filterVisible"
    v-model:portal-user-filter="filter"
    @submit="fetchData"
  />
  <el-table
    :data="userPage.content"
    @sort-change="onSortChange($event, pageRequest)"
    @cell-click="onCellClick"
    :row-style="{ cursor: 'pointer' }"
  >
    <el-table-column :label="$t('id')" prop="id" sortable="custom" width="85">
    </el-table-column>
    <el-table-column
      :label="$t('user.givenName')"
      prop="givenName"
      sortable="custom"
      :show-overflow-tooltip="true"
    >
    </el-table-column>
    <el-table-column
      :label="$t('user.lastName')"
      prop="lastName"
      sortable="custom"
      :show-overflow-tooltip="true"
    >
    </el-table-column>
    <el-table-column
      :label="$t('user.email')"
      prop="email"
      sortable="custom"
      :show-overflow-tooltip="true"
    >
      <template #default="scope">
        <span class="text-break">{{ scope.row.email }}</span>
      </template>
    </el-table-column>
    <el-table-column :label="$t('user.phone')" prop="phone" sortable="custom">
      <template #default="scope">{{ $phone(scope.row.phone) }}</template>
    </el-table-column>
    <el-table-column :label="$t('user.role')" prop="role" sortable="custom">
      <template #default="{ row }">
        {{ row.role }}
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('status')"
      prop="status"
      sortable="custom"
      width="220"
    >
      <template #default="scope">
        <Status :color="$enums.portalUser.statusColor(scope.row.status)">
          {{ $enums.portalUser.status(scope.row.status) }}
        </Status>
      </template>
    </el-table-column>
  </el-table>

  <TableFooter
    @size-change="onSizeChange($event, pageRequest)"
    @current-change="onCurrentChange($event, pageRequest)"
    :page="userPage"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { portalUserApi } from "@/resources";
import Headline from "@/components/Headline.vue";
import { PagePortalUser, PortalUser } from "@/api";
import { authorityMixin, PageRequest, pageSortMixin } from "@/util";
import TableFooter from "@/components/TableFooter.vue";
import Status from "@/components/Status.vue";
import PortalUserFilter from "@/views/user/portal/PortalUserFilter.vue";

export interface Filter {
  fullname: string;
  email: string;
  statuses: string[];
}

export default defineComponent({
  name: "PortalUserList",
  components: { Headline, Status, TableFooter, PortalUserFilter },
  mixins: [pageSortMixin, authorityMixin],
  data: () => ({
    userPage: {} as PagePortalUser,
    pageRequest: {} as PageRequest,
    filterVisible: false,
    filter: {} as Filter,
  }),
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.userPage = await portalUserApi.portalUsers({
        ...this.pageRequest,
        ...this.filter,
      });
    },
    onCellClick(row: PortalUser) {
      this.$router.push({ name: "portalUser", params: { id: row.id } });
    },
  },
});
</script>
