<template>
  <div class="comment-body" v-if="note.id">
    <div v-if="!editable">
      <div class="text">
        <p class="note wrap-text">{{ note.note }}</p>
      </div>
      <div class="note-bottom">
        <NoteInfo
          class="note-bottom-info"
          v-model:note="note"
          :translation-prefix="translationPrefix"
        />
        <div v-if="!editDisabled(note) || !deleteDisabled(note)">
          <el-button
            class="el-button--x-small"
            @click="edit"
            v-if="!editDisabled(note)"
          >
            {{ $t("edit") }}
          </el-button>
          <ButtonOkCancelDialog
            v-if="!deleteDisabled(note)"
            class="el-button--x-small"
            :label="$t(`${translationPrefix}.delete.label`)"
            :title="$t(`${translationPrefix}.delete.title`)"
            :message="$t(`${translationPrefix}.delete.message`)"
            :onOk="deleteNote"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <el-form
        :model="editForm"
        ref="editForm"
        :rules="rules"
        @validate="onValidate"
      >
        <div class="text">
          <el-form-item prop="note" :label="$t(`${translationPrefix}.edit`)">
            <el-input
              :maxlength="1000"
              :placeholder="$t(`${translationPrefix}.placeholder`)"
              show-word-limit
              type="textarea"
              :autosize="{ minRows: 5 }"
              resize="vertical"
              v-model="editForm.note"
            >
            </el-input>
          </el-form-item>
          <div class="note-bottom">
            <NoteInfo
              class="note-bottom-info"
              v-model:note="note"
              :translation-prefix="translationPrefix"
            />
            <div class="note-bottom-buttons-group">
              <el-button size="small" type="default" @click="cancel">
                {{ $t("cancel") }}
              </el-button>
              <el-button
                size="small"
                type="primary"
                @click="updateNote"
                :disabled="!isFormValid"
              >
                {{ $t(`${translationPrefix}.post`) }}
              </el-button>
            </div>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { authorityMixin } from "@/util";
import { NoteItem, NoteRequest } from "@/api";
import { ElementForm, InputRequired } from "@/util/validation";
import { isConstraintViolation, isProblem } from "@/resources/problem";
import NoteInfo from "@/views/components/NoteInfo.vue";
import ButtonOkCancelDialog from "@/views/components/ButtonOkCancelDialog.vue";

export default defineComponent({
  name: "Note",
  components: { ButtonOkCancelDialog, NoteInfo },
  mixins: [authorityMixin],
  props: {
    data: {
      type: Object as PropType<NoteItem>,
      required: true,
    },
    hideMethod: {
      type: Function,
      required: true,
    },
    deleteNoteMethod: {
      type: Function,
      required: true,
    },
    updateNoteMethod: {
      type: Function,
      required: true,
    },
    editDisabled: {
      type: Function,
      required: false,
      default: () => true,
    },
    deleteDisabled: {
      type: Function,
      required: false,
      default: () => true,
    },
    translationPrefix: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    editable: false,
    editForm: {} as NoteRequest,
    isFormValid: true,
    rules: {
      note: [new InputRequired("validation.inputRequired")],
    },
    note: {} as NoteItem,
  }),
  mounted() {
    this.note = this.data;
  },
  methods: {
    edit() {
      Object.assign(this.editForm, { id: this.note.id, note: this.note.note });
      this.editable = true;
    },
    onValidate(prop: string, isVal: boolean) {
      this.isFormValid = isVal;
    },
    async updateNote() {
      const form = this.$refs["editForm"] as ElementForm;
      if (await form.validate()) {
        try {
          this.note = await this.updateNoteMethod(this.note.id, this.editForm);
          this.$emit("update");
        } catch (e) {
          if (isConstraintViolation(e)) {
            e.violations.forEach((violation) =>
              this.$notify.error(this.$t(violation.message))
            );
          } else if (isProblem(e)) {
            this.$notify.error(this.$t(e.detail));
          }
        }
        this.editForm = {} as NoteRequest;
        this.editable = false;
      }
    },
    cancel() {
      Object.assign(this.editForm, { id: this.note.id, note: this.note.note });
      this.editable = false;
    },
    async deleteNote() {
      try {
        await this.deleteNoteMethod(this.note.id);
        await this.hideMethod(this.note.id);
      } catch (e) {
        if (isConstraintViolation(e)) {
          e.violations.forEach((violation) =>
            this.$notify.error(this.$t(violation.message))
          );
        } else if (isProblem(e)) {
          this.$notify.error(this.$t(e.detail));
        }
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.note-bottom {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
}

.note-bottom-info {
  display: inline-block;
  flex-grow: 1;
  font-size: small;
}

.note {
  margin: 0 0 1em;
  white-space: pre-wrap;
}

.comment-body {
  overflow: hidden;
}

.comment .text {
  padding: 10px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  background: #fff;
}
.comment .text p:last-child {
  margin: 0;
}

.comment .attribution {
  margin: 0.5em 0 0;
  font-size: 14px;
  color: #666;
}

.comments:before,
.comment:before,
.comment .text:before {
  content: "";
  position: absolute;
  top: 0;
  left: 65px;
}

.comment .text:before {
  top: 18px;
  left: 78px;
  width: 9px;
  height: 9px;
  border-width: 0 0 1px 1px;
  border-style: solid;
  border-color: #e5e5e5;
  background: #fff;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}
</style>
