
import { defineComponent } from "vue";
import { CreateUserForm, Country } from "@/api";
import {
  ElementForm,
  emailFormat,
  InputLength,
  InputRequired,
  nameFormat,
} from "@/util/validation";
import { roleApi, userApi } from "@/resources";
import Headline from "@/components/Headline.vue";
import MaskedPhoneNumber from "@/components/MaskedPhoneNumber/MaskedPhoneNumber.vue";
import { fixLocalDate } from "@/util";

export default defineComponent({
  name: "NewUser",
  components: { MaskedPhoneNumber, Headline },
  data() {
    return {
      residenceCountryOptions: Country,
      user: {} as CreateUserForm,
      roles: [] as string[],
      rules: {
        givenName: [new InputRequired(), new InputLength(100), nameFormat],
        lastName: [new InputRequired(), new InputLength(100), nameFormat],
        birthDate: [new InputRequired()],
        residenceCountry: [new InputRequired()],
        phone: [new InputRequired("validation.phoneFormatRequired")],
        email: [new InputRequired(), new InputLength(100), emailFormat],
        password: [new InputRequired()],
        role: [new InputRequired("validation.selectRequired")],
      },
    };
  },
  async mounted() {
    this.roles = await roleApi.roleOptions();
  },
  methods: {
    submit() {
      const form = this.$refs["userForm"] as ElementForm;
      form.validate((valid) => {
        if (valid) {
          this.createUser();
        }
        return false;
      });
    },
    async createUser() {
      try {
        this.user.birthDate = fixLocalDate(this.user.birthDate);
        const id = await userApi.createUser({
          createUserForm: this.user,
        });
        this.$notify.info(this.$t("user.userCreated"));
        await this.$router.push({ name: "user", params: { id: id } });
      } catch (error) {
        this.$notify.error(this.$t(error.detail));
      }
    },
  },
});
