/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateRoleForm,
    CreateRoleFormFromJSON,
    CreateRoleFormToJSON,
    PageRoleListItem,
    PageRoleListItemFromJSON,
    PageRoleListItemToJSON,
    Role,
    RoleFromJSON,
    RoleToJSON,
    RoleCreated,
    RoleCreatedFromJSON,
    RoleCreatedToJSON,
    RoleHistory,
    RoleHistoryFromJSON,
    RoleHistoryToJSON,
    RoleOption,
    RoleOptionFromJSON,
    RoleOptionToJSON,
    UpdateRoleForm,
    UpdateRoleFormFromJSON,
    UpdateRoleFormToJSON,
} from '../models';

export interface CreateRoleRequest {
    createRoleForm: CreateRoleForm;
}

export interface GetRoleHistoryRequest {
    id: number;
}

export interface RoleRequest {
    id: number;
}

export interface RolesRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface UpdateRoleRequest {
    id: number;
    updateRoleForm: UpdateRoleForm;
}

/**
 * 
 */
export class RoleControllerApi extends runtime.BaseAPI {

    /**
     */
    async createRoleRaw(requestParameters: CreateRoleRequest): Promise<runtime.ApiResponse<RoleCreated>> {
        if (requestParameters.createRoleForm === null || requestParameters.createRoleForm === undefined) {
            throw new runtime.RequiredError('createRoleForm','Required parameter requestParameters.createRoleForm was null or undefined when calling createRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/roles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateRoleFormToJSON(requestParameters.createRoleForm),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleCreatedFromJSON(jsonValue));
    }

    /**
     */
    async createRole(requestParameters: CreateRoleRequest): Promise<RoleCreated> {
        const response = await this.createRoleRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getRoleHistoryRaw(requestParameters: GetRoleHistoryRequest): Promise<runtime.ApiResponse<Array<RoleHistory>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getRoleHistory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/roles/{id}/history`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RoleHistoryFromJSON));
    }

    /**
     */
    async getRoleHistory(requestParameters: GetRoleHistoryRequest): Promise<Array<RoleHistory>> {
        const response = await this.getRoleHistoryRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async roleRaw(requestParameters: RoleRequest): Promise<runtime.ApiResponse<Role>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling role.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/roles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleFromJSON(jsonValue));
    }

    /**
     */
    async role(requestParameters: RoleRequest): Promise<Role> {
        const response = await this.roleRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async roleOptionsRaw(): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/roles/options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async roleOptions(): Promise<Array<string>> {
        const response = await this.roleOptionsRaw();
        return await response.value();
    }

    /**
     */
    async roleOptionsForFilterRaw(): Promise<runtime.ApiResponse<Array<RoleOption>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/roles/options/filter`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RoleOptionFromJSON));
    }

    /**
     */
    async roleOptionsForFilter(): Promise<Array<RoleOption>> {
        const response = await this.roleOptionsForFilterRaw();
        return await response.value();
    }

    /**
     */
    async rolesRaw(requestParameters: RolesRequest): Promise<runtime.ApiResponse<PageRoleListItem>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/roles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageRoleListItemFromJSON(jsonValue));
    }

    /**
     */
    async roles(requestParameters: RolesRequest): Promise<PageRoleListItem> {
        const response = await this.rolesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateRoleRaw(requestParameters: UpdateRoleRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateRole.');
        }

        if (requestParameters.updateRoleForm === null || requestParameters.updateRoleForm === undefined) {
            throw new runtime.RequiredError('updateRoleForm','Required parameter requestParameters.updateRoleForm was null or undefined when calling updateRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/roles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateRoleFormToJSON(requestParameters.updateRoleForm),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateRole(requestParameters: UpdateRoleRequest): Promise<void> {
        await this.updateRoleRaw(requestParameters);
    }

}
