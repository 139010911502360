
import { defineComponent } from "vue";
import { ElementForm, InputLength } from "@/util/validation";
import { AccreditationStatus } from "@/api";
import { Filter } from "@/views/accreditation/AccreditationList.vue";

export default defineComponent({
  emits: ["update:accreditationFilter", "submit"],
  props: ["accreditationFilter"],
  data() {
    return {
      filter: {} as Filter,
      statuses: [] as AccreditationStatus[],
      isFormValid: true,
      rules: {
        investorName: [new InputLength(100)],
      },
    };
  },
  async mounted() {
    this.statuses = Object.values(AccreditationStatus);
    this.filter = this.accreditationFilter;
  },
  methods: {
    submit() {
      const form = this.$refs["filter-form"] as ElementForm;
      form.validate(async (valid) => {
        if (valid) {
          this.$emit("submit");
        }
      });
    },
    onValidate(prop: string, isVal: boolean) {
      this.isFormValid = isVal;
    },
    resetFilter() {
      this.filter = {} as Filter;
      this.$emit("update:accreditationFilter", this.filter);
      this.$emit("submit");
    },
  },
});
