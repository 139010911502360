
import { defineComponent, inject } from "vue";
import { authorityMixin } from "@/util";
import {
  InvestorFeeSettingsData,
  InvestorFeeSettingsHistoryItem,
  InvestorFeeSettingsItem,
} from "@/api";
import {
  ElementForm,
  InputRequired,
  numberFormat,
  NumberRange,
} from "@/util/validation";
import { settingsApi } from "@/resources";
import { isConstraintViolation, isProblem } from "@/resources/problem";
import { HistoryFormat } from "@/util/enum/HistoryFormat";
import HistoryPopover from "@/components/HistoryPopover.vue";

export default defineComponent({
  name: "SettingsFeeInvestor",
  mixins: [authorityMixin],
  components: { HistoryPopover },
  data() {
    return {
      investorFee: inject("investorFee") as InvestorFeeSettingsItem,
      investorFeeHistory: inject(
        "investorFeeHistory"
      ) as InvestorFeeSettingsHistoryItem,
      historyFormat: HistoryFormat,
      form: {} as InvestorFeeSettingsData,
      rules: {
        accreditationAngel: [
          new InputRequired("validation.inputRequired"),
          numberFormat,
          new NumberRange(0.0, 1000.0),
        ],
        accreditationSophisticated: [
          new InputRequired("validation.inputRequired"),
          numberFormat,
          new NumberRange(0.0, 1000.0),
        ],
        administration: [
          new InputRequired("validation.inputRequired"),
          numberFormat,
          new NumberRange(0.0, 1000.0),
        ],
        kyc: [
          new InputRequired("validation.inputRequired"),
          numberFormat,
          new NumberRange(0.0, 1000.0),
        ],
        reKyc: [
          new InputRequired("validation.inputRequired"),
          numberFormat,
          new NumberRange(0.0, 1000.0),
        ],
        kyb: [
          new InputRequired("validation.inputRequired"),
          numberFormat,
          new NumberRange(0.0, 1000.0),
        ],
        reKyb: [
          new InputRequired("validation.inputRequired"),
          numberFormat,
          new NumberRange(0.0, 1000.0),
        ],
      },
      isFormValid: true,
      editable: false,
    };
  },
  watch: {
    investorFee() {
      Object.assign(this.form, this.investorFee);
    },
  },
  mounted() {
    Object.assign(this.form, this.investorFee);
  },
  methods: {
    cancel() {
      Object.assign(this.form, this.investorFee);
      this.editable = false;
    },
    onValidate(prop: string, isVal: boolean) {
      this.isFormValid = isVal;
    },
    edit() {
      this.editable = true;
      Object.assign(this.form, this.investorFee);
    },
    async submit() {
      const form = this.$refs["form"] as ElementForm;
      if (await form.validate()) {
        try {
          await settingsApi.investorFeeSettings({
            investorFeeSettingsData: this.form,
          });
          Object.assign(this.investorFee, this.form);
          this.editable = false;
          this.$emit("update");
        } catch (error) {
          if (isConstraintViolation(error)) {
            error.violations.forEach((violation) =>
              this.$notify.error(this.$t(violation.message))
            );
          } else if (isProblem(error)) {
            this.$notify.error(this.$t(error.detail));
          }
        }
      }
    },
  },
});
