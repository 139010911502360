<template>
  <div class="row mb-3">
    <div class="col mb-3">
      <h3>{{ $t("address.permanentAddress") }}</h3>
    </div>
    <div class="row ms-0 mb-3">
      <div class="col color-light-text">
        {{ $t("address.line1") }}
      </div>
      <div class="col">
        {{ permanentAddress.line1 ?? "-" }}
      </div>
    </div>
    <div class="row ms-0 mb-3">
      <div class="col color-light-text">
        {{ $t("address.line1") }}
      </div>
      <div class="col">
        {{ permanentAddress.line2 ?? "-" }}
      </div>
    </div>
    <div class="row ms-0 mb-3">
      <div class="col color-light-text">
        {{ $t("address.postalCode") }}
      </div>
      <div class="col">
        {{ permanentAddress.postalCode ?? "-" }}
      </div>
    </div>
    <div class="row ms-0 mb-3">
      <div class="col color-light-text">
        {{ $t("address.country") }}
      </div>
      <div class="col">
        {{ $enums.globals.countryIso(permanentAddress.country) }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { PermanentAddress } from "@/api";

export default defineComponent({
  name: "PermanentAddress",
  props: {
    permanentAddress: {
      type: Object as PropType<PermanentAddress>,
      required: true,
    },
  },
});
</script>
