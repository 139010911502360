<template>
  <el-button v-if="label" @click="dialogVisible = true" v-bind="$attrs">
    {{ label }}
  </el-button>
  <el-button v-else @click="dialogVisible = true" v-bind="$attrs" />

  <teleport to="body">
    <el-dialog :title="title" v-model="dialogVisible" :before-close="cancel">
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancel">{{ $t("cancel") }}</el-button>
          <el-button type="primary" @click="ok">{{ $t("ok") }}</el-button>
        </span>
      </template>
    </el-dialog>
  </teleport>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { isConstraintViolation, isProblem } from "@/resources/problem";

export default defineComponent({
  name: "ButtonOkCancelDialog",
  props: {
    label: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: false,
    },
    dialogDisabled: {
      type: Boolean,
      default: false,
    },
    onOk: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    cancel() {
      this.dialogVisible = false;
    },
    async ok() {
      try {
        await this.onOk();
        if (this.message) {
          this.$notify.info(this.message);
        }
      } catch (e) {
        if (isConstraintViolation(e)) {
          e.violations.forEach((violation) =>
            this.$notify.error(this.$t(violation.message))
          );
        } else if (isProblem(e)) {
          this.$notify.error(this.$t(e.detail));
        }
      }
      this.$emit("update");
      this.cancel();
    },
  },
});
</script>
