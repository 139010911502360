<template>
  <div class="container-filters mt-4 mb-3">
    <el-form
      @validate="onValidate"
      :model="filter"
      :rules="rules"
      @change="$emit('update:investorFilter', filter)"
    >
      <div class="row">
        <div class="col-4">
          <el-form-item :label="$t('investor.filter.name')" prop="name">
            <el-input v-model="filter.name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('investor.filter.type')">
            <el-select
              v-model="filter.types"
              class="w-100"
              multiple
              collapse-tags
            >
              <el-option
                v-for="(type, key) in types"
                :key="key"
                :label="$enums.investor.type(type)"
                :value="type"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="col-4">
          <el-form-item :label="$t('investor.filter.category')">
            <el-select
              v-model="filter.categories"
              class="w-100"
              multiple
              collapse-tags
            >
              <el-option
                v-for="(category, key) in categories"
                :key="key"
                :value="category"
                :label="$enums.investor.category(category)"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('investor.filter.country')">
            <el-select
              v-model="filter.countries"
              class="w-100"
              multiple
              collapse-tags
            >
              <el-option
                v-for="(country, key) in countries"
                :key="key"
                :label="$enums.globals.countryIso(country)"
                :value="country"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="col-4">
          <el-form-item :label="$t('investor.filter.email')" prop="email">
            <el-input v-model="filter.email"></el-input>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
  <div class="mb-5">
    <el-button type="primary" @click="$emit('submit')" :disabled="!isFormValid">
      {{ $t("apply") }}
    </el-button>
    <el-button type="default" @click="resetFilter">
      {{ $t("filter.reset") }}
    </el-button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { InvestorCategory, InvestorType, Country } from "@/api";
import { InputLength } from "@/util/validation";
import { Filter } from "@/views/investor/InvestorList.vue";

export default defineComponent({
  emits: ["update:investorFilter", "submit"],
  props: ["investorFilter"],
  data() {
    return {
      countries: [] as Country[],
      types: [] as InvestorType[],
      categories: [] as InvestorCategory[],
      isFormValid: true,
      filter: {} as Filter,
      rules: {
        name: [new InputLength(100)],
        email: [new InputLength(255)],
      },
    };
  },
  async mounted() {
    this.filter = this.investorFilter;
    this.types = Object.values(InvestorType);
    this.categories = Object.values(InvestorCategory);
    this.countries = Object.values(Country);
  },
  methods: {
    onValidate(prop: string, isVal: boolean) {
      this.isFormValid = isVal;
    },
    resetFilter() {
      this.filter = {} as Filter;
      this.$emit("update:investorFilter", this.filter);
      this.$emit("submit");
    },
  },
});
</script>
