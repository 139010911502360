<template>
  <div class="container ms-0 mb-0" v-if="finance">
    <LegalFinance v-model:finance="finance" />
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { LegalFinanceItem } from "@/api";
import LegalFinance from "@/views/components/LegalFinance.vue";

export default defineComponent({
  name: "BusinessFinanceInfo",
  components: { LegalFinance },
  data() {
    return {
      finance: inject("finance") as LegalFinanceItem,
    };
  },
});
</script>
