
import { defineComponent } from "vue";
import { NoteItem, NoteRequest, PageNoteItem } from "@/api";
import { authorityMixin, PageRequest } from "@/util";
import { mapState } from "vuex";
import { ElementForm, InputRequired } from "@/util/validation";
import { isConstraintViolation, isProblem } from "@/resources/problem";
import Note from "@/views/components/Note.vue";

export default defineComponent({
  name: "Notes",
  components: { Note },
  mixins: [authorityMixin],
  props: {
    loadNotes: {
      type: Function,
      required: true,
    },
    createNoteDisabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    createNote: {
      type: Function,
      required: false,
    },
    deleteNote: {
      type: Function,
      required: false,
      default: () => null,
    },
    updateNote: {
      type: Function,
      required: false,
      default: () => null,
    },
    editDisabled: {
      type: Function,
      required: false,
      default: () => true,
    },
    deleteDisabled: {
      type: Function,
      required: false,
      default: () => true,
    },
    translationPrefix: {
      type: String,
      required: false,
      default: "note",
    },
  },
  data() {
    return {
      notes: [] as NoteItem[],
      pageRequest: {} as PageRequest,
      lastNotePage: {} as PageNoteItem,
      form: {} as NoteRequest,
      isFormValid: true,
      loading: false,
      rules: {
        note: [new InputRequired("validation.inputRequired")],
      },
    };
  },
  async mounted() {
    await this.fetchNotesInitially();
  },
  methods: {
    initials(name: string): string {
      return name
        ? name
            .split(" ")
            .map((el) => el[0])
            .join("")
        : "";
    },
    async loadMore() {
      if (!this.lastNotePage.last && !this.loading) {
        this.loading = true;
        this.pageRequest.page = this.pageRequest.page + 1;
        await this.fetchNotes();
        this.loading = false;
      }
    },
    async fetchNotes() {
      this.lastNotePage = await this.loadNotes(this.pageRequest);
      if (this.lastNotePage.content) {
        this.notes.push(...this.lastNotePage.content);
      }
    },
    async fetchNotesInitially() {
      this.loading = true;
      this.pageRequest.page = 0;
      this.lastNotePage = await this.loadNotes(this.pageRequest);
      this.notes = this.lastNotePage.content ? this.lastNotePage.content : [];
      this.loading = false;
    },
    onValidate(prop: string, isVal: boolean) {
      this.isFormValid = isVal;
    },
    async postNote() {
      const form = this.$refs["form"] as ElementForm;
      if ((await form.validate()) && this.createNote) {
        try {
          await this.createNote(this.form);
          await this.fetchNotesInitially();
        } catch (e) {
          if (isConstraintViolation(e)) {
            e.violations.forEach((violation) =>
              this.$notify.error(this.$t(violation.message))
            );
          } else if (isProblem(e)) {
            this.$notify.error(this.$t(e.detail));
          }
        }
        this.form = {} as NoteRequest;
      }
    },
    hideNote(noteId: number) {
      this.notes = this.notes.filter((noteItem) => noteItem.id !== noteId);
    },
  },
  computed: {
    ...mapState("account", ["profile"]),
    noMoreNotes(): boolean {
      return this.lastNotePage.last === undefined || this.lastNotePage.last;
    },
  },
});
