/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CampaignStatus {
    DRAFT = 'DRAFT',
    WAITING_FOR_REGISTRATION_FEE = 'WAITING_FOR_REGISTRATION_FEE',
    ACTIVE = 'ACTIVE',
    CANCELLATION_REQUEST = 'CANCELLATION_REQUEST',
    CANCELED = 'CANCELED',
    BUSINESS_VERIFICATION = 'BUSINESS_VERIFICATION',
    CAMPAIGN_VERIFICATION = 'CAMPAIGN_VERIFICATION',
    CAMPAIGN_COLLATERAL_REVIEW = 'CAMPAIGN_COLLATERAL_REVIEW',
    WAITING_FOR_DATA = 'WAITING_FOR_DATA',
    DECLINED = 'DECLINED',
    ABANDONED = 'ABANDONED',
    APPROVED = 'APPROVED',
    SIGNING_CAMPAIGN_AGREEMENT = 'SIGNING_CAMPAIGN_AGREEMENT',
    FUNDRAISING_RESERVE = 'FUNDRAISING_RESERVE',
    FUNDRAISING_LIVE = 'FUNDRAISING_LIVE',
    CAMPAIGN_RECONCILIATION = 'CAMPAIGN_RECONCILIATION',
    CAMPAIGN_FAIL = 'CAMPAIGN_FAIL',
    TEMPORARILY_SUSPENDED = 'TEMPORARILY_SUSPENDED',
    UNSUCCESSFUL = 'UNSUCCESSFUL',
    SUCCESSFUL = 'SUCCESSFUL',
    SPV_SET_UP = 'SPV_SET_UP',
    READY_FOR_DISBURSEMENT = 'READY_FOR_DISBURSEMENT',
    DISBURSEMENT_IN_PROGRESS = 'DISBURSEMENT_IN_PROGRESS',
    DISBURSEMENT_ERROR = 'DISBURSEMENT_ERROR',
    DISBURSEMENT_SUCCESS = 'DISBURSEMENT_SUCCESS'
}

export function CampaignStatusFromJSON(json: any): CampaignStatus {
    return CampaignStatusFromJSONTyped(json, false);
}

export function CampaignStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): CampaignStatus {
    return json as CampaignStatus;
}

export function CampaignStatusToJSON(value?: CampaignStatus | null): any {
    return value as any;
}

