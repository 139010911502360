<template>
  <Headline
    >{{ $t("sidebar.batches") }}
    <template v-slot:actions v-if="hasAuthority(authorities.TRANSACTION_BATCH)">
      <el-dropdown trigger="click">
        <el-button type="default">
          {{ $t("actions") }}<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <template #dropdown>
          <el-dropdown-item>
            <OkCancelDialog
              :dialog-disabled="false"
              :header="$t('batch.b2b.header')"
              :text="$t('batch.b2b.text')"
              :onOk="generateBatchB2B"
              @update="fetchData"
            />
          </el-dropdown-item>
        </template>
      </el-dropdown>
    </template>
  </Headline>
  <el-table
    :data="batchPage.content"
    @sort-change="onSortChange($event, pageRequest)"
  >
    <el-table-column :label="$t('id')" prop="id" sortable="custom" width="85">
    </el-table-column>
    <el-table-column :label="$t('createdAt')" width="180">
      <template #default="scope">
        {{ $dateTimeSeconds(scope.row.createdAt) }}
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('createdBy')"
      :show-overflow-tooltip="true"
      width="180"
    >
      <template #default="scope">
        <router-link
          v-if="hasAuthority(authorities.USER_READ)"
          :to="{ name: 'user', params: { id: scope.row.createdBy.id } }"
          >{{ scope.row.createdBy.name }}
        </router-link>
        <span v-else>{{ scope.row.createdBy.name }}</span>
      </template>
    </el-table-column>
    <el-table-column :label="$t('batch.file')">
      <template #default="scope">
        <el-link
          v-if="scope.row.fileName"
          type="primary"
          @click.prevent="
            () => downloadBatchFile(scope.row.id, scope.row.fileName)
          "
          >{{ scope.row.fileName }}
        </el-link>
        <span v-else>-</span>
      </template>
    </el-table-column>
    <el-table-column :label="$t('status')" width="220" align="center">
      <template #default="scope">
        <Status
          size="small"
          :color="$enums.batch.statusColor(scope.row.status)"
        >
          {{ $enums.batch.status(scope.row.status) }}
        </Status>
      </template>
    </el-table-column>
    <el-table-column :label="$t('batch.error')" :show-overflow-tooltip="true">
      <template #default="scope">
        {{ scope.row.error }}
      </template>
    </el-table-column>
  </el-table>

  <TableFooter
    @size-change="onSizeChange($event, pageRequest)"
    @current-change="onCurrentChange($event, pageRequest)"
    :page="batchPage"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { batchApi } from "@/resources";
import Headline from "@/components/Headline.vue";
import { PageBackOfficeBatchListItem, TransactionType } from "@/api";
import { authorityMixin, PageRequest, pageSortMixin } from "@/util";
import TableFooter from "@/components/TableFooter.vue";
import Status from "@/components/Status.vue";
import OkCancelDialog from "@/views/components/OkCancelDialog.vue";

export default defineComponent({
  name: "BatchList",
  components: { Status, Headline, TableFooter, OkCancelDialog },
  mixins: [pageSortMixin, authorityMixin],
  data: () => ({
    batchPage: {} as PageBackOfficeBatchListItem,
    pageRequest: {} as PageRequest,
    types: TransactionType,
  }),
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.batchPage = await batchApi.batches({
        ...this.pageRequest,
      });
    },
    async generateBatchB2B() {
      return await batchApi.generateBatchB2B();
    },
    async downloadBatchFile(id: number, name: string) {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(await batchApi.batchFile({ id: id }));
      link.download = name;
      link.click();
    },
  },
});
</script>
