<template>
  <div class="container-filters mt-4 mb-3">
    <el-form
      @validate="onValidate"
      :model="filter"
      :rules="rules"
      ref="filter-form"
      @change="$emit('update:preScreeningFilter', filter)"
    >
      <div class="row">
        <div class="col-4">
          <el-form-item
            :label="$t('preScreening.companyName')"
            prop="companyName"
          >
            <el-input v-model="filter.companyName"></el-input>
          </el-form-item>
          <el-form-item :label="$t('preScreening.companyRegistrationCountry')">
            <el-select
              v-model="filter.companyRegistrationCountry"
              class="w-100"
              single
              collapse-tags
            >
              <el-option
                v-for="(country, key) in countries"
                :key="key"
                :label="$enums.globals.countryIso(country)"
                :value="country"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('preScreening.filter.duplicateStatus')">
            <el-select
              v-model="filter.duplicateStatus"
              class="w-100"
              single
              collapse-tags
            >
              <el-option
                v-for="(status, key) in duplicateStatuses"
                :key="key"
                :label="$enums.preScreening.duplicateStatus(status)"
                :value="status"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>

        <div class="col-4">
          <el-form-item
            :label="$t('preScreening.contactName')"
            prop="contactName"
          >
            <el-input v-model="filter.contactName"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('preScreening.contactEmail')"
            prop="contactEmail"
          >
            <el-input v-model="filter.contactEmail"></el-input>
          </el-form-item>
        </div>

        <div class="col-4">
          <el-form-item :label="$t('createdAt')">
            <div class="d-flex gap-2">
              <el-date-picker
                type="date"
                v-model="filter.createdAtFrom"
                format="DD/MM/YYYY"
                :placeholder="$t('filter.from')"
              ></el-date-picker>
              <el-date-picker
                type="date"
                v-model="filter.createdAtTo"
                format="DD/MM/YYYY"
                :placeholder="$t('filter.to')"
              ></el-date-picker>
            </div>
          </el-form-item>
          <el-form-item :label="$t('filter.status')">
            <el-select
              v-model="filter.status"
              class="w-100"
              single
              collapse-tags
            >
              <el-option
                v-for="(status, key) in statuses"
                :key="key"
                :label="$enums.preScreening.status(status)"
                :value="status"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
  <div class="mb-5">
    <el-button :disabled="!isFormValid" type="primary" @click="submit">
      {{ $t("apply") }}
    </el-button>
    <el-button type="default" @click="resetFilter">
      {{ $t("filter.reset") }}
    </el-button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ElementForm, InputLength } from "@/util/validation";
import { Filter } from "@/views/pre_screening/PreScreeningList.vue";
import {
  Country,
  PreScreeningDuplicateStatus,
  PreScreeningStatus,
} from "@/api";

export default defineComponent({
  emits: ["update:preScreeningFilter", "submit"],
  props: ["preScreeningFilter"],
  data() {
    return {
      filter: {} as Filter,
      statuses: [] as PreScreeningStatus[],
      duplicateStatuses: [] as PreScreeningDuplicateStatus[],
      countries: [] as Country[],
      isFormValid: true,
      rules: {
        companyName: [new InputLength(70)],
        contactEmail: [new InputLength(255)],
        contactName: [new InputLength(100)],
      },
    };
  },
  async mounted() {
    this.countries = Object.values(Country);
    this.statuses = Object.values(PreScreeningStatus);
    this.duplicateStatuses = Object.values(PreScreeningDuplicateStatus);
    this.filter = this.preScreeningFilter;
  },
  methods: {
    submit() {
      const form = this.$refs["filter-form"] as ElementForm;
      form.validate(async (valid) => {
        if (valid) {
          this.$emit("submit");
        }
      });
    },
    onValidate(prop: string, isVal: boolean) {
      this.isFormValid = isVal;
    },
    resetFilter() {
      this.filter = {} as Filter;
      this.$emit("update:preScreeningFilter", this.filter);
      this.$emit("submit");
    },
  },
});
</script>
