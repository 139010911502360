<template>
  <div class="card">
    <div class="card-main">
      <BackLink />
      <Headline>
        {{ role.name }}
      </Headline>
    </div>
  </div>
  <div class="tab-content">
    <div class="tab-pane active">
      <router-view @update="fetchData"></router-view>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { roleApi } from "@/resources";
import { Role, RoleHistory } from "@/api";
import Headline from "@/components/Headline.vue";
import BackLink from "@/components/BackLink.vue";

export default defineComponent({
  name: "RoleView",
  components: { Headline, BackLink },
  props: ["id"],
  provide() {
    return {
      role: computed(() => this.role),
      history: computed(() => this.history),
    };
  },
  data() {
    return {
      role: {} as Role,
      history: [] as RoleHistory[],
    };
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchRole() {
      this.role = await roleApi.role({ id: this.id });
    },
    async fetchHistory() {
      this.history = await roleApi.getRoleHistory({ id: this.id });
    },
    async fetchData() {
      await Promise.all([this.fetchRole(), this.fetchHistory()]);
    },
  },
});
</script>
