<template>
  <div class="container-auth">
    <el-form
      ref="form"
      :model="registrationForm"
      :rules="rules"
      :hide-required-asterisk="true"
      status-icon
      class="error-message-static"
    >
      <h3 class="my-4 text-center">{{ $t("auth.createPassword") }}</h3>
      <el-form-item :label="$t('auth.passwordTitle')" prop="password">
        <el-input
          v-model="registrationForm.password"
          show-password
          class="el-input__password"
          maxlength="32"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('auth.confirmPassword')" prop="passwordConfirm">
        <el-input
          v-model="registrationForm.passwordConfirm"
          show-password
          class="el-input__password"
          maxlength="32"
        ></el-input>
      </el-form-item>
      <span class="invalid-feedback mb-2 d-block" v-if="errorMessage">
        {{ errorMessage }}
      </span>
      <PasswordPolicy />
      <el-form-item class="center">
        <el-button class="btn btn-primary" type="primary" @click="onSave">
          {{ $t("create") }}</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { isProblem } from "@/resources/problem";
import { authApi } from "@/resources";
import { ElementForm, InputRequired, passwordFormat } from "@/util/validation";
import PasswordPolicy from "@/components/PasswordPolicy.vue";

interface RegistrationForm {
  password: string;
  passwordConfirm: string;
}

export default defineComponent({
  components: { PasswordPolicy },
  data() {
    return {
      registrationForm: {} as RegistrationForm,
      errorMessage: "" as string,
      rules: {
        password: [new InputRequired(), passwordFormat],
        passwordConfirm: [new InputRequired()],
      },
    };
  },
  methods: {
    onSave() {
      this.errorMessage = "";
      const form = this.$refs["form"] as ElementForm;
      form.validate((valid) => {
        if (!valid) {
          return false;
        }
        if (
          this.registrationForm.password ===
          this.registrationForm.passwordConfirm
        ) {
          this.confirmRegistration();
        } else {
          this.errorMessage = this.$t("auth.passwordDontMatch");
        }
        return true;
      });
    },
    async confirmRegistration() {
      try {
        await authApi.confirmRegistration({
          code: this.$route.query.code as string,
          registrationForm: this.registrationForm,
        });
        await this.$router.push({ path: "/", force: true });
      } catch (e) {
        if (isProblem(e)) {
          this.errorMessage = e.detail;
        }
      }
    },
  },
});
</script>

<style scoped>
.container-auth {
  width: 686px;
}
.center {
  text-align: center;
}
</style>
