/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InvestorFeeSettingsItem
 */
export interface InvestorFeeSettingsItem {
    /**
     * 
     * @type {number}
     * @memberof InvestorFeeSettingsItem
     */
    accreditationAngel: number;
    /**
     * 
     * @type {number}
     * @memberof InvestorFeeSettingsItem
     */
    accreditationSophisticated: number;
    /**
     * 
     * @type {number}
     * @memberof InvestorFeeSettingsItem
     */
    administration: number;
    /**
     * 
     * @type {number}
     * @memberof InvestorFeeSettingsItem
     */
    kyb: number;
    /**
     * 
     * @type {number}
     * @memberof InvestorFeeSettingsItem
     */
    kyc: number;
    /**
     * 
     * @type {number}
     * @memberof InvestorFeeSettingsItem
     */
    reKyb: number;
    /**
     * 
     * @type {number}
     * @memberof InvestorFeeSettingsItem
     */
    reKyc: number;
}

export function InvestorFeeSettingsItemFromJSON(json: any): InvestorFeeSettingsItem {
    return InvestorFeeSettingsItemFromJSONTyped(json, false);
}

export function InvestorFeeSettingsItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestorFeeSettingsItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accreditationAngel': json['accreditationAngel'],
        'accreditationSophisticated': json['accreditationSophisticated'],
        'administration': json['administration'],
        'kyb': json['kyb'],
        'kyc': json['kyc'],
        'reKyb': json['reKyb'],
        'reKyc': json['reKyc'],
    };
}

export function InvestorFeeSettingsItemToJSON(value?: InvestorFeeSettingsItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accreditationAngel': value.accreditationAngel,
        'accreditationSophisticated': value.accreditationSophisticated,
        'administration': value.administration,
        'kyb': value.kyb,
        'kyc': value.kyc,
        'reKyb': value.reKyb,
        'reKyc': value.reKyc,
    };
}


