
import { defineComponent, inject } from "vue";
import { authorityMixin } from "@/util";
import {
  CommonFeeSettingsData,
  CommonFeeSettingsHistoryItem,
  CommonFeeSettingsItem,
} from "@/api";
import {
  ElementForm,
  InputRequired,
  numberFormat,
  NumberRange,
} from "@/util/validation";
import { settingsApi } from "@/resources";
import { isConstraintViolation, isProblem } from "@/resources/problem";
import HistoryPopover from "@/components/HistoryPopover.vue";
import { HistoryFormat } from "@/util/enum/HistoryFormat";

export default defineComponent({
  name: "SettingsFeeCommon",
  mixins: [authorityMixin],
  components: { HistoryPopover },
  data() {
    return {
      commonFee: inject("commonFee") as CommonFeeSettingsItem,
      commonFeeHistory: inject(
        "commonFeeHistory"
      ) as CommonFeeSettingsHistoryItem,
      historyFormat: HistoryFormat,
      form: {} as CommonFeeSettingsData,
      rules: {
        payment: [
          new InputRequired("validation.inputRequired"),
          numberFormat,
          new NumberRange(0.0, 10.0),
        ],
      },
      isFormValid: true,
      editable: false,
    };
  },
  watch: {
    commonFee() {
      Object.assign(this.form, this.commonFee);
    },
  },
  mounted() {
    Object.assign(this.form, this.commonFee);
  },
  methods: {
    cancel() {
      Object.assign(this.form, this.commonFee);
      this.editable = false;
    },
    onValidate(prop: string, isVal: boolean) {
      this.isFormValid = isVal;
    },
    edit() {
      this.editable = true;
      Object.assign(this.form, this.commonFee);
    },
    async submit() {
      const form = this.$refs["form"] as ElementForm;
      if (await form.validate()) {
        try {
          await settingsApi.commonFeeSettings({
            commonFeeSettingsData: this.form,
          });
          Object.assign(this.commonFee, this.form);
          this.editable = false;
          this.$emit("update");
        } catch (error) {
          if (isConstraintViolation(error)) {
            error.violations.forEach((violation) =>
              this.$notify.error(this.$t(violation.message))
            );
          } else if (isProblem(error)) {
            this.$notify.error(this.$t(error.detail));
          }
        }
      }
    },
  },
});
