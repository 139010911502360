<template>
  <div class="container ms-0 mb-0" v-if="accreditation.id">
    <div class="row mb-3">
      <div class="col color-light-text">
        {{ $t("accreditation.previousCategory") }}
      </div>
      <div class="col wrap-text">
        {{ $enums.investor.category(accreditation.previousCategory) }}
      </div>
      <div class="col color-light-text">
        {{ $t("createdAt") }}
      </div>
      <div class="col wrap-text">
        {{ $date(accreditation.createdAt) }}
      </div>
    </div>
    <div class="row mb-3">
      <div class="col color-light-text">
        {{ $t("accreditation.category") }}
      </div>
      <div class="col">
        {{ $enums.investor.category(accreditation.category) }}
      </div>
      <div class="col color-light-text">
        {{ $t("accreditation.approvedAt") }}
      </div>
      <div class="col">
        {{ $date(accreditation.approvedAt) }}
      </div>
    </div>
    <div class="row mb-3">
      <div class="col color-light-text">
        {{ $t("accreditation.subCategory") }}
      </div>
      <div class="col wrap-text">
        {{
          $enums.investor.subCategory(
            accreditation.subCategory,
            accreditation.category,
            accreditation.investorType
          )
        }}
      </div>
      <div class="col color-light-text">
        {{ $t("accreditation.validTill") }}
      </div>
      <div class="col">
        {{ $date(accreditation.validTill) }}
      </div>
    </div>
    <div class="row mb-3">
      <div class="col color-light-text">
        {{ $t("status") }}
      </div>
      <div class="col wrap-text">
        {{ $enums.accreditation.status(accreditation.status) }}
        <HistoryPopover
          :history="accreditationHistory.status"
          :field-name="$t('status')"
          :value-type="historyFormat.CUSTOM"
          :custom-format="$enums.accreditation.status"
        />
      </div>
      <div class="col color-light-text"></div>
      <div class="col"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { AccreditationItem, AccreditationItemHistory } from "@/api";
import HistoryPopover from "@/components/HistoryPopover.vue";
import { HistoryFormat } from "@/util/enum/HistoryFormat";

export default defineComponent({
  name: "AccreditationGeneralInfo",
  components: { HistoryPopover },
  data() {
    return {
      accreditation: inject("accreditation") as AccreditationItem,
      accreditationHistory: inject(
        "accreditationHistory"
      ) as AccreditationItemHistory,
      historyFormat: HistoryFormat,
    };
  },
});
</script>
