import { Features } from "@/api";
import { Module } from "vuex";
import { State } from "@/store/index";
import { featureApi } from "@/resources";

export const feature: Module<Features, State> = {
  namespaced: true,
  state: {
    qaEnabled: false,
  },
  mutations: {
    setFeatures(state: Features, features: Features) {
      Object.assign(state, features);
    },
  },
  actions: {
    async loadFeatures({ commit }) {
      const features = await featureApi.features();
      commit("setFeatures", features);
    },
  },
};
