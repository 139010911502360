/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum Country {
    BAHRAIN = 'BAHRAIN',
    KUWAIT = 'KUWAIT',
    OMAN = 'OMAN',
    QATAR = 'QATAR',
    SAUDI_ARABIA = 'SAUDI_ARABIA',
    UNITED_ARAB_EMIRATES = 'UNITED_ARAB_EMIRATES',
    OTHER = 'OTHER'
}

export function CountryFromJSON(json: any): Country {
    return CountryFromJSONTyped(json, false);
}

export function CountryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Country {
    return json as Country;
}

export function CountryToJSON(value?: Country | null): any {
    return value as any;
}

