<template>
  <div class="container ms-0 mb-0">
    <Attachments
      :attachments="attachments"
      :load-attachment="loadAttachment"
      :upload-disabled="!hasAuthority(authorities.CAMPAIGN_WRITE)"
      :upload-attachment="uploadAttachment"
      :delete-enabled="deleteEnabled"
      :delete-method="deleteMethod"
      @update="fetchAttachments"
    ></Attachments>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import {
  ActorType,
  AttachmentListItem,
  CampaignAttachmentType,
  CampaignItem,
} from "@/api";
import { Authority, authorityMixin } from "@/util";
import { campaignApi } from "@/resources";
import Attachments from "@/views/components/Attachments.vue";
import { mapState } from "vuex";
import { AccountState } from "@/store/account";
import { Campaign } from "@/util/enum/campaign";
import { isConstraintViolation, isProblem } from "@/resources/problem";

export default defineComponent({
  name: "CampaignAttachments",
  mixins: [authorityMixin],
  components: { Attachments },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      attachments: [] as AttachmentListItem[],
      campaign: inject("campaign") as CampaignItem,
    };
  },
  async mounted() {
    await this.fetchAttachments();
  },
  computed: {
    ...mapState("account", {
      userId(state: AccountState) {
        return state.profile?.id;
      },
    }),
  },
  methods: {
    async fetchAttachments() {
      this.attachments = await campaignApi.campaignAttachments({
        id: Number(this.id),
        type: CampaignAttachmentType.ATTACHMENT,
      });
    },
    async loadAttachment(attachmentId: number): Promise<Blob> {
      return await campaignApi.campaignAttachment({
        id: Number(this.id),
        attachmentId: attachmentId,
      });
    },
    async uploadAttachment(file: File) {
      return await campaignApi.uploadCampaignAttachment({
        id: Number(this.id),
        file: file,
        type: CampaignAttachmentType.ATTACHMENT,
      });
    },
    deleteEnabled(attachment: AttachmentListItem): boolean {
      return (
        this.userId == attachment.actorId &&
        ActorType.BO_USER === attachment.actorType &&
        this.hasAuthority(Authority.CAMPAIGN_WRITE) &&
        this.campaign.status &&
        !Campaign.TERMINAL_STATUSES.includes(this.campaign.status)
      );
    },
    async deleteMethod(id: number) {
      try {
        await campaignApi.deleteCampaignAttachment({
          id: Number(this.id),
          attachmentId: id,
        });
        this.attachments = this.attachments.filter(
          (attachment) => attachment.id != id
        );
      } catch (e) {
        if (isConstraintViolation(e)) {
          e.violations.forEach((violation) =>
            this.$notify.error(this.$t(violation.message))
          );
        } else if (isProblem(e)) {
          this.$notify.error(this.$t(e.detail));
        }
      }
    },
  },
});
</script>
