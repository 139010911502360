/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CampaignType {
    CROWDFUNDING = 'CROWDFUNDING'
}

export function CampaignTypeFromJSON(json: any): CampaignType {
    return CampaignTypeFromJSONTyped(json, false);
}

export function CampaignTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CampaignType {
    return json as CampaignType;
}

export function CampaignTypeToJSON(value?: CampaignType | null): any {
    return value as any;
}

