import { RoleActor, UserActor } from "@/api";

export class Actor {
  static user(actor: UserActor): string {
    switch (actor) {
      case UserActor.SYSTEM:
        return "System";
      case UserActor.USER:
        return "User";
    }
  }

  static role(actor: RoleActor): string {
    switch (actor) {
      case RoleActor.SYSTEM:
        return "System";
      case RoleActor.USER:
        return "User";
    }
  }
}
