<template>
  <Headline>
    {{ $t("sidebar.campaigns") }}
    <template v-slot:actions>
      <el-button
        v-if="!filterVisible"
        class="filter"
        @click="filterVisible = true"
        >{{ $t("filter.show") }}
      </el-button>
      <el-button
        v-if="filterVisible"
        class="filter"
        @click="filterVisible = false"
        >{{ $t("filter.hide") }}
      </el-button>
    </template>
  </Headline>
  <CampaignFilter
    v-if="filterVisible"
    v-model:campaign-filter="filter"
    @submit="fetchData"
  />
  <el-table
    :data="campaignPage.content"
    @cell-click="onCellClick"
    :row-style="{ cursor: 'pointer' }"
    @sort-change="onSortChange($event, pageRequest)"
    cell-class-name="campaign"
  >
    <el-table-column :label="$t('id')" prop="id" sortable="custom" width="60">
      <template #default="scope">
        {{ scope.row.id }}
      </template>
    </el-table-column>
    <el-table-column :label="$t('campaign.type.title')" min-width="105">
      <template #default="scope">
        {{ $enums.campaign.type(scope.row.type) }}
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('campaign.business')"
      :show-overflow-tooltip="true"
    >
      <template #default="scope">
        <router-link
          v-if="hasAuthority(authorities.BUSINESS_READ)"
          :to="{ name: 'business', params: { id: scope.row.business.id } }"
          >{{ scope.row.business.name }}
        </router-link>
        <span v-else>{{ scope.row.business.name }}</span>
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('campaign.approvedDuration')"
      :show-overflow-tooltip="true"
    >
      <template #default="scope">
        {{ scope.row.approvedData.daysDuration ?? "-" }}
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('campaign.approvedAmount')"
      :show-overflow-tooltip="true"
    >
      <template #default="scope">
        {{ $curr(scope.row.approvedData.amount) }}
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('campaign.summary.committedAmount')"
      :min-width="85"
      :show-overflow-tooltip="true"
    >
      <template #default="scope">
        {{ $curr(scope.row.committedData.amount) }}
      </template>
    </el-table-column>
    <el-table-column
      min-width="105"
      :label="$t('campaign.summary.committedPercent')"
    >
      <template #default="scope">
        {{ scope.row.committedData.percent }}
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('campaign.minimumInvestment')"
      :show-overflow-tooltip="true"
    >
      <template #default="scope">
        {{ $curr(scope.row.approvedData.minimumInvestment) }}
      </template>
    </el-table-column>
    <el-table-column :label="$t('createdAt')" min-width="100">
      <template #default="scope">
        {{ $date(scope.row.createdAt) }}
      </template>
    </el-table-column>
    <el-table-column :label="$t('campaign.campaignStartDate')" min-width="100">
      <template #default="scope">
        {{ $date(scope.row.campaignStartedAt) }}
      </template>
    </el-table-column>
    <el-table-column :label="$t('campaign.campaignEndDate')" min-width="100">
      <template #default="scope">
        {{ $date(scope.row.campaignFinishedAt) }}
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('status')"
      min-width="220"
      align="center"
      class-name="status"
    >
      <template #default="scope">
        <Status
          size="small"
          :color="$enums.campaign.statusColor(scope.row.status)"
        >
          {{ $enums.campaign.status(scope.row.status) }}
        </Status>
      </template>
    </el-table-column>
  </el-table>

  <TableFooter
    @size-change="onSizeChange($event, pageRequest)"
    @current-change="onCurrentChange($event, pageRequest)"
    :page="campaignPage"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { campaignApi } from "@/resources";
import Status from "@/components/Status.vue";
import {
  CampaignListItem,
  CampaignStatus,
  CampaignType,
  PageCampaignListItem,
} from "@/api";
import TableFooter from "@/components/TableFooter.vue";
import {
  authorityMixin,
  fixLocalDate,
  PageRequest,
  pageSortMixin,
} from "@/util";
import Headline from "@/components/Headline.vue";
import CampaignFilter from "@/views/campaign/CampaignFilter.vue";

export interface Filter {
  business: string | undefined;
  createdDateFrom: Date | undefined;
  createdDateTo: Date | undefined;
  campaignStartedFrom: Date | undefined;
  campaignStartedTo: Date | undefined;
  campaignFinishedFrom: Date | undefined;
  campaignFinishedTo: Date | undefined;
  approvedAmountFrom: number | undefined;
  approvedAmountTo: number | undefined;
  commitmentPercentFrom: number | undefined;
  commitmentPercentTo: number | undefined;
  minimumInvestmentFrom: number | undefined;
  minimumInvestmentTo: number | undefined;
  statuses: CampaignStatus[];
  types: CampaignType[];
}

export default defineComponent({
  name: "CampaignList",
  mixins: [pageSortMixin, authorityMixin],
  components: {
    CampaignFilter,
    Headline,
    Status,
    TableFooter,
  },
  data: () => ({
    campaignPage: {} as PageCampaignListItem,
    pageRequest: {} as PageRequest,
    filterVisible: false,
    filter: {} as Filter,
  }),
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.filter.campaignStartedFrom = this.filter.campaignStartedFrom
        ? fixLocalDate(this.filter.campaignStartedFrom)
        : undefined;
      this.filter.campaignStartedTo = this.filter.campaignStartedTo
        ? fixLocalDate(this.filter.campaignStartedTo)
        : undefined;
      this.filter.createdDateFrom = this.filter.createdDateFrom
        ? fixLocalDate(this.filter.createdDateFrom)
        : undefined;
      this.filter.createdDateTo = this.filter.createdDateTo
        ? fixLocalDate(this.filter.createdDateTo)
        : undefined;
      this.filter.campaignFinishedFrom = this.filter.campaignFinishedFrom
        ? fixLocalDate(this.filter.campaignFinishedFrom)
        : undefined;
      this.filter.campaignFinishedTo = this.filter.campaignFinishedTo
        ? fixLocalDate(this.filter.campaignFinishedTo)
        : undefined;

      this.campaignPage = await campaignApi.campaigns({
        ...this.pageRequest,
        ...this.filter,
      });
    },
    onCellClick(row: CampaignListItem) {
      this.$router.push({
        name: "campaign",
        params: { id: row.id },
      });
    },
  },
});
</script>
<style lang="scss">
.el-table {
  .campaign {
    .cell {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .status {
    .cell {
      overflow: visible;
    }
  }
}
</style>
