<template>
  <div class="container-auth">
    <el-form
      ref="form"
      :model="passwordRecoveryForm"
      :rules="rules"
      status-icon
      :hide-required-asterisk="true"
      class="error-message-static"
    >
      <h3 class="my-4 text-center">{{ $t("auth.createNewPassword") }}</h3>
      <el-form-item label="New password" prop="password">
        <el-input
          v-model="passwordRecoveryForm.password"
          show-password
          class="el-input__password"
          maxlength="32"
        ></el-input>
      </el-form-item>
      <el-form-item label="Confirm password" prop="passwordConfirm">
        <el-input
          v-model="passwordRecoveryForm.passwordConfirm"
          show-password
          class="el-input__password"
          maxlength="32"
        ></el-input>
      </el-form-item>
      <span class="invalid-feedback mb-2 d-block" v-if="errorMessage">
        {{ errorMessage }}
      </span>
      <PasswordPolicy />
      <el-form-item class="center">
        <el-button class="btn btn-primary" type="primary" @click="onSubmit">{{
          $t("create")
        }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { isConstraintViolation, isProblem } from "@/resources/problem";
import { authApi } from "@/resources";
import { ElementForm, InputRequired, passwordFormat } from "@/util/validation";
import PasswordPolicy from "@/components/PasswordPolicy.vue";

interface PasswordRecoveryForm {
  password: string;
  passwordConfirm: string;
}

export default defineComponent({
  components: { PasswordPolicy },
  data() {
    return {
      passwordRecoveryForm: {} as PasswordRecoveryForm,
      errorMessage: "" as string,
      rules: {
        password: [new InputRequired(), passwordFormat],
        passwordConfirm: [new InputRequired()],
      },
    };
  },
  methods: {
    onSubmit() {
      this.errorMessage = "";
      const form = this.$refs["form"] as ElementForm;
      form.validate((valid) => {
        if (!valid) {
          return false;
        }
        if (
          this.passwordRecoveryForm.password ===
          this.passwordRecoveryForm.passwordConfirm
        ) {
          this.setPassword();
        } else {
          this.errorMessage = "The entered passwords don't match";
        }
        return true;
      });
    },
    async setPassword() {
      try {
        await authApi.confirmPasswordRecovery({
          code: this.$route.query.code as string,
          passwordRecoveryForm: this.passwordRecoveryForm,
        });
        this.$notify.info("Your password has been successfully changed");
        await this.$router.push({ path: "/", force: true });
      } catch (e) {
        if (isConstraintViolation(e)) {
          e.violations.forEach(
            (violation) => (this.errorMessage = violation.message)
          );
        } else if (isProblem(e)) {
          this.errorMessage = e.detail;
        }
      }
    },
  },
});
</script>

<style scoped>
.container-auth {
  width: 686px;
}
.center {
  text-align: center;
}
</style>
