
import { defineComponent, inject } from "vue";
import { authorityMixin } from "@/util";
import { PortalUser } from "@/api";

export default defineComponent({
  name: "PortalUserDetails",
  mixins: [authorityMixin],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      user: inject("user") as PortalUser,
    };
  },
});
