import { RouteRecordRaw } from "vue-router";
import BusinessList from "@/views/business/BusinessList.vue";
import BusinessView from "@/views/business/BusinessView.vue";
import CampaignList from "@/views/campaign/CampaignList.vue";
import CampaignView from "@/views/campaign/CampaignView.vue";
import CampaignDetails from "@/views/campaign/tabs/CampaignDetails.vue";
import UserList from "@/views/user/backoffice/UserList.vue";
import NewUser from "@/views/user/backoffice/NewUser.vue";
import UserView from "@/views/user/backoffice/UserView.vue";
import UserDetails from "@/views/user/backoffice/tabs/UserDetails.vue";
import RoleList from "@/views/role/RoleList.vue";
import NewRole from "@/views/role/NewRole.vue";
import RoleView from "@/views/role/RoleView.vue";
import RoleDetails from "@/views/role/tabs/RoleDetails.vue";
import Profile from "@/views/profile/Profile.vue";
import NotFound from "@/components/NotFound.vue";
import PasswordChange from "@/views/profile/PasswordChange.vue";
import SuccessSign from "@/components/SuccessSign.vue";
import CampaignBusiness from "@/views/campaign/tabs/CampaignBusiness.vue";
import CampaignAttachments from "@/views/campaign/tabs/CampaignAttachments.vue";
import InvestorList from "@/views/investor/InvestorList.vue";
import InvestorView from "@/views/investor/InvestorView.vue";
import InvestorContactDetails from "@/views/investor/tabs/InvestorContactDetails.vue";
import InvestorGeneralInfo from "@/views/investor/tabs/InvestorGeneralInfo.vue";
import InvestorOccupationIncome from "@/views/investor/tabs/InvestorOccupationIncome.vue";
import DealList from "@/views/deal/DealList.vue";
import DealView from "@/views/deal/DealView.vue";
import DealDetails from "@/views/deal/tabs/DealDetails.vue";
import DealAttachments from "@/views/deal/tabs/DealAttachments.vue";
import InvestorBankDetails from "@/views/investor/tabs/InvestorBankDetails.vue";
import InvestorAttachments from "@/views/investor/tabs/InvestorAttachments.vue";
import BusinessGeneralInfo from "@/views/business/tabs/BusinessGeneralInfo.vue";
import BusinessContactInfo from "@/views/business/tabs/BusinessContactInfo.vue";
import BusinessFinanceInfo from "@/views/business/tabs/BusinessFinanceInfo.vue";
import BusinessCommerceInfo from "@/views/business/tabs/BusinessCommerceInfo.vue";
import BusinessOwnershipInfo from "@/views/business/tabs/BusinessOwnershipInfo.vue";
import BusinessAttachments from "@/views/business/tabs/BusinessAttachments.vue";
import BusinessBankInfo from "@/views/business/tabs/BusinessBankInfo.vue";
import InvestorFinancialInfo from "@/views/investor/tabs/InvestorFinancialInfo.vue";
import InvestorOwnershipInfo from "@/views/investor/tabs/InvestorOwnershipInfo.vue";
import InvestmentList from "@/views/investment/InvestmentList.vue";
import InvestmentView from "@/views/investment/InvestmentView.vue";
import InvestmentDetails from "@/views/investment/tabs/InvestmentDetails.vue";
import InvestmentAttachments from "@/views/investment/tabs/InvestmentAttachments.vue";
import InvestmentInvestor from "@/views/investment/tabs/InvestmentInvestor.vue";
import TransactionList from "@/views/transaction/TransactionList.vue";
import CampaignSummary from "@/views/campaign/tabs/CampaignSummary.vue";
import PreScreeningView from "@/views/pre_screening/PreScreeningView.vue";
import PreScreeningDetails from "@/views/pre_screening/tabs/PreScreeningDetails.vue";
import PreScreeningGeneralInfo from "@/views/pre_screening/tabs/PreScreeningGeneralInfo.vue";
import PreScreeningList from "@/views/pre_screening/PreScreeningList.vue";
import PreScreeningAttachments from "@/views/pre_screening/tabs/PreScreeningAttachments.vue";
import PreScreeningReviewNotes from "@/views/pre_screening/tabs/PreScreeningReviewNotes.vue";
import PreScreeningFollowUpNotes from "@/views/pre_screening/tabs/PreScreeningFollowUpNotes.vue";
import BusinessPreScreening from "@/views/campaign/tabs/CampaignPreScreening.vue";
import SettingsView from "@/views/settings/SettingsView.vue";
import SettingsFee from "@/views/settings/tabs/SettingsFee.vue";
import SettingsFeeCommon from "@/views/settings/tabs/SettingsFeeCommon.vue";
import SettingsFeeInvestor from "@/views/settings/tabs/SettingsFeeInvestor.vue";
import CampaignFee from "@/views/campaign/tabs/CampaignFee.vue";
import InvestmentDocuments from "@/views/investment/tabs/InvestmentDocuments.vue";
import CampaignDocuments from "@/views/campaign/tabs/CampaignDocuments.vue";
import PortalUserDetails from "@/views/user/portal/tabs/PortalUserDetails.vue";
import PortalUserView from "@/views/user/portal/PortalUserView.vue";
import PortalUserList from "@/views/user/portal/PortalUserList.vue";
import AccreditationView from "@/views/accreditation/AccreditationView.vue";
import AccreditationInvestor from "@/views/accreditation/tabs/AccreditationInvestor.vue";
import AccreditationGeneralInfo from "@/views/accreditation/tabs/AccreditationGeneralInfo.vue";
import AccreditationAttachments from "@/views/accreditation/tabs/AccreditationAttachments.vue";
import AccreditationList from "@/views/accreditation/AccreditationList.vue";
import CampaignReviewNotes from "@/views/campaign/tabs/CampaignReviewNotes.vue";
import CampaignGeneralInfo from "@/views/campaign/tabs/CampaignGeneralInfo.vue";
import InvestmentCampaignReview from "@/views/investment/tabs/InvestmentCampaignReview.vue";
import CampaignContent from "@/views/campaign/tabs/CampaignContent.vue";
import SettingsInvestorLimit from "@/views/settings/tabs/SettingsInvestorLimit.vue";
import SettingsLegalInvestorLimit from "@/views/settings/tabs/SettingsLegalInvestorLimit.vue";
import SettingsIndividualInvestorLimit from "@/views/settings/tabs/SettingsIndividualInvestorLimit.vue";
import CampaignCollateralReview from "@/views/campaign/tabs/CampaignCollateralReview.vue";
import CampaignContentFiles from "@/views/campaign/tabs/content/CampaignContentFiles.vue";
import BatchList from "@/views/transaction/batch/BatchList.vue";
import InvestmentInvestorVerification from "@/views/investment/tabs/InvestmentInvestorVerification.vue";
import CampaignFundraiserVerification from "@/views/campaign/tabs/CampaignFundraiserVerification.vue";
import CampaignCampaignVerification from "@/views/campaign/tabs/CampaignCampaignVerification.vue";
import SettingsAccountDetails from "@/views/settings/tabs/SettingsAccountDetails.vue";
import SettingsExchangeRate from "@/views/settings/tabs/SettingsExchangeRate.vue";

export const routes: RouteRecordRaw[] = [
  {
    path: "/successSign",
    name: "SuccessSign",
    component: SuccessSign,
  },
  {
    path: "/businesses",
    name: "businesses",
    component: BusinessList,
  },
  {
    path: "/businesses/:id",
    name: "business",
    component: BusinessView,
    props: true,
    redirect: { name: "businessGeneralInfo" },
    children: [
      {
        name: "businessGeneralInfo",
        path: "general",
        component: BusinessGeneralInfo,
      },
      {
        name: "businessContactInfo",
        path: "contact",
        component: BusinessContactInfo,
      },
      {
        name: "businessFinanceInfo",
        path: "finance",
        component: BusinessFinanceInfo,
      },
      {
        name: "businessCommerceInfo",
        path: "commerce",
        component: BusinessCommerceInfo,
      },
      {
        name: "businessOwnershipInfo",
        path: "ownership",
        component: BusinessOwnershipInfo,
      },
      {
        name: "businessBankInfo",
        path: "bank",
        component: BusinessBankInfo,
      },
      {
        name: "businessAttachments",
        path: "attachments",
        component: BusinessAttachments,
      },
    ],
  },
  {
    path: "/campaigns",
    name: "campaigns",
    component: CampaignList,
  },
  {
    path: "/campaigns/:id",
    name: "campaign",
    component: CampaignView,
    props: true,
    redirect: { name: "campaignGeneralInfo" },
    children: [
      {
        name: "campaignGeneralInfo",
        path: "general",
        component: CampaignGeneralInfo,
        props: true,
      },
      {
        name: "campaignDetails",
        path: "details",
        component: CampaignDetails,
        props: true,
      },
      {
        name: "campaignFee",
        path: "fee",
        component: CampaignFee,
        props: true,
      },
      {
        name: "campaignSummary",
        path: "summary",
        component: CampaignSummary,
        props: true,
      },
      {
        name: "campaignBusiness",
        path: "business/:businessId",
        component: CampaignBusiness,
        props: true,
        children: [
          {
            name: "campaignBusinessGeneralInfo",
            path: "general",
            component: BusinessGeneralInfo,
          },
          {
            name: "campaignBusinessContactInfo",
            path: "contact",
            component: BusinessContactInfo,
          },
          {
            name: "campaignBusinessFinanceInfo",
            path: "finance",
            component: BusinessFinanceInfo,
          },
          {
            name: "campaignBusinessCommerceInfo",
            path: "commerce",
            component: BusinessCommerceInfo,
          },
          {
            name: "campaignBusinessOwnershipInfo",
            path: "ownership",
            component: BusinessOwnershipInfo,
            props: true,
          },
          {
            name: "campaignBusinessBankInfo",
            path: "bank",
            component: BusinessBankInfo,
            props: true,
          },
          {
            name: "campaignBusinessAttachments",
            path: "attachments",
            component: BusinessAttachments,
            props: true,
          },
        ],
      },
      {
        name: "campaignPreScreening",
        path: "pre_screening/:preScreeningId",
        component: BusinessPreScreening,
        redirect: { name: "campaignPreScreeningGeneralInfo" },
        props: true,
        children: [
          {
            name: "campaignPreScreeningDetails",
            path: "details",
            component: PreScreeningDetails,
            props: true,
          },
          {
            name: "campaignPreScreeningGeneralInfo",
            path: "general",
            component: PreScreeningGeneralInfo,
            props: true,
          },
          {
            name: "campaignPreScreeningAttachments",
            path: "attachments",
            component: PreScreeningAttachments,
            props: true,
          },
          {
            name: "campaignPreScreeningReviewNotes",
            path: "review",
            component: PreScreeningReviewNotes,
            props: true,
          },
          {
            name: "campaignPreScreeningFollowUpNotes",
            path: "follow_up",
            component: PreScreeningFollowUpNotes,
            props: true,
          },
        ],
      },
      {
        name: "campaignAttachments",
        path: "attachments",
        component: CampaignAttachments,
        props: true,
      },
      {
        name: "campaignDocuments",
        path: "documents",
        component: CampaignDocuments,
        props: true,
      },
      {
        name: "campaignReviewNotes",
        path: "review",
        component: CampaignReviewNotes,
        props: true,
      },
      {
        name: "campaignCollateralReview",
        path: "collateral",
        component: CampaignCollateralReview,
        props: true,
      },
      {
        name: "campaignFundraiserVerification",
        path: "verification",
        component: CampaignFundraiserVerification,
        props: true,
      },
      {
        name: "campaignCampaignVerification",
        path: "campaignVerification",
        component: CampaignCampaignVerification,
        props: true,
      },
      {
        name: "campaignContent",
        path: "content",
        component: CampaignContent,
        props: true,
      },
      {
        name: "campaignContentFiles",
        path: "content",
        component: CampaignContentFiles,
        props: true,
      },
    ],
  },
  {
    path: "/users",
    name: "users",
    component: UserList,
  },
  {
    path: "/users/new",
    name: "newUser",
    component: NewUser,
  },
  {
    path: "/users/:id",
    name: "user",
    component: UserView,
    props: true,
    redirect: { name: "userDetails" },
    children: [
      {
        name: "userDetails",
        path: "details",
        component: UserDetails,
        props: true,
      },
    ],
  },
  {
    path: "/portalUsers",
    name: "portalUsers",
    component: PortalUserList,
  },
  {
    path: "/portalUsers/:id",
    name: "portalUser",
    component: PortalUserView,
    props: true,
    redirect: { name: "portalUserDetails" },
    children: [
      {
        name: "portalUserDetails",
        path: "details",
        component: PortalUserDetails,
        props: true,
      },
    ],
  },
  {
    path: "/roles",
    name: "roles",
    component: RoleList,
  },
  {
    path: "/roles/new",
    name: "newRole",
    component: NewRole,
  },
  {
    path: "/roles/:id",
    name: "role",
    component: RoleView,
    props: true,
    redirect: { name: "roleDetails" },
    children: [
      {
        name: "roleDetails",
        path: "details",
        component: RoleDetails,
        props: true,
      },
    ],
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "notFound",
    component: NotFound,
  },
  {
    path: "/password/change",
    name: "changePassword",
    component: PasswordChange,
  },
  {
    path: "/deals",
    name: "deals",
    component: DealList,
  },
  {
    path: "/transactions",
    name: "transactions",
    component: TransactionList,
  },
  {
    path: "/batches",
    name: "batches",
    component: BatchList,
  },
  {
    path: "/deals/:id",
    name: "deal",
    component: DealView,
    props: true,
    redirect: { name: "dealDetails" },
    children: [
      {
        name: "dealDetails",
        path: "details",
        component: DealDetails,
        props: true,
      },
      {
        name: "dealAttachments",
        path: "attachments",
        component: DealAttachments,
        props: true,
      },
    ],
  },
  {
    path: "/investors",
    name: "investors",
    component: InvestorList,
  },
  {
    path: "/investors/:id",
    name: "investor",
    component: InvestorView,
    props: true,
    redirect: { name: "investorGeneralInfo" },
    children: [
      {
        name: "investorGeneralInfo",
        path: "generalInfo",
        component: InvestorGeneralInfo,
        props: true,
      },
      {
        name: "investorContactDetails",
        path: "contactDetails",
        component: InvestorContactDetails,
        props: true,
      },
      {
        name: "investorOccupationIncome",
        path: "occupation_income",
        component: InvestorOccupationIncome,
        props: true,
      },
      {
        name: "investorBankDetails",
        path: "bankDetails",
        component: InvestorBankDetails,
        props: true,
      },
      {
        name: "investorAttachments",
        path: "attachments",
        component: InvestorAttachments,
        props: true,
      },
      {
        name: "investorFinancialInfo",
        path: "finance",
        component: InvestorFinancialInfo,
        props: true,
      },
      {
        name: "investorOwnershipInfo",
        path: "ownership",
        component: InvestorOwnershipInfo,
        props: true,
      },
    ],
  },
  {
    path: "/investments",
    name: "investments",
    component: InvestmentList,
  },
  {
    path: "/investments/:id",
    name: "investment",
    component: InvestmentView,
    props: true,
    redirect: { name: "investmentDetails" },
    children: [
      {
        name: "investmentDetails",
        path: "details",
        component: InvestmentDetails,
        props: true,
      },
      {
        name: "investmentCampaignReview",
        path: "review",
        component: InvestmentCampaignReview,
        props: true,
      },
      {
        name: "investmentInvestor",
        path: "investor/:investorId",
        component: InvestmentInvestor,
        props: true,
        children: [
          {
            name: "investmentInvestorGeneralInfo",
            path: "general",
            component: InvestorGeneralInfo,
          },

          {
            name: "investmentInvestorContactDetails",
            path: "contactDetails",
            component: InvestorContactDetails,
            props: true,
          },
          {
            name: "investmentInvestorOccupationIncome",
            path: "occupation_income",
            component: InvestorOccupationIncome,
            props: true,
          },
          {
            name: "investmentInvestorBankDetails",
            path: "bankDetails",
            component: InvestorBankDetails,
            props: true,
          },
          {
            name: "investmentInvestorAttachments",
            path: "attachments",
            component: InvestorAttachments,
            props: true,
          },
          {
            name: "investmentInvestorFinancialInfo",
            path: "finance",
            component: InvestorFinancialInfo,
            props: true,
          },
          {
            name: "investmentInvestorOwnershipInfo",
            path: "ownership",
            component: InvestorOwnershipInfo,
            props: true,
          },
        ],
      },
      {
        name: "investmentAttachments",
        path: "attachments",
        component: InvestmentAttachments,
        props: true,
      },
      {
        name: "investmentDocuments",
        path: "documents",
        component: InvestmentDocuments,
        props: true,
      },

      {
        name: "investorVerification",
        path: "investorVerification",
        component: InvestmentInvestorVerification,
        props: true,
      },
    ],
  },
  {
    path: "/pre_screenings",
    name: "preScreenings",
    component: PreScreeningList,
  },
  {
    path: "/pre_screenings/:id",
    name: "preScreening",
    component: PreScreeningView,
    props: true,
    redirect: { name: "preScreeningGeneralInfo" },
    children: [
      {
        name: "preScreeningDetails",
        path: "details",
        component: PreScreeningDetails,
        props: true,
      },
      {
        name: "preScreeningGeneralInfo",
        path: "general",
        component: PreScreeningGeneralInfo,
        props: true,
      },
      {
        name: "preScreeningAttachments",
        path: "attachments",
        component: PreScreeningAttachments,
        props: true,
      },
      {
        name: "preScreeningReviewNotes",
        path: "review",
        component: PreScreeningReviewNotes,
        props: true,
      },
      {
        name: "preScreeningFollowUpNotes",
        path: "follow_up",
        component: PreScreeningFollowUpNotes,
        props: true,
      },
    ],
  },
  {
    path: "/accreditations",
    name: "accreditations",
    component: AccreditationList,
  },
  {
    path: "/accreditations/:id",
    name: "accreditation",
    component: AccreditationView,
    props: true,
    redirect: { name: "accreditationGeneralInfo" },
    children: [
      {
        name: "accreditationInvestor",
        path: "investor/:investorId",
        component: AccreditationInvestor,
        redirect: { name: "accreditationInvestorGeneralInfo" },
        props: true,
        children: [
          {
            name: "accreditationInvestorGeneralInfo",
            path: "general",
            component: InvestorGeneralInfo,
          },

          {
            name: "accreditationInvestorContactDetails",
            path: "contactDetails",
            component: InvestorContactDetails,
            props: true,
          },
          {
            name: "accreditationInvestorOccupationIncome",
            path: "occupation_income",
            component: InvestorOccupationIncome,
            props: true,
          },
          {
            name: "accreditationInvestorBankDetails",
            path: "bankDetails",
            component: InvestorBankDetails,
            props: true,
          },
          {
            name: "accreditationInvestorAttachments",
            path: "attachments",
            component: InvestorAttachments,
            props: true,
          },
          {
            name: "accreditationInvestorFinancialInfo",
            path: "finance",
            component: InvestorFinancialInfo,
            props: true,
          },
          {
            name: "accreditationInvestorOwnershipInfo",
            path: "ownership",
            component: InvestorOwnershipInfo,
            props: true,
          },
        ],
      },
      {
        name: "accreditationGeneralInfo",
        path: "general",
        component: AccreditationGeneralInfo,
        props: true,
      },
      {
        name: "accreditationAttachments",
        path: "attachments",
        component: AccreditationAttachments,
        props: true,
      },
    ],
  },
  {
    path: "/settings",
    name: "settings",
    component: SettingsView,
    redirect: { name: "settingsFee" },
    children: [
      {
        name: "settingsFee",
        path: "fee",
        redirect: { name: "settingsFeeCommon" },
        component: SettingsFee,
        children: [
          {
            path: "/common",
            name: "settingsFeeCommon",
            component: SettingsFeeCommon,
          },
          {
            path: "/investor",
            name: "settingsFeeInvestor",
            component: SettingsFeeInvestor,
          },
        ],
      },
      {
        name: "settingsInvestorLimit",
        path: "limit/investor",
        redirect: { name: "settingsInvestorLimitIndividual" },
        component: SettingsInvestorLimit,
        children: [
          {
            path: "/individual",
            name: "settingsInvestorLimitIndividual",
            component: SettingsIndividualInvestorLimit,
          },
          {
            path: "/legal",
            name: "settingsInvestorLimitLegal",
            component: SettingsLegalInvestorLimit,
          },
        ],
      },
      {
        name: "settingsAccountDetails",
        path: "/account/details",
        component: SettingsAccountDetails,
      },
      {
        name: "exchangeRate",
        path: "exchangeRate",
        component: SettingsExchangeRate,
      },
    ],
  },
];
