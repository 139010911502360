
import { defineComponent } from "vue";
import { TransactionStatus, TransactionType } from "@/api";
import { ElementForm, InputLength, numberFormat } from "@/util/validation";
import { Filter } from "@/views/deal/DealList.vue";

export default defineComponent({
  emits: ["update:transactionFilter", "submit"],
  props: ["transactionFilter"],
  data() {
    return {
      transactionType: [] as TransactionType[],
      transactionStatus: [] as TransactionStatus[],
      isFormValid: true,
      filter: {} as Filter,
      rules: {
        campaignId: [numberFormat, new InputLength(100)],
        investmentId: [numberFormat, new InputLength(100)],
        amountFrom: [numberFormat],
        amountTo: [numberFormat],
      },
    };
  },
  async mounted() {
    this.filter = this.transactionFilter;
    this.transactionType = Object.values(TransactionType);
    this.transactionStatus = Object.values(TransactionStatus);
  },
  methods: {
    submit() {
      const form = this.$refs["filter-form"] as ElementForm;
      form.validate(async (valid) => {
        if (valid) {
          this.$emit("submit");
        }
      });
    },
    onValidate(prop: string, isVal: boolean) {
      this.isFormValid = isVal;
    },
    resetFilter() {
      this.filter = {} as Filter;
      this.$emit("update:transactionFilter", this.filter);
      this.$emit("submit");
    },
  },
});
