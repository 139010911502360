/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum IdDocumentType {
    CARD_ID = 'CARD_ID',
    PASSPORT = 'PASSPORT'
}

export function IdDocumentTypeFromJSON(json: any): IdDocumentType {
    return IdDocumentTypeFromJSONTyped(json, false);
}

export function IdDocumentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdDocumentType {
    return json as IdDocumentType;
}

export function IdDocumentTypeToJSON(value?: IdDocumentType | null): any {
    return value as any;
}

