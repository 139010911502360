<template>
  <footer class="mt-auto border-top py-2">
    <div class="container-fluid">
      <div class="row">
        <div class="d-flex offset-2 col-10 gap-2">
          <span class="nav-item flex-grow-1">Wadiaa © 2022</span>
          <span class="nav-item"
            >UI Version: <strong>{{ uiVersion }}</strong></span
          >
          <span class="nav-item"
            >Backend Version: <strong>{{ buildVersion }}</strong></span
          >
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "TheFooter",
  data: () => ({
    uiVersion: process.env.VUE_APP_VERSION,
    buildVersion: "0.0.1",
  }),
  mounted() {
    this.fetchBuildVersion();
  },
  methods: {
    async getInfo() {
      const response = await fetch(
        process.env.VUE_APP_BACKOFFICE_PATH + "/actuator/info"
      );
      return response.json();
    },
    async fetchBuildVersion() {
      const info = await this.getInfo();
      this.buildVersion = info.build.version;
    },
  },
});
</script>

<style scoped>
footer {
  font-size: 13px;
}
</style>
