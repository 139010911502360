<template>
  <div class="container-auth">
    <img src="@/assets/WGDBWideLogo.png" alt="logo" class="logo" />
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      status-icon
      class="error-message-static"
    >
      <el-form-item :label="$t('auth.email')" prop="login">
        <el-input v-model="form.login" maxlength="100"></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('auth.passwordTitle')"
        prop="password"
        class="mb-2"
      >
        <el-input
          v-model="form.password"
          show-password
          class="el-input__password"
          maxlength="32"
        ></el-input>
      </el-form-item>
      <span class="forgot-password" v-if="!isAuthenticated">
        <router-link class="mb-2" :to="{ name: 'forgotPassword' }">
          {{ $t("auth.password.forgot") }}
        </router-link>
      </span>
      <span
        id="errorMessage"
        class="invalid-feedback mb-2"
        v-if="errorMessage"
        >{{ $t(errorMessage) }}</span
      >
      <span id="badRole" v-if="showBadRoleMessage" v-html="$t('user.badRole')">
      </span>
      <el-form-item>
        <button
          :disabled="form.login === '' || form.password === ''"
          class="btn btn-primary el-button--lg w-100 mt-4"
          @click="onSubmit"
        >
          {{ $t("auth.login") }}
        </button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters, mapMutations } from "vuex";
import { ElementForm, InputRequired } from "@/util/validation";
import { LoginRequest } from "@/api";
import { isProblem } from "@/resources/problem";
import { authApi, setLastRequestTime } from "@/resources";

function initForm(): LoginRequest {
  return {
    login: "",
    password: "",
  };
}

export default defineComponent({
  name: "Login",
  data: () => ({
    form: initForm(),
    errorMessage: undefined as string | undefined,
    showBadRoleMessage: false,
    rules: {
      login: [new InputRequired()],
      password: [new InputRequired()],
    },
  }),
  methods: {
    ...mapMutations("account", ["setToken"]),
    onSubmit(event: Event) {
      event.preventDefault();
      const form = this.$refs["form"] as ElementForm;
      form.validate((valid) => {
        if (valid) {
          this.doLogin();
        }
        return valid;
      });
    },
    async doLogin() {
      try {
        this.errorMessage = undefined;
        this.showBadRoleMessage = false;
        const token = await authApi.login({
          loginRequest: this.form,
        });
        this.setToken(token);
        setLastRequestTime();
      } catch (e) {
        if (isProblem(e)) {
          switch (e.type) {
            case "changeExpiredPassword":
              await this.$router.push({ name: "changeExpiredPassword" });
              break;
            case "badRole":
              this.showBadRoleMessage = true;
              this.form = initForm();
              break;
            default:
              this.errorMessage = e.detail as string;
              this.form = initForm();
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters("account", ["isAuthenticated"]),
  },
});
</script>

<style scoped>
form {
  position: relative;
}

.logo {
  display: flex;
  align-items: center;
  width: 256px;
  margin: 0 0 0 70px;
}

.forgot-password {
  position: absolute;
  right: 0;
  top: 105px;
}

#errorMessage {
  display: block;
}
</style>
