<template>
  <div class="container ms-0 mb-0" v-if="commerce">
    <LegalCommerce v-model:commerce="commerce" />
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { LegalCommerceItem } from "@/api";
import LegalCommerce from "@/views/components/LegalCommerce.vue";

export default defineComponent({
  name: "BusinessCommerceInfo",
  components: { LegalCommerce },
  data() {
    return {
      commerce: inject("commerce") as LegalCommerceItem,
    };
  },
});
</script>
