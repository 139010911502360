
import { computed, defineComponent } from "vue";
import { authorityMixin } from "@/util";
import { investorApi } from "@/resources";
import { Investor } from "@/api";

export default defineComponent({
  name: "AccreditationInvestor",
  mixins: [authorityMixin],
  props: {
    investorId: {
      type: String,
      required: true,
    },
  },
  provide() {
    return {
      investor: computed(() => this.investor ?? {}),
      investorId: computed(() => this.investorId),
    };
  },
  data() {
    return {
      investor: {} as Investor,
    };
  },
  async mounted() {
    await Promise.all([this.fetchData()]);
  },
  methods: {
    async fetchData(id?: number) {
      await Promise.all([this.fetchInvestor(id ?? Number(this.investorId))]);
    },
    async fetchInvestor(id: number) {
      this.investor = await investorApi.investor({ id });
    },
  },
});
