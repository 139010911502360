
import { defineComponent } from "vue";
import { isConstraintViolation, isProblem } from "@/resources/problem";
import { ElementForm, InputRequired } from "@/util/validation";
import { fixLocalDate, fixLocalDates } from "@/util";
import { CampaignExtendDurationOperationRequest } from "@/api";
import { campaignOperationApi } from "@/resources";

export default defineComponent({
  name: "ExtendCampaignDurationDialog",
  emits: ["update"],
  props: {
    id: {
      type: Number,
      required: true,
    },
    dialogDisabled: {
      type: Boolean,
      required: false,
    },
    campaignFinishedAt: {
      type: Date,
      required: true,
    },
  },
  data() {
    return {
      dialogVisible: false,
      form: {} as CampaignExtendDurationOperationRequest,
      isFormValid: true,
      rules: {
        campaignFinishedAt: [new InputRequired()],
      },
    };
  },
  methods: {
    cancel() {
      this.dialogVisible = false;
      this.form = {} as CampaignExtendDurationOperationRequest;
    },
    onValidate(prop: string, isVal: boolean) {
      this.isFormValid = isVal;
    },
    async extendCampaignDuration() {
      const form = this.$refs["form"] as ElementForm;
      if (await form.validate()) {
        fixLocalDates(this.form);
        try {
          await campaignOperationApi.extendCampaignDuration({
            id: this.id,
            campaignExtendDurationOperationRequest: this.form,
          });
        } catch (e) {
          if (isConstraintViolation(e)) {
            e.violations.forEach((violation) =>
              this.$notify.error(this.$t(violation.message))
            );
          } else if (isProblem(e)) {
            this.$notify.error(this.$t(e.detail));
          }
        }
        this.$emit("update");
        this.cancel();
      }
    },
    invalidDate(date: Date) {
      const fixedDate = fixLocalDate(date);
      const minimumDate = fixLocalDate(
        this.campaignFinishedAt ?? new Date(Date.now())
      );
      return fixedDate.getTime() < minimumDate.getTime();
    },
  },
});
