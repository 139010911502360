
import { defineComponent, inject } from "vue";
import {
  Legal,
  PageLegalMemberItem,
  PageLegalOwnerItem,
  PageLegalPersonItem,
} from "@/api";
import { businessApi } from "@/resources";
import { PageRequest } from "@/util";
import LegalOwners from "@/views/components/LegalOwners.vue";
import LegalMembers from "@/views/components/LegalMembers.vue";
import LegalPersons from "@/views/components/LegalPersons.vue";

export default defineComponent({
  name: "BusinessOwnershipInfo",
  components: { LegalMembers, LegalOwners, LegalPersons },
  data() {
    return {
      businessId: inject("businessId") as number,
      legal: inject("legal") as Legal,
      ownersPage: {} as PageLegalOwnerItem,
      ownersPageRequest: {} as PageRequest,
      membersPage: {} as PageLegalMemberItem,
      membersPageRequest: {} as PageRequest,
      personsPage: {} as PageLegalPersonItem,
      personsPageRequest: {} as PageRequest,
    };
  },
  watch: {
    async legal() {
      await Promise.all([
        this.fetchOwners(),
        this.fetchMembers(),
        this.fetchPersons(),
      ]);
    },
  },
  methods: {
    async fetchPersons() {
      this.personsPage = await businessApi.persons({
        id: this.businessId,
        ...this.personsPageRequest,
      });
    },
    async fetchOwners() {
      this.ownersPage = await businessApi.owners({
        id: this.businessId,
        ...this.ownersPageRequest,
      });
    },
    async fetchMembers() {
      this.membersPage = await businessApi.members({
        id: this.businessId,
        ...this.membersPageRequest,
      });
    },
  },
});
