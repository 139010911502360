<template>
  <div class="card">
    <div class="card-main">
      <BackLink />
      <Headline v-if="user.person && user.person.givenName">
        {{ user.person.givenName }} {{ user.person.lastName }}
        <template v-slot:details>
          <Status :color="$enums.user.statusColor(user.status)" size="medium">
            {{ $enums.user.status(user.status) }}
          </Status>
        </template>
        <template #actions v-if="hasAuthority(authorities.USER_WRITE)">
          <el-dropdown trigger="click" v-if="user.status !== 'INACTIVE'">
            <el-button type="default">
              {{ $t("actions")
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <DeactivateUserDialog
                  v-if="user.status === 'ACTIVE'"
                  :id="id"
                  :given-name="user.person.givenName"
                  :last-name="user.person.lastName"
                  @update="fetchUser"
                />
                <ActivateUserDialog
                  v-if="user.status === 'LOCKED' || user.status === 'SUSPENDED'"
                  :id="id"
                  :given-name="user.person.givenName"
                  :last-name="user.person.lastName"
                  @update="fetchUser"
                />
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </Headline>
    </div>
  </div>

  <div class="tab-content">
    <div class="tab-pane active">
      <router-view @update="fetchData"></router-view>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { User, UserHistory } from "@/api";
import { userApi } from "@/resources";
import Headline from "@/components/Headline.vue";
import Status from "@/components/Status.vue";
import BackLink from "@/components/BackLink.vue";
import DeactivateUserDialog from "@/views/user/backoffice/dialog/DeactivateUserDialog.vue";
import ActivateUserDialog from "@/views/user/backoffice/dialog/ActivateUserDialog.vue";
import { authorityMixin } from "@/util";

export default defineComponent({
  name: "UserView",
  mixins: [authorityMixin],
  components: {
    ActivateUserDialog,
    DeactivateUserDialog,
    Headline,
    Status,
    BackLink,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  provide() {
    return {
      user: computed(() => this.user),
      history: computed(() => this.history),
    };
  },
  data() {
    return {
      user: { person: {} } as User,
      history: [] as UserHistory[],
    };
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchUser() {
      this.user = await userApi.user({ id: Number(this.id) });
    },
    async fetchHistory() {
      this.history = await userApi.getUserHistory({ id: Number(this.id) });
    },
    async fetchData() {
      await Promise.all([this.fetchUser(), this.fetchHistory()]);
    },
  },
});
</script>
