
import { defineComponent, inject } from "vue";
import { authorityMixin } from "@/util";
import {
  InvestorLegalLimitSettingsData,
  InvestorLegalLimitSettingsHistoryItem,
  InvestorLegalLimitSettingsItem,
} from "@/api";
import {
  ElementForm,
  InputRequired,
  numberFormat,
  NumberMin,
} from "@/util/validation";
import { settingsApi } from "@/resources";
import { isConstraintViolation, isProblem } from "@/resources/problem";
import HistoryPopover from "@/components/HistoryPopover.vue";
import { HistoryFormat } from "@/util/enum/HistoryFormat";

export default defineComponent({
  name: "SettingsLegalInvestorLimit.vue",
  mixins: [authorityMixin],
  components: { HistoryPopover },
  data() {
    return {
      investorLegalLimit: inject(
        "investorLegalLimit"
      ) as InvestorLegalLimitSettingsItem,
      investorLegalLimitHistory: inject(
        "investorLegalLimitHistory"
      ) as InvestorLegalLimitSettingsHistoryItem,
      historyFormat: HistoryFormat,
      form: {} as InvestorLegalLimitSettingsData,
      rules: {
        otherInstitutional: [
          new InputRequired("validation.inputRequired"),
          numberFormat,
          new NumberMin(0.0),
        ],
      },
      isFormValid: true,
      editable: false,
    };
  },
  watch: {
    investorLegalLimit() {
      Object.assign(this.form, this.investorLegalLimit);
    },
  },
  mounted() {
    Object.assign(this.form, this.investorLegalLimit);
  },
  methods: {
    cancel() {
      Object.assign(this.form, this.investorLegalLimit);
      this.editable = false;
    },
    onValidate(prop: string, isVal: boolean) {
      this.isFormValid = isVal;
    },
    edit() {
      this.editable = true;
      Object.assign(this.form, this.investorLegalLimit);
    },
    async submit() {
      const form = this.$refs["form"] as ElementForm;
      if (await form.validate()) {
        try {
          await settingsApi.legalInvestorLimitSettings({
            investorLegalLimitSettingsData: this.form,
          });
          Object.assign(this.investorLegalLimit, this.form);
          this.editable = false;
          this.$emit("update");
        } catch (error) {
          if (isConstraintViolation(error)) {
            error.violations.forEach((violation) =>
              this.$notify.error(this.$t(violation.message))
            );
          } else if (isProblem(error)) {
            this.$notify.error(this.$t(error.detail));
          }
        }
      }
    },
  },
});
