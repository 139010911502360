<template>
  <el-table
    :data="page.content"
    @sort-change="onSortChange($event, pageRequest)"
    class="owners"
  >
    <el-table-column :label="$t('id')" prop="id" sortable="custom" width="60">
    </el-table-column>
    <el-table-column
      :label="$t('legal.ownership.owners.name')"
      :show-overflow-tooltip="true"
      prop="name"
    ></el-table-column>
    <el-table-column
      :label="$t('legal.ownership.owners.address')"
      :show-overflow-tooltip="true"
      prop="address"
    >
    </el-table-column>
    <el-table-column
      :label="$t('legal.ownership.owners.residenceCountry')"
      :show-overflow-tooltip="true"
    >
      <template #default="scope">
        {{ $enums.globals.countryIso(scope.row.residence) }}
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('legal.ownership.owners.registrationNumber')"
      :show-overflow-tooltip="true"
    >
      <template #default="scope">
        {{ scope.row.companyRegistrationNumber ?? "-" }}
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('legal.ownership.owners.idCardNumber')"
      :show-overflow-tooltip="true"
    >
      <template #default="scope">
        {{ scope.row.idType === idType.CARD_ID ? scope.row.idNumber : "-" }}
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('legal.ownership.owners.passportNumber')"
      :show-overflow-tooltip="true"
    >
      <template #default="scope">
        {{ scope.row.idType === idType.PASSPORT ? scope.row.idNumber : "-" }}
      </template>
    </el-table-column>
    <el-table-column :label="$t('legal.ownership.owners.birthDate')">
      <template #default="scope">
        {{ $date(scope.row.birthDate) }}
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('legal.ownership.owners.incorporation')"
      prop="incorporation"
      :show-overflow-tooltip="true"
    >
    </el-table-column>
    <el-table-column
      :label="$t('legal.ownership.owners.percent')"
      prop="percent"
    >
    </el-table-column>
  </el-table>
  <TableFooter
    @size-change="onSizeChange($event, pageRequest)"
    @current-change="onCurrentChange($event, pageRequest)"
    :page="page"
    :page-sizes="[5, 10, 30, 40]"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { IdDocumentType, PageLegalOwnerItem } from "@/api";
import { authorityMixin, PageRequest, pageSortMixin } from "@/util";
import TableFooter from "@/components/TableFooter.vue";

export default defineComponent({
  name: "LegalOwners",
  mixins: [pageSortMixin, authorityMixin],
  components: { TableFooter },
  props: {
    page: {
      type: Object as PropType<PageLegalOwnerItem>,
      required: true,
    },
    pageRequest: {
      type: Object as PropType<PageRequest>,
      required: true,
    },
    fetchOwnersData: {
      type: Function,
      required: true,
    },
  },
  mounted() {
    this.fetchOwnersData();
  },
  data() {
    return {
      idType: IdDocumentType,
    };
  },
  methods: {
    fetchData() {
      this.fetchOwnersData();
    },
  },
});
</script>
<style lang="scss">
.owners {
  table {
    width: auto !important;
  }
}
</style>
