
import { defineComponent } from "vue";
import { authorityMixin } from "@/util";
import {
  AccountDetailsSettingsData,
  AccountDetailsSettingsHistoryItem,
  AccountDetailsSettingsItem,
} from "@/api";
import { ElementForm, InputLength, InputRequired } from "@/util/validation";
import { settingsApi } from "@/resources";
import { isConstraintViolation, isProblem } from "@/resources/problem";
import { HistoryFormat } from "@/util/enum/HistoryFormat";
import HistoryPopover from "@/components/HistoryPopover.vue";

export default defineComponent({
  name: "SettingsAccountDetails",
  components: { HistoryPopover },
  mixins: [authorityMixin],
  data() {
    return {
      accountDetails: {} as AccountDetailsSettingsItem,
      form: {} as AccountDetailsSettingsData,
      historyFormat: HistoryFormat,
      accountDetailsHistory: {} as AccountDetailsSettingsHistoryItem,
      rules: {
        bankAccount: [
          new InputRequired("validation.inputRequired"),
          new InputLength(255),
        ],
      },
      isFormValid: true,
      editable: false,
    };
  },
  async mounted() {
    this.accountDetails = await settingsApi.accountDetails();
    this.accountDetailsHistory = await settingsApi.accountDetailsSettingsHistory();
    Object.assign(this.form, this.accountDetails);
  },
  methods: {
    cancel() {
      Object.assign(this.form, this.accountDetails);
      this.editable = false;
    },
    onValidate(prop: string, isVal: boolean) {
      this.isFormValid = isVal;
    },
    edit() {
      this.editable = true;
      Object.assign(this.form, this.accountDetails);
    },
    async submit() {
      const form = this.$refs["form"] as ElementForm;
      if (await form.validate()) {
        try {
          await settingsApi.updateAccountDetails({
            accountDetailsSettingsData: this.form,
          });
          Object.assign(this.accountDetails, this.form);
          this.editable = false;
        } catch (error) {
          if (isConstraintViolation(error)) {
            error.violations.forEach((violation) =>
              this.$notify.error(this.$t(violation.message))
            );
          } else if (isProblem(error)) {
            this.$notify.error(this.$t(error.detail));
          }
        }
      }
    },
  },
});
