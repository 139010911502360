<template>
  <li class="nav-item">
    <router-link
      v-if="to"
      class="nav-link"
      :class="isActive && 'active'"
      :to="{ name: to }"
    >
      <slot></slot>
    </router-link>
    <div v-else class="nav-link" :class="isActive && 'active'">
      <slot></slot>
    </div>
  </li>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { RouteRecordName } from "vue-router";

export default defineComponent({
  name: "SidebarLink",
  props: {
    to: {
      type: String,
      required: false,
    },
    active: {
      type: Object as PropType<Array<RouteRecordName>>,
      required: true,
    },
  },
  computed: {
    isActive(): boolean {
      return this.active.includes(this.$route.name as RouteRecordName);
    },
  },
});
</script>

<style scoped lang="scss">
@import "src/styles/variables";
.nav-link {
  color: $color-light-text;
  position: relative;
  padding: 0 2px;
  margin: 0 0 20px 30px;
  display: inline-block;

  &:hover {
    color: $color-dark-text;
    text-decoration: none;
  }

  &.active {
    font-weight: normal;
    color: $color-dark-text;
    font-family: $font-medium;

    &:after {
      content: " ";
      position: absolute;
      left: 0;
      bottom: -2px;
      width: 100%;
      height: 3px;
      background: linear-gradient(to right, #b990d1, #5e80f1);
    }
  }
}
</style>
