import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store, { State } from "@/store";
import { routes } from "@/router/routes";
import { loginRoutes } from "@/router/login";
import { enums } from "@/util";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [],
});

export default router;

function buildRoutes(authorities: string[] | undefined): RouteRecordRaw[] {
  if (!authorities) {
    return loginRoutes;
  }
  const startPage = store.state.account.token?.startPage;
  if (startPage) {
    const location = enums.startPage.location(startPage);
    return [
      ...routes,
      {
        path: "/",
        name: "index",
        redirect: {
          name: location,
          force: true,
        },
      },
    ];
  } else {
    return routes;
  }
}

let removeRouteFunctions: Array<() => void> = [];

export function rebuildRoutes(authorities: string[] | undefined): void {
  removeRouteFunctions.forEach((removeRoute) => removeRoute());
  const routes = buildRoutes(authorities);
  removeRouteFunctions = routes.map((routeRecord) =>
    router.addRoute(routeRecord)
  );
}

store.watch(
  (state: State, getters) => getters["account/authorities"],
  (authorities: string[] | undefined) => {
    if (!authorities || store.state?.account?.token?.reroute) {
      rebuildRoutes(authorities);
      router.push({ path: "/", force: true });
    }
  }
);

rebuildRoutes(store.getters["account/authorities"]);
