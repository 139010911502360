
import { defineComponent } from "vue";
import { CreateRoleForm, Country, StartPage } from "@/api";
import { ElementForm, InputLength, InputRequired } from "@/util/validation";
import { roleApi } from "@/resources";
import { Authority } from "@/util";
import Headline from "@/components/Headline.vue";
import {
  AllAllowedPermissions,
  AllCampaignStatusPermissions,
  ReadWritePermissions,
  uncheckEditPermission,
} from "@/util/authority-groups";

export default defineComponent({
  name: "NewPole",
  components: { Headline },
  data() {
    return {
      role: {} as Omit<CreateRoleForm, "startPage"> &
        Partial<Pick<CreateRoleForm, "startPage">>,
      authorities: Authority,
      residenceCountryOptions: Country,
      startPages: StartPage,
      pages: new Set<StartPage>(),
      rules: {
        name: [new InputRequired(), new InputLength(100)],
        description: [new InputLength(100)],
        residenceCountry: [new InputRequired()],
        startPage: [new InputRequired("validation.selectRequired")],
        authorities: [new InputRequired("validation.permissionRequired")],
      },
      isAllChecked: false,
      isAllCampaignStatusChecked: false,
      AllCampaignStatusPermissions: AllCampaignStatusPermissions,
      allStartPages: Object.values(StartPage),
    };
  },
  mounted() {
    this.role.authorities = [];
  },
  methods: {
    submit() {
      const form = this.$refs["roleForm"] as ElementForm;
      form.validate(async (valid) => {
        if (valid) {
          await this.createRole();
        }
      });
    },
    async createRole() {
      try {
        const response = await roleApi.createRole({
          createRoleForm: this.role as CreateRoleForm,
        });
        this.$notify.info(this.$t("role.roleCreated"));
        await this.$router.push({
          name: "role",
          params: { id: response.id },
        });
      } catch (error) {
        this.$notify.error(this.$t(error.detail));
      }
    },
    checkCampaignPermission(isChecked: boolean) {
      if (isChecked) {
        this.setPermission(
          isChecked,
          this.startPages.CAMPAIGNS,
          this.authorities.CAMPAIGN_READ
        );
      }
    },
    setPermission(isChecked: boolean, page: StartPage, authority: Authority) {
      this.onChange(isChecked, page);
      this.role.authorities.push(authority);
    },
    uncheckEditPermissionIfNotAllowedReadPermission: function () {
      this.role.authorities = [...new Set(this.role.authorities)];
      for (let group of ReadWritePermissions) {
        this.role.authorities = uncheckEditPermission(
          this.role.authorities,
          group.read,
          group.write
        );
      }
    },
    onChange(isChecked: boolean, page: StartPage) {
      this.onPermissionsChange();
      if (isChecked) {
        this.pages.add(page);
      } else {
        this.pages.delete(page);
      }

      if (this.role.startPage && !this.pages.has(this.role.startPage)) {
        delete this.role.startPage;
      }
    },
    selectAllPermissions: function (isChecked: boolean) {
      if (isChecked) {
        for (const key in Object.values(AllAllowedPermissions)) {
          this.role.authorities.push(
            (Object.values(AllAllowedPermissions) as string[])[key]
          );
        }
        for (const page of this.allStartPages) {
          this.onChange(isChecked, page);
        }
        this.isAllChecked = true;
        this.isAllCampaignStatusChecked = true;
      } else {
        this.pages.clear();
        delete this.role.startPage;
        this.role.authorities = [];
        this.isAllChecked = false;
        this.isAllCampaignStatusChecked = false;
      }
    },
    selectPermissions: function (isChecked: boolean, permissions: Authority[]) {
      if (isChecked) {
        if (permissions.some((value) => value.startsWith("campaign:"))) {
          this.checkCampaignPermission(isChecked);
        }
        for (const key in permissions) {
          this.role.authorities.push(permissions[key]);
        }
      } else {
        this.role.authorities = this.role.authorities.filter(function (item) {
          return !(permissions as string[]).includes(item);
        });
      }
      this.onPermissionsChange();
    },
    onPermissionsChange: function () {
      //remove all duplicates in permissions
      this.role.authorities = [...new Set(this.role.authorities)];
      this.isAllChecked =
        this.role.authorities.length ==
        Object.values(AllAllowedPermissions).length;
      //campaigns statues management
      let campaignStatusesCount = this.role.authorities.filter(function (item) {
        return (AllCampaignStatusPermissions as string[]).includes(item);
      }).length;
      this.isAllCampaignStatusChecked =
        campaignStatusesCount == AllCampaignStatusPermissions.length;
      //uncheck edit permissions if uncheck read permissions for the same block
      this.uncheckEditPermissionIfNotAllowedReadPermission();
    },
  },
});
