<template>
  <div class="container ms-0 mb-0" v-if="checklist">
    <div v-if="checklist.type === 'INDIVIDUAL'">
      <div class="row mb-3">
        <div class="col">
          {{
            $t("investment.investorVerification.individual.documentVerified")
          }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="checklist.individual.documentVerified"
            @decided="updateChecklistValue('documentVerified', $event)"
          />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          {{ $t("investment.investorVerification.individual.photoVerified") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="checklist.individual.photoVerified"
            @decided="updateChecklistValue('photoVerified', $event)"
          />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          {{ $t("investment.investorVerification.individual.faceVerified") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="checklist.individual.faceVerified"
            @decided="updateChecklistValue('faceVerified', $event)"
          />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          {{ $t("investment.investorVerification.individual.dofChecked") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="checklist.individual.dofChecked"
            @decided="updateChecklistValue('dofChecked', $event)"
          />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          {{
            $t("investment.investorVerification.individual.occupationChecked")
          }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="checklist.individual.occupationChecked"
            @decided="updateChecklistValue('occupationChecked', $event)"
          />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          {{
            $t("investment.investorVerification.individual.nationalityChecked")
          }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="checklist.individual.nationalityChecked"
            @decided="updateChecklistValue('nationalityChecked', $event)"
          />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          {{ $t("investment.investorVerification.individual.addressChecked") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="checklist.individual.addressChecked"
            @decided="updateChecklistValue('addressChecked', $event)"
          />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          {{ $t("investment.investorVerification.individual.locationChecked") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="checklist.individual.locationChecked"
            @decided="updateChecklistValue('locationChecked', $event)"
          />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          {{ $t("investment.investorVerification.individual.amlChecked") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="checklist.individual.amlChecked"
            @decided="updateChecklistValue('amlChecked', $event)"
          />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          {{
            $t(
              "investment.investorVerification.individual.accreditationChecked"
            )
          }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="checklist.individual.accreditationChecked"
            @decided="updateChecklistValue('accreditationChecked', $event)"
          />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          {{ $t("investment.investorVerification.individual.kycResultDate") }}
        </div>
        <div class="col wrap-text">
          <el-date-picker
            v-model="checklist.individual.kycResultDate"
            type="date"
            format="DD/MM/YYYY"
            size="small"
            :disabled="checklist.individual.verificationCompleted"
            @change="updateCheckResult"
          />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col fw-bolder">
          {{
            $t(
              "investment.investorVerification.individual.verificationCompleted"
            )
          }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="checklist.individual.verificationCompleted"
            @decided="updateChecklistValue('verificationCompleted', $event)"
          />
        </div>
      </div>
    </div>
    <!--    -->
    <div v-else-if="checklist.type === 'LEGAL'">
      <div class="row mb-3">
        <div class="col">
          {{ $t("investment.investorVerification.legal.informationMatch") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="checklist.legal.informationMatch"
            @decided="updateChecklistValue('informationMatch', $event)"
          />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          {{
            $t(
              "investment.investorVerification.legal.acceptableRegistrationCountry"
            )
          }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="checklist.legal.acceptableRegistrationCountry"
            @decided="
              updateChecklistValue('acceptableRegistrationCountry', $event)
            "
          />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          {{ $t("investment.investorVerification.legal.validCertificate") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="checklist.legal.validCertificate"
            @decided="updateChecklistValue('validCertificate', $event)"
          />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          {{
            $t("investment.investorVerification.legal.collectedKybDocuments")
          }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="checklist.legal.collectedKybDocuments"
            @decided="updateChecklistValue('collectedKybDocuments', $event)"
          />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          {{
            $t("investment.investorVerification.legal.shareholdersIdentified")
          }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="checklist.legal.shareholdersIdentified"
            @decided="updateChecklistValue('shareholdersIdentified', $event)"
          />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          {{
            $t("investment.investorVerification.legal.shareholdersClearedKyc")
          }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="checklist.legal.shareholdersClearedKyc"
            @decided="updateChecklistValue('shareholdersClearedKyc', $event)"
          />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          {{
            $t("investment.investorVerification.legal.accreditationCompleted")
          }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="checklist.legal.accreditationCompleted"
            @decided="updateChecklistValue('accreditationCompleted', $event)"
          />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          {{
            $t(
              "investment.investorVerification.legal.checkedAuthorisationLetter"
            )
          }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="checklist.legal.checkedAuthorisationLetter"
            @decided="
              updateChecklistValue('checkedAuthorisationLetter', $event)
            "
          />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          {{ $t("investment.investorVerification.legal.kycResultDate") }}
        </div>
        <div class="col wrap-text">
          <el-date-picker
            v-model="checklist.legal.kycResultDate"
            type="date"
            format="DD/MM/YYYY"
            size="small"
            :disabled="checklist.legal.verificationCompleted"
            @change="updateCheckResult"
          />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col fw-bolder">
          {{
            $t("investment.investorVerification.legal.verificationCompleted")
          }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="checklist.legal.verificationCompleted"
            @decided="updateChecklistValue('verificationCompleted', $event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { InvestorChecklist, InvestorType } from "@/api";
import { investmentApi } from "@/resources";
import Decision from "@/components/Decision.vue";
import { isConstraintViolation, isProblem } from "@/resources/problem";
import { fixLocalDate } from "@/util";

export default defineComponent({
  name: "InvestmentInvestorVerification",
  components: { Decision },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      checklist: {} as InvestorChecklist,
    };
  },
  async mounted() {
    await this.fetchChecklist();
  },
  methods: {
    async fetchChecklist() {
      this.checklist = await investmentApi.getInvestorChecklist({
        id: Number(this.id),
      });
    },
    async updateChecklistValue(field: string, value: boolean | undefined) {
      try {
        const individual =
          this.checklist.type === InvestorType.INDIVIDUAL
            ? { ...this.checklist.individual, [field]: value }
            : this.checklist.individual;
        const legal =
          this.checklist.type === InvestorType.LEGAL
            ? { ...this.checklist.legal, [field]: value }
            : this.checklist.legal;
        this.checklist = await investmentApi.updateInvestorChecklist({
          id: Number(this.id),
          investorChecklist: {
            type: this.checklist.type,
            individual: individual,
            legal: legal,
          },
        });
      } catch (error) {
        if (isConstraintViolation(error)) {
          error.violations.forEach((violation) =>
            this.$notify.error(this.$t(violation.message))
          );
        } else if (isProblem(error)) {
          this.$notify.error(this.$t(error.detail));
        }
      }
    },
    async updateCheckResult(value: Date) {
      try {
        const individual =
          this.checklist.type === InvestorType.INDIVIDUAL
            ? {
                ...this.checklist.individual,
                kycResultDate: value ? fixLocalDate(value) : undefined,
              }
            : this.checklist.individual;
        const legal =
          this.checklist.type === InvestorType.LEGAL
            ? {
                ...this.checklist.legal,
                kycResultDate: value ? fixLocalDate(value) : undefined,
              }
            : this.checklist.legal;
        this.checklist = await investmentApi.updateInvestorChecklist({
          id: Number(this.id),
          investorChecklist: {
            type: this.checklist.type,
            individual: individual,
            legal: legal,
          },
        });
      } catch (error) {
        if (isConstraintViolation(error)) {
          error.violations.forEach((violation) =>
            this.$notify.error(this.$t(violation.message))
          );
        } else if (isProblem(error)) {
          this.$notify.error(this.$t(error.detail));
        }
      }
    },
  },
});
</script>
