/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InvestmentStatus,
    InvestmentStatusFromJSON,
    InvestmentStatusFromJSONTyped,
    InvestmentStatusToJSON,
    InvestorType,
    InvestorTypeFromJSON,
    InvestorTypeFromJSONTyped,
    InvestorTypeToJSON,
    NoteItem,
    NoteItemFromJSON,
    NoteItemFromJSONTyped,
    NoteItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface Investment
 */
export interface Investment {
    /**
     * 
     * @type {number}
     * @memberof Investment
     */
    campaignId?: number;
    /**
     * 
     * @type {Date}
     * @memberof Investment
     */
    closedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof Investment
     */
    committedAmount?: number;
    /**
     * 
     * @type {Date}
     * @memberof Investment
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Investment
     */
    declineReason?: string;
    /**
     * 
     * @type {number}
     * @memberof Investment
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Investment
     */
    readonly investorCommitment?: number;
    /**
     * 
     * @type {number}
     * @memberof Investment
     */
    investorId?: number;
    /**
     * 
     * @type {InvestorType}
     * @memberof Investment
     */
    investorType?: InvestorType;
    /**
     * 
     * @type {number}
     * @memberof Investment
     */
    platformFee?: number;
    /**
     * 
     * @type {InvestmentStatus}
     * @memberof Investment
     */
    previousStatus?: InvestmentStatus;
    /**
     * 
     * @type {number}
     * @memberof Investment
     */
    receivedAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof Investment
     */
    requestedAmount?: number;
    /**
     * 
     * @type {NoteItem}
     * @memberof Investment
     */
    review?: NoteItem;
    /**
     * 
     * @type {InvestmentStatus}
     * @memberof Investment
     */
    status?: InvestmentStatus;
}

export function InvestmentFromJSON(json: any): Investment {
    return InvestmentFromJSONTyped(json, false);
}

export function InvestmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Investment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'campaignId': !exists(json, 'campaignId') ? undefined : json['campaignId'],
        'closedAt': !exists(json, 'closedAt') ? undefined : (new Date(json['closedAt'])),
        'committedAmount': !exists(json, 'committedAmount') ? undefined : json['committedAmount'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'declineReason': !exists(json, 'declineReason') ? undefined : json['declineReason'],
        'id': json['id'],
        'investorCommitment': !exists(json, 'investorCommitment') ? undefined : json['investorCommitment'],
        'investorId': !exists(json, 'investorId') ? undefined : json['investorId'],
        'investorType': !exists(json, 'investorType') ? undefined : InvestorTypeFromJSON(json['investorType']),
        'platformFee': !exists(json, 'platformFee') ? undefined : json['platformFee'],
        'previousStatus': !exists(json, 'previousStatus') ? undefined : InvestmentStatusFromJSON(json['previousStatus']),
        'receivedAmount': !exists(json, 'receivedAmount') ? undefined : json['receivedAmount'],
        'requestedAmount': !exists(json, 'requestedAmount') ? undefined : json['requestedAmount'],
        'review': !exists(json, 'review') ? undefined : NoteItemFromJSON(json['review']),
        'status': !exists(json, 'status') ? undefined : InvestmentStatusFromJSON(json['status']),
    };
}

export function InvestmentToJSON(value?: Investment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'campaignId': value.campaignId,
        'closedAt': value.closedAt === undefined ? undefined : (value.closedAt.toISOString().substr(0,10)),
        'committedAmount': value.committedAmount,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString().substr(0,10)),
        'declineReason': value.declineReason,
        'id': value.id,
        'investorId': value.investorId,
        'investorType': InvestorTypeToJSON(value.investorType),
        'platformFee': value.platformFee,
        'previousStatus': InvestmentStatusToJSON(value.previousStatus),
        'receivedAmount': value.receivedAmount,
        'requestedAmount': value.requestedAmount,
        'review': NoteItemToJSON(value.review),
        'status': InvestmentStatusToJSON(value.status),
    };
}


