<template>
  <div class="container-md ms-0 mb-5">
    <div class="row mb-3">
      <div class="col color-light-text">{{ $t("user.givenName") }}</div>
      <div class="col">
        {{ user.givenName ?? "-" }}
      </div>
      <div class="col color-light-text">{{ $t("user.role") }}</div>
      <div class="col">
        {{ user.role }}
      </div>
    </div>
    <div class="row mb-3">
      <div class="col color-light-text">{{ $t("user.lastName") }}</div>
      <div class="col">
        {{ user.lastName ?? "-" }}
      </div>
      <div class="col color-light-text"></div>
      <div class="col"></div>
    </div>
    <div class="row mb-3">
      <div class="col color-light-text">{{ $t("user.phone") }}</div>
      <div class="col">
        {{ $phone(user.phone) }}
      </div>
      <div class="col color-light-text"></div>
      <div class="col"></div>
    </div>
    <div class="row mb-3">
      <div class="col color-light-text">{{ $t("user.email") }}</div>
      <div class="col">
        {{ user.email }}
      </div>
      <div class="col color-light-text"></div>
      <div class="col"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { authorityMixin } from "@/util";
import { PortalUser } from "@/api";

export default defineComponent({
  name: "PortalUserDetails",
  mixins: [authorityMixin],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      user: inject("user") as PortalUser,
    };
  },
});
</script>
