<template>
  <div class="block my-3">
    <el-pagination
      @size-change="
        $emit('sizeChange', { size: $event, total: page.totalElements ?? 0 })
      "
      @current-change="$emit('currentChange', $event)"
      :currentPage="currentPage"
      :page-sizes="pageSizes"
      :page-size="page.size ?? pageSizes[0]"
      :total="page.totalElements ?? 0"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "TableFooter",
  emits: ["sizeChange", "currentChange"],
  props: {
    page: {
      type: Object,
      required: true,
    },
    pageSizes: {
      type: Array as PropType<Array<number>>,
      default: () => [10, 20, 30, 40],
    },
  },
  computed: {
    currentPage(): number {
      return this.page.number + 1;
    },
  },
});
</script>
