/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResetPasswordRequest
 */
export interface ResetPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    confirmNewPassword: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    login: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    newPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    oldPassword: string;
}

export function ResetPasswordRequestFromJSON(json: any): ResetPasswordRequest {
    return ResetPasswordRequestFromJSONTyped(json, false);
}

export function ResetPasswordRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResetPasswordRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'confirmNewPassword': json['confirmNewPassword'],
        'login': json['login'],
        'newPassword': !exists(json, 'newPassword') ? undefined : json['newPassword'],
        'oldPassword': json['oldPassword'],
    };
}

export function ResetPasswordRequestToJSON(value?: ResetPasswordRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'confirmNewPassword': value.confirmNewPassword,
        'login': value.login,
        'newPassword': value.newPassword,
        'oldPassword': value.oldPassword,
    };
}


