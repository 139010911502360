<template>
  <template v-if="attachmentsGroup.images.length === 0">
    <div v-if="upload" class="row">
      <div>
        <div class="document-group">
          <el-upload
            action=""
            v-loading="uploadInProgress"
            class="upload-group"
            :accept="uploadAccept"
            drag
            multiple
            :show-file-list="false"
            :http-request="uploadFile"
            :before-upload="checkFile"
          >
            <img
              class="upload-img"
              src="@/assets/upload.svg"
              alt="upload image"
            />
            <div class="el-upload__text">
              Drop file here or <em>click to upload</em>
            </div>
            <template #tip>
              <div class="el-upload__tip">
                {{ uploadTip }}
              </div>
            </template>
          </el-upload>
        </div>
      </div>
    </div>
    <div v-else-if="attachmentsGroup.documents.length === 0" class="row">
      <span>{{ $t("noData") }}</span>
    </div>
  </template>
  <div class="row">
    <!--  Separate group for images to avoid mixes with no-preview and preview files  -->
    <div v-if="attachmentsGroup.images.length > 0">
      <div class="document-group" v-if="upload">
        <el-upload
          action=""
          v-loading="uploadInProgress"
          class="upload-group"
          :accept="uploadAccept"
          drag
          multiple
          :show-file-list="false"
          :http-request="uploadFile"
          :before-upload="checkFile"
        >
          <img
            class="upload-img"
            src="@/assets/upload.svg"
            alt="upload image"
          />
          <div class="el-upload__text">
            Drop file here or <em>click to upload</em>
          </div>
          <template #tip>
            <div class="el-upload__tip">
              {{ uploadTip }}
            </div>
          </template>
        </el-upload>
      </div>
      <div
        class="document-group"
        v-for="image in attachmentsGroup.images"
        v-bind:key="image"
      >
        <Attachment
          :attachment="image"
          :is-image="true"
          :source="loadAttachment"
          :delete-enabled="attachmentDeleteEnabled(image)"
          :delete-method="deleteMethod"
        />
      </div>
    </div>
    <!--  Separate group for documents to avoid mixes with no-preview and preview files  -->
    <div v-if="attachmentsGroup.documents.length > 0">
      <div
        class="document-group"
        v-for="document in attachmentsGroup.documents"
        v-bind:key="document"
      >
        <Attachment
          :attachment="document"
          :is-image="false"
          :source="loadAttachment"
          :delete-enabled="attachmentDeleteEnabled(document)"
          :delete-method="deleteMethod"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { AttachmentListItem } from "@/api";
import Attachment from "@/components/Attachment.vue";
import { ElUploadRequestOptions } from "element-plus/es/el-upload/src/upload.type";
import { isConstraintViolation, isProblem } from "@/resources/problem";

export interface AttachmentsGroup {
  documents: Array<AttachmentListItem>;
  images: Array<AttachmentListItem>;
}

export interface FileFormat {
  mimetype: string;
  viewName: string;
  extension: string;
}

export default defineComponent({
  name: "Attachments",
  components: { Attachment },
  emits: ["update"],
  props: {
    attachments: {
      type: Object as PropType<Array<AttachmentListItem>>,
      required: true,
    },
    loadAttachment: {
      type: Function,
      required: true,
    },
    attachmentFormats: {
      type: Object as PropType<Array<FileFormat>>,
      required: false,
      default: () =>
        [
          { mimetype: "application/msword", viewName: "DOC" } as FileFormat,
          {
            mimetype:
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            viewName: "DOCX",
          } as FileFormat,
          {
            mimetype: "application/vnd.ms-excel",
            viewName: "XLS",
          } as FileFormat,
          {
            mimetype:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            viewName: "XLSX",
          } as FileFormat,
          {
            mimetype: "application/vnd.ms-powerpoint",
            viewName: "PPT",
          } as FileFormat,
          {
            mimetype:
              "application/vnd.openxmlformats-officedocument.presentationml.presentation",
            viewName: "PPTX",
          } as FileFormat,
          { mimetype: "image/png", viewName: "PNG" } as FileFormat,
          { mimetype: "image/jpeg", viewName: "JPEG" } as FileFormat,
          { mimetype: "application/pdf", viewName: "PDF" } as FileFormat,
        ] as FileFormat[],
    },
    uploadDisabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    uploadAttachment: {
      type: Function,
      required: false,
    },
    uploadAttachmentMaxSize: {
      type: Number,
      required: false,
      default: () => 10 * 1024 * 1024,
    },
    deleteEnabled: {
      type: Function,
      default: () => false,
    },
    deleteMethod: {
      type: Function,
      required: false,
    },
  },
  data: () => ({
    uploadInProgress: false,
  }),
  computed: {
    attachmentsGroup(): AttachmentsGroup {
      let attachmentGroup = this.attachments.reduce(
        (result, currentValue) => {
          (this.isImage(currentValue) ? result.images : result.documents).push(
            currentValue
          );
          return result;
        },
        { images: [], documents: [] } as AttachmentsGroup
      );
      this.sort(attachmentGroup.images);
      this.sort(attachmentGroup.documents);
      return attachmentGroup;
    },
    upload(): boolean {
      return this.uploadAttachment !== undefined && !this.uploadDisabled;
    },
    uploadTip(): string {
      return this.$t("attachment.tip", {
        formats: this.formats(this.attachmentFormats),
        size: this.size(this.uploadAttachmentMaxSize),
      });
    },
    uploadAccept(): string {
      return this.attachmentFormats
        ? this.extensions(this.attachmentFormats)
        : "";
    },
  },
  methods: {
    isImage(attachment: AttachmentListItem) {
      return ["image/jpeg", "image/png"].includes(attachment.mimetype);
    },
    formats(formats: FileFormat[]): string {
      return formats.map((format) => format.viewName).join("/\u200B");
    },
    extensions(formats: FileFormat[]): string {
      return formats
        .map(
          (format) =>
            `.${
              format.extension
                ? format.extension
                : format.viewName.toLowerCase()
            }`
        )
        .join(",");
    },
    size(size: number): string {
      let i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
      return `${Number((size / Math.pow(1024, i)).toFixed(2))} ${
        ["B", "kB", "MB", "GB", "TB"][i]
      }`;
    },
    sort(attachments: AttachmentListItem[] | undefined) {
      if (attachments) {
        attachments.sort((a1, a2) => a1.id - a2.id);
      }
    },
    async download(id: number, name: string) {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(await this.loadAttachment(id));
      link.download = name;
      link.click();
    },
    checkFile(file: File) {
      if (
        !this.attachmentFormats
          ?.map((format) => format.mimetype)
          .includes(file.type)
      ) {
        this.$notify.error(
          this.$t("attachment.validations.inappropriateFileType", {
            file: file.name,
            formats: this.formats(this.attachmentFormats),
          })
        );
        return false;
      }
      if (file.size >= this.uploadAttachmentMaxSize) {
        this.$notify.error(
          this.$t("attachment.validations.maxFileSize", {
            size: this.size(this.uploadAttachmentMaxSize),
          })
        );
        return false;
      }
      return true;
    },
    async uploadFile(upload: ElUploadRequestOptions) {
      if (this.uploadAttachment) {
        try {
          this.uploadInProgress = true;
          await this.uploadAttachment(upload.file);
          upload.onSuccess("");
          this.$emit("update");
          this.$notify.info(
            this.$t("attachment.uploaded", { file: upload.file.name })
          );
        } catch (e) {
          if (isConstraintViolation(e)) {
            e.violations.forEach((violation) =>
              this.$notify.error(this.$t(violation.message))
            );
          } else if (isProblem(e)) {
            this.$notify.error(this.$t(e.detail));
          }
          upload.onError(e);
        } finally {
          this.uploadInProgress = false;
        }
      }
    },
    attachmentDeleteEnabled(attachment: AttachmentListItem): boolean {
      return this.deleteEnabled(attachment);
    },
  },
});
</script>

<style lang="scss">
$height: 260px;
$width: 244px;

.document-group {
  display: inline-block;
  justify-content: space-between;
  padding: 20px;
  margin: 0;
  vertical-align: top;
}

.upload-group {
  height: $height + 6px;
  width: $width;
  overflow: hidden;
}

.upload-img {
  width: 30%;
  height: 30%;
  margin: 15% auto auto;
  display: block;
}

.el-upload-dragger {
  height: $height * 0.615 !important;
  width: $width !important;
}

.el-upload__tip {
  margin-top: 0 !important;
  text-align: center;
}
</style>
