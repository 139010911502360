/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApproveCampaignOperationRequest,
    ApproveCampaignOperationRequestFromJSON,
    ApproveCampaignOperationRequestToJSON,
    BaseCampaignOperationRequest,
    BaseCampaignOperationRequestFromJSON,
    BaseCampaignOperationRequestToJSON,
    CampaignExtendDurationOperationRequest,
    CampaignExtendDurationOperationRequestFromJSON,
    CampaignExtendDurationOperationRequestToJSON,
    DeclineCampaignOperationRequest,
    DeclineCampaignOperationRequestFromJSON,
    DeclineCampaignOperationRequestToJSON,
} from '../models';

export interface ApproveCampaignRequest {
    id: number;
    approveCampaignOperationRequest: ApproveCampaignOperationRequest;
}

export interface CampaignCollateralReviewRequest {
    id: number;
}

export interface CampaignVerificationRequest {
    id: number;
}

export interface CancelCampaignRequest {
    id: number;
    baseCampaignOperationRequest: BaseCampaignOperationRequest;
}

export interface DeclineCampaignRequest {
    id: number;
    declineCampaignOperationRequest: DeclineCampaignOperationRequest;
}

export interface DisbursementRequest {
    id: number;
}

export interface ExtendCampaignDurationRequest {
    id: number;
    campaignExtendDurationOperationRequest: CampaignExtendDurationOperationRequest;
}

export interface FundraisingRequest {
    id: number;
}

export interface ManualDisbursementRequest {
    id: number;
}

export interface RevertCancelCampaignRequest {
    id: number;
    baseCampaignOperationRequest: BaseCampaignOperationRequest;
}

export interface RevertSuspendCampaignRequest {
    id: number;
    baseCampaignOperationRequest: BaseCampaignOperationRequest;
}

export interface SigningAgreementRequest {
    id: number;
}

export interface SpvRequest {
    id: number;
}

export interface SuspendCampaignRequest {
    id: number;
    baseCampaignOperationRequest: BaseCampaignOperationRequest;
}

export interface VerifyBusinessRequest {
    id: number;
}

export interface WaitingForDataRequest {
    id: number;
    baseCampaignOperationRequest: BaseCampaignOperationRequest;
}

export interface WaitingForRegistrationFeeCampaignRequest {
    id: number;
}

/**
 * 
 */
export class BackOfficeCampaignOperationControllerApi extends runtime.BaseAPI {

    /**
     */
    async approveCampaignRaw(requestParameters: ApproveCampaignRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling approveCampaign.');
        }

        if (requestParameters.approveCampaignOperationRequest === null || requestParameters.approveCampaignOperationRequest === undefined) {
            throw new runtime.RequiredError('approveCampaignOperationRequest','Required parameter requestParameters.approveCampaignOperationRequest was null or undefined when calling approveCampaign.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/{id}/operation/approve`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApproveCampaignOperationRequestToJSON(requestParameters.approveCampaignOperationRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async approveCampaign(requestParameters: ApproveCampaignRequest): Promise<void> {
        await this.approveCampaignRaw(requestParameters);
    }

    /**
     */
    async campaignCollateralReviewRaw(requestParameters: CampaignCollateralReviewRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling campaignCollateralReview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/{id}/operation/collateral-review`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async campaignCollateralReview(requestParameters: CampaignCollateralReviewRequest): Promise<void> {
        await this.campaignCollateralReviewRaw(requestParameters);
    }

    /**
     */
    async campaignVerificationRaw(requestParameters: CampaignVerificationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling campaignVerification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/{id}/operation/verification`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async campaignVerification(requestParameters: CampaignVerificationRequest): Promise<void> {
        await this.campaignVerificationRaw(requestParameters);
    }

    /**
     */
    async cancelCampaignRaw(requestParameters: CancelCampaignRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cancelCampaign.');
        }

        if (requestParameters.baseCampaignOperationRequest === null || requestParameters.baseCampaignOperationRequest === undefined) {
            throw new runtime.RequiredError('baseCampaignOperationRequest','Required parameter requestParameters.baseCampaignOperationRequest was null or undefined when calling cancelCampaign.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/{id}/operation/cancel`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BaseCampaignOperationRequestToJSON(requestParameters.baseCampaignOperationRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async cancelCampaign(requestParameters: CancelCampaignRequest): Promise<void> {
        await this.cancelCampaignRaw(requestParameters);
    }

    /**
     */
    async declineCampaignRaw(requestParameters: DeclineCampaignRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling declineCampaign.');
        }

        if (requestParameters.declineCampaignOperationRequest === null || requestParameters.declineCampaignOperationRequest === undefined) {
            throw new runtime.RequiredError('declineCampaignOperationRequest','Required parameter requestParameters.declineCampaignOperationRequest was null or undefined when calling declineCampaign.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/{id}/operation/decline`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeclineCampaignOperationRequestToJSON(requestParameters.declineCampaignOperationRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async declineCampaign(requestParameters: DeclineCampaignRequest): Promise<void> {
        await this.declineCampaignRaw(requestParameters);
    }

    /**
     */
    async disbursementRaw(requestParameters: DisbursementRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling disbursement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/{id}/operation/disbursement`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async disbursement(requestParameters: DisbursementRequest): Promise<void> {
        await this.disbursementRaw(requestParameters);
    }

    /**
     */
    async extendCampaignDurationRaw(requestParameters: ExtendCampaignDurationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling extendCampaignDuration.');
        }

        if (requestParameters.campaignExtendDurationOperationRequest === null || requestParameters.campaignExtendDurationOperationRequest === undefined) {
            throw new runtime.RequiredError('campaignExtendDurationOperationRequest','Required parameter requestParameters.campaignExtendDurationOperationRequest was null or undefined when calling extendCampaignDuration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/{id}/operation/extend/duration`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CampaignExtendDurationOperationRequestToJSON(requestParameters.campaignExtendDurationOperationRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async extendCampaignDuration(requestParameters: ExtendCampaignDurationRequest): Promise<void> {
        await this.extendCampaignDurationRaw(requestParameters);
    }

    /**
     */
    async fundraisingRaw(requestParameters: FundraisingRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fundraising.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/{id}/operation/fundraising`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async fundraising(requestParameters: FundraisingRequest): Promise<void> {
        await this.fundraisingRaw(requestParameters);
    }

    /**
     */
    async manualDisbursementRaw(requestParameters: ManualDisbursementRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manualDisbursement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/{id}/operation/manual-disbursement`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async manualDisbursement(requestParameters: ManualDisbursementRequest): Promise<void> {
        await this.manualDisbursementRaw(requestParameters);
    }

    /**
     */
    async revertCancelCampaignRaw(requestParameters: RevertCancelCampaignRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling revertCancelCampaign.');
        }

        if (requestParameters.baseCampaignOperationRequest === null || requestParameters.baseCampaignOperationRequest === undefined) {
            throw new runtime.RequiredError('baseCampaignOperationRequest','Required parameter requestParameters.baseCampaignOperationRequest was null or undefined when calling revertCancelCampaign.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/{id}/operation/cancel/revert`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BaseCampaignOperationRequestToJSON(requestParameters.baseCampaignOperationRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async revertCancelCampaign(requestParameters: RevertCancelCampaignRequest): Promise<void> {
        await this.revertCancelCampaignRaw(requestParameters);
    }

    /**
     */
    async revertSuspendCampaignRaw(requestParameters: RevertSuspendCampaignRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling revertSuspendCampaign.');
        }

        if (requestParameters.baseCampaignOperationRequest === null || requestParameters.baseCampaignOperationRequest === undefined) {
            throw new runtime.RequiredError('baseCampaignOperationRequest','Required parameter requestParameters.baseCampaignOperationRequest was null or undefined when calling revertSuspendCampaign.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/{id}/operation/suspend/revert`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BaseCampaignOperationRequestToJSON(requestParameters.baseCampaignOperationRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async revertSuspendCampaign(requestParameters: RevertSuspendCampaignRequest): Promise<void> {
        await this.revertSuspendCampaignRaw(requestParameters);
    }

    /**
     */
    async signingAgreementRaw(requestParameters: SigningAgreementRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling signingAgreement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/{id}/operation/signing-agreement`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async signingAgreement(requestParameters: SigningAgreementRequest): Promise<void> {
        await this.signingAgreementRaw(requestParameters);
    }

    /**
     */
    async spvRaw(requestParameters: SpvRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling spv.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/{id}/operation/spv`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async spv(requestParameters: SpvRequest): Promise<void> {
        await this.spvRaw(requestParameters);
    }

    /**
     */
    async suspendCampaignRaw(requestParameters: SuspendCampaignRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling suspendCampaign.');
        }

        if (requestParameters.baseCampaignOperationRequest === null || requestParameters.baseCampaignOperationRequest === undefined) {
            throw new runtime.RequiredError('baseCampaignOperationRequest','Required parameter requestParameters.baseCampaignOperationRequest was null or undefined when calling suspendCampaign.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/{id}/operation/suspend`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BaseCampaignOperationRequestToJSON(requestParameters.baseCampaignOperationRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async suspendCampaign(requestParameters: SuspendCampaignRequest): Promise<void> {
        await this.suspendCampaignRaw(requestParameters);
    }

    /**
     */
    async verifyBusinessRaw(requestParameters: VerifyBusinessRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling verifyBusiness.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/{id}/operation/verify/business`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async verifyBusiness(requestParameters: VerifyBusinessRequest): Promise<void> {
        await this.verifyBusinessRaw(requestParameters);
    }

    /**
     */
    async waitingForDataRaw(requestParameters: WaitingForDataRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling waitingForData.');
        }

        if (requestParameters.baseCampaignOperationRequest === null || requestParameters.baseCampaignOperationRequest === undefined) {
            throw new runtime.RequiredError('baseCampaignOperationRequest','Required parameter requestParameters.baseCampaignOperationRequest was null or undefined when calling waitingForData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/{id}/operation/waiting-for-data`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BaseCampaignOperationRequestToJSON(requestParameters.baseCampaignOperationRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async waitingForData(requestParameters: WaitingForDataRequest): Promise<void> {
        await this.waitingForDataRaw(requestParameters);
    }

    /**
     */
    async waitingForRegistrationFeeCampaignRaw(requestParameters: WaitingForRegistrationFeeCampaignRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling waitingForRegistrationFeeCampaign.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/{id}/operation/waiting-for-registration-fee`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async waitingForRegistrationFeeCampaign(requestParameters: WaitingForRegistrationFeeCampaignRequest): Promise<void> {
        await this.waitingForRegistrationFeeCampaignRaw(requestParameters);
    }

}
