<template>
  <span
    class="status-badge"
    v-bind:class="{ 'status-badge--medium': isMedium }"
    :style="{ color: color, 'border-color': color }"
  >
    <slot></slot>
  </span>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { ComponentSize } from "@/util/enum/ComponentSize";

export default defineComponent({
  name: "Status",
  props: {
    color: {
      type: String,
      required: true,
    },
    size: {
      type: String as PropType<ComponentSize>,
      required: false,
    },
  },
  computed: {
    isMedium(): boolean {
      return this.size == ComponentSize.MEDIUM;
    },
  },
});
</script>

<style>
.status-badge {
  display: inline-block;
  padding: 6px 12px;
  border-radius: 8px;
  border: 1px solid white;
  color: white;
  text-align: center;
  white-space: nowrap;
  min-width: 190px;
}

.status-badge--medium {
  font-size: 1rem;
  min-width: 95px;
}
</style>
