
import { defineComponent } from "vue";
import { isConstraintViolation, isProblem } from "@/resources/problem";
import { authApi } from "@/resources";
import { ElementForm, InputRequired, passwordFormat } from "@/util/validation";
import PasswordPolicy from "@/components/PasswordPolicy.vue";

interface PasswordRecoveryForm {
  password: string;
  passwordConfirm: string;
}

export default defineComponent({
  components: { PasswordPolicy },
  data() {
    return {
      passwordRecoveryForm: {} as PasswordRecoveryForm,
      errorMessage: "" as string,
      rules: {
        password: [new InputRequired(), passwordFormat],
        passwordConfirm: [new InputRequired()],
      },
    };
  },
  methods: {
    onSubmit() {
      this.errorMessage = "";
      const form = this.$refs["form"] as ElementForm;
      form.validate((valid) => {
        if (!valid) {
          return false;
        }
        if (
          this.passwordRecoveryForm.password ===
          this.passwordRecoveryForm.passwordConfirm
        ) {
          this.setPassword();
        } else {
          this.errorMessage = "The entered passwords don't match";
        }
        return true;
      });
    },
    async setPassword() {
      try {
        await authApi.confirmPasswordRecovery({
          code: this.$route.query.code as string,
          passwordRecoveryForm: this.passwordRecoveryForm,
        });
        this.$notify.info("Your password has been successfully changed");
        await this.$router.push({ path: "/", force: true });
      } catch (e) {
        if (isConstraintViolation(e)) {
          e.violations.forEach(
            (violation) => (this.errorMessage = violation.message)
          );
        } else if (isProblem(e)) {
          this.errorMessage = e.detail;
        }
      }
    },
  },
});
