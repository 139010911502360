
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "TableFooter",
  emits: ["sizeChange", "currentChange"],
  props: {
    page: {
      type: Object,
      required: true,
    },
    pageSizes: {
      type: Array as PropType<Array<number>>,
      default: () => [10, 20, 30, 40],
    },
  },
  computed: {
    currentPage(): number {
      return this.page.number + 1;
    },
  },
});
