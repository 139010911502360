<template>
  <div class="container ms-0 mb-0" v-if="preScreening.id">
    <div class="row mb-3">
      <div class="col color-light-text">
        {{ $t("preScreening.companyName") }}
      </div>
      <div class="col wrap-text">
        {{ preScreening.companyName }}
      </div>
      <div class="col color-light-text">
        {{ $t("preScreening.contactName") }}
      </div>
      <div class="col wrap-text">
        {{ preScreening.contact.name }}
      </div>
      <div class="col color-light-text">
        {{ $t("preScreening.createdAt") }}
      </div>
      <div class="col">
        {{ $date(preScreening.createdAt) }}
      </div>
    </div>
    <div class="row mb-3">
      <div class="col color-light-text">
        {{ $t("preScreening.companyRegistrationCountry") }}
      </div>
      <div class="col">
        {{ $enums.globals.countryIso(preScreening.companyRegistrationCountry) }}
      </div>
      <div class="col color-light-text">
        {{ $t("preScreening.contactPosition") }}
      </div>
      <div class="col">
        {{ preScreening.contact.position }}
      </div>
      <div class="col"></div>
      <div class="col"></div>
    </div>
    <div class="row mb-3">
      <div class="col color-light-text">
        {{ $t("preScreening.companyRegistrationNumber") }}
      </div>
      <div class="col wrap-text">
        {{ preScreening.companyRegistrationNumber }}
      </div>
      <div class="col color-light-text">
        {{ $t("preScreening.contactNumber") }}
      </div>
      <div class="col">
        {{ $phone(preScreening.contact.phone) }}
      </div>
      <div class="col"></div>
      <div class="col"></div>
    </div>
    <div class="row mb-3">
      <div class="col color-light-text"></div>
      <div class="col"></div>
      <div class="col color-light-text">
        {{ $t("preScreening.contactEmail") }}
      </div>
      <div class="col wrap-text">
        {{ $phone(preScreening.contact.email) }}
      </div>
      <div class="col"></div>
      <div class="col"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { PreScreeningItem } from "@/api";

export default defineComponent({
  name: "PreScreeningGeneralInfo",
  data() {
    return {
      preScreening: inject("preScreening") as PreScreeningItem,
    };
  },
});
</script>
