<template>
  <div class="row mb-3">
    <div class="col mb-3">
      <div class="row mb-3">
        <div class="col color-light-text mb-3">
          {{ $t("legal.finance.turnover.title") }}
        </div>
        <div class="row ms-0 mb-3">
          <div class="col color-light-text">
            {{
              $t("legal.finance.turnover.local", {
                currency: $enums.globals.currency(
                  finance.currency ?? defaultCurrency
                ),
              })
            }}
          </div>
          <div class="row ms-0 mb-3">
            <div class="row ms-0 mb-3" />
            <div class="col color-light-text">
              {{ "-" + $t("legal.finance.turnover.current") }}
            </div>
            <div class="col">
              {{ $currValue(finance.localTurnoverCurrent) }}
            </div>
          </div>
          <div class="row ms-0 mb-3">
            <div class="col color-light-text">
              {{ "-" + $t("legal.finance.turnover.projected") }}
            </div>
            <div class="col">
              {{ $currValue(finance.localTurnoverProjected) }}
            </div>
          </div>
        </div>
        <div class="row ms-0 mb-3">
          <div class="col color-light-text">
            {{
              $t("legal.finance.totalAnnualRevenue", {
                currency: $enums.globals.currency(
                  finance.currency ?? defaultCurrency
                ),
              })
            }}
          </div>
          <div class="col">
            {{ $currValue(finance.totalAnnualRevenue) }}
          </div>
        </div>
        <div class="row ms-0 mb-3">
          <div class="col color-light-text">
            {{
              $t("legal.finance.totalAssets", {
                currency: $enums.globals.currency(
                  finance.currency ?? defaultCurrency
                ),
              })
            }}
          </div>
          <div class="col">
            {{ $currValue(finance.totalAssets) }}
          </div>
        </div>
        <div class="row ms-0 mb-3">
          <div class="col color-light-text">
            {{ $t("legal.finance.initialFundsSource") }}
          </div>
          <div class="col">
            {{ finance.initialFundsSource ?? "-" }}
          </div>
        </div>
      </div>
    </div>
    <div class="col mb-3">
      <div class="row ms-0 mb-3">
        <div class="col color-light-text">
          {{ $t("legal.finance.groupName") }}
        </div>
        <div class="col">
          {{ finance.groupName ?? "-" }}
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col color-light-text">
          {{ $t("legal.finance.groupAddress") }}
        </div>
        <div class="col">
          {{ finance.groupAddress ?? "-" }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Currency, LegalFinanceItem } from "@/api";

export default defineComponent({
  name: "LegalFinance",
  props: {
    finance: {
      type: Object as PropType<LegalFinanceItem>,
      required: true,
    },
  },
  data: () => ({
    defaultCurrency: Currency.OMR as Currency,
  }),
});
</script>
