<template>
  <div class="container-auth">
    <Headline class="headline-title-md">{{
      $t("auth.password.change")
    }}</Headline>
    <el-form
      ref="form"
      :model="passwordChangeForm"
      :rules="rules"
      status-icon
      :hide-required-asterisk="true"
      class="error-message-static"
    >
      <el-form-item
        :label="$t('auth.password.currentPassword')"
        prop="oldPassword"
      >
        <el-input
          v-model="passwordChangeForm.oldPassword"
          show-password
          class="el-input__password"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('auth.password.enterNew')" prop="newPassword">
        <el-input
          v-model="passwordChangeForm.newPassword"
          show-password
          class="el-input__password"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('auth.password.confirmNew')"
        prop="confirmNewPassword"
      >
        <el-input
          v-model="passwordChangeForm.confirmNewPassword"
          show-password
          class="el-input__password"
        ></el-input>
      </el-form-item>
      <span class="invalid-feedback mb-2 d-block" v-if="errorMessage">
        {{ errorMessage }}
      </span>
      <el-form-item>
        <el-button
          class="btn btn-primary el-button--lg w-100 mt-4"
          type="primary"
          @click="onSubmit"
          >{{ $t("save") }}
        </el-button>
      </el-form-item>
      <PasswordPolicy />
    </el-form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { isConstraintViolation, isProblem } from "@/resources/problem";
import { accountApi } from "@/resources";
import { ElementForm, InputRequired, passwordFormat } from "@/util/validation";
import PasswordPolicy from "@/components/PasswordPolicy.vue";
import Headline from "@/components/Headline.vue";
import { PasswordChangeForm } from "@/api";

export default defineComponent({
  components: { PasswordPolicy, Headline },
  data() {
    return {
      passwordChangeForm: {} as PasswordChangeForm,
      errorMessage: "" as string,
      rules: {
        oldPassword: [new InputRequired("validation.currentPasswordRequired")],
        newPassword: [
          new InputRequired("validation.newPasswordRequired"),
          passwordFormat,
          {
            validator: (
              rule: Record<string, unknown>,
              value: string,
              callback: (error?: Error) => unknown
            ) => {
              const constraints = (this
                .passwordChangeForm as unknown) as PasswordChangeForm;
              if (value === constraints.oldPassword) {
                callback(
                  new Error(
                    this.$t(
                      "auth.password.newPasswordIsSameAsPreviousPasswords"
                    )
                  )
                );
              } else {
                callback();
              }
            },
          },
        ],
        confirmNewPassword: [
          new InputRequired("validation.confirmationPasswordRequired"),
          {
            validator: (
              rule: Record<string, unknown>,
              value: string,
              callback: (error?: Error) => unknown
            ) => {
              const constraints = (this
                .passwordChangeForm as unknown) as PasswordChangeForm;
              if (value !== constraints.newPassword) {
                callback(new Error(this.$t("auth.password.passwordMismatch")));
              } else {
                callback();
              }
            },
          },
        ],
      },
    };
  },
  methods: {
    onSubmit(event: Event) {
      event.preventDefault();
      this.errorMessage = "";
      const form = this.$refs["form"] as ElementForm;
      form.validate((valid) => {
        if (!valid) {
          return false;
        }
        this.setPassword();
        return true;
      });
    },
    async setPassword() {
      try {
        await accountApi.changePassword({
          passwordChangeForm: this.passwordChangeForm,
        });
        this.$notify.info(this.$t("auth.password.changed"));
        await this.$router.push({ path: "/", force: true });
      } catch (e) {
        if (isConstraintViolation(e)) {
          e.violations.forEach(
            (violation) => (this.errorMessage = violation.message)
          );
        } else if (isProblem(e)) {
          this.errorMessage = e.detail as string;
        }
      }
    },
  },
});
</script>

<style scoped>
.container-auth {
  margin-top: 40px;
}
</style>
