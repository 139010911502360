<template>
  <div class="card">
    <div class="card-main">
      <BackLink />
      <Headline v-if="investment.id" :class="'align-items-start'">
        <div class="h1-sm" style="display: inline">
          {{ $t("investment.viewTitle") + " " + id }}
        </div>
        <template v-slot:details>
          <Status
            :color="$enums.investment.statusColor(investment.status)"
            size="medium"
          >
            {{ $enums.investment.status(investment.status) }}
          </Status>
        </template>
        <template
          v-slot:actions
          v-if="
            investment.id && hasAuthority(authorities.INVESTMENT_STATUS_CHANGE)
          "
        >
          <el-dropdown trigger="click">
            <el-button type="default">
              {{ $t("actions") }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <template #dropdown>
              <el-dropdown-item>
                <template
                  v-if="
                    investment.id &&
                    investmentState.nextStatuses &&
                    hasAuthority(authorities.INVESTMENT_STATUS_CHANGE)
                  "
                >
                  <OkCancelDialog
                    :dialogDisabled="
                      !investmentState.nextStatuses.includes(
                        investmentStatuses.SUBMITTED
                      ) ||
                      investment.status ===
                        investmentStatuses.CANCELLATION_REQUEST
                    "
                    :header="$t('investment.submit.header')"
                    :text="$t('investment.submit.text')"
                    :info-message="$t('investment.submit.info')"
                    :onOk="submitInvestment"
                    @update="fetchData"
                  />
                </template>
              </el-dropdown-item>
              <el-dropdown-item>
                <template
                  v-if="
                    investment.id &&
                    investmentState.nextStatuses &&
                    hasAuthority(authorities.INVESTMENT_STATUS_CHANGE)
                  "
                >
                  <OkCancelDialog
                    :dialogDisabled="
                      !investmentState.nextStatuses.includes(
                        investmentStatuses.AWAITING_PLATFORM_FEE
                      ) ||
                      investment.status ===
                        investmentStatuses.CANCELLATION_REQUEST
                    "
                    :header="$t('investment.awaitingPlatformFee.header')"
                    :text="$t('investment.awaitingPlatformFee.text')"
                    :info-message="$t('investment.awaitingPlatformFee.info')"
                    :onOk="platformFee"
                    @update="fetchData"
                  />
                </template>
              </el-dropdown-item>
              <el-dropdown-item>
                <template
                  v-if="
                    investment.id &&
                    investmentState.nextStatuses &&
                    hasAuthority(authorities.INVESTMENT_STATUS_CHANGE)
                  "
                >
                  <OkCancelDialog
                    :dialogDisabled="
                      !investmentState.nextStatuses.includes(
                        investmentStatuses.SIGNING_TERM_SHEET
                      ) ||
                      investment.status ===
                        investmentStatuses.CANCELLATION_REQUEST
                    "
                    :header="$t('investment.signingTermSheet.header')"
                    :text="$t('investment.signingTermSheet.text')"
                    :info-message="$t('investment.signingTermSheet.info')"
                    :onOk="signTermSheet"
                    @update="fetchData"
                  />
                </template>
              </el-dropdown-item>
              <el-dropdown-item>
                <template
                  v-if="
                    investment.id &&
                    investmentState.nextStatuses &&
                    hasAuthority(authorities.INVESTMENT_STATUS_CHANGE)
                  "
                >
                  <OkCancelDialog
                    :dialogDisabled="
                      investmentState.investmentTransactionExist ||
                      investment.status !==
                        investmentStatuses.SIGNING_TERM_SHEET
                    "
                    :header="$t('investment.confirmTermSigning.header')"
                    :text="$t('investment.confirmTermSigning.text')"
                    :info-message="$t('investment.confirmTermSigning.info')"
                    :onOk="confirmTermSigning"
                    @update="fetchData"
                  >
                    <div class="container-md ms-lg-1">
                      <el-radio-group
                        v-model="transactionProcessingType"
                        class="row-cols-lg-1"
                      >
                        <el-radio :label="transactionProcessing.MANUAL">
                          {{ $t("investment.transactionProcessing.MANUAL") }}
                        </el-radio>
                        <el-radio :label="transactionProcessing.AUTOMATED">
                          {{ $t("investment.transactionProcessing.AUTOMATED") }}
                        </el-radio>
                      </el-radio-group>
                    </div>
                  </OkCancelDialog>
                </template>
              </el-dropdown-item>
              <el-dropdown-item>
                <template
                  v-if="
                    investment.id &&
                    investmentState.nextStatuses &&
                    hasAuthority(authorities.INVESTMENT_STATUS_CHANGE)
                  "
                >
                  <OkCancelDialog
                    :dialogDisabled="
                      !investmentState.nextStatuses.includes(
                        investmentStatuses.UNDER_PROCESS
                      ) ||
                      investment.status ===
                        investmentStatuses.CANCELLATION_REQUEST
                    "
                    :header="$t('investment.process.header')"
                    :text="$t('investment.process.text')"
                    :info-message="$t('investment.process.info')"
                    :onOk="processInvestment"
                    @update="fetchData"
                  />
                </template>
              </el-dropdown-item>
              <el-dropdown-item>
                <template
                  v-if="
                    investment.id &&
                    investmentState.nextStatuses &&
                    hasAuthority(authorities.INVESTMENT_STATUS_CHANGE)
                  "
                >
                  <BaseInvestmentReasonDialog
                    :dialogDisabled="
                      !investmentState.nextStatuses.includes(
                        investmentStatuses.WAITING_FOR_DATA
                      ) ||
                      investment.status ===
                        investmentStatuses.CANCELLATION_REQUEST
                    "
                    :header="$t('investment.requestDataUpdate.header')"
                    :text="$t('investment.requestDataUpdate.text')"
                    :info-message="$t('investment.requestDataUpdate.info')"
                    :onSubmit="requestDataUpdate"
                    @update="fetchData"
                  />
                </template>
              </el-dropdown-item>
              <el-dropdown-item>
                <template
                  v-if="
                    investment.id &&
                    investmentState.nextStatuses &&
                    hasAuthority(authorities.INVESTMENT_STATUS_CHANGE)
                  "
                >
                  <OkCancelDialog
                    :dialogDisabled="
                      !investmentState.nextStatuses.includes(
                        investmentStatuses.APPROVED
                      ) ||
                      investment.status ===
                        investmentStatuses.CANCELLATION_REQUEST
                    "
                    :header="$t('investment.approve.header')"
                    :text="$t('investment.approve.text')"
                    :info-message="$t('investment.approve.info')"
                    :onOk="approveInvestment"
                    @update="fetchData"
                  />
                </template>
              </el-dropdown-item>
              <el-dropdown-item>
                <template
                  v-if="
                    investment.id &&
                    investmentState.nextStatuses &&
                    hasAuthority(authorities.INVESTMENT_STATUS_CHANGE)
                  "
                >
                  <DeclineInvestmentDialog
                    :dialogDisabled="
                      !investmentState.nextStatuses.includes(
                        investmentStatuses.DECLINED
                      )
                    "
                    :id="investment.id"
                    @update="fetchData"
                  />
                </template>
              </el-dropdown-item>
              <el-dropdown-item>
                <template
                  v-if="
                    investment.id &&
                    investmentState.nextStatuses &&
                    hasAuthority(authorities.INVESTMENT_MANUAL_REFUND)
                  "
                >
                  <OkCancelDialog
                    :dialogDisabled="
                      !investmentState.nextStatuses.includes(
                        investmentStatuses.REFUND_PENDING
                      )
                    "
                    :header="$t('investment.refund.header')"
                    :text="$t('investment.refund.text')"
                    :info-message="$t('investment.refund.info')"
                    :onOk="refundInvestment"
                    @update="fetchData"
                  />
                </template>
              </el-dropdown-item>
            </template>
          </el-dropdown>
        </template>
      </Headline>
    </div>
    <div class="card-foot">
      <ul class="nav nav-tabs mb-3">
        <TabLink to="investmentDetails">
          {{ $t("investment.tabs.details") }}
        </TabLink>
        <li
          v-if="
            hasAuthority(authorities.INVESTOR_READ) && investment.investorId
          "
          class="nav-item"
        >
          <router-link
            v-slot="{ navigate }"
            custom
            :to="{
              name: 'investmentInvestorGeneralInfo',
              params: { investorId: investment.investorId },
            }"
          >
            <button
              class="nav-link"
              :class="investorOpened && 'active'"
              @click="navigate"
            >
              {{ $t("investment.tabs.investor") }}
            </button>
          </router-link>
        </li>
        <TabLink to="investmentAttachments">
          {{ $t("investment.tabs.attachments") }}
        </TabLink>
        <TabLink to="investmentDocuments">
          {{ $t("investment.tabs.documents") }}
        </TabLink>
        <TabLink to="investmentCampaignReview">
          {{ $t("investment.tabs.review") }}
        </TabLink>
        <TabLink
          to="investorVerification"
          v-if="hasAuthority(authorities.INVESTMENT_INVESTOR_VERIFICATION)"
        >
          {{ $t("investment.tabs.investorVerification") }}
        </TabLink>
      </ul>
    </div>
    <CollapseTransition
      v-if="hasAuthority(authorities.INVESTOR_READ) && investment.investorId"
    >
      <div class="card-foot sub_tab_foot" v-show="investorOpened">
        <ul
          class="nav nav-tabs mb-3"
          v-if="investment.investorType === 'INDIVIDUAL'"
        >
          <TabLink
            to="investmentInvestorGeneralInfo"
            :props="{ investorId: investment.investorId }"
          >
            {{ $t("investor.tabs.generalInfo") }}
          </TabLink>
          <TabLink
            to="investmentInvestorContactDetails"
            :props="{ investorId: investment.investorId }"
          >
            {{ $t("investor.tabs.contactDetails") }}
          </TabLink>
          <TabLink
            to="investmentInvestorOccupationIncome"
            :props="{ investorId: investment.investorId }"
          >
            {{ $t("investor.tabs.occupationIncome") }}
          </TabLink>
          <TabLink
            to="investmentInvestorBankDetails"
            :props="{ investorId: investment.investorId }"
          >
            {{ $t("investor.tabs.bankDetails") }}
          </TabLink>
          <TabLink
            to="investmentInvestorAttachments"
            :props="{ investorId: investment.investorId }"
          >
            {{ $t("investor.tabs.attachments") }}
          </TabLink>
        </ul>

        <ul class="nav nav-tabs mb-3" v-else>
          <TabLink
            to="investmentInvestorGeneralInfo"
            :props="{ investorId: investment.investorId }"
          >
            {{ $t("investor.tabs.generalInfo") }}
          </TabLink>
          <TabLink
            to="investmentInvestorContactDetails"
            :props="{ investorId: investment.investorId }"
          >
            {{ $t("investor.tabs.contactDetails") }}
          </TabLink>
          <TabLink
            to="investmentInvestorFinancialInfo"
            :props="{ investorId: investment.investorId }"
          >
            {{ $t("investor.tabs.financialInfo") }}
          </TabLink>
          <TabLink
            to="investmentInvestorBankDetails"
            :props="{ investorId: investment.investorId }"
          >
            {{ $t("investor.tabs.bankDetails") }}
          </TabLink>
          <TabLink
            to="investmentInvestorOwnershipInfo"
            :props="{ investorId: investment.investorId }"
          >
            {{ $t("investor.tabs.ownership") }}
          </TabLink>
          <TabLink
            to="investmentInvestorAttachments"
            :props="{ investorId: investment.investorId }"
          >
            {{ $t("investor.tabs.attachments") }}
          </TabLink>
        </ul>
      </div>
    </CollapseTransition>
  </div>
  <div class="row">
    <div class="tab-content">
      <div class="tab-pane active">
        <router-view @update="fetchData"></router-view>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import TabLink from "@/components/TabLink.vue";
import Headline from "@/components/Headline.vue";
import { investmentApi } from "@/resources";
import {
  BackOfficeInvestmentState,
  BaseInvestmentStatusChangeRequest,
  Investment,
  InvestmentStatus,
  TransactionProcessingType,
} from "@/api";
import BackLink from "@/components/BackLink.vue";
import Status from "@/components/Status.vue";
import { authorityMixin } from "@/util";
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";
import OkCancelDialog from "@/views/components/OkCancelDialog.vue";
import DeclineInvestmentDialog from "@/views/investment/dialogs/DeclineInvestmentDialog.vue";
import BaseInvestmentReasonDialog from "@/views/investment/dialogs/BaseInvestmentReasonDialog.vue";
import { watchInvestmentUpdate } from "@/util/ws";

export default defineComponent({
  name: "InvestmentView",
  mixins: [authorityMixin],
  components: {
    BaseInvestmentReasonDialog,
    Headline,
    TabLink,
    BackLink,
    Status,
    CollapseTransition,
    OkCancelDialog,
    DeclineInvestmentDialog,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    investorId: {
      type: String,
      required: false,
    },
  },
  provide() {
    return {
      investment: computed(() => this.investment),
    };
  },
  data() {
    return {
      transactionProcessingType: TransactionProcessingType.AUTOMATED as TransactionProcessingType,
      investment: {} as Investment,
      investmentState: {} as BackOfficeInvestmentState,
      investmentStatuses: InvestmentStatus,
      transactionProcessing: TransactionProcessingType,
      investmentUpdateSubscription: watchInvestmentUpdate(this.id, () => {
        this.$notify.info(this.$t("investment.updated"));
        this.fetchData(this.id);
      }),
    };
  },
  async mounted() {
    await this.fetchData();
  },
  async beforeRouteLeave() {
    this.investmentUpdateSubscription.unsubscribe();
  },
  methods: {
    async fetchInvestment() {
      this.investment = await investmentApi.investment({ id: Number(this.id) });
    },
    async fetchInvestmentState() {
      this.investmentState = await investmentApi.getInvestmentState({
        id: Number(this.id),
      });
    },
    async fetchData() {
      await Promise.all([this.fetchInvestment(), this.fetchInvestmentState()]);
    },
    async submitInvestment() {
      await investmentApi.submit({
        id: this.investment.id,
      });
    },
    async platformFee() {
      await investmentApi.waitingForPlatformFee({
        id: this.investment.id,
      });
    },
    async signTermSheet() {
      await investmentApi.signTermSheet({
        id: this.investment.id,
      });
    },
    async confirmTermSigning() {
      await investmentApi.confirmTermSigning({
        id: this.investment.id,
        body: this.transactionProcessingType,
      });
    },
    async processInvestment() {
      await investmentApi.process({
        id: this.investment.id,
      });
    },
    async requestDataUpdate(form: BaseInvestmentStatusChangeRequest) {
      await investmentApi.requestDataUpdate({
        id: this.investment.id,
        baseInvestmentStatusChangeRequest: form,
      });
    },
    async approveInvestment() {
      await investmentApi.approveInvestment({
        id: this.investment.id,
      });
    },
    async refundInvestment() {
      await investmentApi.refund({
        id: this.investment.id,
      });
    },
  },
  computed: {
    investorOpened(): boolean {
      return this.$route.name
        ? this.$route.name.toString().startsWith("investmentInvestor")
        : false;
    },
  },
});
</script>
<style lang="scss" scoped>
@import "src/styles/tabs";
</style>
