import {
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";
import Login from "@/views/auth/Login.vue";
import PasswordRegistration from "@/views/auth/PasswordRegistration.vue";
import ForgotPassword from "@/views/auth/ForgotPassword.vue";
import PasswordRecovery from "@/views/auth/PasswordRecovery.vue";
import InvalidLink from "@/views/auth/InvalidLink.vue";
import { authApi } from "@/resources";
import { isProblem } from "@/resources/problem";
import RegistrationLinkExpired from "@/views/auth/RegistrationLinkExpired.vue";
import PasswordRecoveryLinkExpired from "@/views/auth/PasswordRecoveryLinkExpired.vue";
import ExpiredPasswordChange from "@/views/auth/ExpiredPasswordChange.vue";

export const loginRoutes: RouteRecordRaw[] = [
  {
    path: "/:param*",
    name: "index",
    redirect: { name: "login", force: true },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/registration",
    name: "passwordRegistration",
    component: PasswordRegistration,
    beforeEnter: async (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ): Promise<void> => {
      try {
        await authApi.checkRegistrationOtp({
          code: to.query.code as string,
        });
        next();
      } catch (e) {
        if (isProblem(e)) {
          switch (e.type) {
            case "invalidLink":
              next({ name: "invalidLink" });
              break;
            case "registrationLinkExpired":
              next({ name: "registrationLinkExpired" });
              break;
            default:
              next();
          }
        }
      }
    },
  },
  {
    path: "/password/recovery",
    name: "passwordRestore",
    component: PasswordRecovery,
    beforeEnter: async (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ): Promise<void> => {
      try {
        await authApi.checkRecoveryOtp({
          code: to.query.code as string,
        });
        next();
      } catch (e) {
        if (isProblem(e)) {
          switch (e.type) {
            case "invalidLink":
              next({ name: "invalidLink" });
              break;
            case "recoveryLinkExpired":
              next({ name: "recoveryLinkExpired" });
              break;
            default:
              next();
          }
        }
      }
    },
  },
  {
    path: "/forgotPassword",
    name: "forgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/invalidLink",
    name: "invalidLink",
    component: InvalidLink,
  },
  {
    path: "/registration/linkExpired",
    name: "registrationLinkExpired",
    component: RegistrationLinkExpired,
  },
  {
    path: "/password/recovery/linkExpired",
    name: "recoveryLinkExpired",
    component: PasswordRecoveryLinkExpired,
  },
  {
    path: "/password/change",
    name: "changeExpiredPassword",
    component: ExpiredPasswordChange,
  },
];
