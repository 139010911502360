import { BatchStatus } from "@/api";
import { i18n } from "@/util";

export class Batch {
  static status(status: BatchStatus): string {
    return status ? i18n.global.t("batch.status." + status.toLowerCase()) : "-";
  }

  static statusColor(status: BatchStatus): string {
    switch (status) {
      case BatchStatus.FAILED:
        return "#d20f0f";
      case BatchStatus.IN_PROGRESS:
        return "#e1a514";
      case BatchStatus.COMPLETED:
        return "#1ddc37";
      case BatchStatus.INITIATED:
        return "#0056f3";
      default:
        return "#7f7f7f";
    }
  }
}
