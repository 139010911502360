
import { defineComponent } from "vue";
import { mapGetters, mapMutations } from "vuex";
import { ElementForm, InputRequired } from "@/util/validation";
import { LoginRequest } from "@/api";
import { isProblem } from "@/resources/problem";
import { authApi, setLastRequestTime } from "@/resources";

function initForm(): LoginRequest {
  return {
    login: "",
    password: "",
  };
}

export default defineComponent({
  name: "Login",
  data: () => ({
    form: initForm(),
    errorMessage: undefined as string | undefined,
    showBadRoleMessage: false,
    rules: {
      login: [new InputRequired()],
      password: [new InputRequired()],
    },
  }),
  methods: {
    ...mapMutations("account", ["setToken"]),
    onSubmit(event: Event) {
      event.preventDefault();
      const form = this.$refs["form"] as ElementForm;
      form.validate((valid) => {
        if (valid) {
          this.doLogin();
        }
        return valid;
      });
    },
    async doLogin() {
      try {
        this.errorMessage = undefined;
        this.showBadRoleMessage = false;
        const token = await authApi.login({
          loginRequest: this.form,
        });
        this.setToken(token);
        setLastRequestTime();
      } catch (e) {
        if (isProblem(e)) {
          switch (e.type) {
            case "changeExpiredPassword":
              await this.$router.push({ name: "changeExpiredPassword" });
              break;
            case "badRole":
              this.showBadRoleMessage = true;
              this.form = initForm();
              break;
            default:
              this.errorMessage = e.detail as string;
              this.form = initForm();
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters("account", ["isAuthenticated"]),
  },
});
