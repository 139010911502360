<template>
  <div>
    {{ $t("note.createdBy") }}
    <router-link
      v-if="note.actor === actor.BO_USER && hasAuthority(authorities.USER_READ)"
      :to="{ name: 'user', params: { id: note.user.id } }"
      >{{ note.user.name }}
    </router-link>
    <router-link
      v-else-if="
        note.actor === actor.PORTAL_USER && hasAuthority(authorities.USER_READ)
      "
      :to="{ name: 'portalUser', params: { id: note.user.id } }"
      >{{ note.user.name }}
    </router-link>
    <span v-else>{{ note.user.name }}</span>
    {{
      $t(
        note.modifiedAt
          ? `${translationPrefix}.createdModified`
          : `${translationPrefix}.createdAtNotModified`,
        {
          createdAt: $dateTimeSeconds(note.createdAt),
          modifiedAt: $dateTimeSeconds(note.modifiedAt),
        }
      )
    }}
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { FlowActor, NoteItem } from "@/api";
import { authorityMixin } from "@/util";

export default defineComponent({
  name: "NoteInfo",
  mixins: [authorityMixin],
  props: {
    note: {
      type: Object as PropType<NoteItem>,
      required: true,
    },
    translationPrefix: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      actor: FlowActor,
    };
  },
});
</script>
