/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PreScreeningStatus {
    DRAFT = 'DRAFT',
    RECEIVED = 'RECEIVED',
    REVIEWED = 'REVIEWED',
    FOLLOW_UP = 'FOLLOW_UP',
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    DECLINED = 'DECLINED',
    CANCELLED = 'CANCELLED'
}

export function PreScreeningStatusFromJSON(json: any): PreScreeningStatus {
    return PreScreeningStatusFromJSONTyped(json, false);
}

export function PreScreeningStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreScreeningStatus {
    return json as PreScreeningStatus;
}

export function PreScreeningStatusToJSON(value?: PreScreeningStatus | null): any {
    return value as any;
}

