
import { defineComponent, PropType } from "vue";
import { FlowActor, NoteItem } from "@/api";
import { authorityMixin } from "@/util";

export default defineComponent({
  name: "NoteInfo",
  mixins: [authorityMixin],
  props: {
    note: {
      type: Object as PropType<NoteItem>,
      required: true,
    },
    translationPrefix: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      actor: FlowActor,
    };
  },
});
