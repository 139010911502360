<template>
  <div class="container ms-0 mb-0" v-if="investment.id">
    <h3>{{ $t("investment.generalInformation") }}</h3>
    <div class="row mb-3">
      <div class="col color-light-text">
        {{ $t("investment.requestedAmount") }}
      </div>
      <div class="col">
        {{ $currValue(investment.requestedAmount) }}
      </div>
      <div class="col color-light-text">
        {{ $t("investment.createdAt") }}
      </div>
      <div class="col">
        {{ $date(investment.createdAt) }}
      </div>
      <div class="col color-light-text">
        {{ $t("investment.campaign") }}
      </div>
      <div class="col">
        <router-link
          v-if="hasAuthority(authorities.CAMPAIGN_READ)"
          :to="{ name: 'campaign', params: { id: investment.campaignId } }"
          >{{ investment.campaignId }}
        </router-link>
        <span v-else>{{ investment.campaignId }}</span>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col color-light-text">
        {{ $t("investment.committedAmount") }}
      </div>
      <div class="col">
        {{ $currValue(investment.committedAmount) }}
      </div>
      <div class="col color-light-text">
        {{ $t("investment.closedAt") }}
      </div>
      <div class="col">
        {{ $date(investment.closedAt) }}
      </div>
      <div class="col color-light-text">
        {{ $t("investment.declineReason.title") }}
      </div>
      <div class="col">
        {{ $enums.investment.declineReason(investment.declineReason) }}
      </div>
    </div>
    <div class="row mb-3">
      <div class="col color-light-text">
        {{ $t("investment.receivedAmount") }}
      </div>
      <div class="col">
        {{ $currValue(investment.receivedAmount) }}
      </div>
      <div class="col color-light-text"></div>
      <div class="col"></div>
      <div class="col color-light-text"></div>
      <div class="col"></div>
    </div>
    <div class="row mb-3">
      <div class="col color-light-text">
        {{ $t("investment.investorCommitment") }}
      </div>
      <div class="col">
        {{ $currValue(investment.investorCommitment) }}
      </div>
      <div class="col color-light-text"></div>
      <div class="col"></div>
      <div class="col color-light-text"></div>
      <div class="col"></div>
    </div>
    <h3>{{ $t("investment.fee") }}</h3>
    <div class="row mb-3">
      <div class="col color-light-text single-col">
        {{ $t("investment.platformFee") }}
      </div>
      <div class="col">
        {{ $currValue(investment.platformFee) }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { Investment } from "@/api";
import { authorityMixin } from "@/util";

export default defineComponent({
  name: "InvestmentDetails",
  mixins: [authorityMixin],
  data() {
    return {
      investment: inject("investment") as Investment,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "src/styles/variables";
@import "src/styles/tabs";

.single-col {
  min-width: 16.5%;
  max-width: 16.5%;
}
</style>
