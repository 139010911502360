
import { defineComponent } from "vue";
import { PortalUserStatus } from "@/api";
import { Filter } from "@/views/user/portal/PortalUserList.vue";
import { InputLength } from "@/util/validation";

export default defineComponent({
  emits: ["update:portalUserFilter", "submit"],
  name: "PortalUserFilter",
  props: ["portalUserFilter"],
  data() {
    return {
      statuses: PortalUserStatus,
      isFormValid: true,
      filter: {} as Filter,
      rules: {
        fullname: [new InputLength(100)],
        email: [new InputLength(100)],
      },
    };
  },
  async mounted() {
    this.filter = this.portalUserFilter;
  },
  methods: {
    onValidate(prop: string, isVal: boolean) {
      this.isFormValid = isVal;
    },
    resetFilter() {
      this.filter = {} as Filter;
      this.$emit("update:portalUserFilter", this.filter);
      this.$emit("submit");
    },
  },
});
