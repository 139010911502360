<template>
  <div class="row">
    <div class="tab-content">
      <div class="tab-pane active">
        <router-view @update="() => this.$emit('update')"></router-view>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, inject } from "vue";
import { authorityMixin } from "@/util";
import { SettingsHistoryItem, SettingsItem } from "@/api";

export default defineComponent({
  name: "SettingsFee",
  mixins: [authorityMixin],
  provide() {
    return {
      commonFee: computed(() => this.settings.commonFee),
      commonFeeHistory: computed(() => this.settingsHistory?.commonFee ?? {}),
      investorFee: computed(() => this.settings.investorFee),
      investorFeeHistory: computed(
        () => this.settingsHistory?.investorFee ?? {}
      ),
    };
  },
  data() {
    return {
      settings: inject("settings") as SettingsItem,
      settingsHistory: inject("settingsHistory") as SettingsHistoryItem,
    };
  },
});
</script>
