
import { defineComponent, PropType } from "vue";

import { authorityMixin, PageRequest, pageSortMixin } from "@/util";
import TableFooter from "@/components/TableFooter.vue";
import { PageInvestmentSummaryItem } from "@/api";
import Status from "@/components/Status.vue";

export default defineComponent({
  name: "InvestmentSummary",
  mixins: [pageSortMixin, authorityMixin],
  components: { TableFooter, Status },
  props: {
    page: {
      type: Object as PropType<PageInvestmentSummaryItem>,
      required: true,
    },
    pageRequest: {
      type: Object as PropType<PageRequest>,
      required: true,
    },
    fetchInvestmentSummary: {
      type: Function,
      required: true,
    },
  },
  mounted() {
    this.fetchInvestmentSummary();
  },
  methods: {
    fetchData() {
      this.fetchInvestmentSummary();
    },
  },
});
