
import { defineComponent, inject } from "vue";
import { CampaignChecklist, CampaignItem } from "@/api";
import { campaignApi } from "@/resources";
import Decision from "@/components/Decision.vue";
import { isConstraintViolation, isProblem } from "@/resources/problem";
import { Campaign } from "@/util/enum/campaign";

export default defineComponent({
  name: "CampaignCollateralReview",
  computed: {
    Campaign() {
      return Campaign;
    },
  },
  components: { Decision },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      checklist: {} as CampaignChecklist,
      campaign: inject("campaign") as CampaignItem,
    };
  },
  async mounted() {
    await this.fetchChecklist();
  },
  methods: {
    async fetchChecklist() {
      this.checklist = await campaignApi.getCampaignChecklist({
        id: Number(this.id),
      });
    },
    async updateChecklistValue(field: string, value: boolean | undefined) {
      try {
        if (
          value != undefined ||
          (value == undefined && !this.checklist.reviewCompleted)
        ) {
          this.checklist = await campaignApi.updateCampaignChecklist({
            id: Number(this.id),
            campaignChecklist: { ...this.checklist, [field]: value },
          });
        }
      } catch (error) {
        if (isConstraintViolation(error)) {
          error.violations.forEach((violation) =>
            this.$notify.error(this.$t(violation.message))
          );
        } else if (isProblem(error)) {
          this.$notify.error(this.$t(error.detail));
        }
      }
    },
  },
});
