/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FieldHistoryView,
    FieldHistoryViewFromJSON,
    FieldHistoryViewFromJSONTyped,
    FieldHistoryViewToJSON,
} from './';

/**
 * 
 * @export
 * @interface InvestorLegalLimitSettingsHistoryItem
 */
export interface InvestorLegalLimitSettingsHistoryItem {
    /**
     * 
     * @type {Array<FieldHistoryView>}
     * @memberof InvestorLegalLimitSettingsHistoryItem
     */
    otherInstitutional: Array<FieldHistoryView>;
}

export function InvestorLegalLimitSettingsHistoryItemFromJSON(json: any): InvestorLegalLimitSettingsHistoryItem {
    return InvestorLegalLimitSettingsHistoryItemFromJSONTyped(json, false);
}

export function InvestorLegalLimitSettingsHistoryItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestorLegalLimitSettingsHistoryItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'otherInstitutional': ((json['otherInstitutional'] as Array<any>).map(FieldHistoryViewFromJSON)),
    };
}

export function InvestorLegalLimitSettingsHistoryItemToJSON(value?: InvestorLegalLimitSettingsHistoryItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'otherInstitutional': ((value.otherInstitutional as Array<any>).map(FieldHistoryViewToJSON)),
    };
}


