/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CampaignContentData,
    CampaignContentDataFromJSON,
    CampaignContentDataToJSON,
    CampaignContentDescriptionRequest,
    CampaignContentDescriptionRequestFromJSON,
    CampaignContentDescriptionRequestToJSON,
    CampaignContentType,
    CampaignContentTypeFromJSON,
    CampaignContentTypeToJSON,
} from '../models';

export interface CreateContentAttachmentRequest {
    id: number;
    type: CampaignContentType;
    file: Blob;
}

export interface CreateContentDescriptionRequest {
    id: number;
    type: CampaignContentType;
    campaignContentDescriptionRequest: CampaignContentDescriptionRequest;
}

export interface DeleteContentAttachmentRequest {
    id: number;
    contentId: number;
}

export interface GetContentRequest {
    id: number;
}

export interface UpdateContentAttachmentRequest {
    id: number;
    contentId: number;
    file: Blob;
}

export interface UpdateContentDescriptionRequest {
    id: number;
    contentId: number;
    campaignContentDescriptionRequest: CampaignContentDescriptionRequest;
}

/**
 * 
 */
export class BackOfficeCampaignContentControllerApi extends runtime.BaseAPI {

    /**
     */
    async createContentAttachmentRaw(requestParameters: CreateContentAttachmentRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling createContentAttachment.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling createContentAttachment.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling createContentAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/campaign/{id}/content/attachment/{type}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async createContentAttachment(requestParameters: CreateContentAttachmentRequest): Promise<number> {
        const response = await this.createContentAttachmentRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createContentDescriptionRaw(requestParameters: CreateContentDescriptionRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling createContentDescription.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling createContentDescription.');
        }

        if (requestParameters.campaignContentDescriptionRequest === null || requestParameters.campaignContentDescriptionRequest === undefined) {
            throw new runtime.RequiredError('campaignContentDescriptionRequest','Required parameter requestParameters.campaignContentDescriptionRequest was null or undefined when calling createContentDescription.');
        }

        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/{id}/content/description`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CampaignContentDescriptionRequestToJSON(requestParameters.campaignContentDescriptionRequest),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async createContentDescription(requestParameters: CreateContentDescriptionRequest): Promise<number> {
        const response = await this.createContentDescriptionRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteContentAttachmentRaw(requestParameters: DeleteContentAttachmentRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteContentAttachment.');
        }

        if (requestParameters.contentId === null || requestParameters.contentId === undefined) {
            throw new runtime.RequiredError('contentId','Required parameter requestParameters.contentId was null or undefined when calling deleteContentAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/{id}/content/attachment/{content-id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"content-id"}}`, encodeURIComponent(String(requestParameters.contentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteContentAttachment(requestParameters: DeleteContentAttachmentRequest): Promise<void> {
        await this.deleteContentAttachmentRaw(requestParameters);
    }

    /**
     */
    async getContentRaw(requestParameters: GetContentRequest): Promise<runtime.ApiResponse<Array<CampaignContentData>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getContent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/{id}/content`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CampaignContentDataFromJSON));
    }

    /**
     */
    async getContent(requestParameters: GetContentRequest): Promise<Array<CampaignContentData>> {
        const response = await this.getContentRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateContentAttachmentRaw(requestParameters: UpdateContentAttachmentRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateContentAttachment.');
        }

        if (requestParameters.contentId === null || requestParameters.contentId === undefined) {
            throw new runtime.RequiredError('contentId','Required parameter requestParameters.contentId was null or undefined when calling updateContentAttachment.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling updateContentAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/campaign/{id}/content/attachment/{content-id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"content-id"}}`, encodeURIComponent(String(requestParameters.contentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateContentAttachment(requestParameters: UpdateContentAttachmentRequest): Promise<void> {
        await this.updateContentAttachmentRaw(requestParameters);
    }

    /**
     */
    async updateContentDescriptionRaw(requestParameters: UpdateContentDescriptionRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateContentDescription.');
        }

        if (requestParameters.contentId === null || requestParameters.contentId === undefined) {
            throw new runtime.RequiredError('contentId','Required parameter requestParameters.contentId was null or undefined when calling updateContentDescription.');
        }

        if (requestParameters.campaignContentDescriptionRequest === null || requestParameters.campaignContentDescriptionRequest === undefined) {
            throw new runtime.RequiredError('campaignContentDescriptionRequest','Required parameter requestParameters.campaignContentDescriptionRequest was null or undefined when calling updateContentDescription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/{id}/content/description/{content-id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"content-id"}}`, encodeURIComponent(String(requestParameters.contentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CampaignContentDescriptionRequestToJSON(requestParameters.campaignContentDescriptionRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateContentDescription(requestParameters: UpdateContentDescriptionRequest): Promise<void> {
        await this.updateContentDescriptionRaw(requestParameters);
    }

}
