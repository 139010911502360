
import { defineComponent, PropType } from "vue";
import { isConstraintViolation, isProblem } from "@/resources/problem";
import {
  BackOfficeFlowEntityStateCampaignStatus,
  CampaignItem,
  QAChangeStatusRequest,
} from "@/api";
import { qaCampaignApi } from "@/resources";
import { ElementForm, InputLength, InputRequired } from "@/util/validation";

export default defineComponent({
  name: "QAChangeCampaignStatusDialog",
  emits: ["update"],
  props: {
    campaign: {
      type: Object as PropType<CampaignItem>,
      required: true,
    },
    dialogDisabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dialogVisible: false,
      isFormValid: true,
      form: {} as QAChangeStatusRequest,
      qaState: {} as BackOfficeFlowEntityStateCampaignStatus,
      rules: {
        status: [new InputRequired()],
        reason: [
          new InputLength(255),
          new InputRequired("validation.inputRequired"),
        ],
      },
    };
  },
  methods: {
    async fetchQAState() {
      this.qaState = await qaCampaignApi.stateQA({
        id: this.campaign.id,
      });
    },
    async open() {
      this.dialogVisible = true;
      await this.fetchQAState();
    },
    cancel() {
      this.dialogVisible = false;
      this.form = {} as QAChangeStatusRequest;
    },
    onValidate(prop: string, isVal: boolean) {
      this.isFormValid = isVal;
    },
    async submit() {
      const form = this.$refs["form"] as ElementForm;
      if (await form.validate()) {
        try {
          await qaCampaignApi.changeStatusQA({
            id: this.campaign.id,
            qAChangeStatusRequest: this.form,
          });
          this.$notify.info("Campaign status was changed.");
        } catch (e) {
          if (isConstraintViolation(e)) {
            e.violations.forEach((violation) =>
              this.$notify.error(this.$t(violation.message))
            );
          } else if (isProblem(e)) {
            this.$notify.error(this.$t(e.detail));
          }
        }
        this.$emit("update");
        this.cancel();
      }
    },
  },
});
