
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "BackLink",
  setup() {
    const hasHistory = ref(history.state?.back != null);
    useRouter().afterEach(
      () => (hasHistory.value = history.state.back != null)
    );
    return { hasHistory };
  },
  methods: {
    goBack() {
      if (history.state.back) {
        return this.$router.back();
      }
    },
  },
});
