import {
  AccountControllerApi,
  AuthControllerApi,
  BackOfficeCampaignControllerApi,
  BackOfficeCampaignOperationControllerApi,
  BackOfficeBusinessControllerApi,
  Configuration,
  ConfigurationParameters,
  DealControllerApi,
  FeatureControllerApi,
  InvestorControllerApi,
  QaCampaignControllerApi,
  RoleControllerApi,
  TokenResponseToJSON,
  UserControllerApi,
  BackofficeInvestmentControllerApi,
  TransactionControllerApi,
  BackOfficePreScreeningControllerApi,
  BackOfficePreScreeningOperationControllerApi,
  BackOfficeSettingsControllerApi,
  BackOfficePortalUserControllerApi,
  BackOfficeAccreditationControllerApi,
  BackOfficeAccreditationOperationControllerApi,
  BackOfficeCampaignContentControllerApi,
  BackOfficeBatchControllerApi,
  QaTransactionControllerApi,
} from "@/api";
import { authProblemMiddleware, problemMiddleware } from "@/resources/problem";
import store from "@/store";
import { key } from "@/store/account";

const parameters: ConfigurationParameters = {
  basePath: process.env.VUE_APP_BACKOFFICE_PATH,
  middleware: [problemMiddleware],
  accessToken: () => validateAndGetToken(),
};

const authParameters: ConfigurationParameters = {
  basePath: process.env.VUE_APP_BACKOFFICE_PATH,
  middleware: [authProblemMiddleware],
};

const configuration = new Configuration(parameters);

const authConfiguration = new Configuration(authParameters);

export const accountApi = new AccountControllerApi(configuration);
export const authApi = new AuthControllerApi(authConfiguration);
export const featureApi = new FeatureControllerApi(configuration);
export const campaignApi = new BackOfficeCampaignControllerApi(configuration);
export const campaignContentApi = new BackOfficeCampaignContentControllerApi(
  configuration
);
export const campaignOperationApi = new BackOfficeCampaignOperationControllerApi(
  configuration
);
export const businessApi = new BackOfficeBusinessControllerApi(configuration);
export const qaCampaignApi = new QaCampaignControllerApi(configuration);
export const roleApi = new RoleControllerApi(configuration);
export const userApi = new UserControllerApi(configuration);
export const portalUserApi = new BackOfficePortalUserControllerApi(
  configuration
);
export const investorApi = new InvestorControllerApi(configuration);
export const investmentApi = new BackofficeInvestmentControllerApi(
  configuration
);
export const dealApi = new DealControllerApi(configuration);
export const transactionApi = new TransactionControllerApi(configuration);
export const qaTransactionApi = new QaTransactionControllerApi(configuration);
export const batchApi = new BackOfficeBatchControllerApi(configuration);
export const preScreeningApi = new BackOfficePreScreeningControllerApi(
  configuration
);
export const preScreeningOperationApi = new BackOfficePreScreeningOperationControllerApi(
  configuration
);
export const accreditationApi = new BackOfficeAccreditationControllerApi(
  configuration
);
export const accreditationOperationApi = new BackOfficeAccreditationOperationControllerApi(
  configuration
);
export const settingsApi = new BackOfficeSettingsControllerApi(configuration);

const lastRequestTime = "lastRequestTime";
const maxInactivityTimeInMin = 30;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function validateAndGetToken() {
  const token = store.state.account.token;
  const now = Date.now();
  if (token != null && +token?.expireAt < now && +token.refreshExpireAt < now) {
    doLogout();
    return "";
  }
  const maxInactiveTime = new Date();
  maxInactiveTime.setMinutes(
    maxInactiveTime.getMinutes() - maxInactivityTimeInMin
  );
  const lastRequestAt = getLastRequestTime();
  if (lastRequestAt < +maxInactiveTime) {
    console.log("reached inactivity time");
    doLogout();
    return "";
  }
  setLastRequestTime();
  if (token != null && +token?.expireAt < now && +token.refreshExpireAt > now) {
    console.log("do token refresh");
    store.state.account.token = await authApi.refresh({
      refreshTokenRequest: { refreshToken: token.refreshToken },
    });
    localStorage.setItem(
      key,
      JSON.stringify(TokenResponseToJSON(store.state.account.token))
    );
  }
  return store.state.account.token?.idToken as string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function setLastRequestTime() {
  sessionStorage.setItem(lastRequestTime, JSON.stringify(Date.now()));
}

function getLastRequestTime(): number {
  const lastRequestAt = sessionStorage.getItem(lastRequestTime);
  if (lastRequestAt == null) {
    return Date.now();
  }
  return parseInt(lastRequestAt);
}

function doLogout() {
  console.log("do logout");
  sessionStorage.removeItem(lastRequestTime);
  localStorage.removeItem(key);
  delete store.state.account.token;
}
