
import { computed, defineComponent } from "vue";
import { authorityMixin } from "@/util";
import { businessApi } from "@/resources";
import { watchBusinessUpdate } from "@/util/ws";
import { BusinessItem, LegalCommerceItem } from "@/api";

export default defineComponent({
  name: "CampaignBusiness",
  mixins: [authorityMixin],
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  provide() {
    return {
      businessId: computed(() => this.businessId),
      legal: computed(() => (this.business.legal ? this.business.legal : {})),
      address: computed(() =>
        this.business.address ? this.business.address : {}
      ),
      finance: computed(() =>
        this.business.finance ? this.business.finance : {}
      ),
      commerce: computed(() => (this.commerce.commerce ? this.commerce : {})),
    };
  },
  data() {
    return {
      campaignBusinessUpdatedSubscription: watchBusinessUpdate(
        Number(this.businessId),
        () => {
          this.$notify.info(this.$t("campaign.businessUpdated"));
          this.fetchData(this.businessId);
        }
      ),
      business: {} as BusinessItem,
      commerce: {} as LegalCommerceItem,
    };
  },
  async mounted() {
    await Promise.all([this.fetchData()]);
  },
  async beforeRouteLeave() {
    this.campaignBusinessUpdatedSubscription.unsubscribe();
  },
  methods: {
    async fetchData(id?: number) {
      id = id ?? Number(this.businessId);
      await Promise.all([
        this.fetchBusiness(id),
        this.fetchBusinessCommerce(id),
      ]);
    },
    async fetchBusiness(id: number) {
      this.business = await businessApi.business({
        id: id,
      });
    },
    async fetchBusinessCommerce(id: number) {
      this.commerce = await businessApi.commerce({
        id: id,
      });
    },
  },
});
