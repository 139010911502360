
import { defineComponent } from "vue";
import { mask } from "maska";
import {
  Country,
  countries,
  otherCountries,
} from "@/components/MaskedPhoneNumber/countries";
import { parsePhoneNumberFromString } from "libphonenumber-js/max";

export interface CountryData {
  flag: string;
  mask: string | string[];
  iso: string;
  placeholder: string;
}

export default defineComponent({
  emits: ["update:modelValue"],
  props: ["modelValue", "isoCountry", "homePhonesCountries"],
  data() {
    return {
      mobilePhone: this.modelValue,
      skipWatcher: false,
      showCountriesList: false,
      isError: false,
      countries: this.homePhonesCountries
        ? this.homePhonesCountries
        : countries,
      selectedCountryData: {} as CountryData,
      dynamicCountryData: {} as CountryData,
    };
  },
  created() {
    window.addEventListener("click", this.closeByClick);
    const defaultCountry: Country = this.countries.find(
      (c: Country) => c.iso === this.isoCountry
    ) as Country;
    const phoneCountry: Country = this.countries.find((c: Country) =>
      ("+" + this.mobilePhone).startsWith(c.code)
    ) as Country;
    if (defaultCountry) this.selectCountry(phoneCountry as Country);
    if (phoneCountry) {
      this.selectCountry(phoneCountry as Country);
    }
  },
  methods: {
    openCountriesList() {
      this.showCountriesList = true;
    },
    selectCountry(country: Country) {
      this.selectedCountryData = this.toCountryData(country);
      this.dynamicCountryData = this.selectedCountryData;
    },
    toCountryData(country: Country, code?: string): CountryData {
      const countryCode = code ? code : country.code;
      const mask =
        typeof country.mask === "string"
          ? `${countryCode} ${country.mask}`
          : country.mask.map((countryMask) => `${countryCode} ${countryMask}`);
      const placeholder = (typeof mask === "string" ? mask : mask[0]).replace(
        /[#]/g,
        "_"
      );
      return {
        iso: country.iso,
        flag: country.flag,
        mask: mask,
        placeholder: placeholder,
      } as CountryData;
    },
    changeCountry(country: Country) {
      if (this.selectedCountryData.iso !== country.iso) {
        this.mobilePhone = "";
        this.selectCountry(country);
      }
      this.showCountriesList = false;
    },
    closeByClick(e: MouseEvent) {
      if (!this.$el.contains(e.target)) {
        this.showCountriesList = false;
      }
    },
    selectOtherCountry(phone: string) {
      if (this.selectedCountryData.iso === "OTHER") {
        const parsedPhoneNumber = parsePhoneNumberFromString(phone);
        if (parsedPhoneNumber) {
          const candidateCountry = otherCountries.find(
            (country) => country.iso === parsedPhoneNumber?.country
          );
          this.dynamicCountryData = candidateCountry
            ? this.toCountryData(
                candidateCountry,
                "+" + "#".repeat(candidateCountry.code.length - 1)
              )
            : this.selectedCountryData;
        } else {
          this.dynamicCountryData = this.selectedCountryData;
        }
      }
    },
  },
  watch: {
    mobilePhone(phone: string) {
      this.skipWatcher = true;
      const parsedPhoneNumber = parsePhoneNumberFromString(phone);
      this.isError =
        parsedPhoneNumber === undefined || !parsedPhoneNumber.isValid();
      if (!this.isError) {
        this.$emit(
          "update:modelValue",
          mask(phone, "###############", undefined, false)
        );
      } else {
        this.$emit(
          "update:modelValue",
          mask("", "###############", undefined, false)
        );
      }
    },
    modelValue(newValue: string) {
      if (newValue == undefined) {
        this.mobilePhone = "";
      }
      if (this.skipWatcher) {
        this.skipWatcher = false;
      } else {
        if (newValue != undefined) {
          this.mobilePhone = mask(newValue, "+### ####-####", undefined);
        }
      }
    },
  },
  unmounted() {
    window.removeEventListener("click", this.closeByClick);
  },
});
