
import { defineComponent, PropType } from "vue";
import { PermanentAddress } from "@/api";

export default defineComponent({
  name: "PermanentAddress",
  props: {
    permanentAddress: {
      type: Object as PropType<PermanentAddress>,
      required: true,
    },
  },
});
