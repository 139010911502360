import { PortalUserStatus } from "@/api";
import { i18n } from "@/util";

export class PortalUser {
  static status(status: PortalUserStatus): string {
    return status
      ? i18n.global.t("user.userStatus." + status.toLowerCase())
      : "-";
  }

  static statusColor(status: PortalUserStatus): string {
    switch (status) {
      case PortalUserStatus.ACTIVE:
        return "#70B603";
      case PortalUserStatus.LOCKED:
        return "#b60303";
      case PortalUserStatus.SUSPENDED:
        return "#e75900";
      case PortalUserStatus.INACTIVE:
      default:
        return "#7f7f7f";
    }
  }
}
