<template>
  <div class="container ms-0 mb-0" v-if="finance">
    <LegalFinance v-model:finance="finance" />
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { LegalFinanceItem } from "@/api";
import LegalFinance from "@/views/components/LegalFinance.vue";
import { investorApi } from "@/resources";

export default defineComponent({
  name: "InvestorFinancialInfo",
  components: { LegalFinance },
  async mounted() {
    await this.fetchFinance();
  },
  data() {
    return {
      finance: {} as LegalFinanceItem,
      investorId: inject("investorId") as number,
    };
  },
  methods: {
    async fetchFinance() {
      this.finance = await investorApi.investorFinance({
        id: this.investorId,
      });
    },
  },
});
</script>
