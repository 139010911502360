
import { defineComponent, inject } from "vue";
import { Deal, PageInvestmentSummaryItem } from "@/api";
import { authorityMixin, PageRequest } from "@/util";
import { dealApi } from "@/resources";
import InvestmentSummary from "@/views/components/InvestmentSummary.vue";

export default defineComponent({
  name: "DealDetails",
  emits: ["update"],
  mixins: [authorityMixin],
  components: { InvestmentSummary },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      deal: inject("deal") as Deal,
      investmentSummaryPage: {} as PageInvestmentSummaryItem,
      investmentSummaryPageRequest: {} as PageRequest,
    };
  },
  methods: {
    async fetchDeal() {
      let deal = await dealApi.deal({
        id: Number(this.id),
      });
      Object.assign(this.application, deal);
    },
    async fetchInvestmentSummary() {
      this.investmentSummaryPage = await dealApi.dealInvestments({
        id: Number(this.id),
        ...this.investmentSummaryPageRequest,
      });
    },
  },
});
