
import { defineComponent } from "vue";
import { qaTransactionApi, transactionApi } from "@/resources";
import Headline from "@/components/Headline.vue";
import {
  PageTransactionListItem,
  TransactionType,
  TransactionStatus,
  BackOfficeCompleteTransactionRequest,
} from "@/api";
import {
  authorityMixin,
  fixLocalDate,
  PageRequest,
  pageSortMixin,
} from "@/util";
import TableFooter from "@/components/TableFooter.vue";
import TransactionFilter from "@/views/transaction/TransactionFilter.vue";
import Status from "@/components/Status.vue";
import CompleteTransactionDialog from "@/views/transaction/dialogs/CompleteTransactionDialog.vue";
import { mapState } from "vuex";

export interface Filter {
  campaignId: number | undefined;
  investmentId: number | undefined;
  transactionTypes: TransactionType[];
  transactionStatuses: TransactionStatus[];
  startDateFrom: Date | undefined;
  startDateTo: Date | undefined;
  effectiveDateFrom: Date | undefined;
  effectiveDateTo: Date | undefined;
  amountFrom: number | undefined;
  amountTo: number | undefined;
}

export default defineComponent({
  name: "TransactionList",
  components: {
    CompleteTransactionDialog,
    TransactionFilter,
    Status,
    Headline,
    TableFooter,
  },
  mixins: [pageSortMixin, authorityMixin],
  data: () => ({
    transactionPage: {} as PageTransactionListItem,
    pageRequest: {} as PageRequest,
    filterVisible: false,
    filter: {} as Filter,
  }),
  mounted() {
    this.fetchData();
  },
  computed: {
    ...mapState("feature", ["qaEnabled"]),
  },
  methods: {
    async fetchData() {
      this.filter.startDateFrom = this.filter.startDateFrom
        ? fixLocalDate(this.filter.startDateFrom)
        : undefined;
      this.filter.startDateTo = this.filter.startDateTo
        ? fixLocalDate(this.filter.startDateTo)
        : undefined;
      this.filter.effectiveDateFrom = this.filter.effectiveDateFrom
        ? fixLocalDate(this.filter.effectiveDateFrom)
        : undefined;
      this.filter.effectiveDateTo = this.filter.effectiveDateTo
        ? fixLocalDate(this.filter.effectiveDateTo)
        : undefined;

      this.transactionPage = await transactionApi.transactions({
        ...this.pageRequest,
        ...this.filter,
      });
    },
    async completeTransactionManually(
      form: BackOfficeCompleteTransactionRequest
    ) {
      return await transactionApi.completeTransactionManually({
        backOfficeCompleteTransactionRequest: form,
      });
    },
    async completeTransactionQA(form: BackOfficeCompleteTransactionRequest) {
      return await qaTransactionApi.completeTransaction({
        backOfficeCompleteTransactionRequest: form,
      });
    },
  },
});
