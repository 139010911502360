/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AccountDetails,
    AccountDetailsFromJSON,
    AccountDetailsToJSON,
    AccountDetailsForm,
    AccountDetailsFormFromJSON,
    AccountDetailsFormToJSON,
    PasswordChangeForm,
    PasswordChangeFormFromJSON,
    PasswordChangeFormToJSON,
    Profile,
    ProfileFromJSON,
    ProfileToJSON,
    TokenResponse,
    TokenResponseFromJSON,
    TokenResponseToJSON,
} from '../models';

export interface ChangePasswordRequest {
    passwordChangeForm: PasswordChangeForm;
}

export interface UpdateAccountRequest {
    accountDetailsForm: AccountDetailsForm;
}

/**
 * 
 */
export class AccountControllerApi extends runtime.BaseAPI {

    /**
     */
    async accountRaw(): Promise<runtime.ApiResponse<AccountDetails>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/account`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountDetailsFromJSON(jsonValue));
    }

    /**
     */
    async account(): Promise<AccountDetails> {
        const response = await this.accountRaw();
        return await response.value();
    }

    /**
     */
    async changePasswordRaw(requestParameters: ChangePasswordRequest): Promise<runtime.ApiResponse<TokenResponse>> {
        if (requestParameters.passwordChangeForm === null || requestParameters.passwordChangeForm === undefined) {
            throw new runtime.RequiredError('passwordChangeForm','Required parameter requestParameters.passwordChangeForm was null or undefined when calling changePassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/account/password/change`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PasswordChangeFormToJSON(requestParameters.passwordChangeForm),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenResponseFromJSON(jsonValue));
    }

    /**
     */
    async changePassword(requestParameters: ChangePasswordRequest): Promise<TokenResponse> {
        const response = await this.changePasswordRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async profileRaw(): Promise<runtime.ApiResponse<Profile>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/account/profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileFromJSON(jsonValue));
    }

    /**
     */
    async profile(): Promise<Profile> {
        const response = await this.profileRaw();
        return await response.value();
    }

    /**
     */
    async updateAccountRaw(requestParameters: UpdateAccountRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.accountDetailsForm === null || requestParameters.accountDetailsForm === undefined) {
            throw new runtime.RequiredError('accountDetailsForm','Required parameter requestParameters.accountDetailsForm was null or undefined when calling updateAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/account`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AccountDetailsFormToJSON(requestParameters.accountDetailsForm),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateAccount(requestParameters: UpdateAccountRequest): Promise<void> {
        await this.updateAccountRaw(requestParameters);
    }

}
