/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AttachmentListItem,
    AttachmentListItemFromJSON,
    AttachmentListItemToJSON,
    BackOfficeFlowEntityStatePreScreeningStatus,
    BackOfficeFlowEntityStatePreScreeningStatusFromJSON,
    BackOfficeFlowEntityStatePreScreeningStatusToJSON,
    Country,
    CountryFromJSON,
    CountryToJSON,
    NoteItem,
    NoteItemFromJSON,
    NoteItemToJSON,
    NoteRequest,
    NoteRequestFromJSON,
    NoteRequestToJSON,
    PageNoteItem,
    PageNoteItemFromJSON,
    PageNoteItemToJSON,
    PagePreScreeningListItem,
    PagePreScreeningListItemFromJSON,
    PagePreScreeningListItemToJSON,
    PreScreeningDuplicateStatus,
    PreScreeningDuplicateStatusFromJSON,
    PreScreeningDuplicateStatusToJSON,
    PreScreeningItem,
    PreScreeningItemFromJSON,
    PreScreeningItemToJSON,
    PreScreeningNoteType,
    PreScreeningNoteTypeFromJSON,
    PreScreeningNoteTypeToJSON,
    PreScreeningQuizItem,
    PreScreeningQuizItemFromJSON,
    PreScreeningQuizItemToJSON,
    PreScreeningStatus,
    PreScreeningStatusFromJSON,
    PreScreeningStatusToJSON,
} from '../models';

export interface CreatePreScreeningNoteRequest {
    id: number;
    type: PreScreeningNoteType;
    noteRequest: NoteRequest;
}

export interface DeletePreScreeningAttachmentRequest {
    id: number;
    attachmentId: number;
}

export interface DeletePreScreeningNoteRequest {
    id: number;
}

export interface GetPreScreeningAttachmentsRequest {
    id: number;
}

export interface GetPreScreeningStateRequest {
    id: number;
}

export interface PreScreeningRequest {
    id: number;
}

export interface PreScreeningAttachmentRequest {
    id: number;
    attachmentId: number;
}

export interface PreScreeningNotesRequest {
    id: number;
    type: PreScreeningNoteType;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface PreScreeningQuizRequest {
    id: number;
}

export interface PreScreeningsRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
    companyName?: string;
    companyRegistrationCountry?: Country;
    status?: PreScreeningStatus;
    duplicateStatus?: PreScreeningDuplicateStatus;
    contactName?: string;
    contactEmail?: string;
    createdAtFrom?: Date;
    createdAtTo?: Date;
}

export interface UpdatePreScreeningNoteRequest {
    id: number;
    noteRequest: NoteRequest;
}

export interface UploadPreScreeningAttachmentRequest {
    id: number;
    file: Blob;
}

/**
 * 
 */
export class BackOfficePreScreeningControllerApi extends runtime.BaseAPI {

    /**
     */
    async createPreScreeningNoteRaw(requestParameters: CreatePreScreeningNoteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling createPreScreeningNote.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling createPreScreeningNote.');
        }

        if (requestParameters.noteRequest === null || requestParameters.noteRequest === undefined) {
            throw new runtime.RequiredError('noteRequest','Required parameter requestParameters.noteRequest was null or undefined when calling createPreScreeningNote.');
        }

        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/pre/screening/{id}/notes`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NoteRequestToJSON(requestParameters.noteRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createPreScreeningNote(requestParameters: CreatePreScreeningNoteRequest): Promise<void> {
        await this.createPreScreeningNoteRaw(requestParameters);
    }

    /**
     */
    async deletePreScreeningAttachmentRaw(requestParameters: DeletePreScreeningAttachmentRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletePreScreeningAttachment.');
        }

        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId','Required parameter requestParameters.attachmentId was null or undefined when calling deletePreScreeningAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/pre/screening/{id}/attachments/{attachmentId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deletePreScreeningAttachment(requestParameters: DeletePreScreeningAttachmentRequest): Promise<void> {
        await this.deletePreScreeningAttachmentRaw(requestParameters);
    }

    /**
     */
    async deletePreScreeningNoteRaw(requestParameters: DeletePreScreeningNoteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletePreScreeningNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/pre/screening/notes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deletePreScreeningNote(requestParameters: DeletePreScreeningNoteRequest): Promise<void> {
        await this.deletePreScreeningNoteRaw(requestParameters);
    }

    /**
     */
    async getPreScreeningAttachmentsRaw(requestParameters: GetPreScreeningAttachmentsRequest): Promise<runtime.ApiResponse<Array<AttachmentListItem>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPreScreeningAttachments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/pre/screening/{id}/attachments`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AttachmentListItemFromJSON));
    }

    /**
     */
    async getPreScreeningAttachments(requestParameters: GetPreScreeningAttachmentsRequest): Promise<Array<AttachmentListItem>> {
        const response = await this.getPreScreeningAttachmentsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getPreScreeningStateRaw(requestParameters: GetPreScreeningStateRequest): Promise<runtime.ApiResponse<BackOfficeFlowEntityStatePreScreeningStatus>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPreScreeningState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/pre/screening/{id}/state`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BackOfficeFlowEntityStatePreScreeningStatusFromJSON(jsonValue));
    }

    /**
     */
    async getPreScreeningState(requestParameters: GetPreScreeningStateRequest): Promise<BackOfficeFlowEntityStatePreScreeningStatus> {
        const response = await this.getPreScreeningStateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async preScreeningRaw(requestParameters: PreScreeningRequest): Promise<runtime.ApiResponse<PreScreeningItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling preScreening.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/pre/screening/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PreScreeningItemFromJSON(jsonValue));
    }

    /**
     */
    async preScreening(requestParameters: PreScreeningRequest): Promise<PreScreeningItem> {
        const response = await this.preScreeningRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async preScreeningAttachmentRaw(requestParameters: PreScreeningAttachmentRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling preScreeningAttachment.');
        }

        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId','Required parameter requestParameters.attachmentId was null or undefined when calling preScreeningAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/pre/screening/{id}/attachments/{attachmentId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async preScreeningAttachment(requestParameters: PreScreeningAttachmentRequest): Promise<Blob> {
        const response = await this.preScreeningAttachmentRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async preScreeningNotesRaw(requestParameters: PreScreeningNotesRequest): Promise<runtime.ApiResponse<PageNoteItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling preScreeningNotes.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling preScreeningNotes.');
        }

        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/pre/screening/{id}/notes`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageNoteItemFromJSON(jsonValue));
    }

    /**
     */
    async preScreeningNotes(requestParameters: PreScreeningNotesRequest): Promise<PageNoteItem> {
        const response = await this.preScreeningNotesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async preScreeningQuizRaw(requestParameters: PreScreeningQuizRequest): Promise<runtime.ApiResponse<PreScreeningQuizItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling preScreeningQuiz.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/pre/screening/{id}/quiz`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PreScreeningQuizItemFromJSON(jsonValue));
    }

    /**
     */
    async preScreeningQuiz(requestParameters: PreScreeningQuizRequest): Promise<PreScreeningQuizItem> {
        const response = await this.preScreeningQuizRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async preScreeningsRaw(requestParameters: PreScreeningsRequest): Promise<runtime.ApiResponse<PagePreScreeningListItem>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.companyName !== undefined) {
            queryParameters['companyName'] = requestParameters.companyName;
        }

        if (requestParameters.companyRegistrationCountry !== undefined) {
            queryParameters['companyRegistrationCountry'] = requestParameters.companyRegistrationCountry;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.duplicateStatus !== undefined) {
            queryParameters['duplicateStatus'] = requestParameters.duplicateStatus;
        }

        if (requestParameters.contactName !== undefined) {
            queryParameters['contactName'] = requestParameters.contactName;
        }

        if (requestParameters.contactEmail !== undefined) {
            queryParameters['contactEmail'] = requestParameters.contactEmail;
        }

        if (requestParameters.createdAtFrom !== undefined) {
            queryParameters['createdAtFrom'] = (requestParameters.createdAtFrom as any).toISOString().substr(0,10);
        }

        if (requestParameters.createdAtTo !== undefined) {
            queryParameters['createdAtTo'] = (requestParameters.createdAtTo as any).toISOString().substr(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/pre/screening`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PagePreScreeningListItemFromJSON(jsonValue));
    }

    /**
     */
    async preScreenings(requestParameters: PreScreeningsRequest): Promise<PagePreScreeningListItem> {
        const response = await this.preScreeningsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updatePreScreeningNoteRaw(requestParameters: UpdatePreScreeningNoteRequest): Promise<runtime.ApiResponse<NoteItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePreScreeningNote.');
        }

        if (requestParameters.noteRequest === null || requestParameters.noteRequest === undefined) {
            throw new runtime.RequiredError('noteRequest','Required parameter requestParameters.noteRequest was null or undefined when calling updatePreScreeningNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/pre/screening/notes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: NoteRequestToJSON(requestParameters.noteRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NoteItemFromJSON(jsonValue));
    }

    /**
     */
    async updatePreScreeningNote(requestParameters: UpdatePreScreeningNoteRequest): Promise<NoteItem> {
        const response = await this.updatePreScreeningNoteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async uploadPreScreeningAttachmentRaw(requestParameters: UploadPreScreeningAttachmentRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling uploadPreScreeningAttachment.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadPreScreeningAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/pre/screening/{id}/attachments`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async uploadPreScreeningAttachment(requestParameters: UploadPreScreeningAttachmentRequest): Promise<void> {
        await this.uploadPreScreeningAttachmentRaw(requestParameters);
    }

}
