/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CampaignContentType,
    CampaignContentTypeFromJSON,
    CampaignContentTypeFromJSONTyped,
    CampaignContentTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface CampaignContentTypeData
 */
export interface CampaignContentTypeData {
    /**
     * 
     * @type {boolean}
     * @memberof CampaignContentTypeData
     */
    hasAttach: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignContentTypeData
     */
    hasDescription: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignContentTypeData
     */
    isSingleton: boolean;
    /**
     * 
     * @type {CampaignContentType}
     * @memberof CampaignContentTypeData
     */
    type: CampaignContentType;
}

export function CampaignContentTypeDataFromJSON(json: any): CampaignContentTypeData {
    return CampaignContentTypeDataFromJSONTyped(json, false);
}

export function CampaignContentTypeDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CampaignContentTypeData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hasAttach': json['hasAttach'],
        'hasDescription': json['hasDescription'],
        'isSingleton': json['isSingleton'],
        'type': CampaignContentTypeFromJSON(json['type']),
    };
}

export function CampaignContentTypeDataToJSON(value?: CampaignContentTypeData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hasAttach': value.hasAttach,
        'hasDescription': value.hasDescription,
        'isSingleton': value.isSingleton,
        'type': CampaignContentTypeToJSON(value.type),
    };
}


