<template>
  <div class="container ms-0 mb-0" v-if="legal.name">
    <LegalGeneralInfo v-model:legal="legal" />
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { Legal } from "@/api";
import LegalGeneralInfo from "@/views/components/LegalGeneralInfo.vue";

export default defineComponent({
  name: "BusinessGeneralInfo",
  components: { LegalGeneralInfo },
  data() {
    return {
      legal: inject("legal") as Legal,
    };
  },
});
</script>
