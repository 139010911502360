
import { defineComponent } from "vue";
import { userApi } from "@/resources";
import Headline from "@/components/Headline.vue";
import UserFilter from "@/views/user/backoffice/UserFilter.vue";
import { PageUserListItem } from "@/api";
import { authorityMixin, PageRequest, pageSortMixin } from "@/util";
import TableFooter from "@/components/TableFooter.vue";
import { UserListItem } from "@/api";
import Status from "@/components/Status.vue";

export interface Filter {
  fullname: string;
  residenceCountries: string[];
  roles: number[];
  statuses: string[];
}

export default defineComponent({
  name: "UserList",
  components: { Headline, Status, TableFooter, UserFilter },
  mixins: [pageSortMixin, authorityMixin],
  data: () => ({
    userPage: {} as PageUserListItem,
    pageRequest: {} as PageRequest,
    filterVisible: false,
    filter: {} as Filter,
  }),
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.userPage = await userApi.users({
        ...this.pageRequest,
        ...this.filter,
      });
    },
    onCellClick(row: UserListItem) {
      this.$router.push({ name: "user", params: { id: row.id } });
    },
  },
});
