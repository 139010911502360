/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CampaignDetailsItem
 */
export interface CampaignDetailsItem {
    /**
     * 
     * @type {Set<string>}
     * @memberof CampaignDetailsItem
     */
    businessModels?: Set<string>;
    /**
     * 
     * @type {string}
     * @memberof CampaignDetailsItem
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignDetailsItem
     */
    editAvailable?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CampaignDetailsItem
     */
    fundraiserFoundingYear?: number;
    /**
     * 
     * @type {string}
     * @memberof CampaignDetailsItem
     */
    fundraiserWebsite?: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof CampaignDetailsItem
     */
    highlights?: Set<string>;
    /**
     * 
     * @type {Set<string>}
     * @memberof CampaignDetailsItem
     */
    impacts?: Set<string>;
    /**
     * 
     * @type {string}
     * @memberof CampaignDetailsItem
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof CampaignDetailsItem
     */
    previouslyFundedAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignDetailsItem
     */
    priceForShare?: number;
    /**
     * 
     * @type {string}
     * @memberof CampaignDetailsItem
     */
    securityType?: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof CampaignDetailsItem
     */
    technologies?: Set<string>;
    /**
     * 
     * @type {number}
     * @memberof CampaignDetailsItem
     */
    valuation?: number;
}

export function CampaignDetailsItemFromJSON(json: any): CampaignDetailsItem {
    return CampaignDetailsItemFromJSONTyped(json, false);
}

export function CampaignDetailsItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): CampaignDetailsItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'businessModels': !exists(json, 'businessModels') ? undefined : json['businessModels'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'editAvailable': !exists(json, 'editAvailable') ? undefined : json['editAvailable'],
        'fundraiserFoundingYear': !exists(json, 'fundraiserFoundingYear') ? undefined : json['fundraiserFoundingYear'],
        'fundraiserWebsite': !exists(json, 'fundraiserWebsite') ? undefined : json['fundraiserWebsite'],
        'highlights': !exists(json, 'highlights') ? undefined : json['highlights'],
        'impacts': !exists(json, 'impacts') ? undefined : json['impacts'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'previouslyFundedAmount': !exists(json, 'previouslyFundedAmount') ? undefined : json['previouslyFundedAmount'],
        'priceForShare': !exists(json, 'priceForShare') ? undefined : json['priceForShare'],
        'securityType': !exists(json, 'securityType') ? undefined : json['securityType'],
        'technologies': !exists(json, 'technologies') ? undefined : json['technologies'],
        'valuation': !exists(json, 'valuation') ? undefined : json['valuation'],
    };
}

export function CampaignDetailsItemToJSON(value?: CampaignDetailsItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'businessModels': value.businessModels,
        'description': value.description,
        'editAvailable': value.editAvailable,
        'fundraiserFoundingYear': value.fundraiserFoundingYear,
        'fundraiserWebsite': value.fundraiserWebsite,
        'highlights': value.highlights,
        'impacts': value.impacts,
        'name': value.name,
        'previouslyFundedAmount': value.previouslyFundedAmount,
        'priceForShare': value.priceForShare,
        'securityType': value.securityType,
        'technologies': value.technologies,
        'valuation': value.valuation,
    };
}


