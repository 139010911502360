
import { defineComponent, inject } from "vue";
import { PreScreeningItem } from "@/api";

export default defineComponent({
  name: "PreScreeningGeneralInfo",
  data() {
    return {
      preScreening: inject("preScreening") as PreScreeningItem,
    };
  },
});
