
import { defineComponent, PropType } from "vue";
import { Legal } from "@/api";

export default defineComponent({
  name: "LegalContact",
  props: {
    legal: {
      type: Object as PropType<Legal>,
      required: true,
    },
  },
});
