<template v-if="this.item !== undefined">
  <div class="content-header color-light-text">
    {{ $t("campaign.tabs.content.labels." + this.item.type.type) }}
  </div>
  <div class="row">
    <div class="col-md-5" v-if="this.item.type.hasDescription">
      <el-form :model="form" :rules="rules" status-icon ref="form">
        <el-form-item prop="description">
          <div class="row" v-if="this.item.description || editable">
            <el-input
              :disabled="!editable"
              v-model="form.description"
              type="textarea"
              :maxlength="2000"
              show-word-limit
              :autosize="{ minRows: 2, maxRows: 10 }"
              resize="vertical"
            ></el-input>
          </div>
          <div class="row" v-else>
            <pre>-</pre>
          </div>
        </el-form-item>
        <br />
        <el-button
          @click="edit"
          v-show="!editable && campaignIsEditable"
          v-if="hasAuthority(authorities.CAMPAIGN_WRITE)"
        >
          {{ $t("edit") }}
        </el-button>
        <el-button
          style="margin-left: 0"
          @click="cancel"
          v-show="editable && campaignIsEditable"
        >
          {{ $t("cancel") }}
        </el-button>
        <el-button
          type="primary"
          v-show="editable && campaignIsEditable"
          :disabled="!this.form.description || !isFormValid"
          @click="submit"
        >
          {{ $t("save") }}
        </el-button>
      </el-form>
    </div>
    <div class="col-md-5" v-if="this.item.type.hasAttach">
      <div v-if="this.item?.attachmentUrl">
        <ContentAttachment
          :attachment="item"
          :is-image="isImage()"
          :delete-enabled="
            hasAuthority(authorities.CAMPAIGN_WRITE) && campaignIsEditable
          "
          :delete-method="deleteAttach"
        />
      </div>
      <div
        v-if="
          hasAuthority(authorities.CAMPAIGN_WRITE) &&
          this.item.attachmentUrl === undefined
        "
        class="row"
      >
        <el-upload
          action=""
          v-loading="uploadInProgress"
          :accept="uploadAccept"
          drag
          multiple
          :show-file-list="false"
          :http-request="uploadFile"
          :before-upload="checkFile"
          :disabled="!campaignIsEditable"
        >
          <img
            class="upload-img"
            src="@/assets/upload.svg"
            alt="upload image"
          />
          <div class="el-upload__text">
            Drop file here or <em>click to upload</em>
            <div class="el-upload__tip">
              {{ uploadTip }}
            </div>
          </div>
        </el-upload>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { campaignContentApi } from "@/resources";
import { ElementForm, InputLength, InputRequired } from "@/util/validation";
import { isConstraintViolation, isProblem } from "@/resources/problem";
import { authorityMixin } from "@/util";
import ContentAttachment from "@/views/campaign/tabs/content/CampaignContentAttachment.vue";
import { ElUploadRequestOptions } from "element-plus/es/el-upload/src/upload.type";
import { FileFormat } from "@/views/components/Attachments.vue";
import { CampaignContentData } from "@/api";

export interface ContentForm {
  description: string | undefined;
}

export default defineComponent({
  name: "ContentItem",
  emits: ["update"],
  components: { ContentAttachment },
  mixins: [authorityMixin],
  props: {
    campaignId: {
      type: String,
      required: true,
    },
    item: {
      type: Object as PropType<CampaignContentData>,
      required: true,
    },
    campaignIsEditable: {
      type: Boolean,
      required: false,
      default: true,
    },
    attachmentFormats: {
      type: Object as PropType<Array<FileFormat>>,
      required: false,
      default: () =>
        [
          { mimetype: "application/msword", viewName: "DOC" } as FileFormat,
          {
            mimetype:
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            viewName: "DOCX",
          } as FileFormat,
          {
            mimetype: "application/vnd.ms-excel",
            viewName: "XLS",
          } as FileFormat,
          {
            mimetype:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            viewName: "XLSX",
          } as FileFormat,
          {
            mimetype: "application/vnd.ms-powerpoint",
            viewName: "PPT",
          } as FileFormat,
          {
            mimetype:
              "application/vnd.openxmlformats-officedocument.presentationml.presentation",
            viewName: "PPTX",
          } as FileFormat,
          { mimetype: "image/png", viewName: "PNG" } as FileFormat,
          { mimetype: "image/jpeg", viewName: "JPEG" } as FileFormat,
          { mimetype: "application/pdf", viewName: "PDF" } as FileFormat,
        ] as FileFormat[],
    },
    uploadAttachmentMaxSize: {
      type: Number,
      required: false,
      default: () => 10 * 1024 * 1024,
    },
  },
  data() {
    return {
      rules: {
        description: [new InputLength(2000), new InputRequired()],
      },
      isFormValid: true,
      editable: false,
      form: {} as ContentForm,
      attachmentUrl: "" as string,
      uploadInProgress: false,
    };
  },
  mounted() {
    this.form.description = this.item?.description as string;
  },
  computed: {
    uploadTip(): string {
      return this.$t("attachment.tip", {
        formats: this.formats(this.attachmentFormats),
        size: this.size(this.uploadAttachmentMaxSize),
      });
    },
    uploadAccept(): string {
      return this.attachmentFormats
        ? this.extensions(this.attachmentFormats)
        : "";
    },
  },
  methods: {
    edit() {
      this.editable = true;
    },
    cancel() {
      this.editable = false;
      this.form.description = this.item.description;
    },
    async submit() {
      const form = this.$refs["form"] as ElementForm;
      if ((await form.validate()) && this.form.description) {
        try {
          if (this.item.id) {
            await campaignContentApi.updateContentDescription({
              id: Number(this.campaignId),
              contentId: this.item.id,
              campaignContentDescriptionRequest: {
                description: this.form.description,
              },
            });
          } else {
            await campaignContentApi.createContentDescription({
              id: Number(this.campaignId),
              type: this.item.type.type,
              campaignContentDescriptionRequest: {
                description: this.form.description,
              },
            });
          }
          this.editable = false;
          this.$emit("update");
        } catch (error) {
          if (isConstraintViolation(error)) {
            error.violations.forEach((violation) =>
              this.$notify.error(this.$t(violation.message))
            );
          } else if (isProblem(error)) {
            this.$notify.error(this.$t(error.detail));
          }
        }
      }
    },
    isImage(): boolean {
      return ["image/jpeg", "image/png"].includes(this.item.mimetype as string);
    },
    async deleteAttach() {
      if (this.item.id) {
        await campaignContentApi.deleteContentAttachment({
          id: Number(this.campaignId),
          contentId: this.item.id,
        });
      }
      this.$emit("update");
    },
    checkFile(file: File) {
      if (
        !this.attachmentFormats
          ?.map((format) => format.mimetype)
          .includes(file.type)
      ) {
        this.$notify.error(
          this.$t("attachment.validations.inappropriateFileType", {
            file: file.name,
            formats: this.formats(this.attachmentFormats),
          })
        );
        return false;
      }
      if (file.size >= this.uploadAttachmentMaxSize) {
        this.$notify.error(
          this.$t("attachment.validations.maxFileSize", {
            size: this.size(this.uploadAttachmentMaxSize),
          })
        );
        return false;
      }
      return true;
    },
    async uploadFile(upload: ElUploadRequestOptions) {
      if (this.item) {
        try {
          this.uploadInProgress = true;
          if (this.item.id) {
            await campaignContentApi.updateContentAttachment({
              id: Number(this.campaignId),
              contentId: this.item.id,
              file: upload.file,
            });
          } else {
            await campaignContentApi.createContentAttachment({
              id: Number(this.campaignId),
              type: this.item.type.type,
              file: upload.file,
            });
          }
          upload.onSuccess("");
          this.$emit("update");
          this.$notify.info(
            this.$t("attachment.uploaded", { file: upload.file.name })
          );
        } catch (e) {
          if (isConstraintViolation(e)) {
            e.violations.forEach((violation) =>
              this.$notify.error(this.$t(violation.message))
            );
          } else if (isProblem(e)) {
            this.$notify.error(this.$t(e.detail));
          }
          upload.onError(e);
        } finally {
          this.uploadInProgress = false;
        }
      }
    },
    formats(formats: FileFormat[]): string {
      return formats.map((format) => format.viewName).join("/\u200B");
    },
    extensions(formats: FileFormat[]): string {
      return formats
        .map(
          (format) =>
            `.${
              format.extension
                ? format.extension
                : format.viewName.toLowerCase()
            }`
        )
        .join(",");
    },
    size(size: number): string {
      let i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
      return `${Number((size / Math.pow(1024, i)).toFixed(2))} ${
        ["B", "kB", "MB", "GB", "TB"][i]
      }`;
    },
  },
});
</script>

<style lang="scss">
$height: 260px;
$width: 244px;

.upload-img {
  width: 30%;
  height: 30%;
  margin: 15% auto auto;
  display: block;
}

.el-upload-dragger {
  height: $height * 0.615 !important;
  width: $width !important;
}

.el-upload__tip {
  margin-top: 0 !important;
  text-align: center;
  word-break: break-word;
  font-size: xx-small !important;
}

.content-header {
  margin-top: 30px !important;
}
</style>
