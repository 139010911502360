import { ElNotification } from "element-plus";
import { i18n } from "@/util";

export function info(message: string): void {
  ElNotification({
    type: "info",
    title: i18n.global.t("popup.info"),
    message: message,
    position: "bottom-right",
    offset: 50,
  });
}

export function error(message: string): void {
  ElNotification({
    type: "error",
    title: i18n.global.t("popup.error"),
    message: message,
    position: "bottom-right",
    offset: 50,
  });
}
