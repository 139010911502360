<template>
  <div class="container ms-0 mb-0" v-if="preScreeningId">
    <Attachments
      :attachments="attachments"
      :load-attachment="loadAttachment"
      :upload-disabled="!hasAuthority(authorities.PRE_SCREENING_WRITE)"
      :upload-attachment="uploadAttachment"
      :delete-enabled="deleteEnabled"
      :delete-method="deleteMethod"
      @update="fetchAttachments"
    ></Attachments>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { preScreeningApi } from "@/resources";
import { ActorType, AttachmentListItem } from "@/api";
import Attachments from "@/views/components/Attachments.vue";
import { Authority, authorityMixin } from "@/util";
import { account, AccountState } from "@/store/account";
import { mapState } from "vuex";
import { isConstraintViolation, isProblem } from "@/resources/problem";

export default defineComponent({
  name: "PreScreeningAttachments",
  components: { Attachments },
  mixins: [authorityMixin, account],
  data() {
    return {
      preScreeningId: inject("preScreeningId") as number,
      attachments: [] as AttachmentListItem[],
    };
  },
  async mounted() {
    await this.fetchAttachments();
  },
  computed: {
    ...mapState("account", {
      userId(state: AccountState) {
        return state.profile?.id;
      },
    }),
  },
  methods: {
    async fetchAttachments() {
      this.attachments = await preScreeningApi.getPreScreeningAttachments({
        id: this.preScreeningId,
      });
    },
    async loadAttachment(attachmentId: number): Promise<Blob> {
      return await preScreeningApi.preScreeningAttachment({
        id: this.preScreeningId,
        attachmentId: attachmentId,
      });
    },
    async uploadAttachment(file: File) {
      return await preScreeningApi.uploadPreScreeningAttachment({
        id: this.preScreeningId,
        file: file,
      });
    },
    deleteEnabled(attachment: AttachmentListItem): boolean {
      return (
        this.userId == attachment.actorId &&
        ActorType.BO_USER === attachment.actorType &&
        this.hasAuthority(Authority.PRE_SCREENING_WRITE)
      );
    },
    async deleteMethod(id: number) {
      try {
        await preScreeningApi.deletePreScreeningAttachment({
          id: this.preScreeningId,
          attachmentId: id,
        });
        this.attachments = this.attachments.filter(
          (attachment) => attachment.id != id
        );
      } catch (e) {
        if (isConstraintViolation(e)) {
          e.violations.forEach((violation) =>
            this.$notify.error(this.$t(violation.message))
          );
        } else if (isProblem(e)) {
          this.$notify.error(this.$t(e.detail));
        }
      }
    },
  },
});
</script>
