/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Country,
    CountryFromJSON,
    CountryFromJSONTyped,
    CountryToJSON,
} from './';

/**
 * 
 * @export
 * @interface RoleListItem
 */
export interface RoleListItem {
    /**
     * 
     * @type {string}
     * @memberof RoleListItem
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof RoleListItem
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof RoleListItem
     */
    name: string;
    /**
     * 
     * @type {Country}
     * @memberof RoleListItem
     */
    residenceCountry: Country;
    /**
     * 
     * @type {string}
     * @memberof RoleListItem
     */
    startPage?: string;
}

export function RoleListItemFromJSON(json: any): RoleListItem {
    return RoleListItemFromJSONTyped(json, false);
}

export function RoleListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'id': json['id'],
        'name': json['name'],
        'residenceCountry': CountryFromJSON(json['residenceCountry']),
        'startPage': !exists(json, 'startPage') ? undefined : json['startPage'],
    };
}

export function RoleListItemToJSON(value?: RoleListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'id': value.id,
        'name': value.name,
        'residenceCountry': CountryToJSON(value.residenceCountry),
        'startPage': value.startPage,
    };
}


