/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LegalPersonItem
 */
export interface LegalPersonItem {
    /**
     * 
     * @type {string}
     * @memberof LegalPersonItem
     */
    closeAssociate?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalPersonItem
     */
    governmentDepartment?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalPersonItem
     */
    governmentPosition?: string;
    /**
     * 
     * @type {number}
     * @memberof LegalPersonItem
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof LegalPersonItem
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LegalPersonItem
     */
    position: string;
}

export function LegalPersonItemFromJSON(json: any): LegalPersonItem {
    return LegalPersonItemFromJSONTyped(json, false);
}

export function LegalPersonItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): LegalPersonItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'closeAssociate': !exists(json, 'closeAssociate') ? undefined : json['closeAssociate'],
        'governmentDepartment': !exists(json, 'governmentDepartment') ? undefined : json['governmentDepartment'],
        'governmentPosition': !exists(json, 'governmentPosition') ? undefined : json['governmentPosition'],
        'id': json['id'],
        'name': json['name'],
        'position': json['position'],
    };
}

export function LegalPersonItemToJSON(value?: LegalPersonItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'closeAssociate': value.closeAssociate,
        'governmentDepartment': value.governmentDepartment,
        'governmentPosition': value.governmentPosition,
        'id': value.id,
        'name': value.name,
        'position': value.position,
    };
}


