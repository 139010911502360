/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BackOfficeCompleteTransactionRequest,
    BackOfficeCompleteTransactionRequestFromJSON,
    BackOfficeCompleteTransactionRequestToJSON,
    PageTransactionListItem,
    PageTransactionListItemFromJSON,
    PageTransactionListItemToJSON,
} from '../models';

export interface CompleteTransactionManuallyRequest {
    backOfficeCompleteTransactionRequest: BackOfficeCompleteTransactionRequest;
}

export interface TransactionsRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
    campaignId?: number;
    transactionTypes?: Array<string>;
    transactionStatuses?: Array<string>;
    amountFrom?: number;
    amountTo?: number;
    startDateFrom?: Date;
    startDateTo?: Date;
    effectiveDateFrom?: Date;
    effectiveDateTo?: Date;
    investmentId?: number;
}

/**
 * 
 */
export class TransactionControllerApi extends runtime.BaseAPI {

    /**
     */
    async completeTransactionManuallyRaw(requestParameters: CompleteTransactionManuallyRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.backOfficeCompleteTransactionRequest === null || requestParameters.backOfficeCompleteTransactionRequest === undefined) {
            throw new runtime.RequiredError('backOfficeCompleteTransactionRequest','Required parameter requestParameters.backOfficeCompleteTransactionRequest was null or undefined when calling completeTransactionManually.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/transaction`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BackOfficeCompleteTransactionRequestToJSON(requestParameters.backOfficeCompleteTransactionRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async completeTransactionManually(requestParameters: CompleteTransactionManuallyRequest): Promise<void> {
        await this.completeTransactionManuallyRaw(requestParameters);
    }

    /**
     */
    async transactionsRaw(requestParameters: TransactionsRequest): Promise<runtime.ApiResponse<PageTransactionListItem>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.campaignId !== undefined) {
            queryParameters['campaignId'] = requestParameters.campaignId;
        }

        if (requestParameters.transactionTypes) {
            queryParameters['transactionTypes'] = requestParameters.transactionTypes;
        }

        if (requestParameters.transactionStatuses) {
            queryParameters['transactionStatuses'] = requestParameters.transactionStatuses;
        }

        if (requestParameters.amountFrom !== undefined) {
            queryParameters['amountFrom'] = requestParameters.amountFrom;
        }

        if (requestParameters.amountTo !== undefined) {
            queryParameters['amountTo'] = requestParameters.amountTo;
        }

        if (requestParameters.startDateFrom !== undefined) {
            queryParameters['startDateFrom'] = (requestParameters.startDateFrom as any).toISOString().substr(0,10);
        }

        if (requestParameters.startDateTo !== undefined) {
            queryParameters['startDateTo'] = (requestParameters.startDateTo as any).toISOString().substr(0,10);
        }

        if (requestParameters.effectiveDateFrom !== undefined) {
            queryParameters['effectiveDateFrom'] = (requestParameters.effectiveDateFrom as any).toISOString().substr(0,10);
        }

        if (requestParameters.effectiveDateTo !== undefined) {
            queryParameters['effectiveDateTo'] = (requestParameters.effectiveDateTo as any).toISOString().substr(0,10);
        }

        if (requestParameters.investmentId !== undefined) {
            queryParameters['investmentId'] = requestParameters.investmentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/transaction`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageTransactionListItemFromJSON(jsonValue));
    }

    /**
     */
    async transactions(requestParameters: TransactionsRequest): Promise<PageTransactionListItem> {
        const response = await this.transactionsRaw(requestParameters);
        return await response.value();
    }

}
