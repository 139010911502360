/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FlowActor {
    BO_USER = 'BO_USER',
    SYSTEM = 'SYSTEM',
    PORTAL_USER = 'PORTAL_USER'
}

export function FlowActorFromJSON(json: any): FlowActor {
    return FlowActorFromJSONTyped(json, false);
}

export function FlowActorFromJSONTyped(json: any, ignoreDiscriminator: boolean): FlowActor {
    return json as FlowActor;
}

export function FlowActorToJSON(value?: FlowActor | null): any {
    return value as any;
}

