
import { defineComponent } from "vue";
import { ElementForm, InputLength } from "@/util/validation";
import { Filter } from "@/views/investment/InvestmentList.vue";
import { InvestmentStatus } from "@/api";

export default defineComponent({
  emits: ["update:investmentFilter", "submit"],
  props: ["investmentFilter"],
  data() {
    return {
      isFormValid: true,
      filter: {} as Filter,
      statuses: [] as InvestmentStatus[],
      rules: {
        investor: [new InputLength(100)],
        campaignId: [new InputLength(10)],
      },
    };
  },
  async mounted() {
    this.filter = this.investmentFilter;
    this.statuses = Object.values(InvestmentStatus);
  },
  methods: {
    submit() {
      const form = this.$refs["filter-form"] as ElementForm;
      form.validate(async (valid) => {
        if (valid) {
          this.$emit("submit");
        }
      });
    },
    onValidate(prop: string, isVal: boolean) {
      this.isFormValid = isVal;
    },
    resetFilter() {
      this.filter = {} as Filter;
      this.$emit("update:investmentFilter", this.filter);
      this.$emit("submit");
    },
  },
});
