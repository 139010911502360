<template>
  <el-table
    :data="page.content"
    @sort-change="onSortChange($event, pageRequest)"
  >
    <el-table-column :label="$t('id')" prop="id" sortable="custom" width="60">
    </el-table-column>
    <el-table-column
      :label="$t('legal.ownership.members.name')"
      prop="name"
      :show-overflow-tooltip="true"
    ></el-table-column>
    <el-table-column
      :label="$t('legal.ownership.members.position')"
      prop="position"
      :show-overflow-tooltip="true"
    ></el-table-column>
    <el-table-column
      :label="$t('legal.ownership.members.address')"
      prop="address"
      :show-overflow-tooltip="true"
    >
    </el-table-column>
    <el-table-column :label="$t('legal.ownership.members.residenceCountry')">
      <template #default="scope">
        {{ $enums.globals.countryIso(scope.row.residence) }}
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('legal.ownership.members.idCardNumber')"
      :show-overflow-tooltip="true"
    >
      <template #default="scope">
        {{ scope.row.idType === idType.CARD_ID ? scope.row.idNumber : "-" }}
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('legal.ownership.members.passportNumber')"
      :show-overflow-tooltip="true"
    >
      <template #default="scope">
        {{ scope.row.idType === idType.PASSPORT ? scope.row.idNumber : "-" }}
      </template>
    </el-table-column>
    <el-table-column :label="$t('legal.ownership.members.birthDate')">
      <template #default="scope">
        {{ $date(scope.row.birthDate) }}
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('legal.ownership.members.nationality')"
      prop="nationality"
      :show-overflow-tooltip="true"
    >
    </el-table-column>
  </el-table>
  <TableFooter
    @size-change="onSizeChange($event, pageRequest)"
    @current-change="onCurrentChange($event, pageRequest)"
    :page="page"
    :page-sizes="[5, 10, 30, 40]"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { IdDocumentType, PageLegalMemberItem } from "@/api";
import { authorityMixin, PageRequest, pageSortMixin } from "@/util";
import TableFooter from "@/components/TableFooter.vue";

export default defineComponent({
  name: "LegalMembers",
  mixins: [pageSortMixin, authorityMixin],
  components: { TableFooter },
  props: {
    page: {
      type: Object as PropType<PageLegalMemberItem>,
      required: true,
    },
    pageRequest: {
      type: Object as PropType<PageRequest>,
      required: true,
    },
    fetchMembersData: {
      type: Function,
      required: true,
    },
  },
  mounted() {
    this.fetchMembersData();
  },
  data() {
    return {
      idType: IdDocumentType,
    };
  },
  methods: {
    fetchData() {
      this.fetchMembersData();
    },
  },
});
</script>
