<template>
  <!--INDIVIDUAL-->
  <div
    class="container ms-0 mb-0"
    v-if="investor.id && investor.type === 'INDIVIDUAL'"
  >
    <InvestorIndividualGeneralInfo v-model:investor="investor" />
  </div>
  <!--LEGAL-->
  <div
    class="container ms-0 mb-0"
    v-else-if="investor.id && investor.type === 'LEGAL'"
  >
    <div class="row mb-3">
      <div class="col color-light-text">
        {{ $t("investor.investorType") }}
      </div>
      <div class="col">
        {{ $enums.investor.type(investor.type) }}
      </div>
      <div class="col color-light-text">
        {{ $t("investor.investorCategory") }}
      </div>
      <div class="col">{{ $enums.investor.category(investor.category) }}</div>
    </div>
    <LegalGeneralInfo v-model:legal="investor.legal" />
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { Investor } from "@/api";
import InvestorIndividualGeneralInfo from "@/views/investor/components/InvestorIndividualGeneralInfo.vue";
import LegalGeneralInfo from "@/views/components/LegalGeneralInfo.vue";

export default defineComponent({
  name: "InvestorGeneralInfo",
  emits: ["update"],
  components: { InvestorIndividualGeneralInfo, LegalGeneralInfo },
  props: {
    id: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      investor: inject("investor") as Investor,
    };
  },
});
</script>
