/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ForgotPasswordForm
 */
export interface ForgotPasswordForm {
    /**
     * 
     * @type {Date}
     * @memberof ForgotPasswordForm
     */
    birthDate: Date;
    /**
     * 
     * @type {string}
     * @memberof ForgotPasswordForm
     */
    login: string;
}

export function ForgotPasswordFormFromJSON(json: any): ForgotPasswordForm {
    return ForgotPasswordFormFromJSONTyped(json, false);
}

export function ForgotPasswordFormFromJSONTyped(json: any, ignoreDiscriminator: boolean): ForgotPasswordForm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'birthDate': (new Date(json['birthDate'])),
        'login': json['login'],
    };
}

export function ForgotPasswordFormToJSON(value?: ForgotPasswordForm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'birthDate': (value.birthDate.toISOString().substr(0,10)),
        'login': value.login,
    };
}


