/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApproveCampaignOperationRequest
 */
export interface ApproveCampaignOperationRequest {
    /**
     * 
     * @type {number}
     * @memberof ApproveCampaignOperationRequest
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof ApproveCampaignOperationRequest
     */
    daysDuration: number;
    /**
     * 
     * @type {number}
     * @memberof ApproveCampaignOperationRequest
     */
    minimumFundingPercent: number;
    /**
     * 
     * @type {number}
     * @memberof ApproveCampaignOperationRequest
     */
    minimumInvestment: number;
    /**
     * 
     * @type {number}
     * @memberof ApproveCampaignOperationRequest
     */
    overRaiseAmount: number;
}

export function ApproveCampaignOperationRequestFromJSON(json: any): ApproveCampaignOperationRequest {
    return ApproveCampaignOperationRequestFromJSONTyped(json, false);
}

export function ApproveCampaignOperationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApproveCampaignOperationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'daysDuration': json['daysDuration'],
        'minimumFundingPercent': json['minimumFundingPercent'],
        'minimumInvestment': json['minimumInvestment'],
        'overRaiseAmount': json['overRaiseAmount'],
    };
}

export function ApproveCampaignOperationRequestToJSON(value?: ApproveCampaignOperationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'daysDuration': value.daysDuration,
        'minimumFundingPercent': value.minimumFundingPercent,
        'minimumInvestment': value.minimumInvestment,
        'overRaiseAmount': value.overRaiseAmount,
    };
}


