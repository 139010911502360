<template>
  <div class="row mb-3">
    <div class="col">
      <div class="row mb-3">
        <div class="col color-light-text">
          {{ $t("investor.givenName") }}
        </div>
        <div class="col">
          {{ investor.individual.person.givenName }}
        </div>
      </div>
      <div class="row mb-3">
        <div class="col color-light-text">
          {{ $t("investor.lastName") }}
        </div>
        <div class="col">
          {{ investor.individual.person.lastName }}
        </div>
      </div>
      <div class="row mb-3">
        <div class="col color-light-text">
          {{ $t("investor.residenceCountry") }}
        </div>
        <div class="col">
          {{
            $enums.globals.countryIso(
              investor.individual.person.residenceCountry
            )
          }}
        </div>
      </div>
      <div class="row mb-3">
        <div class="col color-light-text">
          {{ $t("investor.nationality") }}
        </div>
        <div class="col">
          {{ investor.individual.nationality }}
        </div>
      </div>
      <div class="row mb-3">
        <div class="col color-light-text">
          {{ $t("investor.birthDate") }}
        </div>
        <div class="col">
          {{ $date(investor.individual.person.birthDate) }}
        </div>
      </div>
      <div class="row mb-3">
        <div class="col color-light-text">
          {{ $t("investor.birthPlace") }}
        </div>
        <div class="col">
          {{ investor.individual.person.birthPlace ?? "-" }}
        </div>
      </div>
      <div class="row mb-3">
        <div class="col color-light-text">
          {{ $t("investor.taxIdNumber") }}
        </div>
        <div class="col wrap-text">
          {{ investor.individual.taxIdNumber ?? "-" }}
        </div>
      </div>
      <div class="row mb-3">
        <div class="col color-light-text">
          {{ $t("investor.cardIdNumber") }}
        </div>
        <div class="col">
          {{
            investor.individual.idDocument.type === "CARD_ID"
              ? investor.individual.idDocument.number
              : "-"
          }}
        </div>
      </div>
      <div class="row mb-3">
        <div class="col color-light-text">
          {{ $t("investor.expiryDate") }}
        </div>
        <div class="col">
          {{
            investor.individual.idDocument.type === "CARD_ID"
              ? $date(investor.individual.idDocument.expiryDate)
              : "-"
          }}
        </div>
      </div>
    </div>
    <!--column 2-->
    <div class="col">
      <div class="row mb-3">
        <div class="col color-light-text">
          {{ $t("investor.investorType") }}
        </div>
        <div class="col">
          {{ $enums.investor.type(investor.type) }}
        </div>
      </div>
      <div class="row mb-3">
        <div class="col color-light-text">
          {{ $t("investor.investorCategory") }}
        </div>
        <div class="col">
          {{ $enums.investor.category(investor.category) }}
        </div>
      </div>
      <div class="row mb-3">
        <div class="col color-light-text">
          {{ $t("investor.passportNumber") }}
        </div>
        <div class="col">
          {{
            investor.individual.idDocument.type === "PASSPORT"
              ? investor.individual.idDocument.number
              : "-"
          }}
        </div>
      </div>
      <div class="row mb-3">
        <div class="col color-light-text">
          {{ $t("investor.issuePlace") }}
        </div>
        <div class="col">
          {{
            investor.individual.idDocument.type === "PASSPORT"
              ? investor.individual.idDocument.issuePlace
              : "-"
          }}
        </div>
      </div>
      <div class="row mb-3">
        <div class="col color-light-text">
          {{ $t("investor.issueDate") }}
        </div>
        <div class="col">
          {{
            investor.individual.idDocument.type === "PASSPORT"
              ? $date(investor.individual.idDocument.issueDate)
              : "-"
          }}
        </div>
      </div>
      <div class="row mb-3">
        <div class="col color-light-text">
          {{ $t("investor.expiryDate") }}
        </div>
        <div class="col">
          {{
            investor.individual.idDocument.type === "PASSPORT"
              ? $date(investor.individual.idDocument.expiryDate)
              : "-"
          }}
        </div>
      </div>
      <div class="row mb-3">
        <div class="col color-light-text">
          {{ $t("investor.education.title") }}
        </div>
        <div class="col">
          {{ $enums.investor.education(investor.individual.education) }}
        </div>
      </div>
      <div class="row mb-3">
        <div class="col color-light-text">
          {{ $t("investor.gender") }}
        </div>
        <div class="col">
          {{ $enums.globals.gender(investor.individual.gender) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Investor } from "@/api";

export default defineComponent({
  name: "InvestorIndividualGeneralInfo",
  props: {
    investor: {
      type: Object as PropType<Investor>,
      required: true,
    },
  },
});
</script>

<style scoped></style>
