/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FieldHistoryView,
    FieldHistoryViewFromJSON,
    FieldHistoryViewFromJSONTyped,
    FieldHistoryViewToJSON,
} from './';

/**
 * 
 * @export
 * @interface AccreditationItemHistory
 */
export interface AccreditationItemHistory {
    /**
     * 
     * @type {Array<FieldHistoryView>}
     * @memberof AccreditationItemHistory
     */
    status?: Array<FieldHistoryView>;
}

export function AccreditationItemHistoryFromJSON(json: any): AccreditationItemHistory {
    return AccreditationItemHistoryFromJSONTyped(json, false);
}

export function AccreditationItemHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccreditationItemHistory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : ((json['status'] as Array<any>).map(FieldHistoryViewFromJSON)),
    };
}

export function AccreditationItemHistoryToJSON(value?: AccreditationItemHistory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status === undefined ? undefined : ((value.status as Array<any>).map(FieldHistoryViewToJSON)),
    };
}


