<template>
  <div class="container-filters mt-4 mb-3">
    <el-form
      @validate="onValidate"
      :model="filter"
      :rules="rules"
      @change="$emit('update:portalUserFilter', filter)"
    >
      <div class="row">
        <div class="col-6">
          <el-form-item :label="$t('filter.fullname')" prop="fullname">
            <el-input v-model="filter.fullname"></el-input>
          </el-form-item>
          <el-form-item :label="$t('filter.status')">
            <el-select
              v-model="filter.statuses"
              class="w-100"
              multiple
              collapse-tags
            >
              <el-option
                v-for="(status, key) in statuses"
                :key="key"
                :label="$enums.portalUser.status(status)"
                :value="status"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="col-6">
          <el-form-item :label="$t('filter.email')" prop="email">
            <el-input v-model="filter.email"></el-input>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
  <div class="mb-5">
    <el-button type="primary" @click="$emit('submit')" :disabled="!isFormValid">
      {{ $t("apply") }}
    </el-button>
    <el-button type="default" @click="resetFilter">
      {{ $t("filter.reset") }}
    </el-button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PortalUserStatus } from "@/api";
import { Filter } from "@/views/user/portal/PortalUserList.vue";
import { InputLength } from "@/util/validation";

export default defineComponent({
  emits: ["update:portalUserFilter", "submit"],
  name: "PortalUserFilter",
  props: ["portalUserFilter"],
  data() {
    return {
      statuses: PortalUserStatus,
      isFormValid: true,
      filter: {} as Filter,
      rules: {
        fullname: [new InputLength(100)],
        email: [new InputLength(100)],
      },
    };
  },
  async mounted() {
    this.filter = this.portalUserFilter;
  },
  methods: {
    onValidate(prop: string, isVal: boolean) {
      this.isFormValid = isVal;
    },
    resetFilter() {
      this.filter = {} as Filter;
      this.$emit("update:portalUserFilter", this.filter);
      this.$emit("submit");
    },
  },
});
</script>
