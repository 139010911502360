<template>
  <span class="back-link" v-if="hasHistory" @click="goBack">
    <img src="@/assets/back-icon.svg" />
    <span>{{ $t("back") }}</span>
  </span>
  <div v-else></div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "BackLink",
  setup() {
    const hasHistory = ref(history.state?.back != null);
    useRouter().afterEach(
      () => (hasHistory.value = history.state.back != null)
    );
    return { hasHistory };
  },
  methods: {
    goBack() {
      if (history.state.back) {
        return this.$router.back();
      }
    },
  },
});
</script>

<style scoped lang="scss">
@import "src/styles/variables";

.back-link {
  color: $color-light-text;
  text-decoration: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  &:hover {
    color: $color-light-text;
  }
  img {
    margin-right: 8px;
  }
  cursor: pointer;
}
</style>
