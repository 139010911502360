import { StartPage } from "@/api";
import { Authority, i18n } from "@/util";

export class StartPageConvertors {
  static fromAuthority(authority: string): StartPage | undefined {
    switch (authority) {
      case "campaign:read":
        return StartPage.CAMPAIGNS;
      case "role:read":
        return StartPage.ROLES;
      case "user:read":
        return StartPage.USERS;
      case "investor:read":
        return StartPage.INVESTORS;
      case "business:read":
        return StartPage.BUSINESSES;
      case "investment:read":
        return StartPage.INVESTMENTS;
      case Authority.PRE_SCREENING_READ:
        return StartPage.PRE_SCREENINGS;
      case Authority.TRANSACTION_READ:
        return StartPage.TRANSACTIONS;
      case Authority.DEAL_READ:
        return StartPage.DEALS;
      case Authority.SETTINGS_READ:
        return StartPage.SETTINGS;
      case Authority.ACCREDITATION_READ:
        return StartPage.ACCREDITATIONS;
      default:
        return undefined;
    }
  }

  static location(startPage: StartPage): string {
    switch (startPage) {
      case StartPage.CAMPAIGNS:
        return "campaigns";
      case StartPage.ROLES:
        return "roles";
      case StartPage.USERS:
        return "users";
      case StartPage.INVESTORS:
        return "investors";
      case StartPage.BUSINESSES:
        return "businesses";
      case StartPage.INVESTMENTS:
        return "investments";
      case StartPage.PRE_SCREENINGS:
        return "preScreenings";
      case StartPage.DEALS:
        return "deals";
      case StartPage.TRANSACTIONS:
        return "transactions";
      case StartPage.SETTINGS:
        return "settings";
      case StartPage.ACCREDITATIONS:
        return "accreditations";
      default:
        throw Error(`Location is note defined for ${startPage}`);
    }
  }

  static displayValue(startPage: StartPage): string {
    switch (startPage) {
      case StartPage.CAMPAIGNS:
        return i18n.global.t("sidebar.campaigns");
      case StartPage.ROLES:
        return i18n.global.t("sidebar.roles");
      case StartPage.USERS:
        return i18n.global.t("sidebar.users");
      case StartPage.INVESTORS:
        return i18n.global.t("sidebar.investors");
      case StartPage.BUSINESSES:
        return i18n.global.t("sidebar.businesses");
      case StartPage.DEALS:
        return i18n.global.t("sidebar.deals");
      case StartPage.INVESTMENTS:
        return i18n.global.t("sidebar.investments");
      case StartPage.TRANSACTIONS:
        return i18n.global.t("sidebar.transactions");
      case StartPage.PRE_SCREENINGS:
        return i18n.global.t("sidebar.preScreening");
      case StartPage.SETTINGS:
        return i18n.global.t("sidebar.settings");
      case StartPage.ACCREDITATIONS:
        return i18n.global.t("sidebar.accreditations");
      default:
        return "-";
    }
  }
}
