/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum LegalEntityType {
    SOLE_TRADER = 'SOLE_TRADER',
    GENERAL_PARTNERSHIP = 'GENERAL_PARTNERSHIP',
    HOLDING_COMPANY = 'HOLDING_COMPANY',
    BRANCH_OF_FOREIGN_COMPANY = 'BRANCH_OF_FOREIGN_COMPANY',
    LIMITED_PARTNERSHIP = 'LIMITED_PARTNERSHIP',
    GOVERNMENT_OR_STATE = 'GOVERNMENT_OR_STATE',
    SAOC = 'SAOC',
    LLC = 'LLC',
    CPV = 'CPV',
    OTHER = 'OTHER'
}

export function LegalEntityTypeFromJSON(json: any): LegalEntityType {
    return LegalEntityTypeFromJSONTyped(json, false);
}

export function LegalEntityTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LegalEntityType {
    return json as LegalEntityType;
}

export function LegalEntityTypeToJSON(value?: LegalEntityType | null): any {
    return value as any;
}

