<template>
  <el-table
    :data="page.content"
    @sort-change="onSortChange($event, pageRequest)"
  >
    <el-table-column
      :label="$t('id')"
      sortable="custom"
      prop="id"
      min-width="60"
    >
      <template #default="scope">
        <router-link
          v-if="hasAuthority(authorities.INVESTMENT_READ)"
          :to="{ name: 'investment', params: { id: scope.row.id } }"
          >{{ scope.row.id }}
        </router-link>
        <span v-else>{{ scope.row.id }}</span>
      </template>
    </el-table-column>
    <el-table-column :label="$t('investmentSummary.name')">
      <template #default="scope">
        <router-link
          v-if="hasAuthority(authorities.INVESTOR_READ)"
          :to="{ name: 'investor', params: { id: scope.row.investor.id } }"
          >{{ scope.row.investor.name }}
        </router-link>
        <span v-else>{{ scope.row.investor.name }}</span>
      </template>
    </el-table-column>
    <el-table-column :label="$t('investmentSummary.requestedAmount')">
      <template #default="scope">
        {{ $curr(scope.row.requestedAmount) }}
      </template>
    </el-table-column>
    <el-table-column :label="$t('investmentSummary.committedAmount')">
      <template #default="scope">
        {{ $curr(scope.row.committedAmount) }}
      </template>
    </el-table-column>
    <el-table-column :label="$t('investmentSummary.receivedAmount')">
      <template #default="scope">
        {{ $curr(scope.row.receivedAmount) }}
      </template>
    </el-table-column>
    <el-table-column :label="$t('status')" min-width="110">
      <template #default="scope">
        <Status :color="$enums.investment.statusColor(scope.row.status)">
          {{ $enums.investment.status(scope.row.status) }}
        </Status>
      </template>
    </el-table-column>
  </el-table>
  <TableFooter
    @size-change="onSizeChange($event, pageRequest)"
    @current-change="onCurrentChange($event, pageRequest)"
    :page="page"
    :page-sizes="[5, 10, 30, 40]"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { authorityMixin, PageRequest, pageSortMixin } from "@/util";
import TableFooter from "@/components/TableFooter.vue";
import { PageInvestmentSummaryItem } from "@/api";
import Status from "@/components/Status.vue";

export default defineComponent({
  name: "InvestmentSummary",
  mixins: [pageSortMixin, authorityMixin],
  components: { TableFooter, Status },
  props: {
    page: {
      type: Object as PropType<PageInvestmentSummaryItem>,
      required: true,
    },
    pageRequest: {
      type: Object as PropType<PageRequest>,
      required: true,
    },
    fetchInvestmentSummary: {
      type: Function,
      required: true,
    },
  },
  mounted() {
    this.fetchInvestmentSummary();
  },
  methods: {
    fetchData() {
      this.fetchInvestmentSummary();
    },
  },
});
</script>
