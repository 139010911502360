<template>
  <Headline
    >{{ $t("sidebar.investors") }}
    <template v-slot:actions>
      <el-button
        class="filter"
        v-if="!filterVisible"
        @click="filterVisible = true"
        >{{ $t("filter.show") }}
      </el-button>
      <el-button class="filter" v-else @click="filterVisible = false">
        {{ $t("filter.hide") }}
      </el-button>
    </template>
  </Headline>
  <InvestorFilter
    v-if="filterVisible"
    v-model:investor-filter="filter"
    @submit="fetchData"
  />
  <el-table
    :data="investorPage.content"
    @sort-change="onSortChange($event, pageRequest)"
    :row-style="{ cursor: 'pointer' }"
    @cell-click="onRowClick"
  >
    <el-table-column :label="$t('id')" prop="id" sortable="custom" width="85">
    </el-table-column>
    <el-table-column :label="$t('investor.type.title')">
      <template #default="scope">
        {{ $enums.investor.type(scope.row.type) }}
      </template>
    </el-table-column>
    <el-table-column :label="$t('investor.category.title')">
      <template #default="scope">
        {{ $enums.investor.category(scope.row.category) }}
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('investor.name')"
      prop="name"
      :show-overflow-tooltip="true"
    >
    </el-table-column>
    <el-table-column :label="$t('investor.country')">
      <template #default="scope">
        <span class="text-break">
          {{ $enums.globals.countryIso(scope.row.country) }}
        </span>
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('investor.phone')"
      :show-overflow-tooltip="true"
    >
      <template #default="scope">
        {{ $phone(scope.row.phone) }}
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('investor.email')"
      prop="email"
      :show-overflow-tooltip="true"
    >
    </el-table-column>
  </el-table>

  <TableFooter
    @size-change="onSizeChange($event, pageRequest)"
    @current-change="onCurrentChange($event, pageRequest)"
    :page="investorPage"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { investorApi } from "@/resources";
import Headline from "@/components/Headline.vue";
import {
  InvestorCategory,
  InvestorType,
  PageInvestorListItem,
  Country,
  Investor,
} from "@/api";
import { authorityMixin, PageRequest, pageSortMixin } from "@/util";
import TableFooter from "@/components/TableFooter.vue";
import InvestorFilter from "@/views/investor/InvestorFilter.vue";

export interface Filter {
  name: string;
  email: string;
  categories: InvestorCategory[];
  types: InvestorType[];
  countries: Country[];
}

export default defineComponent({
  name: "InvestorList",
  components: { InvestorFilter, Headline, TableFooter },
  mixins: [pageSortMixin, authorityMixin],
  data: () => ({
    investorPage: {} as PageInvestorListItem,
    pageRequest: {} as PageRequest,
    filterVisible: false,
    filter: {} as Filter,
  }),
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.investorPage = await investorApi.investors({
        ...this.pageRequest,
        ...this.filter,
      });
    },
    onRowClick(row: Investor) {
      this.$router.push({
        name: "investor",
        params: { id: row.id },
      });
    },
  },
});
</script>
