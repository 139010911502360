
import { defineComponent } from "vue";

export default defineComponent({
  name: "TheFooter",
  data: () => ({
    uiVersion: process.env.VUE_APP_VERSION,
    buildVersion: "0.0.1",
  }),
  mounted() {
    this.fetchBuildVersion();
  },
  methods: {
    async getInfo() {
      const response = await fetch(
        process.env.VUE_APP_BACKOFFICE_PATH + "/actuator/info"
      );
      return response.json();
    },
    async fetchBuildVersion() {
      const info = await this.getInfo();
      this.buildVersion = info.build.version;
    },
  },
});
