const phoneRegex = /(\d{3})(\d{4})(\d{4})/;
const homeWorkPhoneRegex = /(\d{3})(\d{3})(\d{4})/;
const replacement = "+$1 $2-$3";

export function phone(value?: string): string {
  if (value == null) {
    return "-";
  }
  return value.replace(phoneRegex, replacement);
}

export function homeWorkPhone(value?: string): string {
  if (value == null) {
    return "-";
  }
  return value.replace(homeWorkPhoneRegex, replacement);
}
