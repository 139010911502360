
import { defineComponent } from "vue";
import {
  authorityMixin,
  fixLocalDates,
  PageRequest,
  pageSortMixin,
} from "@/util";
import TableFooter from "@/components/TableFooter.vue";
import Headline from "@/components/Headline.vue";
import {
  AccreditationListItem,
  AccreditationStatus,
  PageAccreditationListItem,
} from "@/api";
import Status from "@/components/Status.vue";
import AccreditationFilter from "@/views/accreditation/AccreditationFilter.vue";
import { accreditationApi } from "@/resources";

export interface Filter extends Record<string, unknown> {
  investorName: string | undefined;
  status: AccreditationStatus | undefined;
  createdAtFrom: Date | undefined;
  createdAtTo: Date | undefined;
  approvedAtFrom: Date | undefined;
  approvedAtTo: Date | undefined;
  validTillFrom: Date | undefined;
  validTillTo: Date | undefined;
}

export default defineComponent({
  name: "AccreditationList",
  mixins: [pageSortMixin, authorityMixin],
  components: { TableFooter, Headline, AccreditationFilter, Status },
  data: () => ({
    accreditationPage: {} as PageAccreditationListItem,
    pageRequest: {} as PageRequest,
    filterVisible: false,
    filter: {} as Filter,
  }),
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      fixLocalDates(this.filter);
      this.accreditationPage = await accreditationApi.accreditations({
        ...this.pageRequest,
        ...this.filter,
      });
    },
    onCellClick(row: AccreditationListItem) {
      this.$router.push({
        name: "accreditation",
        params: { id: row.id },
      });
    },
  },
});
