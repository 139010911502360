import { BusinessSector, LegalEntityType } from "@/api";
import { i18n } from "@/util";

export class Legal {
  static businessSector(type: BusinessSector): string {
    return type ? i18n.global.t("legal.businessSector." + type) : "-";
  }

  static type(type: LegalEntityType, defaultValue?: string): string {
    if (!type || type == LegalEntityType.OTHER) {
      return defaultValue ?? "-";
    }
    return type ? i18n.global.t("legal.type." + type) : "-";
  }

  static commerce(type: string): string {
    return type ? i18n.global.t("legal.commerce.type." + type) : "";
  }

  static commerceValue(type: string, value: string): string {
    return value;
  }
}
