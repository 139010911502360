
import { defineComponent, inject } from "vue";
import { CampaignSummaryItem } from "@/api";
import { authorityMixin } from "@/util";

export default defineComponent({
  name: "CampaignSummary",
  mixins: [authorityMixin],
  data() {
    return {
      summary: inject("summary") as CampaignSummaryItem,
    };
  },
});
