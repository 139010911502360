<template>
  <Headline
    >{{ $t("sidebar.businesses") }}
    <template v-slot:actions>
      <el-button
        v-if="!filterVisible"
        class="filter"
        @click="filterVisible = true"
        >{{ $t("filter.show") }}
      </el-button>
      <el-button
        v-if="filterVisible"
        class="filter"
        @click="filterVisible = false"
        >{{ $t("filter.hide") }}
      </el-button>
    </template>
  </Headline>
  <BusinessFilter
    v-model:businessFilter="filter"
    v-if="filterVisible"
    @submit="fetchData"
  ></BusinessFilter>
  <el-table
    :data="businessPage.content"
    @sort-change="onSortChange($event, pageRequest)"
    @cell-click="onCellClick"
    :row-style="{ cursor: 'pointer' }"
  >
    <el-table-column :label="$t('id')" prop="id" sortable="custom" width="60">
    </el-table-column>
    <el-table-column :label="$t('business.name')" :show-overflow-tooltip="true">
      <template #default="scope">
        {{ scope.row.name }}
      </template>
    </el-table-column>
    <el-table-column :label="$t('legal.general.registrationCountry')">
      <template #default="scope">
        {{ $enums.globals.countryIso(scope.row.registrationCountry) }}
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('legal.general.registrationNumber')"
      :show-overflow-tooltip="true"
    >
      <template #default="scope">
        {{ scope.row.registrationNumber }}
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('business.phone')"
      :show-overflow-tooltip="true"
    >
      <template #default="scope">
        {{ $phone(scope.row.phone) }}
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('business.email')"
      :show-overflow-tooltip="true"
    >
      <template #default="scope">{{ scope.row.email }}</template>
    </el-table-column>
  </el-table>

  <TableFooter
    @size-change="onSizeChange($event, pageRequest)"
    @current-change="onCurrentChange($event, pageRequest)"
    :page="businessPage"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { businessApi } from "@/resources";
import { PageRequest, pageSortMixin } from "@/util";
import TableFooter from "@/components/TableFooter.vue";
import Headline from "@/components/Headline.vue";
import BusinessFilter from "@/views/business/BusinessFilter.vue";
import { BusinessListItem, PageBusinessListItem } from "@/api";

export interface Filter {
  name: string;
  registrationNumber: string;
  email: string;
}

export default defineComponent({
  name: "BusinessList",
  mixins: [pageSortMixin],
  components: { TableFooter, Headline, BusinessFilter },
  data: () => ({
    businessPage: {} as PageBusinessListItem,
    pageRequest: {} as PageRequest,
    filterVisible: false,
    filter: {} as Filter,
  }),
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.businessPage = await businessApi.businesses({
        ...this.pageRequest,
        ...this.filter,
      });
    },
    onCellClick(row: BusinessListItem) {
      this.$router.push({
        name: "business",
        params: { id: row.id },
      });
    },
  },
});
</script>
