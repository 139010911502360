<template>
  <template v-if="isAuthenticated && showSideBar">
    <el-container>
      <el-container>
        <el-aside><TheSidebar /></el-aside>
        <el-main><router-view></router-view> </el-main>
      </el-container>
      <el-footer><TheFooter /></el-footer>
    </el-container>
  </template>
  <template v-else>
    <router-view></router-view>
  </template>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TheFooter from "@/components/TheFooter.vue";
import TheSidebar from "@/components/TheSidebar.vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "App",
  components: { TheSidebar, TheFooter },
  computed: {
    ...mapGetters("account", ["isAuthenticated"]),
    showSideBar() {
      return !this.$route.fullPath.includes("/successSign");
    },
  },
});
</script>

<style lang="scss">
@import "src/styles/variables";
.el-aside {
  width: 260px !important;
}
@media (max-width: $width-md) {
  .el-aside {
    width: 220px !important;
  }
}
</style>
