<template>
  <div class="container-auth">
    <h2 class="text-center">
      {{ $t("application.signSuccess") }}
    </h2>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "SuccessSign",
});
</script>
