import { i18n } from "@/util";
import { AccreditationStatus } from "@/api";

export class Accreditation {
  static status(status: AccreditationStatus): string {
    return status ? i18n.global.t("accreditation.status." + status) : "-";
  }

  static statusColor(status: AccreditationStatus): string {
    switch (status) {
      case AccreditationStatus.RECEIVED:
        return "#e78b2d";
      case AccreditationStatus.REVIEWED:
        return "#0f7dbb";
      case AccreditationStatus.APPROVED:
        return "#28e848";
      case AccreditationStatus.DECLINED:
        return "#000000";
      case AccreditationStatus.FOLLOW_UP:
        return "#7601f3";
      case AccreditationStatus.PENDING:
        return "#ca0fd9";
      case AccreditationStatus.CANCELLED:
        return "#8c2c2c";
      default:
        return "#7f7f7f";
    }
  }
}
