
import { computed, defineComponent } from "vue";
import { User, UserHistory } from "@/api";
import { userApi } from "@/resources";
import Headline from "@/components/Headline.vue";
import Status from "@/components/Status.vue";
import BackLink from "@/components/BackLink.vue";
import DeactivateUserDialog from "@/views/user/backoffice/dialog/DeactivateUserDialog.vue";
import ActivateUserDialog from "@/views/user/backoffice/dialog/ActivateUserDialog.vue";
import { authorityMixin } from "@/util";

export default defineComponent({
  name: "UserView",
  mixins: [authorityMixin],
  components: {
    ActivateUserDialog,
    DeactivateUserDialog,
    Headline,
    Status,
    BackLink,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  provide() {
    return {
      user: computed(() => this.user),
      history: computed(() => this.history),
    };
  },
  data() {
    return {
      user: { person: {} } as User,
      history: [] as UserHistory[],
    };
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchUser() {
      this.user = await userApi.user({ id: Number(this.id) });
    },
    async fetchHistory() {
      this.history = await userApi.getUserHistory({ id: Number(this.id) });
    },
    async fetchData() {
      await Promise.all([this.fetchUser(), this.fetchHistory()]);
    },
  },
});
