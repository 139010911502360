import { i18n } from "@/util";
import {
  Education,
  EmploymentType,
  InvestorCategory,
  InvestorSubCategory,
  InvestorType,
} from "@/api";

export class Investor {
  static type(type: string): string {
    return type ? i18n.global.t("investor.type." + type) : "-";
  }

  static category(category: string): string {
    return category ? i18n.global.t("investor.category." + category) : "-";
  }

  static education(value: Education): string {
    return value ? i18n.global.t("investor.education." + value) : "-";
  }

  static employmentType(value: EmploymentType): string {
    return value ? i18n.global.t("investor.employmentType." + value) : "-";
  }

  static subCategory(
    subCategory: InvestorSubCategory,
    category: InvestorCategory,
    type: InvestorType
  ): string {
    if (subCategory && category && type) {
      return i18n.global.t(
        "investor.subCategory." + subCategory + "." + category + "." + type
      );
    }
    return "-";
  }
}
