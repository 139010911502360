import { Middleware, ResponseContext } from "@/api";
import router from "@/router";

export interface Problem {
  type?: string;
  title: string;
  status: number;
  detail: string;
}

export interface Violation {
  field: string;
  message: string;
}

export interface ConstraintViolation extends Problem {
  violations: Violation[];
}

export const problemMiddleware: Middleware = {
  async post(context: ResponseContext): Promise<Response | void> {
    if (
      context.response.headers.get("content-type") ===
      "application/problem+json"
    ) {
      const rawProblem = await context.response.json();
      if (context.response.status == 403 || context.response.status == 404) {
        await router.replace({ name: "notFound" });
      }
      return Promise.reject(rawProblem);
    }
    return context.response;
  },
};

export const authProblemMiddleware: Middleware = {
  async post(context: ResponseContext): Promise<Response | void> {
    if (
      context.response.headers.get("content-type") ===
      "application/problem+json"
    ) {
      const rawProblem = await context.response.json();
      return Promise.reject(rawProblem);
    }
    return context.response;
  },
};

export function isProblem(object: unknown): object is Problem {
  return (
    typeof object === "object" &&
    object != null &&
    "status" in object &&
    "title" in object
  );
}

export function isConstraintViolation(
  object: unknown
): object is ConstraintViolation {
  return (
    isProblem(object) &&
    object.type === "https://zalando.github.io/problem/constraint-violation"
  );
}
