<template>
  <Headline
    >{{ $t("sidebar.preScreening") }}
    <template v-slot:actions>
      <el-button
        v-if="!filterVisible"
        class="filter"
        @click="filterVisible = true"
        >{{ $t("filter.show") }}
      </el-button>
      <el-button
        v-if="filterVisible"
        class="filter"
        @click="filterVisible = false"
        >{{ $t("filter.hide") }}
      </el-button>
    </template>
  </Headline>
  <PreScreeningFilter
    v-model:pre-screening-filter="filter"
    v-if="filterVisible"
    @submit="fetchData"
  ></PreScreeningFilter>
  <el-table
    :data="preScreeningPage.content"
    @sort-change="onSortChange($event, pageRequest)"
    @cell-click="onCellClick"
    :row-style="{ cursor: 'pointer' }"
  >
    <el-table-column :label="$t('id')" prop="id" sortable="custom" width="60">
    </el-table-column>
    <el-table-column
      :label="$t('preScreening.companyName')"
      :show-overflow-tooltip="true"
    >
      <template #default="scope">
        {{ scope.row.companyName }}
      </template>
    </el-table-column>
    <el-table-column :label="$t('preScreening.companyRegistrationCountry')">
      <template #default="scope">
        {{ $enums.globals.countryIso(scope.row.companyRegistrationCountry) }}
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('preScreening.companyRegistrationNumber')"
      :show-overflow-tooltip="true"
    >
      <template #default="scope">
        {{ scope.row.companyRegistrationNumber }}
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('preScreening.contactName')"
      :show-overflow-tooltip="true"
    >
      <template #default="scope">
        {{ $phone(scope.row.contactName) }}
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('preScreening.contactEmail')"
      :show-overflow-tooltip="true"
    >
      <template #default="scope">{{ scope.row.contactEmail }}</template>
    </el-table-column>
    <el-table-column :label="$t('createdAt')" :show-overflow-tooltip="true">
      <template #default="scope">{{ $date(scope.row.createdAt) }}</template>
    </el-table-column>
    <el-table-column
      :label="$t('status')"
      min-width="220"
      align="center"
      class-name="status"
    >
      <template #default="scope">
        <Status
          size="small"
          :color="$enums.preScreening.statusColor(scope.row.status)"
        >
          {{ $enums.preScreening.status(scope.row.status) }}
        </Status>
      </template>
    </el-table-column>
  </el-table>

  <TableFooter
    @size-change="onSizeChange($event, pageRequest)"
    @current-change="onCurrentChange($event, pageRequest)"
    :page="preScreeningPage"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { preScreeningApi } from "@/resources";
import { fixLocalDate, PageRequest, pageSortMixin } from "@/util";
import TableFooter from "@/components/TableFooter.vue";
import Headline from "@/components/Headline.vue";
import PreScreeningFilter from "@/views/pre_screening/PreScreeningFilter.vue";
import {
  Country,
  PagePreScreeningListItem,
  PreScreeningDuplicateStatus,
  PreScreeningListItem,
  PreScreeningStatus,
} from "@/api";
import Status from "@/components/Status.vue";

export interface Filter {
  companyName: string | undefined;
  companyRegistrationCountry: Country | undefined;
  status: PreScreeningStatus | undefined;
  duplicateStatus: PreScreeningDuplicateStatus | undefined;
  contactName: string | undefined;
  contactEmail: string | undefined;
  createdAtFrom: Date | undefined;
  createdAtTo: Date | undefined;
}

export default defineComponent({
  name: "PreScreeningList",
  mixins: [pageSortMixin],
  components: { TableFooter, Headline, PreScreeningFilter, Status },
  data: () => ({
    preScreeningPage: {} as PagePreScreeningListItem,
    pageRequest: {} as PageRequest,
    filterVisible: false,
    filter: {} as Filter,
  }),
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.filter.createdAtTo = this.filter.createdAtTo
        ? fixLocalDate(this.filter.createdAtTo)
        : undefined;
      this.filter.createdAtFrom = this.filter.createdAtFrom
        ? fixLocalDate(this.filter.createdAtFrom)
        : undefined;
      this.preScreeningPage = await preScreeningApi.preScreenings({
        ...this.pageRequest,
        ...this.filter,
      });
    },
    onCellClick(row: PreScreeningListItem) {
      this.$router.push({
        name: "preScreening",
        params: { id: row.id },
      });
    },
  },
});
</script>
