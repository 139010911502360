
import { defineComponent } from "vue";
import { mapMutations, mapState } from "vuex";
import { accountApi } from "@/resources";
import { logoutUser } from "@/util/ws";
import { Subscription } from "rxjs";

export default defineComponent({
  name: "TheUserMenu",
  data() {
    return {
      initials: "" as string,
      logoutUserSubscription: {} as Subscription,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    ...mapMutations("account", ["deleteToken", "setProfile"]),
    async fetchData() {
      const profile = await accountApi.profile();
      (await this).logoutUserSubscription = logoutUser(
        profile.id,
        (message) => {
          if (message.deactivated) {
            this.$notify.info(this.$t("auth.forceLogout"));
          }
          this.deleteToken();
        }
      );
      this.setProfile(profile);
      (await this).initials = profile.userFullName
        .split(" ")
        .map((el) => el[0])
        .join("");
    },
    doLogout() {
      this.deleteToken();
    },
  },
  computed: {
    ...mapState("account", ["profile"]),
  },
});
