import { i18n } from "@/util";

export class InputRequired {
  constructor(
    private message = "validation.selectRequired",
    private required = true,
    private pattern = /\S+/,
    private trigger = ["blur", "change"]
  ) {
    this.message = i18n.global.t(message);
  }
}

export class IntegerRange {
  constructor(
    private min: number,
    private max: number,
    public message = "validation.integerRange",
    private trigger = ["blur", "change"],
    private type = "integer"
  ) {
    this.message = i18n.global
      .t(message)
      .replace("#min", String(min))
      .replace("#max", String(max));
  }
}

export class NumberMin {
  constructor(
    private min: number,
    private trigger = ["blur", "change"],
    protected validator = (
      rule: Record<string, unknown>,
      value: number,
      callback: (error?: Error) => void
    ) => {
      if (value < min) {
        callback(
          new Error(i18n.global.t("validation.integerMin", { min: min }))
        );
      } else {
        callback();
      }
    }
  ) {}
}

export class NumberRange {
  constructor(
    private min: number,
    private max: number,
    private trigger = ["blur", "change"],
    protected validator = (
      rule: Record<string, unknown>,
      value: number,
      callback: (error?: Error) => void
    ) => {
      if (value < min || value > max) {
        callback(
          new Error(
            i18n.global.t("validation.integerRange", { min: min, max: max })
          )
        );
      } else {
        callback();
      }
    }
  ) {}
}

export class InputSelectOptionLength {
  constructor(
    private max: number,
    private trigger = ["blur", "change"],
    protected validator = (
      rule: Record<string, unknown>,
      value: string[],
      callback: (error?: Error) => void
    ) => {
      if (value && value.filter((option) => option.length > max).length > 0) {
        callback(
          new Error(i18n.global.t("validation.inputLength", { max: max }))
        );
      } else {
        callback();
      }
    }
  ) {}
}

export class InputLength {
  constructor(
    private max: number,
    private message = "validation.inputLength",
    private trigger = ["blur", "change"]
  ) {
    this.message = i18n.global.t(message, { max: max });
  }
}

export class DecimalFormat {
  private message: string;
  private pattern: RegExp;

  constructor(
    private integerDigits: number,
    private fractionDigits: number,
    private trigger = ["blur", "change"]
  ) {
    //eslint-disable-line
    //eslint-disable-next-line
    this.pattern = new RegExp(
      `^(0|[1-9]\\d{0,${integerDigits - 1}})([.]\\d{1,${fractionDigits}})?$`
    );
    this.message = i18n.global.t("validation.decimalFormat");
  }
}

export const numberFormat = {
  pattern: /^(0|[1-9]\d*)([.]\d{1,2})?$/,
  trigger: ["blur", "change"],
  message: i18n.global.t("validation.numberFormat"),
};

export const integerFormat = {
  pattern: /^\d*$/,
  trigger: ["blur", "change"],
  message: i18n.global.t("validation.integerFormat"),
};

export const alphabeticalFormat = {
  pattern: /^[a-zA-Z\s]*$/,
  trigger: ["blur", "change"],
  message: i18n.global.t("validation.alphabeticalFormat"),
};

export const alphanumericFormat = {
  pattern: /^[0-9a-zA-Z\s]*$/,
  trigger: ["blur", "change"],
  message: i18n.global.t("validation.alphanumericFormat"),
};

export const emailFormat = {
  pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  trigger: ["blur", "change"],
  message: i18n.global.t("validation.emailFormat"),
};

export const phonePattern = /^507\s?\d{4}-?\d{4}$/;

export const phoneFormat = {
  pattern: phonePattern,
  trigger: ["blur", "change"],
  message: i18n.global.t("validation.phoneFormat"),
};

export const passwordPattern = /^(([A-z0-9])*\S\0)*(?=.*[!"#$%&'()*+,-.\\/:;<=>?@[\]^_`{|}~])\S.\S{6,30}$/;

export const passwordFormat = {
  pattern: passwordPattern,
  trigger: ["blur", "change"],
  message: i18n.global.t("auth.password.incorrectPassword"),
};

export const nameFormat = {
  pattern: /^[a-zA-Z., '-]+$/,
  max: 255,
  trigger: ["blur", "change"],
  message: i18n.global.t("validation.alphabeticalFormat"),
};
