<template>
  <div class="card">
    <div class="card-main">
      <BackLink />
      <Headline v-if="campaign.id" :class="'align-items-start'">
        {{ $t("campaign.number") }}
        <div class="h1-sm" style="display: inline">{{ id }}</div>
        <template v-slot:details>
          <Status
            :color="$enums.campaign.statusColor(campaign.status)"
            size="medium"
          >
            {{ $enums.campaign.status(campaign.status) }}
          </Status>
        </template>
        <template
          v-slot:actions
          v-if="
            campaign.id && hasAuthority(authorities.CAMPAIGN_OPERATION_WRITE)
          "
        >
          <el-dropdown trigger="click">
            <el-button type="default">
              {{ $t("actions")
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <template #dropdown>
              <el-dropdown-item>
                <template
                  v-if="
                    campaign.id &&
                    state.nextStatuses &&
                    hasAuthority(authorities.CAMPAIGN_OPERATION_WRITE)
                  "
                >
                  <OkCancelDialog
                    :dialogDisabled="campaign.status !== campaignStatuses.DRAFT"
                    :header="$t('campaign.registrationFee.header')"
                    :text="$t('campaign.registrationFee.text')"
                    :info-message="$t('campaign.registrationFee.info')"
                    :onOk="registrationFee"
                    @update="fetchData"
                  />
                </template>
              </el-dropdown-item>
              <el-dropdown-item>
                <template
                  v-if="
                    campaign.id &&
                    state.nextStatuses &&
                    hasAuthority(authorities.CAMPAIGN_OPERATION_WRITE)
                  "
                >
                  <OkCancelDialog
                    :dialogDisabled="
                      campaign.status !== campaignStatuses.ACTIVE
                    "
                    :header="$t('campaign.verifyBusiness.header')"
                    :text="$t('campaign.verifyBusiness.text')"
                    :info-message="$t('campaign.verifyBusiness.info')"
                    :onOk="verifyBusiness"
                    @update="fetchData"
                  />
                </template>
              </el-dropdown-item>
              <el-dropdown-item>
                <template
                  v-if="
                    campaign.id &&
                    state.nextStatuses &&
                    hasAuthority(authorities.CAMPAIGN_OPERATION_WRITE)
                  "
                >
                  <BaseCampaignReasonDialog
                    :dialogDisabled="
                      !state.nextStatuses.includes(
                        campaignStatuses.WAITING_FOR_DATA
                      ) ||
                      campaign.status === campaignStatuses.CANCELLATION_REQUEST
                    "
                    :header="$t('campaign.waitingForData.header')"
                    :text="$t('campaign.waitingForData.text')"
                    :info-message="$t('campaign.waitingForData.info')"
                    :onSubmit="waitingForData"
                    @update="fetchData"
                  />
                </template>
              </el-dropdown-item>
              <el-dropdown-item>
                <template
                  v-if="
                    campaign.id &&
                    state.nextStatuses &&
                    hasAuthority(authorities.CAMPAIGN_OPERATION_WRITE)
                  "
                >
                  <BaseCampaignReasonDialog
                    :dialogDisabled="
                      !state.nextStatuses.includes(
                        campaignStatuses.TEMPORARILY_SUSPENDED
                      )
                    "
                    :header="$t('campaign.suspend.header')"
                    :text="$t('campaign.suspend.text')"
                    :info-message="$t('campaign.suspend.info')"
                    :onSubmit="suspendCampaign"
                    @update="fetchData"
                  />
                </template>
              </el-dropdown-item>
              <el-dropdown-item>
                <template
                  v-if="
                    campaign.id &&
                    state.nextStatuses &&
                    hasAuthority(authorities.CAMPAIGN_OPERATION_WRITE)
                  "
                >
                  <BaseCampaignReasonDialog
                    :dialogDisabled="
                      campaign.status !==
                        campaignStatuses.TEMPORARILY_SUSPENDED ||
                      !state.nextStatuses.includes(state.previousStatus)
                    "
                    :header="
                      $t('campaign.revertSuspend.header', {
                        status: $enums.campaign.status(
                          campaign.status ===
                            campaignStatuses.TEMPORARILY_SUSPENDED
                            ? state.previousStatus
                            : campaignStatuses.FUNDRAISING_RESERVE
                        ),
                      })
                    "
                    :text="
                      $t('campaign.revertSuspend.text', {
                        status: $enums.campaign.status(
                          campaign.status ===
                            campaignStatuses.TEMPORARILY_SUSPENDED
                            ? state.previousStatus
                            : campaignStatuses.FUNDRAISING_RESERVE
                        ),
                      })
                    "
                    :info-message="
                      $t('campaign.revertSuspend.info', {
                        status: $enums.campaign.status(
                          campaign.status ===
                            campaignStatuses.TEMPORARILY_SUSPENDED
                            ? state.previousStatus
                            : campaignStatuses.FUNDRAISING_RESERVE
                        ),
                      })
                    "
                    :onSubmit="revertSuspendCampaign"
                    @update="fetchData"
                  />
                </template>
              </el-dropdown-item>
              <el-dropdown-item>
                <template
                  v-if="
                    campaign.id &&
                    state.nextStatuses &&
                    hasAuthority(authorities.CAMPAIGN_OPERATION_WRITE)
                  "
                >
                  <BaseCampaignReasonDialog
                    :dialogDisabled="
                      !state.nextStatuses.includes(campaignStatuses.CANCELED)
                    "
                    :header="$t('campaign.cancel.header')"
                    :text="$t('campaign.cancel.text')"
                    :info-message="$t('campaign.cancel.info')"
                    :onSubmit="cancelCampaign"
                    @update="fetchData"
                  />
                </template>
              </el-dropdown-item>
              <el-dropdown-item>
                <template
                  v-if="
                    campaign.id &&
                    state.nextStatuses &&
                    hasAuthority(authorities.CAMPAIGN_OPERATION_WRITE)
                  "
                >
                  <BaseCampaignReasonDialog
                    :dialogDisabled="
                      campaign.status !==
                        campaignStatuses.CANCELLATION_REQUEST ||
                      !state.nextStatuses.includes(state.previousStatus)
                    "
                    :header="$t('campaign.revertCancel.header')"
                    :text="
                      $t('campaign.revertCancel.text', {
                        status: $enums.campaign.status(
                          campaign.status ===
                            campaignStatuses.CANCELLATION_REQUEST
                            ? state.previousStatus
                            : campaignStatuses.BUSINESS_VERIFICATION
                        ),
                      })
                    "
                    :info-message="
                      $t('campaign.revertCancel.info', {
                        status: $enums.campaign.status(
                          campaign.status ===
                            campaignStatuses.CANCELLATION_REQUEST
                            ? state.previousStatus
                            : campaignStatuses.BUSINESS_VERIFICATION
                        ),
                      })
                    "
                    :onSubmit="revertCancelCampaign"
                    @update="fetchData"
                  />
                </template>
              </el-dropdown-item>
              <el-dropdown-item>
                <template
                  v-if="
                    campaign.id &&
                    state.nextStatuses &&
                    hasAuthority(authorities.CAMPAIGN_OPERATION_WRITE)
                  "
                >
                  <DeclineCampaignDialog
                    :dialogDisabled="
                      !state.nextStatuses.includes(campaignStatuses.DECLINED)
                    "
                    :id="campaign.id"
                    @update="fetchData"
                  />
                </template>
              </el-dropdown-item>
              <el-dropdown-item>
                <template
                  v-if="
                    campaign.id &&
                    state.nextStatuses &&
                    hasAuthority(authorities.CAMPAIGN_OPERATION_WRITE)
                  "
                >
                  <ApproveCampaignDialog
                    :dialogDisabled="
                      !state.nextStatuses.includes(campaignStatuses.APPROVED) ||
                      campaign.status === campaignStatuses.CANCELLATION_REQUEST
                    "
                    :campaign="campaign"
                    @update="fetchData"
                  />
                </template>
              </el-dropdown-item>
              <el-dropdown-item>
                <template
                  v-if="
                    campaign.id &&
                    state.nextStatuses &&
                    hasAuthority(authorities.CAMPAIGN_WRITE)
                  "
                >
                  <OkCancelDialog
                    :dialogDisabled="
                      !state.nextStatuses.includes(
                        campaignStatuses.SIGNING_CAMPAIGN_AGREEMENT
                      ) ||
                      campaign.status === campaignStatuses.CANCELLATION_REQUEST
                    "
                    :header="$t('campaign.signingAgreement.header')"
                    :text="$t('campaign.signingAgreement.text')"
                    :info-message="$t('campaign.signingAgreement.info')"
                    :onOk="signingAgreement"
                    @update="fetchData"
                  />
                </template>
              </el-dropdown-item>
              <el-dropdown-item>
                <template
                  v-if="
                    campaign.id &&
                    state.nextStatuses &&
                    hasAuthority(authorities.CAMPAIGN_WRITE)
                  "
                >
                  <OkCancelDialog
                    :dialogDisabled="
                      !state.nextStatuses.includes(
                        campaignStatuses.FUNDRAISING_RESERVE
                      ) ||
                      campaign.status === campaignStatuses.TEMPORARILY_SUSPENDED
                    "
                    :header="$t('campaign.fundraising.header')"
                    :text="$t('campaign.fundraising.text')"
                    :info-message="$t('campaign.fundraising.info')"
                    :onOk="fundraising"
                    @update="fetchData"
                  />
                </template>
              </el-dropdown-item>
              <el-dropdown-item>
                <template
                  v-if="
                    campaign.id &&
                    state.nextStatuses &&
                    hasAuthority(authorities.CAMPAIGN_WRITE)
                  "
                >
                  <OkCancelDialog
                    :dialogDisabled="
                      !state.nextStatuses.includes(campaignStatuses.SPV_SET_UP)
                    "
                    :header="$t('campaign.spv.header')"
                    :text="$t('campaign.spv.text')"
                    :info-message="$t('campaign.spv.info')"
                    :onOk="spv"
                    @update="fetchData"
                  />
                </template>
              </el-dropdown-item>
              <el-dropdown-item>
                <template
                  v-if="
                    campaign.id &&
                    state.nextStatuses &&
                    hasAuthority(authorities.CAMPAIGN_WRITE)
                  "
                >
                  <OkCancelDialog
                    :dialogDisabled="
                      !state.nextStatuses.includes(
                        campaignStatuses.READY_FOR_DISBURSEMENT
                      ) ||
                      campaign.status === campaignStatuses.DISBURSEMENT_ERROR
                    "
                    :header="$t('campaign.disbursement.header')"
                    :text="$t('campaign.disbursement.text')"
                    :info-message="$t('campaign.disbursement.info')"
                    :onOk="disbursement"
                    @update="fetchData"
                  />
                </template>
              </el-dropdown-item>
              <el-dropdown-item>
                <template
                  v-if="
                    campaign.id &&
                    state.nextStatuses &&
                    hasAuthority(
                      authorities.CAMPAIGN_OPERATION_MANUAL_DISBURSEMENT
                    )
                  "
                >
                  <OkCancelDialog
                    :dialogDisabled="
                      !state.nextStatuses.includes(
                        campaignStatuses.READY_FOR_DISBURSEMENT
                      ) ||
                      campaign.status !== campaignStatuses.DISBURSEMENT_ERROR
                    "
                    :header="$t('campaign.manualDisbursement.header')"
                    :text="$t('campaign.manualDisbursement.text')"
                    :info-message="$t('campaign.manualDisbursement.info')"
                    :onOk="manualDisbursement"
                    @update="fetchData"
                  />
                </template>
              </el-dropdown-item>
              <el-dropdown-item>
                <template
                  v-if="
                    campaign.id &&
                    campaign.campaignFinishedAt &&
                    hasAuthority(authorities.CAMPAIGN_WRITE)
                  "
                >
                  <ExtendCampaignDurationDialog
                    :dialogDisabled="
                      !allowedForExtendDuration.includes(campaign.status)
                    "
                    :campaign-finished-at="campaign.campaignFinishedAt"
                    :id="campaign.id"
                    @update="fetchData"
                  />
                </template>
              </el-dropdown-item>
              <el-dropdown-item>
                <template
                  v-if="
                    qaEnabled &&
                    campaign.id &&
                    state.nextStatuses &&
                    hasAuthority(authorities.CAMPAIGN_OPERATION_WRITE)
                  "
                >
                  <QAChangeCampaignStatusDialog
                    :dialogDisabled="
                      campaign.terminatedAt ||
                      campaign.status === campaignStatuses.DISBURSEMENT_SUCCESS
                    "
                    :campaign="campaign"
                    @update="fetchData"
                  />
                </template>
              </el-dropdown-item>
            </template>
          </el-dropdown>
        </template>
      </Headline>
    </div>
    <div class="card-foot">
      <ul class="nav nav-tabs mb-3">
        <TabLink to="campaignGeneralInfo">
          {{ $t("campaign.tabs.general") }}
        </TabLink>
        <TabLink to="campaignFee">
          {{ $t("campaign.tabs.fee") }}
        </TabLink>
        <TabLink to="campaignSummary">
          {{ $t("campaign.tabs.summary") }}
        </TabLink>
        <li
          v-if="
            hasAuthority(authorities.BUSINESS_READ) && campaign.campaignBusiness
          "
          class="nav-item"
        >
          <router-link
            v-slot="{ navigate }"
            custom
            :to="{
              name: 'campaignBusinessGeneralInfo',
              params: { businessId: campaign.campaignBusiness.id },
            }"
          >
            <button
              class="nav-link"
              :class="businessOpened && 'active'"
              @click="navigate"
            >
              {{ $t("campaign.tabs.business.title") }}
            </button>
          </router-link>
        </li>
        <li
          v-if="
            hasAuthority(authorities.PRE_SCREENING_READ) &&
            campaign.preScreeningId
          "
          class="nav-item"
        >
          <router-link
            v-slot="{ navigate }"
            custom
            :to="{
              name: 'campaignPreScreening',
              params: { preScreeningId: campaign.preScreeningId },
            }"
          >
            <button
              class="nav-link"
              :class="preScreeningOpened && 'active'"
              @click="navigate"
            >
              {{ $t("campaign.tabs.preScreening.title") }}
            </button>
          </router-link>
        </li>
        <TabLink to="campaignAttachments">
          {{ $t("campaign.tabs.attachments") }}
        </TabLink>
        <TabLink to="campaignDocuments">
          {{ $t("campaign.tabs.documents") }}
        </TabLink>
        <li
          v-if="hasAuthority(authorities.CAMPAIGN_READ) && campaign.id"
          class="nav-item"
        >
          <router-link
            v-slot="{ navigate }"
            custom
            :to="{
              name: 'campaignContent',
              params: { campaignId: campaign.id },
            }"
          >
            <button
              class="nav-link"
              :class="contentOpened && 'active'"
              @click="navigate"
            >
              {{ $t("campaign.tabs.content.title") }}
            </button>
          </router-link>
        </li>
        <TabLink to="campaignDetails">
          {{ $t("campaign.tabs.details") }}
        </TabLink>
        <TabLink to="campaignReviewNotes">
          {{ $t("campaign.tabs.reviewNotes") }}
        </TabLink>
        <TabLink
          to="campaignFundraiserVerification"
          v-if="hasAuthority(authorities.CAMPAIGN_FUNDRAISER_VERIFICATION)"
        >
          {{ $t("campaign.tabs.fundraiserVerification") }}
        </TabLink>
        <TabLink
          to="campaignCampaignVerification"
          v-if="hasAuthority(authorities.CAMPAIGN_CAMPAIGN_VERIFICATION)"
        >
          {{ $t("campaign.tabs.campaignVerification") }}
        </TabLink>
        <TabLink
          to="campaignCollateralReview"
          v-if="hasAuthority(authorities.CAMPAIGN_COLLATERAL_REVIEW)"
        >
          {{ $t("campaign.tabs.collateralReview") }}
        </TabLink>
      </ul>
    </div>
    <CollapseTransition
      v-if="
        hasAuthority(authorities.BUSINESS_READ) && campaign.campaignBusiness
      "
    >
      <div class="card-foot sub_tab_foot" v-show="businessOpened">
        <ul class="nav nav-tabs mb-3">
          <TabLink
            to="campaignBusinessGeneralInfo"
            :props="{ businessId: campaign.campaignBusiness.id }"
            >{{ $t("campaign.tabs.business.legal") }}
          </TabLink>
          <TabLink
            to="campaignBusinessContactInfo"
            :props="{ businessId: campaign.campaignBusiness.id }"
            >{{ $t("campaign.tabs.business.contact") }}
          </TabLink>
          <TabLink
            to="campaignBusinessFinanceInfo"
            :props="{ businessId: campaign.campaignBusiness.id }"
            >{{ $t("campaign.tabs.business.finance") }}
          </TabLink>
          <TabLink
            to="campaignBusinessCommerceInfo"
            :props="{ businessId: campaign.campaignBusiness.id }"
            >{{ $t("campaign.tabs.business.commerce") }}
          </TabLink>
          <TabLink
            to="campaignBusinessBankInfo"
            :props="{ businessId: campaign.campaignBusiness.id }"
            >{{ $t("campaign.tabs.business.bank") }}
          </TabLink>
          <TabLink
            to="campaignBusinessOwnershipInfo"
            :props="{ businessId: campaign.campaignBusiness.id }"
            >{{ $t("campaign.tabs.business.ownership") }}
          </TabLink>
          <TabLink
            to="campaignBusinessAttachments"
            :props="{ businessId: campaign.campaignBusiness.id }"
            >{{ $t("campaign.tabs.business.attachments") }}
          </TabLink>
        </ul>
      </div>
    </CollapseTransition>
    <CollapseTransition
      v-if="
        hasAuthority(authorities.PRE_SCREENING_READ) && campaign.preScreeningId
      "
    >
      <div class="card-foot sub_tab_foot" v-show="preScreeningOpened">
        <ul class="nav nav-tabs mb-3">
          <TabLink
            to="campaignPreScreeningGeneralInfo"
            :props="{ preScreeningId: campaign.preScreeningId }"
            >{{ $t("campaign.tabs.preScreening.general") }}
          </TabLink>
          <TabLink
            to="campaignPreScreeningDetails"
            :props="{ preScreeningId: campaign.preScreeningId }"
            >{{ $t("campaign.tabs.preScreening.details") }}
          </TabLink>
          <TabLink
            to="campaignPreScreeningAttachments"
            :props="{ preScreeningId: campaign.preScreeningId }"
            >{{ $t("campaign.tabs.preScreening.attachments") }}
          </TabLink>
          <TabLink
            to="campaignPreScreeningReviewNotes"
            :props="{ preScreeningId: campaign.preScreeningId }"
            >{{ $t("campaign.tabs.preScreening.reviewNotes") }}
          </TabLink>
          <TabLink
            to="campaignPreScreeningFollowUpNotes"
            :props="{ preScreeningId: campaign.preScreeningId }"
            >{{ $t("campaign.tabs.preScreening.followUpNotes") }}
          </TabLink>
        </ul>
      </div>
    </CollapseTransition>
    <CollapseTransition
      v-if="hasAuthority(authorities.CAMPAIGN_READ) && campaign.id"
    >
      <div class="card-foot sub_tab_foot" v-show="contentOpened">
        <ul class="nav nav-tabs mb-3">
          <TabLink to="campaignContent"
            >{{ $t("campaign.tabs.content.title") }}
          </TabLink>
          <TabLink to="campaignContentFiles"
            >{{ $t("campaign.tabs.content.filesTitle") }}
          </TabLink>
        </ul>
      </div>
    </CollapseTransition>
  </div>
  <div class="row">
    <div class="tab-content">
      <div class="tab-pane active">
        <router-view @update="fetchData"></router-view>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import TabLink from "@/components/TabLink.vue";
import Headline from "@/components/Headline.vue";
import { campaignApi, campaignOperationApi } from "@/resources";
import {
  BackOfficeFlowEntityStateCampaignStatus,
  BaseCampaignOperationRequest,
  CampaignItem,
  CampaignStatus,
} from "@/api";
import { authorityMixin } from "@/util";
import Status from "@/components/Status.vue";
import BackLink from "@/components/BackLink.vue";
import { watchCampaignUpdate } from "@/util/ws";
import BaseCampaignReasonDialog from "@/views/campaign/dialogs/BaseCampaignReasonDialog.vue";
import DeclineCampaignDialog from "@/views/campaign/dialogs/DeclineCampaignDialog.vue";
import ApproveCampaignDialog from "@/views/campaign/dialogs/ApproveCampaignDialog.vue";
import { mapState } from "vuex";
import QAChangeCampaignStatusDialog from "@/views/campaign/dialogs/QAChangeCampaignStatusDialog.vue";
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";
import OkCancelDialog from "@/views/components/OkCancelDialog.vue";
import ExtendCampaignDurationDialog from "@/views/campaign/dialogs/ExtendCampaignDurationDialog.vue";

export default defineComponent({
  name: "CampaignView",
  mixins: [authorityMixin],
  components: {
    ExtendCampaignDurationDialog,
    OkCancelDialog,
    QAChangeCampaignStatusDialog,
    ApproveCampaignDialog,
    DeclineCampaignDialog,
    BaseCampaignReasonDialog,
    Headline,
    Status,
    TabLink,
    BackLink,
    CollapseTransition,
  },
  provide() {
    return {
      campaign: computed(() => (this.campaign.id ? this.campaign : {})),
      summary: computed(() =>
        this.campaign?.summary ? this.campaign.summary : {}
      ),
      state: computed(() => (this.state.nextStatuses ? this.state : {})),
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      campaignUpdatedSubscription: watchCampaignUpdate(this.id, () => {
        this.$notify.info(this.$t("campaign.updated"));
        this.fetchData(this.id);
      }),
      campaign: {} as CampaignItem,
      state: {} as BackOfficeFlowEntityStateCampaignStatus,
      campaignStatuses: CampaignStatus,
      allowedForExtendDuration: [
        CampaignStatus.FUNDRAISING_LIVE,
        CampaignStatus.FUNDRAISING_RESERVE,
        CampaignStatus.TEMPORARILY_SUSPENDED,
      ] as CampaignStatus[],
    };
  },
  async mounted() {
    await Promise.all([this.fetchData()]);
  },
  async beforeRouteLeave() {
    this.campaignUpdatedSubscription.unsubscribe();
  },
  methods: {
    async fetchData(id?: string) {
      id = id ?? this.id;
      await Promise.all([
        this.fetchCampaign(Number(id)),
        this.fetchState(Number(id)),
      ]);
    },
    async fetchCampaign(id: number) {
      this.campaign = await campaignApi.campaign({ id: id });
    },
    async fetchState(id: number) {
      this.state = await campaignApi.state({ id: id });
    },
    async cancelCampaign(form: BaseCampaignOperationRequest) {
      await campaignOperationApi.cancelCampaign({
        id: this.campaign.id,
        baseCampaignOperationRequest: form,
      });
    },
    async suspendCampaign(form: BaseCampaignOperationRequest) {
      await campaignOperationApi.suspendCampaign({
        id: this.campaign.id,
        baseCampaignOperationRequest: form,
      });
    },
    async waitingForData(form: BaseCampaignOperationRequest) {
      await campaignOperationApi.waitingForData({
        id: this.campaign.id,
        baseCampaignOperationRequest: form,
      });
    },
    async revertSuspendCampaign(form: BaseCampaignOperationRequest) {
      await campaignOperationApi.revertSuspendCampaign({
        id: this.campaign.id,
        baseCampaignOperationRequest: form,
      });
    },
    async revertCancelCampaign(form: BaseCampaignOperationRequest) {
      await campaignOperationApi.revertCancelCampaign({
        id: this.campaign.id,
        baseCampaignOperationRequest: form,
      });
    },
    async verifyBusiness() {
      await campaignOperationApi.verifyBusiness({
        id: this.campaign.id,
      });
    },
    async registrationFee() {
      await campaignOperationApi.waitingForRegistrationFeeCampaign({
        id: this.campaign.id,
      });
    },
    async manualDisbursement() {
      await campaignOperationApi.manualDisbursement({
        id: this.campaign.id,
      });
    },
    async disbursement() {
      await campaignOperationApi.disbursement({
        id: this.campaign.id,
      });
    },
    async spv() {
      await campaignOperationApi.spv({
        id: this.campaign.id,
      });
    },
    async fundraising() {
      await campaignOperationApi.fundraising({
        id: this.campaign.id,
      });
    },
    async signingAgreement() {
      await campaignOperationApi.signingAgreement({
        id: this.campaign.id,
      });
    },
  },
  watch: {
    async id() {
      await Promise.all([this.fetchData(this.id)]);
    },
  },
  computed: {
    businessOpened(): boolean {
      return this.$route.name
        ? this.$route.name.toString().startsWith("campaignBusiness")
        : false;
    },
    preScreeningOpened(): boolean {
      return this.$route.name
        ? this.$route.name.toString().startsWith("campaignPreScreening")
        : false;
    },
    contentOpened(): boolean {
      return this.$route.name
        ? this.$route.name.toString().startsWith("campaignContent")
        : false;
    },
    ...mapState("feature", ["qaEnabled"]),
  },
});
</script>
<style lang="scss" scoped>
@import "src/styles/tabs";
</style>
