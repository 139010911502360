/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CampaignDeclineReason,
    CampaignDeclineReasonFromJSON,
    CampaignDeclineReasonFromJSONTyped,
    CampaignDeclineReasonToJSON,
} from './';

/**
 * 
 * @export
 * @interface DeclineCampaignOperationRequest
 */
export interface DeclineCampaignOperationRequest {
    /**
     * 
     * @type {CampaignDeclineReason}
     * @memberof DeclineCampaignOperationRequest
     */
    declineReason: CampaignDeclineReason;
    /**
     * 
     * @type {string}
     * @memberof DeclineCampaignOperationRequest
     */
    reason?: string;
}

export function DeclineCampaignOperationRequestFromJSON(json: any): DeclineCampaignOperationRequest {
    return DeclineCampaignOperationRequestFromJSONTyped(json, false);
}

export function DeclineCampaignOperationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeclineCampaignOperationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'declineReason': CampaignDeclineReasonFromJSON(json['declineReason']),
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
    };
}

export function DeclineCampaignOperationRequestToJSON(value?: DeclineCampaignOperationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'declineReason': CampaignDeclineReasonToJSON(value.declineReason),
        'reason': value.reason,
    };
}


