
import { defineComponent, inject } from "vue";
import { Investor } from "@/api";
import ContactPerson from "@/views/components/ContactPerson.vue";
import InvestorIndividualContact from "@/views/investor/components/InvestorIndividualContact.vue";
import LegalContact from "@/views/components/LegalContact.vue";
import CurrentAddress from "@/views/components/CurrentAddress.vue";
import PermanentAddress from "@/views/components/PermanentAddress.vue";

export default defineComponent({
  name: "InvestorContactDetails",
  components: {
    LegalContact,
    InvestorIndividualContact,
    ContactPerson,
    CurrentAddress,
    PermanentAddress,
  },
  data() {
    return {
      investor: inject("investor") as Investor,
    };
  },
});
