<template>
  <section class="comments" v-if="notes.length > 0 || !createNoteDisabled">
    <el-form
      v-if="!createNoteDisabled"
      :model="form"
      ref="form"
      :rules="rules"
      @validate="onValidate"
    >
      <article class="comment">
        <div class="comment-img">
          <span class="initials">{{ initials(profile.userFullName) }}</span>
        </div>

        <div class="comment-body">
          <div class="text">
            <el-form-item prop="note" :label="$t(`${translationPrefix}.title`)">
              <el-input
                :maxlength="1000"
                :placeholder="$t(`${translationPrefix}.placeholder`)"
                show-word-limit
                type="textarea"
                :autosize="{ minRows: 5 }"
                resize="vertical"
                v-model="form.note"
              >
              </el-input>
            </el-form-item>
            <el-button
              class="post-button"
              size="small"
              type="primary"
              @click="postNote"
              :disabled="!isFormValid"
              >{{ $t(`${translationPrefix}.post`) }}</el-button
            >
          </div>
          <p class="note attribution">
            by
            <router-link
              v-if="hasAuthority(authorities.USER_READ)"
              :to="{ name: 'user', params: { id: profile.id } }"
              >{{ profile.userFullName }}
            </router-link>
            <span v-else>{{ profile.userFullName }}</span>
          </p>
        </div>
      </article>
    </el-form>
    <div v-if="notes">
      <article class="comment" v-for="note in notes" v-bind:key="note">
        <div class="comment-img">
          <span class="initials">{{ initials(note.user.name) }}</span>
        </div>
        <Note
          :key="note.id"
          :data="note"
          :hide-method="hideNote"
          :delete-note-method="deleteNote"
          :update-note-method="updateNote"
          :edit-disabled="editDisabled"
          :delete-disabled="deleteDisabled"
          :translation-prefix="translationPrefix"
        />
      </article>
      <el-button
        class="load-button"
        v-if="!noMoreNotes"
        v-loading="loading"
        @click="loadMore"
      >
        {{ loading ? $t("loading") : $t("load") }}
      </el-button>
    </div>
  </section>
  <div v-else>
    <span>{{ $t("noData") }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { NoteItem, NoteRequest, PageNoteItem } from "@/api";
import { authorityMixin, PageRequest } from "@/util";
import { mapState } from "vuex";
import { ElementForm, InputRequired } from "@/util/validation";
import { isConstraintViolation, isProblem } from "@/resources/problem";
import Note from "@/views/components/Note.vue";

export default defineComponent({
  name: "Notes",
  components: { Note },
  mixins: [authorityMixin],
  props: {
    loadNotes: {
      type: Function,
      required: true,
    },
    createNoteDisabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    createNote: {
      type: Function,
      required: false,
    },
    deleteNote: {
      type: Function,
      required: false,
      default: () => null,
    },
    updateNote: {
      type: Function,
      required: false,
      default: () => null,
    },
    editDisabled: {
      type: Function,
      required: false,
      default: () => true,
    },
    deleteDisabled: {
      type: Function,
      required: false,
      default: () => true,
    },
    translationPrefix: {
      type: String,
      required: false,
      default: "note",
    },
  },
  data() {
    return {
      notes: [] as NoteItem[],
      pageRequest: {} as PageRequest,
      lastNotePage: {} as PageNoteItem,
      form: {} as NoteRequest,
      isFormValid: true,
      loading: false,
      rules: {
        note: [new InputRequired("validation.inputRequired")],
      },
    };
  },
  async mounted() {
    await this.fetchNotesInitially();
  },
  methods: {
    initials(name: string): string {
      return name
        ? name
            .split(" ")
            .map((el) => el[0])
            .join("")
        : "";
    },
    async loadMore() {
      if (!this.lastNotePage.last && !this.loading) {
        this.loading = true;
        this.pageRequest.page = this.pageRequest.page + 1;
        await this.fetchNotes();
        this.loading = false;
      }
    },
    async fetchNotes() {
      this.lastNotePage = await this.loadNotes(this.pageRequest);
      if (this.lastNotePage.content) {
        this.notes.push(...this.lastNotePage.content);
      }
    },
    async fetchNotesInitially() {
      this.loading = true;
      this.pageRequest.page = 0;
      this.lastNotePage = await this.loadNotes(this.pageRequest);
      this.notes = this.lastNotePage.content ? this.lastNotePage.content : [];
      this.loading = false;
    },
    onValidate(prop: string, isVal: boolean) {
      this.isFormValid = isVal;
    },
    async postNote() {
      const form = this.$refs["form"] as ElementForm;
      if ((await form.validate()) && this.createNote) {
        try {
          await this.createNote(this.form);
          await this.fetchNotesInitially();
        } catch (e) {
          if (isConstraintViolation(e)) {
            e.violations.forEach((violation) =>
              this.$notify.error(this.$t(violation.message))
            );
          } else if (isProblem(e)) {
            this.$notify.error(this.$t(e.detail));
          }
        }
        this.form = {} as NoteRequest;
      }
    },
    hideNote(noteId: number) {
      this.notes = this.notes.filter((noteItem) => noteItem.id !== noteId);
    },
  },
  computed: {
    ...mapState("account", ["profile"]),
    noMoreNotes(): boolean {
      return this.lastNotePage.last === undefined || this.lastNotePage.last;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.load-button {
  margin: 0 auto;
  display: block;
}

.post-button {
  display: block;
  margin-left: auto;
  margin-right: 0;
}

.comment {
  overflow: hidden;
  padding: 0 0 1em;
  border-bottom: 1px solid #ddd;
  margin: 0 0 1em;
  *zoom: 1;
}

.comment-img {
  float: left;
  margin-right: 33px;
  border-radius: 5px;
  overflow: hidden;
}

.initials {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: $color-primary;
  color: #fff;
  margin-right: 10px;
}

.comment-img img {
  display: block;
}

.comment-body {
  overflow: hidden;
}

.comment .text {
  padding: 10px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  background: #fff;
}

.comment .text p:last-child {
  margin: 0;
}

.comment .attribution {
  margin: 0.5em 0 0;
  font-size: 14px;
  color: #666;
}

/* Decoration */

.comments,
.comment {
  position: relative;
}

.comments:before,
.comment:before,
.comment .text:before {
  content: "";
  position: absolute;
  top: 0;
  left: 65px;
}

.comments:before {
  width: 3px;
  top: -20px;
  bottom: -20px;
  background: rgba(0, 0, 0, 0.1);
}

.comment:before {
  width: 9px;
  height: 9px;
  border: 3px solid #fff;
  border-radius: 100px;
  margin: 16px 0 0 -6px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), inset 0 1px 1px rgba(0, 0, 0, 0.1);
  background: #ccc;
}

.comment:hover:before {
  background: orange;
}

.comment .text:before {
  top: 18px;
  left: 78px;
  width: 9px;
  height: 9px;
  border-width: 0 0 1px 1px;
  border-style: solid;
  border-color: #e5e5e5;
  background: #fff;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}
</style>
