/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApprovedCampaignData,
    ApprovedCampaignDataFromJSON,
    ApprovedCampaignDataFromJSONTyped,
    ApprovedCampaignDataToJSON,
    CampaignDeclineReason,
    CampaignDeclineReasonFromJSON,
    CampaignDeclineReasonFromJSONTyped,
    CampaignDeclineReasonToJSON,
    CampaignStatus,
    CampaignStatusFromJSON,
    CampaignStatusFromJSONTyped,
    CampaignStatusToJSON,
    CampaignSummaryItem,
    CampaignSummaryItemFromJSON,
    CampaignSummaryItemFromJSONTyped,
    CampaignSummaryItemToJSON,
    IdNameEntry,
    IdNameEntryFromJSON,
    IdNameEntryFromJSONTyped,
    IdNameEntryToJSON,
    RequestedCampaignData,
    RequestedCampaignDataFromJSON,
    RequestedCampaignDataFromJSONTyped,
    RequestedCampaignDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface CampaignItem
 */
export interface CampaignItem {
    /**
     * 
     * @type {ApprovedCampaignData}
     * @memberof CampaignItem
     */
    approvedData?: ApprovedCampaignData;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof CampaignItem
     */
    business: IdNameEntry;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof CampaignItem
     */
    campaignBusiness: IdNameEntry;
    /**
     * 
     * @type {Date}
     * @memberof CampaignItem
     */
    campaignFinishedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CampaignItem
     */
    campaignStartedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CampaignItem
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof CampaignItem
     */
    disbursementFinishedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof CampaignItem
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignItem
     */
    preScreeningId: number;
    /**
     * 
     * @type {RequestedCampaignData}
     * @memberof CampaignItem
     */
    requestedData: RequestedCampaignData;
    /**
     * 
     * @type {CampaignStatus}
     * @memberof CampaignItem
     */
    status: CampaignStatus;
    /**
     * 
     * @type {CampaignSummaryItem}
     * @memberof CampaignItem
     */
    summary: CampaignSummaryItem;
    /**
     * 
     * @type {Date}
     * @memberof CampaignItem
     */
    terminatedAt?: Date;
    /**
     * 
     * @type {CampaignDeclineReason}
     * @memberof CampaignItem
     */
    terminatedReason?: CampaignDeclineReason;
}

export function CampaignItemFromJSON(json: any): CampaignItem {
    return CampaignItemFromJSONTyped(json, false);
}

export function CampaignItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): CampaignItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'approvedData': !exists(json, 'approvedData') ? undefined : ApprovedCampaignDataFromJSON(json['approvedData']),
        'business': IdNameEntryFromJSON(json['business']),
        'campaignBusiness': IdNameEntryFromJSON(json['campaignBusiness']),
        'campaignFinishedAt': !exists(json, 'campaignFinishedAt') ? undefined : (new Date(json['campaignFinishedAt'])),
        'campaignStartedAt': !exists(json, 'campaignStartedAt') ? undefined : (new Date(json['campaignStartedAt'])),
        'createdAt': (new Date(json['createdAt'])),
        'disbursementFinishedAt': !exists(json, 'disbursementFinishedAt') ? undefined : (new Date(json['disbursementFinishedAt'])),
        'id': json['id'],
        'preScreeningId': json['preScreeningId'],
        'requestedData': RequestedCampaignDataFromJSON(json['requestedData']),
        'status': CampaignStatusFromJSON(json['status']),
        'summary': CampaignSummaryItemFromJSON(json['summary']),
        'terminatedAt': !exists(json, 'terminatedAt') ? undefined : (new Date(json['terminatedAt'])),
        'terminatedReason': !exists(json, 'terminatedReason') ? undefined : CampaignDeclineReasonFromJSON(json['terminatedReason']),
    };
}

export function CampaignItemToJSON(value?: CampaignItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'approvedData': ApprovedCampaignDataToJSON(value.approvedData),
        'business': IdNameEntryToJSON(value.business),
        'campaignBusiness': IdNameEntryToJSON(value.campaignBusiness),
        'campaignFinishedAt': value.campaignFinishedAt === undefined ? undefined : (value.campaignFinishedAt.toISOString().substr(0,10)),
        'campaignStartedAt': value.campaignStartedAt === undefined ? undefined : (value.campaignStartedAt.toISOString().substr(0,10)),
        'createdAt': (value.createdAt.toISOString().substr(0,10)),
        'disbursementFinishedAt': value.disbursementFinishedAt === undefined ? undefined : (value.disbursementFinishedAt.toISOString().substr(0,10)),
        'id': value.id,
        'preScreeningId': value.preScreeningId,
        'requestedData': RequestedCampaignDataToJSON(value.requestedData),
        'status': CampaignStatusToJSON(value.status),
        'summary': CampaignSummaryItemToJSON(value.summary),
        'terminatedAt': value.terminatedAt === undefined ? undefined : (value.terminatedAt.toISOString().substr(0,10)),
        'terminatedReason': CampaignDeclineReasonToJSON(value.terminatedReason),
    };
}


