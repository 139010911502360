<template>
  <div class="card">
    <div class="card-main">
      <BackLink />
      <Headline v-if="investor.id" :class="'align-items-start'">
        <div class="h1-sm" style="display: inline">
          {{
            investor.type === "INDIVIDUAL"
              ? investor.individual.person.givenName +
                " " +
                investor.individual.person.lastName
              : investor.legal.name
          }}
        </div>
      </Headline>
    </div>
    <div class="card-foot">
      <ul class="nav nav-tabs mb-3" v-if="investor.type === 'INDIVIDUAL'">
        <TabLink to="investorGeneralInfo">
          {{ $t("investor.tabs.generalInfo") }}
        </TabLink>
        <TabLink to="investorContactDetails">
          {{ $t("investor.tabs.contactDetails") }}
        </TabLink>
        <TabLink to="investorOccupationIncome">
          {{ $t("investor.tabs.occupationIncome") }}
        </TabLink>
        <TabLink to="investorBankDetails">
          {{ $t("investor.tabs.bankDetails") }}
        </TabLink>
        <TabLink to="investorAttachments">
          {{ $t("investor.tabs.attachments") }}
        </TabLink>
      </ul>
      <ul class="nav nav-tabs mb-3" v-else>
        <TabLink to="investorGeneralInfo">
          {{ $t("investor.tabs.generalInfo") }}
        </TabLink>
        <TabLink to="investorContactDetails">
          {{ $t("investor.tabs.contactDetails") }}
        </TabLink>
        <TabLink to="investorFinancialInfo">
          {{ $t("investor.tabs.financialInfo") }}
        </TabLink>
        <TabLink to="investorBankDetails">
          {{ $t("investor.tabs.bankDetails") }}
        </TabLink>
        <TabLink to="investorOwnershipInfo">
          {{ $t("investor.tabs.ownership") }}
        </TabLink>
        <TabLink to="investorAttachments">
          {{ $t("investor.tabs.attachments") }}
        </TabLink>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="tab-content">
      <div class="tab-pane active">
        <router-view @update="fetchData"></router-view>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import TabLink from "@/components/TabLink.vue";
import Headline from "@/components/Headline.vue";
import { investorApi } from "@/resources";
import { Investor } from "@/api";
import BackLink from "@/components/BackLink.vue";

export default defineComponent({
  name: "InvestorView",
  components: {
    Headline,
    TabLink,
    BackLink,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  provide() {
    return {
      investorId: computed(() => this.id),
      investor: computed(() => this.investor),
    };
  },
  data() {
    return {
      investor: {} as Investor,
    };
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchInvestor() {
      this.investor = await investorApi.investor({ id: Number(this.id) });
    },
    async fetchData() {
      await Promise.all([this.fetchInvestor()]);
    },
  },
});
</script>
