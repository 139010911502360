<template>
  <div class="container-md ms-0 mb-5" v-if="!editable && user">
    <div class="row mb-3">
      <div class="col color-light-text">{{ $t("user.givenName") }}</div>
      <div class="col">
        {{ user.person.givenName ?? "-" }}
      </div>
      <div class="col color-light-text">{{ $t("user.birthDate") }}</div>
      <div class="col">
        {{ $date(user.person.birthDate) }}
      </div>
    </div>
    <div class="row mb-3">
      <div class="col color-light-text">{{ $t("user.lastName") }}</div>
      <div class="col">
        {{ user.person.lastName ?? "-" }}
      </div>
      <div class="col color-light-text">{{ $t("user.residenceCountry") }}</div>
      <div class="col">
        {{ $enums.globals.country(user.person.residenceCountry) }}
      </div>
    </div>
    <div class="row mb-3">
      <div class="col color-light-text">{{ $t("user.phone") }}</div>
      <div class="col">
        {{ $phone(user.person.phone) }}
      </div>
      <div class="col color-light-text">{{ $t("user.role") }}</div>
      <div class="col">
        <div v-if="user.role === 'Admin'">
          {{ user.role }}
        </div>
        <router-link v-else :to="{ name: 'role', params: { id: user.roleId } }"
          >{{ user.role }}
        </router-link>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col color-light-text">{{ $t("user.email") }}</div>
      <div class="col">
        {{ user.person.email ?? "-" }}
      </div>
      <div class="col color-light-text"></div>
      <div class="col"></div>
    </div>
  </div>

  <div v-if="editable && userForm" class="container-md ms-0 mb-5">
    <el-form :model="userForm" :rules="rules" status-icon ref="userForm">
      <div class="row">
        <div class="col-md-6">
          <el-form-item :label="$t('user.givenName')" prop="givenName">
            <el-input v-model="userForm.givenName"></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.lastName')" prop="lastName">
            <el-input v-model="userForm.lastName"></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.phone')" prop="phone">
            <MaskedPhoneNumber v-model="userForm.phone"></MaskedPhoneNumber>
          </el-form-item>
          <el-form-item :label="$t('user.email')" prop="email">
            <el-input v-model="userForm.email" disabled></el-input>
          </el-form-item>
        </div>
        <div class="col-md-6">
          <el-form-item :label="$t('user.birthDate')" prop="birthDate">
            <el-date-picker
              type="date"
              v-model="userForm.birthDate"
              class="w-100"
              format="DD/MM/YYYY"
            ></el-date-picker>
          </el-form-item>
          <el-form-item
            :label="$t('user.residenceCountry')"
            prop="residenceCountry"
            v-if="userForm.role !== 'Admin'"
          >
            <el-select
              v-model="userForm.residenceCountry"
              class="w-100"
              clearable
            >
              <el-option
                v-for="(country, key) in residenceCountryOptions"
                :key="key"
                :label="$enums.globals.country(country)"
                :value="country"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :label="$t('user.role')"
            prop="role"
            v-if="userForm.role !== 'Admin'"
          >
            <el-select v-model="userForm.role" clearable class="w-100">
              <el-option
                v-for="(role, id) in roles"
                :key="id"
                :value="role"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>

  <template v-if="editable">
    <el-button @click="editable = false">
      {{ $t("cancel") }}
    </el-button>
    <el-button type="primary" @click="submit">
      {{ $t("save") }}
    </el-button>
  </template>
  <template v-if="!editable">
    <el-button
      v-if="hasAuthority(authorities.USER_WRITE)"
      type="default"
      @click="edit"
    >
      {{ $t("edit") }}
    </el-button>
    <el-button
      type="primary"
      v-if="user.status === 'INACTIVE'"
      @click="resendLink"
      >{{ $t("user.resendLink") }}
    </el-button>
  </template>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import {
  ElementForm,
  InputLength,
  InputRequired,
  nameFormat,
} from "@/util/validation";
import { Country, UpdateUserForm, User, UserHistory } from "@/api";
import { roleApi, userApi } from "@/resources";
import { authorityMixin, fixLocalDate } from "@/util";
import { isConstraintViolation, isProblem } from "@/resources/problem";
import MaskedPhoneNumber from "@/components/MaskedPhoneNumber/MaskedPhoneNumber.vue";

export default defineComponent({
  name: "UserDetails",
  components: { MaskedPhoneNumber },
  mixins: [authorityMixin],
  emits: ["update"],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      residenceCountryOptions: Country,
      user: inject("user") as User,
      roles: [] as string[],
      userForm: {} as UpdateUserForm,
      editable: false,
      rules: {
        givenName: [new InputRequired(), new InputLength(100), nameFormat],
        lastName: [new InputRequired(), new InputLength(100), nameFormat],
        birthDate: [new InputRequired()],
        residenceCountry: [new InputRequired()],
        phone: [new InputRequired("validation.phoneFormatRequired")],
        role: [new InputRequired("validation.selectRequired")],
      },
      history: inject("history") as UserHistory[],
    };
  },
  async mounted() {
    this.roles = await roleApi.roleOptions();
  },
  methods: {
    edit() {
      Object.assign(this.userForm, { ...this.user, ...this.user.person });
      this.editable = true;
    },
    submit() {
      const form = this.$refs["userForm"] as ElementForm;
      form.validate((valid) => {
        if (valid) {
          this.updateUser();
        }
        return false;
      });
    },
    async updateUser() {
      try {
        this.userForm.birthDate = fixLocalDate(this.userForm.birthDate);
        await userApi.updateUser({
          id: Number(this.id),
          updateUserForm: this.userForm,
        });
        this.$notify.info(this.$t("user.userUpdated"));
        Object.assign(this.user, {
          ...this.userForm,
          person: { ...this.userForm },
        });
        this.editable = false;
        this.$emit("update");
      } catch (error) {
        if (isConstraintViolation(error)) {
          error.violations.forEach((violation) =>
            this.$notify.error(this.$t(violation.message))
          );
        }
        if (isProblem(error)) {
          this.$notify.error(this.$t(error.detail));
        }
      }
    },
    async resendLink() {
      try {
        await userApi.resendRegistrationLink({
          id: Number(this.id),
        });
        this.$notify.info(this.$t("user.resendLinkSent"));
      } catch (error) {
        if (isConstraintViolation(error)) {
          error.violations.forEach((violation) =>
            this.$notify.error(this.$t(violation.message))
          );
        }
        if (isProblem(error)) {
          this.$notify.error(this.$t(error.detail));
        }
      }
    },
  },
});
</script>
