
import { defineComponent } from "vue";
import { batchApi } from "@/resources";
import Headline from "@/components/Headline.vue";
import { PageBackOfficeBatchListItem, TransactionType } from "@/api";
import { authorityMixin, PageRequest, pageSortMixin } from "@/util";
import TableFooter from "@/components/TableFooter.vue";
import Status from "@/components/Status.vue";
import OkCancelDialog from "@/views/components/OkCancelDialog.vue";

export default defineComponent({
  name: "BatchList",
  components: { Status, Headline, TableFooter, OkCancelDialog },
  mixins: [pageSortMixin, authorityMixin],
  data: () => ({
    batchPage: {} as PageBackOfficeBatchListItem,
    pageRequest: {} as PageRequest,
    types: TransactionType,
  }),
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.batchPage = await batchApi.batches({
        ...this.pageRequest,
      });
    },
    async generateBatchB2B() {
      return await batchApi.generateBatchB2B();
    },
    async downloadBatchFile(id: number, name: string) {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(await batchApi.batchFile({ id: id }));
      link.download = name;
      link.click();
    },
  },
});
