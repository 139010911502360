/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FieldHistoryView,
    FieldHistoryViewFromJSON,
    FieldHistoryViewFromJSONTyped,
    FieldHistoryViewToJSON,
} from './';

/**
 * 
 * @export
 * @interface InvestorIndividualLimitSettingsHistoryItem
 */
export interface InvestorIndividualLimitSettingsHistoryItem {
    /**
     * 
     * @type {Array<FieldHistoryView>}
     * @memberof InvestorIndividualLimitSettingsHistoryItem
     */
    angel: Array<FieldHistoryView>;
    /**
     * 
     * @type {Array<FieldHistoryView>}
     * @memberof InvestorIndividualLimitSettingsHistoryItem
     */
    retail: Array<FieldHistoryView>;
}

export function InvestorIndividualLimitSettingsHistoryItemFromJSON(json: any): InvestorIndividualLimitSettingsHistoryItem {
    return InvestorIndividualLimitSettingsHistoryItemFromJSONTyped(json, false);
}

export function InvestorIndividualLimitSettingsHistoryItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestorIndividualLimitSettingsHistoryItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'angel': ((json['angel'] as Array<any>).map(FieldHistoryViewFromJSON)),
        'retail': ((json['retail'] as Array<any>).map(FieldHistoryViewFromJSON)),
    };
}

export function InvestorIndividualLimitSettingsHistoryItemToJSON(value?: InvestorIndividualLimitSettingsHistoryItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'angel': ((value.angel as Array<any>).map(FieldHistoryViewToJSON)),
        'retail': ((value.retail as Array<any>).map(FieldHistoryViewToJSON)),
    };
}


