import { Actor as actor } from "@/util/enum/actor";
import { Campaign as campaign } from "@/util/enum/campaign";
import { Legal as legal } from "@/util/enum/legal";
import { StartPageConvertors as startPage } from "@/util/enum/page";
import { User as user } from "@/util/enum/user";
import { PortalUser as portalUser } from "@/util/enum/portalUser";
import { Investor as investor } from "@/util/enum/investor";
import { Investment as investment } from "@/util/enum/investment";
import { Globals as globals } from "@/util/enum/globals";
import { Bank as bank } from "@/util/enum/bank";
import { Transaction as transaction } from "@/util/enum/transaction";
import { PreScreening as preScreening } from "@/util/enum/prescreening";
import { Accreditation as accreditation } from "@/util/enum/accreditation";
import { Batch as batch } from "@/util/enum/batch";

export const enums = {
  actor,
  campaign,
  preScreening,
  legal,
  startPage,
  user,
  portalUser,
  investor,
  investment,
  globals,
  bank,
  transaction,
  batch,
  accreditation,
};
