<template>
  <div class="card">
    <div class="card-main">
      <div class="d-flex justify-content-between align-items-start">
        <BackLink />
      </div>
      <Headline v-if="business.legal">
        {{ business.legal.name }}
      </Headline>
    </div>
    <div class="card-foot">
      <ul class="nav nav-tabs mb-3">
        <TabLink to="businessGeneralInfo"
          >{{ $t("business.tabs.legal") }}
        </TabLink>
        <TabLink to="businessContactInfo"
          >{{ $t("business.tabs.contact") }}
        </TabLink>
        <TabLink to="businessFinanceInfo"
          >{{ $t("business.tabs.finance") }}
        </TabLink>
        <TabLink to="businessCommerceInfo"
          >{{ $t("business.tabs.commerce") }}
        </TabLink>
        <TabLink to="businessBankInfo">{{ $t("business.tabs.bank") }} </TabLink>
        <TabLink to="businessOwnershipInfo"
          >{{ $t("business.tabs.ownership") }}
        </TabLink>
        <TabLink to="businessAttachments"
          >{{ $t("business.tabs.attachments") }}
        </TabLink>
      </ul>
    </div>
  </div>
  <div class="tab-content">
    <div class="tab-pane active">
      <router-view @update="fetchBusinessData"></router-view>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import TabLink from "@/components/TabLink.vue";
import { BusinessItem, LegalCommerceItem } from "@/api";
import { businessApi } from "@/resources";
import Headline from "@/components/Headline.vue";
import BackLink from "@/components/BackLink.vue";
import { authorityMixin } from "@/util";
import { watchBusinessUpdate } from "@/util/ws";

export default defineComponent({
  name: "BusinessView",
  mixins: [authorityMixin],
  components: { Headline, TabLink, BackLink },
  props: ["id"],
  provide() {
    return {
      businessId: computed(() => this.id),
      legal: computed(() => (this.business.legal ? this.business.legal : {})),
      address: computed(() =>
        this.business.address ? this.business.address : {}
      ),
      finance: computed(() =>
        this.business.finance ? this.business.finance : {}
      ),
      commerce: computed(() => (this.commerce.commerce ? this.commerce : {})),
    };
  },
  data() {
    return {
      business: {} as BusinessItem,
      commerce: {} as LegalCommerceItem,
      businessUpdatedSubscription: watchBusinessUpdate(this.id, () => {
        this.$notify.info(this.$t("business.updated"));
        this.fetchBusinessData();
      }),
    };
  },
  async beforeRouteLeave() {
    this.businessUpdatedSubscription.unsubscribe();
  },
  async mounted() {
    await this.fetchBusinessData();
  },
  methods: {
    async fetchBusinessData() {
      await Promise.all([this.fetchBusiness(), this.fetchCommerce()]);
    },
    async fetchBusiness() {
      this.business = await businessApi.business({ id: this.id });
    },
    async fetchCommerce() {
      this.commerce = await businessApi.commerce({ id: this.id });
    },
  },
});
</script>
<style lang="scss" scoped>
@import "src/styles/tabs";
</style>
