/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CommittedCampaignData
 */
export interface CommittedCampaignData {
    /**
     * 
     * @type {number}
     * @memberof CommittedCampaignData
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof CommittedCampaignData
     */
    percent: number;
}

export function CommittedCampaignDataFromJSON(json: any): CommittedCampaignData {
    return CommittedCampaignDataFromJSONTyped(json, false);
}

export function CommittedCampaignDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommittedCampaignData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'percent': json['percent'],
    };
}

export function CommittedCampaignDataToJSON(value?: CommittedCampaignData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'percent': value.percent,
    };
}


