
import { defineComponent, inject } from "vue";
import {
  PageLegalMemberItem,
  PageLegalOwnerItem,
  PageLegalPersonItem,
} from "@/api";
import { investorApi } from "@/resources";
import LegalMembers from "@/views/components/LegalMembers.vue";
import LegalOwners from "@/views/components/LegalOwners.vue";
import LegalPersons from "@/views/components/LegalPersons.vue";
import { PageRequest } from "@/util";

export default defineComponent({
  name: "InvestorOwnershipInfo",
  components: { LegalMembers, LegalOwners, LegalPersons },
  data() {
    return {
      investorId: inject("investorId") as number,
      ownersPage: {} as PageLegalOwnerItem,
      ownersPageRequest: {} as PageRequest,
      membersPage: {} as PageLegalMemberItem,
      membersPageRequest: {} as PageRequest,
      personsPage: {} as PageLegalPersonItem,
      personsPageRequest: {} as PageRequest,
    };
  },
  methods: {
    async fetchPersons() {
      this.personsPage = await investorApi.investorPersons({
        id: this.investorId,
        ...this.personsPageRequest,
      });
    },
    async fetchOwners() {
      this.ownersPage = await investorApi.investorOwners({
        id: this.investorId,
        ...this.ownersPageRequest,
      });
    },
    async fetchMembers() {
      this.membersPage = await investorApi.investorMembers({
        id: this.investorId,
        ...this.membersPageRequest,
      });
    },
  },
});
