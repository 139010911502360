import { SortInfo, PageRequest, Authority } from "@/util";
import { mapGetters } from "vuex";

export const pageSortMixin = {
  methods: {
    fetchData(): void {
      //empty method
    },
    onSizeChange(
      { size, total }: { size: number; total: number },
      request: PageRequest
    ): void {
      request.size = size;
      if (request.page === undefined || request.page * size <= total) {
        this.fetchData();
      }
    },
    onCurrentChange(number: number, request: PageRequest): void {
      request.page = number - 1;
      this.fetchData();
    },
    onSortChange(sort: SortInfo, request: PageRequest): void {
      if (sort.prop) {
        request.sort = [sort.prop + "," + this.formatOrder(sort.order)];
      } else {
        request.sort = [];
      }
      this.fetchData();
    },
    formatOrder: (order: string): string =>
      order === "ascending" ? "asc" : "desc",
  },
};

export const authorityMixin = {
  // eslint-disable-next-line
  data() {
    return {
      authorities: Authority,
    };
  },
  computed: mapGetters("account", ["hasAuthority"]),
};
