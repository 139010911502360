
import { defineComponent } from "vue";

export default defineComponent({
  name: "TabLink",
  props: {
    to: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
    },
  },
});
