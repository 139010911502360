import { UserStatus } from "@/api";
import { i18n } from "@/util";

export class User {
  static status(status: UserStatus): string {
    switch (status) {
      case UserStatus.ACTIVE:
        return i18n.global.t("user.userStatus.active");
      case UserStatus.INACTIVE:
        return i18n.global.t("user.userStatus.inactive");
      case UserStatus.LOCKED:
        return i18n.global.t("user.userStatus.locked");
      case UserStatus.SUSPENDED:
        return i18n.global.t("user.userStatus.suspended");
      default:
        return "-";
    }
  }

  static statusColor(status: UserStatus): string {
    switch (status) {
      case UserStatus.ACTIVE:
        return "#70B603";
      case UserStatus.LOCKED:
        return "#b60303";
      case UserStatus.SUSPENDED:
        return "#e75900";
      case UserStatus.INACTIVE:
      default:
        return "#7f7f7f";
    }
  }
}
