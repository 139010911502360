
import { defineComponent, PropType } from "vue";
import { PageBankAccountListItem } from "@/api";
import { authorityMixin, PageRequest, pageSortMixin } from "@/util";
import TableFooter from "@/components/TableFooter.vue";

export default defineComponent({
  name: "BankAccounts",
  mixins: [pageSortMixin, authorityMixin],
  components: { TableFooter },
  props: {
    page: {
      type: Object as PropType<PageBankAccountListItem>,
      required: true,
    },
    pageRequest: {
      type: Object as PropType<PageRequest>,
      required: true,
    },
    fetchBankAccountsData: {
      type: Function,
      required: true,
    },
  },
  mounted() {
    this.fetchBankAccountsData();
  },
  methods: {
    fetchData() {
      this.fetchBankAccountsData();
    },
  },
});
