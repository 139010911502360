<template>
  <div class="container ms-0 mb-0" v-if="preScreeningId">
    <Notes
      :load-notes="loadNotes"
      :create-note-disabled="!hasAuthority(authorities.PRE_SCREENING_WRITE)"
      :create-note="createNote"
      :edit-disabled="
        (note) =>
          note.user.id !== profile.id ||
          !hasAuthority(authorities.PRE_SCREENING_WRITE)
      "
      :delete-disabled="
        (note) =>
          note.user.id !== profile.id ||
          !hasAuthority(authorities.PRE_SCREENING_WRITE)
      "
      :delete-note="deleteNote"
      :update-note="updateNote"
    ></Notes>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { NoteRequest, PageNoteItem, PreScreeningNoteType } from "@/api";
import Notes from "@/views/components/Notes.vue";
import { authorityMixin, PageRequest } from "@/util";
import { preScreeningApi } from "@/resources";
import { mapState } from "vuex";

export default defineComponent({
  name: "PreScreeningReviewNotes",
  components: { Notes },
  mixins: [authorityMixin],
  data() {
    return {
      preScreeningId: inject("preScreeningId") as number,
    };
  },
  computed: {
    ...mapState("account", ["profile"]),
  },
  methods: {
    loadNotes(pageRequest: PageRequest): Promise<PageNoteItem> {
      return preScreeningApi.preScreeningNotes({
        id: this.preScreeningId,
        type: PreScreeningNoteType.REVIEW,
        ...pageRequest,
      });
    },
    createNote(noteRequest: NoteRequest): Promise<void> {
      return preScreeningApi.createPreScreeningNote({
        id: this.preScreeningId,
        type: PreScreeningNoteType.REVIEW,
        noteRequest: noteRequest,
      });
    },
    deleteNote(noteId: number): Promise<void> {
      return preScreeningApi.deletePreScreeningNote({
        id: noteId,
      });
    },
    updateNote(noteId: number, noteRequest: NoteRequest) {
      preScreeningApi.updatePreScreeningNote({
        id: noteId,
        noteRequest: noteRequest,
      });
    },
  },
});
</script>
