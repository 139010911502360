/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TransactionStatus,
    TransactionStatusFromJSON,
    TransactionStatusFromJSONTyped,
    TransactionStatusToJSON,
    TransactionType,
    TransactionTypeFromJSON,
    TransactionTypeFromJSONTyped,
    TransactionTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface TransactionListItem
 */
export interface TransactionListItem {
    /**
     * 
     * @type {number}
     * @memberof TransactionListItem
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionListItem
     */
    campaignId: number;
    /**
     * 
     * @type {Date}
     * @memberof TransactionListItem
     */
    effectiveDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof TransactionListItem
     */
    externalId?: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionListItem
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionListItem
     */
    investmentId: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionListItem
     */
    omrAmount: number;
    /**
     * 
     * @type {Date}
     * @memberof TransactionListItem
     */
    startDate: Date;
    /**
     * 
     * @type {TransactionStatus}
     * @memberof TransactionListItem
     */
    transactionStatus: TransactionStatus;
    /**
     * 
     * @type {TransactionType}
     * @memberof TransactionListItem
     */
    transactionType: TransactionType;
}

export function TransactionListItemFromJSON(json: any): TransactionListItem {
    return TransactionListItemFromJSONTyped(json, false);
}

export function TransactionListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'campaignId': json['campaignId'],
        'effectiveDate': !exists(json, 'effectiveDate') ? undefined : (new Date(json['effectiveDate'])),
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'id': json['id'],
        'investmentId': json['investmentId'],
        'omrAmount': json['omrAmount'],
        'startDate': (new Date(json['startDate'])),
        'transactionStatus': TransactionStatusFromJSON(json['transactionStatus']),
        'transactionType': TransactionTypeFromJSON(json['transactionType']),
    };
}

export function TransactionListItemToJSON(value?: TransactionListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'campaignId': value.campaignId,
        'effectiveDate': value.effectiveDate === undefined ? undefined : (value.effectiveDate.toISOString()),
        'externalId': value.externalId,
        'id': value.id,
        'investmentId': value.investmentId,
        'omrAmount': value.omrAmount,
        'startDate': (value.startDate.toISOString()),
        'transactionStatus': TransactionStatusToJSON(value.transactionStatus),
        'transactionType': TransactionTypeToJSON(value.transactionType),
    };
}


