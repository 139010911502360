
import { defineComponent, PropType } from "vue";
import { RouteRecordName } from "vue-router";

export default defineComponent({
  name: "SidebarLink",
  props: {
    to: {
      type: String,
      required: false,
    },
    active: {
      type: Object as PropType<Array<RouteRecordName>>,
      required: true,
    },
  },
  computed: {
    isActive(): boolean {
      return this.active.includes(this.$route.name as RouteRecordName);
    },
  },
});
