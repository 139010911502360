/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApprovedCampaignData,
    ApprovedCampaignDataFromJSON,
    ApprovedCampaignDataFromJSONTyped,
    ApprovedCampaignDataToJSON,
    CampaignStatus,
    CampaignStatusFromJSON,
    CampaignStatusFromJSONTyped,
    CampaignStatusToJSON,
    CampaignType,
    CampaignTypeFromJSON,
    CampaignTypeFromJSONTyped,
    CampaignTypeToJSON,
    CommittedCampaignData,
    CommittedCampaignDataFromJSON,
    CommittedCampaignDataFromJSONTyped,
    CommittedCampaignDataToJSON,
    IdNameEntry,
    IdNameEntryFromJSON,
    IdNameEntryFromJSONTyped,
    IdNameEntryToJSON,
} from './';

/**
 * 
 * @export
 * @interface CampaignListItem
 */
export interface CampaignListItem {
    /**
     * 
     * @type {ApprovedCampaignData}
     * @memberof CampaignListItem
     */
    approvedData?: ApprovedCampaignData;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof CampaignListItem
     */
    business: IdNameEntry;
    /**
     * 
     * @type {Date}
     * @memberof CampaignListItem
     */
    campaignFinishedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CampaignListItem
     */
    campaignStartedAt?: Date;
    /**
     * 
     * @type {CommittedCampaignData}
     * @memberof CampaignListItem
     */
    committedData: CommittedCampaignData;
    /**
     * 
     * @type {Date}
     * @memberof CampaignListItem
     */
    createdAt: Date;
    /**
     * 
     * @type {number}
     * @memberof CampaignListItem
     */
    id: number;
    /**
     * 
     * @type {CampaignStatus}
     * @memberof CampaignListItem
     */
    status: CampaignStatus;
    /**
     * 
     * @type {CampaignType}
     * @memberof CampaignListItem
     */
    type: CampaignType;
}

export function CampaignListItemFromJSON(json: any): CampaignListItem {
    return CampaignListItemFromJSONTyped(json, false);
}

export function CampaignListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): CampaignListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'approvedData': !exists(json, 'approvedData') ? undefined : ApprovedCampaignDataFromJSON(json['approvedData']),
        'business': IdNameEntryFromJSON(json['business']),
        'campaignFinishedAt': !exists(json, 'campaignFinishedAt') ? undefined : (new Date(json['campaignFinishedAt'])),
        'campaignStartedAt': !exists(json, 'campaignStartedAt') ? undefined : (new Date(json['campaignStartedAt'])),
        'committedData': CommittedCampaignDataFromJSON(json['committedData']),
        'createdAt': (new Date(json['createdAt'])),
        'id': json['id'],
        'status': CampaignStatusFromJSON(json['status']),
        'type': CampaignTypeFromJSON(json['type']),
    };
}

export function CampaignListItemToJSON(value?: CampaignListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'approvedData': ApprovedCampaignDataToJSON(value.approvedData),
        'business': IdNameEntryToJSON(value.business),
        'campaignFinishedAt': value.campaignFinishedAt === undefined ? undefined : (value.campaignFinishedAt.toISOString().substr(0,10)),
        'campaignStartedAt': value.campaignStartedAt === undefined ? undefined : (value.campaignStartedAt.toISOString().substr(0,10)),
        'committedData': CommittedCampaignDataToJSON(value.committedData),
        'createdAt': (value.createdAt.toISOString().substr(0,10)),
        'id': value.id,
        'status': CampaignStatusToJSON(value.status),
        'type': CampaignTypeToJSON(value.type),
    };
}


