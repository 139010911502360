
import { defineComponent } from "vue";
import { preScreeningApi } from "@/resources";
import { fixLocalDate, PageRequest, pageSortMixin } from "@/util";
import TableFooter from "@/components/TableFooter.vue";
import Headline from "@/components/Headline.vue";
import PreScreeningFilter from "@/views/pre_screening/PreScreeningFilter.vue";
import {
  Country,
  PagePreScreeningListItem,
  PreScreeningDuplicateStatus,
  PreScreeningListItem,
  PreScreeningStatus,
} from "@/api";
import Status from "@/components/Status.vue";

export interface Filter {
  companyName: string | undefined;
  companyRegistrationCountry: Country | undefined;
  status: PreScreeningStatus | undefined;
  duplicateStatus: PreScreeningDuplicateStatus | undefined;
  contactName: string | undefined;
  contactEmail: string | undefined;
  createdAtFrom: Date | undefined;
  createdAtTo: Date | undefined;
}

export default defineComponent({
  name: "PreScreeningList",
  mixins: [pageSortMixin],
  components: { TableFooter, Headline, PreScreeningFilter, Status },
  data: () => ({
    preScreeningPage: {} as PagePreScreeningListItem,
    pageRequest: {} as PageRequest,
    filterVisible: false,
    filter: {} as Filter,
  }),
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.filter.createdAtTo = this.filter.createdAtTo
        ? fixLocalDate(this.filter.createdAtTo)
        : undefined;
      this.filter.createdAtFrom = this.filter.createdAtFrom
        ? fixLocalDate(this.filter.createdAtFrom)
        : undefined;
      this.preScreeningPage = await preScreeningApi.preScreenings({
        ...this.pageRequest,
        ...this.filter,
      });
    },
    onCellClick(row: PreScreeningListItem) {
      this.$router.push({
        name: "preScreening",
        params: { id: row.id },
      });
    },
  },
});
