/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum InvestmentDeclineReason {
    BAD_KYC = 'BAD_KYC',
    LOW_SCORE = 'LOW_SCORE',
    SUSPICION_OF_FRAUD = 'SUSPICION_OF_FRAUD',
    OTHER = 'OTHER'
}

export function InvestmentDeclineReasonFromJSON(json: any): InvestmentDeclineReason {
    return InvestmentDeclineReasonFromJSONTyped(json, false);
}

export function InvestmentDeclineReasonFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestmentDeclineReason {
    return json as InvestmentDeclineReason;
}

export function InvestmentDeclineReasonToJSON(value?: InvestmentDeclineReason | null): any {
    return value as any;
}

