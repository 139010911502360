
import { defineComponent } from "vue";
import { portalUserApi } from "@/resources";
import Headline from "@/components/Headline.vue";
import { PagePortalUser, PortalUser } from "@/api";
import { authorityMixin, PageRequest, pageSortMixin } from "@/util";
import TableFooter from "@/components/TableFooter.vue";
import Status from "@/components/Status.vue";
import PortalUserFilter from "@/views/user/portal/PortalUserFilter.vue";

export interface Filter {
  fullname: string;
  email: string;
  statuses: string[];
}

export default defineComponent({
  name: "PortalUserList",
  components: { Headline, Status, TableFooter, PortalUserFilter },
  mixins: [pageSortMixin, authorityMixin],
  data: () => ({
    userPage: {} as PagePortalUser,
    pageRequest: {} as PageRequest,
    filterVisible: false,
    filter: {} as Filter,
  }),
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.userPage = await portalUserApi.portalUsers({
        ...this.pageRequest,
        ...this.filter,
      });
    },
    onCellClick(row: PortalUser) {
      this.$router.push({ name: "portalUser", params: { id: row.id } });
    },
  },
});
