<template>
  <div class="container ms-0 mb-0" v-if="investor.id">
    <div class="row mb-3">
      <div class="col">
        <div class="row mb-3">
          <div class="col color-light-text">
            {{ $t("investor.employmentType.title") }}
          </div>
          <div class="col">
            {{
              $enums.investor.employmentType(
                investor.individual.occupation.employmentType
              )
            }}
          </div>
        </div>
        <div class="row mb-3">
          <div class="col color-light-text">
            {{ $t("investor.companyName") }}
          </div>
          <div class="col">
            {{ investor.individual.occupation.companyName }}
          </div>
        </div>
        <div class="row mb-3">
          <div class="col color-light-text">
            {{ $t("investor.companyAddress") }}
          </div>
          <div class="col">
            {{ investor.individual.occupation.companyAddress }}
          </div>
        </div>
        <div class="row mb-3">
          <div class="col color-light-text">
            {{ $t("investor.companyContactNumber") }}
          </div>
          <div class="col">
            {{ $phone(investor.individual.occupation.companyContactNumber) }}
          </div>
        </div>
        <div class="row mb-3">
          <div class="col color-light-text">
            {{ $t("investor.occupation") }}
          </div>
          <div class="col">
            {{ investor.individual.occupation.occupation }}
          </div>
        </div>
        <div class="row mb-3">
          <div class="col color-light-text">
            {{ $t("investor.wealthSource") }}
          </div>
          <div class="col">
            {{ investor.individual.income?.wealthSource ?? "-" }}
          </div>
        </div>
      </div>
      <!--column 2-->
      <div class="col">
        <div class="row mb-3">
          <div class="col color-light-text">
            {{
              $t("investor.totalNetJointAssets", {
                currency: currency,
              })
            }}
          </div>
          <div class="col">
            {{ $currValue(investor.individual.income?.totalNetJointAssets) }}
          </div>
        </div>
        <div class="row mb-3">
          <div class="col color-light-text">
            {{
              $t("investor.totalNetPersonalAssets", {
                currency: currency,
              })
            }}
          </div>
          <div class="col">
            {{ $currValue(investor.individual.income?.totalNetPersonalAssets) }}
          </div>
        </div>
        <div class="row mb-3">
          <div class="col color-light-text">
            {{
              $t("investor.totalGrossJointAnnualIncome", {
                currency: currency,
              })
            }}
          </div>
          <div class="col">
            {{
              $currValue(
                investor.individual.income?.totalGrossJointAnnualIncome
              )
            }}
          </div>
        </div>
        <div class="row mb-3">
          <div class="col color-light-text">
            {{
              $t("investor.totalGrossPersonalAnnualIncome", {
                currency: currency,
              })
            }}
          </div>
          <div class="col">
            {{
              $currValue(
                investor.individual.income?.totalGrossPersonalAnnualIncome
              )
            }}
          </div>
        </div>
        <div class="row mb-3">
          <div class="col color-light-text">
            {{
              $t("investor.totalAnnualInvestments", {
                currency: currency,
              })
            }}
          </div>
          <div class="col">
            {{ $currValue(investor.individual.income?.totalAnnualInvestments) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { Currency, Investor } from "@/api";

export default defineComponent({
  name: "InvestorOccupationIncome",
  data() {
    return {
      investor: inject("investor") as Investor,
    };
  },
  computed: {
    currency(): Currency {
      return this.investor.individual?.income?.currency ?? Currency.OMR;
    },
  },
});
</script>
