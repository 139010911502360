/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BusinessSector,
    BusinessSectorFromJSON,
    BusinessSectorFromJSONTyped,
    BusinessSectorToJSON,
    LegalEntityType,
    LegalEntityTypeFromJSON,
    LegalEntityTypeFromJSONTyped,
    LegalEntityTypeToJSON,
    PrimaryContact,
    PrimaryContactFromJSON,
    PrimaryContactFromJSONTyped,
    PrimaryContactToJSON,
} from './';

/**
 * 
 * @export
 * @interface Legal
 */
export interface Legal {
    /**
     * 
     * @type {string}
     * @memberof Legal
     */
    businessNature: string;
    /**
     * 
     * @type {BusinessSector}
     * @memberof Legal
     */
    businessSector: BusinessSector;
    /**
     * 
     * @type {PrimaryContact}
     * @memberof Legal
     */
    contact: PrimaryContact;
    /**
     * 
     * @type {string}
     * @memberof Legal
     */
    email: string;
    /**
     * 
     * @type {Date}
     * @memberof Legal
     */
    establishedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Legal
     */
    expiryDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof Legal
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Legal
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof Legal
     */
    numberOfEmployees: number;
    /**
     * 
     * @type {boolean}
     * @memberof Legal
     */
    ownershipChangedLast5Years: boolean;
    /**
     * 
     * @type {string}
     * @memberof Legal
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof Legal
     */
    registrationCountry: string;
    /**
     * 
     * @type {string}
     * @memberof Legal
     */
    registrationNumber: string;
    /**
     * 
     * @type {boolean}
     * @memberof Legal
     */
    statusChangedLast5Years: boolean;
    /**
     * 
     * @type {string}
     * @memberof Legal
     */
    taxIdNumber?: string;
    /**
     * 
     * @type {LegalEntityType}
     * @memberof Legal
     */
    type: LegalEntityType;
    /**
     * 
     * @type {string}
     * @memberof Legal
     */
    typeOther?: string;
}

export function LegalFromJSON(json: any): Legal {
    return LegalFromJSONTyped(json, false);
}

export function LegalFromJSONTyped(json: any, ignoreDiscriminator: boolean): Legal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'businessNature': json['businessNature'],
        'businessSector': BusinessSectorFromJSON(json['businessSector']),
        'contact': PrimaryContactFromJSON(json['contact']),
        'email': json['email'],
        'establishedAt': !exists(json, 'establishedAt') ? undefined : (new Date(json['establishedAt'])),
        'expiryDate': !exists(json, 'expiryDate') ? undefined : (new Date(json['expiryDate'])),
        'id': json['id'],
        'name': json['name'],
        'numberOfEmployees': json['numberOfEmployees'],
        'ownershipChangedLast5Years': json['ownershipChangedLast5Years'],
        'phone': json['phone'],
        'registrationCountry': json['registrationCountry'],
        'registrationNumber': json['registrationNumber'],
        'statusChangedLast5Years': json['statusChangedLast5Years'],
        'taxIdNumber': !exists(json, 'taxIdNumber') ? undefined : json['taxIdNumber'],
        'type': LegalEntityTypeFromJSON(json['type']),
        'typeOther': !exists(json, 'typeOther') ? undefined : json['typeOther'],
    };
}

export function LegalToJSON(value?: Legal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'businessNature': value.businessNature,
        'businessSector': BusinessSectorToJSON(value.businessSector),
        'contact': PrimaryContactToJSON(value.contact),
        'email': value.email,
        'establishedAt': value.establishedAt === undefined ? undefined : (value.establishedAt.toISOString().substr(0,10)),
        'expiryDate': value.expiryDate === undefined ? undefined : (value.expiryDate.toISOString().substr(0,10)),
        'id': value.id,
        'name': value.name,
        'numberOfEmployees': value.numberOfEmployees,
        'ownershipChangedLast5Years': value.ownershipChangedLast5Years,
        'phone': value.phone,
        'registrationCountry': value.registrationCountry,
        'registrationNumber': value.registrationNumber,
        'statusChangedLast5Years': value.statusChangedLast5Years,
        'taxIdNumber': value.taxIdNumber,
        'type': LegalEntityTypeToJSON(value.type),
        'typeOther': value.typeOther,
    };
}


