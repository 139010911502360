<template>
  <Headline
    >{{ $t("sidebar.investments") }}
    <template v-slot:actions>
      <el-button
        v-if="!filterVisible"
        class="filter"
        @click="filterVisible = true"
        >{{ $t("filter.show") }}
      </el-button>
      <el-button v-else class="filter" @click="filterVisible = false">
        {{ $t("filter.hide") }}
      </el-button>
    </template>
  </Headline>
  <InvestmentFilter
    v-if="filterVisible"
    v-model:investment-filter="filter"
    @submit="fetchData"
  />
  <el-table
    :data="investmentPage.content"
    @sort-change="onSortChange($event, pageRequest)"
    :row-style="{ cursor: 'pointer' }"
    @cell-click="onRowClick"
  >
    <el-table-column :label="$t('id')" prop="id" sortable="custom" width="85">
    </el-table-column>
    <el-table-column
      :label="$t('investment.columns.campaignId')"
      prop="campaignId"
      width="85"
    >
      <template #default="scope">
        <router-link
          v-if="hasAuthority(authorities.CAMPAIGN_READ)"
          :to="{ name: 'campaign', params: { id: scope.row.campaignId } }"
          >{{ scope.row.campaignId }}
        </router-link>
        <span v-else>{{ scope.row.campaignId }}</span>
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('investment.columns.investor')"
      prop="investor.name"
      :show-overflow-tooltip="true"
    >
      <template #default="scope">
        <router-link
          v-if="scope.row.investor && hasAuthority(authorities.INVESTOR_READ)"
          :to="{ name: 'investor', params: { id: scope.row.investor.id } }"
          >{{ scope.row.investor.name }}
        </router-link>
        <span v-else>{{ scope.row.investor.name }}</span>
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('investment.columns.requestedAmount')"
      :show-overflow-tooltip="true"
    >
      <template #default="scope">
        {{ $currValue(scope.row.requestedAmount) }}
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('investment.columns.committedAmount')"
      :show-overflow-tooltip="true"
    >
      <template #default="scope">
        {{ $currValue(scope.row.committedAmount) }}
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('investment.columns.receivedAmount')"
      :show-overflow-tooltip="true"
    >
      <template #default="scope">
        {{ $currValue(scope.row.receivedAmount) }}
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('investment.columns.investorCommitment')"
      :min-width="85"
      :show-overflow-tooltip="true"
    >
      <template #default="scope">
        {{ $currValue(scope.row.investorCommitment) }}
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('investment.columns.createdAt')"
      min-width="100"
    >
      <template #default="scope">
        {{ $date(scope.row.createdAt) }}
      </template>
    </el-table-column>
    <el-table-column :label="$t('investment.columns.status')" min-width="220">
      <template #default="scope">
        <Status :color="$enums.investment.statusColor(scope.row.status)">
          {{ $enums.investment.status(scope.row.status) }}
        </Status>
      </template>
    </el-table-column>
  </el-table>

  <TableFooter
    @size-change="onSizeChange($event, pageRequest)"
    @current-change="onCurrentChange($event, pageRequest)"
    :page="investmentPage"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { investmentApi } from "@/resources";
import Headline from "@/components/Headline.vue";
import { CampaignStatus, Investment, PageInvestmentListItem } from "@/api";
import { authorityMixin, PageRequest, pageSortMixin } from "@/util";
import TableFooter from "@/components/TableFooter.vue";
import InvestmentFilter from "@/views/investment/InvestmentFilter.vue";
import Status from "@/components/Status.vue";

export interface Filter {
  investor: string | undefined;
  statuses: CampaignStatus[];
  committedAmountFrom: number | undefined;
  committedAmountTo: number | undefined;
  receivedAmountFrom: number | undefined;
  receivedAmountTo: number | undefined;
  createdDateFrom: Date | undefined;
  createdDateTo: Date | undefined;
  campaignId: string | undefined;
}

export default defineComponent({
  name: "InvestmentList",
  components: { Status, InvestmentFilter, Headline, TableFooter },
  mixins: [pageSortMixin, authorityMixin],
  data: () => ({
    investmentPage: {} as PageInvestmentListItem,
    pageRequest: {} as PageRequest,
    filterVisible: false,
    filter: {} as Filter,
  }),
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.investmentPage = await investmentApi.investments({
        ...this.pageRequest,
        ...this.filter,
      });
    },
    onRowClick(row: Investment) {
      this.$router.push({
        name: "investment",
        params: { id: row.id },
      });
    },
  },
});
</script>
