<template>
  <div class="row mb-3">
    <div class="row ms-0 mb-3">
      <div class="col color-light-text">
        {{ $t("investor.mobileNumber") }}
      </div>
      <div class="col">
        {{ $phone(individual.person.phone) }}
      </div>
    </div>
    <div class="row ms-0 mb-3">
      <div class="col color-light-text">
        {{ $t("investor.email") }}
      </div>
      <div class="col">
        {{ individual.person.email ?? "-" }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Individual } from "@/api";

export default defineComponent({
  name: "InvestorIndividualContact",
  props: {
    individual: {
      type: Object as PropType<Individual>,
      required: true,
    },
  },
});
</script>
