/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StartPage,
    StartPageFromJSON,
    StartPageFromJSONTyped,
    StartPageToJSON,
} from './';

/**
 * 
 * @export
 * @interface TokenResponse
 */
export interface TokenResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof TokenResponse
     */
    authorities: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof TokenResponse
     */
    expireAt: Date;
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    idToken: string;
    /**
     * 
     * @type {Date}
     * @memberof TokenResponse
     */
    refreshExpireAt: Date;
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    refreshToken: string;
    /**
     * 
     * @type {boolean}
     * @memberof TokenResponse
     */
    reroute: boolean;
    /**
     * 
     * @type {StartPage}
     * @memberof TokenResponse
     */
    startPage: StartPage;
}

export function TokenResponseFromJSON(json: any): TokenResponse {
    return TokenResponseFromJSONTyped(json, false);
}

export function TokenResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TokenResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'authorities': json['authorities'],
        'expireAt': (new Date(json['expireAt'])),
        'idToken': json['idToken'],
        'refreshExpireAt': (new Date(json['refreshExpireAt'])),
        'refreshToken': json['refreshToken'],
        'reroute': json['reroute'],
        'startPage': StartPageFromJSON(json['startPage']),
    };
}

export function TokenResponseToJSON(value?: TokenResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'authorities': value.authorities,
        'expireAt': (value.expireAt.toISOString()),
        'idToken': value.idToken,
        'refreshExpireAt': (value.refreshExpireAt.toISOString()),
        'refreshToken': value.refreshToken,
        'reroute': value.reroute,
        'startPage': StartPageToJSON(value.startPage),
    };
}


