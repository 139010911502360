<template>
  <div class="container-filters mt-4 mb-3">
    <el-form
      @validate="onValidate"
      :model="filter"
      :rules="rules"
      ref="filter-form"
      @change="$emit('update:accreditationFilter', filter)"
    >
      <div class="row">
        <div class="col-4">
          <el-form-item
            :label="$t('accreditation.investor')"
            prop="investorName"
          >
            <el-input v-model="filter.investorName"></el-input>
          </el-form-item>
          <el-form-item :label="$t('filter.status')">
            <el-select
              v-model="filter.status"
              class="w-100"
              single
              collapse-tags
            >
              <el-option
                v-for="(status, key) in statuses"
                :key="key"
                :label="$enums.accreditation.status(status)"
                :value="status"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>

        <div class="col-4">
          <el-form-item :label="$t('createdAt')">
            <div class="d-flex gap-2">
              <el-date-picker
                type="date"
                v-model="filter.createdAtFrom"
                format="DD/MM/YYYY"
                :placeholder="$t('filter.from')"
              ></el-date-picker>
              <el-date-picker
                type="date"
                v-model="filter.createdAtTo"
                format="DD/MM/YYYY"
                :placeholder="$t('filter.to')"
              ></el-date-picker>
            </div>
          </el-form-item>
          <el-form-item :label="$t('accreditation.approvedAt')">
            <div class="d-flex gap-2">
              <el-date-picker
                type="date"
                v-model="filter.approvedAtFrom"
                format="DD/MM/YYYY"
                :placeholder="$t('filter.from')"
              ></el-date-picker>
              <el-date-picker
                type="date"
                v-model="filter.approvedAtTo"
                format="DD/MM/YYYY"
                :placeholder="$t('filter.to')"
              ></el-date-picker>
            </div>
          </el-form-item>
        </div>

        <div class="col-4">
          <el-form-item :label="$t('accreditation.validTill')">
            <div class="d-flex gap-2">
              <el-date-picker
                type="date"
                v-model="filter.validTillFrom"
                format="DD/MM/YYYY"
                :placeholder="$t('filter.from')"
              ></el-date-picker>
              <el-date-picker
                type="date"
                v-model="filter.validTillTo"
                format="DD/MM/YYYY"
                :placeholder="$t('filter.to')"
              ></el-date-picker>
            </div>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
  <div class="mb-5">
    <el-button :disabled="!isFormValid" type="primary" @click="submit">
      {{ $t("apply") }}
    </el-button>
    <el-button type="default" @click="resetFilter">
      {{ $t("filter.reset") }}
    </el-button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ElementForm, InputLength } from "@/util/validation";
import { AccreditationStatus } from "@/api";
import { Filter } from "@/views/accreditation/AccreditationList.vue";

export default defineComponent({
  emits: ["update:accreditationFilter", "submit"],
  props: ["accreditationFilter"],
  data() {
    return {
      filter: {} as Filter,
      statuses: [] as AccreditationStatus[],
      isFormValid: true,
      rules: {
        investorName: [new InputLength(100)],
      },
    };
  },
  async mounted() {
    this.statuses = Object.values(AccreditationStatus);
    this.filter = this.accreditationFilter;
  },
  methods: {
    submit() {
      const form = this.$refs["filter-form"] as ElementForm;
      form.validate(async (valid) => {
        if (valid) {
          this.$emit("submit");
        }
      });
    },
    onValidate(prop: string, isVal: boolean) {
      this.isFormValid = isVal;
    },
    resetFilter() {
      this.filter = {} as Filter;
      this.$emit("update:accreditationFilter", this.filter);
      this.$emit("submit");
    },
  },
});
</script>
