/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApprovedCampaignData
 */
export interface ApprovedCampaignData {
    /**
     * 
     * @type {number}
     * @memberof ApprovedCampaignData
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof ApprovedCampaignData
     */
    daysDuration?: number;
    /**
     * 
     * @type {number}
     * @memberof ApprovedCampaignData
     */
    minimumFundingPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof ApprovedCampaignData
     */
    minimumInvestment?: number;
    /**
     * 
     * @type {number}
     * @memberof ApprovedCampaignData
     */
    overRaiseAmount?: number;
}

export function ApprovedCampaignDataFromJSON(json: any): ApprovedCampaignData {
    return ApprovedCampaignDataFromJSONTyped(json, false);
}

export function ApprovedCampaignDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApprovedCampaignData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'daysDuration': !exists(json, 'daysDuration') ? undefined : json['daysDuration'],
        'minimumFundingPercent': !exists(json, 'minimumFundingPercent') ? undefined : json['minimumFundingPercent'],
        'minimumInvestment': !exists(json, 'minimumInvestment') ? undefined : json['minimumInvestment'],
        'overRaiseAmount': !exists(json, 'overRaiseAmount') ? undefined : json['overRaiseAmount'],
    };
}

export function ApprovedCampaignDataToJSON(value?: ApprovedCampaignData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'daysDuration': value.daysDuration,
        'minimumFundingPercent': value.minimumFundingPercent,
        'minimumInvestment': value.minimumInvestment,
        'overRaiseAmount': value.overRaiseAmount,
    };
}


