/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum InvestorCategory {
    RETAIL = 'RETAIL',
    ANGEL = 'ANGEL',
    SOPHISTICATED = 'SOPHISTICATED',
    OTHER_INSTITUTIONAL = 'OTHER_INSTITUTIONAL'
}

export function InvestorCategoryFromJSON(json: any): InvestorCategory {
    return InvestorCategoryFromJSONTyped(json, false);
}

export function InvestorCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestorCategory {
    return json as InvestorCategory;
}

export function InvestorCategoryToJSON(value?: InvestorCategory | null): any {
    return value as any;
}

