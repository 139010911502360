/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PreScreeningDuplicateStatus,
    PreScreeningDuplicateStatusFromJSON,
    PreScreeningDuplicateStatusFromJSONTyped,
    PreScreeningDuplicateStatusToJSON,
    PreScreeningStatus,
    PreScreeningStatusFromJSON,
    PreScreeningStatusFromJSONTyped,
    PreScreeningStatusToJSON,
    PrimaryContact,
    PrimaryContactFromJSON,
    PrimaryContactFromJSONTyped,
    PrimaryContactToJSON,
} from './';

/**
 * 
 * @export
 * @interface PreScreeningItem
 */
export interface PreScreeningItem {
    /**
     * 
     * @type {string}
     * @memberof PreScreeningItem
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof PreScreeningItem
     */
    companyRegistrationCountry: string;
    /**
     * 
     * @type {string}
     * @memberof PreScreeningItem
     */
    companyRegistrationNumber: string;
    /**
     * 
     * @type {PrimaryContact}
     * @memberof PreScreeningItem
     */
    contact: PrimaryContact;
    /**
     * 
     * @type {Date}
     * @memberof PreScreeningItem
     */
    createdAt: Date;
    /**
     * 
     * @type {PreScreeningDuplicateStatus}
     * @memberof PreScreeningItem
     */
    duplicateStatus: PreScreeningDuplicateStatus;
    /**
     * 
     * @type {number}
     * @memberof PreScreeningItem
     */
    id: number;
    /**
     * 
     * @type {PreScreeningStatus}
     * @memberof PreScreeningItem
     */
    status: PreScreeningStatus;
}

export function PreScreeningItemFromJSON(json: any): PreScreeningItem {
    return PreScreeningItemFromJSONTyped(json, false);
}

export function PreScreeningItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreScreeningItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyName': json['companyName'],
        'companyRegistrationCountry': json['companyRegistrationCountry'],
        'companyRegistrationNumber': json['companyRegistrationNumber'],
        'contact': PrimaryContactFromJSON(json['contact']),
        'createdAt': (new Date(json['createdAt'])),
        'duplicateStatus': PreScreeningDuplicateStatusFromJSON(json['duplicateStatus']),
        'id': json['id'],
        'status': PreScreeningStatusFromJSON(json['status']),
    };
}

export function PreScreeningItemToJSON(value?: PreScreeningItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyName': value.companyName,
        'companyRegistrationCountry': value.companyRegistrationCountry,
        'companyRegistrationNumber': value.companyRegistrationNumber,
        'contact': PrimaryContactToJSON(value.contact),
        'createdAt': (value.createdAt.toISOString().substr(0,10)),
        'duplicateStatus': PreScreeningDuplicateStatusToJSON(value.duplicateStatus),
        'id': value.id,
        'status': PreScreeningStatusToJSON(value.status),
    };
}


