<template>
  <div class="container-auth">
    <h1 class="text-center">{{ $t("auth.password.expired") }}</h1>
    <p>{{ $t("auth.password.createNew") }}</p>
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      status-icon
      class="error-message-static"
    >
      <el-form-item :label="$t('auth.email')" prop="login">
        <el-input v-model="form.login"></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('auth.password.currentPassword')"
        prop="oldPassword"
      >
        <el-input
          v-model="form.oldPassword"
          show-password
          class="el-input__password"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('auth.password.enterNew')" prop="newPassword">
        <el-input
          v-model="form.newPassword"
          show-password
          class="el-input__password"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('auth.password.confirmNew')"
        prop="confirmNewPassword"
      >
        <el-input
          v-model="form.confirmNewPassword"
          show-password
          class="el-input__password"
        ></el-input>
      </el-form-item>
      <span
        id="errorMessage"
        class="invalid-feedback mb-2"
        v-if="errorMessage"
        >{{ errorMessage }}</span
      >
      <el-form-item>
        <button
          class="btn btn-primary el-button--lg w-100 mt-4"
          @click="onSubmit"
        >
          {{ $t("save") }}
        </button>
      </el-form-item>
      <PasswordPolicy />
    </el-form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ElementForm, InputRequired, passwordFormat } from "@/util/validation";
import { isConstraintViolation, isProblem } from "@/resources/problem";
import { authApi } from "@/resources";
import PasswordPolicy from "@/components/PasswordPolicy.vue";
import { ResetPasswordRequest } from "@/api";

export default defineComponent({
  name: "ExpiredPasswordChange",
  components: { PasswordPolicy },
  data() {
    return {
      form: {} as ResetPasswordRequest,
      errorMessage: "" as string,
      rules: {
        login: [new InputRequired()],
        oldPassword: [new InputRequired("validation.currentPasswordRequired")],
        newPassword: [
          new InputRequired("validation.newPasswordRequired"),
          passwordFormat,
          {
            validator: (
              rule: Record<string, unknown>,
              value: string,
              callback: (error?: Error) => unknown
            ) => {
              const constraints = (this
                .form as unknown) as ResetPasswordRequest;
              if (value === constraints.oldPassword) {
                callback(
                  new Error(
                    this.$t(
                      "auth.password.newPasswordIsSameAsPreviousPasswords"
                    )
                  )
                );
              } else {
                callback();
              }
            },
          },
        ],
        confirmNewPassword: [
          new InputRequired("validation.confirmationPasswordRequired"),
          {
            validator: (
              rule: Record<string, unknown>,
              value: string,
              callback: (error?: Error) => unknown
            ) => {
              const constraints = (this
                .form as unknown) as ResetPasswordRequest;
              if (value !== constraints.newPassword) {
                callback(new Error(this.$t("auth.assword.passwordMismatch")));
              } else {
                callback();
              }
            },
          },
        ],
      },
    };
  },
  methods: {
    onSubmit(event: Event) {
      event.preventDefault();
      const form = this.$refs["form"] as ElementForm;
      form.validate((valid) => {
        if (!valid) {
          return false;
        }
        this.setPassword();
        return true;
      });
    },
    async setPassword() {
      try {
        await authApi.resetPassword({
          resetPasswordRequest: this.form,
        });
        this.$notify.info(this.$t("auth.password.changed"));
        await this.$router.push({ path: "/", force: true });
      } catch (e) {
        if (isConstraintViolation(e)) {
          e.violations.forEach(
            (violation) => (this.errorMessage = violation.message)
          );
        } else if (isProblem(e)) {
          this.errorMessage = e.detail as string;
        }
      }
    },
  },
});
</script>

<style scoped>
form {
  position: relative;
}

#errorMessage {
  display: block;
}
</style>
