/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Person,
    PersonFromJSON,
    PersonFromJSONTyped,
    PersonToJSON,
    UserStatus,
    UserStatusFromJSON,
    UserStatusFromJSONTyped,
    UserStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {Person}
     * @memberof User
     */
    person: Person;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    role: string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    roleId: number;
    /**
     * 
     * @type {UserStatus}
     * @memberof User
     */
    status: UserStatus;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'person': PersonFromJSON(json['person']),
        'role': json['role'],
        'roleId': json['roleId'],
        'status': UserStatusFromJSON(json['status']),
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'person': PersonToJSON(value.person),
        'role': value.role,
        'roleId': value.roleId,
        'status': UserStatusToJSON(value.status),
    };
}


