<template>
  <div class="container ms-0 mb-3" v-if="form.accreditationAngel !== undefined">
    <el-form
      :model="form"
      :rules="rules"
      status-icon
      ref="form"
      @validate="onValidate"
    >
      <div class="row">
        <div class="col-md-5">
          <div class="row">
            <el-form-item
              :label="$t('settings.fee.investorAccreditationAngel')"
              prop="accreditationAngel"
            >
              <el-input
                :disabled="!editable"
                v-model="form.accreditationAngel"
              ></el-input>
              <HistoryPopover
                :history="investorFeeHistory.accreditationAngel"
                :field-name="$t('settings.fee.investorAccreditationAngel')"
                :value-type="historyFormat.CURRENCY"
              />
            </el-form-item>
          </div>
          <div class="row">
            <el-form-item
              :label="$t('settings.fee.investorAccreditationSophisticated')"
              prop="accreditationSophisticated"
            >
              <el-input
                :disabled="!editable"
                v-model="form.accreditationSophisticated"
              ></el-input>
              <HistoryPopover
                :history="investorFeeHistory.accreditationSophisticated"
                :field-name="
                  $t('settings.fee.investorAccreditationSophisticated')
                "
                :value-type="historyFormat.CURRENCY"
              />
            </el-form-item>
          </div>
          <div class="row">
            <el-form-item
              :label="$t('settings.fee.investorAdministration')"
              prop="administration"
            >
              <el-input
                :disabled="!editable"
                v-model="form.administration"
              ></el-input>
              <HistoryPopover
                :history="investorFeeHistory.administration"
                :field-name="$t('settings.fee.investorAdministration')"
                :value-type="historyFormat.CURRENCY"
              />
            </el-form-item>
          </div>
          <br />
          <el-button
            @click="edit"
            v-show="!editable"
            v-if="hasAuthority(authorities.SETTINGS_WRITE)"
          >
            {{ $t("edit") }}
          </el-button>
          <el-button style="margin-left: 0" @click="cancel" v-show="editable">
            {{ $t("cancel") }}
          </el-button>
          <el-button
            type="primary"
            v-show="editable"
            :disabled="!isFormValid"
            @click="submit"
          >
            {{ $t("save") }}
          </el-button>
        </div>
        <div class="col-md-5">
          <div class="row">
            <el-form-item :label="$t('settings.fee.investorKyc')" prop="kyc">
              <el-input :disabled="!editable" v-model="form.kyc"></el-input>
              <HistoryPopover
                :history="investorFeeHistory.kyc"
                :field-name="$t('settings.fee.investorKyc')"
                :value-type="historyFormat.CURRENCY"
              />
            </el-form-item>
          </div>
          <div class="row">
            <el-form-item
              :label="$t('settings.fee.investorReKyc')"
              prop="reKyc"
            >
              <el-input :disabled="!editable" v-model="form.reKyc"></el-input>
              <HistoryPopover
                :history="investorFeeHistory.reKyc"
                :field-name="$t('settings.fee.investorReKyc')"
                :value-type="historyFormat.CURRENCY"
              />
            </el-form-item>
          </div>
          <div class="row">
            <el-form-item :label="$t('settings.fee.investorKyb')" prop="kyb">
              <el-input :disabled="!editable" v-model="form.kyb"></el-input>
              <HistoryPopover
                :history="investorFeeHistory.kyb"
                :field-name="$t('settings.fee.investorKyb')"
                :value-type="historyFormat.CURRENCY"
              />
            </el-form-item>
          </div>
          <div class="row">
            <el-form-item
              :label="$t('settings.fee.investorReKyb')"
              prop="reKyb"
            >
              <el-input :disabled="!editable" v-model="form.reKyb"></el-input>
              <HistoryPopover
                :history="investorFeeHistory.reKyb"
                :field-name="$t('settings.fee.investorReKyb')"
                :value-type="historyFormat.CURRENCY"
              />
            </el-form-item>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { authorityMixin } from "@/util";
import {
  InvestorFeeSettingsData,
  InvestorFeeSettingsHistoryItem,
  InvestorFeeSettingsItem,
} from "@/api";
import {
  ElementForm,
  InputRequired,
  numberFormat,
  NumberRange,
} from "@/util/validation";
import { settingsApi } from "@/resources";
import { isConstraintViolation, isProblem } from "@/resources/problem";
import { HistoryFormat } from "@/util/enum/HistoryFormat";
import HistoryPopover from "@/components/HistoryPopover.vue";

export default defineComponent({
  name: "SettingsFeeInvestor",
  mixins: [authorityMixin],
  components: { HistoryPopover },
  data() {
    return {
      investorFee: inject("investorFee") as InvestorFeeSettingsItem,
      investorFeeHistory: inject(
        "investorFeeHistory"
      ) as InvestorFeeSettingsHistoryItem,
      historyFormat: HistoryFormat,
      form: {} as InvestorFeeSettingsData,
      rules: {
        accreditationAngel: [
          new InputRequired("validation.inputRequired"),
          numberFormat,
          new NumberRange(0.0, 1000.0),
        ],
        accreditationSophisticated: [
          new InputRequired("validation.inputRequired"),
          numberFormat,
          new NumberRange(0.0, 1000.0),
        ],
        administration: [
          new InputRequired("validation.inputRequired"),
          numberFormat,
          new NumberRange(0.0, 1000.0),
        ],
        kyc: [
          new InputRequired("validation.inputRequired"),
          numberFormat,
          new NumberRange(0.0, 1000.0),
        ],
        reKyc: [
          new InputRequired("validation.inputRequired"),
          numberFormat,
          new NumberRange(0.0, 1000.0),
        ],
        kyb: [
          new InputRequired("validation.inputRequired"),
          numberFormat,
          new NumberRange(0.0, 1000.0),
        ],
        reKyb: [
          new InputRequired("validation.inputRequired"),
          numberFormat,
          new NumberRange(0.0, 1000.0),
        ],
      },
      isFormValid: true,
      editable: false,
    };
  },
  watch: {
    investorFee() {
      Object.assign(this.form, this.investorFee);
    },
  },
  mounted() {
    Object.assign(this.form, this.investorFee);
  },
  methods: {
    cancel() {
      Object.assign(this.form, this.investorFee);
      this.editable = false;
    },
    onValidate(prop: string, isVal: boolean) {
      this.isFormValid = isVal;
    },
    edit() {
      this.editable = true;
      Object.assign(this.form, this.investorFee);
    },
    async submit() {
      const form = this.$refs["form"] as ElementForm;
      if (await form.validate()) {
        try {
          await settingsApi.investorFeeSettings({
            investorFeeSettingsData: this.form,
          });
          Object.assign(this.investorFee, this.form);
          this.editable = false;
          this.$emit("update");
        } catch (error) {
          if (isConstraintViolation(error)) {
            error.violations.forEach((violation) =>
              this.$notify.error(this.$t(violation.message))
            );
          } else if (isProblem(error)) {
            this.$notify.error(this.$t(error.detail));
          }
        }
      }
    },
  },
});
</script>
