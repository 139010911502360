/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CampaignFundraiserVerification
 */
export interface CampaignFundraiserVerification {
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    acceptableCompanyType?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    acceptableFinancials?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    acceptableRegistrationCountry?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    acceptableRepute?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    acceptableTargetAmount?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    acceptableValuationAndTerms?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    activitiesMatches?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    checkedAssociationDocs?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    checkedAuditedFinancials?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    checkedCancelledCheque?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    checkedFounderProfiles?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    checkedRegistrationDocument?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    checkedShareholderAgreement?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    checkedValuationDocument?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    clearShareholding?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    clearStrategy?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CampaignFundraiserVerification
     */
    comment?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    fundraisingBudgetAgreed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    fundraisingExperience?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    fundraisingPlanCompleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    hasOtherActivities?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    hasWebsite?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    informationMatch?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    interestInMarket?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    interestSectorForInvestor?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    kybCompleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    marketingExperience?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    nda?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    orientationSessionCompleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    potentialRisks?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    pricingAgreed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    riskAssessmentCompleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    riskRatingAcceptable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    securedLeadInvestor?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    shareholdersCompletedKyc?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    socialMediaPresence?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    traction?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    understandCostsAndLimits?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    validCertificate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    validCertificateInCountry?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    valuableOffer?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    verificationCompleted?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof CampaignFundraiserVerification
     */
    verificationDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignFundraiserVerification
     */
    willingInvestTimeEffort?: boolean;
}

export function CampaignFundraiserVerificationFromJSON(json: any): CampaignFundraiserVerification {
    return CampaignFundraiserVerificationFromJSONTyped(json, false);
}

export function CampaignFundraiserVerificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): CampaignFundraiserVerification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'acceptableCompanyType': !exists(json, 'acceptableCompanyType') ? undefined : json['acceptableCompanyType'],
        'acceptableFinancials': !exists(json, 'acceptableFinancials') ? undefined : json['acceptableFinancials'],
        'acceptableRegistrationCountry': !exists(json, 'acceptableRegistrationCountry') ? undefined : json['acceptableRegistrationCountry'],
        'acceptableRepute': !exists(json, 'acceptableRepute') ? undefined : json['acceptableRepute'],
        'acceptableTargetAmount': !exists(json, 'acceptableTargetAmount') ? undefined : json['acceptableTargetAmount'],
        'acceptableValuationAndTerms': !exists(json, 'acceptableValuationAndTerms') ? undefined : json['acceptableValuationAndTerms'],
        'activitiesMatches': !exists(json, 'activitiesMatches') ? undefined : json['activitiesMatches'],
        'checkedAssociationDocs': !exists(json, 'checkedAssociationDocs') ? undefined : json['checkedAssociationDocs'],
        'checkedAuditedFinancials': !exists(json, 'checkedAuditedFinancials') ? undefined : json['checkedAuditedFinancials'],
        'checkedCancelledCheque': !exists(json, 'checkedCancelledCheque') ? undefined : json['checkedCancelledCheque'],
        'checkedFounderProfiles': !exists(json, 'checkedFounderProfiles') ? undefined : json['checkedFounderProfiles'],
        'checkedRegistrationDocument': !exists(json, 'checkedRegistrationDocument') ? undefined : json['checkedRegistrationDocument'],
        'checkedShareholderAgreement': !exists(json, 'checkedShareholderAgreement') ? undefined : json['checkedShareholderAgreement'],
        'checkedValuationDocument': !exists(json, 'checkedValuationDocument') ? undefined : json['checkedValuationDocument'],
        'clearShareholding': !exists(json, 'clearShareholding') ? undefined : json['clearShareholding'],
        'clearStrategy': !exists(json, 'clearStrategy') ? undefined : json['clearStrategy'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'fundraisingBudgetAgreed': !exists(json, 'fundraisingBudgetAgreed') ? undefined : json['fundraisingBudgetAgreed'],
        'fundraisingExperience': !exists(json, 'fundraisingExperience') ? undefined : json['fundraisingExperience'],
        'fundraisingPlanCompleted': !exists(json, 'fundraisingPlanCompleted') ? undefined : json['fundraisingPlanCompleted'],
        'hasOtherActivities': !exists(json, 'hasOtherActivities') ? undefined : json['hasOtherActivities'],
        'hasWebsite': !exists(json, 'hasWebsite') ? undefined : json['hasWebsite'],
        'informationMatch': !exists(json, 'informationMatch') ? undefined : json['informationMatch'],
        'interestInMarket': !exists(json, 'interestInMarket') ? undefined : json['interestInMarket'],
        'interestSectorForInvestor': !exists(json, 'interestSectorForInvestor') ? undefined : json['interestSectorForInvestor'],
        'kybCompleted': !exists(json, 'kybCompleted') ? undefined : json['kybCompleted'],
        'marketingExperience': !exists(json, 'marketingExperience') ? undefined : json['marketingExperience'],
        'nda': !exists(json, 'nda') ? undefined : json['nda'],
        'orientationSessionCompleted': !exists(json, 'orientationSessionCompleted') ? undefined : json['orientationSessionCompleted'],
        'potentialRisks': !exists(json, 'potentialRisks') ? undefined : json['potentialRisks'],
        'pricingAgreed': !exists(json, 'pricingAgreed') ? undefined : json['pricingAgreed'],
        'riskAssessmentCompleted': !exists(json, 'riskAssessmentCompleted') ? undefined : json['riskAssessmentCompleted'],
        'riskRatingAcceptable': !exists(json, 'riskRatingAcceptable') ? undefined : json['riskRatingAcceptable'],
        'securedLeadInvestor': !exists(json, 'securedLeadInvestor') ? undefined : json['securedLeadInvestor'],
        'shareholdersCompletedKyc': !exists(json, 'shareholdersCompletedKyc') ? undefined : json['shareholdersCompletedKyc'],
        'socialMediaPresence': !exists(json, 'socialMediaPresence') ? undefined : json['socialMediaPresence'],
        'traction': !exists(json, 'traction') ? undefined : json['traction'],
        'understandCostsAndLimits': !exists(json, 'understandCostsAndLimits') ? undefined : json['understandCostsAndLimits'],
        'validCertificate': !exists(json, 'validCertificate') ? undefined : json['validCertificate'],
        'validCertificateInCountry': !exists(json, 'validCertificateInCountry') ? undefined : json['validCertificateInCountry'],
        'valuableOffer': !exists(json, 'valuableOffer') ? undefined : json['valuableOffer'],
        'verificationCompleted': !exists(json, 'verificationCompleted') ? undefined : json['verificationCompleted'],
        'verificationDate': !exists(json, 'verificationDate') ? undefined : (new Date(json['verificationDate'])),
        'willingInvestTimeEffort': !exists(json, 'willingInvestTimeEffort') ? undefined : json['willingInvestTimeEffort'],
    };
}

export function CampaignFundraiserVerificationToJSON(value?: CampaignFundraiserVerification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'acceptableCompanyType': value.acceptableCompanyType,
        'acceptableFinancials': value.acceptableFinancials,
        'acceptableRegistrationCountry': value.acceptableRegistrationCountry,
        'acceptableRepute': value.acceptableRepute,
        'acceptableTargetAmount': value.acceptableTargetAmount,
        'acceptableValuationAndTerms': value.acceptableValuationAndTerms,
        'activitiesMatches': value.activitiesMatches,
        'checkedAssociationDocs': value.checkedAssociationDocs,
        'checkedAuditedFinancials': value.checkedAuditedFinancials,
        'checkedCancelledCheque': value.checkedCancelledCheque,
        'checkedFounderProfiles': value.checkedFounderProfiles,
        'checkedRegistrationDocument': value.checkedRegistrationDocument,
        'checkedShareholderAgreement': value.checkedShareholderAgreement,
        'checkedValuationDocument': value.checkedValuationDocument,
        'clearShareholding': value.clearShareholding,
        'clearStrategy': value.clearStrategy,
        'comment': value.comment,
        'fundraisingBudgetAgreed': value.fundraisingBudgetAgreed,
        'fundraisingExperience': value.fundraisingExperience,
        'fundraisingPlanCompleted': value.fundraisingPlanCompleted,
        'hasOtherActivities': value.hasOtherActivities,
        'hasWebsite': value.hasWebsite,
        'informationMatch': value.informationMatch,
        'interestInMarket': value.interestInMarket,
        'interestSectorForInvestor': value.interestSectorForInvestor,
        'kybCompleted': value.kybCompleted,
        'marketingExperience': value.marketingExperience,
        'nda': value.nda,
        'orientationSessionCompleted': value.orientationSessionCompleted,
        'potentialRisks': value.potentialRisks,
        'pricingAgreed': value.pricingAgreed,
        'riskAssessmentCompleted': value.riskAssessmentCompleted,
        'riskRatingAcceptable': value.riskRatingAcceptable,
        'securedLeadInvestor': value.securedLeadInvestor,
        'shareholdersCompletedKyc': value.shareholdersCompletedKyc,
        'socialMediaPresence': value.socialMediaPresence,
        'traction': value.traction,
        'understandCostsAndLimits': value.understandCostsAndLimits,
        'validCertificate': value.validCertificate,
        'validCertificateInCountry': value.validCertificateInCountry,
        'valuableOffer': value.valuableOffer,
        'verificationCompleted': value.verificationCompleted,
        'verificationDate': value.verificationDate === undefined ? undefined : (value.verificationDate.toISOString().substr(0,10)),
        'willingInvestTimeEffort': value.willingInvestTimeEffort,
    };
}


