/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EmploymentType,
    EmploymentTypeFromJSON,
    EmploymentTypeFromJSONTyped,
    EmploymentTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface Occupation
 */
export interface Occupation {
    /**
     * 
     * @type {string}
     * @memberof Occupation
     */
    companyAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof Occupation
     */
    companyContactNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Occupation
     */
    companyName?: string;
    /**
     * 
     * @type {EmploymentType}
     * @memberof Occupation
     */
    employmentType?: EmploymentType;
    /**
     * 
     * @type {string}
     * @memberof Occupation
     */
    occupation?: string;
}

export function OccupationFromJSON(json: any): Occupation {
    return OccupationFromJSONTyped(json, false);
}

export function OccupationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Occupation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyAddress': !exists(json, 'companyAddress') ? undefined : json['companyAddress'],
        'companyContactNumber': !exists(json, 'companyContactNumber') ? undefined : json['companyContactNumber'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'employmentType': !exists(json, 'employmentType') ? undefined : EmploymentTypeFromJSON(json['employmentType']),
        'occupation': !exists(json, 'occupation') ? undefined : json['occupation'],
    };
}

export function OccupationToJSON(value?: Occupation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyAddress': value.companyAddress,
        'companyContactNumber': value.companyContactNumber,
        'companyName': value.companyName,
        'employmentType': EmploymentTypeToJSON(value.employmentType),
        'occupation': value.occupation,
    };
}


