
import { defineComponent, PropType } from "vue";
import { AttachmentListItem } from "@/api";
import Attachment from "@/components/Attachment.vue";
import { ElUploadRequestOptions } from "element-plus/es/el-upload/src/upload.type";
import { isConstraintViolation, isProblem } from "@/resources/problem";

export interface AttachmentsGroup {
  documents: Array<AttachmentListItem>;
  images: Array<AttachmentListItem>;
}

export interface FileFormat {
  mimetype: string;
  viewName: string;
  extension: string;
}

export default defineComponent({
  name: "Attachments",
  components: { Attachment },
  emits: ["update"],
  props: {
    attachments: {
      type: Object as PropType<Array<AttachmentListItem>>,
      required: true,
    },
    loadAttachment: {
      type: Function,
      required: true,
    },
    attachmentFormats: {
      type: Object as PropType<Array<FileFormat>>,
      required: false,
      default: () =>
        [
          { mimetype: "application/msword", viewName: "DOC" } as FileFormat,
          {
            mimetype:
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            viewName: "DOCX",
          } as FileFormat,
          {
            mimetype: "application/vnd.ms-excel",
            viewName: "XLS",
          } as FileFormat,
          {
            mimetype:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            viewName: "XLSX",
          } as FileFormat,
          {
            mimetype: "application/vnd.ms-powerpoint",
            viewName: "PPT",
          } as FileFormat,
          {
            mimetype:
              "application/vnd.openxmlformats-officedocument.presentationml.presentation",
            viewName: "PPTX",
          } as FileFormat,
          { mimetype: "image/png", viewName: "PNG" } as FileFormat,
          { mimetype: "image/jpeg", viewName: "JPEG" } as FileFormat,
          { mimetype: "application/pdf", viewName: "PDF" } as FileFormat,
        ] as FileFormat[],
    },
    uploadDisabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    uploadAttachment: {
      type: Function,
      required: false,
    },
    uploadAttachmentMaxSize: {
      type: Number,
      required: false,
      default: () => 10 * 1024 * 1024,
    },
    deleteEnabled: {
      type: Function,
      default: () => false,
    },
    deleteMethod: {
      type: Function,
      required: false,
    },
  },
  data: () => ({
    uploadInProgress: false,
  }),
  computed: {
    attachmentsGroup(): AttachmentsGroup {
      let attachmentGroup = this.attachments.reduce(
        (result, currentValue) => {
          (this.isImage(currentValue) ? result.images : result.documents).push(
            currentValue
          );
          return result;
        },
        { images: [], documents: [] } as AttachmentsGroup
      );
      this.sort(attachmentGroup.images);
      this.sort(attachmentGroup.documents);
      return attachmentGroup;
    },
    upload(): boolean {
      return this.uploadAttachment !== undefined && !this.uploadDisabled;
    },
    uploadTip(): string {
      return this.$t("attachment.tip", {
        formats: this.formats(this.attachmentFormats),
        size: this.size(this.uploadAttachmentMaxSize),
      });
    },
    uploadAccept(): string {
      return this.attachmentFormats
        ? this.extensions(this.attachmentFormats)
        : "";
    },
  },
  methods: {
    isImage(attachment: AttachmentListItem) {
      return ["image/jpeg", "image/png"].includes(attachment.mimetype);
    },
    formats(formats: FileFormat[]): string {
      return formats.map((format) => format.viewName).join("/\u200B");
    },
    extensions(formats: FileFormat[]): string {
      return formats
        .map(
          (format) =>
            `.${
              format.extension
                ? format.extension
                : format.viewName.toLowerCase()
            }`
        )
        .join(",");
    },
    size(size: number): string {
      let i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
      return `${Number((size / Math.pow(1024, i)).toFixed(2))} ${
        ["B", "kB", "MB", "GB", "TB"][i]
      }`;
    },
    sort(attachments: AttachmentListItem[] | undefined) {
      if (attachments) {
        attachments.sort((a1, a2) => a1.id - a2.id);
      }
    },
    async download(id: number, name: string) {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(await this.loadAttachment(id));
      link.download = name;
      link.click();
    },
    checkFile(file: File) {
      if (
        !this.attachmentFormats
          ?.map((format) => format.mimetype)
          .includes(file.type)
      ) {
        this.$notify.error(
          this.$t("attachment.validations.inappropriateFileType", {
            file: file.name,
            formats: this.formats(this.attachmentFormats),
          })
        );
        return false;
      }
      if (file.size >= this.uploadAttachmentMaxSize) {
        this.$notify.error(
          this.$t("attachment.validations.maxFileSize", {
            size: this.size(this.uploadAttachmentMaxSize),
          })
        );
        return false;
      }
      return true;
    },
    async uploadFile(upload: ElUploadRequestOptions) {
      if (this.uploadAttachment) {
        try {
          this.uploadInProgress = true;
          await this.uploadAttachment(upload.file);
          upload.onSuccess("");
          this.$emit("update");
          this.$notify.info(
            this.$t("attachment.uploaded", { file: upload.file.name })
          );
        } catch (e) {
          if (isConstraintViolation(e)) {
            e.violations.forEach((violation) =>
              this.$notify.error(this.$t(violation.message))
            );
          } else if (isProblem(e)) {
            this.$notify.error(this.$t(e.detail));
          }
          upload.onError(e);
        } finally {
          this.uploadInProgress = false;
        }
      }
    },
    attachmentDeleteEnabled(attachment: AttachmentListItem): boolean {
      return this.deleteEnabled(attachment);
    },
  },
});
