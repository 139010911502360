<template>
  <div class="container ms-0 mb-5">
    <h3>{{ $t("campaign.summary.title") }}</h3>
    <el-table :data="[summary]">
      <el-table-column :label="$t('date')">
        <template #default="scope">
          {{ $date(scope.row.date) }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('campaign.summary.tentativeAmount')">
        <template #default="scope">
          {{ $curr(scope.row.tentativeAmount) }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('campaign.summary.committedAmount')">
        <template #default="scope">
          {{ $curr(scope.row.committedAmount) }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('campaign.summary.receivedAmount')">
        <template #default="scope">
          {{ $curr(scope.row.receivedAmount) }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('campaign.summary.committedPercent')">
        <template #default="scope">
          {{ scope.row.committedPercent }}
        </template>
      </el-table-column>
    </el-table>
    <br />
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { CampaignSummaryItem } from "@/api";
import { authorityMixin } from "@/util";

export default defineComponent({
  name: "CampaignSummary",
  mixins: [authorityMixin],
  data() {
    return {
      summary: inject("summary") as CampaignSummaryItem,
    };
  },
});
</script>
