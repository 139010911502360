
import { defineComponent, PropType } from "vue";
import { IdDocumentType, PageLegalMemberItem } from "@/api";
import { authorityMixin, PageRequest, pageSortMixin } from "@/util";
import TableFooter from "@/components/TableFooter.vue";

export default defineComponent({
  name: "LegalMembers",
  mixins: [pageSortMixin, authorityMixin],
  components: { TableFooter },
  props: {
    page: {
      type: Object as PropType<PageLegalMemberItem>,
      required: true,
    },
    pageRequest: {
      type: Object as PropType<PageRequest>,
      required: true,
    },
    fetchMembersData: {
      type: Function,
      required: true,
    },
  },
  mounted() {
    this.fetchMembersData();
  },
  data() {
    return {
      idType: IdDocumentType,
    };
  },
  methods: {
    fetchData() {
      this.fetchMembersData();
    },
  },
});
