<template>
  <div class="mb-4 mt-4">
    <div class="el-dropdown-wrapper">
      <el-dropdown class="d-flex align-content-center flex-wrap">
        <span class="el-dropdown-link">
          <span class="initials">{{ initials }}</span>
          <span class="color-light-text">{{ profile.userFullName }}</span>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item>
              <router-link :to="{ name: 'profile' }">
                {{ $t("header.myProfile") }}
              </router-link>
            </el-dropdown-item>
            <el-dropdown-item>
              <router-link :to="{ name: 'changePassword' }">
                {{ $t("header.changePassword") }}
              </router-link>
            </el-dropdown-item>
            <el-dropdown-item @click="doLogout">
              {{ $t("header.logout") }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapMutations, mapState } from "vuex";
import { accountApi } from "@/resources";
import { logoutUser } from "@/util/ws";
import { Subscription } from "rxjs";

export default defineComponent({
  name: "TheUserMenu",
  data() {
    return {
      initials: "" as string,
      logoutUserSubscription: {} as Subscription,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    ...mapMutations("account", ["deleteToken", "setProfile"]),
    async fetchData() {
      const profile = await accountApi.profile();
      (await this).logoutUserSubscription = logoutUser(
        profile.id,
        (message) => {
          if (message.deactivated) {
            this.$notify.info(this.$t("auth.forceLogout"));
          }
          this.deleteToken();
        }
      );
      this.setProfile(profile);
      (await this).initials = profile.userFullName
        .split(" ")
        .map((el) => el[0])
        .join("");
    },
    doLogout() {
      this.deleteToken();
    },
  },
  computed: {
    ...mapState("account", ["profile"]),
  },
});
</script>

<style scoped lang="scss">
@import "src/styles/variables";

.el-dropdown-wrapper {
  position: fixed;
  bottom: 80px;
  width: 240px;
}

.el-dropdown-link {
  display: flex;
  align-items: center;
  margin-left: 30px;
  font-size: 16px;
}

.initials {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: $color-primary;
  color: #fff;
  margin-right: 10px;
}

@media (max-width: $width-md) {
  .el-dropdown-link {
    font-size: 15px;
  }
}
</style>
