
import { defineComponent } from "vue";
import { isConstraintViolation, isProblem } from "@/resources/problem";
import { DeclineInvestmentRequest, InvestmentDeclineReason } from "@/api";
import { ElementForm, InputLength, InputRequired } from "@/util/validation";
import { investmentApi } from "@/resources";

export default defineComponent({
  name: "DeclineInvestmentDialog",
  emits: ["update"],
  props: {
    id: {
      type: Number,
      required: true,
    },
    dialogDisabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dialogVisible: false,
      isFormValid: true,
      declineReasons: [] as InvestmentDeclineReason[],
      form: {} as DeclineInvestmentRequest,
      rules: {
        declineReason: [new InputRequired("validation.selectRequired")],
        reason: [
          new InputLength(255),
          {
            validator: (
              rule: Record<string, unknown>,
              value: string,
              callback: (error?: Error) => unknown
            ) => {
              const constraints = (this
                .form as unknown) as DeclineInvestmentRequest;
              if (
                constraints.declineReason === InvestmentDeclineReason.OTHER &&
                !value
              ) {
                callback(new Error(this.$t("validation.inputRequired")));
              } else {
                callback();
              }
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.declineReasons = Object.values(InvestmentDeclineReason);
  },
  methods: {
    cancel() {
      this.dialogVisible = false;
      this.form = {} as DeclineInvestmentRequest;
    },
    onValidate(prop: string, isVal: boolean) {
      this.isFormValid = isVal;
    },
    async submit() {
      const form = this.$refs["form"] as ElementForm;
      if (await form.validate()) {
        try {
          await investmentApi.decline({
            id: this.id,
            declineInvestmentRequest: this.form,
          });
          this.$notify.info(this.$t("investment.decline.info"));
        } catch (e) {
          if (isConstraintViolation(e)) {
            e.violations.forEach((violation) =>
              this.$notify.error(this.$t(violation.message))
            );
          } else if (isProblem(e)) {
            this.$notify.error(this.$t(e.detail));
          }
        }
        this.$emit("update");
        this.cancel();
      }
    },
  },
});
