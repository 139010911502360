
import { defineComponent } from "vue";
import { investorApi } from "@/resources";
import Headline from "@/components/Headline.vue";
import {
  InvestorCategory,
  InvestorType,
  PageInvestorListItem,
  Country,
  Investor,
} from "@/api";
import { authorityMixin, PageRequest, pageSortMixin } from "@/util";
import TableFooter from "@/components/TableFooter.vue";
import InvestorFilter from "@/views/investor/InvestorFilter.vue";

export interface Filter {
  name: string;
  email: string;
  categories: InvestorCategory[];
  types: InvestorType[];
  countries: Country[];
}

export default defineComponent({
  name: "InvestorList",
  components: { InvestorFilter, Headline, TableFooter },
  mixins: [pageSortMixin, authorityMixin],
  data: () => ({
    investorPage: {} as PageInvestorListItem,
    pageRequest: {} as PageRequest,
    filterVisible: false,
    filter: {} as Filter,
  }),
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.investorPage = await investorApi.investors({
        ...this.pageRequest,
        ...this.filter,
      });
    },
    onRowClick(row: Investor) {
      this.$router.push({
        name: "investor",
        params: { id: row.id },
      });
    },
  },
});
