<template>
  <Headline>
    {{ $t("profile.myProfile") }}
  </Headline>

  <template v-if="!editable && accountDetails.email">
    <div class="container-md ms-0 mb-5 mt-5">
      <div class="row mb-3">
        <div class="col color-light-text">{{ $t("user.givenName") }}</div>
        <div class="col">
          {{ accountDetails.givenName ?? "-" }}
        </div>
        <div class="col color-light-text">{{ $t("user.birthDate") }}</div>
        <div class="col">
          {{ $date(accountDetails.birthDate) }}
        </div>
      </div>
      <div class="row mb-3">
        <div class="col color-light-text">{{ $t("user.lastName") }}</div>
        <div class="col">
          {{ accountDetails.lastName ?? "-" }}
        </div>
        <div class="col color-light-text">
          {{ $t("user.residenceCountry") }}
        </div>
        <div class="col">
          {{ $enums.globals.country(accountDetails.residenceCountry) }}
        </div>
      </div>
      <div class="row mb-3">
        <div class="col color-light-text">{{ $t("user.phone") }}</div>
        <div class="col">
          {{ $phone(accountDetails.phone) }}
        </div>
        <div class="col color-light-text">{{ $t("user.role") }}</div>
        <div class="col">
          {{ accountDetails.role }}
        </div>
      </div>
      <div class="row mb-3">
        <div class="col color-light-text">{{ $t("user.email") }}</div>
        <div class="col">
          {{ accountDetails.email ?? "-" }}
        </div>
        <div class="col color-light-text"></div>
        <div class="col"></div>
      </div>
    </div>
    <el-button type="primary" @click="edit">
      {{ $t("edit") }}
    </el-button>
  </template>
  <template v-if="editable">
    <el-form
      :model="accountDetailsForm"
      :rules="rules"
      status-icon
      ref="profileForm"
    >
      <div class="row">
        <div class="col-md-6">
          <el-form-item :label="$t('user.givenName')" prop="givenName">
            <el-input v-model="accountDetailsForm.givenName"></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.lastName')" prop="lastName">
            <el-input v-model="accountDetailsForm.lastName"></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.phone')" prop="phone">
            <MaskedPhoneNumber
              v-model="accountDetailsForm.phone"
              :isoCountry="'OM'"
            ></MaskedPhoneNumber>
          </el-form-item>
        </div>
        <div class="col-md-6">
          <el-form-item :label="$t('user.birthDate')" prop="birthDate">
            <el-date-picker
              type="date"
              v-model="accountDetailsForm.birthDate"
              class="w-100"
              format="DD/MM/YYYY"
            ></el-date-picker>
          </el-form-item>
          <el-form-item
            :label="$t('user.residenceCountry')"
            prop="residenceCountry"
          >
            <el-select
              v-model="accountDetailsForm.residenceCountry"
              class="w-100"
              clearable
            >
              <el-option
                v-for="(country, key) in residenceCountryOptions"
                :key="key"
                :label="$enums.globals.country(country)"
                :value="country"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>
    </el-form>

    <el-button @click="editable = false">
      {{ $t("cancel") }}
    </el-button>
    <el-button type="primary" @click="submit">
      {{ $t("save") }}
    </el-button>
  </template>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Headline from "@/components/Headline.vue";
import {
  ElementForm,
  InputLength,
  InputRequired,
  nameFormat,
} from "@/util/validation";
import { AccountDetails, AccountDetailsForm, Country } from "@/api";
import { accountApi } from "@/resources";
import { mapMutations } from "vuex";
import { isConstraintViolation, isProblem } from "@/resources/problem";
import MaskedPhoneNumber from "@/components/MaskedPhoneNumber/MaskedPhoneNumber.vue";
import { fixLocalDate } from "@/util";

export default defineComponent({
  name: "Profile",
  components: { Headline, MaskedPhoneNumber },
  data() {
    return {
      residenceCountryOptions: Country,
      accountDetails: {} as AccountDetails,
      accountDetailsForm: {} as AccountDetailsForm,
      editable: false,
      rules: {
        givenName: [new InputRequired(), new InputLength(100), nameFormat],
        lastName: [new InputRequired(), new InputLength(100), nameFormat],
        birthDate: [new InputRequired()],
        residenceCountry: [new InputRequired()],
        phone: [new InputRequired("validation.phoneFormatRequired")],
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    ...mapMutations("account", ["setProfile"]),
    async fetchData() {
      this.accountDetails = await accountApi.account();
    },
    edit() {
      Object.assign(this.accountDetailsForm, this.accountDetails);
      this.editable = true;
    },
    submit() {
      const form = this.$refs["profileForm"] as ElementForm;
      form.validate((valid) => {
        if (valid) {
          this.updateAccountDetails();
        }
        return false;
      });
    },
    async updateAccountDetails() {
      try {
        this.accountDetailsForm.birthDate = fixLocalDate(
          this.accountDetailsForm.birthDate
        );
        await accountApi.updateAccount({
          accountDetailsForm: this.accountDetailsForm,
        });
        this.$notify.info(this.$t("profile.updated"));
        Object.assign(this.accountDetails, this.accountDetailsForm);
        const profile = await accountApi.profile();
        this.setProfile(profile);
        this.editable = false;
      } catch (error) {
        if (isConstraintViolation(error)) {
          error.violations.forEach((violation) =>
            this.$notify.error(this.$t(violation.message))
          );
        }
        if (isProblem(error)) {
          this.$notify.error(this.$t(error.detail));
        }
      }
    },
  },
});
</script>
