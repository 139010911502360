/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    Individual,
    IndividualFromJSON,
    IndividualFromJSONTyped,
    IndividualToJSON,
    InvestorCategory,
    InvestorCategoryFromJSON,
    InvestorCategoryFromJSONTyped,
    InvestorCategoryToJSON,
    InvestorType,
    InvestorTypeFromJSON,
    InvestorTypeFromJSONTyped,
    InvestorTypeToJSON,
    Legal,
    LegalFromJSON,
    LegalFromJSONTyped,
    LegalToJSON,
} from './';

/**
 * 
 * @export
 * @interface Investor
 */
export interface Investor {
    /**
     * 
     * @type {Address}
     * @memberof Investor
     */
    address?: Address;
    /**
     * 
     * @type {InvestorCategory}
     * @memberof Investor
     */
    category?: InvestorCategory;
    /**
     * 
     * @type {number}
     * @memberof Investor
     */
    id: number;
    /**
     * 
     * @type {Individual}
     * @memberof Investor
     */
    individual?: Individual;
    /**
     * 
     * @type {Legal}
     * @memberof Investor
     */
    legal?: Legal;
    /**
     * 
     * @type {InvestorType}
     * @memberof Investor
     */
    type?: InvestorType;
}

export function InvestorFromJSON(json: any): Investor {
    return InvestorFromJSONTyped(json, false);
}

export function InvestorFromJSONTyped(json: any, ignoreDiscriminator: boolean): Investor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'category': !exists(json, 'category') ? undefined : InvestorCategoryFromJSON(json['category']),
        'id': json['id'],
        'individual': !exists(json, 'individual') ? undefined : IndividualFromJSON(json['individual']),
        'legal': !exists(json, 'legal') ? undefined : LegalFromJSON(json['legal']),
        'type': !exists(json, 'type') ? undefined : InvestorTypeFromJSON(json['type']),
    };
}

export function InvestorToJSON(value?: Investor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': AddressToJSON(value.address),
        'category': InvestorCategoryToJSON(value.category),
        'id': value.id,
        'individual': IndividualToJSON(value.individual),
        'legal': LegalToJSON(value.legal),
        'type': InvestorTypeToJSON(value.type),
    };
}


