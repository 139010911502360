/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FieldHistoryView,
    FieldHistoryViewFromJSON,
    FieldHistoryViewFromJSONTyped,
    FieldHistoryViewToJSON,
} from './';

/**
 * 
 * @export
 * @interface CommonFeeSettingsHistoryItem
 */
export interface CommonFeeSettingsHistoryItem {
    /**
     * 
     * @type {Array<FieldHistoryView>}
     * @memberof CommonFeeSettingsHistoryItem
     */
    payment: Array<FieldHistoryView>;
}

export function CommonFeeSettingsHistoryItemFromJSON(json: any): CommonFeeSettingsHistoryItem {
    return CommonFeeSettingsHistoryItemFromJSONTyped(json, false);
}

export function CommonFeeSettingsHistoryItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommonFeeSettingsHistoryItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'payment': ((json['payment'] as Array<any>).map(FieldHistoryViewFromJSON)),
    };
}

export function CommonFeeSettingsHistoryItemToJSON(value?: CommonFeeSettingsHistoryItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'payment': ((value.payment as Array<any>).map(FieldHistoryViewToJSON)),
    };
}


