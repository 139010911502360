
import { defineComponent, inject } from "vue";
import { AccreditationItem, AccreditationItemHistory } from "@/api";
import HistoryPopover from "@/components/HistoryPopover.vue";
import { HistoryFormat } from "@/util/enum/HistoryFormat";

export default defineComponent({
  name: "AccreditationGeneralInfo",
  components: { HistoryPopover },
  data() {
    return {
      accreditation: inject("accreditation") as AccreditationItem,
      accreditationHistory: inject(
        "accreditationHistory"
      ) as AccreditationItemHistory,
      historyFormat: HistoryFormat,
    };
  },
});
