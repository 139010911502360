/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AttachmentListItem,
    AttachmentListItemFromJSON,
    AttachmentListItemToJSON,
    BackOfficeFlowEntityStateCampaignStatus,
    BackOfficeFlowEntityStateCampaignStatusFromJSON,
    BackOfficeFlowEntityStateCampaignStatusToJSON,
    CampaignAttachmentType,
    CampaignAttachmentTypeFromJSON,
    CampaignAttachmentTypeToJSON,
    CampaignChecklist,
    CampaignChecklistFromJSON,
    CampaignChecklistToJSON,
    CampaignDetailsData,
    CampaignDetailsDataFromJSON,
    CampaignDetailsDataToJSON,
    CampaignDetailsItem,
    CampaignDetailsItemFromJSON,
    CampaignDetailsItemToJSON,
    CampaignFundraiserVerification,
    CampaignFundraiserVerificationFromJSON,
    CampaignFundraiserVerificationToJSON,
    CampaignItem,
    CampaignItemFromJSON,
    CampaignItemToJSON,
    CampaignSettingsData,
    CampaignSettingsDataFromJSON,
    CampaignSettingsDataToJSON,
    CampaignSettingsHistoryItem,
    CampaignSettingsHistoryItemFromJSON,
    CampaignSettingsHistoryItemToJSON,
    CampaignSettingsItem,
    CampaignSettingsItemFromJSON,
    CampaignSettingsItemToJSON,
    CampaignStatus,
    CampaignStatusFromJSON,
    CampaignStatusToJSON,
    CampaignVerificationChecklist,
    CampaignVerificationChecklistFromJSON,
    CampaignVerificationChecklistToJSON,
    NoteItem,
    NoteItemFromJSON,
    NoteItemToJSON,
    NoteRequest,
    NoteRequestFromJSON,
    NoteRequestToJSON,
    PageCampaignListItem,
    PageCampaignListItemFromJSON,
    PageCampaignListItemToJSON,
    PageInvestmentSummaryItem,
    PageInvestmentSummaryItemFromJSON,
    PageInvestmentSummaryItemToJSON,
    PageNoteItem,
    PageNoteItemFromJSON,
    PageNoteItemToJSON,
} from '../models';

export interface CampaignRequest {
    id: number;
}

export interface CampaignAttachmentRequest {
    id: number;
    attachmentId: number;
}

export interface CampaignAttachmentsRequest {
    id: number;
    type: CampaignAttachmentType;
}

export interface CampaignDetailsRequest {
    id: number;
}

export interface CampaignInvestmentsRequest {
    id: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface CampaignNotesRequest {
    id: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface CampaignSettingsRequest {
    id: number;
}

export interface CampaignSettingsHistoryRequest {
    id: number;
}

export interface CampaignsRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
    createdDateFrom?: Date;
    createdDateTo?: Date;
    campaignStartedFrom?: Date;
    campaignStartedTo?: Date;
    campaignFinishedFrom?: Date;
    campaignFinishedTo?: Date;
    approvedAmountFrom?: number;
    approvedAmountTo?: number;
    minimumInvestmentFrom?: number;
    minimumInvestmentTo?: number;
    commitmentPercentFrom?: number;
    commitmentPercentTo?: number;
    types?: Array<string>;
    statuses?: Array<CampaignStatus>;
    business?: string;
}

export interface CreateCampaignNoteRequest {
    id: number;
    noteRequest: NoteRequest;
}

export interface DeleteCampaignAttachmentRequest {
    id: number;
    attachmentId: number;
}

export interface DeleteCampaignNoteRequest {
    id: number;
}

export interface GetCampaignChecklistRequest {
    id: number;
}

export interface GetCampaignFundraiserVerificationRequest {
    id: number;
}

export interface GetCampaignVerificationChecklistRequest {
    id: number;
}

export interface StateRequest {
    id: number;
}

export interface UpdateCampaignChecklistRequest {
    id: number;
    campaignChecklist: CampaignChecklist;
}

export interface UpdateCampaignDetailsRequest {
    id: number;
    campaignDetailsData: CampaignDetailsData;
}

export interface UpdateCampaignFundraiserVerificationRequest {
    id: number;
    campaignFundraiserVerification: CampaignFundraiserVerification;
}

export interface UpdateCampaignNoteRequest {
    id: number;
    noteRequest: NoteRequest;
}

export interface UpdateCampaignSettingsRequest {
    id: number;
    campaignSettingsData: CampaignSettingsData;
}

export interface UpdateCampaignVerificationChecklistRequest {
    id: number;
    campaignVerificationChecklist: CampaignVerificationChecklist;
}

export interface UploadCampaignAttachmentRequest {
    id: number;
    type: CampaignAttachmentType;
    file: Blob;
}

/**
 * 
 */
export class BackOfficeCampaignControllerApi extends runtime.BaseAPI {

    /**
     */
    async campaignRaw(requestParameters: CampaignRequest): Promise<runtime.ApiResponse<CampaignItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling campaign.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CampaignItemFromJSON(jsonValue));
    }

    /**
     */
    async campaign(requestParameters: CampaignRequest): Promise<CampaignItem> {
        const response = await this.campaignRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async campaignAttachmentRaw(requestParameters: CampaignAttachmentRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling campaignAttachment.');
        }

        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId','Required parameter requestParameters.attachmentId was null or undefined when calling campaignAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/{id}/attachments/{attachmentId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async campaignAttachment(requestParameters: CampaignAttachmentRequest): Promise<Blob> {
        const response = await this.campaignAttachmentRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async campaignAttachmentsRaw(requestParameters: CampaignAttachmentsRequest): Promise<runtime.ApiResponse<Array<AttachmentListItem>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling campaignAttachments.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling campaignAttachments.');
        }

        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/{id}/attachments`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AttachmentListItemFromJSON));
    }

    /**
     */
    async campaignAttachments(requestParameters: CampaignAttachmentsRequest): Promise<Array<AttachmentListItem>> {
        const response = await this.campaignAttachmentsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async campaignDetailsRaw(requestParameters: CampaignDetailsRequest): Promise<runtime.ApiResponse<CampaignDetailsItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling campaignDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/{id}/details`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CampaignDetailsItemFromJSON(jsonValue));
    }

    /**
     */
    async campaignDetails(requestParameters: CampaignDetailsRequest): Promise<CampaignDetailsItem> {
        const response = await this.campaignDetailsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async campaignInvestmentsRaw(requestParameters: CampaignInvestmentsRequest): Promise<runtime.ApiResponse<PageInvestmentSummaryItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling campaignInvestments.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/{id}/investments`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageInvestmentSummaryItemFromJSON(jsonValue));
    }

    /**
     */
    async campaignInvestments(requestParameters: CampaignInvestmentsRequest): Promise<PageInvestmentSummaryItem> {
        const response = await this.campaignInvestmentsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async campaignNotesRaw(requestParameters: CampaignNotesRequest): Promise<runtime.ApiResponse<PageNoteItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling campaignNotes.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/{id}/notes`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageNoteItemFromJSON(jsonValue));
    }

    /**
     */
    async campaignNotes(requestParameters: CampaignNotesRequest): Promise<PageNoteItem> {
        const response = await this.campaignNotesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async campaignSettingsRaw(requestParameters: CampaignSettingsRequest): Promise<runtime.ApiResponse<CampaignSettingsItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling campaignSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/{id}/settings`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CampaignSettingsItemFromJSON(jsonValue));
    }

    /**
     */
    async campaignSettings(requestParameters: CampaignSettingsRequest): Promise<CampaignSettingsItem> {
        const response = await this.campaignSettingsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async campaignSettingsHistoryRaw(requestParameters: CampaignSettingsHistoryRequest): Promise<runtime.ApiResponse<CampaignSettingsHistoryItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling campaignSettingsHistory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/{id}/settings/history`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CampaignSettingsHistoryItemFromJSON(jsonValue));
    }

    /**
     */
    async campaignSettingsHistory(requestParameters: CampaignSettingsHistoryRequest): Promise<CampaignSettingsHistoryItem> {
        const response = await this.campaignSettingsHistoryRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async campaignsRaw(requestParameters: CampaignsRequest): Promise<runtime.ApiResponse<PageCampaignListItem>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.createdDateFrom !== undefined) {
            queryParameters['createdDateFrom'] = (requestParameters.createdDateFrom as any).toISOString().substr(0,10);
        }

        if (requestParameters.createdDateTo !== undefined) {
            queryParameters['createdDateTo'] = (requestParameters.createdDateTo as any).toISOString().substr(0,10);
        }

        if (requestParameters.campaignStartedFrom !== undefined) {
            queryParameters['campaignStartedFrom'] = (requestParameters.campaignStartedFrom as any).toISOString().substr(0,10);
        }

        if (requestParameters.campaignStartedTo !== undefined) {
            queryParameters['campaignStartedTo'] = (requestParameters.campaignStartedTo as any).toISOString().substr(0,10);
        }

        if (requestParameters.campaignFinishedFrom !== undefined) {
            queryParameters['campaignFinishedFrom'] = (requestParameters.campaignFinishedFrom as any).toISOString().substr(0,10);
        }

        if (requestParameters.campaignFinishedTo !== undefined) {
            queryParameters['campaignFinishedTo'] = (requestParameters.campaignFinishedTo as any).toISOString().substr(0,10);
        }

        if (requestParameters.approvedAmountFrom !== undefined) {
            queryParameters['approvedAmountFrom'] = requestParameters.approvedAmountFrom;
        }

        if (requestParameters.approvedAmountTo !== undefined) {
            queryParameters['approvedAmountTo'] = requestParameters.approvedAmountTo;
        }

        if (requestParameters.minimumInvestmentFrom !== undefined) {
            queryParameters['minimumInvestmentFrom'] = requestParameters.minimumInvestmentFrom;
        }

        if (requestParameters.minimumInvestmentTo !== undefined) {
            queryParameters['minimumInvestmentTo'] = requestParameters.minimumInvestmentTo;
        }

        if (requestParameters.commitmentPercentFrom !== undefined) {
            queryParameters['commitmentPercentFrom'] = requestParameters.commitmentPercentFrom;
        }

        if (requestParameters.commitmentPercentTo !== undefined) {
            queryParameters['commitmentPercentTo'] = requestParameters.commitmentPercentTo;
        }

        if (requestParameters.types) {
            queryParameters['types'] = requestParameters.types;
        }

        if (requestParameters.statuses) {
            queryParameters['statuses'] = requestParameters.statuses;
        }

        if (requestParameters.business !== undefined) {
            queryParameters['business'] = requestParameters.business;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageCampaignListItemFromJSON(jsonValue));
    }

    /**
     */
    async campaigns(requestParameters: CampaignsRequest): Promise<PageCampaignListItem> {
        const response = await this.campaignsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createCampaignNoteRaw(requestParameters: CreateCampaignNoteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling createCampaignNote.');
        }

        if (requestParameters.noteRequest === null || requestParameters.noteRequest === undefined) {
            throw new runtime.RequiredError('noteRequest','Required parameter requestParameters.noteRequest was null or undefined when calling createCampaignNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/{id}/notes`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NoteRequestToJSON(requestParameters.noteRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createCampaignNote(requestParameters: CreateCampaignNoteRequest): Promise<void> {
        await this.createCampaignNoteRaw(requestParameters);
    }

    /**
     */
    async deleteCampaignAttachmentRaw(requestParameters: DeleteCampaignAttachmentRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCampaignAttachment.');
        }

        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId','Required parameter requestParameters.attachmentId was null or undefined when calling deleteCampaignAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/{id}/attachments/{attachmentId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteCampaignAttachment(requestParameters: DeleteCampaignAttachmentRequest): Promise<void> {
        await this.deleteCampaignAttachmentRaw(requestParameters);
    }

    /**
     */
    async deleteCampaignNoteRaw(requestParameters: DeleteCampaignNoteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCampaignNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/notes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteCampaignNote(requestParameters: DeleteCampaignNoteRequest): Promise<void> {
        await this.deleteCampaignNoteRaw(requestParameters);
    }

    /**
     */
    async getCampaignChecklistRaw(requestParameters: GetCampaignChecklistRequest): Promise<runtime.ApiResponse<CampaignChecklist>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCampaignChecklist.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/{id}/checklist`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CampaignChecklistFromJSON(jsonValue));
    }

    /**
     */
    async getCampaignChecklist(requestParameters: GetCampaignChecklistRequest): Promise<CampaignChecklist> {
        const response = await this.getCampaignChecklistRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getCampaignFundraiserVerificationRaw(requestParameters: GetCampaignFundraiserVerificationRequest): Promise<runtime.ApiResponse<CampaignFundraiserVerification>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCampaignFundraiserVerification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/{id}/verification`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CampaignFundraiserVerificationFromJSON(jsonValue));
    }

    /**
     */
    async getCampaignFundraiserVerification(requestParameters: GetCampaignFundraiserVerificationRequest): Promise<CampaignFundraiserVerification> {
        const response = await this.getCampaignFundraiserVerificationRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getCampaignVerificationChecklistRaw(requestParameters: GetCampaignVerificationChecklistRequest): Promise<runtime.ApiResponse<CampaignVerificationChecklist>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCampaignVerificationChecklist.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/{id}/campaign-verification`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CampaignVerificationChecklistFromJSON(jsonValue));
    }

    /**
     */
    async getCampaignVerificationChecklist(requestParameters: GetCampaignVerificationChecklistRequest): Promise<CampaignVerificationChecklist> {
        const response = await this.getCampaignVerificationChecklistRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async stateRaw(requestParameters: StateRequest): Promise<runtime.ApiResponse<BackOfficeFlowEntityStateCampaignStatus>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling state.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/{id}/state`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BackOfficeFlowEntityStateCampaignStatusFromJSON(jsonValue));
    }

    /**
     */
    async state(requestParameters: StateRequest): Promise<BackOfficeFlowEntityStateCampaignStatus> {
        const response = await this.stateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateCampaignChecklistRaw(requestParameters: UpdateCampaignChecklistRequest): Promise<runtime.ApiResponse<CampaignChecklist>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCampaignChecklist.');
        }

        if (requestParameters.campaignChecklist === null || requestParameters.campaignChecklist === undefined) {
            throw new runtime.RequiredError('campaignChecklist','Required parameter requestParameters.campaignChecklist was null or undefined when calling updateCampaignChecklist.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/{id}/checklist`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CampaignChecklistToJSON(requestParameters.campaignChecklist),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CampaignChecklistFromJSON(jsonValue));
    }

    /**
     */
    async updateCampaignChecklist(requestParameters: UpdateCampaignChecklistRequest): Promise<CampaignChecklist> {
        const response = await this.updateCampaignChecklistRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateCampaignDetailsRaw(requestParameters: UpdateCampaignDetailsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCampaignDetails.');
        }

        if (requestParameters.campaignDetailsData === null || requestParameters.campaignDetailsData === undefined) {
            throw new runtime.RequiredError('campaignDetailsData','Required parameter requestParameters.campaignDetailsData was null or undefined when calling updateCampaignDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/{id}/details`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CampaignDetailsDataToJSON(requestParameters.campaignDetailsData),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateCampaignDetails(requestParameters: UpdateCampaignDetailsRequest): Promise<void> {
        await this.updateCampaignDetailsRaw(requestParameters);
    }

    /**
     */
    async updateCampaignFundraiserVerificationRaw(requestParameters: UpdateCampaignFundraiserVerificationRequest): Promise<runtime.ApiResponse<CampaignFundraiserVerification>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCampaignFundraiserVerification.');
        }

        if (requestParameters.campaignFundraiserVerification === null || requestParameters.campaignFundraiserVerification === undefined) {
            throw new runtime.RequiredError('campaignFundraiserVerification','Required parameter requestParameters.campaignFundraiserVerification was null or undefined when calling updateCampaignFundraiserVerification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/{id}/verification`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CampaignFundraiserVerificationToJSON(requestParameters.campaignFundraiserVerification),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CampaignFundraiserVerificationFromJSON(jsonValue));
    }

    /**
     */
    async updateCampaignFundraiserVerification(requestParameters: UpdateCampaignFundraiserVerificationRequest): Promise<CampaignFundraiserVerification> {
        const response = await this.updateCampaignFundraiserVerificationRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateCampaignNoteRaw(requestParameters: UpdateCampaignNoteRequest): Promise<runtime.ApiResponse<NoteItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCampaignNote.');
        }

        if (requestParameters.noteRequest === null || requestParameters.noteRequest === undefined) {
            throw new runtime.RequiredError('noteRequest','Required parameter requestParameters.noteRequest was null or undefined when calling updateCampaignNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/notes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: NoteRequestToJSON(requestParameters.noteRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NoteItemFromJSON(jsonValue));
    }

    /**
     */
    async updateCampaignNote(requestParameters: UpdateCampaignNoteRequest): Promise<NoteItem> {
        const response = await this.updateCampaignNoteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateCampaignSettingsRaw(requestParameters: UpdateCampaignSettingsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCampaignSettings.');
        }

        if (requestParameters.campaignSettingsData === null || requestParameters.campaignSettingsData === undefined) {
            throw new runtime.RequiredError('campaignSettingsData','Required parameter requestParameters.campaignSettingsData was null or undefined when calling updateCampaignSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/{id}/settings`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CampaignSettingsDataToJSON(requestParameters.campaignSettingsData),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateCampaignSettings(requestParameters: UpdateCampaignSettingsRequest): Promise<void> {
        await this.updateCampaignSettingsRaw(requestParameters);
    }

    /**
     */
    async updateCampaignVerificationChecklistRaw(requestParameters: UpdateCampaignVerificationChecklistRequest): Promise<runtime.ApiResponse<CampaignVerificationChecklist>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCampaignVerificationChecklist.');
        }

        if (requestParameters.campaignVerificationChecklist === null || requestParameters.campaignVerificationChecklist === undefined) {
            throw new runtime.RequiredError('campaignVerificationChecklist','Required parameter requestParameters.campaignVerificationChecklist was null or undefined when calling updateCampaignVerificationChecklist.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/{id}/campaign-verification`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CampaignVerificationChecklistToJSON(requestParameters.campaignVerificationChecklist),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CampaignVerificationChecklistFromJSON(jsonValue));
    }

    /**
     */
    async updateCampaignVerificationChecklist(requestParameters: UpdateCampaignVerificationChecklistRequest): Promise<CampaignVerificationChecklist> {
        const response = await this.updateCampaignVerificationChecklistRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async uploadCampaignAttachmentRaw(requestParameters: UploadCampaignAttachmentRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling uploadCampaignAttachment.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling uploadCampaignAttachment.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadCampaignAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/campaign/{id}/attachments/{type}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async uploadCampaignAttachment(requestParameters: UploadCampaignAttachmentRequest): Promise<void> {
        await this.uploadCampaignAttachmentRaw(requestParameters);
    }

}
