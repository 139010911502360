/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AccreditationItem,
    AccreditationItemFromJSON,
    AccreditationItemToJSON,
    AccreditationItemHistory,
    AccreditationItemHistoryFromJSON,
    AccreditationItemHistoryToJSON,
    AccreditationStatus,
    AccreditationStatusFromJSON,
    AccreditationStatusToJSON,
    AttachmentListItem,
    AttachmentListItemFromJSON,
    AttachmentListItemToJSON,
    BackOfficeFlowEntityStateAccreditationStatus,
    BackOfficeFlowEntityStateAccreditationStatusFromJSON,
    BackOfficeFlowEntityStateAccreditationStatusToJSON,
    PageAccreditationListItem,
    PageAccreditationListItemFromJSON,
    PageAccreditationListItemToJSON,
} from '../models';

export interface AccreditationRequest {
    id: number;
}

export interface AccreditationHistoryRequest {
    id: number;
}

export interface AccreditationsRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
    investorName?: string;
    status?: AccreditationStatus;
    createdAtFrom?: Date;
    createdAtTo?: Date;
    approvedAtFrom?: Date;
    approvedAtTo?: Date;
    validTillFrom?: Date;
    validTillTo?: Date;
}

export interface DeleteAccreditationAttachmentRequest {
    id: number;
    attachmentId: number;
}

export interface GetAccreditationAttachmentRequest {
    id: number;
    attachmentId: number;
}

export interface GetAccreditationAttachmentsRequest {
    id: number;
}

export interface GetAccreditationStateRequest {
    id: number;
}

export interface UploadAccreditationAttachmentRequest {
    id: number;
    file: Blob;
}

/**
 * 
 */
export class BackOfficeAccreditationControllerApi extends runtime.BaseAPI {

    /**
     */
    async accreditationRaw(requestParameters: AccreditationRequest): Promise<runtime.ApiResponse<AccreditationItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling accreditation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/accreditation/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AccreditationItemFromJSON(jsonValue));
    }

    /**
     */
    async accreditation(requestParameters: AccreditationRequest): Promise<AccreditationItem> {
        const response = await this.accreditationRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async accreditationHistoryRaw(requestParameters: AccreditationHistoryRequest): Promise<runtime.ApiResponse<AccreditationItemHistory>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling accreditationHistory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/accreditation/{id}/history`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AccreditationItemHistoryFromJSON(jsonValue));
    }

    /**
     */
    async accreditationHistory(requestParameters: AccreditationHistoryRequest): Promise<AccreditationItemHistory> {
        const response = await this.accreditationHistoryRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async accreditationsRaw(requestParameters: AccreditationsRequest): Promise<runtime.ApiResponse<PageAccreditationListItem>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.investorName !== undefined) {
            queryParameters['investorName'] = requestParameters.investorName;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.createdAtFrom !== undefined) {
            queryParameters['createdAtFrom'] = (requestParameters.createdAtFrom as any).toISOString().substr(0,10);
        }

        if (requestParameters.createdAtTo !== undefined) {
            queryParameters['createdAtTo'] = (requestParameters.createdAtTo as any).toISOString().substr(0,10);
        }

        if (requestParameters.approvedAtFrom !== undefined) {
            queryParameters['approvedAtFrom'] = (requestParameters.approvedAtFrom as any).toISOString().substr(0,10);
        }

        if (requestParameters.approvedAtTo !== undefined) {
            queryParameters['approvedAtTo'] = (requestParameters.approvedAtTo as any).toISOString().substr(0,10);
        }

        if (requestParameters.validTillFrom !== undefined) {
            queryParameters['validTillFrom'] = (requestParameters.validTillFrom as any).toISOString().substr(0,10);
        }

        if (requestParameters.validTillTo !== undefined) {
            queryParameters['validTillTo'] = (requestParameters.validTillTo as any).toISOString().substr(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/accreditation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageAccreditationListItemFromJSON(jsonValue));
    }

    /**
     */
    async accreditations(requestParameters: AccreditationsRequest): Promise<PageAccreditationListItem> {
        const response = await this.accreditationsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteAccreditationAttachmentRaw(requestParameters: DeleteAccreditationAttachmentRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteAccreditationAttachment.');
        }

        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId','Required parameter requestParameters.attachmentId was null or undefined when calling deleteAccreditationAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/accreditation/{id}/attachments/{attachmentId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteAccreditationAttachment(requestParameters: DeleteAccreditationAttachmentRequest): Promise<void> {
        await this.deleteAccreditationAttachmentRaw(requestParameters);
    }

    /**
     */
    async getAccreditationAttachmentRaw(requestParameters: GetAccreditationAttachmentRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAccreditationAttachment.');
        }

        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId','Required parameter requestParameters.attachmentId was null or undefined when calling getAccreditationAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/accreditation/{id}/attachments/{attachmentId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async getAccreditationAttachment(requestParameters: GetAccreditationAttachmentRequest): Promise<Blob> {
        const response = await this.getAccreditationAttachmentRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getAccreditationAttachmentsRaw(requestParameters: GetAccreditationAttachmentsRequest): Promise<runtime.ApiResponse<Array<AttachmentListItem>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAccreditationAttachments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/accreditation/{id}/attachments`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AttachmentListItemFromJSON));
    }

    /**
     */
    async getAccreditationAttachments(requestParameters: GetAccreditationAttachmentsRequest): Promise<Array<AttachmentListItem>> {
        const response = await this.getAccreditationAttachmentsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getAccreditationStateRaw(requestParameters: GetAccreditationStateRequest): Promise<runtime.ApiResponse<BackOfficeFlowEntityStateAccreditationStatus>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAccreditationState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/accreditation/{id}/state`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BackOfficeFlowEntityStateAccreditationStatusFromJSON(jsonValue));
    }

    /**
     */
    async getAccreditationState(requestParameters: GetAccreditationStateRequest): Promise<BackOfficeFlowEntityStateAccreditationStatus> {
        const response = await this.getAccreditationStateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async uploadAccreditationAttachmentRaw(requestParameters: UploadAccreditationAttachmentRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling uploadAccreditationAttachment.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadAccreditationAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/accreditation/{id}/attachments`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async uploadAccreditationAttachment(requestParameters: UploadAccreditationAttachmentRequest): Promise<void> {
        await this.uploadAccreditationAttachmentRaw(requestParameters);
    }

}
