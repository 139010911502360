<template>
  <div class="container ms-0 mb-0" v-if="checklist">
    <div class="row mb-3">
      <div class="col">
        {{ $t("campaign.collateralReview.valued") }}
      </div>
      <div class="col wrap-text">
        <Decision
          v-model:checked="checklist.valued"
          @decided="updateChecklistValue('valued', $event)"
        />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col">
        {{ $t("campaign.collateralReview.audited") }}
      </div>
      <div class="col wrap-text">
        <Decision
          :checked="checklist.audited"
          @decided="updateChecklistValue('audited', $event)"
        />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col">
        {{ $t("campaign.collateralReview.documentAccepted") }}
      </div>
      <div class="col wrap-text">
        <Decision
          :checked="checklist.documentAccepted"
          @decided="updateChecklistValue('documentAccepted', $event)"
        />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col">
        {{ $t("campaign.collateralReview.campaignVideoAccepted") }}
      </div>
      <div class="col wrap-text">
        <Decision
          :checked="checklist.campaignVideoAccepted"
          @decided="updateChecklistValue('campaignVideoAccepted', $event)"
        />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col">
        {{ $t("campaign.collateralReview.campaignImageAccepted") }}
      </div>
      <div class="col wrap-text">
        <Decision
          :checked="checklist.campaignImageAccepted"
          @decided="updateChecklistValue('campaignImageAccepted', $event)"
        />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col">
        {{ $t("campaign.collateralReview.sectionImagesAccepted") }}
      </div>
      <div class="col wrap-text">
        <Decision
          :checked="checklist.sectionImagesAccepted"
          @decided="updateChecklistValue('sectionImagesAccepted', $event)"
        />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col">
        {{ $t("campaign.collateralReview.sectionContentComplete") }}
      </div>
      <div class="col wrap-text">
        <Decision
          :checked="checklist.sectionContentComplete"
          @decided="updateChecklistValue('sectionContentComplete', $event)"
        />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col">
        {{ $t("campaign.collateralReview.campaignSignedOff") }}
      </div>
      <div class="col wrap-text">
        <Decision
          :checked="checklist.campaignSignedOff"
          @decided="updateChecklistValue('campaignSignedOff', $event)"
        />
      </div>
    </div>
    <div class="row mb-lg-0">
      <div class="col fw-bolder">
        {{ $t("campaign.collateralReview.reviewCompleted") }}
      </div>
      <div class="col wrap-text">
        <Decision
          :checked="checklist.reviewCompleted"
          :disabled="
            !Campaign.COLLATERAL_REVIEW_STATUSES.includes(campaign.status)
          "
          @decided="updateChecklistValue('reviewCompleted', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { CampaignChecklist, CampaignItem } from "@/api";
import { campaignApi } from "@/resources";
import Decision from "@/components/Decision.vue";
import { isConstraintViolation, isProblem } from "@/resources/problem";
import { Campaign } from "@/util/enum/campaign";

export default defineComponent({
  name: "CampaignCollateralReview",
  computed: {
    Campaign() {
      return Campaign;
    },
  },
  components: { Decision },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      checklist: {} as CampaignChecklist,
      campaign: inject("campaign") as CampaignItem,
    };
  },
  async mounted() {
    await this.fetchChecklist();
  },
  methods: {
    async fetchChecklist() {
      this.checklist = await campaignApi.getCampaignChecklist({
        id: Number(this.id),
      });
    },
    async updateChecklistValue(field: string, value: boolean | undefined) {
      try {
        if (
          value != undefined ||
          (value == undefined && !this.checklist.reviewCompleted)
        ) {
          this.checklist = await campaignApi.updateCampaignChecklist({
            id: Number(this.id),
            campaignChecklist: { ...this.checklist, [field]: value },
          });
        }
      } catch (error) {
        if (isConstraintViolation(error)) {
          error.violations.forEach((violation) =>
            this.$notify.error(this.$t(violation.message))
          );
        } else if (isProblem(error)) {
          this.$notify.error(this.$t(error.detail));
        }
      }
    },
  },
});
</script>
