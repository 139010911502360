
import { defineComponent } from "vue";
import { isConstraintViolation, isProblem } from "@/resources/problem";
import { BaseCampaignOperationRequest } from "@/api";
import { ElementForm, InputLength, InputRequired } from "@/util/validation";

export default defineComponent({
  name: "BaseCampaignReasonDialog",
  emits: ["update"],
  props: {
    header: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    infoMessage: {
      type: String,
      required: false,
    },
    dialogDisabled: {
      type: Boolean,
      required: true,
    },
    onSubmit: {
      type: Function,
      required: true,
    },
    commentLength: {
      type: Number,
      default: 1000,
    },
  },
  data() {
    return {
      dialogVisible: false,
      isFormValid: true,
      form: {} as BaseCampaignOperationRequest,
      rules: {
        reason: [
          new InputRequired("validation.inputRequired"),
          new InputLength(this.commentLength),
        ],
      },
    };
  },
  methods: {
    cancel() {
      this.dialogVisible = false;
      this.form = {} as BaseCampaignOperationRequest;
    },
    onValidate(prop: string, isVal: boolean) {
      this.isFormValid = isVal;
    },
    async submit() {
      const form = this.$refs["form"] as ElementForm;
      if (await form.validate()) {
        try {
          await this.onSubmit(this.form);
          if (this.infoMessage) {
            this.$notify.info(this.infoMessage);
          }
        } catch (e) {
          if (isConstraintViolation(e)) {
            e.violations.forEach((violation) =>
              this.$notify.error(this.$t(violation.message))
            );
          } else if (isProblem(e)) {
            this.$notify.error(this.$t(e.detail));
          }
        }
        this.$emit("update");
        this.cancel();
      }
    },
  },
});
