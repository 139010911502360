/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum RoleActor {
    USER = 'USER',
    SYSTEM = 'SYSTEM'
}

export function RoleActorFromJSON(json: any): RoleActor {
    return RoleActorFromJSONTyped(json, false);
}

export function RoleActorFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleActor {
    return json as RoleActor;
}

export function RoleActorToJSON(value?: RoleActor | null): any {
    return value as any;
}

