/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InvestorIndividualChecklist,
    InvestorIndividualChecklistFromJSON,
    InvestorIndividualChecklistFromJSONTyped,
    InvestorIndividualChecklistToJSON,
    InvestorLegalChecklist,
    InvestorLegalChecklistFromJSON,
    InvestorLegalChecklistFromJSONTyped,
    InvestorLegalChecklistToJSON,
    InvestorType,
    InvestorTypeFromJSON,
    InvestorTypeFromJSONTyped,
    InvestorTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface InvestorChecklist
 */
export interface InvestorChecklist {
    /**
     * 
     * @type {InvestorIndividualChecklist}
     * @memberof InvestorChecklist
     */
    individual?: InvestorIndividualChecklist;
    /**
     * 
     * @type {InvestorLegalChecklist}
     * @memberof InvestorChecklist
     */
    legal?: InvestorLegalChecklist;
    /**
     * 
     * @type {InvestorType}
     * @memberof InvestorChecklist
     */
    type?: InvestorType;
}

export function InvestorChecklistFromJSON(json: any): InvestorChecklist {
    return InvestorChecklistFromJSONTyped(json, false);
}

export function InvestorChecklistFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestorChecklist {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'individual': !exists(json, 'individual') ? undefined : InvestorIndividualChecklistFromJSON(json['individual']),
        'legal': !exists(json, 'legal') ? undefined : InvestorLegalChecklistFromJSON(json['legal']),
        'type': !exists(json, 'type') ? undefined : InvestorTypeFromJSON(json['type']),
    };
}

export function InvestorChecklistToJSON(value?: InvestorChecklist | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'individual': InvestorIndividualChecklistToJSON(value.individual),
        'legal': InvestorLegalChecklistToJSON(value.legal),
        'type': InvestorTypeToJSON(value.type),
    };
}


