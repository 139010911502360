
import { computed, defineComponent } from "vue";
import TabLink from "@/components/TabLink.vue";
import {
  BackOfficeFlowEntityStatePreScreeningStatus,
  PreScreeningItem,
  PreScreeningQuizItem,
  PreScreeningStatus,
} from "@/api";
import { preScreeningApi, preScreeningOperationApi } from "@/resources";
import Headline from "@/components/Headline.vue";
import BackLink from "@/components/BackLink.vue";
import { authorityMixin } from "@/util";
import Status from "@/components/Status.vue";
import OkCancelDialog from "@/views/components/OkCancelDialog.vue";

export default defineComponent({
  name: "PreScreeningView",
  mixins: [authorityMixin],
  components: { Headline, TabLink, BackLink, Status, OkCancelDialog },
  props: ["id"],
  provide() {
    return {
      preScreeningId: computed(() => this.id),
      preScreening: computed(() =>
        this.preScreening.id ? this.preScreening : {}
      ),
      preScreeningQuiz: computed(() =>
        this.preScreeningQuiz.quiz ? this.preScreeningQuiz : {}
      ),
    };
  },
  data() {
    return {
      preScreening: {} as PreScreeningItem,
      preScreeningState: {} as BackOfficeFlowEntityStatePreScreeningStatus,
      preScreeningQuiz: {} as PreScreeningQuizItem,
      preScreeningStatuses: Object.values(PreScreeningStatus).filter(
        (status) =>
          status !== PreScreeningStatus.DRAFT &&
          status !== PreScreeningStatus.RECEIVED
      ),
    };
  },
  async mounted() {
    await this.fetchPreScreeningData();
  },
  methods: {
    async fetchPreScreeningData() {
      await Promise.all([
        this.fetchPreScreening(),
        this.fetchPreScreeningState(),
        this.fetchPreScreeningQuiz(),
      ]);
    },
    async fetchPreScreening() {
      this.preScreening = await preScreeningApi.preScreening({ id: this.id });
    },
    async fetchPreScreeningState() {
      this.preScreeningState = await preScreeningApi.getPreScreeningState({
        id: this.id,
      });
    },
    async fetchPreScreeningQuiz() {
      this.preScreeningQuiz = await preScreeningApi.preScreeningQuiz({
        id: this.id,
      });
    },
    async changePreScreeningStatus(status: PreScreeningStatus) {
      await preScreeningOperationApi.changePreScreeningStatus({
        id: this.id,
        backOfficeFlowStatusOperationRequestPreScreeningStatus: {
          status: status,
        },
      });
    },
  },
});
