/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum BusinessSector {
    AGRICULTURE_FISHERY = 'AGRICULTURE_FISHERY',
    ARTS_ENTERTAINMENT = 'ARTS_ENTERTAINMENT',
    CONSTRUCTION_INFRASTRUCTURE = 'CONSTRUCTION_INFRASTRUCTURE',
    GOODS_RETAIL = 'GOODS_RETAIL',
    EDTECH_EDUCATION = 'EDTECH_EDUCATION',
    ENERGY = 'ENERGY',
    FASHION_DESIGN = 'FASHION_DESIGN',
    FINTECH_FINANCE = 'FINTECH_FINANCE',
    FOODTECH_FOOD_BEVERAGE = 'FOODTECH_FOOD_BEVERAGE',
    HEALTH_WELLNESS = 'HEALTH_WELLNESS',
    HEALTHCARE = 'HEALTHCARE',
    IT = 'IT',
    MANUFACTURING = 'MANUFACTURING',
    SERVICE = 'SERVICE',
    SPORT = 'SPORT',
    TRANSPORT = 'TRANSPORT',
    REAL_ESTATE = 'REAL_ESTATE',
    SCIENCE_RESEARCH_DEVELOPMENT = 'SCIENCE_RESEARCH_DEVELOPMENT',
    TRAVEL_TOURISM = 'TRAVEL_TOURISM',
    OTHER = 'OTHER'
}

export function BusinessSectorFromJSON(json: any): BusinessSector {
    return BusinessSectorFromJSONTyped(json, false);
}

export function BusinessSectorFromJSONTyped(json: any, ignoreDiscriminator: boolean): BusinessSector {
    return json as BusinessSector;
}

export function BusinessSectorToJSON(value?: BusinessSector | null): any {
    return value as any;
}

