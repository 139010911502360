
import { defineComponent } from "vue";
import { investmentApi } from "@/resources";
import Headline from "@/components/Headline.vue";
import { CampaignStatus, Investment, PageInvestmentListItem } from "@/api";
import { authorityMixin, PageRequest, pageSortMixin } from "@/util";
import TableFooter from "@/components/TableFooter.vue";
import InvestmentFilter from "@/views/investment/InvestmentFilter.vue";
import Status from "@/components/Status.vue";

export interface Filter {
  investor: string | undefined;
  statuses: CampaignStatus[];
  committedAmountFrom: number | undefined;
  committedAmountTo: number | undefined;
  receivedAmountFrom: number | undefined;
  receivedAmountTo: number | undefined;
  createdDateFrom: Date | undefined;
  createdDateTo: Date | undefined;
  campaignId: string | undefined;
}

export default defineComponent({
  name: "InvestmentList",
  components: { Status, InvestmentFilter, Headline, TableFooter },
  mixins: [pageSortMixin, authorityMixin],
  data: () => ({
    investmentPage: {} as PageInvestmentListItem,
    pageRequest: {} as PageRequest,
    filterVisible: false,
    filter: {} as Filter,
  }),
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.investmentPage = await investmentApi.investments({
        ...this.pageRequest,
        ...this.filter,
      });
    },
    onRowClick(row: Investment) {
      this.$router.push({
        name: "investment",
        params: { id: row.id },
      });
    },
  },
});
