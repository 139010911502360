/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ActorType,
    ActorTypeFromJSON,
    ActorTypeFromJSONTyped,
    ActorTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface AttachmentListItem
 */
export interface AttachmentListItem {
    /**
     * 
     * @type {number}
     * @memberof AttachmentListItem
     */
    actorId?: number;
    /**
     * 
     * @type {ActorType}
     * @memberof AttachmentListItem
     */
    actorType?: ActorType;
    /**
     * 
     * @type {number}
     * @memberof AttachmentListItem
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AttachmentListItem
     */
    mimetype: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentListItem
     */
    name: string;
}

export function AttachmentListItemFromJSON(json: any): AttachmentListItem {
    return AttachmentListItemFromJSONTyped(json, false);
}

export function AttachmentListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttachmentListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actorId': !exists(json, 'actorId') ? undefined : json['actorId'],
        'actorType': !exists(json, 'actorType') ? undefined : ActorTypeFromJSON(json['actorType']),
        'id': json['id'],
        'mimetype': json['mimetype'],
        'name': json['name'],
    };
}

export function AttachmentListItemToJSON(value?: AttachmentListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actorId': value.actorId,
        'actorType': ActorTypeToJSON(value.actorType),
        'id': value.id,
        'mimetype': value.mimetype,
        'name': value.name,
    };
}


