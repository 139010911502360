<template>
  <div class="card">
    <div class="card-main">
      <BackLink />
      <Headline v-if="deal.id" :class="'align-items-start'">
        {{ $t("deals.number") }}
        <div class="h1-sm" style="display: inline">
          {{ id }} - {{ deal.campaign.name }}
        </div>
      </Headline>
    </div>
    <div class="card-foot">
      <ul class="nav nav-tabs mb-3">
        <TabLink to="dealDetails">
          {{ $t("deals.tabs.details") }}
        </TabLink>
        <TabLink to="dealAttachments">
          {{ $t("deals.tabs.attachments") }}
        </TabLink>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="tab-content">
      <div class="tab-pane active">
        <router-view @update="fetchData"></router-view>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import TabLink from "@/components/TabLink.vue";
import Headline from "@/components/Headline.vue";
import { dealApi } from "@/resources";
import BackLink from "@/components/BackLink.vue";
import { Deal } from "@/api";

export default defineComponent({
  name: "DealView",
  components: {
    Headline,
    TabLink,
    BackLink,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  provide() {
    return {
      deal: computed(() => this.deal),
    };
  },
  data() {
    return {
      deal: {} as Deal,
    };
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchDeal() {
      this.deal = await dealApi.deal({ id: Number(this.id) });
    },
    async fetchData() {
      await Promise.all([this.fetchDeal()]);
    },
  },
});
</script>
