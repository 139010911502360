
import { defineComponent } from "vue";
import { userApi } from "@/resources";
import { isProblem } from "@/resources/problem";

export default defineComponent({
  name: "ActivateUserDialog",
  emits: ["update"],
  props: ["id", "givenName", "lastName"],
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    async activateUser() {
      try {
        await userApi.activateUser({
          id: this.id,
        });
        this.$notify.info(
          this.$t("user.activate", {
            givenName: this.givenName,
            lastName: this.lastName,
          })
        );
        this.$emit("update");
        this.dialogVisible = false;
      } catch (e) {
        if (isProblem(e)) {
          this.$notify.error(this.$t(e.detail));
        }
      }
    },
  },
});
