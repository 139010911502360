<template>
  <div class="row mb-3">
    <div class="col mb-3">
      <h3>{{ $t("address.currentAddress") }}</h3>
    </div>
    <div class="row ms-0 mb-3">
      <div class="col color-light-text">
        {{ $t("address.apartment") }}
      </div>
      <div class="col">
        {{ currentAddress.apartment ?? "-" }}
      </div>
    </div>
    <div class="row ms-0 mb-3">
      <div class="col color-light-text">
        {{ $t("address.building") }}
      </div>
      <div class="col">
        {{ currentAddress.building ?? "-" }}
      </div>
    </div>
    <div class="row ms-0 mb-3">
      <div class="col color-light-text">
        {{ $t("address.street") }}
      </div>
      <div class="col">
        {{ currentAddress.street ?? "-" }}
      </div>
    </div>
    <div class="row ms-0 mb-3">
      <div class="col color-light-text">
        {{ $t("address.poBox") }}
      </div>
      <div class="col">
        {{ currentAddress.poBox ?? "-" }}
      </div>
    </div>
    <div class="row ms-0 mb-3">
      <div class="col color-light-text">
        {{ $t("address.postalCode") }}
      </div>
      <div class="col">
        {{ currentAddress.postalCode ?? "-" }}
      </div>
    </div>
    <div class="row ms-0 mb-3">
      <div class="col color-light-text">
        {{ $t("address.city") }}
      </div>
      <div class="col">
        {{ currentAddress.city ?? "-" }}
      </div>
    </div>
    <div class="row ms-0 mb-3">
      <div class="col color-light-text">
        {{ $t("address.state") }}
      </div>
      <div class="col">
        {{ currentAddress.state ?? "-" }}
      </div>
    </div>
    <div class="row ms-0 mb-3">
      <div class="col color-light-text">
        {{ $t("address.country") }}
      </div>
      <div class="col">
        {{ $enums.globals.countryIso(currentAddress.country) }}
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { CurrentAddress } from "@/api";

export default defineComponent({
  name: "CurrentAddress",
  props: {
    currentAddress: {
      type: Object as PropType<CurrentAddress>,
      required: true,
    },
  },
});
</script>
