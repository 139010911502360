<template>
  <h1>Sorry, you are not allowed to access this page</h1>
  <p>Please, contact your system administrator for clarification</p>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "NotFound",
});
</script>
