<template>
  <div class="container-filters mt-4 mb-3">
    <el-form
      @validate="onValidate"
      :model="filter"
      :rules="rules"
      ref="filter-form"
      @change="$emit('update:campaignFilter', filter)"
    >
      <div class="row">
        <div class="col-4">
          <el-form-item :label="$t('campaign.business')" prop="business">
            <el-input v-model="filter.business"></el-input>
          </el-form-item>
          <el-form-item :label="$t('filter.status')">
            <el-select
              v-model="filter.statuses"
              class="w-100"
              multiple
              collapse-tags
            >
              <el-option
                v-for="(status, key) in statuses"
                :key="key"
                :label="$enums.campaign.status(status)"
                :value="status"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('campaign.type.title')">
            <el-select
              v-model="filter.types"
              class="w-100"
              multiple
              collapse-tags
            >
              <el-option
                v-for="(type, key) in type"
                :key="key"
                :label="$enums.campaign.type(type)"
                :value="type"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="col-4">
          <label class="el-form-item__label">{{
            $t("campaign.approvedAmount")
          }}</label>
          <div class="d-flex gap-2">
            <el-form-item prop="approvedAmountFrom">
              <el-input
                v-model.number="filter.approvedAmountFrom"
                :placeholder="$t('filter.from')"
              >
                <template #prepend>
                  <font-awesome-icon :icon="['fas', 'dollar-sign']" />
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="approvedAmountTo">
              <el-input
                v-model.number="filter.approvedAmountTo"
                :placeholder="$t('filter.to')"
              >
                <template #prepend>
                  <font-awesome-icon :icon="['fas', 'dollar-sign']" />
                </template>
              </el-input>
            </el-form-item>
          </div>
          <label class="el-form-item__label">{{
            $t("campaign.minimumInvestment")
          }}</label>
          <div class="d-flex gap-2">
            <el-form-item prop="minimumInvestmentFrom">
              <el-input
                v-model.number="filter.minimumInvestmentFrom"
                :placeholder="$t('filter.from')"
              >
                <template #prepend>
                  <font-awesome-icon :icon="['fas', 'dollar-sign']" />
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="minimumInvestmentTo">
              <el-input
                v-model.number="filter.minimumInvestmentTo"
                :placeholder="$t('filter.to')"
              >
                <template #prepend>
                  <font-awesome-icon :icon="['fas', 'dollar-sign']" />
                </template>
              </el-input>
            </el-form-item>
          </div>
          <label class="el-form-item__label">{{
            $t("campaign.summary.committedPercent")
          }}</label>
          <div class="d-flex gap-2">
            <el-form-item prop="commitmentPercentFrom">
              <el-input
                v-model.number="filter.commitmentPercentFrom"
                :placeholder="$t('filter.from')"
              >
                <template #prepend>
                  <font-awesome-icon :icon="['fas', 'percent']" />
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="commitmentPercentTo">
              <el-input
                v-model.number="filter.commitmentPercentTo"
                :placeholder="$t('filter.to')"
              >
                <template #prepend>
                  <font-awesome-icon :icon="['fas', 'percent']" />
                </template>
              </el-input>
            </el-form-item>
          </div>
        </div>
        <div class="col-4">
          <el-form-item :label="$t('filter.createDate')">
            <div class="d-flex gap-2">
              <el-date-picker
                type="date"
                v-model="filter.createdDateFrom"
                format="DD/MM/YYYY"
                :placeholder="$t('filter.from')"
              ></el-date-picker>
              <el-date-picker
                type="date"
                v-model="filter.createdDateTo"
                format="DD/MM/YYYY"
                :placeholder="$t('filter.to')"
              ></el-date-picker>
            </div>
          </el-form-item>
          <el-form-item :label="$t('campaign.campaignStartDate')">
            <div class="d-flex gap-2">
              <el-date-picker
                type="date"
                v-model="filter.campaignStartedFrom"
                format="DD/MM/YYYY"
                :placeholder="$t('filter.from')"
              ></el-date-picker>
              <el-date-picker
                type="date"
                v-model="filter.campaignStartedTo"
                format="DD/MM/YYYY"
                :placeholder="$t('filter.to')"
              ></el-date-picker>
            </div>
          </el-form-item>
          <el-form-item :label="$t('campaign.campaignEndDate')">
            <div class="d-flex gap-2">
              <el-date-picker
                type="date"
                v-model="filter.campaignFinishedFrom"
                format="DD/MM/YYYY"
                :placeholder="$t('filter.from')"
              ></el-date-picker>
              <el-date-picker
                type="date"
                v-model="filter.campaignFinishedTo"
                format="DD/MM/YYYY"
                :placeholder="$t('filter.to')"
              ></el-date-picker>
            </div>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
  <div class="mb-5">
    <el-button type="primary" @click="submit" :disabled="!isFormValid">
      {{ $t("apply") }}
    </el-button>
    <el-button type="default" @click="resetFilter">
      {{ $t("filter.reset") }}
    </el-button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { CampaignStatus, CampaignType } from "@/api";
import {
  ElementForm,
  InputLength,
  numberFormat,
  NumberRange,
} from "@/util/validation";
import { Filter } from "@/views/campaign/CampaignList.vue";

export default defineComponent({
  emits: ["update:campaignFilter", "submit"],
  props: ["campaignFilter"],
  data() {
    return {
      statuses: [] as CampaignStatus[],
      type: [] as CampaignType[],
      isFormValid: true,
      filter: {} as Filter,
      rules: {
        business: [new InputLength(100)],
        approvedAmount: [numberFormat],
        minimumInvestmentFrom: [numberFormat],
        minimumInvestmentTo: [numberFormat],
        approvedAmountFrom: [numberFormat],
        approvedAmountTo: [numberFormat],
        commitmentPercentFrom: [numberFormat, new NumberRange(0.0, 100)],
        commitmentPercentTo: [numberFormat, new NumberRange(0.0, 100)],
      },
    };
  },
  async mounted() {
    this.filter = this.campaignFilter;
    this.statuses = Object.values(CampaignStatus);
    this.type = Object.values(CampaignType);
  },
  methods: {
    submit() {
      const form = this.$refs["filter-form"] as ElementForm;
      form.validate(async (valid) => {
        if (valid) {
          this.$emit("submit");
        }
      });
    },
    onValidate(prop: string, isVal: boolean) {
      this.isFormValid = isVal;
    },
    resetFilter() {
      this.filter = {} as Filter;
      this.$emit("update:campaignFilter", this.filter);
      this.$emit("submit");
    },
  },
});
</script>
