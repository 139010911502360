
import { defineComponent } from "vue";
import Headline from "@/components/Headline.vue";
import {
  ElementForm,
  InputLength,
  InputRequired,
  nameFormat,
} from "@/util/validation";
import { AccountDetails, AccountDetailsForm, Country } from "@/api";
import { accountApi } from "@/resources";
import { mapMutations } from "vuex";
import { isConstraintViolation, isProblem } from "@/resources/problem";
import MaskedPhoneNumber from "@/components/MaskedPhoneNumber/MaskedPhoneNumber.vue";
import { fixLocalDate } from "@/util";

export default defineComponent({
  name: "Profile",
  components: { Headline, MaskedPhoneNumber },
  data() {
    return {
      residenceCountryOptions: Country,
      accountDetails: {} as AccountDetails,
      accountDetailsForm: {} as AccountDetailsForm,
      editable: false,
      rules: {
        givenName: [new InputRequired(), new InputLength(100), nameFormat],
        lastName: [new InputRequired(), new InputLength(100), nameFormat],
        birthDate: [new InputRequired()],
        residenceCountry: [new InputRequired()],
        phone: [new InputRequired("validation.phoneFormatRequired")],
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    ...mapMutations("account", ["setProfile"]),
    async fetchData() {
      this.accountDetails = await accountApi.account();
    },
    edit() {
      Object.assign(this.accountDetailsForm, this.accountDetails);
      this.editable = true;
    },
    submit() {
      const form = this.$refs["profileForm"] as ElementForm;
      form.validate((valid) => {
        if (valid) {
          this.updateAccountDetails();
        }
        return false;
      });
    },
    async updateAccountDetails() {
      try {
        this.accountDetailsForm.birthDate = fixLocalDate(
          this.accountDetailsForm.birthDate
        );
        await accountApi.updateAccount({
          accountDetailsForm: this.accountDetailsForm,
        });
        this.$notify.info(this.$t("profile.updated"));
        Object.assign(this.accountDetails, this.accountDetailsForm);
        const profile = await accountApi.profile();
        this.setProfile(profile);
        this.editable = false;
      } catch (error) {
        if (isConstraintViolation(error)) {
          error.violations.forEach((violation) =>
            this.$notify.error(this.$t(violation.message))
          );
        }
        if (isProblem(error)) {
          this.$notify.error(this.$t(error.detail));
        }
      }
    },
  },
});
