/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BaseCampaignOperationRequest
 */
export interface BaseCampaignOperationRequest {
    /**
     * 
     * @type {string}
     * @memberof BaseCampaignOperationRequest
     */
    reason: string;
}

export function BaseCampaignOperationRequestFromJSON(json: any): BaseCampaignOperationRequest {
    return BaseCampaignOperationRequestFromJSONTyped(json, false);
}

export function BaseCampaignOperationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseCampaignOperationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reason': json['reason'],
    };
}

export function BaseCampaignOperationRequestToJSON(value?: BaseCampaignOperationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reason': value.reason,
    };
}


