/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum Currency {
    USD = 'USD',
    OMR = 'OMR',
    AED = 'AED',
    QAR = 'QAR',
    SAR = 'SAR',
    BHD = 'BHD',
    KWD = 'KWD'
}

export function CurrencyFromJSON(json: any): Currency {
    return CurrencyFromJSONTyped(json, false);
}

export function CurrencyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Currency {
    return json as Currency;
}

export function CurrencyToJSON(value?: Currency | null): any {
    return value as any;
}

