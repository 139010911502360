export class PageRequest {
  size: number;
  page: number;
  sort: string[];

  constructor(size: number, page: number, sort: string[]) {
    this.size = size;
    this.page = page;
    this.sort = sort;
  }
}
