
import { defineComponent, PropType } from "vue";
import { FieldHistoryView, FlowActor } from "@/api";
import { authorityMixin } from "@/util";
import { HistoryFormat } from "@/util/enum/HistoryFormat";

export default defineComponent({
  name: "HistoryPopover",
  mixins: [authorityMixin],
  props: {
    history: {
      type: Object as PropType<FieldHistoryView[]>,
      required: false,
    },
    fieldName: {
      type: String,
      required: true,
    },
    valueType: {
      type: String,
      required: false,
      default: HistoryFormat.STRING,
    },
    customFormat: {
      type: Function,
      required: false,
      default: (value: unknown) => String(value),
    },
  },
  data() {
    return {
      flowActor: FlowActor,
    };
  },
  methods: {
    formatValue(val: never): string {
      switch (this.valueType) {
        case HistoryFormat.CUSTOM:
          return this.customFormat(val);
        case HistoryFormat.CURRENCY:
          return this.$curr(Number(val));
        case HistoryFormat.DATE:
          return this.$date(new Date(val));
        case HistoryFormat.DATETIME:
          return this.$dateTime(new Date(val));
        case HistoryFormat.LOGIC:
          return this.$logic(val);
        case HistoryFormat.STRING:
        default:
          return String(val);
      }
    },
  },
});
