
import { computed, defineComponent } from "vue";
import TabLink from "@/components/TabLink.vue";
import Headline from "@/components/Headline.vue";
import { dealApi } from "@/resources";
import BackLink from "@/components/BackLink.vue";
import { Deal } from "@/api";

export default defineComponent({
  name: "DealView",
  components: {
    Headline,
    TabLink,
    BackLink,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  provide() {
    return {
      deal: computed(() => this.deal),
    };
  },
  data() {
    return {
      deal: {} as Deal,
    };
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchDeal() {
      this.deal = await dealApi.deal({ id: Number(this.id) });
    },
    async fetchData() {
      await Promise.all([this.fetchDeal()]);
    },
  },
});
