
import { defineComponent } from "vue";
import TheFooter from "@/components/TheFooter.vue";
import TheSidebar from "@/components/TheSidebar.vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "App",
  components: { TheSidebar, TheFooter },
  computed: {
    ...mapGetters("account", ["isAuthenticated"]),
    showSideBar() {
      return !this.$route.fullPath.includes("/successSign");
    },
  },
});
