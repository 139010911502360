
import { computed, defineComponent } from "vue";
import TabLink from "@/components/TabLink.vue";
import Headline from "@/components/Headline.vue";
import { settingsApi } from "@/resources";
import BackLink from "@/components/BackLink.vue";
import { SettingsHistoryItem, SettingsItem } from "@/api";
import { mapState } from "vuex";
import { authorityMixin } from "@/util";

export default defineComponent({
  name: "SettingsView",
  mixins: [authorityMixin],
  components: {
    Headline,
    TabLink,
    BackLink,
  },
  provide() {
    return {
      settings: computed(() => this.settings),
      settingsHistory: computed(() => this.settingsHistory),
    };
  },
  data() {
    return {
      settings: {} as SettingsItem,
      settingsHistory: {} as SettingsHistoryItem,
    };
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchSettings() {
      this.settings = await settingsApi.settings();
    },
    async fetchSettingsHistory() {
      this.settingsHistory = await settingsApi.settingsHistory();
    },
    async fetchData() {
      await Promise.all([this.fetchSettings(), this.fetchSettingsHistory()]);
    },
  },
  computed: {
    feeOpened(): boolean {
      return this.$route.name
        ? this.$route.name.toString().startsWith("settingsFee")
        : false;
    },
    investorLimitOpened(): boolean {
      return this.$route.name
        ? this.$route.name.toString().startsWith("settingsInvestorLimit")
        : false;
    },
    ...mapState("feature", ["qaEnabled"]),
  },
});
