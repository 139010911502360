/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum InvestorSubCategory {
    GOVERNMENT = 'GOVERNMENT',
    CMA_LICENSED_ENTITIES = 'CMA_LICENSED_ENTITIES',
    CBO_LICENSED_ENTITIES = 'CBO_LICENSED_ENTITIES',
    PENSION_FUNDS = 'PENSION_FUNDS',
    LEVEL_1 = 'LEVEL_1',
    LEVEL_2 = 'LEVEL_2',
    LEVEL_3 = 'LEVEL_3'
}

export function InvestorSubCategoryFromJSON(json: any): InvestorSubCategory {
    return InvestorSubCategoryFromJSONTyped(json, false);
}

export function InvestorSubCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestorSubCategory {
    return json as InvestorSubCategory;
}

export function InvestorSubCategoryToJSON(value?: InvestorSubCategory | null): any {
    return value as any;
}

