<template>
  <el-dropdown-item @click="dialogVisible = true">
    {{ header }}
  </el-dropdown-item>

  <teleport to="body">
    <el-dialog :title="text" v-model="dialogVisible" :before-close="cancel">
      <el-form :model="form" ref="form" :rules="rules" @validate="onValidate">
        <el-form-item :label="$t('transactions.id')" prop="id">
          <el-input v-model="form.id"></el-input>
        </el-form-item>
        <el-form-item :label="$t('transactions.externalId')" prop="externalId">
          <el-input v-model="form.externalId"></el-input>
        </el-form-item>
        <el-form-item :label="$t('status')" prop="status">
          <el-select v-model="form.status" class="w-100">
            <el-option
              v-for="(status, key) in statuses"
              :key="key"
              :label="$enums.transaction.status(status)"
              :value="status"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancel">{{ $t("no") }}</el-button>
          <el-button type="primary" :disabled="!isFormValid" @click="submit">{{
            $t("yes")
          }}</el-button>
        </span>
      </template>
    </el-dialog>
  </teleport>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { isConstraintViolation, isProblem } from "@/resources/problem";
import { BackOfficeCompleteTransactionRequest, TransactionStatus } from "@/api";
import { ElementForm, InputLength, InputRequired } from "@/util/validation";

export default defineComponent({
  name: "CompleteTransactionDialog",
  emits: ["update"],
  props: {
    header: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    infoMessage: {
      type: String,
      required: false,
    },
    onSubmit: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      dialogVisible: false,
      isFormValid: true,
      statuses: [
        TransactionStatus.FAILED,
        TransactionStatus.SUBMITTED,
      ] as TransactionStatus[],
      form: {} as BackOfficeCompleteTransactionRequest,
      rules: {
        id: [new InputRequired("validation.inputRequired")],
        externalId: [new InputLength(255)],
        status: [new InputRequired()],
      },
    };
  },
  methods: {
    cancel() {
      this.dialogVisible = false;
      this.form = {} as BackOfficeCompleteTransactionRequest;
    },
    onValidate(prop: string, isVal: boolean) {
      this.isFormValid = isVal;
    },
    async submit() {
      console.log(this);
      const form = this.$refs["form"] as ElementForm;
      if (await form.validate()) {
        try {
          await this.onSubmit(this.form);
          if (this.infoMessage) {
            this.$notify.info(this.infoMessage);
          }
        } catch (e) {
          if (isConstraintViolation(e)) {
            e.violations.forEach((violation) =>
              this.$notify.error(this.$t(violation.message))
            );
          } else if (isProblem(e)) {
            this.$notify.error(this.$t(e.detail));
          }
        }
        this.$emit("update");
        this.cancel();
      }
    },
  },
});
</script>
