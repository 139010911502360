/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IdEntry,
    IdEntryFromJSON,
    IdEntryToJSON,
    PageBackOfficeBatchListItem,
    PageBackOfficeBatchListItemFromJSON,
    PageBackOfficeBatchListItemToJSON,
} from '../models';

export interface BatchFileRequest {
    id: number;
}

export interface BatchesRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

/**
 * 
 */
export class BackOfficeBatchControllerApi extends runtime.BaseAPI {

    /**
     */
    async batchFileRaw(requestParameters: BatchFileRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling batchFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/transaction/batch/{id}/file`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async batchFile(requestParameters: BatchFileRequest): Promise<Blob> {
        const response = await this.batchFileRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async batchesRaw(requestParameters: BatchesRequest): Promise<runtime.ApiResponse<PageBackOfficeBatchListItem>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/transaction/batch`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageBackOfficeBatchListItemFromJSON(jsonValue));
    }

    /**
     */
    async batches(requestParameters: BatchesRequest): Promise<PageBackOfficeBatchListItem> {
        const response = await this.batchesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async generateBatchB2BRaw(): Promise<runtime.ApiResponse<IdEntry>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/transaction/batch/b2b`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IdEntryFromJSON(jsonValue));
    }

    /**
     */
    async generateBatchB2B(): Promise<IdEntry> {
        const response = await this.generateBatchB2BRaw();
        return await response.value();
    }

}
