/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AccountDetailsSettingsData,
    AccountDetailsSettingsDataFromJSON,
    AccountDetailsSettingsDataToJSON,
    AccountDetailsSettingsHistoryItem,
    AccountDetailsSettingsHistoryItemFromJSON,
    AccountDetailsSettingsHistoryItemToJSON,
    AccountDetailsSettingsItem,
    AccountDetailsSettingsItemFromJSON,
    AccountDetailsSettingsItemToJSON,
    CommonFeeSettingsData,
    CommonFeeSettingsDataFromJSON,
    CommonFeeSettingsDataToJSON,
    ExchangeRateSettingsData,
    ExchangeRateSettingsDataFromJSON,
    ExchangeRateSettingsDataToJSON,
    ExchangeRateSettingsHistoryItem,
    ExchangeRateSettingsHistoryItemFromJSON,
    ExchangeRateSettingsHistoryItemToJSON,
    ExchangeRateSettingsItem,
    ExchangeRateSettingsItemFromJSON,
    ExchangeRateSettingsItemToJSON,
    InvestorFeeSettingsData,
    InvestorFeeSettingsDataFromJSON,
    InvestorFeeSettingsDataToJSON,
    InvestorIndividualLimitSettingsData,
    InvestorIndividualLimitSettingsDataFromJSON,
    InvestorIndividualLimitSettingsDataToJSON,
    InvestorLegalLimitSettingsData,
    InvestorLegalLimitSettingsDataFromJSON,
    InvestorLegalLimitSettingsDataToJSON,
    SettingsHistoryItem,
    SettingsHistoryItemFromJSON,
    SettingsHistoryItemToJSON,
    SettingsItem,
    SettingsItemFromJSON,
    SettingsItemToJSON,
} from '../models';

export interface CommonFeeSettingsRequest {
    commonFeeSettingsData: CommonFeeSettingsData;
}

export interface IndividualInvestorLimitSettingsRequest {
    investorIndividualLimitSettingsData: InvestorIndividualLimitSettingsData;
}

export interface InvestorFeeSettingsRequest {
    investorFeeSettingsData: InvestorFeeSettingsData;
}

export interface LegalInvestorLimitSettingsRequest {
    investorLegalLimitSettingsData: InvestorLegalLimitSettingsData;
}

export interface UpdateAccountDetailsRequest {
    accountDetailsSettingsData: AccountDetailsSettingsData;
}

export interface UpdateExchangeRatesRequest {
    exchangeRateSettingsData: ExchangeRateSettingsData;
}

/**
 * 
 */
export class BackOfficeSettingsControllerApi extends runtime.BaseAPI {

    /**
     */
    async accountDetailsRaw(): Promise<runtime.ApiResponse<AccountDetailsSettingsItem>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/settings/account-details`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountDetailsSettingsItemFromJSON(jsonValue));
    }

    /**
     */
    async accountDetails(): Promise<AccountDetailsSettingsItem> {
        const response = await this.accountDetailsRaw();
        return await response.value();
    }

    /**
     */
    async accountDetailsSettingsHistoryRaw(): Promise<runtime.ApiResponse<AccountDetailsSettingsHistoryItem>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/settings/account-details/history`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountDetailsSettingsHistoryItemFromJSON(jsonValue));
    }

    /**
     */
    async accountDetailsSettingsHistory(): Promise<AccountDetailsSettingsHistoryItem> {
        const response = await this.accountDetailsSettingsHistoryRaw();
        return await response.value();
    }

    /**
     */
    async commonFeeSettingsRaw(requestParameters: CommonFeeSettingsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.commonFeeSettingsData === null || requestParameters.commonFeeSettingsData === undefined) {
            throw new runtime.RequiredError('commonFeeSettingsData','Required parameter requestParameters.commonFeeSettingsData was null or undefined when calling commonFeeSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/settings/common/fee`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CommonFeeSettingsDataToJSON(requestParameters.commonFeeSettingsData),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async commonFeeSettings(requestParameters: CommonFeeSettingsRequest): Promise<void> {
        await this.commonFeeSettingsRaw(requestParameters);
    }

    /**
     */
    async exchangeRatesRaw(): Promise<runtime.ApiResponse<ExchangeRateSettingsItem>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/settings/exchange-rates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExchangeRateSettingsItemFromJSON(jsonValue));
    }

    /**
     */
    async exchangeRates(): Promise<ExchangeRateSettingsItem> {
        const response = await this.exchangeRatesRaw();
        return await response.value();
    }

    /**
     */
    async exchangeRatesHistoryRaw(): Promise<runtime.ApiResponse<ExchangeRateSettingsHistoryItem>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/settings/exchange-rates/history`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExchangeRateSettingsHistoryItemFromJSON(jsonValue));
    }

    /**
     */
    async exchangeRatesHistory(): Promise<ExchangeRateSettingsHistoryItem> {
        const response = await this.exchangeRatesHistoryRaw();
        return await response.value();
    }

    /**
     */
    async individualInvestorLimitSettingsRaw(requestParameters: IndividualInvestorLimitSettingsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.investorIndividualLimitSettingsData === null || requestParameters.investorIndividualLimitSettingsData === undefined) {
            throw new runtime.RequiredError('investorIndividualLimitSettingsData','Required parameter requestParameters.investorIndividualLimitSettingsData was null or undefined when calling individualInvestorLimitSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/settings/investor/individual/limit`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InvestorIndividualLimitSettingsDataToJSON(requestParameters.investorIndividualLimitSettingsData),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async individualInvestorLimitSettings(requestParameters: IndividualInvestorLimitSettingsRequest): Promise<void> {
        await this.individualInvestorLimitSettingsRaw(requestParameters);
    }

    /**
     */
    async investorFeeSettingsRaw(requestParameters: InvestorFeeSettingsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.investorFeeSettingsData === null || requestParameters.investorFeeSettingsData === undefined) {
            throw new runtime.RequiredError('investorFeeSettingsData','Required parameter requestParameters.investorFeeSettingsData was null or undefined when calling investorFeeSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/settings/investor/fee`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InvestorFeeSettingsDataToJSON(requestParameters.investorFeeSettingsData),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async investorFeeSettings(requestParameters: InvestorFeeSettingsRequest): Promise<void> {
        await this.investorFeeSettingsRaw(requestParameters);
    }

    /**
     */
    async legalInvestorLimitSettingsRaw(requestParameters: LegalInvestorLimitSettingsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.investorLegalLimitSettingsData === null || requestParameters.investorLegalLimitSettingsData === undefined) {
            throw new runtime.RequiredError('investorLegalLimitSettingsData','Required parameter requestParameters.investorLegalLimitSettingsData was null or undefined when calling legalInvestorLimitSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/settings/investor/legal/limit`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InvestorLegalLimitSettingsDataToJSON(requestParameters.investorLegalLimitSettingsData),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async legalInvestorLimitSettings(requestParameters: LegalInvestorLimitSettingsRequest): Promise<void> {
        await this.legalInvestorLimitSettingsRaw(requestParameters);
    }

    /**
     */
    async settingsRaw(): Promise<runtime.ApiResponse<SettingsItem>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingsItemFromJSON(jsonValue));
    }

    /**
     */
    async settings(): Promise<SettingsItem> {
        const response = await this.settingsRaw();
        return await response.value();
    }

    /**
     */
    async settingsHistoryRaw(): Promise<runtime.ApiResponse<SettingsHistoryItem>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/settings/history`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingsHistoryItemFromJSON(jsonValue));
    }

    /**
     */
    async settingsHistory(): Promise<SettingsHistoryItem> {
        const response = await this.settingsHistoryRaw();
        return await response.value();
    }

    /**
     */
    async updateAccountDetailsRaw(requestParameters: UpdateAccountDetailsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.accountDetailsSettingsData === null || requestParameters.accountDetailsSettingsData === undefined) {
            throw new runtime.RequiredError('accountDetailsSettingsData','Required parameter requestParameters.accountDetailsSettingsData was null or undefined when calling updateAccountDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/settings/account-details`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AccountDetailsSettingsDataToJSON(requestParameters.accountDetailsSettingsData),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateAccountDetails(requestParameters: UpdateAccountDetailsRequest): Promise<void> {
        await this.updateAccountDetailsRaw(requestParameters);
    }

    /**
     */
    async updateExchangeRatesRaw(requestParameters: UpdateExchangeRatesRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.exchangeRateSettingsData === null || requestParameters.exchangeRateSettingsData === undefined) {
            throw new runtime.RequiredError('exchangeRateSettingsData','Required parameter requestParameters.exchangeRateSettingsData was null or undefined when calling updateExchangeRates.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/settings/exchange-rate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExchangeRateSettingsDataToJSON(requestParameters.exchangeRateSettingsData),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateExchangeRates(requestParameters: UpdateExchangeRatesRequest): Promise<void> {
        await this.updateExchangeRatesRaw(requestParameters);
    }

}
