<template>
  <div class="card">
    <div class="card-main">
      <BackLink />
      <Headline v-if="user && user.givenName">
        {{ user.givenName }} {{ user.lastName }}
        <template v-slot:details>
          <Status
            :color="$enums.portalUser.statusColor(user.status)"
            size="medium"
          >
            {{ $enums.portalUser.status(user.status) }}
          </Status>
        </template>
        <template #actions v-if="hasAuthority(authorities.USER_WRITE)">
          <el-dropdown trigger="click" v-if="user.status !== 'INACTIVE'">
            <el-button type="default">
              {{ $t("actions")
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <ActivatePortalUserDialog
                  :id="id"
                  :given-name="user.givenName"
                  :last-name="user.lastName"
                  v-if="['SUSPENDED', 'LOCKED'].includes(user.status)"
                  @update="fetchUser"
                />
                <SuspendPortalUserDialog
                  :id="id"
                  :given-name="user.givenName"
                  :last-name="user.lastName"
                  v-if="user.status === 'ACTIVE'"
                  @update="fetchUser"
                />
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </Headline>
    </div>
  </div>

  <div class="tab-content">
    <div class="tab-pane active">
      <router-view @update="fetchUser"></router-view>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { PortalUser } from "@/api";
import Headline from "@/components/Headline.vue";
import Status from "@/components/Status.vue";
import BackLink from "@/components/BackLink.vue";
import ActivatePortalUserDialog from "@/views/user/portal/dialog/ActivatePortalUserDialog.vue";
import { authorityMixin } from "@/util";
import { portalUserApi } from "@/resources";
import SuspendPortalUserDialog from "@/views/user/portal/dialog/SuspendPortalUserDialog.vue";

export default defineComponent({
  name: "PortalUserView",
  mixins: [authorityMixin],
  components: {
    SuspendPortalUserDialog,
    ActivatePortalUserDialog,
    Headline,
    Status,
    BackLink,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  provide() {
    return {
      user: computed(() => this.user),
    };
  },
  data() {
    return {
      user: {} as PortalUser,
    };
  },
  async mounted() {
    await this.fetchUser();
  },
  methods: {
    async fetchUser() {
      this.user = await portalUserApi.portalUser({ id: Number(this.id) });
    },
  },
});
</script>
