/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExchangeRateSettingsData
 */
export interface ExchangeRateSettingsData {
    /**
     * 
     * @type {number}
     * @memberof ExchangeRateSettingsData
     */
    usdToAed: number;
    /**
     * 
     * @type {number}
     * @memberof ExchangeRateSettingsData
     */
    usdToBhd: number;
    /**
     * 
     * @type {number}
     * @memberof ExchangeRateSettingsData
     */
    usdToKwd: number;
    /**
     * 
     * @type {number}
     * @memberof ExchangeRateSettingsData
     */
    usdToOmr: number;
    /**
     * 
     * @type {number}
     * @memberof ExchangeRateSettingsData
     */
    usdToQar: number;
    /**
     * 
     * @type {number}
     * @memberof ExchangeRateSettingsData
     */
    usdToSar: number;
}

export function ExchangeRateSettingsDataFromJSON(json: any): ExchangeRateSettingsData {
    return ExchangeRateSettingsDataFromJSONTyped(json, false);
}

export function ExchangeRateSettingsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExchangeRateSettingsData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'usdToAed': json['usdToAed'],
        'usdToBhd': json['usdToBhd'],
        'usdToKwd': json['usdToKwd'],
        'usdToOmr': json['usdToOmr'],
        'usdToQar': json['usdToQar'],
        'usdToSar': json['usdToSar'],
    };
}

export function ExchangeRateSettingsDataToJSON(value?: ExchangeRateSettingsData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'usdToAed': value.usdToAed,
        'usdToBhd': value.usdToBhd,
        'usdToKwd': value.usdToKwd,
        'usdToOmr': value.usdToOmr,
        'usdToQar': value.usdToQar,
        'usdToSar': value.usdToSar,
    };
}


