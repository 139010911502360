/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CampaignStatus,
    CampaignStatusFromJSON,
    CampaignStatusFromJSONTyped,
    CampaignStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface BackOfficeFlowEntityStateCampaignStatus
 */
export interface BackOfficeFlowEntityStateCampaignStatus {
    /**
     * 
     * @type {Array<CampaignStatus>}
     * @memberof BackOfficeFlowEntityStateCampaignStatus
     */
    nextStatuses: Array<CampaignStatus>;
    /**
     * 
     * @type {CampaignStatus}
     * @memberof BackOfficeFlowEntityStateCampaignStatus
     */
    previousStatus?: CampaignStatus;
}

export function BackOfficeFlowEntityStateCampaignStatusFromJSON(json: any): BackOfficeFlowEntityStateCampaignStatus {
    return BackOfficeFlowEntityStateCampaignStatusFromJSONTyped(json, false);
}

export function BackOfficeFlowEntityStateCampaignStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): BackOfficeFlowEntityStateCampaignStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nextStatuses': ((json['nextStatuses'] as Array<any>).map(CampaignStatusFromJSON)),
        'previousStatus': !exists(json, 'previousStatus') ? undefined : CampaignStatusFromJSON(json['previousStatus']),
    };
}

export function BackOfficeFlowEntityStateCampaignStatusToJSON(value?: BackOfficeFlowEntityStateCampaignStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nextStatuses': ((value.nextStatuses as Array<any>).map(CampaignStatusToJSON)),
        'previousStatus': CampaignStatusToJSON(value.previousStatus),
    };
}


