<template>
  <div class="container ms-0 mb-0" v-if="investorId">
    <div class="row ms-0 mb-3">
      <h4>{{ $t("legal.ownership.owners.title") }}</h4>
      <LegalOwners
        :page="ownersPage"
        :pageRequest="ownersPageRequest"
        :fetchOwnersData="fetchOwners"
      ></LegalOwners>
    </div>
    <div class="row ms-0 mb-3">
      <h4>{{ $t("legal.ownership.members.title") }}</h4>
      <LegalMembers
        :page="membersPage"
        :pageRequest="membersPageRequest"
        :fetchMembersData="fetchMembers"
      ></LegalMembers>
    </div>
    <div class="row ms-0 mb-3">
      <h4>{{ $t("legal.ownership.persons.title") }}</h4>
      <LegalPersons
        :page="personsPage"
        :pageRequest="personsPageRequest"
        :fetchPersonsData="fetchPersons"
      ></LegalPersons>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import {
  PageLegalMemberItem,
  PageLegalOwnerItem,
  PageLegalPersonItem,
} from "@/api";
import { investorApi } from "@/resources";
import LegalMembers from "@/views/components/LegalMembers.vue";
import LegalOwners from "@/views/components/LegalOwners.vue";
import LegalPersons from "@/views/components/LegalPersons.vue";
import { PageRequest } from "@/util";

export default defineComponent({
  name: "InvestorOwnershipInfo",
  components: { LegalMembers, LegalOwners, LegalPersons },
  data() {
    return {
      investorId: inject("investorId") as number,
      ownersPage: {} as PageLegalOwnerItem,
      ownersPageRequest: {} as PageRequest,
      membersPage: {} as PageLegalMemberItem,
      membersPageRequest: {} as PageRequest,
      personsPage: {} as PageLegalPersonItem,
      personsPageRequest: {} as PageRequest,
    };
  },
  methods: {
    async fetchPersons() {
      this.personsPage = await investorApi.investorPersons({
        id: this.investorId,
        ...this.personsPageRequest,
      });
    },
    async fetchOwners() {
      this.ownersPage = await investorApi.investorOwners({
        id: this.investorId,
        ...this.ownersPageRequest,
      });
    },
    async fetchMembers() {
      this.membersPage = await investorApi.investorMembers({
        id: this.investorId,
        ...this.membersPageRequest,
      });
    },
  },
});
</script>
