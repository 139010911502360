
import { defineComponent } from "vue";
import { ElementForm, emailFormat, InputRequired } from "@/util/validation";
import { authApi } from "@/resources";
import { isProblem } from "@/resources/problem";
import { ForgotPasswordForm } from "@/api";
import { fixLocalDate, i18n } from "@/util";

export default defineComponent({
  data() {
    return {
      forgotPasswordForm: {} as ForgotPasswordForm,
      linkSent: false,
      errorMessage: "" as string,
      rules: {
        login: [new InputRequired(), emailFormat],
        birthDate: [new InputRequired()],
      },
    };
  },
  methods: {
    onSubmit() {
      const form = this.$refs["form"] as ElementForm;
      form.validate((valid) => {
        if (!valid) {
          return false;
        }
        this.restorePassword();
        return true;
      });
    },
    async restorePassword() {
      try {
        this.errorMessage = "";
        this.forgotPasswordForm.birthDate = fixLocalDate(
          this.forgotPasswordForm.birthDate
        );
        await authApi.passwordRecoveryRequest({
          forgotPasswordForm: this.forgotPasswordForm,
        });
        this.linkSent = true;
      } catch (e) {
        if (isProblem(e)) {
          this.errorMessage = i18n.global.t(e.detail);
        }
      }
    },
  },
});
