/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateUserForm,
    CreateUserFormFromJSON,
    CreateUserFormToJSON,
    PageUserListItem,
    PageUserListItemFromJSON,
    PageUserListItemToJSON,
    UpdateUserForm,
    UpdateUserFormFromJSON,
    UpdateUserFormToJSON,
    User,
    UserFromJSON,
    UserToJSON,
    UserHistory,
    UserHistoryFromJSON,
    UserHistoryToJSON,
} from '../models';

export interface ActivateUserRequest {
    id: number;
}

export interface CreateUserRequest {
    createUserForm: CreateUserForm;
}

export interface DeactivateUserRequest {
    id: number;
}

export interface GetUserHistoryRequest {
    id: number;
}

export interface ResendRegistrationLinkRequest {
    id: number;
}

export interface UpdateUserRequest {
    id: number;
    updateUserForm: UpdateUserForm;
}

export interface UserRequest {
    id: number;
}

export interface UsersRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
    fullname?: string;
    roles?: Array<number>;
    statuses?: Array<string>;
    residenceCountries?: Array<string>;
}

/**
 * 
 */
export class UserControllerApi extends runtime.BaseAPI {

    /**
     */
    async activateUserRaw(requestParameters: ActivateUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling activateUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/{id}/activate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async activateUser(requestParameters: ActivateUserRequest): Promise<void> {
        await this.activateUserRaw(requestParameters);
    }

    /**
     */
    async createUserRaw(requestParameters: CreateUserRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.createUserForm === null || requestParameters.createUserForm === undefined) {
            throw new runtime.RequiredError('createUserForm','Required parameter requestParameters.createUserForm was null or undefined when calling createUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUserFormToJSON(requestParameters.createUserForm),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async createUser(requestParameters: CreateUserRequest): Promise<number> {
        const response = await this.createUserRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deactivateUserRaw(requestParameters: DeactivateUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deactivateUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/{id}/deactivate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deactivateUser(requestParameters: DeactivateUserRequest): Promise<void> {
        await this.deactivateUserRaw(requestParameters);
    }

    /**
     */
    async getUserHistoryRaw(requestParameters: GetUserHistoryRequest): Promise<runtime.ApiResponse<Array<UserHistory>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUserHistory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/{id}/history`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserHistoryFromJSON));
    }

    /**
     */
    async getUserHistory(requestParameters: GetUserHistoryRequest): Promise<Array<UserHistory>> {
        const response = await this.getUserHistoryRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async resendRegistrationLinkRaw(requestParameters: ResendRegistrationLinkRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling resendRegistrationLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/{id}/registration/link`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async resendRegistrationLink(requestParameters: ResendRegistrationLinkRequest): Promise<void> {
        await this.resendRegistrationLinkRaw(requestParameters);
    }

    /**
     */
    async updateUserRaw(requestParameters: UpdateUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateUser.');
        }

        if (requestParameters.updateUserForm === null || requestParameters.updateUserForm === undefined) {
            throw new runtime.RequiredError('updateUserForm','Required parameter requestParameters.updateUserForm was null or undefined when calling updateUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserFormToJSON(requestParameters.updateUserForm),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateUser(requestParameters: UpdateUserRequest): Promise<void> {
        await this.updateUserRaw(requestParameters);
    }

    /**
     */
    async userRaw(requestParameters: UserRequest): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling user.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    async user(requestParameters: UserRequest): Promise<User> {
        const response = await this.userRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async usersRaw(requestParameters: UsersRequest): Promise<runtime.ApiResponse<PageUserListItem>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.fullname !== undefined) {
            queryParameters['fullname'] = requestParameters.fullname;
        }

        if (requestParameters.roles) {
            queryParameters['roles'] = requestParameters.roles;
        }

        if (requestParameters.statuses) {
            queryParameters['statuses'] = requestParameters.statuses;
        }

        if (requestParameters.residenceCountries) {
            queryParameters['residenceCountries'] = requestParameters.residenceCountries;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageUserListItemFromJSON(jsonValue));
    }

    /**
     */
    async users(requestParameters: UsersRequest): Promise<PageUserListItem> {
        const response = await this.usersRaw(requestParameters);
        return await response.value();
    }

}
