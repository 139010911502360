/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CurrentAddress,
    CurrentAddressFromJSON,
    CurrentAddressFromJSONTyped,
    CurrentAddressToJSON,
    PermanentAddress,
    PermanentAddressFromJSON,
    PermanentAddressFromJSONTyped,
    PermanentAddressToJSON,
} from './';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {CurrentAddress}
     * @memberof Address
     */
    currentAddress: CurrentAddress;
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    id?: number;
    /**
     * 
     * @type {PermanentAddress}
     * @memberof Address
     */
    permanentAddress: PermanentAddress;
}

export function AddressFromJSON(json: any): Address {
    return AddressFromJSONTyped(json, false);
}

export function AddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): Address {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currentAddress': CurrentAddressFromJSON(json['currentAddress']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'permanentAddress': PermanentAddressFromJSON(json['permanentAddress']),
    };
}

export function AddressToJSON(value?: Address | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currentAddress': CurrentAddressToJSON(value.currentAddress),
        'id': value.id,
        'permanentAddress': PermanentAddressToJSON(value.permanentAddress),
    };
}


