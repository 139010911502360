<template>
  <el-table
    :data="page.content"
    @sort-change="onSortChange($event, pageRequest)"
  >
    <el-table-column :label="$t('id')" prop="id" sortable="custom" width="85">
    </el-table-column>
    <el-table-column
      :label="$t('bankAccount.number')"
      prop="number"
      :show-overflow-tooltip="true"
    ></el-table-column>
    <el-table-column :label="$t('bankAccount.openedAt')">
      <template #default="scope">
        {{ $date(scope.row.openedAt) }}
      </template>
    </el-table-column>
    <el-table-column :label="$t('bankAccount.status')">
      <template #default="scope">
        {{ $enums.bank.accountStatus(scope.row.status) }}
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('bankAccount.bankName')"
      prop="bankName"
      :show-overflow-tooltip="true"
    ></el-table-column>
    <el-table-column
      :label="$t('bankAccount.bankAddress')"
      prop="bankAddress"
      :show-overflow-tooltip="true"
    ></el-table-column>
    <el-table-column
      :label="$t('bankAccount.bankBic')"
      prop="bankBic"
      :show-overflow-tooltip="true"
    ></el-table-column>
    <el-table-column :label="$t('bankAccount.master')">
      <template #default="scope">
        {{ $logic(scope.row.master) }}
      </template>
    </el-table-column>
    <el-table-column :label="$t('bankAccount.dividend')">
      <template #default="scope">
        {{ $logic(scope.row.dividend) }}
      </template>
    </el-table-column>
    <el-table-column :label="$t('bankAccount.currency')">
      <template #default="scope">
        {{ $enums.globals.currency(scope.row.currency) }}
      </template>
    </el-table-column>
  </el-table>
  <TableFooter
    @size-change="onSizeChange($event, pageRequest)"
    @current-change="onCurrentChange($event, pageRequest)"
    :page="page"
    :page-sizes="[5, 10, 30, 40]"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { PageBankAccountListItem } from "@/api";
import { authorityMixin, PageRequest, pageSortMixin } from "@/util";
import TableFooter from "@/components/TableFooter.vue";

export default defineComponent({
  name: "BankAccounts",
  mixins: [pageSortMixin, authorityMixin],
  components: { TableFooter },
  props: {
    page: {
      type: Object as PropType<PageBankAccountListItem>,
      required: true,
    },
    pageRequest: {
      type: Object as PropType<PageRequest>,
      required: true,
    },
    fetchBankAccountsData: {
      type: Function,
      required: true,
    },
  },
  mounted() {
    this.fetchBankAccountsData();
  },
  methods: {
    fetchData() {
      this.fetchBankAccountsData();
    },
  },
});
</script>
