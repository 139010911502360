/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum Education {
    UNIVERSITY = 'UNIVERSITY',
    SECONDARY = 'SECONDARY',
    MIDDLE_SCHOOL = 'MIDDLE_SCHOOL',
    ELEMENTARY = 'ELEMENTARY',
    OTHER = 'OTHER'
}

export function EducationFromJSON(json: any): Education {
    return EducationFromJSONTyped(json, false);
}

export function EducationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Education {
    return json as Education;
}

export function EducationToJSON(value?: Education | null): any {
    return value as any;
}

