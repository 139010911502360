<template>
  <Headline>{{ $t("role.new") }}</Headline>

  <div class="container ms-0 mb-3">
    <el-form :model="role" :rules="rules" status-icon ref="roleForm">
      <div class="row justify-content-between">
        <div class="col-md-4">
          <el-form-item :label="$t('role.roleName')" prop="name">
            <el-input v-model="role.name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('role.roleDescription')" prop="description">
            <el-input v-model="role.description"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('role.residenceCountry')"
            prop="residenceCountry"
          >
            <el-select v-model="role.residenceCountry" class="w-100" clearable>
              <el-option
                v-for="(country, key) in residenceCountryOptions"
                :key="key"
                :label="$enums.globals.country(country)"
                :value="country"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            class="mb-5"
            :label="$t('role.startPage')"
            prop="startPage"
          >
            <el-select
              v-model="role.startPage"
              :no-data-text="$t('role.startPageNoData')"
              class="w-100"
            >
              <el-option
                v-for="(page, i) in pages"
                :key="i"
                :value="page"
                :label="$enums.startPage.displayValue(page)"
              ></el-option>
            </el-select>
          </el-form-item>
          <div>
            <el-button @click="$router.push({ name: 'roles' })">
              {{ $t("cancel") }}
            </el-button>
            <el-button type="primary" @click="submit">
              {{ $t("save") }}
            </el-button>
          </div>
        </div>
        <div class="col-md-4">
          <span class="h5">{{ $t("role.campaignStatuses") }}</span>
          <el-checkbox
            class="check"
            @change="selectPermissions($event, AllCampaignStatusPermissions)"
            :model-value="isAllCampaignStatusChecked"
            :label="authorities.CAMPAIGN_ALL_STATUSES_VIEW"
            ><span>{{ $t("role.selectAllCampaignStatuses") }}</span>
          </el-checkbox>
          <el-checkbox-group
            v-if="role.authorities"
            v-model="role.authorities"
            class="check-group"
            @change="onPermissionsChange"
          >
            <div class="row mb-4 mt-3">
              <el-checkbox
                class="check mt-4"
                @change="checkCampaignPermission($event)"
                :label="authorities.CAMPAIGN_DRAFT_VIEW"
                ><span>{{ $t("role.CAMPAIGN_DRAFT_VIEW") }}</span>
              </el-checkbox>
              <el-checkbox
                class="check"
                @change="checkCampaignPermission($event)"
                :label="authorities.CAMPAIGN_WAITING_FOR_REGISTRATION_FEE_VIEW"
                ><span>{{
                  $t("role.CAMPAIGN_WAITING_FOR_REGISTRATION_FEE_VIEW")
                }}</span>
              </el-checkbox>
              <el-checkbox
                class="check"
                @change="checkCampaignPermission($event)"
                :label="authorities.CAMPAIGN_ACTIVE_VIEW"
                ><span>{{ $t("role.CAMPAIGN_ACTIVE_VIEW") }}</span>
              </el-checkbox>
              <el-checkbox
                class="check"
                @change="checkCampaignPermission($event)"
                :label="authorities.CAMPAIGN_CANCELLATION_REQUEST_VIEW"
                ><span>{{
                  $t("role.CAMPAIGN_CANCELLATION_REQUEST_VIEW")
                }}</span>
              </el-checkbox>
              <el-checkbox
                class="check"
                @change="checkCampaignPermission($event)"
                :label="authorities.CAMPAIGN_CANCELED_VIEW"
                ><span>{{ $t("role.CAMPAIGN_CANCELED_VIEW") }}</span>
              </el-checkbox>
              <el-checkbox
                class="check"
                @change="checkCampaignPermission($event)"
                :label="authorities.CAMPAIGN_BUSINESS_VERIFICATION_VIEW"
                ><span>{{
                  $t("role.CAMPAIGN_BUSINESS_VERIFICATION_VIEW")
                }}</span>
              </el-checkbox>
              <el-checkbox
                class="check"
                @change="checkCampaignPermission($event)"
                :label="authorities.CAMPAIGN_CAMPAIGN_VERIFICATION_VIEW"
                ><span>{{
                  $t("role.CAMPAIGN_CAMPAIGN_VERIFICATION_VIEW")
                }}</span>
              </el-checkbox>
              <el-checkbox
                class="check"
                @change="checkCampaignPermission($event)"
                :label="authorities.CAMPAIGN_CAMPAIGN_COLLATERAL_REVIEW_VIEW"
                ><span>{{
                  $t("role.CAMPAIGN_CAMPAIGN_COLLATERAL_REVIEW_VIEW")
                }}</span>
              </el-checkbox>
              <el-checkbox
                class="check"
                @change="checkCampaignPermission($event)"
                :label="authorities.CAMPAIGN_WAITING_FOR_DATA_VIEW"
                ><span>{{ $t("role.CAMPAIGN_WAITING_FOR_DATA_VIEW") }}</span>
              </el-checkbox>
              <el-checkbox
                class="check"
                @change="checkCampaignPermission($event)"
                :label="authorities.CAMPAIGN_DECLINED_VIEW"
                ><span>{{ $t("role.CAMPAIGN_DECLINED_VIEW") }}</span>
              </el-checkbox>
              <el-checkbox
                class="check"
                @change="checkCampaignPermission($event)"
                :label="authorities.CAMPAIGN_ABANDONED_VIEW"
                ><span>{{ $t("role.CAMPAIGN_ABANDONED_VIEW") }}</span>
              </el-checkbox>
              <el-checkbox
                class="check"
                @change="checkCampaignPermission($event)"
                :label="authorities.CAMPAIGN_APPROVED_VIEW"
                ><span>{{ $t("role.CAMPAIGN_APPROVED_VIEW") }}</span>
              </el-checkbox>
              <el-checkbox
                class="check"
                @change="checkCampaignPermission($event)"
                :label="authorities.CAMPAIGN_SIGNING_CAMPAIGN_AGREEMENT_VIEW"
                ><span>{{
                  $t("role.CAMPAIGN_SIGNING_CAMPAIGN_AGREEMENT_VIEW")
                }}</span>
              </el-checkbox>
              <el-checkbox
                class="check"
                @change="checkCampaignPermission($event)"
                :label="authorities.CAMPAIGN_FUNDRAISING_RESERVE_VIEW"
                ><span
                  >{{ $t("role.CAMPAIGN_FUNDRAISING_RESERVE_VIEW") }}
                </span>
              </el-checkbox>
              <el-checkbox
                class="check"
                @change="checkCampaignPermission($event)"
                :label="authorities.CAMPAIGN_FUNDRAISING_LIVE_VIEW"
                ><span>{{ $t("role.CAMPAIGN_FUNDRAISING_LIVE_VIEW") }} </span>
              </el-checkbox>
              <el-checkbox
                class="check"
                @change="checkCampaignPermission($event)"
                :label="authorities.CAMPAIGN_RECONCILIATION_VIEW"
                ><span>{{ $t("role.CAMPAIGN_RECONCILIATION_VIEW") }} </span>
              </el-checkbox>
              <el-checkbox
                class="check"
                @change="checkCampaignPermission($event)"
                :label="authorities.CAMPAIGN_FAIL_VIEW"
                ><span>{{ $t("role.CAMPAIGN_FAIL_VIEW") }} </span>
              </el-checkbox>
              <el-checkbox
                class="check"
                @change="checkCampaignPermission($event)"
                :label="authorities.CAMPAIGN_TEMPORARILY_SUSPENDED_VIEW"
                ><span>{{
                  $t("role.CAMPAIGN_TEMPORARILY_SUSPENDED_VIEW")
                }}</span>
              </el-checkbox>
              <el-checkbox
                class="check"
                @change="checkCampaignPermission($event)"
                :label="authorities.CAMPAIGN_UNSUCCESSFUL_VIEW"
                ><span>{{ $t("role.CAMPAIGN_UNSUCCESSFUL_VIEW") }}</span>
              </el-checkbox>
              <el-checkbox
                class="check"
                @change="checkCampaignPermission($event)"
                :label="authorities.CAMPAIGN_SUCCESSFUL_VIEW"
                ><span>{{ $t("role.CAMPAIGN_SUCCESSFUL_VIEW") }}</span>
              </el-checkbox>
              <el-checkbox
                class="check"
                @change="checkCampaignPermission($event)"
                :label="authorities.CAMPAIGN_READY_FOR_DISBURSEMENT_VIEW"
                ><span>{{
                  $t("role.CAMPAIGN_READY_FOR_DISBURSEMENT_VIEW")
                }}</span>
              </el-checkbox>
              <el-checkbox
                class="check"
                @change="checkCampaignPermission($event)"
                :label="authorities.CAMPAIGN_DISBURSEMENT_IN_PROGRESS_VIEW"
                ><span>{{
                  $t("role.CAMPAIGN_DISBURSEMENT_IN_PROGRESS_VIEW")
                }}</span>
              </el-checkbox>
              <el-checkbox
                class="check"
                @change="checkCampaignPermission($event)"
                :label="authorities.CAMPAIGN_DISBURSEMENT_ERROR_VIEW"
                ><span>{{ $t("role.CAMPAIGN_DISBURSEMENT_ERROR_VIEW") }}</span>
              </el-checkbox>
              <el-checkbox
                class="check"
                @change="checkCampaignPermission($event)"
                :label="authorities.CAMPAIGN_DISBURSEMENT_SUCCESS_VIEW"
                ><span>{{
                  $t("role.CAMPAIGN_DISBURSEMENT_SUCCESS_VIEW")
                }}</span>
              </el-checkbox>
              <el-checkbox
                class="check"
                @change="checkCampaignPermission($event)"
                :label="authorities.CAMPAIGN_SPV_SET_UP_VIEW"
                ><span>{{ $t("role.CAMPAIGN_SPV_SET_UP_VIEW") }}</span>
              </el-checkbox>
            </div>
          </el-checkbox-group>
        </div>
        <div class="col-md-3">
          <span class="h5">{{ $t("role.permissions") }}</span>
          <el-form-item prop="authorities" v-if="role.authorities">
            <el-checkbox
              @change="selectAllPermissions($event)"
              class="check"
              :model-value="isAllChecked"
              ><span> {{ $t("role.permissionsSelectAll") }}</span>
            </el-checkbox>
            <el-checkbox-group
              v-if="role.authorities"
              v-model="role.authorities"
              class="check-group"
              @change="onPermissionsChange"
            >
              <div class="row my-4">
                <div class="col-md-6">
                  <el-checkbox
                    @change="onChange($event, startPages.CAMPAIGNS)"
                    class="check mt-4"
                    :label="authorities.CAMPAIGN_READ"
                    ><span class="fw-bolder">{{ $t("role.campaigns") }}</span>
                  </el-checkbox>
                  <el-checkbox
                    class="check"
                    :label="authorities.CAMPAIGN_WRITE"
                    :disabled="
                      !role.authorities.includes(authorities.CAMPAIGN_READ)
                    "
                    ><span>{{ $t("role.edit") }}</span>
                  </el-checkbox>
                  <el-checkbox
                    class="check"
                    :label="authorities.CAMPAIGN_OPERATION_WRITE"
                    :disabled="
                      !role.authorities.includes(authorities.CAMPAIGN_READ)
                    "
                    ><span>{{ $t("role.campaignOperations") }}</span>
                  </el-checkbox>
                  <el-checkbox
                    class="check"
                    :label="authorities.CAMPAIGN_OPERATION_MANUAL_DISBURSEMENT"
                    :disabled="
                      !role.authorities.includes(authorities.CAMPAIGN_READ)
                    "
                    ><span>{{ $t("role.manualDisbursement") }}</span>
                  </el-checkbox>
                  <el-checkbox
                    class="check"
                    :label="authorities.CAMPAIGN_COLLATERAL_REVIEW"
                    :disabled="
                      !role.authorities.includes(authorities.CAMPAIGN_READ)
                    "
                    ><span>{{ $t("role.collateralReview") }}</span>
                  </el-checkbox>
                  <el-checkbox
                    class="check"
                    :label="authorities.CAMPAIGN_FUNDRAISER_VERIFICATION"
                    :disabled="
                      !role.authorities.includes(authorities.CAMPAIGN_READ)
                    "
                    ><span>{{ $t("role.fundraiserVerification") }}</span>
                  </el-checkbox>
                  <el-checkbox
                    class="check"
                    :label="authorities.CAMPAIGN_CAMPAIGN_VERIFICATION"
                    :disabled="
                      !role.authorities.includes(authorities.CAMPAIGN_READ)
                    "
                    ><span>{{ $t("role.campaignVerification") }}</span>
                  </el-checkbox>
                </div>
              </div>
              <div class="row my-4">
                <div class="col-md-6">
                  <el-checkbox
                    @change="onChange($event, startPages.ROLES)"
                    class="check mb-3"
                    :label="authorities.ROLE_READ"
                    ><span class="fw-bolder">{{ $t("role.roles") }}</span>
                  </el-checkbox>
                  <el-checkbox
                    class="check"
                    :label="authorities.ROLE_WRITE"
                    :disabled="
                      !role.authorities.includes(authorities.ROLE_READ)
                    "
                    ><span>{{ $t("role.editCreate") }}</span>
                  </el-checkbox>
                </div>
              </div>
              <div class="row my-4">
                <div class="col-md-6">
                  <el-checkbox
                    @change="onChange($event, startPages.USERS)"
                    class="check mb-3"
                    :label="authorities.USER_READ"
                    ><span class="fw-bolder">{{ $t("role.users") }}</span>
                  </el-checkbox>
                  <el-checkbox
                    class="check"
                    :label="authorities.USER_WRITE"
                    :disabled="
                      !role.authorities.includes(authorities.USER_READ)
                    "
                    ><span>{{ $t("role.editCreate") }}</span>
                  </el-checkbox>
                </div>
              </div>
            </el-checkbox-group>
            <el-checkbox-group
              v-if="role.authorities"
              v-model="role.authorities"
              class="check-group"
              @change="onPermissionsChange"
            >
              <div class="row my-4">
                <div class="col-md-6">
                  <el-checkbox
                    @change="onChange($event, startPages.INVESTORS)"
                    class="check mt-4"
                    :label="authorities.INVESTOR_READ"
                  >
                    <span class="fw-bolder">{{ $t("role.investors") }}</span>
                  </el-checkbox>
                </div>
              </div>
            </el-checkbox-group>
            <el-checkbox-group
              v-if="role.authorities"
              v-model="role.authorities"
              class="check-group"
              @change="onPermissionsChange"
            >
              <el-checkbox
                @change="onChange($event, startPages.BUSINESSES)"
                class="check mt-4"
                :label="authorities.BUSINESS_READ"
              >
                <span class="fw-bolder">{{ $t("role.businesses") }}</span>
              </el-checkbox>
            </el-checkbox-group>
            <el-checkbox-group
              v-if="role.authorities"
              v-model="role.authorities"
              class="check-group"
              @change="onPermissionsChange"
            >
              <el-checkbox
                @change="onChange($event, startPages.DEALS)"
                class="check mt-4"
                :label="authorities.DEAL_READ"
              >
                <span class="fw-bolder">{{ $t("role.deals") }}</span>
              </el-checkbox>
            </el-checkbox-group>
            <el-checkbox-group
              v-if="role.authorities"
              v-model="role.authorities"
              class="check-group"
              @change="onPermissionsChange"
            >
              <el-checkbox
                @change="onChange($event, startPages.TRANSACTIONS)"
                class="check mt-4"
                :label="authorities.TRANSACTION_READ"
              >
                <span class="fw-bolder">{{ $t("role.transactions") }}</span>
              </el-checkbox>
              <el-checkbox
                class="check"
                :label="authorities.TRANSACTION_WRITE"
                :disabled="
                  !role.authorities.includes(authorities.TRANSACTION_READ)
                "
                ><span>{{ $t("role.completeTransaction") }}</span>
              </el-checkbox>
              <el-checkbox
                class="check"
                :label="authorities.TRANSACTION_BATCH"
                :disabled="
                  !role.authorities.includes(authorities.TRANSACTION_READ)
                "
                ><span>{{ $t("role.batch") }}</span>
              </el-checkbox>
            </el-checkbox-group>
            <el-checkbox-group
              v-if="role.authorities"
              v-model="role.authorities"
              class="check-group"
              @change="onPermissionsChange"
            >
              <el-checkbox
                @change="onChange($event, startPages.INVESTMENTS)"
                class="check mt-4"
                :label="authorities.INVESTMENT_READ"
              >
                <span class="fw-bolder">{{ $t("role.investments") }}</span>
              </el-checkbox>
              <el-checkbox
                class="check"
                :label="authorities.INVESTMENT_WRITE"
                :disabled="
                  !role.authorities.includes(authorities.INVESTMENT_READ)
                "
                ><span>{{ $t("role.edit") }}</span>
              </el-checkbox>
              <el-checkbox
                class="check"
                :label="authorities.INVESTMENT_STATUS_CHANGE"
                :disabled="
                  !role.authorities.includes(authorities.INVESTMENT_READ)
                "
                ><span>{{ $t("role.investmentChangeStatus") }}</span>
              </el-checkbox>
              <el-checkbox
                class="check"
                :label="authorities.INVESTMENT_MANUAL_REFUND"
                :disabled="
                  !role.authorities.includes(authorities.INVESTMENT_READ)
                "
                ><span>{{ $t("role.investmentManualRefund") }}</span>
              </el-checkbox>
              <el-checkbox
                class="check"
                :label="authorities.INVESTMENT_INVESTOR_VERIFICATION"
                :disabled="
                  !role.authorities.includes(authorities.INVESTMENT_READ)
                "
                ><span>{{ $t("role.investmentInvestorVerification") }}</span>
              </el-checkbox>
            </el-checkbox-group>
            <el-checkbox-group
              v-if="role.authorities"
              v-model="role.authorities"
              class="check-group"
              @change="onPermissionsChange"
            >
              <el-checkbox
                @change="onChange($event, startPages.PRE_SCREENINGS)"
                class="check mt-4"
                :label="authorities.PRE_SCREENING_READ"
              >
                <span class="fw-bolder">{{ $t("role.preScreening") }}</span>
              </el-checkbox>
              <el-checkbox
                class="check"
                :label="authorities.PRE_SCREENING_WRITE"
                :disabled="
                  !role.authorities.includes(authorities.PRE_SCREENING_READ)
                "
                ><span>{{ $t("role.preScreeningEdit") }}</span>
              </el-checkbox>
              <el-checkbox
                class="check"
                :label="authorities.PRE_SCREENING_OPERATION_WRITE"
                :disabled="
                  !role.authorities.includes(authorities.PRE_SCREENING_READ)
                "
                ><span>{{ $t("role.preScreeningOperations") }}</span>
              </el-checkbox>
            </el-checkbox-group>
            <el-checkbox-group
              v-if="role.authorities"
              v-model="role.authorities"
              class="check-group"
              @change="onPermissionsChange"
            >
              <el-checkbox
                @change="onChange($event, startPages.ACCREDITATIONS)"
                class="check mt-4"
                :label="authorities.ACCREDITATION_READ"
              >
                <span class="fw-bolder">{{ $t("role.accreditation") }}</span>
              </el-checkbox>
              <el-checkbox
                class="check"
                :label="authorities.ACCREDITATION_OPERATION_WRITE"
                :disabled="
                  !role.authorities.includes(authorities.ACCREDITATION_READ)
                "
                ><span>{{ $t("role.accreditationOperations") }}</span>
              </el-checkbox>
            </el-checkbox-group>
            <el-checkbox-group
              v-if="role.authorities"
              v-model="role.authorities"
              class="check-group"
              @change="onPermissionsChange"
            >
              <el-checkbox
                @change="onChange($event, startPages.SETTINGS)"
                class="check mt-4"
                :label="authorities.SETTINGS_READ"
              >
                <span class="fw-bolder">{{ $t("role.settings") }}</span>
              </el-checkbox>
              <el-checkbox
                class="check"
                :label="authorities.SETTINGS_WRITE"
                :disabled="
                  !role.authorities.includes(authorities.SETTINGS_READ)
                "
                ><span>{{ $t("role.edit") }}</span>
              </el-checkbox>
              <el-checkbox
                class="check"
                :label="authorities.SETTINGS_ACCOUNT_DETAILS"
                :disabled="
                  !role.authorities.includes(authorities.SETTINGS_READ)
                "
                ><span>{{ $t("role.settingsAccountDetails") }}</span>
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { CreateRoleForm, Country, StartPage } from "@/api";
import { ElementForm, InputLength, InputRequired } from "@/util/validation";
import { roleApi } from "@/resources";
import { Authority } from "@/util";
import Headline from "@/components/Headline.vue";
import {
  AllAllowedPermissions,
  AllCampaignStatusPermissions,
  ReadWritePermissions,
  uncheckEditPermission,
} from "@/util/authority-groups";

export default defineComponent({
  name: "NewPole",
  components: { Headline },
  data() {
    return {
      role: {} as Omit<CreateRoleForm, "startPage"> &
        Partial<Pick<CreateRoleForm, "startPage">>,
      authorities: Authority,
      residenceCountryOptions: Country,
      startPages: StartPage,
      pages: new Set<StartPage>(),
      rules: {
        name: [new InputRequired(), new InputLength(100)],
        description: [new InputLength(100)],
        residenceCountry: [new InputRequired()],
        startPage: [new InputRequired("validation.selectRequired")],
        authorities: [new InputRequired("validation.permissionRequired")],
      },
      isAllChecked: false,
      isAllCampaignStatusChecked: false,
      AllCampaignStatusPermissions: AllCampaignStatusPermissions,
      allStartPages: Object.values(StartPage),
    };
  },
  mounted() {
    this.role.authorities = [];
  },
  methods: {
    submit() {
      const form = this.$refs["roleForm"] as ElementForm;
      form.validate(async (valid) => {
        if (valid) {
          await this.createRole();
        }
      });
    },
    async createRole() {
      try {
        const response = await roleApi.createRole({
          createRoleForm: this.role as CreateRoleForm,
        });
        this.$notify.info(this.$t("role.roleCreated"));
        await this.$router.push({
          name: "role",
          params: { id: response.id },
        });
      } catch (error) {
        this.$notify.error(this.$t(error.detail));
      }
    },
    checkCampaignPermission(isChecked: boolean) {
      if (isChecked) {
        this.setPermission(
          isChecked,
          this.startPages.CAMPAIGNS,
          this.authorities.CAMPAIGN_READ
        );
      }
    },
    setPermission(isChecked: boolean, page: StartPage, authority: Authority) {
      this.onChange(isChecked, page);
      this.role.authorities.push(authority);
    },
    uncheckEditPermissionIfNotAllowedReadPermission: function () {
      this.role.authorities = [...new Set(this.role.authorities)];
      for (let group of ReadWritePermissions) {
        this.role.authorities = uncheckEditPermission(
          this.role.authorities,
          group.read,
          group.write
        );
      }
    },
    onChange(isChecked: boolean, page: StartPage) {
      this.onPermissionsChange();
      if (isChecked) {
        this.pages.add(page);
      } else {
        this.pages.delete(page);
      }

      if (this.role.startPage && !this.pages.has(this.role.startPage)) {
        delete this.role.startPage;
      }
    },
    selectAllPermissions: function (isChecked: boolean) {
      if (isChecked) {
        for (const key in Object.values(AllAllowedPermissions)) {
          this.role.authorities.push(
            (Object.values(AllAllowedPermissions) as string[])[key]
          );
        }
        for (const page of this.allStartPages) {
          this.onChange(isChecked, page);
        }
        this.isAllChecked = true;
        this.isAllCampaignStatusChecked = true;
      } else {
        this.pages.clear();
        delete this.role.startPage;
        this.role.authorities = [];
        this.isAllChecked = false;
        this.isAllCampaignStatusChecked = false;
      }
    },
    selectPermissions: function (isChecked: boolean, permissions: Authority[]) {
      if (isChecked) {
        if (permissions.some((value) => value.startsWith("campaign:"))) {
          this.checkCampaignPermission(isChecked);
        }
        for (const key in permissions) {
          this.role.authorities.push(permissions[key]);
        }
      } else {
        this.role.authorities = this.role.authorities.filter(function (item) {
          return !(permissions as string[]).includes(item);
        });
      }
      this.onPermissionsChange();
    },
    onPermissionsChange: function () {
      //remove all duplicates in permissions
      this.role.authorities = [...new Set(this.role.authorities)];
      this.isAllChecked =
        this.role.authorities.length ==
        Object.values(AllAllowedPermissions).length;
      //campaigns statues management
      let campaignStatusesCount = this.role.authorities.filter(function (item) {
        return (AllCampaignStatusPermissions as string[]).includes(item);
      }).length;
      this.isAllCampaignStatusChecked =
        campaignStatusesCount == AllCampaignStatusPermissions.length;
      //uncheck edit permissions if uncheck read permissions for the same block
      this.uncheckEditPermissionIfNotAllowedReadPermission();
    },
  },
});
</script>

<style scoped>
.check {
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
}

.check-group span {
  font-size: large;
  font-weight: normal;
}
</style>
