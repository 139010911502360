/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    Legal,
    LegalFromJSON,
    LegalFromJSONTyped,
    LegalToJSON,
    LegalFinanceItem,
    LegalFinanceItemFromJSON,
    LegalFinanceItemFromJSONTyped,
    LegalFinanceItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface BusinessItem
 */
export interface BusinessItem {
    /**
     * 
     * @type {Address}
     * @memberof BusinessItem
     */
    address?: Address;
    /**
     * 
     * @type {LegalFinanceItem}
     * @memberof BusinessItem
     */
    finance?: LegalFinanceItem;
    /**
     * 
     * @type {number}
     * @memberof BusinessItem
     */
    id: number;
    /**
     * 
     * @type {Legal}
     * @memberof BusinessItem
     */
    legal: Legal;
}

export function BusinessItemFromJSON(json: any): BusinessItem {
    return BusinessItemFromJSONTyped(json, false);
}

export function BusinessItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): BusinessItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'finance': !exists(json, 'finance') ? undefined : LegalFinanceItemFromJSON(json['finance']),
        'id': json['id'],
        'legal': LegalFromJSON(json['legal']),
    };
}

export function BusinessItemToJSON(value?: BusinessItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': AddressToJSON(value.address),
        'finance': LegalFinanceItemToJSON(value.finance),
        'id': value.id,
        'legal': LegalToJSON(value.legal),
    };
}


