/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AttachmentListItem,
    AttachmentListItemFromJSON,
    AttachmentListItemToJSON,
    CampaignAttachmentType,
    CampaignAttachmentTypeFromJSON,
    CampaignAttachmentTypeToJSON,
    Deal,
    DealFromJSON,
    DealToJSON,
    PageDealListItem,
    PageDealListItemFromJSON,
    PageDealListItemToJSON,
    PageInvestmentSummaryItem,
    PageInvestmentSummaryItemFromJSON,
    PageInvestmentSummaryItemToJSON,
} from '../models';

export interface DealRequest {
    id: number;
}

export interface DealCampaignAttachmentRequest {
    id: number;
    attachmentId: number;
}

export interface DealCampaignAttachmentsRequest {
    id: number;
    type: CampaignAttachmentType;
}

export interface DealInvestmentsRequest {
    id: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface DealsRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
    businessName?: string;
    campaignTypes?: Array<string>;
    amountFrom?: number;
    amountTo?: number;
    startFrom?: Date;
    startTo?: Date;
    endFrom?: Date;
    endTo?: Date;
}

/**
 * 
 */
export class DealControllerApi extends runtime.BaseAPI {

    /**
     */
    async dealRaw(requestParameters: DealRequest): Promise<runtime.ApiResponse<Deal>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/deals/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DealFromJSON(jsonValue));
    }

    /**
     */
    async deal(requestParameters: DealRequest): Promise<Deal> {
        const response = await this.dealRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async dealCampaignAttachmentRaw(requestParameters: DealCampaignAttachmentRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dealCampaignAttachment.');
        }

        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId','Required parameter requestParameters.attachmentId was null or undefined when calling dealCampaignAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/deals/{id}/campaign/attachments/{attachmentId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async dealCampaignAttachment(requestParameters: DealCampaignAttachmentRequest): Promise<Blob> {
        const response = await this.dealCampaignAttachmentRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async dealCampaignAttachmentsRaw(requestParameters: DealCampaignAttachmentsRequest): Promise<runtime.ApiResponse<Array<AttachmentListItem>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dealCampaignAttachments.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling dealCampaignAttachments.');
        }

        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/deals/{id}/campaign/attachments`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AttachmentListItemFromJSON));
    }

    /**
     */
    async dealCampaignAttachments(requestParameters: DealCampaignAttachmentsRequest): Promise<Array<AttachmentListItem>> {
        const response = await this.dealCampaignAttachmentsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async dealInvestmentsRaw(requestParameters: DealInvestmentsRequest): Promise<runtime.ApiResponse<PageInvestmentSummaryItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dealInvestments.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/deals/{id}/investments`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageInvestmentSummaryItemFromJSON(jsonValue));
    }

    /**
     */
    async dealInvestments(requestParameters: DealInvestmentsRequest): Promise<PageInvestmentSummaryItem> {
        const response = await this.dealInvestmentsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async dealsRaw(requestParameters: DealsRequest): Promise<runtime.ApiResponse<PageDealListItem>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.businessName !== undefined) {
            queryParameters['businessName'] = requestParameters.businessName;
        }

        if (requestParameters.campaignTypes) {
            queryParameters['campaignTypes'] = requestParameters.campaignTypes;
        }

        if (requestParameters.amountFrom !== undefined) {
            queryParameters['amountFrom'] = requestParameters.amountFrom;
        }

        if (requestParameters.amountTo !== undefined) {
            queryParameters['amountTo'] = requestParameters.amountTo;
        }

        if (requestParameters.startFrom !== undefined) {
            queryParameters['startFrom'] = (requestParameters.startFrom as any).toISOString().substr(0,10);
        }

        if (requestParameters.startTo !== undefined) {
            queryParameters['startTo'] = (requestParameters.startTo as any).toISOString().substr(0,10);
        }

        if (requestParameters.endFrom !== undefined) {
            queryParameters['endFrom'] = (requestParameters.endFrom as any).toISOString().substr(0,10);
        }

        if (requestParameters.endTo !== undefined) {
            queryParameters['endTo'] = (requestParameters.endTo as any).toISOString().substr(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/deals`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDealListItemFromJSON(jsonValue));
    }

    /**
     */
    async deals(requestParameters: DealsRequest): Promise<PageDealListItem> {
        const response = await this.dealsRaw(requestParameters);
        return await response.value();
    }

}
