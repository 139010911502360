<template>
  <div class="container-filters mt-4 mb-3">
    <el-form
      @validate="onValidate"
      :model="filter"
      :rules="rules"
      ref="filter-form"
      @change="$emit('update:businessFilter', filter)"
    >
      <div class="row">
        <div class="col-6">
          <el-form-item :label="$t('business.name')" prop="name">
            <el-input v-model="filter.name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('business.email')" prop="email">
            <el-input v-model="filter.email"></el-input>
          </el-form-item>
        </div>

        <div class="col-6">
          <el-form-item
            :label="$t('legal.general.registrationNumber')"
            prop="registrationNumber"
          >
            <el-input v-model="filter.registrationNumber"></el-input>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
  <div class="mb-5">
    <el-button :disabled="!isFormValid" type="primary" @click="submit">
      {{ $t("apply") }}
    </el-button>
    <el-button type="default" @click="resetFilter">
      {{ $t("filter.reset") }}
    </el-button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ElementForm, InputLength } from "@/util/validation";
import { Filter } from "@/views/business/BusinessList.vue";

export default defineComponent({
  emits: ["update:businessFilter", "submit"],
  props: ["businessFilter"],
  data() {
    return {
      filter: {} as Filter,
      isFormValid: true,
      rules: {
        name: [new InputLength(70)],
        registrationNumber: [new InputLength(50)],
        email: [new InputLength(255)],
      },
    };
  },
  async mounted() {
    this.filter = this.businessFilter;
  },
  methods: {
    submit() {
      const form = this.$refs["filter-form"] as ElementForm;
      form.validate(async (valid) => {
        if (valid) {
          this.$emit("submit");
        }
      });
    },
    onValidate(prop: string, isVal: boolean) {
      this.isFormValid = isVal;
    },
    resetFilter() {
      this.filter = {} as Filter;
      this.$emit("update:businessFilter", this.filter);
      this.$emit("submit");
    },
  },
});
</script>
