/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PreScreeningStatus,
    PreScreeningStatusFromJSON,
    PreScreeningStatusFromJSONTyped,
    PreScreeningStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface PreScreeningListItem
 */
export interface PreScreeningListItem {
    /**
     * 
     * @type {string}
     * @memberof PreScreeningListItem
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof PreScreeningListItem
     */
    companyRegistrationCountry: string;
    /**
     * 
     * @type {string}
     * @memberof PreScreeningListItem
     */
    companyRegistrationNumber: string;
    /**
     * 
     * @type {string}
     * @memberof PreScreeningListItem
     */
    contactEmail: string;
    /**
     * 
     * @type {string}
     * @memberof PreScreeningListItem
     */
    contactName: string;
    /**
     * 
     * @type {Date}
     * @memberof PreScreeningListItem
     */
    createdAt: Date;
    /**
     * 
     * @type {number}
     * @memberof PreScreeningListItem
     */
    id: number;
    /**
     * 
     * @type {PreScreeningStatus}
     * @memberof PreScreeningListItem
     */
    status: PreScreeningStatus;
}

export function PreScreeningListItemFromJSON(json: any): PreScreeningListItem {
    return PreScreeningListItemFromJSONTyped(json, false);
}

export function PreScreeningListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreScreeningListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyName': json['companyName'],
        'companyRegistrationCountry': json['companyRegistrationCountry'],
        'companyRegistrationNumber': json['companyRegistrationNumber'],
        'contactEmail': json['contactEmail'],
        'contactName': json['contactName'],
        'createdAt': (new Date(json['createdAt'])),
        'id': json['id'],
        'status': PreScreeningStatusFromJSON(json['status']),
    };
}

export function PreScreeningListItemToJSON(value?: PreScreeningListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyName': value.companyName,
        'companyRegistrationCountry': value.companyRegistrationCountry,
        'companyRegistrationNumber': value.companyRegistrationNumber,
        'contactEmail': value.contactEmail,
        'contactName': value.contactName,
        'createdAt': (value.createdAt.toISOString().substr(0,10)),
        'id': value.id,
        'status': PreScreeningStatusToJSON(value.status),
    };
}


