/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Country,
    CountryFromJSON,
    CountryFromJSONTyped,
    CountryToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateUserForm
 */
export interface UpdateUserForm {
    /**
     * 
     * @type {Date}
     * @memberof UpdateUserForm
     */
    birthDate: Date;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserForm
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserForm
     */
    givenName: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserForm
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserForm
     */
    phone: string;
    /**
     * 
     * @type {Country}
     * @memberof UpdateUserForm
     */
    residenceCountry: Country;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserForm
     */
    role: string;
}

export function UpdateUserFormFromJSON(json: any): UpdateUserForm {
    return UpdateUserFormFromJSONTyped(json, false);
}

export function UpdateUserFormFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserForm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'birthDate': (new Date(json['birthDate'])),
        'email': json['email'],
        'givenName': json['givenName'],
        'lastName': json['lastName'],
        'phone': json['phone'],
        'residenceCountry': CountryFromJSON(json['residenceCountry']),
        'role': json['role'],
    };
}

export function UpdateUserFormToJSON(value?: UpdateUserForm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'birthDate': (value.birthDate.toISOString().substr(0,10)),
        'email': value.email,
        'givenName': value.givenName,
        'lastName': value.lastName,
        'phone': value.phone,
        'residenceCountry': CountryToJSON(value.residenceCountry),
        'role': value.role,
    };
}


