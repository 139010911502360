
import { defineComponent } from "vue";
import { isProblem } from "@/resources/problem";
import { authApi } from "@/resources";
import { ElementForm, InputRequired, passwordFormat } from "@/util/validation";
import PasswordPolicy from "@/components/PasswordPolicy.vue";

interface RegistrationForm {
  password: string;
  passwordConfirm: string;
}

export default defineComponent({
  components: { PasswordPolicy },
  data() {
    return {
      registrationForm: {} as RegistrationForm,
      errorMessage: "" as string,
      rules: {
        password: [new InputRequired(), passwordFormat],
        passwordConfirm: [new InputRequired()],
      },
    };
  },
  methods: {
    onSave() {
      this.errorMessage = "";
      const form = this.$refs["form"] as ElementForm;
      form.validate((valid) => {
        if (!valid) {
          return false;
        }
        if (
          this.registrationForm.password ===
          this.registrationForm.passwordConfirm
        ) {
          this.confirmRegistration();
        } else {
          this.errorMessage = this.$t("auth.passwordDontMatch");
        }
        return true;
      });
    },
    async confirmRegistration() {
      try {
        await authApi.confirmRegistration({
          code: this.$route.query.code as string,
          registrationForm: this.registrationForm,
        });
        await this.$router.push({ path: "/", force: true });
      } catch (e) {
        if (isProblem(e)) {
          this.errorMessage = e.detail;
        }
      }
    },
  },
});
