/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CommonFeeSettingsHistoryItem,
    CommonFeeSettingsHistoryItemFromJSON,
    CommonFeeSettingsHistoryItemFromJSONTyped,
    CommonFeeSettingsHistoryItemToJSON,
    InvestorFeeSettingsHistoryItem,
    InvestorFeeSettingsHistoryItemFromJSON,
    InvestorFeeSettingsHistoryItemFromJSONTyped,
    InvestorFeeSettingsHistoryItemToJSON,
    InvestorIndividualLimitSettingsHistoryItem,
    InvestorIndividualLimitSettingsHistoryItemFromJSON,
    InvestorIndividualLimitSettingsHistoryItemFromJSONTyped,
    InvestorIndividualLimitSettingsHistoryItemToJSON,
    InvestorLegalLimitSettingsHistoryItem,
    InvestorLegalLimitSettingsHistoryItemFromJSON,
    InvestorLegalLimitSettingsHistoryItemFromJSONTyped,
    InvestorLegalLimitSettingsHistoryItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface SettingsHistoryItem
 */
export interface SettingsHistoryItem {
    /**
     * 
     * @type {CommonFeeSettingsHistoryItem}
     * @memberof SettingsHistoryItem
     */
    commonFee?: CommonFeeSettingsHistoryItem;
    /**
     * 
     * @type {InvestorFeeSettingsHistoryItem}
     * @memberof SettingsHistoryItem
     */
    investorFee?: InvestorFeeSettingsHistoryItem;
    /**
     * 
     * @type {InvestorIndividualLimitSettingsHistoryItem}
     * @memberof SettingsHistoryItem
     */
    investorIndividualLimit?: InvestorIndividualLimitSettingsHistoryItem;
    /**
     * 
     * @type {InvestorLegalLimitSettingsHistoryItem}
     * @memberof SettingsHistoryItem
     */
    investorLegalLimit?: InvestorLegalLimitSettingsHistoryItem;
}

export function SettingsHistoryItemFromJSON(json: any): SettingsHistoryItem {
    return SettingsHistoryItemFromJSONTyped(json, false);
}

export function SettingsHistoryItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingsHistoryItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'commonFee': !exists(json, 'commonFee') ? undefined : CommonFeeSettingsHistoryItemFromJSON(json['commonFee']),
        'investorFee': !exists(json, 'investorFee') ? undefined : InvestorFeeSettingsHistoryItemFromJSON(json['investorFee']),
        'investorIndividualLimit': !exists(json, 'investorIndividualLimit') ? undefined : InvestorIndividualLimitSettingsHistoryItemFromJSON(json['investorIndividualLimit']),
        'investorLegalLimit': !exists(json, 'investorLegalLimit') ? undefined : InvestorLegalLimitSettingsHistoryItemFromJSON(json['investorLegalLimit']),
    };
}

export function SettingsHistoryItemToJSON(value?: SettingsHistoryItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'commonFee': CommonFeeSettingsHistoryItemToJSON(value.commonFee),
        'investorFee': InvestorFeeSettingsHistoryItemToJSON(value.investorFee),
        'investorIndividualLimit': InvestorIndividualLimitSettingsHistoryItemToJSON(value.investorIndividualLimit),
        'investorLegalLimit': InvestorLegalLimitSettingsHistoryItemToJSON(value.investorLegalLimit),
    };
}


