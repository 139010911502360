
import { defineComponent, PropType } from "vue";
import { Currency, LegalFinanceItem } from "@/api";

export default defineComponent({
  name: "LegalFinance",
  props: {
    finance: {
      type: Object as PropType<LegalFinanceItem>,
      required: true,
    },
  },
  data: () => ({
    defaultCurrency: Currency.OMR as Currency,
  }),
});
