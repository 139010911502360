<template>
  <div class="container ms-0 mb-0" v-if="investor.id">
    <div class="row">
      <div class="col mb-3">
        <CurrentAddress
          v-model:current-address="investor.address.currentAddress"
        >
          <div class="col mb-3">
            <InvestorIndividualContact
              v-model:individual="investor.individual"
              v-if="investor.type === 'INDIVIDUAL'"
            />
            <LegalContact v-model:legal="investor.legal" v-else />
          </div>
        </CurrentAddress>
      </div>
      <div class="col mb-3">
        <PermanentAddress
          v-model:permanent-address="investor.address.permanentAddress"
        />
      </div>
      <div class="col mb-3">
        <ContactPerson
          v-if="investor.type === 'INDIVIDUAL'"
          v-model:contact="investor.individual.contact"
        />
        <ContactPerson v-else v-model:contact="investor.legal.contact" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { Investor } from "@/api";
import ContactPerson from "@/views/components/ContactPerson.vue";
import InvestorIndividualContact from "@/views/investor/components/InvestorIndividualContact.vue";
import LegalContact from "@/views/components/LegalContact.vue";
import CurrentAddress from "@/views/components/CurrentAddress.vue";
import PermanentAddress from "@/views/components/PermanentAddress.vue";

export default defineComponent({
  name: "InvestorContactDetails",
  components: {
    LegalContact,
    InvestorIndividualContact,
    ContactPerson,
    CurrentAddress,
    PermanentAddress,
  },
  data() {
    return {
      investor: inject("investor") as Investor,
    };
  },
});
</script>
