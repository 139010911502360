<template>
  <el-dropdown-item :disabled="dialogDisabled" @click="dialogVisible = true">
    {{ header }}
  </el-dropdown-item>

  <teleport to="body">
    <el-dialog :title="text" v-model="dialogVisible" :before-close="cancel">
      <slot></slot>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancel">{{ $t("cancel") }}</el-button>
          <el-button type="primary" @click="ok">{{ $t("ok") }}</el-button>
        </span>
      </template>
    </el-dialog>
  </teleport>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { isConstraintViolation, isProblem } from "@/resources/problem";

export default defineComponent({
  name: "OkCancelDialog",
  emits: ["update"],
  props: {
    header: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    infoMessage: {
      type: String,
      required: false,
    },
    dialogDisabled: {
      type: Boolean,
      required: true,
    },
    onOk: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    cancel() {
      this.dialogVisible = false;
    },
    async ok() {
      try {
        await this.onOk();
        if (this.infoMessage) {
          this.$notify.info(this.infoMessage);
        }
      } catch (e) {
        if (isConstraintViolation(e)) {
          e.violations.forEach((violation) =>
            this.$notify.error(this.$t(violation.message))
          );
        } else if (isProblem(e)) {
          this.$notify.error(this.$t(e.detail));
        }
      }
      this.$emit("update");
      this.cancel();
    },
  },
});
</script>
