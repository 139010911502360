<template>
  <div class="container-filters mt-4 mb-3">
    <el-form
      @validate="onValidate"
      :model="filter"
      :rules="rules"
      @change="$emit('update:userFilter', filter)"
    >
      <div class="row">
        <div class="col-6">
          <el-form-item :label="$t('filter.fullname')" prop="fullname">
            <el-input v-model="filter.fullname"></el-input>
          </el-form-item>
          <el-form-item :label="$t('filter.status')">
            <el-select
              v-model="filter.statuses"
              class="w-100"
              multiple
              collapse-tags
            >
              <el-option
                v-for="(status, key) in statuses"
                :key="key"
                :label="$enums.user.status(status)"
                :value="status"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="col-6">
          <el-form-item :label="$t('filter.role')">
            <el-select
              v-model="filter.roles"
              class="w-100"
              multiple
              collapse-tags
            >
              <el-option
                v-for="(role, id) in roles"
                :key="id"
                :value="role.id"
                :label="role.name"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item :label="$t('filter.residenceCountry')">
            <el-select
              v-model="filter.residenceCountries"
              class="w-100"
              multiple
              collapse-tags
            >
              <el-option
                v-for="(country, key) in residenceCountryOptions"
                :key="key"
                :label="$enums.globals.country(country)"
                :value="country"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
  <div class="mb-5">
    <el-button type="primary" @click="$emit('submit')" :disabled="!isFormValid">
      {{ $t("apply") }}
    </el-button>
    <el-button type="default" @click="resetFilter">
      {{ $t("filter.reset") }}
    </el-button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Country, RoleOption, UserStatus } from "@/api";
import { Filter } from "@/views/user/backoffice/UserList.vue";
import { InputLength } from "@/util/validation";
import { roleApi } from "@/resources";

export default defineComponent({
  emits: ["update:userFilter", "submit"],
  name: "UserFilter",
  props: ["userFilter"],
  data() {
    return {
      residenceCountryOptions: Country,
      statuses: UserStatus,
      roles: [] as RoleOption[],
      isFormValid: true,
      filter: {} as Filter,
      rules: {
        fullname: [new InputLength(100)],
      },
    };
  },
  async mounted() {
    this.filter = this.userFilter;
    this.roles = await roleApi.roleOptionsForFilter();
  },
  methods: {
    onValidate(prop: string, isVal: boolean) {
      this.isFormValid = isVal;
    },
    resetFilter() {
      this.filter = {} as Filter;
      this.$emit("update:userFilter", this.filter);
      this.$emit("submit");
    },
  },
});
</script>
