
import { defineComponent, PropType } from "vue";
import { Individual } from "@/api";

export default defineComponent({
  name: "InvestorIndividualContact",
  props: {
    individual: {
      type: Object as PropType<Individual>,
      required: true,
    },
  },
});
