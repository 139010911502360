/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BankAccountStatus,
    BankAccountStatusFromJSON,
    BankAccountStatusFromJSONTyped,
    BankAccountStatusToJSON,
    Currency,
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './';

/**
 * 
 * @export
 * @interface BankAccountListItem
 */
export interface BankAccountListItem {
    /**
     * 
     * @type {string}
     * @memberof BankAccountListItem
     */
    bankAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountListItem
     */
    bankBic?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountListItem
     */
    bankName?: string;
    /**
     * 
     * @type {Currency}
     * @memberof BankAccountListItem
     */
    currency?: Currency;
    /**
     * 
     * @type {boolean}
     * @memberof BankAccountListItem
     */
    dividend?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BankAccountListItem
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BankAccountListItem
     */
    master?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BankAccountListItem
     */
    number?: string;
    /**
     * 
     * @type {Date}
     * @memberof BankAccountListItem
     */
    openedAt?: Date;
    /**
     * 
     * @type {BankAccountStatus}
     * @memberof BankAccountListItem
     */
    status?: BankAccountStatus;
}

export function BankAccountListItemFromJSON(json: any): BankAccountListItem {
    return BankAccountListItemFromJSONTyped(json, false);
}

export function BankAccountListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): BankAccountListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bankAddress': !exists(json, 'bankAddress') ? undefined : json['bankAddress'],
        'bankBic': !exists(json, 'bankBic') ? undefined : json['bankBic'],
        'bankName': !exists(json, 'bankName') ? undefined : json['bankName'],
        'currency': !exists(json, 'currency') ? undefined : CurrencyFromJSON(json['currency']),
        'dividend': !exists(json, 'dividend') ? undefined : json['dividend'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'master': !exists(json, 'master') ? undefined : json['master'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'openedAt': !exists(json, 'openedAt') ? undefined : (new Date(json['openedAt'])),
        'status': !exists(json, 'status') ? undefined : BankAccountStatusFromJSON(json['status']),
    };
}

export function BankAccountListItemToJSON(value?: BankAccountListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bankAddress': value.bankAddress,
        'bankBic': value.bankBic,
        'bankName': value.bankName,
        'currency': CurrencyToJSON(value.currency),
        'dividend': value.dividend,
        'id': value.id,
        'master': value.master,
        'number': value.number,
        'openedAt': value.openedAt === undefined ? undefined : (value.openedAt.toISOString().substr(0,10)),
        'status': BankAccountStatusToJSON(value.status),
    };
}


