/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CampaignContentType {
    HIGHLIGHTS = 'HIGHLIGHTS',
    PROBLEM = 'PROBLEM',
    SOLUTION = 'SOLUTION',
    PRODUCT = 'PRODUCT',
    TRACTION = 'TRACTION',
    CUSTOMERS = 'CUSTOMERS',
    BUSINESS = 'BUSINESS',
    MARKET = 'MARKET',
    COMPETITION = 'COMPETITION',
    STRATEGY = 'STRATEGY',
    FUNDING = 'FUNDING',
    TEAM = 'TEAM',
    RISKS = 'RISKS',
    TERMS = 'TERMS',
    LOGO = 'LOGO',
    BANNER = 'BANNER',
    FAQ = 'FAQ',
    TERMS_AND_CONDITION = 'TERMS_AND_CONDITION',
    PITCH_DECK = 'PITCH_DECK',
    VIDEO_LINK = 'VIDEO_LINK'
}

export function CampaignContentTypeFromJSON(json: any): CampaignContentType {
    return CampaignContentTypeFromJSONTyped(json, false);
}

export function CampaignContentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CampaignContentType {
    return json as CampaignContentType;
}

export function CampaignContentTypeToJSON(value?: CampaignContentType | null): any {
    return value as any;
}

