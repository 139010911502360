/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum BankAccountStatus {
    ACTIVE = 'ACTIVE'
}

export function BankAccountStatusFromJSON(json: any): BankAccountStatus {
    return BankAccountStatusFromJSONTyped(json, false);
}

export function BankAccountStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): BankAccountStatus {
    return json as BankAccountStatus;
}

export function BankAccountStatusToJSON(value?: BankAccountStatus | null): any {
    return value as any;
}

