/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FieldHistoryView,
    FieldHistoryViewFromJSON,
    FieldHistoryViewFromJSONTyped,
    FieldHistoryViewToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExchangeRateSettingsHistoryItem
 */
export interface ExchangeRateSettingsHistoryItem {
    /**
     * 
     * @type {Array<FieldHistoryView>}
     * @memberof ExchangeRateSettingsHistoryItem
     */
    usdToAed: Array<FieldHistoryView>;
    /**
     * 
     * @type {Array<FieldHistoryView>}
     * @memberof ExchangeRateSettingsHistoryItem
     */
    usdToBhd: Array<FieldHistoryView>;
    /**
     * 
     * @type {Array<FieldHistoryView>}
     * @memberof ExchangeRateSettingsHistoryItem
     */
    usdToKwd: Array<FieldHistoryView>;
    /**
     * 
     * @type {Array<FieldHistoryView>}
     * @memberof ExchangeRateSettingsHistoryItem
     */
    usdToOmr: Array<FieldHistoryView>;
    /**
     * 
     * @type {Array<FieldHistoryView>}
     * @memberof ExchangeRateSettingsHistoryItem
     */
    usdToQar: Array<FieldHistoryView>;
    /**
     * 
     * @type {Array<FieldHistoryView>}
     * @memberof ExchangeRateSettingsHistoryItem
     */
    usdToSar: Array<FieldHistoryView>;
}

export function ExchangeRateSettingsHistoryItemFromJSON(json: any): ExchangeRateSettingsHistoryItem {
    return ExchangeRateSettingsHistoryItemFromJSONTyped(json, false);
}

export function ExchangeRateSettingsHistoryItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExchangeRateSettingsHistoryItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'usdToAed': ((json['usdToAed'] as Array<any>).map(FieldHistoryViewFromJSON)),
        'usdToBhd': ((json['usdToBhd'] as Array<any>).map(FieldHistoryViewFromJSON)),
        'usdToKwd': ((json['usdToKwd'] as Array<any>).map(FieldHistoryViewFromJSON)),
        'usdToOmr': ((json['usdToOmr'] as Array<any>).map(FieldHistoryViewFromJSON)),
        'usdToQar': ((json['usdToQar'] as Array<any>).map(FieldHistoryViewFromJSON)),
        'usdToSar': ((json['usdToSar'] as Array<any>).map(FieldHistoryViewFromJSON)),
    };
}

export function ExchangeRateSettingsHistoryItemToJSON(value?: ExchangeRateSettingsHistoryItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'usdToAed': ((value.usdToAed as Array<any>).map(FieldHistoryViewToJSON)),
        'usdToBhd': ((value.usdToBhd as Array<any>).map(FieldHistoryViewToJSON)),
        'usdToKwd': ((value.usdToKwd as Array<any>).map(FieldHistoryViewToJSON)),
        'usdToOmr': ((value.usdToOmr as Array<any>).map(FieldHistoryViewToJSON)),
        'usdToQar': ((value.usdToQar as Array<any>).map(FieldHistoryViewToJSON)),
        'usdToSar': ((value.usdToSar as Array<any>).map(FieldHistoryViewToJSON)),
    };
}


