<template>
  <li class="nav-item" :class="{ disabled: disabled }">
    <button v-if="disabled" class="nav-link disabled" disabled>
      <slot></slot>
    </button>
    <router-link
      v-else
      v-slot="{ navigate, isActive }"
      custom
      :to="{ name: to, params: props }"
    >
      <button class="nav-link" :class="isActive && 'active'" @click="navigate">
        <slot></slot>
      </button>
    </router-link>
  </li>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "TabLink",
  props: {
    to: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/styles/tabs";
</style>
