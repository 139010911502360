<template>
  <div class="container ms-0 mb-3" v-if="form.retail !== undefined">
    <div class="col-md-5">
      <div class="row">
        <el-form
          :model="form"
          :rules="rules"
          status-icon
          ref="form"
          @validate="onValidate"
        >
          <el-form-item
            :label="$t('settings.investorLimit.retail')"
            prop="retail"
          >
            <el-input :disabled="!editable" v-model="form.retail"></el-input>
            <HistoryPopover
              :history="investorIndividualLimitHistory.retail"
              :field-name="$t('settings.investorLimit.retail')"
              :value-type="historyFormat.CURRENCY"
            />
          </el-form-item>
          <el-form-item
            :label="$t('settings.investorLimit.angel')"
            prop="angel"
          >
            <el-input :disabled="!editable" v-model="form.angel"></el-input>
            <HistoryPopover
              :history="investorIndividualLimitHistory.angel"
              :field-name="$t('settings.investorLimit.angel')"
              :value-type="historyFormat.CURRENCY"
            />
          </el-form-item>
        </el-form>
      </div>
      <br />
      <el-button
        @click="edit"
        v-show="!editable"
        v-if="hasAuthority(authorities.SETTINGS_WRITE)"
      >
        {{ $t("edit") }}
      </el-button>
      <el-button style="margin-left: 0" @click="cancel" v-show="editable">
        {{ $t("cancel") }}
      </el-button>
      <el-button
        type="primary"
        v-show="editable"
        :disabled="!isFormValid"
        @click="submit"
      >
        {{ $t("save") }}
      </el-button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { authorityMixin } from "@/util";
import {
  InvestorIndividualLimitSettingsData,
  InvestorIndividualLimitSettingsHistoryItem,
  InvestorIndividualLimitSettingsItem,
} from "@/api";
import {
  ElementForm,
  InputRequired,
  numberFormat,
  NumberMin,
} from "@/util/validation";
import { settingsApi } from "@/resources";
import { isConstraintViolation, isProblem } from "@/resources/problem";
import HistoryPopover from "@/components/HistoryPopover.vue";
import { HistoryFormat } from "@/util/enum/HistoryFormat";

export default defineComponent({
  name: "SettingsIndividualInvestorLimit",
  mixins: [authorityMixin],
  components: { HistoryPopover },
  data() {
    return {
      investorIndividualLimit: inject(
        "investorIndividualLimit"
      ) as InvestorIndividualLimitSettingsItem,
      investorIndividualLimitHistory: inject(
        "investorIndividualLimitHistory"
      ) as InvestorIndividualLimitSettingsHistoryItem,
      historyFormat: HistoryFormat,
      form: {} as InvestorIndividualLimitSettingsData,
      rules: {
        retail: [
          new InputRequired("validation.inputRequired"),
          numberFormat,
          new NumberMin(0.0),
        ],
        angel: [
          new InputRequired("validation.inputRequired"),
          numberFormat,
          new NumberMin(0.0),
        ],
      },
      isFormValid: true,
      editable: false,
    };
  },
  watch: {
    investorIndividualLimit() {
      Object.assign(this.form, this.investorIndividualLimit);
    },
  },
  mounted() {
    Object.assign(this.form, this.investorIndividualLimit);
  },
  methods: {
    cancel() {
      Object.assign(this.form, this.investorIndividualLimit);
      this.editable = false;
    },
    onValidate(prop: string, isVal: boolean) {
      this.isFormValid = isVal;
    },
    edit() {
      this.editable = true;
      Object.assign(this.form, this.investorIndividualLimit);
    },
    async submit() {
      const form = this.$refs["form"] as ElementForm;
      if (await form.validate()) {
        try {
          await settingsApi.individualInvestorLimitSettings({
            investorIndividualLimitSettingsData: this.form,
          });
          Object.assign(this.investorIndividualLimit, this.form);
          this.editable = false;
          this.$emit("update");
        } catch (error) {
          if (isConstraintViolation(error)) {
            error.violations.forEach((violation) =>
              this.$notify.error(this.$t(violation.message))
            );
          } else if (isProblem(error)) {
            this.$notify.error(this.$t(error.detail));
          }
        }
      }
    },
  },
});
</script>
