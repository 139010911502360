import moment from "moment";

export function date(date?: Date, defaultValue?: string): string {
  if (date != null) {
    return moment(date).utc().format("DD/MM/YYYY");
  }
  return defaultValue != null ? defaultValue : "-";
}

export function dateTime(date?: Date): string {
  if (date != null) {
    return moment(date).format("DD/MM/YYYY HH:mm");
  }
  return "-";
}

export function dateTimeSeconds(date?: Date): string {
  if (date != null) {
    return moment(date).format("DD/MM/YYYY HH:mm:ss");
  }
  return "-";
}

export function time(date?: Date): string {
  if (date != null) {
    return moment(date).format("hh:mm A");
  }
  return "-";
}

export function fixLocalDate(date: Date): Date {
  if (date) {
    const utcDate = Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    );
    return new Date(utcDate);
  }

  return date;
}

export function fixLocalDates<Type>(object: Record<string, Type>): void {
  for (const [k, v] of Object.entries(object)) {
    if (v instanceof Date) {
      object[k] = fixLocalDate(v as Date) as never;
    } else if (v === null) {
      object[k] = undefined as never;
    }
  }
}
