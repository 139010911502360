/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PreScreeningQuizKey,
    PreScreeningQuizKeyFromJSON,
    PreScreeningQuizKeyFromJSONTyped,
    PreScreeningQuizKeyToJSON,
} from './';

/**
 * 
 * @export
 * @interface PairPreScreeningQuizKeyString
 */
export interface PairPreScreeningQuizKeyString {
    /**
     * 
     * @type {PreScreeningQuizKey}
     * @memberof PairPreScreeningQuizKeyString
     */
    key: PreScreeningQuizKey;
    /**
     * 
     * @type {string}
     * @memberof PairPreScreeningQuizKeyString
     */
    value: string;
}

export function PairPreScreeningQuizKeyStringFromJSON(json: any): PairPreScreeningQuizKeyString {
    return PairPreScreeningQuizKeyStringFromJSONTyped(json, false);
}

export function PairPreScreeningQuizKeyStringFromJSONTyped(json: any, ignoreDiscriminator: boolean): PairPreScreeningQuizKeyString {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': PreScreeningQuizKeyFromJSON(json['key']),
        'value': json['value'],
    };
}

export function PairPreScreeningQuizKeyStringToJSON(value?: PairPreScreeningQuizKeyString | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': PreScreeningQuizKeyToJSON(value.key),
        'value': value.value,
    };
}


