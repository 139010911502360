
import { computed, defineComponent } from "vue";
import TabLink from "@/components/TabLink.vue";
import Headline from "@/components/Headline.vue";
import { investorApi } from "@/resources";
import { Investor } from "@/api";
import BackLink from "@/components/BackLink.vue";

export default defineComponent({
  name: "InvestorView",
  components: {
    Headline,
    TabLink,
    BackLink,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  provide() {
    return {
      investorId: computed(() => this.id),
      investor: computed(() => this.investor),
    };
  },
  data() {
    return {
      investor: {} as Investor,
    };
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchInvestor() {
      this.investor = await investorApi.investor({ id: Number(this.id) });
    },
    async fetchData() {
      await Promise.all([this.fetchInvestor()]);
    },
  },
});
