/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BackOfficeFlowEntityStateCampaignStatus,
    BackOfficeFlowEntityStateCampaignStatusFromJSON,
    BackOfficeFlowEntityStateCampaignStatusToJSON,
    QAChangeStatusRequest,
    QAChangeStatusRequestFromJSON,
    QAChangeStatusRequestToJSON,
} from '../models';

export interface ChangeStatusQARequest {
    id: number;
    qAChangeStatusRequest: QAChangeStatusRequest;
}

export interface StateQARequest {
    id: number;
}

/**
 * 
 */
export class QaCampaignControllerApi extends runtime.BaseAPI {

    /**
     */
    async changeStatusQARaw(requestParameters: ChangeStatusQARequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeStatusQA.');
        }

        if (requestParameters.qAChangeStatusRequest === null || requestParameters.qAChangeStatusRequest === undefined) {
            throw new runtime.RequiredError('qAChangeStatusRequest','Required parameter requestParameters.qAChangeStatusRequest was null or undefined when calling changeStatusQA.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/qa/{id}/status`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QAChangeStatusRequestToJSON(requestParameters.qAChangeStatusRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async changeStatusQA(requestParameters: ChangeStatusQARequest): Promise<void> {
        await this.changeStatusQARaw(requestParameters);
    }

    /**
     */
    async stateQARaw(requestParameters: StateQARequest): Promise<runtime.ApiResponse<BackOfficeFlowEntityStateCampaignStatus>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling stateQA.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/campaign/qa/{id}/state`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BackOfficeFlowEntityStateCampaignStatusFromJSON(jsonValue));
    }

    /**
     */
    async stateQA(requestParameters: StateQARequest): Promise<BackOfficeFlowEntityStateCampaignStatus> {
        const response = await this.stateQARaw(requestParameters);
        return await response.value();
    }

}
