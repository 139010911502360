/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BackOfficeInvestmentState
 */
export interface BackOfficeInvestmentState {
    /**
     * 
     * @type {boolean}
     * @memberof BackOfficeInvestmentState
     */
    investmentTransactionExist?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof BackOfficeInvestmentState
     */
    nextStatuses?: Array<string>;
}

export function BackOfficeInvestmentStateFromJSON(json: any): BackOfficeInvestmentState {
    return BackOfficeInvestmentStateFromJSONTyped(json, false);
}

export function BackOfficeInvestmentStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): BackOfficeInvestmentState {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'investmentTransactionExist': !exists(json, 'investmentTransactionExist') ? undefined : json['investmentTransactionExist'],
        'nextStatuses': !exists(json, 'nextStatuses') ? undefined : json['nextStatuses'],
    };
}

export function BackOfficeInvestmentStateToJSON(value?: BackOfficeInvestmentState | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'investmentTransactionExist': value.investmentTransactionExist,
        'nextStatuses': value.nextStatuses,
    };
}


