/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccountDetailsSettingsItem
 */
export interface AccountDetailsSettingsItem {
    /**
     * 
     * @type {string}
     * @memberof AccountDetailsSettingsItem
     */
    bankAccount: string;
}

export function AccountDetailsSettingsItemFromJSON(json: any): AccountDetailsSettingsItem {
    return AccountDetailsSettingsItemFromJSONTyped(json, false);
}

export function AccountDetailsSettingsItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountDetailsSettingsItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bankAccount': json['bankAccount'],
    };
}

export function AccountDetailsSettingsItemToJSON(value?: AccountDetailsSettingsItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bankAccount': value.bankAccount,
    };
}


