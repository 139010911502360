<template>
  <div v-loading="loading" v-if="isImage" class="image-container">
    <el-image
      class="img"
      v-if="sourceUrl"
      fit="contain"
      :src="sourceUrl"
      :preview-src-list="[sourceUrl]"
    >
    </el-image>
    <!--    Render blank for avoid resizing of div    -->
    <el-image class="img" v-else fit="contain">
      <template #error>
        <div class="image-slot"></div>
      </template>
    </el-image>
    <el-link type="primary" class="link" @click.prevent="download"
      >{{ attachment.filename ?? $t("download") }}
    </el-link>
    <ButtonOkCancelDialog
      v-if="deleteEnabled && deleteMethod"
      class="el-button--x-small"
      style="position: absolute; top: 10px; right: 10px"
      icon="el-icon-delete"
      :title="$t('attachment.delete.title')"
      :message="$t('attachment.delete.message')"
      :onOk="deleteAttachment"
    />
  </div>
  <div v-if="!isImage" class="link-container">
    <el-link type="primary" class="link" @click.prevent="download"
      >{{ attachment.filename ?? $t("download") }}
    </el-link>
    <div class="button-container">
      <ButtonOkCancelDialog
        v-if="deleteEnabled && deleteMethod"
        class="el-button--x-small"
        style="margin-left: 10px"
        icon="el-icon-delete"
        :title="$t('attachment.delete.title')"
        :message="$t('attachment.delete.message')"
        :onOk="deleteAttachment"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import ButtonOkCancelDialog from "@/views/components/ButtonOkCancelDialog.vue";
import { CampaignContentData } from "@/api";

export default defineComponent({
  name: "ContentAttachment",
  components: { ButtonOkCancelDialog },
  emits: ["update"],
  props: {
    attachment: {
      type: Object as PropType<CampaignContentData>,
      required: true,
    },
    isImage: {
      type: Boolean,
      required: true,
    },
    deleteEnabled: {
      type: Boolean,
      default: false,
    },
    deleteMethod: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      loading: true,
      sourceUrl: "" as string,
    };
  },
  async mounted() {
    if (this.isImage) {
      await this.updateSourceUrl();
    }
    this.loading = false;
  },
  methods: {
    async download() {
      const link = document.createElement("a");
      link.href = this.attachment.attachmentUrl as string;
      link.download = this.attachment.filename as string;
      link.click();
    },
    async updateSourceUrl() {
      this.sourceUrl = this.attachment.attachmentUrl as string;
    },
    async deleteAttachment() {
      if (this.deleteMethod) {
        await this.deleteMethod();
      }
    },
  },
});
</script>

<style scoped lang="scss">
$height: 260px;
$width: 244px;

i {
  font-size: x-large;
}
.image-container {
  height: $height;
  width: $width;
  text-align: left;
  position: relative;

  .img {
    width: 100%;
    height: 61.5%;
    white-space: nowrap;
    border-radius: 3px;
    overflow: hidden;
    border: 1px solid #dadada;
    box-shadow: 0 3px 3px rgb(8 20 39 / 10%);
  }

  .link {
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all !important;
  }
}

.link-container {
  width: $width;
  height: $height * 0.385;
  display: flex;
  .link {
    width: 95%;
    height: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all !important;
  }
}

.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--el-fill-color-light);
  color: var(--el-text-color-secondary);
  font-size: 30px;
}
</style>
