
import { computed, defineComponent } from "vue";
import TabLink from "@/components/TabLink.vue";
import Headline from "@/components/Headline.vue";
import { campaignApi, campaignOperationApi } from "@/resources";
import {
  BackOfficeFlowEntityStateCampaignStatus,
  BaseCampaignOperationRequest,
  CampaignItem,
  CampaignStatus,
} from "@/api";
import { authorityMixin } from "@/util";
import Status from "@/components/Status.vue";
import BackLink from "@/components/BackLink.vue";
import { watchCampaignUpdate } from "@/util/ws";
import BaseCampaignReasonDialog from "@/views/campaign/dialogs/BaseCampaignReasonDialog.vue";
import DeclineCampaignDialog from "@/views/campaign/dialogs/DeclineCampaignDialog.vue";
import ApproveCampaignDialog from "@/views/campaign/dialogs/ApproveCampaignDialog.vue";
import { mapState } from "vuex";
import QAChangeCampaignStatusDialog from "@/views/campaign/dialogs/QAChangeCampaignStatusDialog.vue";
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";
import OkCancelDialog from "@/views/components/OkCancelDialog.vue";
import ExtendCampaignDurationDialog from "@/views/campaign/dialogs/ExtendCampaignDurationDialog.vue";

export default defineComponent({
  name: "CampaignView",
  mixins: [authorityMixin],
  components: {
    ExtendCampaignDurationDialog,
    OkCancelDialog,
    QAChangeCampaignStatusDialog,
    ApproveCampaignDialog,
    DeclineCampaignDialog,
    BaseCampaignReasonDialog,
    Headline,
    Status,
    TabLink,
    BackLink,
    CollapseTransition,
  },
  provide() {
    return {
      campaign: computed(() => (this.campaign.id ? this.campaign : {})),
      summary: computed(() =>
        this.campaign?.summary ? this.campaign.summary : {}
      ),
      state: computed(() => (this.state.nextStatuses ? this.state : {})),
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      campaignUpdatedSubscription: watchCampaignUpdate(this.id, () => {
        this.$notify.info(this.$t("campaign.updated"));
        this.fetchData(this.id);
      }),
      campaign: {} as CampaignItem,
      state: {} as BackOfficeFlowEntityStateCampaignStatus,
      campaignStatuses: CampaignStatus,
      allowedForExtendDuration: [
        CampaignStatus.FUNDRAISING_LIVE,
        CampaignStatus.FUNDRAISING_RESERVE,
        CampaignStatus.TEMPORARILY_SUSPENDED,
      ] as CampaignStatus[],
    };
  },
  async mounted() {
    await Promise.all([this.fetchData()]);
  },
  async beforeRouteLeave() {
    this.campaignUpdatedSubscription.unsubscribe();
  },
  methods: {
    async fetchData(id?: string) {
      id = id ?? this.id;
      await Promise.all([
        this.fetchCampaign(Number(id)),
        this.fetchState(Number(id)),
      ]);
    },
    async fetchCampaign(id: number) {
      this.campaign = await campaignApi.campaign({ id: id });
    },
    async fetchState(id: number) {
      this.state = await campaignApi.state({ id: id });
    },
    async cancelCampaign(form: BaseCampaignOperationRequest) {
      await campaignOperationApi.cancelCampaign({
        id: this.campaign.id,
        baseCampaignOperationRequest: form,
      });
    },
    async suspendCampaign(form: BaseCampaignOperationRequest) {
      await campaignOperationApi.suspendCampaign({
        id: this.campaign.id,
        baseCampaignOperationRequest: form,
      });
    },
    async waitingForData(form: BaseCampaignOperationRequest) {
      await campaignOperationApi.waitingForData({
        id: this.campaign.id,
        baseCampaignOperationRequest: form,
      });
    },
    async revertSuspendCampaign(form: BaseCampaignOperationRequest) {
      await campaignOperationApi.revertSuspendCampaign({
        id: this.campaign.id,
        baseCampaignOperationRequest: form,
      });
    },
    async revertCancelCampaign(form: BaseCampaignOperationRequest) {
      await campaignOperationApi.revertCancelCampaign({
        id: this.campaign.id,
        baseCampaignOperationRequest: form,
      });
    },
    async verifyBusiness() {
      await campaignOperationApi.verifyBusiness({
        id: this.campaign.id,
      });
    },
    async registrationFee() {
      await campaignOperationApi.waitingForRegistrationFeeCampaign({
        id: this.campaign.id,
      });
    },
    async manualDisbursement() {
      await campaignOperationApi.manualDisbursement({
        id: this.campaign.id,
      });
    },
    async disbursement() {
      await campaignOperationApi.disbursement({
        id: this.campaign.id,
      });
    },
    async spv() {
      await campaignOperationApi.spv({
        id: this.campaign.id,
      });
    },
    async fundraising() {
      await campaignOperationApi.fundraising({
        id: this.campaign.id,
      });
    },
    async signingAgreement() {
      await campaignOperationApi.signingAgreement({
        id: this.campaign.id,
      });
    },
  },
  watch: {
    async id() {
      await Promise.all([this.fetchData(this.id)]);
    },
  },
  computed: {
    businessOpened(): boolean {
      return this.$route.name
        ? this.$route.name.toString().startsWith("campaignBusiness")
        : false;
    },
    preScreeningOpened(): boolean {
      return this.$route.name
        ? this.$route.name.toString().startsWith("campaignPreScreening")
        : false;
    },
    contentOpened(): boolean {
      return this.$route.name
        ? this.$route.name.toString().startsWith("campaignContent")
        : false;
    },
    ...mapState("feature", ["qaEnabled"]),
  },
});
