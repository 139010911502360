/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ForgotPasswordForm,
    ForgotPasswordFormFromJSON,
    ForgotPasswordFormToJSON,
    LoginRequest,
    LoginRequestFromJSON,
    LoginRequestToJSON,
    PasswordRecoveryForm,
    PasswordRecoveryFormFromJSON,
    PasswordRecoveryFormToJSON,
    RefreshTokenRequest,
    RefreshTokenRequestFromJSON,
    RefreshTokenRequestToJSON,
    RegistrationForm,
    RegistrationFormFromJSON,
    RegistrationFormToJSON,
    ResetPasswordRequest,
    ResetPasswordRequestFromJSON,
    ResetPasswordRequestToJSON,
    TokenResponse,
    TokenResponseFromJSON,
    TokenResponseToJSON,
} from '../models';

export interface CheckRecoveryOtpRequest {
    code: string;
}

export interface CheckRegistrationOtpRequest {
    code: string;
}

export interface ConfirmPasswordRecoveryRequest {
    code: string;
    passwordRecoveryForm: PasswordRecoveryForm;
}

export interface ConfirmRegistrationRequest {
    code: string;
    registrationForm: RegistrationForm;
}

export interface LoginOperationRequest {
    loginRequest: LoginRequest;
}

export interface PasswordRecoveryRequestRequest {
    forgotPasswordForm: ForgotPasswordForm;
}

export interface RefreshRequest {
    refreshTokenRequest: RefreshTokenRequest;
}

export interface ResetPasswordOperationRequest {
    resetPasswordRequest: ResetPasswordRequest;
}

/**
 * 
 */
export class AuthControllerApi extends runtime.BaseAPI {

    /**
     */
    async checkRecoveryOtpRaw(requestParameters: CheckRecoveryOtpRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling checkRecoveryOtp.');
        }

        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/password/recovery/otp`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async checkRecoveryOtp(requestParameters: CheckRecoveryOtpRequest): Promise<void> {
        await this.checkRecoveryOtpRaw(requestParameters);
    }

    /**
     */
    async checkRegistrationOtpRaw(requestParameters: CheckRegistrationOtpRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling checkRegistrationOtp.');
        }

        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/registration/otp`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async checkRegistrationOtp(requestParameters: CheckRegistrationOtpRequest): Promise<void> {
        await this.checkRegistrationOtpRaw(requestParameters);
    }

    /**
     */
    async confirmPasswordRecoveryRaw(requestParameters: ConfirmPasswordRecoveryRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling confirmPasswordRecovery.');
        }

        if (requestParameters.passwordRecoveryForm === null || requestParameters.passwordRecoveryForm === undefined) {
            throw new runtime.RequiredError('passwordRecoveryForm','Required parameter requestParameters.passwordRecoveryForm was null or undefined when calling confirmPasswordRecovery.');
        }

        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/auth/password/recovery`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PasswordRecoveryFormToJSON(requestParameters.passwordRecoveryForm),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async confirmPasswordRecovery(requestParameters: ConfirmPasswordRecoveryRequest): Promise<void> {
        await this.confirmPasswordRecoveryRaw(requestParameters);
    }

    /**
     */
    async confirmRegistrationRaw(requestParameters: ConfirmRegistrationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling confirmRegistration.');
        }

        if (requestParameters.registrationForm === null || requestParameters.registrationForm === undefined) {
            throw new runtime.RequiredError('registrationForm','Required parameter requestParameters.registrationForm was null or undefined when calling confirmRegistration.');
        }

        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/auth/registration`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegistrationFormToJSON(requestParameters.registrationForm),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async confirmRegistration(requestParameters: ConfirmRegistrationRequest): Promise<void> {
        await this.confirmRegistrationRaw(requestParameters);
    }

    /**
     */
    async loginRaw(requestParameters: LoginOperationRequest): Promise<runtime.ApiResponse<TokenResponse>> {
        if (requestParameters.loginRequest === null || requestParameters.loginRequest === undefined) {
            throw new runtime.RequiredError('loginRequest','Required parameter requestParameters.loginRequest was null or undefined when calling login.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/auth/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginRequestToJSON(requestParameters.loginRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenResponseFromJSON(jsonValue));
    }

    /**
     */
    async login(requestParameters: LoginOperationRequest): Promise<TokenResponse> {
        const response = await this.loginRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async passwordRecoveryRequestRaw(requestParameters: PasswordRecoveryRequestRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.forgotPasswordForm === null || requestParameters.forgotPasswordForm === undefined) {
            throw new runtime.RequiredError('forgotPasswordForm','Required parameter requestParameters.forgotPasswordForm was null or undefined when calling passwordRecoveryRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/auth/password/recovery/request`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ForgotPasswordFormToJSON(requestParameters.forgotPasswordForm),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async passwordRecoveryRequest(requestParameters: PasswordRecoveryRequestRequest): Promise<void> {
        await this.passwordRecoveryRequestRaw(requestParameters);
    }

    /**
     */
    async refreshRaw(requestParameters: RefreshRequest): Promise<runtime.ApiResponse<TokenResponse>> {
        if (requestParameters.refreshTokenRequest === null || requestParameters.refreshTokenRequest === undefined) {
            throw new runtime.RequiredError('refreshTokenRequest','Required parameter requestParameters.refreshTokenRequest was null or undefined when calling refresh.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/auth/refreshToken`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RefreshTokenRequestToJSON(requestParameters.refreshTokenRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenResponseFromJSON(jsonValue));
    }

    /**
     */
    async refresh(requestParameters: RefreshRequest): Promise<TokenResponse> {
        const response = await this.refreshRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async resetPasswordRaw(requestParameters: ResetPasswordOperationRequest): Promise<runtime.ApiResponse<TokenResponse>> {
        if (requestParameters.resetPasswordRequest === null || requestParameters.resetPasswordRequest === undefined) {
            throw new runtime.RequiredError('resetPasswordRequest','Required parameter requestParameters.resetPasswordRequest was null or undefined when calling resetPassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/auth/resetPassword`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordRequestToJSON(requestParameters.resetPasswordRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenResponseFromJSON(jsonValue));
    }

    /**
     */
    async resetPassword(requestParameters: ResetPasswordOperationRequest): Promise<TokenResponse> {
        const response = await this.resetPasswordRaw(requestParameters);
        return await response.value();
    }

}
