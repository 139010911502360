
import { defineComponent } from "vue";
import { NoteRequest, PageNoteItem } from "@/api";
import Notes from "@/views/components/Notes.vue";
import { authorityMixin, PageRequest } from "@/util";
import { campaignApi } from "@/resources";
import { mapState } from "vuex";

export default defineComponent({
  name: "CampaignReviewNotes",
  components: { Notes },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  mixins: [authorityMixin],
  computed: {
    ...mapState("account", ["profile"]),
  },
  methods: {
    loadNotes(pageRequest: PageRequest): Promise<PageNoteItem> {
      return campaignApi.campaignNotes({
        id: Number(this.id),
        ...pageRequest,
      });
    },
    createNote(noteRequest: NoteRequest): Promise<void> {
      return campaignApi.createCampaignNote({
        id: Number(this.id),
        noteRequest: noteRequest,
      });
    },
    deleteNote(noteId: number): Promise<void> {
      return campaignApi.deleteCampaignNote({
        id: noteId,
      });
    },
    updateNote(noteId: number, noteRequest: NoteRequest) {
      return campaignApi.updateCampaignNote({
        id: noteId,
        noteRequest: noteRequest,
      });
    },
  },
});
