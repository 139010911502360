<template>
  <div class="container ms-0 mb-0">
    <Attachments
      :attachments="attachments"
      :load-attachment="loadAttachment"
    ></Attachments>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { AttachmentListItem, CampaignAttachmentType } from "@/api";
import { dealApi } from "@/resources";
import Attachments from "@/views/components/Attachments.vue";

export default defineComponent({
  name: "DealAttachments",
  components: { Attachments },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      attachments: [] as AttachmentListItem[],
    };
  },
  async mounted() {
    await this.fetchAttachments();
  },
  methods: {
    async fetchAttachments() {
      this.attachments = await dealApi.dealCampaignAttachments({
        id: Number(this.id),
        type: CampaignAttachmentType.SPV,
      });
    },
    async loadAttachment(attachmentId: number): Promise<Blob> {
      return await dealApi.dealCampaignAttachment({
        id: Number(this.id),
        attachmentId: attachmentId,
      });
    },
  },
});
</script>
