
import { defineComponent, inject } from "vue";
import { CampaignItem, PageInvestmentSummaryItem } from "@/api";
import { authorityMixin, PageRequest } from "@/util";
import InvestmentSummary from "@/views/components/InvestmentSummary.vue";
import { campaignApi } from "@/resources";

export default defineComponent({
  name: "CampaignGeneralInfo",
  mixins: [authorityMixin],
  components: { InvestmentSummary },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      campaign: inject("campaign") as CampaignItem,
      investmentSummaryPage: {} as PageInvestmentSummaryItem,
      investmentSummaryPageRequest: {} as PageRequest,
    };
  },
  methods: {
    async fetchInvestmentSummary() {
      this.investmentSummaryPage = await campaignApi.campaignInvestments({
        id: Number(this.id),
        ...this.investmentSummaryPageRequest,
      });
    },
  },
});
