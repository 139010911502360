/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IdDocumentType,
    IdDocumentTypeFromJSON,
    IdDocumentTypeFromJSONTyped,
    IdDocumentTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface LegalOwnerItem
 */
export interface LegalOwnerItem {
    /**
     * 
     * @type {string}
     * @memberof LegalOwnerItem
     */
    address: string;
    /**
     * 
     * @type {Date}
     * @memberof LegalOwnerItem
     */
    birthDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof LegalOwnerItem
     */
    companyRegistrationNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof LegalOwnerItem
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof LegalOwnerItem
     */
    idNumber?: string;
    /**
     * 
     * @type {IdDocumentType}
     * @memberof LegalOwnerItem
     */
    idType?: IdDocumentType;
    /**
     * 
     * @type {string}
     * @memberof LegalOwnerItem
     */
    incorporation: string;
    /**
     * 
     * @type {string}
     * @memberof LegalOwnerItem
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof LegalOwnerItem
     */
    percent: number;
    /**
     * 
     * @type {string}
     * @memberof LegalOwnerItem
     */
    residence: string;
}

export function LegalOwnerItemFromJSON(json: any): LegalOwnerItem {
    return LegalOwnerItemFromJSONTyped(json, false);
}

export function LegalOwnerItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): LegalOwnerItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': json['address'],
        'birthDate': !exists(json, 'birthDate') ? undefined : (new Date(json['birthDate'])),
        'companyRegistrationNumber': !exists(json, 'companyRegistrationNumber') ? undefined : json['companyRegistrationNumber'],
        'id': json['id'],
        'idNumber': !exists(json, 'idNumber') ? undefined : json['idNumber'],
        'idType': !exists(json, 'idType') ? undefined : IdDocumentTypeFromJSON(json['idType']),
        'incorporation': json['incorporation'],
        'name': json['name'],
        'percent': json['percent'],
        'residence': json['residence'],
    };
}

export function LegalOwnerItemToJSON(value?: LegalOwnerItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': value.address,
        'birthDate': value.birthDate === undefined ? undefined : (value.birthDate.toISOString().substr(0,10)),
        'companyRegistrationNumber': value.companyRegistrationNumber,
        'id': value.id,
        'idNumber': value.idNumber,
        'idType': IdDocumentTypeToJSON(value.idType),
        'incorporation': value.incorporation,
        'name': value.name,
        'percent': value.percent,
        'residence': value.residence,
    };
}


