export enum Authority {
  CAMPAIGN_ALL_STATUSES_VIEW = "campaign:allStatuses:view",
  CAMPAIGN_READ = "campaign:read",
  CAMPAIGN_WRITE = "campaign:write",
  CAMPAIGN_COLLATERAL_REVIEW = "campaign:collateralReview",
  CAMPAIGN_FUNDRAISER_VERIFICATION = "campaign:fundraiserVerification",
  CAMPAIGN_CAMPAIGN_VERIFICATION = "campaign:campaignVerification",
  CAMPAIGN_OPERATION_WRITE = "campaign:operation:write",
  CAMPAIGN_OPERATION_MANUAL_DISBURSEMENT = "campaign:operation:manualDisbursement",
  //application statuses
  CAMPAIGN_DRAFT_VIEW = "campaign:DRAFT:view",
  CAMPAIGN_WAITING_FOR_REGISTRATION_FEE_VIEW = "campaign:WAITING_FOR_REGISTRATION_FEE:view",
  CAMPAIGN_ACTIVE_VIEW = "campaign:ACTIVE:view",
  CAMPAIGN_CANCELLATION_REQUEST_VIEW = "campaign:CANCELLATION_REQUEST:view",
  CAMPAIGN_CANCELED_VIEW = "campaign:CANCELED:view",
  CAMPAIGN_BUSINESS_VERIFICATION_VIEW = "campaign:BUSINESS_VERIFICATION:view",
  CAMPAIGN_CAMPAIGN_VERIFICATION_VIEW = "campaign:CAMPAIGN_VERIFICATION:view",
  CAMPAIGN_CAMPAIGN_COLLATERAL_REVIEW_VIEW = "campaign:CAMPAIGN_COLLATERAL_REVIEW:view",
  CAMPAIGN_WAITING_FOR_DATA_VIEW = "campaign:WAITING_FOR_DATA:view",
  CAMPAIGN_DECLINED_VIEW = "campaign:DECLINED:view",
  CAMPAIGN_ABANDONED_VIEW = "campaign:ABANDONED:view",
  CAMPAIGN_APPROVED_VIEW = "campaign:APPROVED:view",
  CAMPAIGN_SIGNING_CAMPAIGN_AGREEMENT_VIEW = "campaign:SIGNING_CAMPAIGN_AGREEMENT:view",
  CAMPAIGN_FUNDRAISING_RESERVE_VIEW = "campaign:FUNDRAISING_RESERVE:view",
  CAMPAIGN_FUNDRAISING_LIVE_VIEW = "campaign:FUNDRAISING_LIVE:view",
  CAMPAIGN_RECONCILIATION_VIEW = "campaign:CAMPAIGN_RECONCILIATION:view",
  CAMPAIGN_FAIL_VIEW = "campaign:CAMPAIGN_FAIL:view",
  CAMPAIGN_TEMPORARILY_SUSPENDED_VIEW = "campaign:TEMPORARILY_SUSPENDED:view",
  CAMPAIGN_UNSUCCESSFUL_VIEW = "campaign:UNSUCCESSFUL:view",
  CAMPAIGN_SUCCESSFUL_VIEW = "campaign:SUCCESSFUL:view",
  CAMPAIGN_READY_FOR_DISBURSEMENT_VIEW = "campaign:READY_FOR_DISBURSEMENT:view",
  CAMPAIGN_DISBURSEMENT_IN_PROGRESS_VIEW = "campaign:DISBURSEMENT_IN_PROGRESS:view",
  CAMPAIGN_DISBURSEMENT_ERROR_VIEW = "campaign:DISBURSEMENT_ERROR:view",
  CAMPAIGN_DISBURSEMENT_SUCCESS_VIEW = "campaign:DISBURSEMENT_SUCCESS:view",
  CAMPAIGN_SPV_SET_UP_VIEW = "campaign:SPV_SET_UP:view",

  ROLE_READ = "role:read",
  ROLE_WRITE = "role:write",

  USER_READ = "user:read",
  USER_WRITE = "user:write",

  INVESTOR_READ = "investor:read",
  INVESTMENT_READ = "investment:read",
  INVESTMENT_WRITE = "investment:write",
  TRANSACTION_READ = "transaction:read",
  TRANSACTION_WRITE = "transaction:write",
  TRANSACTION_BATCH = "transaction:batch",
  INVESTMENT_STATUS_CHANGE = "investment:statusChange",
  INVESTMENT_MANUAL_REFUND = "investment:manualRefund",
  INVESTMENT_INVESTOR_VERIFICATION = "investment:investorVerification",
  DEAL_READ = "deal:read",
  BUSINESS_READ = "business:read",
  PRE_SCREENING_READ = "pre_screening:read",
  PRE_SCREENING_WRITE = "pre_screening:write",
  PRE_SCREENING_OPERATION_WRITE = "pre_screening:operation:write",

  SETTINGS_READ = "settings:read",
  SETTINGS_WRITE = "settings:write",
  SETTINGS_ACCOUNT_DETAILS = "settings:accountDetails",
  SETTINGS_EXCHANGE_RATES = "settings:exchangeRates",

  ACCREDITATION_READ = "accreditation:read",
  ACCREDITATION_OPERATION_WRITE = "accreditation:operation:write",
}
