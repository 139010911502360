/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BatchStatus,
    BatchStatusFromJSON,
    BatchStatusFromJSONTyped,
    BatchStatusToJSON,
    IdNameEntry,
    IdNameEntryFromJSON,
    IdNameEntryFromJSONTyped,
    IdNameEntryToJSON,
} from './';

/**
 * 
 * @export
 * @interface BackOfficeBatchListItem
 */
export interface BackOfficeBatchListItem {
    /**
     * 
     * @type {Date}
     * @memberof BackOfficeBatchListItem
     */
    createdAt: Date;
    /**
     * 
     * @type {IdNameEntry}
     * @memberof BackOfficeBatchListItem
     */
    createdBy: IdNameEntry;
    /**
     * 
     * @type {string}
     * @memberof BackOfficeBatchListItem
     */
    error?: string;
    /**
     * 
     * @type {string}
     * @memberof BackOfficeBatchListItem
     */
    fileName?: string;
    /**
     * 
     * @type {number}
     * @memberof BackOfficeBatchListItem
     */
    id: number;
    /**
     * 
     * @type {BatchStatus}
     * @memberof BackOfficeBatchListItem
     */
    status: BatchStatus;
}

export function BackOfficeBatchListItemFromJSON(json: any): BackOfficeBatchListItem {
    return BackOfficeBatchListItemFromJSONTyped(json, false);
}

export function BackOfficeBatchListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): BackOfficeBatchListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['createdAt'])),
        'createdBy': IdNameEntryFromJSON(json['createdBy']),
        'error': !exists(json, 'error') ? undefined : json['error'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'id': json['id'],
        'status': BatchStatusFromJSON(json['status']),
    };
}

export function BackOfficeBatchListItemToJSON(value?: BackOfficeBatchListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': (value.createdAt.toISOString()),
        'createdBy': IdNameEntryToJSON(value.createdBy),
        'error': value.error,
        'fileName': value.fileName,
        'id': value.id,
        'status': BatchStatusToJSON(value.status),
    };
}


