
import { defineComponent } from "vue";
import { Country, RoleOption, UserStatus } from "@/api";
import { Filter } from "@/views/user/backoffice/UserList.vue";
import { InputLength } from "@/util/validation";
import { roleApi } from "@/resources";

export default defineComponent({
  emits: ["update:userFilter", "submit"],
  name: "UserFilter",
  props: ["userFilter"],
  data() {
    return {
      residenceCountryOptions: Country,
      statuses: UserStatus,
      roles: [] as RoleOption[],
      isFormValid: true,
      filter: {} as Filter,
      rules: {
        fullname: [new InputLength(100)],
      },
    };
  },
  async mounted() {
    this.filter = this.userFilter;
    this.roles = await roleApi.roleOptionsForFilter();
  },
  methods: {
    onValidate(prop: string, isVal: boolean) {
      this.isFormValid = isVal;
    },
    resetFilter() {
      this.filter = {} as Filter;
      this.$emit("update:userFilter", this.filter);
      this.$emit("submit");
    },
  },
});
