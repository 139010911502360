<template>
  <div class="row" v-if="hasAuthority(authorities.PRE_SCREENING_READ)">
    <div class="tab-content">
      <div class="tab-pane active">
        <router-view @update="fetchPreScreeningData"></router-view>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { authorityMixin } from "@/util";
import { preScreeningApi } from "@/resources";
import { PreScreeningItem, PreScreeningQuizItem } from "@/api";

export default defineComponent({
  name: "CampaignPreScreening",
  mixins: [authorityMixin],
  props: {
    preScreeningId: {
      type: String,
      required: true,
    },
  },
  provide() {
    return {
      preScreeningId: computed(() => this.preScreeningId),
      preScreening: computed(() =>
        this.preScreening.id ? this.preScreening : {}
      ),
      preScreeningQuiz: computed(() =>
        this.preScreeningQuiz.quiz ? this.preScreeningQuiz : {}
      ),
    };
  },
  data() {
    return {
      preScreening: {} as PreScreeningItem,
      preScreeningQuiz: {} as PreScreeningQuizItem,
    };
  },
  async mounted() {
    await this.fetchPreScreeningData();
  },
  methods: {
    async fetchPreScreeningData() {
      await Promise.all([
        this.fetchPreScreening(),
        this.fetchPreScreeningQuiz(),
      ]);
    },
    async fetchPreScreening() {
      this.preScreening = await preScreeningApi.preScreening({
        id: Number(this.preScreeningId),
      });
    },
    async fetchPreScreeningQuiz() {
      this.preScreeningQuiz = await preScreeningApi.preScreeningQuiz({
        id: Number(this.preScreeningId),
      });
    },
  },
});
</script>
