
import { defineComponent } from "vue";
import { CampaignDetailsData, CampaignDetailsItem } from "@/api";
import { authorityMixin } from "@/util";
import { campaignApi } from "@/resources";
import {
  DecimalFormat,
  ElementForm,
  InputLength,
  InputSelectOptionLength,
  integerFormat,
  numberFormat,
  NumberMin,
} from "@/util/validation";
import { isConstraintViolation, isProblem } from "@/resources/problem";

export default defineComponent({
  name: "CampaignDetails",
  mixins: [authorityMixin],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      details: {} as CampaignDetailsItem,
      form: {} as CampaignDetailsData,
      rules: {
        name: new InputLength(100),
        description: new InputLength(1000),
        technologies: new InputSelectOptionLength(100),
        highlights: new InputSelectOptionLength(100),
        valuation: [numberFormat, new NumberMin(0.01)],
        securityType: new InputLength(100),
        impacts: new InputSelectOptionLength(100),
        businessModels: new InputSelectOptionLength(100),
        previouslyFundedAmount: [new DecimalFormat(17, 2), new NumberMin(0.01)],
        priceForShare: [new DecimalFormat(17, 2), new NumberMin(0.01)],
        fundraiserWebsite: new InputLength(100),
        fundraiserFoundingYear: [integerFormat, new NumberMin(1)],
      },
      isFormValid: true,
      editable: false,
      technologies: [
        "AI_ML",
        "APPS_ECOMMERCE",
        "AR_VR",
        "BIG_DATA",
        "BIOTECHNOLOGY",
        "BLOCKCHAIN_WEB3",
        "DEVELOPMENT_TOOLS",
        "ELECTRIC_VEHICLES",
        "GAMES",
        "HARDWARE",
        "IOT",
        "ROBOTICS",
        "SMART_DEVICES",
        "WEARABLES",
      ],
      highlights: [
        "RAISED_LESS_250K",
        "RAISED_MORE_250K",
        "RAISED_MORE_1M",
        "RAISED_MORE_5M",
        "RAISED_MORE_10M",
        "RAISED_MORE_25M",
        "EMPLOYEES_BETWEEN_0_10",
        "EMPLOYEES_BETWEEN_11_25",
        "EMPLOYEES_BETWEEN_26_50",
        "EMPLOYEES_BETWEEN_51_200",
        "EMPLOYEES_MORE_200",
        "BOOTSTRAPPED",
        "NOTABLE_ANGEL_BACKED",
        "POWER_FOUNDERS",
        "PROFITABLE",
        "VENTURE_BACKED",
        "SME_DEVELOPMENT_FUND_BACKED",
        "OMAN_TECHNOLOGY_FUND_BACKED",
      ],
      businessModels: [
        "B2B",
        "B2C",
        "D2C",
        "MARKETPLACE",
        "SAAS",
        "SUBSCRIPTION",
      ],
      impacts: [
        "LOCAL_FOUNDERS",
        "WOMEN_FOUNDERS",
        "DIVERSE_FOUNDERS",
        "MINORITY_FOUNDER",
        "SOCIAL_IMPACT",
        "SUSTAINABILITY",
      ],
    };
  },
  async mounted() {
    await this.fetchData();
  },
  watch: {
    details() {
      Object.assign(this.form, this.details);
    },
  },
  methods: {
    async fetchData() {
      return Promise.all([this.fetchDetails()]);
    },
    async fetchDetails() {
      this.details = await campaignApi.campaignDetails({
        id: Number(this.id),
      });
    },
    cancel() {
      Object.assign(this.form, this.details);
      this.editable = false;
      const form = this.$refs["form"] as ElementForm;
      form.clearValidate();
    },
    onValidate(prop: string, isVal: boolean) {
      this.isFormValid = isVal;
    },
    edit() {
      this.editable = true;
      Object.assign(this.form, this.details);
    },
    async submit() {
      const form = this.$refs["form"] as ElementForm;
      if (await form.validate()) {
        try {
          await campaignApi.updateCampaignDetails({
            id: Number(this.id),
            campaignDetailsData: this.form,
          });
          Object.assign(this.details, this.form);
          this.editable = false;
          await this.fetchData();
          this.$emit("update");
        } catch (error) {
          if (isConstraintViolation(error)) {
            error.violations.forEach((violation) =>
              this.$notify.error(this.$t(violation.message))
            );
          } else if (isProblem(error)) {
            this.$notify.error(this.$t(error.detail));
          }
        }
      }
    },
  },
});
