const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const valueFormatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
  minimumFractionDigits: 2,
});

const omrValueFormatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
  minimumFractionDigits: 3,
});

export function currency(value?: number): string {
  if (value == null) {
    return "-";
  }
  return currencyFormatter.format(value);
}

export function currencyValue(value?: number): string {
  if (value == null) {
    return "-";
  }
  return valueFormatter.format(value);
}

export function omrCurrencyValue(value?: number): string {
  if (value == null) {
    return "-";
  }
  return omrValueFormatter.format(value);
}
