
import { defineComponent, inject } from "vue";
import { Legal, PageBankAccountListItem } from "@/api";
import BankAccounts from "@/views/components/BankAccounts.vue";
import { businessApi } from "@/resources";
import { PageRequest } from "@/util";

export default defineComponent({
  name: "BusinessBankDetails",
  components: { BankAccounts },
  data() {
    return {
      businessId: inject("businessId") as number,
      legal: inject("legal") as Legal,
      bankAccountsPage: {} as PageBankAccountListItem,
      bankAccountsPageRequest: {} as PageRequest,
    };
  },
  watch: {
    async legal() {
      await Promise.all([this.fetchBankAccountsData()]);
    },
  },
  methods: {
    async fetchBankAccountsData() {
      this.bankAccountsPage = await businessApi.bankAccounts({
        id: this.businessId,
        ...this.bankAccountsPageRequest,
      });
    },
  },
});
