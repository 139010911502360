
import { computed, defineComponent } from "vue";
import TabLink from "@/components/TabLink.vue";
import Headline from "@/components/Headline.vue";
import { investmentApi } from "@/resources";
import {
  BackOfficeInvestmentState,
  BaseInvestmentStatusChangeRequest,
  Investment,
  InvestmentStatus,
  TransactionProcessingType,
} from "@/api";
import BackLink from "@/components/BackLink.vue";
import Status from "@/components/Status.vue";
import { authorityMixin } from "@/util";
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";
import OkCancelDialog from "@/views/components/OkCancelDialog.vue";
import DeclineInvestmentDialog from "@/views/investment/dialogs/DeclineInvestmentDialog.vue";
import BaseInvestmentReasonDialog from "@/views/investment/dialogs/BaseInvestmentReasonDialog.vue";
import { watchInvestmentUpdate } from "@/util/ws";

export default defineComponent({
  name: "InvestmentView",
  mixins: [authorityMixin],
  components: {
    BaseInvestmentReasonDialog,
    Headline,
    TabLink,
    BackLink,
    Status,
    CollapseTransition,
    OkCancelDialog,
    DeclineInvestmentDialog,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    investorId: {
      type: String,
      required: false,
    },
  },
  provide() {
    return {
      investment: computed(() => this.investment),
    };
  },
  data() {
    return {
      transactionProcessingType: TransactionProcessingType.AUTOMATED as TransactionProcessingType,
      investment: {} as Investment,
      investmentState: {} as BackOfficeInvestmentState,
      investmentStatuses: InvestmentStatus,
      transactionProcessing: TransactionProcessingType,
      investmentUpdateSubscription: watchInvestmentUpdate(this.id, () => {
        this.$notify.info(this.$t("investment.updated"));
        this.fetchData(this.id);
      }),
    };
  },
  async mounted() {
    await this.fetchData();
  },
  async beforeRouteLeave() {
    this.investmentUpdateSubscription.unsubscribe();
  },
  methods: {
    async fetchInvestment() {
      this.investment = await investmentApi.investment({ id: Number(this.id) });
    },
    async fetchInvestmentState() {
      this.investmentState = await investmentApi.getInvestmentState({
        id: Number(this.id),
      });
    },
    async fetchData() {
      await Promise.all([this.fetchInvestment(), this.fetchInvestmentState()]);
    },
    async submitInvestment() {
      await investmentApi.submit({
        id: this.investment.id,
      });
    },
    async platformFee() {
      await investmentApi.waitingForPlatformFee({
        id: this.investment.id,
      });
    },
    async signTermSheet() {
      await investmentApi.signTermSheet({
        id: this.investment.id,
      });
    },
    async confirmTermSigning() {
      await investmentApi.confirmTermSigning({
        id: this.investment.id,
        body: this.transactionProcessingType,
      });
    },
    async processInvestment() {
      await investmentApi.process({
        id: this.investment.id,
      });
    },
    async requestDataUpdate(form: BaseInvestmentStatusChangeRequest) {
      await investmentApi.requestDataUpdate({
        id: this.investment.id,
        baseInvestmentStatusChangeRequest: form,
      });
    },
    async approveInvestment() {
      await investmentApi.approveInvestment({
        id: this.investment.id,
      });
    },
    async refundInvestment() {
      await investmentApi.refund({
        id: this.investment.id,
      });
    },
  },
  computed: {
    investorOpened(): boolean {
      return this.$route.name
        ? this.$route.name.toString().startsWith("investmentInvestor")
        : false;
    },
  },
});
