
import { defineComponent, inject } from "vue";
import { Address, Legal } from "@/api";
import LegalContact from "@/views/components/LegalContact.vue";
import ContactPerson from "@/views/components/ContactPerson.vue";
import PermanentAddress from "@/views/components/PermanentAddress.vue";
import CurrentAddress from "@/views/components/CurrentAddress.vue";

export default defineComponent({
  name: "BusinessContactInfo",
  components: {
    CurrentAddress,
    ContactPerson,
    LegalContact,
    PermanentAddress,
  },
  data() {
    return {
      legal: inject("legal") as Legal,
      address: inject("address") as Address,
    };
  },
});
