<template>
  <div class="container ms-0 mb-0" v-if="investment.id">
    <Notes
      :load-notes="loadReview"
      :delete-disabled="(note) => !hasAuthority(authorities.INVESTMENT_WRITE)"
      :delete-note="deleteReview"
      :translation-prefix="'review'"
    ></Notes>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { Investment, NoteItem, PageNoteItem } from "@/api";
import { authorityMixin } from "@/util";
import Notes from "@/views/components/Notes.vue";
import { investmentApi } from "@/resources";

export default defineComponent({
  name: "InvestmentCampaignReview",
  components: { Notes },
  mixins: [authorityMixin],
  data() {
    return {
      investment: inject("investment") as Investment,
    };
  },
  methods: {
    loadReview(): Promise<PageNoteItem> {
      return Promise.resolve({
        content: this.investment.review
          ? [this.investment.review]
          : ([] as NoteItem[]),
        last: true,
      } as PageNoteItem);
    },
    async deleteReview() {
      await investmentApi.deleteReview({
        id: this.investment.id,
      });
      this.$emit("update");
    },
  },
});
</script>
