import { Module } from "vuex";
import {
  Profile,
  TokenResponse,
  TokenResponseFromJSON,
  TokenResponseToJSON,
} from "@/api";
import { State } from "@/store/index";

export const key = "token";

export interface AccountState {
  token?: TokenResponse;
  profile?: Profile;
}

function loadToken(): TokenResponse | undefined {
  const tokenString = localStorage.getItem(key);
  if (tokenString == null) {
    return undefined;
  }
  const tokenResponse = TokenResponseFromJSON(JSON.parse(tokenString));
  return tokenResponse.expireAt < new Date() ? undefined : tokenResponse;
}

export const account: Module<AccountState, State> = {
  namespaced: true,
  state() {
    return {
      token: loadToken(),
      profile: {} as Profile,
    } as AccountState;
  },
  getters: {
    isAuthenticated({ token }): boolean {
      return token != null && +token.expireAt > Date.now();
    },
    hasAuthority: ({ token }) => (authority: string): boolean => {
      return token != null && token.authorities.includes(authority);
    },
    authorities({ token }): string[] | undefined {
      return token?.authorities;
    },
  },
  mutations: {
    setToken(state: AccountState, token: TokenResponse) {
      localStorage.setItem(key, JSON.stringify(TokenResponseToJSON(token)));
      state.token = token;
    },
    deleteToken(state: AccountState) {
      localStorage.removeItem(key);
      delete state.token;
    },
    setProfile(state: AccountState, profile: Profile) {
      state.profile = profile;
    },
  },
};
