<template>
  <div class="container ms-0 mb-0" v-if="verification">
    <el-form ref="comment" :model="commentForm">
      <div class="row mb-2">
        <div class="col">
          <el-form-item prop="comment">
            <el-input
              :disabled="!editable"
              :maxlength="1000"
              :placeholder="$t('campaign.campaignVerification.placeholder')"
              show-word-limit
              type="textarea"
              :autosize="{ minRows: 5 }"
              resize="vertical"
              v-model="commentForm.comment"
            />
          </el-form-item>
        </div>
        <div class="col">
          <el-button
            class="post-button"
            size="small"
            type="default"
            @click="edit"
            v-show="!editable"
            >{{ $t("edit") }}</el-button
          >
          <el-button
            size="small"
            style="margin-left: 0"
            @click="cancel"
            v-show="editable"
          >
            {{ $t("cancel") }}
          </el-button>
          <el-button
            size="small"
            type="primary"
            v-show="editable"
            @click="saveComment"
          >
            {{ $t("save") }}
          </el-button>
        </div>
      </div>
    </el-form>

    <div class="row mb-3">
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.campaignVerification.planAccepted") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.planAccepted"
            @decided="updateValue('planAccepted', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.campaignVerification.amountsAgreed") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.amountsAgreed"
            @decided="updateValue('amountsAgreed', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.campaignVerification.overRaiseAgreed") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.overRaiseAgreed"
            @decided="updateValue('overRaiseAgreed', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.campaignVerification.durationAgreed") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.durationAgreed"
            @decided="updateValue('durationAgreed', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.campaignVerification.extensionAgreed") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.extensionAgreed"
            @decided="updateValue('extensionAgreed', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.campaignVerification.rollingCloseAgreed") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.rollingCloseAgreed"
            @decided="updateValue('rollingCloseAgreed', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.campaignVerification.rewardAgreed") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.rewardAgreed"
            @decided="updateValue('rewardAgreed', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col-6">
          {{ $t("campaign.campaignVerification.rewardSigned") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.rewardSigned"
            @decided="updateValue('rewardSigned', $event)"
          />
        </div>
        <div class="col wrap-text">
          <el-date-picker
            v-model="rewardSignedDate"
            type="date"
            format="DD/MM/YYYY"
            size="small"
            @change="updateDate('rewardSignedDate', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col-6">
          {{ $t("campaign.campaignVerification.campaignFeeReceived") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.campaignFeeReceived"
            @decided="updateValue('campaignFeeReceived', $event)"
          />
        </div>
        <div class="col wrap-text">
          <el-date-picker
            v-model="campaignFeeReceivedDate"
            type="date"
            format="DD/MM/YYYY"
            size="small"
            @change="updateDate('campaignFeeReceivedDate', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.campaignVerification.termsSigned") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.termsSigned"
            @decided="updateValue('termsSigned', $event)"
          />
        </div>
      </div>
      <div class="row ms-0 mb-3">
        <div class="col">
          {{ $t("campaign.campaignVerification.investmentNoteUploaded") }}
        </div>
        <div class="col wrap-text">
          <Decision
            v-model:checked="verification.investmentNoteUploaded"
            @decided="updateValue('investmentNoteUploaded', $event)"
          />
        </div>
      </div>
    </div>
    <!--    -->
    <div class="row mb-3">
      <div class="row ms-0 mb-lg-0">
        <div class="col-6 fw-bolder">
          {{ $t("campaign.campaignVerification.verificationCompleted") }}
        </div>
        <div class="col wrap-text">
          <Decision
            :checked="verification.verificationCompleted"
            :disabled="
              !Campaign.CAMPAIGN_VERIFICATION_STATUSES.includes(campaign.status)
            "
            @decided="updateValue('verificationCompleted', $event)"
          />
        </div>
        <div class="col wrap-text">
          <el-date-picker
            v-model="verification.verificationDate"
            type="date"
            format="DD/MM/YYYY"
            size="small"
            disabled
          />
        </div>
      </div>
    </div>
    <el-button
      @click="completeCampaignVerification"
      :disabled="!verification.verificationCompleted"
      type="primary"
      v-if="
        hasAuthority(authorities.CAMPAIGN_OPERATION_WRITE) &&
        state.nextStatuses &&
        campaign.status !== campaignStatuses.CANCELLATION_REQUEST &&
        state.nextStatuses.includes(campaignStatuses.CAMPAIGN_COLLATERAL_REVIEW)
      "
    >
      {{ $t("campaign.campaignVerification.complete") }}
    </el-button>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import {
  BackOfficeFlowEntityStateCampaignStatus,
  CampaignItem,
  CampaignStatus,
  CampaignVerificationChecklist,
} from "@/api";
import { campaignApi, campaignOperationApi } from "@/resources";
import Decision from "@/components/Decision.vue";
import { isConstraintViolation, isProblem } from "@/resources/problem";
import { InputLength } from "@/util/validation";
import { authorityMixin, fixLocalDate } from "@/util";
import { Campaign } from "@/util/enum/campaign";

interface CommentForm {
  comment: string | undefined;
}

export default defineComponent({
  name: "CampaignCampaignVerification",
  computed: {
    Campaign() {
      return Campaign;
    },
  },
  mixins: [authorityMixin],
  components: { Decision },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      rules: {
        comment: [new InputLength(1000)],
      },
      verification: {} as CampaignVerificationChecklist,
      campaignStatuses: CampaignStatus,
      state: inject("state") as BackOfficeFlowEntityStateCampaignStatus,
      campaign: inject("campaign") as CampaignItem,
      editable: false,
      commentForm: {} as CommentForm,
      rewardSignedDate: undefined as Date | undefined,
      campaignFeeReceivedDate: undefined as Date | undefined,
    };
  },
  async mounted() {
    await this.fetchVerification();
  },
  methods: {
    async fetchVerification() {
      this.verification = await campaignApi.getCampaignVerificationChecklist({
        id: Number(this.id),
      });
      this.commentForm = { comment: this.verification.comment };
      this.rewardSignedDate = this.verification.rewardSignedDate;
      this.campaignFeeReceivedDate = this.verification.campaignFeeReceivedDate;
    },
    async updateValue(field: string, value: boolean | undefined) {
      try {
        if (
          value != undefined ||
          (value == undefined && !this.verification.verificationCompleted)
        ) {
          this.verification = await campaignApi.updateCampaignVerificationChecklist(
            {
              id: Number(this.id),
              campaignVerificationChecklist: {
                ...this.verification,
                [field]: value,
              },
            }
          );
        }
      } catch (error) {
        if (isConstraintViolation(error)) {
          error.violations.forEach((violation) =>
            this.$notify.error(this.$t(violation.message))
          );
        } else if (isProblem(error)) {
          this.$notify.error(this.$t(error.detail));
        }
      }
    },
    async saveComment() {
      try {
        this.verification = await campaignApi.updateCampaignVerificationChecklist(
          {
            id: Number(this.id),
            campaignVerificationChecklist: {
              ...this.verification,
              comment: this.commentForm.comment,
            },
          }
        );
        this.editable = false;
      } catch (e) {
        if (isConstraintViolation(e)) {
          e.violations.forEach((violation) =>
            this.$notify.error(this.$t(violation.message))
          );
        } else if (isProblem(e)) {
          this.$notify.error(this.$t(e.detail));
        }
      }
    },
    async completeCampaignVerification() {
      await campaignOperationApi.campaignCollateralReview({
        id: Number(this.id),
      });
    },
    edit() {
      this.editable = true;
    },
    cancel() {
      this.commentForm.comment = this.verification.comment;
      this.editable = false;
    },
    async updateDate(field: string, value: Date) {
      try {
        this.verification = await campaignApi.updateCampaignVerificationChecklist(
          {
            id: Number(this.id),
            campaignVerificationChecklist: {
              ...this.verification,
              [field]: value ? fixLocalDate(value) : undefined,
            },
          }
        );
      } catch (error) {
        if (isConstraintViolation(error)) {
          error.violations.forEach((violation) =>
            this.$notify.error(this.$t(violation.message))
          );
        } else if (isProblem(error)) {
          this.$notify.error(this.$t(error.detail));
        }
        this.rewardSignedDate = this.verification.rewardSignedDate;
        this.campaignFeeReceivedDate = this.verification.campaignFeeReceivedDate;
      }
    },
  },
});
</script>
