<template>
  <el-dropdown-item @click="dialogVisible = true">{{
    $t("user.doSuspend")
  }}</el-dropdown-item>

  <teleport to="body">
    <el-dialog :title="$t('user.askPortalUserSuspend')" v-model="dialogVisible">
      <template #footer>
        <el-button @click="dialogVisible = false">
          {{ $t("no") }}
        </el-button>
        <el-button type="primary" @click="deactivatePortalUser">
          {{ $t("yes") }}
        </el-button>
      </template>
    </el-dialog>
  </teleport>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { portalUserApi } from "@/resources";
import { isProblem } from "@/resources/problem";

export default defineComponent({
  name: "SuspendPortalUserDialog",
  emits: ["update"],
  props: ["id", "givenName", "lastName"],
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    async deactivatePortalUser() {
      try {
        await portalUserApi.deactivatePortalUser({
          id: this.id,
        });
        this.$notify.info(
          this.$t("user.suspend", {
            givenName: this.givenName,
            lastName: this.lastName,
          })
        );
        this.$emit("update");
        this.dialogVisible = false;
      } catch (e) {
        if (isProblem(e)) {
          this.$notify.error(e.detail ?? e.title);
        }
      }
    },
  },
});
</script>
