
import { defineComponent } from "vue";
import { ElementForm, InputLength } from "@/util/validation";
import { Filter } from "@/views/pre_screening/PreScreeningList.vue";
import {
  Country,
  PreScreeningDuplicateStatus,
  PreScreeningStatus,
} from "@/api";

export default defineComponent({
  emits: ["update:preScreeningFilter", "submit"],
  props: ["preScreeningFilter"],
  data() {
    return {
      filter: {} as Filter,
      statuses: [] as PreScreeningStatus[],
      duplicateStatuses: [] as PreScreeningDuplicateStatus[],
      countries: [] as Country[],
      isFormValid: true,
      rules: {
        companyName: [new InputLength(70)],
        contactEmail: [new InputLength(255)],
        contactName: [new InputLength(100)],
      },
    };
  },
  async mounted() {
    this.countries = Object.values(Country);
    this.statuses = Object.values(PreScreeningStatus);
    this.duplicateStatuses = Object.values(PreScreeningDuplicateStatus);
    this.filter = this.preScreeningFilter;
  },
  methods: {
    submit() {
      const form = this.$refs["filter-form"] as ElementForm;
      form.validate(async (valid) => {
        if (valid) {
          this.$emit("submit");
        }
      });
    },
    onValidate(prop: string, isVal: boolean) {
      this.isFormValid = isVal;
    },
    resetFilter() {
      this.filter = {} as Filter;
      this.$emit("update:preScreeningFilter", this.filter);
      this.$emit("submit");
    },
  },
});
