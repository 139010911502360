/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IdDocumentType,
    IdDocumentTypeFromJSON,
    IdDocumentTypeFromJSONTyped,
    IdDocumentTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface LegalMemberItem
 */
export interface LegalMemberItem {
    /**
     * 
     * @type {string}
     * @memberof LegalMemberItem
     */
    address?: string;
    /**
     * 
     * @type {Date}
     * @memberof LegalMemberItem
     */
    birthDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof LegalMemberItem
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof LegalMemberItem
     */
    idNumber?: string;
    /**
     * 
     * @type {IdDocumentType}
     * @memberof LegalMemberItem
     */
    idType?: IdDocumentType;
    /**
     * 
     * @type {string}
     * @memberof LegalMemberItem
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LegalMemberItem
     */
    nationality?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalMemberItem
     */
    position: string;
    /**
     * 
     * @type {string}
     * @memberof LegalMemberItem
     */
    residence: string;
}

export function LegalMemberItemFromJSON(json: any): LegalMemberItem {
    return LegalMemberItemFromJSONTyped(json, false);
}

export function LegalMemberItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): LegalMemberItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': !exists(json, 'address') ? undefined : json['address'],
        'birthDate': !exists(json, 'birthDate') ? undefined : (new Date(json['birthDate'])),
        'id': json['id'],
        'idNumber': !exists(json, 'idNumber') ? undefined : json['idNumber'],
        'idType': !exists(json, 'idType') ? undefined : IdDocumentTypeFromJSON(json['idType']),
        'name': json['name'],
        'nationality': !exists(json, 'nationality') ? undefined : json['nationality'],
        'position': json['position'],
        'residence': json['residence'],
    };
}

export function LegalMemberItemToJSON(value?: LegalMemberItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': value.address,
        'birthDate': value.birthDate === undefined ? undefined : (value.birthDate.toISOString().substr(0,10)),
        'id': value.id,
        'idNumber': value.idNumber,
        'idType': IdDocumentTypeToJSON(value.idType),
        'name': value.name,
        'nationality': value.nationality,
        'position': value.position,
        'residence': value.residence,
    };
}


