/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CampaignSettingsData
 */
export interface CampaignSettingsData {
    /**
     * 
     * @type {number}
     * @memberof CampaignSettingsData
     */
    fundraisingCommissionFee: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignSettingsData
     */
    registrationFee: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignSettingsData
     */
    spvFee: number;
}

export function CampaignSettingsDataFromJSON(json: any): CampaignSettingsData {
    return CampaignSettingsDataFromJSONTyped(json, false);
}

export function CampaignSettingsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CampaignSettingsData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fundraisingCommissionFee': json['fundraisingCommissionFee'],
        'registrationFee': json['registrationFee'],
        'spvFee': json['spvFee'],
    };
}

export function CampaignSettingsDataToJSON(value?: CampaignSettingsData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fundraisingCommissionFee': value.fundraisingCommissionFee,
        'registrationFee': value.registrationFee,
        'spvFee': value.spvFee,
    };
}


