/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AttachmentListItem,
    AttachmentListItemFromJSON,
    AttachmentListItemToJSON,
    BusinessItem,
    BusinessItemFromJSON,
    BusinessItemToJSON,
    LegalCommerceItem,
    LegalCommerceItemFromJSON,
    LegalCommerceItemToJSON,
    PageBankAccountListItem,
    PageBankAccountListItemFromJSON,
    PageBankAccountListItemToJSON,
    PageBusinessListItem,
    PageBusinessListItemFromJSON,
    PageBusinessListItemToJSON,
    PageLegalMemberItem,
    PageLegalMemberItemFromJSON,
    PageLegalMemberItemToJSON,
    PageLegalOwnerItem,
    PageLegalOwnerItemFromJSON,
    PageLegalOwnerItemToJSON,
    PageLegalPersonItem,
    PageLegalPersonItemFromJSON,
    PageLegalPersonItemToJSON,
} from '../models';

export interface AttachmentRequest {
    id: number;
    attachmentId: number;
}

export interface AttachmentsRequest {
    id: number;
}

export interface BankAccountsRequest {
    id: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface BusinessRequest {
    id: number;
}

export interface BusinessesRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
    name?: string;
    email?: string;
    registrationNumber?: string;
}

export interface CommerceRequest {
    id: number;
}

export interface MembersRequest {
    id: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface OwnersRequest {
    id: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface PersonsRequest {
    id: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

/**
 * 
 */
export class BackOfficeBusinessControllerApi extends runtime.BaseAPI {

    /**
     */
    async attachmentRaw(requestParameters: AttachmentRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling attachment.');
        }

        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId','Required parameter requestParameters.attachmentId was null or undefined when calling attachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/business/{id}/attachments/{attachmentId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async attachment(requestParameters: AttachmentRequest): Promise<Blob> {
        const response = await this.attachmentRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async attachmentsRaw(requestParameters: AttachmentsRequest): Promise<runtime.ApiResponse<Array<AttachmentListItem>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling attachments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/business/{id}/attachments`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AttachmentListItemFromJSON));
    }

    /**
     */
    async attachments(requestParameters: AttachmentsRequest): Promise<Array<AttachmentListItem>> {
        const response = await this.attachmentsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async bankAccountsRaw(requestParameters: BankAccountsRequest): Promise<runtime.ApiResponse<PageBankAccountListItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling bankAccounts.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/business/{id}/bankAccounts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageBankAccountListItemFromJSON(jsonValue));
    }

    /**
     */
    async bankAccounts(requestParameters: BankAccountsRequest): Promise<PageBankAccountListItem> {
        const response = await this.bankAccountsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async businessRaw(requestParameters: BusinessRequest): Promise<runtime.ApiResponse<BusinessItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling business.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/business/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BusinessItemFromJSON(jsonValue));
    }

    /**
     */
    async business(requestParameters: BusinessRequest): Promise<BusinessItem> {
        const response = await this.businessRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async businessesRaw(requestParameters: BusinessesRequest): Promise<runtime.ApiResponse<PageBusinessListItem>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        if (requestParameters.registrationNumber !== undefined) {
            queryParameters['registrationNumber'] = requestParameters.registrationNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/business`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageBusinessListItemFromJSON(jsonValue));
    }

    /**
     */
    async businesses(requestParameters: BusinessesRequest): Promise<PageBusinessListItem> {
        const response = await this.businessesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async commerceRaw(requestParameters: CommerceRequest): Promise<runtime.ApiResponse<LegalCommerceItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling commerce.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/business/{id}/commerce`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LegalCommerceItemFromJSON(jsonValue));
    }

    /**
     */
    async commerce(requestParameters: CommerceRequest): Promise<LegalCommerceItem> {
        const response = await this.commerceRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async membersRaw(requestParameters: MembersRequest): Promise<runtime.ApiResponse<PageLegalMemberItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling members.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/business/{id}/members`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageLegalMemberItemFromJSON(jsonValue));
    }

    /**
     */
    async members(requestParameters: MembersRequest): Promise<PageLegalMemberItem> {
        const response = await this.membersRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async ownersRaw(requestParameters: OwnersRequest): Promise<runtime.ApiResponse<PageLegalOwnerItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling owners.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/business/{id}/owners`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageLegalOwnerItemFromJSON(jsonValue));
    }

    /**
     */
    async owners(requestParameters: OwnersRequest): Promise<PageLegalOwnerItem> {
        const response = await this.ownersRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async personsRaw(requestParameters: PersonsRequest): Promise<runtime.ApiResponse<PageLegalPersonItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling persons.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/business/{id}/persons`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageLegalPersonItemFromJSON(jsonValue));
    }

    /**
     */
    async persons(requestParameters: PersonsRequest): Promise<PageLegalPersonItem> {
        const response = await this.personsRaw(requestParameters);
        return await response.value();
    }

}
