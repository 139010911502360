<template>
  <div class="container-auth">
    <div class="mt-4" v-if="!linkSent">
      <h2>
        {{ $t("auth.password.recoverPassword") }}
      </h2>
      <el-form
        class="mt-3 error-message-static"
        ref="form"
        :rules="rules"
        :model="forgotPasswordForm"
        status-icon
      >
        <el-form-item label="Email" prop="login">
          <el-input
            v-model="forgotPasswordForm.login"
            maxlength="100"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('user.birthDate')" prop="birthDate">
          <el-date-picker
            type="date"
            v-model="forgotPasswordForm.birthDate"
            class="w-100"
            format="DD/MM/YYYY"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <span class="invalid-feedback mb-2 d-block" v-if="errorMessage">{{
        errorMessage
      }}</span>
      <div class="mt-4">
        <el-button @click="$router.push('/')">{{ $t("back") }}</el-button>
        <el-button type="primary" @click="onSubmit">{{
          $t("auth.password.recoverySendMeLink")
        }}</el-button>
      </div>
    </div>
    <div class="mt-5" v-if="linkSent">
      <div class="text-center">
        {{ $t("auth.password.recoveryLinkHasBeenSent") }}
      </div>
      <div class="d-flex justify-content-center mt-5">
        <el-button type="primary" @click="$router.push('/')">Back</el-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ElementForm, emailFormat, InputRequired } from "@/util/validation";
import { authApi } from "@/resources";
import { isProblem } from "@/resources/problem";
import { ForgotPasswordForm } from "@/api";
import { fixLocalDate, i18n } from "@/util";

export default defineComponent({
  data() {
    return {
      forgotPasswordForm: {} as ForgotPasswordForm,
      linkSent: false,
      errorMessage: "" as string,
      rules: {
        login: [new InputRequired(), emailFormat],
        birthDate: [new InputRequired()],
      },
    };
  },
  methods: {
    onSubmit() {
      const form = this.$refs["form"] as ElementForm;
      form.validate((valid) => {
        if (!valid) {
          return false;
        }
        this.restorePassword();
        return true;
      });
    },
    async restorePassword() {
      try {
        this.errorMessage = "";
        this.forgotPasswordForm.birthDate = fixLocalDate(
          this.forgotPasswordForm.birthDate
        );
        await authApi.passwordRecoveryRequest({
          forgotPasswordForm: this.forgotPasswordForm,
        });
        this.linkSent = true;
      } catch (e) {
        if (isProblem(e)) {
          this.errorMessage = i18n.global.t(e.detail);
        }
      }
    },
  },
});
</script>
