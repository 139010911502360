<template>
  <el-dropdown-item @click="dialogVisible = true">
    {{ $t("user.doActivate") }}
  </el-dropdown-item>

  <teleport to="body">
    <el-dialog
      :title="$t('user.askPortalUserActivate')"
      v-model="dialogVisible"
    >
      <template #footer>
        <el-button @click="dialogVisible = false">
          {{ $t("no") }}
        </el-button>
        <el-button type="primary" @click="activateUser">
          {{ $t("yes") }}
        </el-button>
      </template>
    </el-dialog>
  </teleport>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { portalUserApi } from "@/resources";
import { isProblem } from "@/resources/problem";

export default defineComponent({
  name: "ActivatePortalUserDialog",
  emits: ["update"],
  props: ["id", "givenName", "lastName"],
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    async activateUser() {
      try {
        await portalUserApi.activatePortalUser({
          id: this.id,
        });
        this.$notify.info(
          this.$t("user.activate", {
            givenName: this.givenName,
            lastName: this.lastName,
          })
        );
        this.$emit("update");
        this.dialogVisible = false;
      } catch (e) {
        if (isProblem(e)) {
          this.$notify.error(this.$t(e.detail));
        }
      }
    },
  },
});
</script>
