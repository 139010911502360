
import { defineComponent } from "vue";
import { CampaignStatus, CampaignType } from "@/api";
import {
  ElementForm,
  InputLength,
  numberFormat,
  NumberRange,
} from "@/util/validation";
import { Filter } from "@/views/campaign/CampaignList.vue";

export default defineComponent({
  emits: ["update:campaignFilter", "submit"],
  props: ["campaignFilter"],
  data() {
    return {
      statuses: [] as CampaignStatus[],
      type: [] as CampaignType[],
      isFormValid: true,
      filter: {} as Filter,
      rules: {
        business: [new InputLength(100)],
        approvedAmount: [numberFormat],
        minimumInvestmentFrom: [numberFormat],
        minimumInvestmentTo: [numberFormat],
        approvedAmountFrom: [numberFormat],
        approvedAmountTo: [numberFormat],
        commitmentPercentFrom: [numberFormat, new NumberRange(0.0, 100)],
        commitmentPercentTo: [numberFormat, new NumberRange(0.0, 100)],
      },
    };
  },
  async mounted() {
    this.filter = this.campaignFilter;
    this.statuses = Object.values(CampaignStatus);
    this.type = Object.values(CampaignType);
  },
  methods: {
    submit() {
      const form = this.$refs["filter-form"] as ElementForm;
      form.validate(async (valid) => {
        if (valid) {
          this.$emit("submit");
        }
      });
    },
    onValidate(prop: string, isVal: boolean) {
      this.isFormValid = isVal;
    },
    resetFilter() {
      this.filter = {} as Filter;
      this.$emit("update:campaignFilter", this.filter);
      this.$emit("submit");
    },
  },
});
