<template>
  <div class="container ms-0 mb-0" v-if="preScreeningQuiz.quiz">
    <div class="row mb-3" v-for="row in computeQuiz" v-bind:key="row">
      <div class="col color-light-text">
        {{ $enums.preScreening.quizKey(row.key1) }}
      </div>
      <div class="col wrap-text">{{ formatValue(row.key1, row.value1) }}</div>
      <div v-if="row.key2" class="col color-light-text">
        {{ $enums.preScreening.quizKey(row.key2) }}
      </div>
      <div v-else class="col color-light-text"></div>
      <div v-if="row.key2" class="col wrap-text">
        {{ formatValue(row.key2, row.value2) }}
      </div>
      <div v-else class="col"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import {
  PairPreScreeningQuizKeyString,
  PreScreeningQuizItem,
  PreScreeningQuizKey,
} from "@/api";

export interface Row {
  key1: PreScreeningQuizKey;
  value1: string;
  key2: PreScreeningQuizKey | undefined;
  value2: string | undefined;
}

export default defineComponent({
  name: "PreScreeningDetails",
  data() {
    return {
      preScreeningQuiz: inject("preScreeningQuiz") as PreScreeningQuizItem,
    };
  },
  computed: {
    computeQuiz(): Row[] {
      const rows = [] as Row[];
      if (this.preScreeningQuiz.quiz) {
        let quiz = this.preScreeningQuiz.quiz
          .slice()
          .sort(
            (
              k1: PairPreScreeningQuizKeyString,
              k2: PairPreScreeningQuizKeyString
            ) =>
              this.$enums.preScreening.quizOrder(k1.key) -
              this.$enums.preScreening.quizOrder(k2.key)
          );
        for (let i = 0; i < quiz.length; i = i + 2) {
          const row = {} as Row;
          row.key1 = quiz[i].key;
          row.value1 = quiz[i].value;
          if (i + 1 < quiz.length) {
            row.key2 = quiz[i + 1].key;
            row.value2 = quiz[i + 1].value;
          }
          rows.push(row);
        }
      }
      return rows;
    },
  },
  methods: {
    formatValue(key: PreScreeningQuizKey, value: string | undefined): string {
      if (!value) {
        return "-";
      }
      switch (key) {
        case PreScreeningQuizKey.GENERATE_REVENUE:
        case PreScreeningQuizKey.LAUNCHED_PRODUCT:
        case PreScreeningQuizKey.MONEY_FUNDRAISED:
          return this.$logic(Boolean(value));
        case PreScreeningQuizKey.MONTHLY_RUNWAY:
        case PreScreeningQuizKey.MONEY_RAISED:
        case PreScreeningQuizKey.MONEY_WANTS_RAISE:
          return this.$currValue(Number(value));
        case PreScreeningQuizKey.CAMPAIGN_TYPE:
          return this.$enums.campaign.type(value);
        case PreScreeningQuizKey.SUMMARY:
        case PreScreeningQuizKey.TRACTION:
        case PreScreeningQuizKey.NUMBER_OF_EMPLOYEES:
          return value;
        default:
          return value;
      }
    },
  },
});
</script>
